import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import AsideComponent from '../../../components/Aside/aside.component';
import InputComponent from '../../../components/Form/input/input.component';
import ButtonOutlineComponent from '../../../components/Button/buttonTypeOutline/buttonTypeOutline.component';
import ButtonSocialComponent from '../../../components/Button/buttonSocial/buttonSocial.component';
import './login.screen.scss';
import ModalInfoComponent from '../../../components/Modal/modalInfo/modalInfo.component';
import Cookies from 'js-cookie';

const LoginScreen = () => {
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(() => {
    // Comprueba si la cookie 'modalClosed' existe al cargar el componente
    const modalClosedCookie = Cookies.get('modalClosed');
    return modalClosedCookie ? false : true; // Si existe la cookie, no muestres el modal
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [errorExist, setErrorExist] = useState(false);

  const setError = (errorMessage: string) => {
    setErrorExist(true);
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const confirmSignUp = async (code: string) => {
    setIsLoading(true);
    try {
      const { email, password } = user;
      const confirmed = await Auth.confirmSignUp(email, code);
      if (confirmed) {
        await Auth.signIn(email, password);
      }
      setIsLoading(false);
      // this.state.navigation.navigate('Onboarding', {});
    } catch (err) {
      if ((err as any).code === 'UserNotConfirmedException') {
        setError('La cuenta aún no ha sido verificada');
      } else if ((err as any).code === 'PasswordResetRequiredException') {
        setError('Por favor reinicia tu contraseña');
      } else if ((err as any).code === 'NotAuthorizedException') {
        setError('¿Olvidaste tu contraseña?');
      } else if ((err as any).code === 'UserNotFoundException') {
        setError('El usuario no existe.');
      } else if ((err as any).code === 'CodeMismatchException') {
        setError('El código de verificación ingresado es incorrecto.');
      } else {
        setError((err as any).message);
      }
    }
  };

  const codeShow = async () => {
    const { email } = user;
    try {
      setIsLoading(true);
      await Auth.resendSignUp(email);
      Swal.fire({
        icon: 'question',
        title: 'Valida tu identidad',
        text: 'Ingresa el código que has recibido en tu correo electrónico. Revisa también en tu bandeja de spam.',
        input: 'number',
        inputAttributes: {
          length: '5',
        },
        showCancelButton: false,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: false,
        preConfirm: (confirmToken: string) => {
          if (!confirmToken || confirmToken.trim() === '') {
            throw new Error(
              'Captura el código que has recibido en tu correo electrónico'
            );
          }
          return confirmSignUp(confirmToken)
            .then((resp) => resp)
            .catch((error) => {
              setErrorExist(true);
              Swal.showValidationMessage(`${error}`);
            });
        },
        allowOutsideClick: false,
      })
        .then((result) => {
          setTimeout(() => {
            if (result.isConfirmed && !errorExist) {
              Swal.fire('Listo', 'Te has registrado', 'success');
            }
          }, 2000);
        })
        .catch((error) => {
          setErrorExist(true);
          Swal.showValidationMessage(`${error}`);
        });
    } catch (err) {
      if ((err as any).code === 'UserNotConfirmedException') {
        setError('Tu cuenta aún no ha sido verificada');
      } else if ((err as any).code === 'PasswordResetRequiredException') {
        setError('Por favor reinicia tu contraseña');
      } else if ((err as any).code === 'NotAuthorizedException') {
        setError('Haz olvidado tu contraseña?');
      } else if ((err as any).code === 'UserNotFoundException') {
        setError('El usuario no existe');
      } else if ((err as any).code === 'CodeMismatchException') {
        setError('El código de verificación ingresado es incorrecto.');
      } else {
        setError((err as any).message);
      }
    }
  };

  const signInUser = async () => {
    console.log('signInUser');
    setIsLoading(true);
    try {
      const { email, password } = user;
      const userSignIn = await Auth.signIn(email, password);
      return userSignIn;
    } catch (err) {
      const { code } = err as any;
      console.log({ code });
      if (code) {
        switch (code) {
          case 'UserNotConfirmedException':
            setError('No has confirmado tu código');
            codeShow();
            break;
          case 'UserNotFoundException':
            setError('El usuario no existe');
            break;
          case 'NotAuthorizedException':
            setError('Correo o contraseña incorrectos');
            break;
          default:
            //setError((err as any).message);
            setError('Por favor, intentalo de nuevo.');
            break;
        }
      }
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/iniciar-sesion',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);
  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <HeaderLogoutComponent />
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setModalShow(false);
          Cookies.set('modalClosed', 'true', { expires: 15 }); // Guarda una cookie llamada 'modalClosed' durante 15 días
        }}
        click={() => {
          setModalShow(false);
          Cookies.set('modalClosed', 'true', { expires: 15 });
        }}
        imageUrl="/assets/images/elements/julia-complete.png"
        content={
          <>
            <h4>Cuenta diferente requerida</h4>
            <p>
              Para acceder a <strong>PACO Teacher</strong> necesitas una cuenta
              diferente a la que utilizas en <strong>PACO</strong>. Si aún no la
              tienes, ¡crea una para disfrutar todos los contenidos que hemos
              creado para los maestros!
            </p>
          </>
        }
      />
      <div className="app-section__top">
        <BreadcrumbComponent url="/" back="Regresar" />
        <div className="container">
          <div className="row">
            <div className="register__col-left register--to-mobile login--to-mobile col-md-4 col-12">
              <AsideComponent
                title="Entra a tu cuenta y…"
                list={
                  <>
                    <li>
                      <span className="material-icons-outlined">done</span>
                      <p>Diseña planeaciones de manera gratuita e intuitiva.</p>
                    </li>
                    <li>
                      <span className="material-icons-outlined">done</span>
                      <p>
                        Comparte y explora las planeaciones de otros docentes.
                      </p>
                    </li>
                    <li>
                      <span className="material-icons-outlined">done</span>
                      <p>
                        Encuentra videojuegos y otros recursos en línea para
                        usar en clase.
                      </p>
                    </li>
                  </>
                }
                image="/assets/images/aside/aside1.png"
                alt="Registro"
              />
            </div>
            <div className="register__col-right col-md-8 col-12">
              <div className="form-init ">
                <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                  <p className="form__title-message">
                    ¡Hola! Nos alegra que te unas a esta comunidad.{' '}
                  </p>
                  <h3 className="form__title">¡Bienvenid@ de nuevo! </h3>
                  <form
                    onSubmit={(evt) => {
                      evt.preventDefault();
                      signInUser();
                    }}
                  >
                    <div className="form__row">
                      <label htmlFor="email" className="form__label">
                        Correo electrónico
                      </label>
                      <InputComponent
                        type="email"
                        id="email"
                        placeholder="miemail@mail.com"
                        value={user.email}
                        onChangeCapture={(evt) =>
                          setUser({
                            ...user,
                            email:
                              (evt?.target as HTMLTextAreaElement).value || '',
                          })
                        }
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="password" className="form__label">
                        Contraseña
                      </label>
                      <div className="form-control__password">
                        <InputComponent
                          type={passwordShown ? 'text' : 'password'}
                          id="password"
                          placeholder="Escribe una contraseña"
                          value={user.password}
                          onChangeCapture={(evt) =>
                            setUser({
                              ...user,
                              password:
                                (evt?.target as HTMLTextAreaElement).value ||
                                '',
                            })
                          }
                        />
                        <button
                          type="button"
                          className="form-control__password-action"
                          onClick={(evt) => {
                            evt.preventDefault();
                            setPasswordShown(!passwordShown);
                          }}
                          data-active={passwordShown ? 'visible' : 'off'}
                        >
                          <span className="material-icons-outlined form-control__password-visible">
                            visibility
                          </span>
                          <span className="material-icons-outlined form-control__password-off">
                            visibility_off
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="form__row">
                      <p>
                        Recupera tu cuenta{' '}
                        <Link to="/recuperar-contrasena">aquí</Link>
                      </p>
                    </div>
                    <div className="form__row form__action mt32">
                      <button
                        type="submit"
                        className="btn btn--type3"
                        disabled={isLoading}
                      >
                        Entrar
                      </button>
                    </div>
                  </form>
                </div>
                <div className="separator">
                  <p>Ó inicia con </p>
                </div>
                <div className="social-list-log">
                  <ul>
                    <li>
                      <ButtonSocialComponent
                        icon="fab fa-facebook-f"
                        onClick={() =>
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Facebook,
                          })
                        }
                      />
                    </li>
                    <li>
                      <ButtonSocialComponent
                        icon="fab fa-google"
                        onClick={() =>
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Google,
                          })
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div className="form__row form__action">
                  <div className="col-12">
                    <p className="mb-0">
                      Únete a nuestra comunidad, regístrate aquí
                    </p>
                  </div>
                  <ButtonOutlineComponent url="/registro" name="Crear cuenta" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default LoginScreen;
