import { getUserIp, getAuthSessionId } from '../utils/helpers';
import api from './index';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createSessionEvent = async (eventName: string, body: any) => {
  const userIp = await getUserIp();
  const { sessionId, kameUserId } = await getAuthSessionId(body);
  const response = await api.post(
    '/sessionEvent',
    { eventName, sessionObject: body, userIp, sessionId, kameUserId },
    {
      headers: {
        ...commonHeaders,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchSignUpUser = async (id: string, token: string) => {
  console.log('------ searchSignUpUser ------');
  const response = await api.get(`/sessionEvent/searchSignUp/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};