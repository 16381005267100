import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './explainIntro.screen.scss';
import { getPecExplanationTopic } from '../../../strapi/pecContent';
import { DatumExplanationTopic } from '../../../types/PECInterface';

const ExplainIntroScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [explanations, setExplanations] = useState<DatumExplanationTopic[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const slugify = (str: string) =>
    str
      .toLowerCase()
      .trim()
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const fetchExplanations = async () => {
    try {
      const subjectTitle = searchParams.get('subjectTitle');
      const query = {
        sort: ['title:asc'],
        pagination: { pageSize: 100 },
        filters: { subjectTitle: { $eq: subjectTitle } },
      };

      const expResult = await getPecExplanationTopic(qs.stringify(query));
      if (expResult && expResult.meta.pagination.pageCount > 1) {
        const result2 = await getPecExplanationTopic(qs.stringify({...query, pagination: { pageSize: 100, page: 2 }}));
        expResult.data.push( ...result2.data.map((s) => s));
      }

      if (expResult && expResult.data && expResult.data.length) {
        setExplanations(expResult.data);
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/explicaciones-indice',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            fetchExplanations();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url="/explicaciones-materia"
          back="Regresar"
          urlSecond="/explicaciones-materia"
          urlSecondText="Explicaciones por tema"
          urlThird="/explicaciones-indice"
          urlThirdText={`${searchParams.get('subjectTitle')}`}
        />
        <section className="container body-activity">
          <div className="row row--index">
            <div className="mx-auto col-md-10 col-12">
              <section className="header-activity header--empty">
                <figure className="header-activity__book">
                  <img
                    src="/assets/images/icon/explain.png"
                    alt="Explicaciones por tema"
                  />
                </figure>
                <article className="header-activity__text-head">
                  <h1 className="header-activity__title h3">{`${searchParams.get(
                    'subjectTitle'
                  )}`}</h1>
                </article>
              </section>
              <section className="body-activity__content">
                <div className="block-activity">
                  {/*
                  <div className="block-activity__item-search search__engine search__inner-page">
                    <div className="search-select">
                      <span className="material-icons-outlined search-select__icon">
                        search
                      </span>
                      <input
                        type="text"
                        className="form-control react-select-container"
                        placeholder="Buscar explicaciones..."
                      />
                    </div>
                  </div> */}
                  {explanations.length ? (
                    explanations.map((explanation, key) => {
                      const { title, slug } = explanation.attributes;
                      return (
                        <div className="block-activity__item" key={key}>
                          <button
                            className="modal__button"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/paco-te-explica/${slugify(
                                  searchParams.get('subjectTitle') || ''
                                )}/${slug}?goBack=/explicaciones-indice?subjectTitle=${searchParams.get(
                                  'subjectTitle'
                                )}`
                              );
                            }}
                          >
                            <p>{`${title || ''}`}</p>
                            <small>{``}</small>
                            <span className="material-icons-outlined icon">
                              navigate_next
                            </span>
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default ExplainIntroScreen;
