import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './explainContent.screen.scss';
import { getPecExplanationTopic } from '../../../strapi/pecContent';

const ExplainContentScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [topicSubjects, setTopicSubjects] = useState<string[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchPecExplanationTopic = async () => {
    try {
      const query = {
        fields: ['subjectTitle'],
        sort: ['subjectTitle'],
        pagination: { pageSize: 100, page: 1 },
      };
      const pages = [1, 2, 3, 4, 5, 6];
      const subjects: string[] = [];
      for await (const page of pages) {
        const explanationTopicsResult = await getPecExplanationTopic(
          qs.stringify({
            ...query,
            pagination: {
              ...query.pagination,
              page,
            },
          })
        );
        if (
          explanationTopicsResult &&
          explanationTopicsResult.data &&
          explanationTopicsResult.data.length
        ) {
          subjects.push(
            ...explanationTopicsResult.data
            .filter((topic) => topic.attributes.subjectTitle !== null)
            .map((topic) => topic.attributes.subjectTitle)
          );
        }
      }
      const uniques = [...new Set(subjects)];
      setTopicSubjects(uniques);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/explicaciones-materia',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            fetchPecExplanationTopic();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url="/"
          back="Regresar"
          urlSecond="/"
          urlSecondText="Inicio"
          urlThird="/"
          urlThirdText="Explicaciones por tema"
        />
        <section className="container body-activity">
          <div className="row row--index">
            <div className="mx-auto col-md-10 col-12">
              <section className="header-activity header--empty">
                <figure className="header-activity__book">
                  <img
                    src="/assets/images/icon/explain.png"
                    alt="Explicaciones por tema"
                  />
                </figure>
                <article className="header-activity__text-head">
                  <h1 className="header-activity__title h3">
                    Selecciona tu materia
                  </h1>
                </article>
              </section>
              <section className="body-activity__content">
                <section className="body-activity__grades col-12">
                  <div className="block-activity__grid-choose row">
                    {topicSubjects.map((topicSubject, key) => {
                      return (
                        <div
                          className="grid-choose__content col-lg-6 col-12"
                          key={key}
                        >
                          <div className="grid-choose__content-inner">
                            <button
                              className="grid-choose__material"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  `/explicaciones-indice?subjectTitle=${topicSubject}`
                                );
                              }}
                            >
                              <article>
                                <p>{`${topicSubject}`}</p>
                                <span className="material-icons-outlined icon">
                                  navigate_next
                                </span>
                              </article>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default ExplainContentScreen;
