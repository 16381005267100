import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalInfo.component.scss';

const ModalInfoComponent = (props: any) => {
  const handleClose = () => {
    props.onHide(); // cerrar el modal
  };

  // Usar la imagen proporcionada desde props o la imagen por defecto
  const imageSrc = props.imageUrl || "../../assets/images/elements/taco.png";

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="modal-info"
      centered
      className={`modal--info ${props.class}`}
    >
      <Modal.Header className="modal__title-icon">
        <figure className="modal-image-paco">
          <img src={imageSrc} alt="Paco" />
        </figure>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn--type3"
          onClick={handleClose}
        >
          Aceptar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfoComponent;
