import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalSecuenciaComponent = (props: any) => {
  const handleClose = () => {
    props.onHide(); // cerrar el modal
  };
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="modal-info"
      centered
      className="modal--info info--session"
    >
      <Modal.Header className="modal__title-icon" closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
      <Modal.Footer>
        <div className="modal-info__actions col-12">
          <div className="col-12">
            <button className="btn btn--type3" onClick={handleClose}>
              Guardar
            </button>
          </div>
        </div>
        <div className="modal-info__bottom col-12">
          <button
            className="btn"
            onClick={(evt) => {
              evt.preventDefault();
              props.onDelete();
            }}
          >
            Eliminar secuencia
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSecuenciaComponent;
