import React from 'react';
import { Instrumento } from '../../../types/EntidadesInterface';

type Props = {
  show: boolean;
  instrumento: Partial<Instrumento>;
  whtml: string;
};

const InstrumentosPrintComponent = ({ show, instrumento, whtml }: Props) => {
  return (
    <>
      <section
        className={`${
          show ? 'print-plan-body print-section' : 'print-plan-body '
        }`}
      >
        <div className="print-plan__title row">
          <figure className="print-plan__logo">
            <img src="https://www.pacoteacher.com/assets/images/logo/logo60.png" alt="PACO Teacher" />
          </figure>
          <h1>{instrumento.name || ''}</h1>
        </div>
        <section className="print-plan__section">
          <div className="print-section__item">
            <div className="print-section__row row">
              <div className="print-section__row__col col-12">
                {instrumento.respuestaGptEdit &&
                instrumento.respuestaGptEdit != '' ? (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: instrumento.respuestaGptEdit,
                    }}
                  ></div>
                ) : (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: whtml || `${instrumento.respuestaGpt}`,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default InstrumentosPrintComponent;
