import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import PrintStraComponent from '../print/strategiesPlan.component';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import { useParams } from 'react-router-dom';
import {
  EstrategiasDidacticas,
  LogoResult,
  RecursoEducativo,
} from '../../../types/EntidadesInterface';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ModalPDFComponent from '../../../components/Modal/modalPDF/modalPDF.component';
import ModalIframeComponent from '../../../components/Modal/modalIframe/modalIframe.component';
import ResourceStrategyComponent from '../../../components/Cards/resourceStrategy/resourceStrategy.component';
import NavigationHints from '../../../components/NavigationHints/navigationHints';

const DetailIndexScreen = () => {
  const { estrategiaId, temaId } = useParams();
  const { trackEvent } = useTracking();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPrint, setShowPrint] = useState(false);
  const [modalShowPDF, setModalShowPDF] = useState(false);
  const [modalShowIframe, setModalShowIframe] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [estrategia, setEstrategia] =
    useState<Partial<EstrategiasDidacticas>>();
  const [recurso, setRecurso] = useState<{
    id: number;
    attributes: RecursoEducativo;
  }>();
  const [recursoImage, setRecursoImage] = useState('');
  const [tema, setTema] = useState('');

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const handlePrint = () => {
    trackEvent({
      event: 'pdfPrint',
      page: '/estrategias-didacticas-detalle',
      name: estrategia?.Titulo,
      tema: tema,
      proposito: estrategia?.propositos_de_ensenanza?.data.attributes.Nombre,
      estrategia: estrategia?.estrategia?.data.attributes.Nombre,
    });
    window.print();
  };

  const handlePdf = () => {
    trackEvent({
      event: 'pdfDownload',
      page: '/estrategias-didacticas-detalle',
      name: estrategia?.Titulo,
      tema: tema,
      proposito: estrategia?.propositos_de_ensenanza?.data.attributes.Nombre,
      estrategia: estrategia?.estrategia?.data.attributes.Nombre,
    });
    const data = document.getElementById('print-placeholder');
    if (data) {
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
      });

      pdf.setProperties({
        title: 'PACO Teacher | Estrategia didáctica',
        subject: estrategia?.Titulo || '',
        author: 'PACO Teacher',
        keywords: 'estrategias-didacticas',
        creator: 'PACO Teacher',
      });

      pdf.setDisplayMode(1);

      html2canvas(data, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        const fileName = 'Estrategias didacticas';
        pdf.save(`${fileName.toLowerCase().replace(/\s/g, '-')}.pdf`);

        setIsLoading(false);
        setShowPrint(false);
      });
    } else {
      setIsLoading(false);
    }
  };

  const getTiposRecurso = async (_recurso: RecursoEducativo) => {
    const tiposR = await getStrapiCatalog(
      qs.stringify({ sort: ['Nombre:asc'], populate: ['Logo'] }),
      '/tipo-de-recursos'
    );
    if (tiposR.data?.length) {
      const tipoRecursoData = _recurso?.tipo_de_recursos?.data || [];
      const tipoRecursoImageId = tipoRecursoData.length
        ? tipoRecursoData[0].id
        : '';
      const index = tiposR.data.findIndex((r) => r.id === tipoRecursoImageId);
      if (index >= 0) {
        const image =
          (tiposR.data[index].attributes as LogoResult).Logo.data.attributes
            .url || '';
        setRecursoImage(image);
      }
    }
  };

  const fetchResource = async (tipo_recurso: number) => {
    const query = {
      populate: '*',
      sort: ['id:asc'],
      filters: {
        habilidad: { id: { $eq: temaId } },
        tipo_de_recursos: { id: { $eq: tipo_recurso } },
      },
    };
    const resource = await getStrapiCatalog(
      qs.stringify(query),
      '/recursos-educativos'
    );
    setRecurso(
      resource.data[0] as {
        id: number;
        attributes: RecursoEducativo;
      }
    );
    getTiposRecurso(resource.data[0].attributes as RecursoEducativo);
    setIsLoading(false);
  };

  const fetchEstrategia = async () => {
    const query = {
      populate: '*',
      sort: ['id:asc'],
      filters: { id: { $eq: estrategiaId } },
    };
    const resultED = await getStrapiCatalog(
      qs.stringify(query),
      '/estrategias-didacticas'
    );
    const r = resultED.data[0].attributes as EstrategiasDidacticas;
    let tem = '';
    r.habilidades.data.map((t) => {
      `${t.id}` === temaId ? (tem = t.attributes.habilidades) : '';
    });
    setEstrategia(r);
    setTema(tem);
    fetchResource(r.tipo_de_recursos.data[0].id);

    trackEvent({
      event: 'pageView',
      page: '/estrategias-didacticas-detalle',
      name: r.Titulo,
      tema: tem,
      proposito: r.propositos_de_ensenanza.data.attributes.Nombre,
      estrategia: r.estrategia.data.attributes.Nombre,
      fullPath: window.location.href,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            fetchEstrategia();
            setIsLoading(true);
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <ModalPDFComponent
        show={modalShowPDF}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowPDF(false)}
        title={`${
          recurso?.attributes?.tipo_de_recursos?.data?.length
            ? recurso?.attributes.tipo_de_recursos.data
                .map((tR) => tR.attributes.Nombre)
                .join(', ')
            : ''
        }`}
        file={recurso?.attributes.documento?.data?.attributes?.url}
      />
      <ModalIframeComponent
        show={modalShowIframe}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowIframe(false)}
        title={`${
          recurso?.attributes?.tipo_de_recursos?.data?.length
            ? recurso?.attributes.tipo_de_recursos.data
                .map((tR) => tR.attributes.Nombre)
                .join(', ')
            : ''
        }`}
        src={recurso?.attributes?.recurso}
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/estrategias-didacticas`}
          back="Regresar"
          urlInit={`/estrategias-didacticas`}
          urlInitText="Estrategias didácticas"
          urlSecond={`/ensena-con-paco-1-2-3/`}
          urlSecondText="Enseña con Paco ¡1, 2, 3!"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-12 mx-auto">
              <div className="strategy-detail-section">
                <div className="strategy-detail-section__title">
                  <h1 className="h3">Enseña con PACO Max en ¡1, 2, 3!</h1>
                  <div className="strategy-actions">
                    <div className="planning-options col-12">
                      <button
                        onClick={handlePrint}
                        className="planning-options__button"
                      >
                        <span className="material-icons-outlined">print</span>
                        Imprimir
                      </button>
                      <button
                        onClick={(evt) => {
                          evt.preventDefault();
                          setIsLoading(true);
                          setShowPrint(true);
                          setTimeout(() => {
                            handlePdf();
                          }, 200);
                        }}
                        className="planning-options__button"
                      >
                        <span className="material-icons-outlined">
                          file_download
                        </span>
                        Descargar
                      </button>
                    </div>
                  </div>
                </div>
                <div className="strategy-detail-section__content">
                  <div className="strategy-card-item">
                    <div className="strategy-card-item__head">
                      <h4>{estrategia?.Titulo}</h4>
                    </div>
                    <div className="strategy-card-item__body">
                      <div className="strategy-card__row card-row--top">
                        <small>
                          Tiempo aproximado: {estrategia?.timePrepara}
                        </small>
                        <h5>Sugerencia para preparar a tu grupo:</h5>
                        <article>
                          <p>{estrategia?.preparaGrupo}</p>
                          <ReactMarkdown
                            linkTarget={'_blank'}
                            skipHtml={false}
                          >{`${
                            estrategia?.actividadPrepara || ''
                          }`}</ReactMarkdown>
                        </article>
                      </div>
                      <div className="strategy-card__row card-row--middle">
                        <small>Tiempo aproximado: {estrategia?.time}</small>
                        <article className="strategy-article">
                          <p>
                            <strong>Propósito:</strong>{' '}
                            {
                              estrategia?.propositos_de_ensenanza?.data
                                .attributes.Nombre
                            }
                          </p>
                          <p>
                            <strong>Estrategia:</strong>{' '}
                            {estrategia?.estrategia?.data.attributes.Nombre}
                          </p>
                          <p>
                            <strong>Concepto/tema:</strong> {tema}
                          </p>
                          <p>
                            <strong>Material:</strong> {estrategia?.material}
                          </p>
                        </article>
                        <h5>Actividad: {estrategia?.Titulo} </h5>
                        <article className="strategy-article">
                          <ul>
                            <li>
                              <ReactMarkdown
                                linkTarget={'_blank'}
                                skipHtml={false}
                              >{`${
                                estrategia?.actividad1 || ''
                              }`}</ReactMarkdown>
                            </li>
                            {estrategia?.positionResource == 1 ? (
                              <>
                                <li>
                                  {`${estrategia.tipo_de_recursos?.data[0].attributes.Nombre}: `}
                                  {recurso?.attributes.Nombre}
                                </li>
                                <ResourceStrategyComponent
                                  recursoImage={recursoImage}
                                  recursoNombre={
                                    recurso?.attributes.Nombre || ''
                                  }
                                  recursoDesc={
                                    recurso?.attributes.descripcion || ''
                                  }
                                  onClick={() => {
                                    recurso?.attributes.documento?.data
                                      ? setModalShowPDF(true)
                                      : setModalShowIframe(true);
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            <li>
                              <ReactMarkdown
                                linkTarget={'_blank'}
                                skipHtml={false}
                              >{`${
                                estrategia?.actividad2 || ''
                              }`}</ReactMarkdown>
                            </li>
                            {estrategia?.positionResource == 2 ? (
                              <>
                                <li>
                                  {`${estrategia.tipo_de_recursos?.data[0].attributes.Nombre}: `}
                                  {recurso?.attributes.Nombre}
                                </li>
                                <ResourceStrategyComponent
                                  recursoImage={recursoImage}
                                  recursoNombre={
                                    recurso?.attributes.Nombre || ''
                                  }
                                  recursoDesc={
                                    recurso?.attributes.descripcion || ''
                                  }
                                  onClick={() => {
                                    recurso?.attributes.documento?.data
                                      ? setModalShowPDF(true)
                                      : setModalShowIframe(true);
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            <li>
                              <ReactMarkdown
                                linkTarget={'_blank'}
                                skipHtml={false}
                              >{`${
                                estrategia?.actividad3 || ''
                              }`}</ReactMarkdown>
                            </li>
                            {estrategia?.positionResource == 3 ? (
                              <>
                                <li>
                                  {`${estrategia.tipo_de_recursos?.data[0].attributes.Nombre}: `}
                                  {recurso?.attributes.Nombre}
                                </li>
                                <ResourceStrategyComponent
                                  recursoImage={recursoImage}
                                  recursoNombre={
                                    recurso?.attributes.Nombre || ''
                                  }
                                  recursoDesc={
                                    recurso?.attributes.descripcion || ''
                                  }
                                  onClick={() => {
                                    recurso?.attributes.documento?.data
                                      ? setModalShowPDF(true)
                                      : setModalShowIframe(true);
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </article>
                      </div>
                      <div className="strategy-card__row card-row--bottom">
                        <h5>Sugerencias para la evaluación de la actividad</h5>
                        <article>
                          <p>{estrategia?.evaluacion}</p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationHints
        button1={{
          link: '/planeaciones/nueva-escuela-mexicana',
          texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
          color: 'btn--color2',
          iconText: 'icon--grid2',
          icon: true,
          arti: false,
          max: false,
        }}
        button2={{
          link: '/crea-tu-planeacion-nem',
          texto: 'Planeaciones NEM con Inteligencia Artificial',
          iconText: 'icon--grid3',
          color: 'btn--color2',
          icon: true,
          arti: true,
          max: false,
        }}
        button3={{
          link: '/respuestas-grado',
          texto: 'Respuestas a todos los libros SEP (incluye NEM)',
          color: 'btn--color1',
          iconText: 'icon--grid1',
          icon: true,
          arti: false,
          max: false,
        }}
        user={user}
      />
      <FooterComponent />
      <div id="print-placeholder" className="toPrint" ref={componentRef}>
        <PrintStraComponent
          show={showPrint}
          estrategia={estrategia as EstrategiasDidacticas}
          temaId={temaId || ''}
          resource={recurso?.attributes}
        />
      </div>
    </section>
  );
};

export default DetailIndexScreen;
