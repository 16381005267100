import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import { IUser } from '../../types/UserInterface';
import './navigationHints.scss';
import ModalArtiComponent from '../Modal/modalArti/modalArti.component';

type ButtonInfo = {
  link: string;
  texto: string;
  color: string;
  iconText?: string;
  icon: boolean;
  arti: boolean;
  max: boolean;
  artiChat?: boolean;
};

type NavigationHintsProps = {
  user: Partial<IUser>;
  button1?: ButtonInfo;
  button2?: ButtonInfo;
  button3?: ButtonInfo;
};

const NavigationHints = ({
  user,
  button1,
  button2,
  button3,
}: NavigationHintsProps) => {
  const { trackEvent } = useTracking();
  const [modalArtiShow, setModalArtiShow] = useState(false);

  const trackingButton = (button: string) => {
    trackEvent({
      event: 'clickNavigationHints',
      page: window.location.pathname,
      button,
      fullPath: window.location.href,
    });
  };

  useEffect(() => {
    console.log(user);
  }, []);

  return (
    <>
      <section className="grid-services-navhints grid--quick">
        <ModalArtiComponent
          show={modalArtiShow}
          onHide={() => setModalArtiShow(false)}
        />
        <div className="container buttons-hints">
          <div className="grid-services-navhints-title__col col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="row">
              <div className="title-hints col-sm-12 col-md-10 col-12">
                <h1>¿En qué más te podemos ayudar?</h1>
              </div>
              <div className="col-2">
                <figure>
                  <img
                    src="/assets/images/pacomax/paco-max-enc.png"
                    alt="Pandilla PACO"
                    className="hints-pandilla__img"
                  />
                </figure>
              </div>
            </div>
          </div>
          {button1 ? (
            <div className="grid-services-navhints__col col-lg-4 col-md-6 col-sm-12 col-12">
              <a
                href={button1.link}
                className={`grid-services-navhints__btn ${button1.color}`}
                onClick={() => {
                  if (button1.artiChat) {
                    setModalArtiShow(true);
                    trackingButton('artiChat');
                  } else {
                    trackingButton(button1.link);
                  }
                }}
              >
                {button1.icon && <i className={`icon ${button1.iconText}`}></i>}
                <p>{button1.texto}</p>
                {button1.arti && (
                  <img
                    src="/assets/images/elements/arti-icon.png"
                    alt="ARTI"
                    className="grid-button-navhints__image"
                  />
                )}
                {button1.max && (
                  <img
                    src="/assets/images/pacomax/paco-icon.png"
                    alt="PACO Max"
                    className="grid-button-navhints__image"
                  />
                )}
              </a>
            </div>
          ) : (
            <></>
          )}
          {button2 ? (
            <div className="grid-services-navhints__col col-lg-4 col-md-6 col-sm-12 col-12">
              <a
                href={button2.link}
                className={`grid-services-navhints__btn ${button2.color}`}
                onClick={() => {
                  if (button2.artiChat) {
                    setModalArtiShow(true);
                    trackingButton('artiChat');
                  } else {
                    trackingButton(button2.link);
                  }
                }}
              >
                {button2.icon && <i className={`icon ${button2.iconText}`}></i>}
                <p>{button2.texto}</p>
                {button2.arti && (
                  <img
                    src="/assets/images/elements/arti-icon.png"
                    alt="ARTI"
                    className="grid-button-navhints__image"
                  />
                )}
                {button2.max && (
                  <img
                    src="/assets/images/pacomax/paco-icon.png"
                    alt="PACO Max"
                    className="grid-button-navhints__image"
                  />
                )}
              </a>
            </div>
          ) : (
            <></>
          )}
          {button3 ? (
            <div className="grid-services-navhints__col col-lg-4 col-md-6 col-sm-12 col-12">
              <a
                href={button3.link}
                className={`grid-services-navhints__btn ${button3.color}`}
                onClick={() => {
                  if (button3.artiChat) {
                    setModalArtiShow(true);
                    trackingButton('artiChat');
                  } else {
                    trackingButton(button3.link);
                  }
                }}
              >
                {button3.icon && <i className={`icon ${button3.iconText}`}></i>}
                <p>{button3.texto}</p>
                {button3.arti && (
                  <img
                    src="/assets/images/elements/arti-icon.png"
                    alt="ARTI"
                    className="grid-button-navhints__image"
                  />
                )}
                {button3.max && (
                  <img
                    src="/assets/images/pacomax/paco-icon.png"
                    alt="PACO Max"
                    className="grid-button-navhints__image"
                  />
                )}
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default NavigationHints;
