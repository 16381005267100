import React, { useEffect, useState } from 'react';
import {
  consultarArti,
  createPlanningNem,
  getCountArti,
  instrumentosArti,
} from '../../api/chatGptApi';
import { useTracking } from 'react-tracking';
import { IUser } from '../../types/UserInterface';
import Swal from 'sweetalert2';
import {
  InstrumentosData,
  PlanningNemData,
} from '../../types/EntidadesInterface';

type ChatGptProps = {
  user: Partial<IUser>;
  onChatButtonClick: (opt: number) => void;
  onChatOptionButtonClick: (msj: string, opt: number) => void;
  planningId?: string;
  asignaturaId?: string;
  sessionId?: string;
  campo: string;
  option: number;
  slug: string;
  chatTitle?: string;
  chatText?: string;
  instrumento?: Partial<InstrumentosData>;
  planeacionNem?: Partial<PlanningNemData>;
};

const ChatGpt = ({
  user,
  onChatButtonClick,
  onChatOptionButtonClick,
  planningId,
  asignaturaId,
  sessionId,
  option,
  slug,
  chatText,
  chatTitle,
  instrumento,
  planeacionNem,
}: ChatGptProps) => {
  const { trackEvent } = useTracking();
  const [attemptsLeft, setAttemptsLeft] = useState(2);

  const countArti = async () => {
    getCountArti(
      {
        planningId,
        asignaturaId,
        sessionId,
        option,
        campo: slug,
      },
      user
    ).then((countA) => {
      if (countA.userPlanningIA < 2) {
        setAttemptsLeft(2 - countA.userPlanningIA);
      } else {
        setAttemptsLeft(0);
        const chatButton = document.querySelector(
          `.field-${option}-${asignaturaId}-${sessionId}`
        ) as HTMLButtonElement;
        chatButton.classList.add('disabled');
      }
    });
  };

  const instrumentos = async (chatButton: any) => {
    instrumentosArti({ ...instrumento, userId: user._id }, `${user.token}`)
      .then((r) => {
        onChatOptionButtonClick(r.idInstrumento, option);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          'Lo sentimos...',
          'Ocurrio un error, intentalo de nuevo',
          'error'
        );
        chatButton.classList.remove('disabled');
        onChatOptionButtonClick('error', option);
      });
  };

  const clickInstrumento = async () => {
    if (instrumento) {
      console.log(instrumento);
      trackEvent({
        event: 'clickARTI',
        page: '/instrumentos-de-evaluacion',
        campo: slug,
        instrumento: instrumento,
        fullPath: window.location.href,
      });
      const chatButton = document.querySelector(
        `.field-${option}-${asignaturaId}-${sessionId}`
      ) as HTMLButtonElement;
      chatButton.classList.add('disabled');
      const { gradoEscolar, asignatura, campoF } = instrumento;
      const validP1 = [gradoEscolar?.value, (asignatura?.value || campoF?.value)].every(
        (v) => v && v !== ''
      );
      let validP2 = false;
      if (instrumento.type == 1) {
        const { evaluacionCorta } = instrumento;
        validP2 = [
          evaluacionCorta?.habilidades,
          evaluacionCorta?.reactivos,
          evaluacionCorta?.numReactivos,
        ].every((v) => v && v !== '');
      } else if (instrumento.type == 2) {
        const { rubricas } = instrumento;
        validP2 = [
          rubricas?.evaluar,
          rubricas?.criterios,
          rubricas?.numCriterios,
          rubricas?.desempeno,
        ].every((v) => v && v !== '');
      }
      if (validP1 && validP2) {
        instrumentos(chatButton);
      } else {
        Swal.fire('', 'Por favor, llena todos los campos.', 'warning');
        onChatOptionButtonClick('error', option);
        chatButton.classList.remove('disabled');
      }
    }
  };

  const clickPlanning = async () => {
    if (planeacionNem) {
      trackEvent({
        event: 'clickARTI',
        page: '/planeacion-nem',
        planeacionNem: planeacionNem,
        fullPath: window.location.href,
      });
      const chatButton = document.querySelector(
        `.field-${option}-${asignaturaId}-${sessionId}`
      ) as HTMLButtonElement;
      chatButton.classList.add('disabled');
      const { grado, libro, proyecto, pda, campoFormativo, sesiones } =
        planeacionNem;
      const validP1 = [
        grado?.value,
        libro?.value,
        proyecto?.value,
        pda?.value,
        campoFormativo?.value,
        sesiones,
      ].every((v) => v && v !== '');

      if (validP1) {
        createPlanningNem(
          { planningNem: {...planeacionNem}, userId: user._id },
          `${user.token}`
        )
          .then((r) => {
            onChatOptionButtonClick(r.idPlanNem, option);
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
              'Lo sentimos...',
              'Ocurrio un error, intentalo de nuevo',
              'error'
            );
            chatButton.classList.remove('disabled');
            onChatOptionButtonClick('error', option);
          });
      } else {
        Swal.fire('', 'Por favor, llena todos los campos.', 'warning');
        onChatOptionButtonClick('error', option);
        chatButton.classList.remove('disabled');
      }
    }
  };

  const handleChatButtonClick = async () => {
    onChatButtonClick(option);
    if (option < 8) {
      trackEvent({
        event: 'clickARTI',
        page: '/planeacion-flexible',
        planningId,
        asignaturaId,
        campo: slug,
        fullPath: window.location.href,
      });
      const chatButton = document.querySelector(
        `.field-${option}-${asignaturaId}-${sessionId}`
      ) as HTMLButtonElement;
      if (attemptsLeft <= 1) {
        chatButton.classList.add('disabled');
      }
      setAttemptsLeft(attemptsLeft - 1);
      let c = {
        planningId,
        asignaturaId,
        sessionId,
        option,
        campo: slug,
      };
      consultarArti(c, `${user.token}`)
        .then((r) => {
          console.log(r.response);
          onChatOptionButtonClick(r.response, option);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            'Lo sentimos...',
            'Ocurrio un error, intentalo de nuevo',
            'error'
          );
          if (attemptsLeft <= 0) {
            chatButton.classList.remove('disabled');
          }
          setAttemptsLeft(attemptsLeft - 1 + 1);
        });
    } else if (option == 8 && instrumento) {
      clickInstrumento();
    } else if (option == 9) {
      clickPlanning();
    }
  };

  useEffect(() => {
    if (option < 8) {
      countArti();
    }
  }, []);

  return (
    <>
      <div
        className={`chat-section field-${option}-${asignaturaId}-${sessionId}`}
      >
        <button className="chat-text-button" onClick={handleChatButtonClick}>
          <button className="chat-button">
            <figure>
              <img
                src="/assets/images/elements/arti-happy.png "
                alt="Arti Chat"
              />
            </figure>
          </button>
          <article className="chat-info">
            <h6>{chatTitle || 'Sugerencias de ARTI'}</h6>
            <p>{chatText || 'Inteligencia Artificial'}</p>
          </article>
        </button>
      </div>
    </>
  );
};

export default ChatGpt;
