import React, { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ModalInfoComponent from '../../Modal/modalInfo/modalInfo.component';
import ToggleEnabledComponent from '../../Form/toggleEnabled/toggleEnabled.component';
import OverlayComponent from '../../OverlayTrigger/OverlayComponent';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
} from '../../../types/FlexiblePlanningInterface';
import ChatGpt from '../../ChatGpt/chatGpt';
import { IUser } from '../../../types/UserInterface';
import { useTracking } from 'react-tracking';

interface Props {
  user: Partial<IUser>;
  planning: Partial<IFlexiblePlanning>;
  stateArti: {estado: boolean, est: number};
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
  planningAsignatura: IFlexiblePlanningAsignatura;
  updateAsignaturaState: (
    planningAsignatura: IFlexiblePlanningAsignatura,
    key: keyof IFlexiblePlanningAsignatura,
    value: any
  ) => void;
  updateAsignatura: (
    planningAsignatura: Partial<IFlexiblePlanningAsignatura>,
    key: keyof IFlexiblePlanningAsignatura
  ) => Promise<Partial<IFlexiblePlanningAsignatura>>;
}

const CamposAdicionalesComponent = ({
  user,
  planning,
  stateArti,
  updatePlanning,
  updatePlanningState,
  planningAsignatura,
  updateAsignaturaState,
  updateAsignatura,
}: Props) => {
  const { trackEvent } = useTracking();
  const [modalShow, setModalShow] = useState(false);
  const [activo, setActivo] = useState(false);
  const [loading, setloading] = useState({
    apclave: false,
    propped: false
  });
  const [showTextArea, setShowTextArea] = useState({
    apclave: false,
    propped: false
  });
  const [textAreaValue, setTextAreaValue] = useState({
    apclave: '',
    propped: ''
  });

  const handleChatButtonClick = (opt: number) => {
    if(opt === 2){
      setShowTextArea({...showTextArea, apclave: true})
      setloading({...loading, apclave: true});
    }  else{
      setShowTextArea({...showTextArea, propped: true})
      setloading({...loading, propped: true});
    }
  };

  const handleChatOptionButtonClick = (msj: string, opt: number) => {
    let txt = '';
    if(opt === 2) {
      setShowTextArea({...showTextArea, apclave: true})
      txt = (textAreaValue.apclave === '') 
                ? `Mi primera sugerencia es: ${msj} \n\n`
                : `Mi segunda sugerencia es: ${msj} \n\n ${textAreaValue.apclave}`;
      setTextAreaValue({...textAreaValue, apclave: txt })
      setloading({...loading, apclave: false});
    }else{
      setShowTextArea({...showTextArea, propped: true})
      txt = (textAreaValue.propped === '') 
                ? `Mi primera sugerencia es: ${msj} \n\n`
                : `Mi segunda sugerencia es: ${msj} \n\n ${textAreaValue.propped}`;
      setTextAreaValue({...textAreaValue, propped: txt })
      setloading({...loading, propped: false});
    }
  };

  const copytxt = (opt: number) => {
    trackEvent({
      event: 'copyArti',
      page: '/planeacion-flexible',
      planningId: planning._id,
      asignaturaId: planningAsignatura._id,
      campo: (opt == 1) ? 'aprendizajes-clave' : 'propositos-pedagogicos',
      fullPath: window.location.href,
    });
    if(opt === 1){
      const textA = textAreaValue.apclave.replace('Mi primera sugerencia es: ', '')
                          .replace('Mi segunda sugerencia es: ', '').trim();
      let apc = (planningAsignatura.apClave) 
          ? planningAsignatura.apClave + textA
          : textA
      updateAsignaturaState(
        planningAsignatura,
        'apClave',
        apc
      );
      updateAsignatura(
        {
          ...planningAsignatura,
          apClave: apc
        },
        'apClave'
      );
    }  else{
      const textP = textAreaValue.propped.replace('Mi primera sugerencia es: ', '')
      .replace('Mi segunda sugerencia es: ', '').trim();
      let ppe = (planningAsignatura.propPed) 
          ? planningAsignatura.propPed + textP
          : textP;
      updateAsignaturaState(
        planningAsignatura,
        'propPed',
        ppe
      )
      updateAsignatura(
        {
          ...planningAsignatura,
          propPed: ppe,
        },
        'propPed'
      );
    }
  };

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { disabledFields } = planning;
    if (!event.target.checked) {
      await updateAsignatura(
        {
          ...planningAsignatura,
          apClave: '',
          propPed: '',
          contexto: '',
        },
        'apClave'
      );
      let founded = false;
      let disabledFieldsA = (disabledFields?.asignaturas || []).map(
        (asignatura) => {
          if (asignatura.asignaturaId === planningAsignatura._id) {
            founded = true;
            const fields = asignatura.fields || [];
            fields.push('apClave', 'propPed', 'contexto');
            return {
              asignaturaId: asignatura.asignaturaId,
              fields: [...new Set(fields)],
            };
          }
          return {
            ...asignatura,
          };
        }
      );
      if (!founded) {
        disabledFieldsA.push({
          asignaturaId: planningAsignatura._id || '',
          fields: ['apClave', 'propPed', 'contexto'],
        });
      }
      await updatePlanning({
        ...planning,
        disabledFields: {
          ...disabledFields,
          asignaturas: disabledFieldsA,
        },
      });
    } else {
      await updatePlanning({
        ...planning,
        disabledFields: {
          ...planning.disabledFields,
          asignaturas: planning.disabledFields?.asignaturas?.map((a) => {
            if (a.asignaturaId === planningAsignatura._id) {
              return {
                asignaturaId: a.asignaturaId,
                fields: a.fields.filter(
                  (f) => !['apClave', 'propPed', 'contexto'].includes(f)
                ),
              };
            }
            return {
              ...a,
            };
          }),
        },
      });
    }
  };

  useEffect(() => {
    if(stateArti.est == 1){
      setShowTextArea({
        apclave: stateArti.estado,
        propped: stateArti.estado,
      });
      setTextAreaValue({
        apclave: '',
        propped: ''
      })
    }
  }, [planning]);

  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Campos adicionales</h4>
            <p>
              De ser necesario, puedes agregar los aprendizajes clave y/o propósitos pedagógicos que buscas lograr con tu planeación. 
              Recuerda que si decides compartir tu planeación con otros compañeros(as) docentes, les puede ser de ayuda conocer el 
              contexto en tu aula. 
            </p>
          </>
        }
      />
      <div
        className={`session-complete ${
          (
            ['apClave', 'propPed', 'contexto'] as Array<
              keyof IFlexiblePlanningAsignatura
            >
          ).every((f) => {
            return planning.disabledFields?.asignaturas?.length
              ? planning.disabledFields?.asignaturas
                  ?.filter((a) => a.asignaturaId === planningAsignatura._id)
                  .every((a) => a.fields.includes(f))
              : true;
          })
            ? 'disabled'
            : ''
        }`}
      >
        <Accordion
          defaultActiveKey="0"
          className="new-accordion-planning accordion--color3"
        >
          <Card>
            <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
              <div className="accordion-planning__title  pl-0">
                <div className="toggle-complete">
                  <div className="toggle-action">
                    <input
                      type="checkbox"
                      id="toggle"
                      checked={
                        (
                          ['apClave', 'propPed', 'contexto'] as Array<
                            keyof IFlexiblePlanningAsignatura
                          >
                        ).every((f) => {
                          return planning.disabledFields?.asignaturas?.length
                            ? planning.disabledFields?.asignaturas
                                ?.filter(
                                  (a) =>
                                    a.asignaturaId === planningAsignatura._id
                                )
                                .every((a) => a.fields.includes(f))
                            : true;
                        })
                          ? false
                          : true
                      }
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <figure></figure>
                    </div>
                  </div>
                  <label>
                    Campos adicionales
                    <button
                      onClick={() => {
                        setModalShow(true);
                      }}
                      className="toggle-moda-tooltip"
                    >
                      <span className="material-icons-outlined">info</span>
                    </button>
                  </label>
                </div>
              </div>
              <CustomToggle eventKey="0">
                <span className="material-icons-outlined accordion-header__arrow">
                  keyboard_arrow_down
                </span>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'asignatura',
                          asignatura: {
                            asignaturaId: planningAsignatura._id || '',
                            field: 'apClave',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updateAsignatura(
                              {
                                ...planningAsignatura,
                                apClave: undefined,
                              },
                              'apClave'
                            );
                          }
                        }}
                        contentTitle={
                          <>
                            Aprendizajes clave
                            <OverlayComponent toolTipText="Enlista los conceptos o habilidades específicas en los que se centrará esta sesión de tu planeación. ARTI te puede ayudar con un par de ideas" />
                          </>
                        }
                        contentToEnabled={
                          <> 
                            <ChatGpt 
                              user={user}
                              onChatButtonClick={handleChatButtonClick} 
                              onChatOptionButtonClick={handleChatOptionButtonClick} 
                              campo='Aprendizajes clave'
                              slug='aprendizajes-clave'
                              planningId={planning._id}
                              asignaturaId={planningAsignatura._id}
                              sessionId=''
                              option={2}
                            />

                            {showTextArea.apclave ? (
                            <div className="form-control form--hide-element">
                              <textarea
                                className="form-control"
                                value={planningAsignatura.apClave || ''}
                                onChangeCapture={(event) =>
                                  updateAsignaturaState(
                                    planningAsignatura,
                                    'apClave',
                                    (event.target as HTMLTextAreaElement).value
                                  )
                                }
                                onBlur={(event) => {
                                  event.preventDefault();
                                  updateAsignatura(
                                    {
                                      ...planningAsignatura,
                                      apClave: (
                                        event.target as HTMLTextAreaElement
                                      ).value,
                                    },
                                    'apClave'
                                  );
                                }}
                              >
                              </textarea>
                              <p className="autocomplete-field__indicator">Texto sugerido por ARTI, puedes copiarlo y pegarlo en el campo de tu planeación, ahí podrás editarlo. <br /> Solo puedes usar ARTI 2 veces en este campo.</p>
                             <div className="autocomplete-field__container">
                              { loading.apclave ? <img className="loader-small" src="/assets/images/elements/arthinking.gif" /> : <></> }
                              <textarea className="autocomplete-field" value={textAreaValue.apclave} />
                              { textAreaValue.apclave != '' ? 
                                <div className='autocomplete-field__btn'>
                                  <button
                                    className="btn btn--type-outline btn--small"
                                    onClick={(evt) => {
                                      evt.preventDefault();
                                      copytxt(1);
                                    }}
                                  >
                                    Copiar
                                  </button>
                                </div>
                                : <></>
                              }
                             </div>
                            </div>
                          ) : <>
                            <textarea
                              className="form-control"
                              value={planningAsignatura.apClave || ''}
                              onChangeCapture={(event) =>
                                updateAsignaturaState(
                                  planningAsignatura,
                                  'apClave',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateAsignatura(
                                  {
                                    ...planningAsignatura,
                                    apClave: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                  'apClave'
                                );
                              }}
                            ></textarea>
                          
                          </>}
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'asignatura',
                          asignatura: {
                            asignaturaId: planningAsignatura._id || '',
                            field: 'propPed',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updateAsignatura(
                              {
                                ...planningAsignatura,
                                propPed: undefined,
                              },
                              'propPed'
                            );
                          }
                        }}
                        contentTitle={
                          <>
                            Propósitos pedagógicos
                            <OverlayComponent toolTipText="En este campo describe la razón de la planeación y los objetivos que tus alumnos deben alcanzar. ARTI te puede ayudar con un par de ideas  " />
                          </>
                        }
                        contentToEnabled={
                          <> 
                            <ChatGpt 
                              user={user}
                              onChatButtonClick={handleChatButtonClick} 
                              onChatOptionButtonClick={handleChatOptionButtonClick} 
                              campo='Propósitos pedagógicos'
                              slug='propositos-pedagogicos'
                              planningId={planning._id}
                              asignaturaId={planningAsignatura._id}
                              sessionId=''
                              option={3}
                            />
                            {showTextArea.propped ? (
                            <div className="form-control form--hide-element">
                              <textarea
                              className="form-control"
                              value={planningAsignatura.propPed || ''}
                              onChangeCapture={(event) =>
                                updateAsignaturaState(
                                  planningAsignatura,
                                  'propPed',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateAsignatura(
                                  {
                                    ...planningAsignatura,
                                    propPed: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                  'propPed'
                                );
                              }}
                            ></textarea>
                              <p className="autocomplete-field__indicator">Texto sugerido por ARTI, puedes copiarlo y pegarlo en el campo de tu planeación, ahí podrás editarlo. <br /> Solo puedes usar ARTI 2 veces en este campo.</p>
                              { loading.propped ? <img className="loader-small" src="/assets/images/elements/arthinking.gif" /> : <></> }
                              <textarea className="autocomplete-field" value={textAreaValue.propped} />
                              { textAreaValue.propped != '' ? 
                                <div className='autocomplete-field__btn'>
                                  <button
                                    className="btn btn--type-outline btn--small"
                                    onClick={(evt) => {
                                      evt.preventDefault();
                                      copytxt(2);
                                    }}
                                  >
                                    Copiar
                                  </button>
                                </div>
                                : <></>
                              }
                            </div>
                          ) : <>
                            <textarea
                              className="form-control"
                              value={planningAsignatura.propPed || ''}
                              onChangeCapture={(event) =>
                                updateAsignaturaState(
                                  planningAsignatura,
                                  'propPed',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateAsignatura(
                                  {
                                    ...planningAsignatura,
                                    propPed: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                  'propPed'
                                );
                              }}
                            ></textarea>
                          
                          </>}
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'asignatura',
                          asignatura: {
                            asignaturaId: planningAsignatura._id || '',
                            field: 'contexto',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updateAsignatura(
                              {
                                ...planningAsignatura,
                                contexto: undefined,
                              },
                              'contexto'
                            );
                          }
                        }}
                        contentTitle={
                          <>
                            Contexto en el aula
                            <OverlayComponent toolTipText="Describe el contexto en el cual se lleva a cabo la sesión.  " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={planningAsignatura.contexto || ''}
                              onChangeCapture={(event) =>
                                updateAsignaturaState(
                                  planningAsignatura,
                                  'contexto',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateAsignatura(
                                  {
                                    ...planningAsignatura,
                                    contexto: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                  'contexto'
                                );
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </>
  );
};

export default CamposAdicionalesComponent;
