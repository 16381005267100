import React, { useEffect, useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import ModalArtiComponent from '../../Modal/modalArti/modalArti.component';
import AdviceVentasComponent from '../../Advice/adviceVentas.component';
// import AdviceComponent from '../../Advice/advice.component';

const HeaderLogoutComponent = () => {
  const { trackEvent } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [modalArtiShow, setModalArtiShow] = useState(false);

  const contentClassname = isOpen ? `active` : ``;
  const location = useLocation();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 100;
      if (scrollCheck) {
        console.log('scrollCheck', scrollCheck);
        setScroll(scrollCheck);
      } else {
        setScroll(false);
      }
    });
  });

  return (
    <header className="header" data-class={scroll}>
      <ModalArtiComponent
        show={modalArtiShow}
        onHide={() => setModalArtiShow(false)}
      />
      <section className="header__top-row">
        <div className="container">
          <div className="header__row row">
            <div className="header__col-left col-xl-2  col-6">
              <Link to="/" className="header__logo">
                <figure>
                  <img
                    src="/assets/images/logo/paco-teacher-logo.svg"
                    alt="PACO Teacher"
                  />
                </figure>
              </Link>
            </div>
            <div className="header__col-right col-xl-10  col-6">
            <div className="header__gif">
              <button
                    className="header__arti"
                    onClick={() => {
                      setModalArtiShow(true);
                      trackEvent({
                        event: 'clickArtiChat',
                        page: window.location.pathname,
                        fullPath: window.location.href,
                      });
                    }}
                  >
                    <img
                      src="/assets/images/pacomax/arti-mov.gif"
                      alt="Arti Chat"
                    />
                  </button>
              </div>
              <nav className="main-nav" data-action={contentClassname}>
                <ul>
                  <li>
                    <NavLink to="/mis-planeaciones">
                      Planeaciones{' '}
                      <span className="material-icons">expand_more</span>
                    </NavLink>
                    <ol>
                      <li>
                        <NavLink to="/planeaciones/nueva-escuela-mexicana">
                          Planeaciones para descargar (incluye NEM y
                          dosificaciones)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/crea-tu-planeacion-nem">
                          Planeaciones NEM con Inteligencia Artificial
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/planeacion-flexible">
                          Planeaciones 2011 y 2017
                        </NavLink>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <NavLink to="/respuestas-grado">
                      Prepara tu clase{' '}
                      <span className="material-icons">expand_more</span>
                    </NavLink>
                    <ol>
                      <li>
                        <NavLink to="/busqueda">Recursos educativos</NavLink>
                      </li>
                      <li>
                        <NavLink to="/estrategias-didacticas">
                          Enseña matemáticas con PACO Max
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/explicaciones-materia">
                          Explicaciones por tema
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/respuestas-grado">
                          Respuestas a todos los libros SEP (incluye NEM)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/ponte-a-prueba-grados">
                          Reactivos para exámenes
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/instrumentos-de-evaluacion">
                          Instrumentos de evaluación con Inteligencia Artificial
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/recursos-papalote">
                          Recursos educativos Papalote Museo del Niño
                        </NavLink>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <NavLink to="/boletin-nueva-escuela-mexicana">
                      Boletín NEM
                    </NavLink>
                  </li>
                  <li>
                    <button
                      className="header__arti"
                      onClick={() => {
                        setModalArtiShow(true);
                        trackEvent({
                          event: 'clickArtiChat',
                          page: window.location.pathname,
                          fullPath: window.location.href,
                        });
                      }}
                    >
                      <img
                        src="/assets/images/pacomax/arti-mov.gif"
                        alt="Arti Chat"
                      />
                    </button>
                  </li>
                  <li className="main-nav__separator main-nav__extras">
                    <NavLink
                      to="/iniciar-sesion"
                      className="main-nav__link main-nav__login"
                    >
                      Iniciar sesión
                    </NavLink>
                  </li>
                  <li className="main-nav__extras">
                    <Link to="/registro" className="btn btn--type4">
                      Regístrate
                    </Link>
                  </li>
                </ul>
              </nav>
              <button
                id="open-nav"
                className={contentClassname}
                onClick={() => setIsOpen(!isOpen)}
              >
                <small></small>
                <small></small>
                <small></small>
              </button>
            </div>
          </div>
        </div>
      </section>
      {location.pathname === '/' ||
      location.pathname.includes('/bienvenido') ? (
        <>
          {/* <div className="header__search-bar">
          <div className="container">
            <div className="row">
              <div className="header__search-bar-item col-lg-6 col-12"></div>
              <div className="header__search-bar-item col-lg-6 col-12">
                <input
                  type="search"
                  placeholder="Encuentra inspiración para tus planeaciones..."
                />
                <Link to="recursos-digitales">
                  <span className="material-icons">search</span>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
          <AdviceVentasComponent />
        </>
      ) : (
        <></>
      )}
    </header>
  );
};

export default HeaderLogoutComponent;
