import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import AsideComponent from '../../../components/Aside/aside.component';
import InputComponent from '../../../components/Form/input/input.component';
import './recover.screen.scss';
import { IUser } from '../../../types/UserInterface';

const RecoverScreen = () => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const intentRecover = async () => {
    const { email } = user;
    if (!email) {
      return Swal.fire('Oops', 'Introduce un correo válido', 'warning');
    }
    try {
      setIsLoading(true);
      const userForgot = await Auth.forgotPassword(email);
      if (userForgot) {
        setIsLoading(false);
        Swal.fire({
          icon: 'question',
          title: 'Recuperar contraseña',
          text: 'Ingresa el código que has recibido en tu correo electrónico',
          input: 'number',
          inputAttributes: {
            length: '5',
          },
          showCancelButton: false,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: false,
          preConfirm: (confirmToken: string) => {
            if (!confirmToken || confirmToken.trim() === '') {
              throw new Error(
                'Captura el código que has recibido en tu correo electrónico'
              );
            }
            setUser({
              ...user,
              recoverCode: confirmToken,
            });
          },
          allowOutsideClick: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Listo',
                'Elige y confirma tu nueva contraseña.',
                'success'
              );
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
          });
      }
    } catch (error) {
      return setIsLoading(false);
    }
  };

  const confirmRecover = async () => {
    const { recoverCode, email, password, confirmPassword } = user;
    console.log({ recoverCode, email, password, confirmPassword });
    const valid = [recoverCode, email, password, confirmPassword].every(
      (k) => k !== ''
    );
    if (valid) {
      try {
        setIsLoading(true);
        const success = await Auth.forgotPasswordSubmit(
          email || '',
          recoverCode || '',
          password || ''
        );
        if (success) {
          await Auth.signIn(email || '', password);
          return true;
        }
      } catch (error) {
        return setError(`${error}`);
      }
    }
    Swal.fire('Ooops', 'Captura todos los datos', 'warning');
    return true;
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/recuperar-contrasena',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <HeaderLogoutComponent />
      <div className="app-section__top">
        <BreadcrumbComponent url="/" back="Regresar" />
        <div className="container">
          <div className="row">
            <div className="register__col-left register--to-mobile login--to-mobile col-md-4 col-12">
              <AsideComponent
                title="Recupera tu cuenta aquí"
                list=""
                image="/assets/images/aside/aside1.png"
                alt="Registro"
              />
            </div>
            <div className="register__col-right col-md-8 col-12">
              <div className="form-init ">
                {!user.recoverCode ? (
                  <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                    <h3 className="form__title">Recuperar contraseña</h3>
                    <article className="form__text">
                      <p>
                        Ingresa tu <strong>correo electrónico</strong> para
                        enviarte los pasos a seguir para la recuperación de tu
                        contraseña.
                      </p>
                    </article>
                    <form
                      onSubmit={(evt) => {
                        evt.preventDefault();
                        intentRecover();
                      }}
                    >
                      <div className="form__row">
                        <label htmlFor="email" className="form__label">
                          Correo electrónico
                        </label>
                        <InputComponent
                          type="email"
                          id="email"
                          placeholder="miemail@mail.com"
                          value={user.email}
                          onChangeCapture={(evt) =>
                            setUser({
                              ...user,
                              email:
                                (evt?.target as HTMLTextAreaElement).value ||
                                '',
                            })
                          }
                        />
                      </div>
                      <div className="form__row form__action mt32">
                        <button
                          type="submit"
                          className="btn btn--type3"
                          disabled={isLoading}
                        >
                          Recuperar
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                    <h3 className="form__title">Recuperar contraseña</h3>
                    <article className="form__text">
                      <p>Elige y confirma tu nueva contraseña.</p>
                    </article>
                    <form
                      onSubmit={(evt) => {
                        evt.preventDefault();
                        confirmRecover();
                      }}
                    >
                      <div className="form__row">
                        <label htmlFor="password" className="form__label">
                          Contraseña
                        </label>
                        <div className="form-control__password mb-4">
                          <InputComponent
                            type={passwordShown ? 'text' : 'password'}
                            id="password"
                            placeholder="Escribe una contraseña"
                            value={user.password || ''}
                            onChangeCapture={(evt) =>
                              setUser({
                                ...user,
                                password:
                                  (evt?.target as HTMLTextAreaElement).value ||
                                  '',
                              })
                            }
                          />
                          <button
                            type="button"
                            className="form-control__password-action"
                            onClick={() => setPasswordShown(!passwordShown)}
                            data-active={passwordShown ? 'visible' : 'off'}
                          >
                            <span className="material-icons-outlined form-control__password-visible">
                              visibility
                            </span>
                            <span className="material-icons-outlined form-control__password-off">
                              visibility_off
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="form__row">
                        <label
                          htmlFor="confirmPassword"
                          className="form__label"
                        >
                          Confirmar contraseña
                        </label>
                        <div className="form-control__password mb-4">
                          <InputComponent
                            type={passwordShown ? 'text' : 'password'}
                            id="confirmPassword"
                            placeholder="Escribe una contraseña"
                            value={user.confirmPassword || ''}
                            onChangeCapture={(evt) =>
                              setUser({
                                ...user,
                                confirmPassword:
                                  (evt?.target as HTMLTextAreaElement).value ||
                                  '',
                              })
                            }
                          />
                        </div>
                        <div className="alert alert-password">
                          <p>
                            <strong>
                              <i className="fas fa-info-circle"></i> Tip.
                            </strong>{' '}
                            Considera en tu contraseña <strong>mínimo</strong>:
                          </p>
                          <ul>
                            <li>8 caracteres</li>
                            <li>Letras minúsculas</li>
                            <li>Letras mayúsculas</li>
                          </ul>
                        </div>
                      </div>
                      <div className="form__row form__action mt32">
                        <button
                          type="submit"
                          className="btn btn--type3"
                          disabled={isLoading}
                        >
                          Recuperar
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default RecoverScreen;
