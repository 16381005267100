import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import Swal from 'sweetalert2';
import TimepickerComponent from '../../Form/timepicker/timepicker.component';
import TextareaComponent from '../../Form/textarea/textarea.component';
import MaterialItemAddedComponent from '../../Cards/materialAdded/materialAdded.component';
import { Accordion } from 'react-bootstrap';
import './cierre.component.scss';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { IPlanningSession } from '../../../types/PlaningInterface';
import {
  GenericResult,
  Logo,
  RecursoEducativo,
  PreguntaReflexion,
} from '../../../types/EntidadesInterface';
import CreatableComponent from '../../Form/multiselect/creatable.component';

type Props = {
  session: Partial<IPlanningSession>;
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
  tiposRecurso: GenericResult;
  setSession: (
    key: keyof IPlanningSession,
    value: any
  ) => Partial<IPlanningSession>;
  updateSession: (newSession: Partial<IPlanningSession>) => Promise<void>;
  preguntasR: {
    id: number;
    attributes: PreguntaReflexion;
  }[];
  isSameUser: boolean;
  isValidSession: () => boolean;
  getTotalMinutes: (value: number) => number;
};

const CierreComponent = ({
  session,
  recursos,
  tiposRecurso,
  setSession,
  updateSession,
  preguntasR,
  isSameUser,
  isValidSession,
  getTotalMinutes,
}: Props) => {
  const navigate = useNavigate();
  const { cierre } = session;
  const introRecursos = (cierre?.resources || []).map((r) => r.documentId);
  const strapiRecursos = recursos.filter(
    (r) => introRecursos.indexOf(`${r.id}`) >= 0
  );

  return (
    <Accordion.Item eventKey="3" className="session-tab">
      <Accordion.Header>
        <div className="session-tab__toggle tab--color3">
          <h6>3. Cierre</h6>
          <p>
            {`${
              cierre?.minutes
                ? format(fromUnixTime(cierre.minutes), 'HH:mm')
                : ''
            }`}
          </p>
          <span className="material-icons-outlined icon">expand_more</span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="session-tab__open">
          <div className="session-tab__open-row row">
            <div className="col-12 col-reset">
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form__row">
                    <label
                      htmlFor="description"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        ¿Quieres agregar preguntas de reflexión?
                      </div>
                      <OverlayComponent toolTipText="Selecciona todas la preguntas que consideres relevantes para el cierre de la sesión. Si se te ocurre alguna otra, añádela a la lista." />
                    </label>
                    <CreatableComponent
                      disabled={!isSameUser}
                      options={(preguntasR || []).map((inst) => ({
                        value: `${inst.id}`,
                        label: inst.attributes.Nombre,
                      }))}
                      value={(cierre?.preguntasReflexion || []).map((v) => ({
                        value: v.documentId,
                        label: v.value,
                      }))}
                      onChange={(values) => {
                        if (cierre) {
                          const newSession = setSession('cierre', {
                            preguntasReflexion: values.map((v) => ({
                              documentId: v.value,
                              value: v.label,
                            })),
                            description: cierre?.description || '',
                            minutes: cierre?.minutes || 0,
                            resources: cierre?.resources || [],
                          });
                          updateSession(newSession);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form__row">
                    <label
                      htmlFor="description"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Tiempo estimado (hh:mm)
                      </div>
                      <OverlayComponent toolTipText="Añade el tiempo destinado a esta etapa de la sesión. Formato horas:minutos" />
                    </label>
                    <TimepickerComponent
                      disabled={!isSameUser}
                      currentValue={
                        cierre?.minutes ? fromUnixTime(cierre.minutes) : null
                      }
                      onTimeChange={(unix) => {
                        const newSession = setSession('cierre', {
                          description: cierre?.description || '',
                          minutes: unix,
                          totalMinutes: getTotalMinutes(unix),
                          preguntasReflexion: cierre?.preguntasReflexion || [],
                          resources: cierre?.resources || [],
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-reset">
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="form__row">
                    <label
                      htmlFor="description"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Actividades a desarrollar
                      </div>
                      <OverlayComponent toolTipText="Describe las actividades que vas a desarrollar para la última etapa de tu actividad." />
                    </label>
                    <TextareaComponent
                      id="description"
                      placeholder="Descripción"
                      value={cierre ? cierre.description : ''}
                      readOnly={!isSameUser}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        setSession('cierre', {
                          description:
                            (evt.target as HTMLTextAreaElement).value || '',
                          minutes: cierre?.minutes || 0,
                          preguntasReflexion: cierre?.preguntasReflexion || [],
                          resources: cierre?.resources || [],
                        });
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = setSession('cierre', {
                          description:
                            (evt?.target as HTMLTextAreaElement).value || '',
                          minutes: cierre?.minutes || 0,
                          preguntasReflexion: cierre?.preguntasReflexion || [],
                          resources: cierre?.resources || [],
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="session-tab__open-row row">
            {(strapiRecursos || []).map((recurso, k) => {
              const {
                attributes: {
                  Nombre,
                  aprendizajes,
                  fuente,
                  tipo_de_recursos: tipoDeRecurso,
                },
              } = recurso;
              const recursoFiltro = (tiposRecurso.data || []).filter(
                (tR) => tR.id === tipoDeRecurso.data[0].id
              );
              const logo = recursoFiltro.length
                ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                : null;
              return (
                <div className="session-tab__col col-lg-4  col-12" key={k}>
                  <div className="material-title">
                    <p>{`${Nombre}`}</p>
                  </div>
                  <MaterialItemAddedComponent
                    deleteLabel={isSameUser ? 'delete' : ''}
                    image={`${
                      logo ? logo.data.attributes.formats.thumbnail.url : ''
                    }`}
                    alt="Sheepy y Los Dragones"
                    name={`${
                      tipoDeRecurso.data.length
                        ? tipoDeRecurso.data
                            .map((tR) => tR.attributes.Nombre)
                            .join(', ')
                        : ''
                    }`}
                    type="Actividad"
                    aprendizaje={
                      aprendizajes.data.length
                        ? aprendizajes.data[0].attributes.Nombre
                        : ''
                    }
                    fuente={fuente ? fuente.data.attributes.Fuente : ''}
                    time="30 min"
                    url={
                      session._id && cierre?._id
                        ? `/actividad/${recurso.id}/${session._id}/${cierre?._id}`
                        : `/actividad/${recurso.id}`
                    }
                    onClickDelete={() => {
                      const currentRs = cierre?.resources || [];
                      const index = currentRs.findIndex(
                        (r) => r.documentId === `${recurso.id}`
                      );
                      currentRs.splice(index, 1);
                      return Swal.fire({
                        icon: 'question',
                        title: 'Eliminar recurso',
                        text: `¿Deseas quitar este recurso de tu sesión?`,
                        showCancelButton: true,
                        confirmButtonText: 'Sí, quitar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const newSession = setSession('cierre', {
                            ...session,
                            cierre: {
                              description: cierre?.description || '',
                              minutes: cierre?.minutes || 0,
                              preguntasReflexion:
                                cierre?.preguntasReflexion || [],
                              resources: currentRs,
                            },
                          });
                          updateSession(newSession);
                        }
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          {isSameUser ? (
            <div className="session-tab__open-row row">
              <div className="col-12 form__action">
                <button
                  className="btn btn--add"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (isValidSession()) {
                      navigate(`/busqueda/${session._id}/${cierre?._id}`);
                    } else {
                      Swal.fire(
                        'Oops',
                        'Para agrear recursos, primero define el nombre y descripción de tu sesión',
                        'warning'
                      );
                    }
                  }}
                >
                  <span className="material-icons-outlined">add</span>
                  <p>Busca recursos para agregar a tu planeación</p>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CierreComponent;
