import api from './index';
import qs from 'qs';
import { WelcomeArti, BotonesArti, GenericResult } from '../types/EntidadesInterface';

export const getListWelcome = async () => {
  const idRandom = Math.floor(Math.random() * 5 + 1);
  const query = qs.stringify({
    filters: { id: idRandom },
  });
  const response = await api.get<GenericResult>(`/welcome-artis?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data.data[0].attributes as WelcomeArti;
};

export const getTextButton = async (button: string) => {
  const query = qs.stringify({
    filters: { button: button },
  });
  const response = await api.get<GenericResult>(`/botones-arti?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data.data[0].attributes as BotonesArti;
};
