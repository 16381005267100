import React from 'react';
import './switch.component.scss';

type Props = {
  id: string;
  checked?: boolean;
  readOnly?: boolean;
  onChangeEvent?: (checked: boolean) => void;
};

const SwitchComponent = ({ id, checked, readOnly, onChangeEvent }: Props) => {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={id}
        checked={checked || false}
        readOnly={readOnly || false}
        onChange={(event) =>
          onChangeEvent ? onChangeEvent(event.target.checked) : null
        }
      />
    </div>
  );
};

export default SwitchComponent;
