import axios from 'axios';

export default axios.create({
  baseURL: 'https://cms.docen-te.mx/api',
  headers: {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer 4dc278273f51b9ef3312c592731f8966ca4cdafcce9b0214f19465155cd2991b9be14512977ca9656f9c8234938e591defb62263aff1ff1c8add4be89d9186e4e5aa646d7371180b5df68c3244395f394fc4257a88f64f777dad9de2829499b3c86f801c172b2636c5770198c38b4f874de5fc97406585edd762ab2ac298e5e2',
  },
});
