import React from 'react';
import { PlanningNem } from '../../../types/EntidadesInterface';

type Props = {
  show: boolean;
  planning: Partial<PlanningNem>;
  whtml: string;
};

const PlanningNemPrintComponent = ({ show, planning, whtml }: Props) => {
  return (
    <>
      <section
        className={`${
          show ? 'print-plan-body print-section' : 'print-plan-body '
        }`}
      >
        <div className="print-plan__title row">
          <figure className="print-plan__logo">
            <img src="https://www.pacoteacher.com/assets/images/logo/logo60.png" alt="PACO Teacher" />
          </figure>
          <h1>{planning.name || ''}</h1>
        </div>
        <section className="print-plan__section">
          <div className="print-section__item">
            <div className="print-section__row row">
              <div className="nem-section__row__col">
                  <h4>Ubicación curricular</h4>
              </div>
              <div className="planning-row-form row">
                {!!planning.planningNem?.proyecto && (
                  <div className="nem-section__row__col col-6">
                    <h6>Proyecto</h6>
                    <p>{`${planning.planningNem?.proyecto.nombre}`}</p>
                  </div>
                )}
                {!!planning.planningNem?.grado && (
                  <div className="nem-section__row__col col-6">
                    <h6>Grado escolar</h6>
                    <p>{`${planning.planningNem?.grado.value}`}</p>
                  </div>
                )}
                {!!planning.planningNem?.campoFormativo && (
                  <div className="nem-section__row__col col-6">
                    <h6>Campo formativo</h6>
                    <p>{`${planning.planningNem?.campoFormativo.value}`}</p>
                  </div>
                )}
                {!!planning.planningNem?.libro && (
                  <div className="nem-section__row__col col-6">
                    <h6>Libro de texto</h6>
                    <p>{`${planning.planningNem?.libro.value}`}</p>
                  </div>
                )}
                {!!planning.planningNem?.contenidoS && (
                  <div className="nem-section__row__col col-6">
                    <h6> Contenido sintético</h6>
                    <p>{`${planning.planningNem?.contenidoS.value}`}</p>
                  </div>
                )}
                {!!planning.planningNem?.pda && (
                  <div className="nem-section__row__col col-6">
                    <h6> Proceso de desarrollo de aprendizaje (PDA)</h6>
                    <p>{`${planning.planningNem?.pda.value}`}</p>
                  </div>
                )}
                {!!planning.planningNem?.sesiones && (
                  <div className="nem-section__row__col col-6">
                    <h6>Número de sesiones</h6>
                    <p>{`${planning.planningNem?.sesiones}`}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="print-section__row row">
            <div className="nem-section__row__col">
                  <h4>Secuencia didáctica</h4>
              </div>
              <div className="print-section__row__col col-12">
                {planning.planningGptEdit && planning.planningGptEdit != '' ? (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: planning.planningGptEdit,
                    }}
                  ></div>
                ) : (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: whtml || `${planning.planningGpt}`,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default PlanningNemPrintComponent;
