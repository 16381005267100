import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import Swal from 'sweetalert2';
import { Accordion } from 'react-bootstrap';
import TimepickerComponent from '../../Form/timepicker/timepicker.component';
import TextareaComponent from '../../Form/textarea/textarea.component';
import MaterialItemAddedComponent from '../../Cards/materialAdded/materialAdded.component';
import './introduccion.component.scss';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { IPlanningSession } from '../../../types/PlaningInterface';
import {
  GenericResult,
  Logo,
  RecursoEducativo,
} from '../../../types/EntidadesInterface';

type Props = {
  session: Partial<IPlanningSession>;
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
  tiposRecurso: GenericResult;
  setSession: (
    key: keyof IPlanningSession,
    value: any
  ) => Partial<IPlanningSession>;
  updateSession: (newSession: Partial<IPlanningSession>) => Promise<void>;
  isSameUser: boolean;
  isValidSession: () => boolean;
  getTotalMinutes: (value: number) => number;
};

const IntroduccionComponent = ({
  session,
  recursos,
  tiposRecurso,
  setSession,
  updateSession,
  isSameUser,
  isValidSession,
  getTotalMinutes,
}: Props) => {
  const navigate = useNavigate();
  const { introduccion } = session;
  const introRecursos = (introduccion?.resources || []).map(
    (r) => r.documentId
  );
  const strapiRecursos = recursos.filter(
    (r) => introRecursos.indexOf(`${r.id}`) >= 0
  );
  return (
    <Accordion.Item eventKey="0" className="session-tab">
      <Accordion.Header>
        <div className="session-tab__toggle tab--color1">
          <h6>1. Inicio</h6>
          <p>
            {`${
              introduccion?.minutes
                ? format(fromUnixTime(introduccion.minutes), 'HH:mm')
                : ''
            }`}
          </p>
          <span className="material-icons-outlined icon">expand_more</span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="session-tab__open">
          <div className="session-tab__open-row row ">
            <div className="col-md-12 col-12">
              <div className="row m-0">
                <div className="col-md-4 col-12 col-reset">
                  <div className="form__row">
                    <label
                      htmlFor="description"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Tiempo estimado (hh:mm)
                      </div>
                      <OverlayComponent toolTipText="Añade el tiempo destinado a esta etapa de la sesión. Formato horas:minutos" />
                    </label>
                    <TimepickerComponent
                      disabled={!isSameUser}
                      currentValue={
                        introduccion?.minutes
                          ? fromUnixTime(introduccion.minutes)
                          : null
                      }
                      onTimeChange={(unix) => {
                        const newSession = setSession('introduccion', {
                          description: introduccion?.description || '',
                          minutes: unix,
                          totalMinutes: getTotalMinutes(unix),
                          resources: introduccion?.resources || [],
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-reset">
                  <div className="form__row">
                    <label
                      htmlFor="description"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Actividades a desarrollar
                      </div>
                      <OverlayComponent toolTipText="Describe las actividades que vas a desarrollar para esta etapa." />
                    </label>
                    <TextareaComponent
                      id="description"
                      placeholder="Descripción"
                      value={introduccion ? introduccion.description : ''}
                      readOnly={!isSameUser}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        setSession('introduccion', {
                          description:
                            (evt.target as HTMLTextAreaElement).value || '',
                          minutes: introduccion?.minutes || 0,
                          totalMinutes: getTotalMinutes(
                            introduccion?.minutes || 0
                          ),
                          resources: introduccion?.resources || [],
                        });
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = setSession('introduccion', {
                          description:
                            (evt?.target as HTMLTextAreaElement).value || '',
                          minutes: introduccion?.minutes || 0,
                          totalMinutes: getTotalMinutes(
                            introduccion?.minutes || 0
                          ),
                          resources: introduccion?.resources || [],
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isSameUser ? (
              <div className="session-tab__alert col-md-8 col-12">
                <div className="alert alert-neutral">
                  <p>
                    <strong>
                      <i className="fas fa-info-circle"></i> Tip.
                    </strong>{' '}
                    Incluye una pregunta detonadora al inicio de la sesión para
                    captar la atención de tus estudiantes e invitarlos a
                    participar.{' '}
                  </p>
                  <p>
                    <strong>
                      <i className="fas fa-info-circle"></i> Tip.
                    </strong>{' '}
                    Antes de abordar un tema nuevo, incluye una actividad para
                    saber cuánto conocen tus estudiantes del mismo o bien, si
                    cuentan con los conocimientos previos necesarios para pasar
                    al siguiente nivel.
                  </p>
                  <p>
                    <strong>
                      <i className="fas fa-info-circle"></i> Tip.
                    </strong>{' '}
                    Aquí podrás incluir una actividad disparadora del tema,
                    recuerda que debe incluir un ejercicio divertido para
                    generar interés en tus alumnos, podrás elegir entre todos
                    nuestros recursos digitales una que se adapte a tu contexto
                    educativo.{' '}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="session-tab__open-row row">
            {(strapiRecursos || []).map((recurso, k) => {
              const {
                attributes: {
                  Nombre,
                  aprendizajes,
                  fuente,
                  tipo_de_recursos: tipoDeRecurso,
                },
              } = recurso;
              const recursoFiltro = (tiposRecurso.data || []).filter(
                (tR) => tR.id === tipoDeRecurso.data[0].id
              );
              const logo = recursoFiltro.length
                ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                : null;
              return (
                <div className="session-tab__col col-lg-4  col-12" key={k}>
                  <div className="material-title">
                    <p>{`${Nombre}`}</p>
                  </div>
                  <MaterialItemAddedComponent
                    deleteLabel={isSameUser ? 'delete' : ''}
                    image={`${
                      logo ? logo.data.attributes.formats.thumbnail.url : ''
                    }`}
                    alt="Sheepy y Los Dragones"
                    name={`${
                      tipoDeRecurso.data.length
                        ? tipoDeRecurso.data
                            .map((tR) => tR.attributes.Nombre)
                            .join(', ')
                        : ''
                    }`}
                    type="Actividad"
                    aprendizaje={
                      aprendizajes.data.length
                        ? aprendizajes.data[0].attributes.Nombre
                        : ''
                    }
                    fuente={fuente ? fuente.data.attributes.Fuente : ''}
                    time="30 min"
                    url={
                      session._id && introduccion?._id
                        ? `/actividad/${recurso.id}/${session._id}/${introduccion?._id}`
                        : `/actividad/${recurso.id}`
                    }
                    onClickDelete={() => {
                      const currentRs = introduccion?.resources || [];
                      const index = currentRs.findIndex(
                        (r) => r.documentId === `${recurso.id}`
                      );
                      currentRs.splice(index, 1);
                      return Swal.fire({
                        icon: 'question',
                        title: 'Eliminar recurso',
                        text: `¿Deseas quitar este recurso de tu sesión?`,
                        showCancelButton: true,
                        confirmButtonText: 'Sí, quitar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const newSession = setSession('introduccion', {
                            description: introduccion?.description || '',
                            minutes: introduccion?.minutes || 0,
                            totalMinutes: getTotalMinutes(
                              introduccion?.minutes || 0
                            ),
                            resources: currentRs,
                          });
                          updateSession(newSession);
                        }
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          {isSameUser ? (
            <div className="session-tab__open-row row">
              <div className="col-12 form__action">
                <div className="col-12">
                  <button
                    className="btn btn--add"
                    onClick={(evt) => {
                      evt.preventDefault();
                      if (isValidSession()) {
                        navigate(
                          `/busqueda/${session._id}/${introduccion?._id}`
                        );
                      } else {
                        Swal.fire(
                          'Oops',
                          'Para agrear recursos, primero define el nombre y descripción de tu sesión',
                          'warning'
                        );
                      }
                    }}
                  >
                    <span className="material-icons-outlined">add</span>
                    <p>Busca recursos para agregar a tu planeación</p>
                  </button>
                </div>

                <div className="alert alert-simple">
                  <p>
                    Te puedes apoyar de un recurso digital como un video o un
                    juego. Qué mejor manera de empezar una clase que jugando.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default IntroduccionComponent;
