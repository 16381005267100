import React from 'react';
import './share.component.scss';

type Props = {
  totalShare?: number;
  checked?: boolean;
  disabled?: boolean;
  onCheck?: (event: React.FormEvent<HTMLInputElement> | undefined) => void;
};

const ShareComponent = ({ totalShare, onCheck, checked, disabled }: Props) => {
  return (
    <div className="check-like">
      <input
        type="checkbox"
        className="check-like__check"
        onClick={onCheck}
        checked={checked || false}
        disabled={disabled || false}
        title="Copiada"
      />
      <div className="check-like__change">
        <figure>
          <span className="material-icons-outlined check__inactive">
            copy_border
          </span>
          <span className="material-icons-outlined check__active">copy</span>
        </figure>
        <p>{Number(totalShare)}</p>
      </div>
    </div>
  );
};

export default ShareComponent;
