import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './answersContent.screen.scss';
import { getPecBook } from '../../../strapi/pecContent';
import { DatumBook } from '../../../types/PECInterface';

const AnswersContentScreen = () => {
  const { gradoId } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [books, setBooks] = useState<DatumBook[]>([]);
  const [nemBooks, setNemBooks] = useState<DatumBook[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchBooks = async () => {
    try {
      const query = {
        populate: '*',
        sort: ['bookName:asc'],
        filters: { grado: { id: { $eq: gradoId } } },
        pagination: { pageSize: 100 },
      };

      const booksResult = await getPecBook(qs.stringify(query));

      if (booksResult && booksResult.data && booksResult.data.length) {
        setBooks(booksResult.data.filter((book) => book.id < 823));
        setNemBooks(booksResult.data.filter((book) => book.id > 822));
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/respuestas-materia',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            if (gradoId) {
              setIsLoading(true);
              fetchBooks();
            }
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url="/respuestas-grado"
          back="Regresar"
          urlSecond="/respuestas-grado"
          urlSecondText={
            Number(gradoId || 0) > 6
              ? 'Respuestas Secundaria'
              : 'Respuestas Primaria'
          }
          urlThird={`/respuestas-materia/${gradoId}`}
          urlThirdText="Selecciona tu materia"
        />
        <section className="header-activity-banner">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">Respuestas a los libros SEP</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="container body-activity">
          <div className="row row--index">
            <figure className="activity-image">
              <img src="/assets/images/elements/taco.png" alt="Taco" />
            </figure>
            <div className="mx-auto col-md-8 col-12">
              <section className="body-activity__content">
                <article className="header-activity__intro">
                  {books[0] && (
                    <>
                      <p>
                        {books[0].attributes.grado.data.attributes.Grado}{' '}
                        {' - '}
                        {Number(gradoId || 0) > 6 ? 'Secundaria' : 'Primaria'}
                      </p>
                    </>
                  )}
                  <h2 className="h4">Selecciona tu materia</h2>
                </article>
                <section className="body-activity__grades col-12">
                  <div className="grid-grades row mb-4">
                    {nemBooks && nemBooks.length ? (
                      nemBooks.map((book, key) => {
                        const {
                          id: bookId,
                          attributes: { bookName, bookImage },
                        } = book;
                        return (
                          <div
                            className="grid-choose__content col-lg-6 col-12"
                            key={key}
                          >
                            <div className="grid-choose__content-inner">
                              <button
                                className="grid-choose__material"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/actividad-indice/${gradoId}/${bookId}`
                                  );
                                }}
                              >
                                {bookImage.data ? (
                                  <figure className="material-book">
                                    <img
                                      src={bookImage.data.attributes.url}
                                      alt={`${bookName || ''}`}
                                    />
                                  </figure>
                                ) : (
                                  <></>
                                )}
                                <article>
                                  <p>{`${bookName || ''}`}</p>
                                  <span className="material-icons-outlined icon">
                                    navigate_next
                                  </span>
                                </article>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="grid-grades row">
                    {books && books.length ? (
                      books.map((book, key) => {
                        const {
                          id: bookId,
                          attributes: { bookName, bookImage },
                        } = book;
                        return (
                          <div
                            className="grid-choose__content col-lg-6 col-12"
                            key={key}
                          >
                            <div className="grid-choose__content-inner">
                              <button
                                className="grid-choose__material"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/actividad-indice/${gradoId}/${bookId}`
                                  );
                                }}
                              >
                                {bookImage.data ? (
                                  <figure className="material-book">
                                    <img
                                      src={bookImage.data.attributes.url}
                                      alt={`${bookName || ''}`}
                                    />
                                  </figure>
                                ) : (
                                  <></>
                                )}
                                <article>
                                  <p>{`${bookName || ''}`}</p>
                                  <span className="material-icons-outlined icon">
                                    navigate_next
                                  </span>
                                </article>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>
                        <strong>404</strong> No hay contenido disponible
                      </p>
                    )}
                  </div>
                </section>
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default AnswersContentScreen;
