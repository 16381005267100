import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import './activityIntro.screen.scss';
import { DatumBook, DatumBookSection } from '../../types/PECInterface';
import { getPecBookById, getPecBookSection } from '../../strapi/pecContent';

const ActivityIntroScreen = () => {
  const { gradoId, bookId } = useParams();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [book, setBook] = useState<Partial<DatumBook>>({});
  const [bookSections, setBookSections] = useState<
    {
      bloqueId: number;
      bloque: string;
      sections: DatumBookSection[];
    }[]
  >([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchPecSections = async (selectedBookId: number) => {
    try {
      const query = {
        populate: '*',
        filters: { pec_book: { id: { $eq: selectedBookId } } },
        pagination: { pageSize: 100, page: 1 },
      };
      const result = await getPecBookSection(qs.stringify(query));
      if (result && result.meta.pagination.pageCount > 1) {
        const result2 = await getPecBookSection(qs.stringify({...query, pagination: { pageSize: 100, page: 2 }}));
        result.data.push( ...result2.data.map((s) => s));
      }
      if (result) {
        const { data } = result;
        if (data && data.length) {
          const bloques = [
            ...new Set(
              data
                .map((section) => {
                  const {
                    attributes: { unitId },
                  } = section;
                  return unitId;
                })
                .sort((a, b) => a - b)
            ),
          ];
          if (bloques.length) {
            const sectionsByBlocks = bloques.map((bloque) => {
              const sections = data
                .filter((s) => s.attributes.unitId === bloque)
                .sort((a, b) =>
                  a.attributes.oldId > b.attributes.oldId ? 1 : -1
                );
              return {
                bloqueId: bloque,
                bloque: sections.length ? sections[0].attributes.unitLabel : '',
                sections,
              };
            });
            setBookSections(sectionsByBlocks);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
  };

  const fetchPecBook = async (selectedBookId: string) => {
    try {
      const result = await getPecBookById(selectedBookId);
      if (result) {
        const { data } = result;
        if (data && data) {
          setBook(data);
          fetchPecSections(data.id);
          trackEvent({
            event: 'pageView',
            page: '/actividad-indice',
            fullPath: window.location.href,
            bookName: data.attributes?.bookName,
            gradoId
          });
        }
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            if (bookId) {
              setIsLoading(true);
              fetchPecBook(bookId);
            }
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/respuestas-materia/${gradoId}`}
          back="Regresar"
          urlSecond={`/respuestas-materia/${gradoId}`}
          urlSecondText="Selecciona tu materia"
          urlThird={`/actividad-indice/${gradoId}/${bookId}`}
          urlThirdText={`${book.attributes?.bookName}`}
        />
        <section className="container body-activity">
          <div className="row row--index">
            <div className="mx-auto col-md-10 col-12">
              {book && book.attributes ? (
                <section className="header-activity">
                  {book.attributes.bookImage.data ? (
                    <figure className="header-activity__book">
                      <img
                        src={`${book.attributes.bookImage.data.attributes.url}`}
                        alt={`${book.attributes.bookName}`}
                      />
                    </figure>
                  ) : (
                    <></>
                  )}
                  <article className="header-activity__text-head">
                    <p>
                      { Number(gradoId || 0) > 6
                        ? 'Secundaria. '
                        : 'Primaria. '}
                      {`${book.attributes.grado.data.attributes.Grado}`}
                    </p>
                    <h1 className="header-activity__title h3">
                      {`${book.attributes.bookName}`}
                    </h1>
                  </article>
                </section>
              ) : (
                <></>
              )}
              <section className="body-activity__content">
                {bookSections.length ? (
                  bookSections.map((bS, key) => {
                    return (
                      <div className="block-activity" key={key}>
                        <div className="block-activity__item-head">
                          <h2 className="h4">{`${bS.bloque}`}</h2>
                        </div>
                        {bS.sections.map((section, k) => {
                          const {
                            attributes: { bookSectionName, pagesInclude },
                          } = section;
                          const pages = (pagesInclude || '').split(' - ');
                          return (
                            <div className="block-activity__item" key={k}>
                              <button
                                className="modal__button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/actividad-pec/${section.attributes.pec_book.data.id}/${section.id}/${pages[0]}`
                                  );
                                }}
                              >
                                <p>{`${bookSectionName}`}</p>
                                <small>{`Páginas ${pagesInclude || ''}`}</small>
                                <span className="material-icons-outlined icon">
                                  navigate_next
                                </span>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default ActivityIntroScreen;
