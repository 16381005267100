import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import track from 'react-tracking';
import './index.css';
import './datadogRum';

import App from './App';
import { createSessionEvent } from './api/sessionEventsApi';

const TrackedApp = track(
  // app-level tracking data
  { app: 'kame-docente-website' },
  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      console.log(data);
      ((window as any).dataLayer = (window as any).dataLayer || []).push(data);
      createSessionEvent(data.event, { ...data });
    },
  }
)(App);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <TrackedApp />
  </BrowserRouter>
);
