import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  EmailIcon,
  EmailShareButton,
} from 'react-share';
import { useTracking } from 'react-tracking';
import { IPlanning } from '../../../types/PlaningInterface';

type Props = {
  planning: Partial<IPlanning>;
  show: boolean;
  size: string;
  onHide: () => void;
};

const ModalSharePlanning = ({ planning, show, size, onHide }: Props) => {
  const { trackEvent } = useTracking();
  const iconSize = 60;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size as any}
      aria-labelledby="modal-options"
      centered
      className="modal--options modal--left"
    >
      <Modal.Header className="modal__title-icon">
        <Modal.Title id="modal-options">
          <h4>Compartir mi planeación:</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="share-col col-3">
            <FacebookShareButton
              quote={`Te invito a conocer la planeación que hice en la plataforma PACO Teacher`}
              url={`https://pacoteacher.com/sesion-compartida/${planning._id}`}
              onClick={() => {
                trackEvent({
                  event: 'planningShare',
                  page: '/planeacion-sesiones',
                  media: 'Facebook',
                });
              }}
            >
              <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
          </div>
          <div className="share-col col-3">
            <TwitterShareButton
              title={`Te invito a conocer la planeación que hice en la plataforma PACO Teacher`}
              url={`https://pacoteacher.com/sesion-compartida/${planning._id}`}
              onClick={() => {
                trackEvent({
                  event: 'planningShare',
                  page: '/planeacion-sesiones',
                  media: 'Twitter',
                });
              }}
            >
              <TwitterIcon size={iconSize} round />
            </TwitterShareButton>
          </div>
          <div className="share-col col-3">
            <WhatsappShareButton
              title={`Te invito a conocer la planeación que hice en la plataforma PACO Teacher`}
              url={`https://pacoteacher.com/sesion-compartida/${planning._id}`}
              separator=""
              onClick={() => {
                trackEvent({
                  event: 'planningShare',
                  page: '/planeacion-sesiones',
                  media: 'WhatsApp',
                });
              }}
            >
              <WhatsappIcon size={iconSize} round />
            </WhatsappShareButton>
          </div>
          <div className="share-col col-3" 
            onClick={() => {
              trackEvent({
                event: 'planningShare',
                page: '/planeacion-sesiones',
                media: 'Email',
              });
            }}>
            <EmailShareButton
              subject={`Te invito a conocer la planeación que hice en la plataforma PACO Teacher`}
              url={`https://pacoteacher.com/sesion-compartida/${planning._id}`}
            >
              <EmailIcon size={iconSize} round />
            </EmailShareButton>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn--type-outline btn--small "
          onClick={(evt) => {
            evt.preventDefault();
            onHide();
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSharePlanning;
