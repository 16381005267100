import qs from 'qs';
import api from './index';
import { IPlanningFavorite, ResultPaginate } from '../types/PlaningInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createPlanningFavorite = async (
  planningFavorite: Partial<IPlanningFavorite>,
  token: string
) => {
  console.log('------ createPlanningFavorite ------');
  const response = await api.post<IPlanningFavorite>(
    '/planningFavorites',
    planningFavorite,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPlanningFavorite = async (id: string, token: string) => {
  console.log('------ getPlanningFavorite ------', { id, token });
  const response = await api.get<IPlanningFavorite>(
    `/planningFavorites/${id}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchPlanningFavorite = async (
  query: Partial<IPlanningFavorite>,
  token: string
) => {
  console.log('------ searchPlanningFavorite ------');
  const response = await api.post<ResultPaginate>(
    `/planningFavorites/search?${qs.stringify({ limit: 50 })}`,
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
