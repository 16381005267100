import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './testAnswersContent.screen.scss';
import { getPecExam } from '../../../strapi/pecContent';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import { Grado } from '../../../types/EntidadesInterface';

const TestAnswersContentScreen = () => {
  const { gradoId } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [materia, setMateria] = useState<string[]>([]);
  const [grados, setGrado] = useState<{ id: number; attributes: Grado }[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchGrado = async () => {
    const query = {
      fields: ['Grado'],
      filters: {  id: { $eq: gradoId } },
    };
    const resultGrados =  await getStrapiCatalog(qs.stringify({ ...query, sort: ['id:asc'] }), '/grados')
    setGrado(
      resultGrados.data as {
        id: number;
        attributes: Grado;
      }[]
    );
  };

  const fetchExamMateria = async () => {
    try {
      const materias: string[] = [];
      const query = {
        fields: ['subjectTitle'],
        sort: ['subjectTitle'],
        filters: { grado: { id: { $eq: gradoId } } },
        pagination: { pageSize: 100, page: 1 },
      };
      const pages = [1, 2, 3];
      for await (const page of pages) {
        const materiasResult = await getPecExam(
          qs.stringify({
            ...query, 
            pagination: {
              ...query.pagination,
              page,
            },
        }));
        if (materiasResult && materiasResult.data && materiasResult.data.length) {
          materias.push(
            ...materiasResult.data.map(
              (subject) => subject.attributes.subjectTitle
            )
          );
        }
      }
      const uniques = [...new Set(materias)];
      setMateria(uniques);
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/ponte-a-prueba-materia',
      fullPath: window.location.href,
      gradoId,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            if (gradoId) {
              setIsLoading(true);
              fetchExamMateria();
              fetchGrado();
            }
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url="/ponte-a-prueba-grados"
          back="Regresar"
          urlInit="/ponte-a-prueba-grados"
          urlInitText="Grados"
          urlSecond={`/ponte-a-prueba-materia/${gradoId}`}
          urlSecondText="Selecciona tu materia"
        />
         <section className="header-activity-banner">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">Reactivos para exámenes</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="container body-activity">
          <div className="row row--index">
            <figure className="activity-image">
              <img src="/assets/images/elements/taco.png" alt="Arti" />
            </figure>
            <div className="mx-auto col-md-8 col-12">
             
              
              <section className="body-activity__content">
                 <article className="header-activity__intro">
                  <p>{grados[0]?.attributes.Grado}</p>
                  <h2 className="h4">Selecciona tu materia</h2>
                </article>
                <section className="body-activity__grades col-12">
                  <div className="grid-grades row">
                    {materia && materia.length ? (
                      materia.map((materia, key) => {
                        return (
                          <div
                            className="grid-choose__content col-lg-6 col-12"
                            key={key}
                          >
                            <div className="grid-choose__content-inner">
                              <button
                                className="grid-choose__material"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/ponte-a-prueba-tipo/${gradoId}/${materia}`
                                  );
                                }}
                              >
                                <article>
                                  <p>{`${materia || ''}`}</p>
                                  <span className="material-icons-outlined icon">
                                    navigate_next
                                  </span>
                                </article>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>
                        <strong>404</strong> No hay contenido disponible
                      </p>
                    )}
                  </div>
                </section>
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default TestAnswersContentScreen;
