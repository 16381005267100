import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import ButtonType1Component from '../../../components/Button/buttonType1/buttonType1.component';
import PlaneacionItemComponent from '../../../components/Cards/planeacion/planeacion.component';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';
import './dashboard.screen.scss';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { searchPlanning } from '../../../api/planningsApi';
import { IPlanning, ResultPaginate } from '../../../types/PlaningInterface';
import { createPlanningFavorite } from '../../../api/planningFavoritesApi';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import { searchSignUpUser } from '../../../api/sessionEventsApi';
import {
  IFlexiblePlanning,
  ResultPaginateFlexible,
} from '../../../types/FlexiblePlanningInterface';
import { searchFlexiblePlanning } from '../../../api/flexiblePlanningsApi';
import { createFlexiblePlanningFavorite } from '../../../api/flexiblePlanningFavoritesApi';

const DashboardScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [plannings, setPlannings] = useState<ResultPaginate>({
    docs: [],
    offset: 0,
    limit: 30,
  });
  const [planningsFlex, setplanningsFlex] = useState<ResultPaginateFlexible>({
    docs: [],
    offset: 0,
    limit: 30,
  });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const setFavorite = async (planning: IPlanning, checked: boolean) => {
    const result = await createPlanningFavorite(
      { userId: user._id, planningId: planning._id, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  const setFavoriteFlex = async (
    planning: IFlexiblePlanning,
    checked: boolean
  ) => {
    const result = await createFlexiblePlanningFavorite(
      { userId: user._id, planningId: planning._id, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({
      event: 'pageView',
      page: '/mis-planeaciones',
      fullPath: window.location.href,
    });
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              searchSignUpUser(kameUserId, jwtToken);
              setUser({ ...kameUser, token: jwtToken });
              searchPlanning(
                { userId: kameUser._id, myUserId: kameUser._id },
                jwtToken
              ).then((response) => {
                if (response) {
                  console.log(response);
                  setPlannings(response);
                }
              });
              searchFlexiblePlanning(
                { userId: kameUser._id, myUserId: kameUser._id },
                jwtToken
              ).then((response) => {
                if (response) {
                  console.log(response);
                  setplanningsFlex(response);
                }
              });
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="app-section">
      <HeaderLoginComponent />
      <ModalTooltipComponent
        title="¿Tienes dudas de cómo usar la plataforma?"
        description="Puedes ir a la sección de <a href='/ayuda'>ayuda</a> donde encontrarás tutoriales y <a href='/preguntas-frecuentes'>preguntas frecuentes</a> que te serán de utilidad."
      />
      <div className="app-section__top">
        <section className="module">
          <div className="container container-materials">
            <div className="container-materials__top-row row">
              <div className="container-materials__cta col-md-12 col-6">
                <ButtonType1Component
                  url="/planeacion-flexible"
                  name="Crear planeación +"
                ></ButtonType1Component>
              </div>
              <div className="module__title title--action col-md-12 col-6">
                <h2>Mis planeaciones guardadas</h2>
              </div>
            </div>
            <div className="container-materials__row row">
              <div className="mx-auto col-md-6 col-12">
                <SearchSelectComponent
                  options={((planningsFlex.docs as IPlanning[]) || []).map(
                    (planning) => ({
                      value: `${planning._id}`,
                      label: planning.name,
                    })
                  )}
                  onChange={(newValue) => {
                    navigate(`/planeacion-sesiones/${newValue?.value}`);
                  }}
                />
                <div className="search-result">
                  {(plannings.docs.length || planningsFlex.docs.length) > 0 ? (
                    <p>
                      Mostrando{' '}
                      <strong>{`${
                        plannings.docs.length + planningsFlex.docs.length || 0
                      }`}</strong>{' '}
                      {plannings.docs.length + planningsFlex.docs.length > 1 ? (
                        <>planeaciones disponibles</>
                      ) : (
                        <>planeación disponible</>
                      )}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="container-materials__row row">
              {(planningsFlex.docs || []).map((planning, key) => {
                const {
                  name,
                  isPublic,
                  asignaturas,
                  gradoEscolar,
                  favorites,
                  myFavorite,
                  copyCount,
                } = planning as IFlexiblePlanning;
                return (
                  <div
                    className="container-materials__col col-md-3 col-6"
                    key={key}
                  >
                    <PlaneacionItemComponent
                      type={`${isPublic ? 'comunidad' : 'propias'}`}
                      classTitle={``}
                      plan={`${name}`}
                      grade={`${gradoEscolar?.value || ''}`}
                      url={`/planeacion-flexible/${planning._id}`}
                      totalFavs={favorites}
                      totalShare={copyCount}
                      hideShare={!isPublic}
                      asignaturas={
                        asignaturas
                          ? asignaturas
                              .map((as) => as.asignatura?.value)
                              .join(', ')
                          : ''
                      }
                      user={user}
                      checked={Number(myFavorite) >= 1}
                      onCheck={(evt) => {
                        evt?.preventDefault();
                        const checked = (evt?.target as any).checked;
                        const planningDocs = [
                          ...(planningsFlex.docs as IFlexiblePlanning[]),
                        ];
                        const currentFavs = Number(planningDocs[key].favorites);
                        planningDocs[key].myFavorite = checked ? 1 : 0;
                        planningDocs[key].favorites = checked
                          ? currentFavs + 1
                          : currentFavs - 1;

                        setFavoriteFlex(
                          planning as IFlexiblePlanning,
                          checked
                        ).then(() => {
                          setplanningsFlex({
                            ...plannings,
                            docs: planningDocs,
                          });
                        });
                      }}
                    />
                  </div>
                );
              })}
              {(plannings.docs || []).map((planning, key) => {
                const {
                  name,
                  asignatura: { value: asignatura },
                  gradoEscolar: { value: gradoEscolar },
                  favorites,
                  myFavorite,
                  copyCount,
                  isPublic,
                  aprendizajes,
                } = planning as IPlanning;
                return (
                  <div
                    className="container-materials__col col-md-3 col-6"
                    key={key}
                  >
                    <PlaneacionItemComponent
                      type={`${isPublic ? 'comunidad' : 'old'}`}
                      classTitle={`${asignatura}`}
                      plan={`${name}`}
                      grade={`${gradoEscolar}`}
                      url={`/sesion-compartida/${planning._id}`}
                      totalFavs={favorites}
                      totalShare={copyCount}
                      hide={true}
                      hideShare={!isPublic}
                      checked={Number(myFavorite) >= 1}
                      onCheck={(evt) => {
                        evt?.preventDefault();
                        const checked = (evt?.target as any).checked;
                        const planningDocs = [
                          ...(plannings.docs as IPlanning[]),
                        ];
                        const currentFavs = Number(planningDocs[key].favorites);
                        planningDocs[key].myFavorite = checked ? 1 : 0;
                        planningDocs[key].favorites = checked
                          ? currentFavs + 1
                          : currentFavs - 1;

                        setFavorite(planning as IPlanning, checked).then(() => {
                          setPlannings({
                            ...plannings,
                            docs: planningDocs,
                          });
                        });
                      }}
                      aprendizajes={
                        aprendizajes
                          ? aprendizajes.map((ap) => ap.value).join(',')
                          : ''
                      }
                      user={user}
                    />
                  </div>
                );
              })}
            </div>
            {/**
             * <div className="row">
              <div className="module__action col-12">
                <ButtonTypeOutlineComponent url="/" name="Cargar más (4)" />
              </div>
            </div>
             */}
          </div>
        </section>
      </div>

      <FooterComponent />
    </section>
  );
};

export default DashboardScreen;
