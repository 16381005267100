import qs from 'qs';
import api from './index';
import {
  IPlanning,
  IPlanningQuery,
  ResultPaginate,
} from '../types/PlaningInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createPlanning = async (
  planning: Partial<IPlanning>,
  token: string
) => {
  console.log('------ createPlanning ------');
  const response = await api.post<IPlanning>('/plannings', planning, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPlanning = async (id: string, token: string) => {
  console.log('------ getPlanning ------', { id, token });
  const response = await api.get<IPlanning>(`/plannings/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  })
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const deletePlanning = async (id: string, token: string) => {
  console.log('------ deletePlanning ------', { id, token });
  const response = await api.delete<IPlanning>(`/plannings/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updatePlanning = async (
  id: string,
  planning: Partial<IPlanning>,
  token: string
) => {
  console.log('------ updatePlanning ------');
  const response = await api.patch<IPlanning>(`/plannings/${id}`, planning, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchPlanning = async (
  query: Partial<IPlanningQuery>,
  token: string
) => {
  console.log('------ searchPlanning ------');
  const response = await api.post<ResultPaginate>(
    `/plannings/search?${qs.stringify({ limit: 50 })}`,
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
