import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { MeiliSearch } from 'meilisearch';
import { useTracking } from 'react-tracking';
import '../../pages/Materials/plannings/plannings.screen.scss';
import { IUser } from '../../types/UserInterface';
import { IPlanning } from '../../types/PlaningInterface';
import { IFlexiblePlanning } from '../../types/FlexiblePlanningInterface';
import {
  GenericResult,
  ModeloEducativoData,
} from '../../types/EntidadesInterface';
import { getStrapiCatalog } from '../../strapi/escuelas';
import { searchPlanning } from '../../api/planningsApi';
import { searchFlexiblePlanning } from '../../api/flexiblePlanningsApi';
import { createPlanningFavorite } from '../../api/planningFavoritesApi';
import { createFlexiblePlanningFavorite } from '../../api/flexiblePlanningFavoritesApi';
import CheckboxComponent from '../Form/checkbox/checkbox.component';
import PlaneacionItemComponent from '../Cards/planeacion/planeacion.component';

const client = new MeiliSearch({
  host: 'https://meilisearch.a22.mx',
  apiKey: '6530efa95fd438f33b2b5dae0b02f440a815152bb0c651cbc64dd2c2845c4d94',
});

type Props = {
  user: Partial<IUser>;
};

const CommunityComponent = ({ user }: Props) => {
  const { trackEvent } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [plannings, setPlannings] = useState<{
    docs: Partial<IPlanning>[];
    offset: number;
    limit: number;
  }>({
    docs: [],
    offset: 0,
    limit: 30,
  });
  const [flexiblePlannings, setFlexiblePlannings] = useState<{
    docs: Partial<IFlexiblePlanning>[];
    offset: number;
    limit: number;
  }>({
    docs: [],
    offset: 0,
    limit: 30,
  });
  const [filtros, setFiltros] = useState<{
    materias: GenericResult;
    modelosEducativo: GenericResult;
    grados: GenericResult;
  }>({
    materias: { data: [] },
    modelosEducativo: { data: [] },
    grados: { data: [] },
  });
  const [activeFilters, setActiveFilters] = useState<{
    materias: string[];
    modelosEducativo: string[];
    grados: string[];
  }>({ materias: [], modelosEducativo: [], grados: [] });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getFiltros = async () => {
    try {
      const materias = await getStrapiCatalog(
        qs.stringify({
          sort: ['Materia:asc'],
          populate: ['modelo_educativo'],
        }),
        '/materias'
      );
      const modelosEducativo = await getStrapiCatalog(
        qs.stringify({
          sort: ['modelo_educativo:asc'],
          populate: '*',
          filters: {
            id: {
              $in: [1,2],
            },
          },
        }),
        '/modelos-educativos'
      );
      const grados = await getStrapiCatalog(
        qs.stringify({
          sort: ['id:asc'],
          filters: { level: { $eq: 'Primaria' } },
        }),
        '/grados'
      );
      setFiltros({ materias, modelosEducativo, grados });
    } catch (error) {
      setError(`${error}`);
    }
  };

  const queryPlannings = async (
    _activeFilters: {
      materias: string[];
      modelosEducativo: string[];
      grados: string[];
    },
    match: string,
    kameUserId: string
  ) => {
    try {
      const ands: any[] = [];
      const { materias, modelosEducativo, grados } = _activeFilters;
      [
        { filtro: materias, label: 'materia' },
        { filtro: modelosEducativo, label: 'modelo_educativo' },
        { filtro: grados, label: 'grado' },
      ].forEach(({ filtro, label }) => {
        if (filtro.length) {
          filtro.forEach((f) => {
            ands.push(`${label}.id = ${f}`);
          });
        }
      });

      const meiliResult = await client
        .index('planeaciones-de-la-comunidad')
        .search(match, {
          limit: 300,
          filter: [ands.join(' OR ')],
        });

      const planningIds = meiliResult.hits.map((hit) => `${hit.planningId}`);
      const apiResutl = await searchPlanning(
        {
          isPublic: true,
          notUserId: kameUserId,
          myUserId: kameUserId,
          planningIds,
        },
        `${user.token}`
      );

      setPlannings({
        docs: (apiResutl.docs || []) as IPlanning[],
        offset: apiResutl.offset || 0,
        limit: apiResutl.limit || 30,
      });

      const fpApiResult = await searchFlexiblePlanning(
        {
          isPublic: true,
          notUserId: kameUserId,
          myUserId: kameUserId,
          planningIds,
        },
        `${user.token}`
      );
      setFlexiblePlannings({
        docs: fpApiResult.docs || [],
        offset: fpApiResult.offset || 0,
        limit: fpApiResult.limit || 30,
      });

      trackEvent({
        event: 'planningSearch',
        page: '/planeaciones/reformas-anteriores',
        match,
        filters: ands,
        results: apiResutl.docs.length,
        time: meiliResult.processingTimeMs,
      });
    } catch (error) {
      setError(`${error}`);
    }
  };

  const setFavorite = async (planning: IPlanning, checked: boolean) => {
    if (user && user._id) {
      trackEvent({
        event: 'planningLike',
        page: '/planeaciones/reformas-anteriores',
        like: checked,
        planningId: planning._id,
      });
      const result = await createPlanningFavorite(
        { userId: user._id, planningId: planning._id, isFavorite: checked },
        `${user.token}`
      );
      return result;
    }
    return null;
  };

  const setFavoriteFlex = async (
    planning: Partial<IFlexiblePlanning>,
    checked: boolean
  ) => {
    trackEvent({
      event: 'planningLike',
      page: '/planeaciones/reformas-anteriores',
      like: checked,
      planningId: planning._id,
    });
    const result = await createFlexiblePlanningFavorite(
      { userId: user._id, planningId: planning._id, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeaciones/reformas-anteriores',
      fullPath: window.location.href,
    });
    if (user) {
      getFiltros();
      queryPlannings(activeFilters, '', user?._id || '');
    } else {
      getFiltros();
      queryPlannings(activeFilters, '', '');
    }
  }, []);

  return (
    <section>
      <div className="container container-materials">
        <div className="planning-desc row">
          <div className="col-12">
            <div className="tab-change">
              <Link to="/planeaciones/nueva-escuela-mexicana">
                Nueva Escuela Mexicana
              </Link>
              <Link to="/planeaciones/reformas-anteriores" className="active">
                Reformas 2011 y 2017
              </Link>
            </div>
          </div>
        </div>
        <div className="container-materials__row row">
          <div className="mx-auto col-md-6 col-12">
            <div className="search-select">
              <span className="material-icons-outlined search-select__icon">
                search
              </span>
              <input
                type="text"
                className="form-control react-select-container"
                value={searchText}
                placeholder="Busca planeaciones para descargar..."
                onChangeCapture={(evt) => {
                  evt.preventDefault();
                  const newValue = (evt.target as HTMLInputElement).value;
                  setSearchText(newValue);
                  if (newValue && newValue.length > 3) {
                    queryPlannings(activeFilters, newValue, `${user._id}`);
                  }
                }}
              />
              <p className="mt-4">
                <strong>
                  Encuentra planeaciones de otros docentes que puedan
                  inspirarte. Escribe la palabra del concepto o tema clave que
                  te interese explorar.
                </strong>
              </p>
            </div>
            <div className="search-result text-start">
              {flexiblePlannings.docs.length > 0 ? (
                <p>
                  Mostrando{' '}
                  <strong>{`${
                    (plannings.docs.length || 0) +
                    (flexiblePlannings.docs.length || 0)
                  }`}</strong>{' '}
                  {flexiblePlannings.docs.length > 1 ? (
                    <>planeaciones para descargar disponibles</>
                  ) : (
                    <>planeación para descargar disponible</>
                  )}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="mx-auto col-md-6 col-12">
            <div className="search__engine-sortable">
              <button
                className="btn btn--filter"
                onClick={() => setIsOpen(!isOpen)}
              >
                Filtros de búsqueda{' '}
                <span className="material-icons-outlined">filter_alt</span>
              </button>
              <div
                className="filter-open-container"
                data-open={`${isOpen ? 'active' : ''}`}
              >
                <button
                  className="btn--simple-filter"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setIsOpen(!isOpen);
                  }}
                >
                  Cancelar
                </button>
                <div className="filter-open-contaner__inner">
                  <div className="filter-open-container__row">
                    <h6>Modelo educativo</h6>
                    {(filtros.modelosEducativo.data || []).map(
                      (modeloE, key) => (
                        <CheckboxComponent
                          key={key}
                          text={`${
                            (modeloE.attributes as any).modelo_educativo
                          }`}
                          id={`modeloE-${modeloE.id}`}
                          htmlFor={`modeloE-${modeloE.id}`}
                          checked={
                            activeFilters.modelosEducativo.indexOf(
                              `${modeloE.id}`
                            ) >= 0
                          }
                          onChangeEvent={(checked) => {
                            const activeF = { ...activeFilters };
                            if (checked) {
                              activeF.modelosEducativo = [`${modeloE.id}`];
                              setActiveFilters({ ...activeF });
                            } else {
                              const index = activeF.modelosEducativo.findIndex(
                                (f) => f === `${modeloE.id}`
                              );
                              activeF.modelosEducativo.splice(index, 1);
                              setActiveFilters({ ...activeF });
                            }
                          }}
                        />
                      )
                    )}
                  </div>
                  {activeFilters.modelosEducativo.length ? (
                    <div className="filter-open-container__row">
                      <h6>Materia</h6>
                      <div className="row">
                        {(filtros.materias.data || [])
                          .filter(
                            (materia) =>
                              (
                                (materia.attributes as any).modelo_educativo
                                  .data as ModeloEducativoData
                              ).id === Number(activeFilters.modelosEducativo[0])
                          )
                          .map((materia, key) => (
                            <div className="col-md-6 col-12" key={key}>
                              <CheckboxComponent
                                text={`${(materia.attributes as any).Materia}`}
                                id={`materia-${materia.id}`}
                                htmlFor={`materia-${materia.id}`}
                                checked={
                                  activeFilters.materias.indexOf(
                                    `${materia.id}`
                                  ) >= 0
                                }
                                onChangeEvent={(checked) => {
                                  const activeF = { ...activeFilters };
                                  if (checked) {
                                    activeF.materias.push(`${materia.id}`);
                                    setActiveFilters({ ...activeF });
                                  } else {
                                    const index = activeF.materias.findIndex(
                                      (f) => f === `${materia.id}`
                                    );
                                    activeF.materias.splice(index, 1);
                                    setActiveFilters({ ...activeF });
                                  }
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="filter-open-container__row">
                    <h6>Grado escolar</h6>
                    <div className="filter-open-container__multiple row">
                      {(filtros.grados.data || []).map((grado, key) => (
                        <div className="mb-3 col-md-4 col-12" key={key}>
                          <CheckboxComponent
                            text={`${(grado.attributes as any).Grado}`}
                            id={`grado-${grado.id}`}
                            htmlFor={`grado-${grado.id}`}
                            checked={
                              activeFilters.grados.indexOf(`${grado.id}`) >= 0
                            }
                            onChangeEvent={(checked) => {
                              const activeF = { ...activeFilters };
                              if (checked) {
                                activeF.grados.push(`${grado.id}`);
                                setActiveFilters({ ...activeF });
                              } else {
                                const index = activeF.grados.findIndex(
                                  (f) => f === `${grado.id}`
                                );
                                activeF.grados.splice(index, 1);
                                setActiveFilters({ ...activeF });
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="filter-open-container__action">
                    <button
                      type="button"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsOpen(!isOpen);
                        const newF = {
                          materias: [],
                          grados: [],
                          modelosEducativo: [],
                        };
                        setActiveFilters(newF);
                        queryPlannings(activeFilters, '', `${user._id}`);
                      }}
                      className="btn btn--type-outline"
                    >
                      Limpiar filtros
                    </button>
                    <button
                      type="button"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsOpen(!isOpen);
                        queryPlannings(activeFilters, '', `${user._id}`);
                      }}
                      className="btn btn--type3"
                    >
                      Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-materials__row row">
          {flexiblePlannings.docs.map((planning, key) => {
            const {
              userId,
              name,
              asignaturas,
              gradoEscolar,
              favorites,
              copyCount,
              myFavorite,
            } = planning;
            return (
              <div
                className="container-materials__col col-md-3 col-6"
                key={key}
              >
                <PlaneacionItemComponent
                  type="comunidad"
                  classTitle={''}
                  plan={`${name}`}
                  grade={`${gradoEscolar?.value}`}
                  url={`/planeacion-flexible-vista-previa/${planning._id}`}
                  totalFavs={favorites}
                  totalShare={copyCount}
                  checked={Number(myFavorite) >= 1}
                  onCheck={(evt) => {
                    evt?.preventDefault();
                    const checked = (evt?.target as any).checked;
                    const planningDocs = [...flexiblePlannings.docs];
                    const currentFavs = Number(planningDocs[key].favorites);
                    planningDocs[key].myFavorite = checked ? 1 : 0;
                    planningDocs[key].favorites = checked
                      ? currentFavs + 1
                      : currentFavs - 1;
                    setFavoriteFlex(planning, checked).then((response) => {
                      if (response) {
                        setFlexiblePlannings({
                          ...plannings,
                          docs: planningDocs,
                        });
                      }
                    });
                  }}
                  // aprendizajes={asignaturas
                  //   ?.map((a) =>
                  //     a.aprendizajes?.map((ap) => ap.value).join(', ')
                  //   )
                  //   .join(', ')}
                  asignaturas={
                    asignaturas
                      ? asignaturas.map((as) => as.asignatura?.value).join(', ')
                      : ''
                  }
                  user={user}
                  authorId={userId}
                />
              </div>
            );
          })}
          {plannings.docs.map((planning, key) => {
            const {
              name,
              asignatura: { value: asignatura },
              gradoEscolar: { value: gradoEscolar },
              favorites,
              myFavorite,
              copyCount,
              aprendizajes,
            } = planning as IPlanning;
            return (
              <div
                className="container-materials__col col-md-3 col-6"
                key={key}
              >
                <PlaneacionItemComponent
                  type="comunidad-old"
                  classTitle={`${asignatura}`}
                  plan={`${name}`}
                  grade={`${gradoEscolar}`}
                  url={
                    user.email !== ''
                      ? `/sesion-compartida/${planning._id}`
                      : `/sesion-compartida/${planning._id}`
                  }
                  totalFavs={favorites}
                  totalShare={copyCount}
                  checked={Number(myFavorite) >= 1}
                  onCheck={(evt) => {
                    evt?.preventDefault();
                    const checked = (evt?.target as any).checked;
                    const planningDocs = [...plannings.docs];
                    const currentFavs = Number(planningDocs[key].favorites);
                    planningDocs[key].myFavorite = checked ? 1 : 0;
                    planningDocs[key].favorites = checked
                      ? currentFavs + 1
                      : currentFavs - 1;
                    setFavorite(planning as IPlanning, checked).then(
                      (response) => {
                        if (response) {
                          setPlannings({
                            ...plannings,
                            docs: planningDocs,
                          });
                        }
                      }
                    );
                  }}
                  aprendizajes={
                    aprendizajes
                      ? aprendizajes.map((ap) => ap.value).join(',')
                      : ''
                  }
                  user={user}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CommunityComponent;
