import React from 'react';
import './textarea.component.scss';

interface Props {
  id: string;
  placeholder?: string;
  value?: string;
  readOnly?: boolean;
  onChangeCapture?: (evt: React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement> | undefined) => void;
}

const TextareaComponent = ({
  id,
  placeholder,
  value,
  readOnly,
  onChangeCapture,
  onBlur,
}: Props) => {
  return (
    <textarea
      className="form-control"
      id={id}
      placeholder={placeholder || ''}
      value={value || ''}
      readOnly={readOnly || false}
      disabled={readOnly || false}
      onChangeCapture={onChangeCapture}
      onBlur={onBlur}
    />
  );
};

export default TextareaComponent;
