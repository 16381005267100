import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
const html2pdf = require('html2pdf.js');
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import InputComponent from '../../../components/Form/input/input.component';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getPlaneacionNem, updatePlaneacionNem } from '../../../api/chatGptApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PlanningNem } from '../../../types/EntidadesInterface';
import PlanningNemPrintComponent from '../print/planningPrint.component';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import NavigationHints from '../../../components/NavigationHints/navigationHints';

pdfMake.vfs = pdfFonts.pdfMake.vfs; // Load pdfMake fonts (optional)
interface MSNavigator extends Navigator {
  msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
}
declare var navigator: MSNavigator;

const PlanningNemDetailScreen = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { id: planningId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const componentRef = useRef<HTMLDivElement>(null);
  const [showPrint, setShowPrint] = useState(false);
  const [planning, setPlanning] = useState<Partial<PlanningNem>>({});
  const [htmlText, setHtmlText] = useState('');
  const [activo, setActivo] = useState(false);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-nem-detalle',
      planningId,
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            // setIsLoading(true);
            getPlaneacionNem(planningId, jwtToken).then((plan) => {
              setHtmlText(
                `<p> ${plan.planningGpt?.replace(/\n/g, '<br />')} </p> `
              );
              setPlanning(plan);
              setIsLoading(false);
            });
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  // Print Generator
  const handlePrint = () => {
    trackEvent({
      event: 'printPlanningNem',
      page: '/planeacion-nem-detalle',
      planningId,
      fullPath: window.location.href,
    });
    window.print();
  };

  // PDF Generator
  const handlePdf = async () => {
    trackEvent({
      event: 'downloadPlanningNem',
      page: '/planeacion-nem-detalle',
      planningId,
      fullPath: window.location.href,
    });
    const printElement = componentRef.current;

    if (!printElement) {
      setIsLoading(false);
      return;
    }
    const fileName = planning.name || 'PlaneacionNEM';
    const options = {
      margin: 5,
      filename: `${fileName.toLowerCase().replace(/\s/g, '-')}.pdf`,
      image: { type: 'jpeg', quality: 1 }, // Increase the image quality
      html2canvas: { scale: 2, dpi: 300 }, // Increase the scale and dpi for better resolution
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    // Wait for html2pdf to load fonts and render the PDF
    await html2pdf().set(options).from(printElement).save();

    setIsLoading(false);
    setShowPrint(false);
  };

  // Word generator
  function Export2Doc(elementId: any, filename = '') {
    const preHtml = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
      <meta charset='utf-8'>
      <title>Export HTML To Doc</title>
      <style>
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      body {
        background: url(https://www.pacoteacher.com/assets/images/elements/paco-a4.jpg) center center no-repeat;
        background-size: cover;
      }
      h1 {
        color: #4b0082;
      }
        h5 {
          color: #8439FF;
          font-size: 18px;
        }
        h6 {
          font-size: 12px;
        }
        p {
          font-size: 14px;
        }
        img,
        .print-plan__logo {
          width: 60px !important;
        }
        .print-section__item {
          border-bottom: 1px solid #DEE6F2;
        }
      </style>
    </head>
    <body>
    `;
    const postHtml = '</body></html>';
    const element = document.getElementById(elementId);
    // console.log('ELEMENT::', element);
    if (!element) {
      console.error('Element not found!');
      return;
    }
    const html = preHtml + element.innerHTML + postHtml;
    // console.log('HTML::', html);
    const blob = new Blob(['ufeff', html], {
      type: 'application/msword',
    });

    const url =
      'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    filename = filename ? filename + '.doc' : 'document.doc';

    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }
  const downloadWordDocument = () => {
    Export2Doc(
      'print-placeholder',
      `${planning.name || 'mi_planeacion_PACO-Teacher'}`
    );
    trackEvent({
      event: 'downloadWordPlanningNem',
      page: '/planeacion-nem-detalle',
      planningId,
      fullPath: window.location.href,
    });
  };

  const updatePlanNem = async (plan: Partial<PlanningNem>) => {
    try {
      const { _id: planningId } = plan;
      if (planningId) {
        const update = await updatePlaneacionNem(
          planningId,
          { ...plan },
          `${user.token}`
        );
        return update;
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const savePlanning = () => {
    trackEvent({
      event: 'savePlanningNem',
      page: '/planeacion-nem-detalle',
      planningId,
      fullPath: window.location.href,
    });
    updatePlanNem({ ...planning })
      .then(() => {
        return Swal.fire({
          icon: 'success',
          text: `La planeación NEM fue guardada correctamente.`,
          showCancelButton: true,
          confirmButtonText: 'Ir a Mis planeaciones NEM',
          cancelButtonText: 'Continuar editando',
        }).then((response) => {
          if (response.isConfirmed) {
            navigate('/mis-planeaciones-nem');
          }
        });
      })
      .catch((error) => setError(error));
  };

  const deletePlanning = () => {
    Swal.fire({
      icon: 'warning',
      text: `¿Estas seguro de eliminar esta planeación?.`,
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No, cancelar',
    }).then((response) => {
      if (response.isConfirmed) {
        trackEvent({
          event: 'deletePlanningNem',
          page: '/planeacion-nem-detalle',
          planningId,
          fullPath: window.location.href,
        });
        navigate('/mis-planeaciones-nem');
        updatePlanNem({ ...planning, isDelete: true })
          .then(() => {
            Swal.fire('', `Planeación NEM eliminada`, 'success');
            navigate('/mis-planeaciones-nem');
          })
          .catch((error) => setError(error));
      }
    });
  };

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={'/mis-planeaciones-nem'}
          customNavigate={() => {
            navigate(-1);
          }}
          back="Regresar"
          urlSecond={`/planeacion-nem-detalle/${planningId}`}
          urlSecondText={'Planeaciones NEM'}
        />

        <section className="custom-content">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12 mx-auto">
                <div className="custom-content__head">
                  <article>
                    <h3 className="text-center text-color-purple">
                      Nueva planeación para la Nueva Escuela Mexicana
                    </h3>
                    <p>
                      Aquí tienes la sugerencia generada con la inteligencia
                      artificial ARTI. Puedes editarla y modificarla de acuerdo
                      a tus necesidades.{' '}
                    </p>
                  </article>
                  <div className="planning-options col-12">
                    <button
                      onClick={handlePrint}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">print</span>
                      Imprimir
                    </button>
                    <button
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsLoading(true);
                        setShowPrint(true);
                        setTimeout(() => {
                          handlePdf();
                        }, 200);
                      }}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                      Descargar
                    </button>
                    <button
                      type="button"
                      onClick={downloadWordDocument}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">
                        text_snippet
                      </span>
                      Documento Word
                    </button>
                  </div>
                </div>
                <div className="custom-content__body">
                  <div className="form__row strategie--title">
                    <label htmlFor="plan" className="form__label">
                      <h4>
                        Ponle un nombre a tu planeación para que la identifiques
                      </h4>
                    </label>
                    <div className="form__validation-input">
                      <InputComponent
                        type="text"
                        id="plan"
                        placeholder="Escribe un nombre para tu planeación"
                        value={planning.name || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setPlanning({
                            ...planning,
                            name:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__row strategie--title">
                    <Accordion
                      defaultActiveKey="0"
                      className="new-accordion-planning"
                    >
                      <Card>
                        <Card.Header
                          className={`card-header ${activo ? '' : 'active'}`}
                        >
                          <div id="notas" className="plan-nav__click"></div>
                          <div className="accordion-planning__title">
                            <div className="toggle-complete">
                              <label>
                                <p>Ubicación curricular</p>
                              </label>
                            </div>
                          </div>
                          <CustomToggle eventKey="0">
                            <span className="material-icons-outlined accordion-header__arrow">
                              keyboard_arrow_down
                            </span>
                          </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="planning-row-form row">
                              {!!planning.planningNem?.proyecto && (
                                <div className="nem-section__row__col col-6">
                                  <h6>Proyecto</h6>
                                  <p>{`${planning.planningNem?.proyecto.nombre}`}</p>
                                </div>
                              )}
                              {!!planning.planningNem?.grado && (
                                <div className="nem-section__row__col col-6">
                                  <h6>Grado escolar</h6>
                                  <p>
                                    {planning.planningNem.grado.documentId > '6'
                                      ? `${planning.planningNem?.grado.value} - Secundaria`
                                      : `${planning.planningNem?.grado.value}`}
                                  </p>
                                </div>
                              )}
                              {!!planning.planningNem?.campoFormativo && (
                                <div className="nem-section__row__col col-6">
                                  <h6>Campo formativo</h6>
                                  <p>{`${planning.planningNem?.campoFormativo.value}`}</p>
                                </div>
                              )}
                              {!!planning.planningNem?.libro && (
                                <div className="nem-section__row__col col-6">
                                  <h6>Libro de texto</h6>
                                  <p>{`${planning.planningNem?.libro.value}`}</p>
                                </div>
                              )}
                              {!!planning.planningNem?.contenidoS && (
                                <div className="nem-section__row__col col-6">
                                  <h6> Contenido sintético</h6>
                                  <p>{`${planning.planningNem?.contenidoS.value}`}</p>
                                </div>
                              )}
                              {!!planning.planningNem?.pda && (
                                <div className="nem-section__row__col col-6">
                                  <h6>
                                    {' '}
                                    Proceso de desarrollo de aprendizaje (PDA)
                                  </h6>
                                  <p>{`${planning.planningNem?.pda.value}`}</p>
                                </div>
                              )}
                              {!!planning.planningNem?.sesiones && (
                                <div className="nem-section__row__col col-6">
                                  <h6>Número de sesiones</h6>
                                  <p>{`${planning.planningNem?.sesiones}`}</p>
                                </div>
                              )}
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                  <div className="form__row strategie--title">
                    <label htmlFor="editor" className="form__label">
                      <h4>Edita la secuencia didáctica libremente.</h4>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: {
                          removeItems: ['uploadImage'],
                        },
                      }}
                      data={
                        planning.planningGptEdit
                          ? planning.planningGptEdit
                          : htmlText
                      }
                      onReady={(editor) => {
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        planning._id
                          ? setPlanning({
                              ...planning,
                              planningGptEdit: data,
                            })
                          : '';
                      }}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setPlanning({
                          ...planning,
                          planningGptEdit: data,
                        });
                      }}
                    />
                    <div className="strategie--footer">
                      <p>
                        ARTI aún está aprendiendo, siempre verifica sus
                        respuestas.
                        <Link to="/terminos-y-condiciones">
                          {' '}
                          Conoce más aquí.
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="form__row form__row--bottom">
                    <div className="form__row-actions">
                      <button className="btn btn--type1" onClick={savePlanning}>
                        Guardar en Mis planeaciones NEM{' '}
                      </button>
                    </div>
                    <div className="planning-options col-12">
                      <button
                        onClick={handlePrint}
                        className="planning-options__button"
                      >
                        <span className="material-icons-outlined">print</span>
                        Imprimir
                      </button>
                      <button
                        onClick={(evt) => {
                          evt.preventDefault();
                          setIsLoading(true);
                          setShowPrint(true);
                          setTimeout(() => {
                            handlePdf();
                          }, 200);
                        }}
                        className="planning-options__button"
                      >
                        <span className="material-icons-outlined">
                          file_download
                        </span>
                        Descargar
                      </button>
                      <button
                        type="button"
                        onClick={downloadWordDocument}
                        className="planning-options__button"
                      >
                        <span className="material-icons-outlined">
                          text_snippet
                        </span>
                        Documento Word
                      </button>
                    </div>
                    <div className="modal-info__bottom modal-info--border mt-4 col-12">
                      <button className="btn" onClick={deletePlanning}>
                        Eliminar planeación
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NavigationHints
          button1={{
            link: '/instrumentos-de-evaluacion',
            texto: 'Instrumentos de evaluación con Inteligencia Artificial',
            color: 'btn--color4',
            iconText: 'icon--grid8',
            icon: true,
            arti: true,
            max: false,
          }}
          button2={{
            link: '/planeaciones/nueva-escuela-mexicana',
            texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
            color: 'btn--color2',
            iconText: 'icon--grid2',
            icon: true,
            arti: false,
            max: false,
          }}
          button3={{
            link: '/estrategias-didacticas',
            texto: 'Enseña Matemáticas con PACO Max',
            color: 'btn--color3',
            iconText: 'icon--grid7',
            icon: true,
            arti: false,
            max: true,
          }}
          user={user}
        />
      </div>
      <FooterComponent />
      <div id="print-placeholder" ref={componentRef}>
        <PlanningNemPrintComponent
          show={showPrint}
          planning={planning}
          whtml={htmlText}
        />
      </div>
    </section>
  );
};

export default PlanningNemDetailScreen;
