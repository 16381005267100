import React from 'react';
import { ActionMeta, MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './multiselect.component.scss';

type Props = {
  id?: string;
  options: {
    value: string;
    label: string;
    extra?: string;
  }[];
  value?: {
    value: string;
    label: string;
    extra?: string;
  }[];
  disabled?: boolean;
  onChange?:
    | ((
        newValue: MultiValue<{
          value: string;
          label: string;
          extra?: string | undefined;
        }>,
        actionMeta: ActionMeta<{
          value: string;
          label: string;
          extra?: string | undefined;
        }>
      ) => void)
    | undefined;
};

const CreatableComponent = ({
  id,
  options,
  value,
  disabled,
  onChange,
}: Props) => {
  return (
    <div className="search-select">
      <span className="material-icons-outlined search-select__icon">
        search
      </span>
      <CreatableSelect
        id={id || ''}
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        isMulti
        value={value || []}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F3F5F9',
            primary: '#6081E2',
          },
        })}
        isDisabled={disabled || false}
        isClearable
        onChange={onChange}
        formatCreateLabel={(userInput) => `Añadir otra: ${userInput}`}
      />
    </div>
  );
};

export default CreatableComponent;
