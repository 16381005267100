import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import PlaneacionItemComponent from '../../../components/Cards/planeacion/planeacion.component';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';
import MaterialItemAddedComponent from '../../../components/Cards/materialAdded/materialAdded.component';
import ButtonAddComponent from '../../../components/Button/buttonAdd/buttonAdd.component';
import './favorites.screen.scss';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { searchPlanningFavorite } from '../../../api/planningFavoritesApi';
import {
  IPlanning,
  IPlanningFavorite,
  IPlanningNem,
  PlanningNemLikeResult,
  PlanningNemResult,
  ResultPaginate,
  SearchResult,
} from '../../../types/PlaningInterface';
import {
  createResourceFavorite,
  searchResourceFavorite,
} from '../../../api/resourceFavoritesApi';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import {
  GenericResult,
  Logo,
  RecursoEducativo,
} from '../../../types/EntidadesInterface';
import { searchFlexiblePlanningFavorite } from '../../../api/flexiblePlanningFavoritesApi';
import {
  IFlexiblePlanning,
  IFlexiblePlanningFavorite,
  ResultPaginateFlexible,
} from '../../../types/FlexiblePlanningInterface';
import { searchPlanningNemFav } from '../../../api/planningNemFavs';
import { getPlanningsNem } from '../../../strapi/planningsNem';
import PlaneacionNemItemComponent from '../../../components/Cards/planeacion/planeacionNem.component';

const FavoritesScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [favorites, setFavorites] = useState<ResultPaginate>({
    docs: [],
    offset: 0,
    limit: 50,
  });
  const [favoritesFlex, setFavoritesFlex] = useState<ResultPaginateFlexible>({
    docs: [],
    offset: 0,
    limit: 50,
  });
  const [resourceFavorites, setResourceFavorites] = useState<SearchResult>({
    myFavorites: [],
    all: [],
  });
  const [resourcesF, setResourcesF] = useState<GenericResult | null>(null);
  const [tiposRecurso, setTiposRecurso] = useState<GenericResult | null>(null);
  const [planNemFavorites, setPlanNem] = useState<PlanningNemLikeResult>({
    myFavorites: [],
    all: [],
  });
  const [planningsNem, setPlanningsNem] = useState<Partial<PlanningNemResult>>(
    {}
  );

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getTiposRecursos = async () => {
    const result = await getStrapiCatalog(
      qs.stringify({ sort: ['Nombre:asc'], populate: '*' }),
      '/tipo-de-recursos'
    );
    setTiposRecurso(result);
  };

  const getRecursosFavoritos = async (ids: number[]) => {
    try {
      const query = qs.stringify({
        filters: {
          id: {
            $in: ids,
          },
        },
        populate: '*',
      });
      const result = await getStrapiCatalog(query, '/recursos-educativos');
      if (result) {
        await getTiposRecursos();
        setResourcesF(result);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getFavorites = async (userId: string, token: string) => {
    try {
      const result = await searchResourceFavorite(
        { userId, isFavorite: true },
        token
      );
      if (result) {
        setResourceFavorites(result);
        getRecursosFavoritos(
          result.myFavorites.map(({ resourceId }) => resourceId)
        );
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const setRFavorite = async (resourceId: string, checked: boolean) => {
    trackEvent({
      event: 'resourceFavorite',
      page: '/busqueda',
      resource: resourceId,
      favorite: checked,
    });
    const result = await createResourceFavorite(
      { userId: user._id, resourceId, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  const getPlanningNemFavorite = async (ids: number[]) => {
    try {
      const query = {
        populate: '*',
        filters: {
          id: {
            $in: ids,
          },
        },
      };
      getPlanningsNem(query).then((result) => {
        if (result) {
          setPlanningsNem(result);
        }
      });
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/mis-favoritos',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            searchPlanningFavorite(
              { userId: kameUser._id, isFavorite: true },
              jwtToken
            ).then((resp) => {
              setFavorites(resp);
            });
            searchFlexiblePlanningFavorite(
              { userId: kameUser._id, isFavorite: true },
              jwtToken
            ).then((resp) => {
              setFavoritesFlex(resp);
            });
            searchPlanningNemFav(
              { userId: kameUser._id, isFavorite: true },
              jwtToken
            ).then((resp) => {
              setPlanNem(resp);
              getPlanningNemFavorite(
                resp.myFavorites.map(({ planningNemId }) => planningNemId)
              );
            });
            getFavorites(`${kameUser._id}`, jwtToken);
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  const totalFavs =
    favorites.docs.length +
    favoritesFlex.docs.length +
    planNemFavorites.myFavorites.length;

  return (
    <section className="app-section">
      <HeaderLoginComponent />
      <div className="app-section__top">
        <section className="module">
          <div className="container container-materials">
            <div className="row">
              <div className="module__title title--action col-12">
                <h2>Mis favoritos</h2>
              </div>
            </div>
          </div>
          <div className="container container-materials">
            <Tabs
              defaultActiveKey="planning"
              id="favorites"
              className="mb-3 tab-change-material"
              onSelect={(event) => {
                trackEvent({
                  event: 'favoriteClick',
                  page: '/mis-favoritos',
                  section: event,
                });
              }}
            >
              <Tab eventKey="planning" title="Planeaciones">
                {!favorites.docs.length ? (
                  <div className="session-placeholder">
                    <div className="session-placeholder__inner">
                      <span className="material-icons-outlined session-placeholder__icon">
                        work_outline
                      </span>
                      <article className="session-placeholder__article col-12 col-reset">
                        <h6>Aún no has agregado favoritos a tu perfil</h6>
                        <p>
                          Aquí se muestran todos tus favoritos, desde recursos
                          hasta planeaciones. Así podrás encontrarlos fácilmente
                          y verlos en el momento que quieras.
                        </p>
                      </article>
                      <ButtonAddComponent url="/bienvenido" name="Explorar" />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="container-materials__row row">
                  <div className="mx-auto col-md-6 col-12">
                    <SearchSelectComponent
                      options={(
                        (favorites.docs as IPlanningFavorite[]) || []
                      ).map((fav) => {
                        const { planningId: planning } = fav;
                        const { name, _id: planningId } = planning as IPlanning;
                        return {
                          value: planningId,
                          label: name,
                        };
                      })}
                      onChange={(newValue) => {
                        navigate(`/planeacion-sesiones/${newValue?.value}`);
                      }}
                    />
                    <div className="search-result">
                      {totalFavs > 0 ? (
                        <p>
                          Mostrando <strong>{`${totalFavs || 0}`}</strong>{' '}
                          {totalFavs > 1 ? (
                            <>planeaciones disponibles</>
                          ) : (
                            <>planeación disponible</>
                          )}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="container-materials__row row">
                  {(planningsNem?.data || []).map((planning, key) => {
                    const {
                      Proyecto,
                      grados,
                      nem_campo_formativos: campoFormativo,
                    } = planning.attributes as IPlanningNem;
                    return (
                      <div
                        className="container-materials__col col-md-3 col-6"
                        key={key}
                      >
                        <PlaneacionNemItemComponent
                          nombre={`${Proyecto}`}
                          paco={true}
                          grade={`${
                            grados
                              ? grados.data
                                  .map((g) => g.attributes.Grado)
                                  .join(', ')
                              : ''
                          }`}
                          campoFormativo={`${
                            campoFormativo
                              ? campoFormativo.data
                                  .map((c) => c.attributes.Nombre)
                                  .join(', ')
                              : ''
                          }`}
                          url={`/planeaciones-nueva-escuela-mexicana/${planning.id}`}
                          hide={true}
                          hideDownloads={true}
                        />
                      </div>
                    );
                  })}
                  {(
                    (favoritesFlex.docs as IFlexiblePlanningFavorite[]) || []
                  ).map((fav, key) => {
                    const { planningId: planning } = fav;
                    const {
                      _id: planningId,
                      userId,
                      name,
                      asignaturas,
                      gradoEscolar,
                      favorites,
                      myFavorite,
                      copyCount,
                    } = planning as IFlexiblePlanning;
                    return (
                      <div
                        className="container-materials__col col-md-3 col-6"
                        key={key}
                      >
                        <PlaneacionItemComponent
                          type={user._id === userId ? 'old' : 'comunidad'}
                          classTitle={''}
                          plan={`${name}`}
                          grade={`${gradoEscolar?.value || ''}`}
                          url={`/planeacion-flexible-vista-previa/${planningId}`}
                          totalFavs={favorites}
                          totalShare={copyCount}
                          checked={Number(myFavorite) >= 1}
                          hide={true}
                          hideShare={true}
                          asignaturas={
                            asignaturas
                              ? asignaturas
                                  .map((as) => as.asignatura?.value)
                                  .join(', ')
                              : ''
                          }
                          user={user}
                        />
                      </div>
                    );
                  })}
                  {((favorites.docs as IPlanningFavorite[]) || []).map(
                    (fav, key) => {
                      const { planningId: planning } = fav;
                      const {
                        _id: planningId,
                        userId,
                        name,
                        asignatura: { value: asignatura },
                        gradoEscolar: { value: gradoEscolar },
                        favorites,
                        myFavorite,
                        copyCount,
                        aprendizajes,
                      } = planning as IPlanning;
                      return (
                        <div
                          className="container-materials__col col-md-3 col-6"
                          key={key}
                        >
                          <PlaneacionItemComponent
                            type={user._id === userId ? 'old' : 'comunidad'}
                            classTitle={`${asignatura}`}
                            plan={`${name}`}
                            grade={`${gradoEscolar}`}
                            url={
                              user.email !== ''
                                ? `/sesion-compartida/${planningId}`
                                : '/iniciar-sesion'
                            }
                            totalFavs={favorites}
                            totalShare={copyCount}
                            checked={Number(myFavorite) >= 1}
                            hide={true}
                            hideShare={true}
                            aprendizajes={aprendizajes
                              .map((ap) => ap.value)
                              .join(',')}
                            user={user}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </Tab>
              <Tab eventKey="materials" title="Materiales digitales">
                <div className="container-materials__row row">
                  <div className="mx-auto col-md-6 col-12">
                    <SearchSelectComponent
                      options={(resourcesF?.data || []).map((resource) => {
                        return {
                          value: `${resource.id}`,
                          label: (resource.attributes as RecursoEducativo)
                            .Nombre,
                        };
                      })}
                      onChange={(newValue) => {
                        navigate(`/actividad/${newValue?.value}`);
                      }}
                    />
                    <p className="search-result">
                      Mostrando{' '}
                      <strong>
                        {resourceFavorites.myFavorites.length || 0}
                      </strong>{' '}
                      materiales disponibles
                    </p>
                  </div>
                </div>
                <div className="container-materials__row row">
                  {(resourcesF?.data || []).map((resource, key) => {
                    const {
                      Nombre,
                      aprendizajes,
                      fuente,
                      tipo_de_recursos: tipoDeRecurso,
                    } = resource.attributes as RecursoEducativo;

                    const recursoFiltro = (tiposRecurso?.data || []).filter(
                      (tR) => `${tR.id}` === `${tipoDeRecurso.data[0].id}`
                    );
                    const logo = recursoFiltro.length
                      ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                      : null;

                    const myFavorite = resourceFavorites.myFavorites.filter(
                      ({ resourceId }) => `${resourceId}` === `${resource.id}`
                    );
                    const allFavorites = resourceFavorites.all.length
                      ? resourceFavorites.all
                          .filter(({ _id }) => `${_id}` === `${resource.id}`)
                          .map(({ count }) => count)
                      : [];

                    if (!myFavorite.length) {
                      return (
                        <div
                          className="container-materials__col col-lg-4 col-12"
                          key={key}
                        ></div>
                      );
                    }

                    return (
                      <div
                        className="container-materials__col col-lg-4 col-12"
                        key={key}
                      >
                        <div className="material-title">
                          <p>{`${Nombre}`}</p>
                        </div>
                        <MaterialItemAddedComponent
                          image={`${
                            logo
                              ? logo.data.attributes.formats.thumbnail.url
                              : ''
                          }`}
                          alt={Nombre}
                          name={`${
                            tipoDeRecurso.data.length
                              ? tipoDeRecurso.data
                                  .map((tR) => tR.attributes.Nombre)
                                  .join(', ')
                              : ''
                          }`}
                          type="Recurso"
                          aprendizaje={
                            aprendizajes.data.length
                              ? aprendizajes.data[0].attributes.Nombre
                              : ''
                          }
                          fuente={fuente ? fuente.data.attributes.Fuente : ''}
                          url={`/actividad/${resource.id}`}
                          myFavorite={myFavorite.length}
                          allFavorites={
                            allFavorites.length
                              ? allFavorites.reduce((a, b) => a + b)
                              : 0
                          }
                          onCheckFavorite={(event) => {
                            event?.preventDefault();
                            const checked = (event?.target as any).checked;
                            setRFavorite(`${resource.id}`, checked).then(() => {
                              getFavorites(user._id || '', `${user.token}`);
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default FavoritesScreen;
