import React, { useState, useEffect } from 'react';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
  IFlexiblePlanningSession,
  IFlexiblePlanningSessionDoc,
  IFlexiblePlanningSessionEval,
} from '../../../types/FlexiblePlanningInterface';
interface Props {
  contentTitle: any;
  contentToEnabled: any;
  disableField?: (checked: boolean) => void;
  isChecked?: boolean;
  updates?: {
    currentPlanning: Partial<IFlexiblePlanning>;
    updatePlanningState: (
      key: keyof IFlexiblePlanning,
      value: any
    ) => Partial<IFlexiblePlanning>;
    updatePlanning: (
      newPlanning: Partial<IFlexiblePlanning>
    ) => Promise<Partial<IFlexiblePlanning>>;
    toUpdate: 'planning' | 'asignatura' | 'session';
    planning?: {
      field: keyof IFlexiblePlanning;
    };
    asignatura?: {
      asignaturaId: string;
      field: keyof IFlexiblePlanningAsignatura;
    };
    session?: {
      sessionId: string;
      field: keyof IFlexiblePlanningSession;
      inicio?: keyof IFlexiblePlanningSessionDoc;
      desarrollo?: keyof IFlexiblePlanningSessionDoc;
      cierre?: keyof IFlexiblePlanningSessionDoc;
      evaluacion?: keyof (IFlexiblePlanningSessionDoc &
        IFlexiblePlanningSessionEval);
    };
  };
}

const ToggleEnabledComponent = ({
  contentTitle,
  contentToEnabled,
  disableField,
  updates,
}: Props) => {
  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (updates) {
      const {
        toUpdate,
        planning,
        asignatura,
        session,
        currentPlanning,
        updatePlanningState,
        updatePlanning,
      } = updates;
      const { disabledFields } = currentPlanning;
      let currentFields = [];
      let newPlanning = currentPlanning;
      switch (toUpdate) {
        case 'planning':
          if (planning) {
            const { field } = planning;
            currentFields = disabledFields?.planning?.fields || [];
            if (checked) {
              currentFields = currentFields.filter((cF) => cF !== field);
            } else {
              currentFields.push(field);
            }
            newPlanning = updatePlanningState('disabledFields', {
              ...disabledFields,
              planning: {
                fields: currentFields,
              },
            });
          }
          break;
        case 'asignatura':
          if (asignatura) {
            const { asignaturaId, field } = asignatura;
            let founded = false;
            const mappedFields = (disabledFields?.asignaturas || []).map(
              (_asignatura) => {
                currentFields = _asignatura.fields || [];
                if (_asignatura.asignaturaId === asignaturaId) {
                  founded = true;
                  if (checked) {
                    currentFields = currentFields.filter((cF) => cF !== field);
                  } else {
                    currentFields.push(field);
                  }
                }
                return {
                  asignaturaId: _asignatura.asignaturaId,
                  fields: currentFields,
                };
              }
            );
            if (!founded) {
              mappedFields.push({
                asignaturaId,
                fields: [field],
              });
            }
            newPlanning = updatePlanningState('disabledFields', {
              ...disabledFields,
              asignaturas: mappedFields,
            });
          }
          break;
        case 'session':
          if (session) {
            const { sessionId, field } = session;
            let founded = false;
            let child = {};
            const mappedFields = (disabledFields?.sessions || []).map(
              (_session) => {
                currentFields = _session.fields || [];
                if (_session.sessionId === sessionId) {
                  founded = true;
                  if (
                    ['inicio', 'desarrollo', 'cierre', 'evaluacion'].includes(
                      field
                    )
                  ) {
                    const newField: string = (session as any)[field];
                    if (checked) {
                      currentFields = [...new Set(_session.fields || [])];
                      child = {
                        [field]: (_session as any)[field].filter(
                          (cF: string) => cF !== newField
                        ),
                      };
                    } else {
                      currentFields.push(field);
                      ((_session as any)[field] || []).push(newField);
                      child = {
                        [field]: (_session as any)[field] || [],
                      };
                    }
                  } else {
                    if (checked) {
                      currentFields = currentFields.filter(
                        (cF) => cF !== field
                      );
                    } else {
                      currentFields.push(field);
                    }
                  }
                }
                return {
                  ..._session,
                  sessionId: _session.sessionId,
                  fields: currentFields,
                  ...child,
                };
              }
            );
            if (!founded) {
              mappedFields.push({
                sessionId,
                fields: [field],
              });
            }
            newPlanning = updatePlanningState('disabledFields', {
              ...disabledFields,
              sessions: mappedFields,
            });
          }
          break;
      }
      return updatePlanning({ ...newPlanning }).then(() => {
        setChecked(checked);
        setTimeout(() => {
          const result = disableField ? disableField(checked) : {};
          return result;
        }, 500);
      });
    }
    return false;
  };

  useEffect(() => {
    if (updates) {
      const { toUpdate, planning, asignatura, session, currentPlanning } =
        updates;
      const { disabledFields } = currentPlanning;
      switch (toUpdate) {
        case 'planning':
          if (planning) {
            const { field } = planning;
            if (disabledFields?.planning?.fields.includes(field)) {
              setChecked(false);
            } else {
              setChecked(true);
            }
          }
          break;
        case 'asignatura':
          if (asignatura) {
            const { asignaturaId, field } = asignatura;
            disabledFields?.asignaturas?.forEach((_asignatura) => {
              if (_asignatura.asignaturaId === asignaturaId) {
                if (_asignatura.fields.includes(field)) {
                  setChecked(false);
                } else {
                  setChecked(true);
                }
              }
            });
          }
          break;
        case 'session':
          if (session) {
            const { sessionId, field } = session;
            disabledFields?.sessions?.forEach((_session) => {
              if (_session.sessionId === sessionId) {
                if (
                  ['inicio', 'desarrollo', 'cierre', 'evaluacion'].includes(
                    field
                  )
                ) {
                  if (
                    (_session as any)[field] &&
                    (_session as any)[field].includes((session as any)[field])
                  ) {
                    setChecked(false);
                  } else {
                    setChecked(true);
                  }
                } else {
                  if (_session.fields.includes(field)) {
                    setChecked(false);
                  } else {
                    setChecked(true);
                  }
                }
              }
            });
          }
          break;
      }
    }
  }, [updates, updates?.currentPlanning]);

  return (
    <>
      <div className={`planning-row-enabled ${checked ? '' : 'disabled'}`}>
        <div className="form-input-element">
          <div className="toggle-complete mb-3">
            <div className="toggle-action">
              <input
                type="checkbox"
                id="toggle"
                checked={checked}
                onChange={handleCheckboxChange}
              />
              <div>
                <figure></figure>
              </div>
            </div>
            <label>{contentTitle}</label>
          </div>
          <div className="form-input-enabled">{contentToEnabled}</div>
        </div>
      </div>
    </>
  );
};

export default ToggleEnabledComponent;
