import React, { HTMLInputTypeAttribute } from 'react';
import './input.component.scss';

type Props = {
  onChangeCapture?: (
    event: React.FormEvent<HTMLInputElement> | undefined
  ) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement> | undefined) => void;
  type: HTMLInputTypeAttribute;
  id: string;
  placeholder: string;
  value?: string;
  readOnly?: boolean;
  onChangeInput?: any;
  onFocus?: any;
};

const InputComponent = ({
  type,
  id,
  placeholder,
  value,
  readOnly,
  onChangeCapture,
  onChangeInput,
  onBlur,
  onFocus,
}: Props) => {
  return (
    <input
      type={type}
      className="form-control"
      id={id}
      placeholder={placeholder}
      value={value || ''}
      onChangeCapture={onChangeCapture}
      onChange={onChangeInput}
      readOnly={readOnly || false}
      disabled={readOnly || false}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default InputComponent;
