import React from 'react';
import { Link } from 'react-router-dom';
import './stepperPlanning.component.scss';

const StepperPlaningComponent = (props: any) => {
  return (
    <div
      className="stepper stepper--planning"
      data-status={`step-${props.status}`}
    >
      <ul>
        <li>
          <Link to="#" aria-disabled="true">
            <span className="stepper__span">1</span>
            <small>Iniciar planeación</small>
          </Link>
        </li>
        <li>
          <Link
            to={`/planeacion-aprendizajes/${props.planningId}`}
            aria-disabled="true"
          >
            <span className="stepper__span">2</span>
            <small>Seleccionar aprendizajes</small>
          </Link>
        </li>
        <li>
          <Link
            to={
              props.canGoNext ? `/planeacion-campos/${props.planningId}` : '#'
            }
          >
            <span className="stepper__span">3</span>
            <small>Definir planeación</small>
          </Link>
        </li>
        {/* <li>
          <Link
            to={
              props.canGoNext
                ? `/planeacion-resultado/${props.planningId}`
                : '#'
            }
          >
            <span className="stepper__span">4</span>
            <small>Revisar planeación</small>
          </Link>
        </li> */}
        <li>
          <Link to={props.canGoNext ? `/sesiones/${props.planningId}` : '#'}>
            <span className="stepper__span">4</span>
            <small>Crear sesiones</small>
          </Link>
        </li>
        <li>
          <Link
            to={
              props.canGoNext ? `/planeacion-sesiones/${props.planningId}` : '#'
            }
          >
            <span className="stepper__span">5</span>
            <small>Ver planeación final</small>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default StepperPlaningComponent;
