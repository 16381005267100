import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import {
  GenericResult,
  Logo,
  RecursoPapalote,
} from '../../types/EntidadesInterface';
import { getStrapiCatalog } from '../../strapi/escuelas';
import MaterialPapaloteComponent from '../../components/Cards/materialPapalote/materialPapalote.component';
import MultiSelectComponent from '../../components/Form/multiselect/multiselect.component';
import SearchSelectComponent from '../../components/Form/searchSelect/searchSelect.component';

const SearchScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [filtros, setFiltros] = useState<{
    tiposRecurso: GenericResult;
  }>({
    tiposRecurso: { data: [] },
  });
  const [recursos, setRecursos] = useState<
    {
      id: number;
      attributes: RecursoPapalote;
    }[]
  >([]);
  const [currentResources, setCurrentResources] = useState<
    {
      id: number;
      attributes: RecursoPapalote;
    }[]
  >([]);
  const [activeFilters, setActiveFilters] = useState<{
    tiposRecurso: string[];
  }>({ tiposRecurso: [] });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const getFiltros = async () => {
    try {
      const tiposRecurso = await getStrapiCatalog(
        qs.stringify({ sort: ['Nombre:asc'], populate: '*' }),
        '/tipo-de-recursos-papalote'
      );
      setFiltros({ tiposRecurso });
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getRecursos = async (filters?: any) => {
    try {
      const query = filters
        ? {
            populate: '*',
            sort: ['id:asc'],
            pagination: { pageSize: 100 },
            filters: {
              tipo_de_recurso_papalote: {
                id: { $in: filters.tiposRecurso.map((idR: any) => idR) },
              },
            },
          }
        : {
            populate: '*',
            sort: ['id:asc'],
            pagination: { pageSize: 100 },
          };
      getStrapiCatalog(qs.stringify(query), 'recursos-papalote')
        .then((response) => {
          setRecursos(
            response.data as {
              id: number;
              attributes: RecursoPapalote;
            }[]
          );
          setCurrentResources(
            response.data as {
              id: number;
              attributes: RecursoPapalote;
            }[]
          );
        })
        .catch((error) => setError(error));
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    trackEvent({
      event: 'pageView',
      page: '/recursos-papalote',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            getFiltros();
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              getRecursos().then(() => console.log());
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch(() => {
        getRecursos().then(() => setIsLoading(false));
      });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={'/bienvenido'}
          customNavigate={() => {
            navigate(-1);
          }}
          back="Regresar"
        />
        <section className="search-block">
          <div className="container">
            <div className="search-block__top">
              <div className="row">
                <div className="search-block__top-col col-md-10 col-12">
                  <div className="search__title">
                    <h1>Recursos Papalote Museo del Niño</h1>
                    <p>
                      Explora recursos digitales hechos por el Papalote Museo del Niño, para trabajar en clase y/o para apoyarte en tu tarea docente. 
                      Encontrarás tips, estrategias, ideas y mucho más{' '}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <figure>
                    <img src="/assets/images/elements/papalote_museo.png" alt="Papalote" />
                  </figure>
                </div>
              </div>
              <div className="row">
                <div className="search-block__top-col col-12">
                  <div className="search__global-filters">
                    <div className="row">
                      <div className="form__row col-md-4 col-12">
                        <label htmlFor="state" className="form__label">
                          Tipo de contenido
                        </label>
                        <MultiSelectComponent
                          options={(filtros.tiposRecurso.data || []).map(
                            (tipoRecurso) => ({
                              value: `${tipoRecurso.id}`,
                              label: (tipoRecurso.attributes as any).Nombre,
                            })
                          )}
                          onChange={(evt) => {
                            let activeF = { ...activeFilters };
                            activeF.tiposRecurso.splice(
                              0,
                              activeF.tiposRecurso.length
                            );
                            evt.forEach((e) => {
                              activeF.tiposRecurso.push(`${e.value}`);
                            });
                            setActiveFilters({ ...activeF });
                            getRecursos(activeF);
                          }}
                          from="search"
                          value={(activeFilters.tiposRecurso || []).map(
                            (tipoRecurso) => ({
                              value: `${tipoRecurso}`,
                              label: (
                                filtros.tiposRecurso.data[
                                  filtros.tiposRecurso.data.findIndex(
                                    (rec) => `${rec.id}` === tipoRecurso
                                  )
                                ].attributes as any
                              ).Nombre,
                            })
                          )}
                        />
                      </div>
                      <div className="form__row col-md-5 col-12">
                        <label htmlFor="state" className="form__label">
                          Recurso
                        </label>
                        <SearchSelectComponent
                          options={(recursos || []).map((recurso) => ({
                            value: `${recurso.id}`,
                            label: recurso.attributes.Nombre,
                          }))}
                          onChange={(newValue) => {
                            navigate(`/actividad-papalote/${newValue?.value}`);
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <button
                          className="search__engine-clear"
                          onClick={(evt) => {
                            evt.preventDefault();
                            setActiveFilters({
                              tiposRecurso: [],
                            });
                            getRecursos();
                            trackEvent({
                              event: 'cleanFilters',
                              page: '/recursos-papalote',
                              fullPath: window.location.href,
                            });
                          }}
                        >
                          Limpiar filtros de búsqueda
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-block__bottom">
              {recursos.length > 0 ? (
                <p>
                  Mostrando <strong>{`${(recursos || []).length}`}</strong>{' '}
                  recursos disponibles
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="search-block__results">
              <h4>Resultados de la búsqueda</h4>
              <div className="search-pills"></div>
              <div className="search-block__results-grid">
                <div className="row">
                  {(currentResources || []).map((recurso, k) => {
                    const { Nombre, tipo_de_recurso_papalote: tipoDeRecurso } =
                      recurso.attributes;
                    const recursoFiltro = (
                      filtros.tiposRecurso.data || []
                    ).filter((tR) => `${tR.id}` === `${tipoDeRecurso.data.id}`);
                    const logo = recursoFiltro.length
                      ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                      : null;

                    return (
                      <div
                        className="session-block__results__col col-lg-4  col-12"
                        key={k}
                      >
                        <div className="material-title">
                          <p>{`${Nombre}`}</p>
                        </div>

                        <MaterialPapaloteComponent
                          image={`${
                            logo
                              ? logo.data.attributes.formats.thumbnail.url
                              : ''
                          }`}
                          alt={Nombre}
                          name={`${tipoDeRecurso.data.attributes.Nombre}`}
                          type="Recurso"
                          url={`/actividad-papalote/${recurso.id}`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default SearchScreen;
