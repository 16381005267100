import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import SelectComponent from '../../../components/Form/select/select.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import ChatGpt from '../../../components/ChatGpt/chatGpt';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import {
  ContenidoSintetico,
  Entidad,
  Grado,
  Pda,
  PlanningNemData,
  ProyectoNem,
} from '../../../types/EntidadesInterface';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import LoaderComponent from '../../../components/Loader/loader.component';

const PlaneacionNemIndexScreen = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planeacionNem, setPlaneacionNem] = useState<Partial<PlanningNemData>>(
    {}
  );
  const [grados, setGrados] = useState<{ id: number; attributes: Grado }[]>([]);
  const [libros, setLibros] = useState<{ id: number; attributes: Entidad }[]>(
    []
  );
  const [campoF, setCampoF] = useState<{ id: number; attributes: Entidad }[]>(
    []
  );
  const [proyectos, setProyectos] = useState<
    { id: number; attributes: ProyectoNem }[]
  >([]);
  const [pda, setPda] = useState<{ id: number; attributes: Pda }[]>([]);
  const [contenidoS, setContenidoS] = useState<
    { id: number; attributes: ContenidoSintetico }[]
  >([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchCatalog = async (query: string, route: string) => {
    try {
      const result = await getStrapiCatalog(query, route);
      if (result.data.length) {
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return [];
  };

  const fetchCatalogs = async () => {
    try {
      const query = {
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
      };
      const resultGrados = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/grados'
      );
      setGrados(
        resultGrados as {
          id: number;
          attributes: Grado;
        }[]
      );
      const resultLibros = await fetchCatalog(
        qs.stringify({ sort: ['id:asc'], pagination: { pageSize: 50 } }),
        '/nem-libros'
      );
      setLibros(
        resultLibros as {
          id: number;
          attributes: Entidad;
        }[]
      );
      const resultCampoF = await fetchCatalog(
        qs.stringify({ sort: ['id:asc'], pagination: { pageSize: 50 } }),
        '/nem-campos-formativos'
      );
      setCampoF(
        resultCampoF as {
          id: number;
          attributes: Entidad;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const searchProyectos = async (g: string, l: string, cf: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
      filters: {
        $and: [
          { grado: { id: { $eq: g } } },
          { nem_libro: { id: { $eq: l } } },
          {
            nem_campo_formativo: {
              id: { $eq: cf },
            },
          },
        ],
      },
    };
    const resultProyectos = await fetchCatalog(
      qs.stringify({ ...query }),
      '/nem-proyectos'
    );
    setProyectos(
      resultProyectos as {
        id: number;
        attributes: ProyectoNem;
      }[]
    );
  };

  const searchContenido = async (g: string, cf: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
      filters: {
        $and: [
          { grados: { id: { $eq: g } } },
          {
            nem_campo_formativo: {
              id: { $eq: cf },
            },
          },
        ],
      },
    };
    const resultConten = await fetchCatalog(
      qs.stringify({ ...query }),
      '/nem-contenido-sinteticos'
    );
    setContenidoS(
      resultConten as {
        id: number;
        attributes: ContenidoSintetico;
      }[]
    );
  };

  const searchPda = async (cs: string, g: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
      filters: {
        $and: [
          { nem_contenido_sintetico: { id: { $eq: cs } } },
          { grado: { id: { $eq: g } } },
        ],
      },
    };
    const resultPda = await fetchCatalog(
      qs.stringify({ ...query }),
      '/nem-pdas'
    );
    setPda(
      resultPda as {
        id: number;
        attributes: Pda;
      }[]
    );
  };

  const handleChatButtonClick = (opt: number) => {
    if (opt == 9) {
      setIsLoading(true);
    }
  };

  const handleChatOptionButtonClick = (msj: string, opt: number) => {
    if (msj === 'error') {
      setIsLoading(false);
    } else if (msj && opt === 9) {
      navigate(`/planeacion-nem-detalle/${msj}`);
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/crea-tu-planeacion-nem',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then(async (kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            fetchCatalogs();
            if (
              planeacionNem.grado &&
              planeacionNem.campoFormativo &&
              planeacionNem.libro
            ) {
              searchProyectos(
                planeacionNem.grado.documentId,
                planeacionNem.libro.documentId,
                planeacionNem.campoFormativo.documentId
              );
            }
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  const handleLimpiar = async () => {
    trackEvent({
      event: 'cleanFilters',
      page: '/crea-tu-planeacion-nem',
      fullPath: window.location.href,
    });
    setGrados([]);
    setLibros([]);
    setCampoF([]);
    setProyectos([]);
    setContenidoS([]);
    setPda([]);
    setPlaneacionNem({});
    fetchCatalogs();
  };

  return (
    <section className="app-section app--background">
      <LoaderComponent
        dataClass={isLoading}
        text="ARTI está generando tu planeación..."
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={'/bienvenido'}
          customNavigate={() => {
            navigate(-1);
          }}
          back="Regresar"
          urlSecond={'/bienvenido'}
          urlSecondText={'Prepara tu clase'}
          urlThird={'/crea-tu-planeacion-nem'}
          urlThirdText={'Haz tu planeación NEM'}
        />
        <section className="header-activity-banner header--confetti">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">
                  Planeaciones para la Nueva Escuela <br /> Mexicana con
                  Inteligencia Artificial
                </h1>
              </div>
              <figure className="strategy-image strategy--left instrumento">
                <img src="/assets/images/elements/arti-icon.png" alt="Arti" />
              </figure>
            </div>
          </div>
        </section>
        <section className="container body-activity body--strategies">
          <div className="area-title area-title--decore area--button-action row">
            <div className="col-12">
              <div className="area-title__button">
                <Link to={'/mis-planeaciones-nem'}>
                  <button className="btn btn--type1">
                    Consulta mis planeaciones NEM
                  </button>
                </Link>
              </div>
              <h3 className="text-center text-color-purple col-md-4 col-12 col-reset mx-auto">
                Genera una{' '}
                <span className="span-text-color-purple">planeación</span> NEM
                Proyectos Escolares, Comunitarios y de Aula para tus clases
              </h3>
            </div>
          </div>
          <div className="search-area row">
            <div className="search-area__title col-md-10 col-12 mx-auto">
              <article>
                <h6>¡Hola, maestr@!</h6>
                <p>
                  Con esta herramienta podrás generar planeaciones útiles para
                  el nuevo modelo educativo de la Nueva Escuela Mexicana.{' '}
                  <strong>
                    Encuentra todos los proyectos escolares, comunitarios y de
                    aula.
                  </strong>{' '}
                  Tendrás la ayuda de ARTI, una inteligencia artificial que te
                  apoyará con ideas focalizadas en tus necesidades.
                </p>
              </article>
              {/* <div className="sticker-planning col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                <figure>
                  <img
                    src="/assets/images/elements/planning-arti.png "
                    alt="¡Espera muy pronto los proyectos de primero de secundaria!"
                  />
                </figure>
              </div> */}
            </div>
            <div className="col-md-10 col-12 mx-auto">
              <div className="strategies-forms row">
                <div className="strategie--title col-12">
                  <h4>
                    1. Elige los datos generales
                    <OverlayComponent toolTipText="Indica los datos generales para tu planeación. A partir de estos obtendrás una sugerencia creada por ARTI, nuestra inteligencia artificial" />
                  </h4>
                </div>
                <div className="strategie__col col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      Grado escolar <small className="required">*</small>
                    </label>
                    <SelectComponent
                      label=""
                      id="grado-escolar"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Seleccionar...
                          </option>
                          {grados.map((e, k) => {
                            return (
                              <option key={k} value={`${e.id}`}>
                                {e.id > 6
                                  ? `${e.attributes.Grado} - Secundaria`
                                  : `${e.attributes.Grado}`}
                              </option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setProyectos([]);
                        setContenidoS([]);
                        setPda([]);
                        const grado = grados.filter(
                          (m) => `${m.id}` === evt.target.value
                        );
                        setPlaneacionNem({
                          ...planeacionNem,
                          grado: {
                            documentId: evt.target.value,
                            value: grado.length
                              ? grado[0].attributes.Grado
                              : '',
                          },
                        });
                        if (
                          planeacionNem.campoFormativo &&
                          planeacionNem.libro
                        ) {
                          searchProyectos(
                            evt.target.value,
                            planeacionNem.libro.documentId,
                            planeacionNem.campoFormativo.documentId
                          );
                          searchContenido(
                            evt.target.value,
                            planeacionNem.campoFormativo.documentId
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="strategie__col col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      Libro de texto <small className="required">*</small>
                    </label>
                    <SelectComponent
                      label=""
                      id="libro"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Seleccionar...
                          </option>
                          {libros.map((e, k) => {
                            if (
                              parseInt(
                                planeacionNem?.grado?.documentId || '0'
                              ) < 7
                            ) {
                              if (k < 3) {
                                return (
                                  <option key={k} value={`${e.id}`}>
                                    {`${e.attributes.Nombre}`}
                                  </option>
                                );
                              }
                            } else {
                              if (k >= 3) {
                                return (
                                  <option key={k} value={`${e.id}`}>
                                    {`${e.attributes.Nombre}`}
                                  </option>
                                );
                              }
                            }
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        const libro = libros.filter(
                          (m) => `${m.id}` === evt.target.value
                        );
                        setPlaneacionNem({
                          ...planeacionNem,
                          libro: {
                            documentId: evt.target.value,
                            value: libro.length
                              ? libro[0].attributes.Nombre
                              : '',
                          },
                        });
                        if (
                          planeacionNem.grado &&
                          planeacionNem.campoFormativo
                        ) {
                          searchProyectos(
                            planeacionNem.grado.documentId,
                            evt.target.value,
                            planeacionNem.campoFormativo.documentId
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="strategie__col col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      Campo formativo <small className="required">*</small>
                    </label>
                    <SelectComponent
                      label=""
                      id="campof"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Seleccionar...
                          </option>
                          {campoF.map((e, k) => {
                            return (
                              <option
                                key={k}
                                value={`${e.id}`}
                              >{`${e.attributes.Nombre}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setProyectos([]);
                        setContenidoS([]);
                        setPda([]);
                        const campos = campoF.filter(
                          (m) => `${m.id}` === evt.target.value
                        );
                        setPlaneacionNem({
                          ...planeacionNem,
                          campoFormativo: {
                            documentId: evt.target.value,
                            value: campos.length
                              ? campos[0].attributes.Nombre
                              : '',
                          },
                        });
                        if (planeacionNem.grado && planeacionNem.libro) {
                          searchProyectos(
                            planeacionNem.grado.documentId,
                            planeacionNem.libro.documentId,
                            evt.target.value
                          );
                          searchContenido(
                            planeacionNem.grado.documentId,
                            evt.target.value
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                {proyectos.length > 0 ? (
                  <div className="strategie__col col-12">
                    <div className="form-group">
                      <label>
                        Proyecto <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label=""
                        id="proyecto"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {proyectos.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.nombre}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          const proyecto = proyectos.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          setPlaneacionNem({
                            ...planeacionNem,
                            proyecto: {
                              documentId: evt.target.value,
                              value: proyecto.length
                                ? proyecto[0].attributes.resumen
                                : '',
                              nombre: proyecto[0].attributes.nombre,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {contenidoS.length > 0 ? (
                  <div className="strategie__col col-12">
                    <div className="form-group">
                      <label>
                        Contenido sintético{' '}
                        <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label=""
                        id="contenido-sintetico"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {contenidoS.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.contenido}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          const content = contenidoS.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          setPlaneacionNem({
                            ...planeacionNem,
                            contenidoS: {
                              documentId: evt.target.value,
                              value: content.length
                                ? content[0].attributes.contenido
                                : '',
                            },
                          });
                          searchPda(
                            evt.target.value,
                            planeacionNem.grado?.documentId || ''
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {pda.length > 0 ? (
                  <div className="strategie__col col-12">
                    <div className="form-group">
                      <label>
                        Proceso de Desarrollo de Aprendizaje (PDA){' '}
                        <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label=""
                        id="pda"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {pda.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.pda}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          const pdas = pda.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          setPlaneacionNem({
                            ...planeacionNem,
                            pda: {
                              documentId: evt.target.value,
                              value: pdas.length ? pdas[0].attributes.pda : '',
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="strategie__col col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      Número de sesiones <small className="required">*</small>
                    </label>
                    <SelectComponent
                      label=""
                      id="numero-sesiones"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Seleccionar...
                          </option>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, k) => {
                            return (
                              <option
                                key={k}
                                value={`${e}`}
                                selected={`${e}` === planeacionNem.sesiones}
                              >
                                {`${e}`}
                              </option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setPlaneacionNem({
                          ...planeacionNem,
                          sesiones: evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="strategies-forms row">
                <div className="strategie--title col-12">
                  <h6>* Campos obligatorios</h6>
                </div>

                <div className="strategie__col strategie--chat col-12">
                  <ChatGpt
                    user={user}
                    campo={''}
                    slug="instrumento-de-evaluacion"
                    chatTitle={' Da click para generar tu planeación'}
                    chatText={' '}
                    option={9}
                    planeacionNem={planeacionNem}
                    onChatButtonClick={handleChatButtonClick}
                    onChatOptionButtonClick={handleChatOptionButtonClick}
                  />
                </div>

                <div className="strategie__col strategie--chat col-12">
                  <button
                    className="btn btn--type-outline"
                    onClick={handleLimpiar}
                  >
                    Limpiar el formulario
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PlaneacionNemIndexScreen;
