import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import SelectComponent from '../../../components/Form/select/select.component';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';
import qs from 'qs';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import { Entidad, EstrategiasDidacticas, Habilidades, PropositosEnsenanza } from '../../../types/EntidadesInterface';
import { Link } from 'react-router-dom';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';


const StrategyIndexScreen = () => {
  const { trackEvent } = useTracking();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [tema, setTema] = useState<{ id: number; attributes: Habilidades }[]>([]);
  const [proposito, setPropositos] = useState<{ id: number; attributes: PropositosEnsenanza }[]>([]);
  const [estrategia, setEstrategia] = useState<{ id: number; attributes: Entidad }[]>([]);
  const [search, setSearch] = useState<{
    propositos_de_ensenanza: string,
    estrategia: string,
    habilidades: string,
  }>({ propositos_de_ensenanza: '0', estrategia: '0', habilidades: '0' });
  const [resultEstrategia, setResultEstrategia] = useState<{ id: number; attributes: EstrategiasDidacticas }[]>([]);
  const [showmsg, setShowmsg] = useState(false);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchCatalogs = async () => {
    const query = {
      sort: ['id:asc'],
    };
    const resultHabilidades =  await getStrapiCatalog(
      qs.stringify({ ...query }), '/habilidades-pec'
    )
    setTema(
      resultHabilidades.data as {
        id: number;
        attributes: Habilidades;
      }[]
    );
    const resultPropositos =  await getStrapiCatalog(
      qs.stringify({ ...query }), '/propositos-de-ensenanzas'
    )
    setPropositos(
      resultPropositos.data as {
        id: number;
        attributes: PropositosEnsenanza;
      }[]
    );
    setIsLoading(false);
  };

  const fetchEstrategia = async (propos: string) => {
    setEstrategia([]);
    const query = {
      sort: ['id:asc'],
      filters: { propositos_de_ensenanza: { id: { $eq: propos } } },
    };
    const resultE =  await getStrapiCatalog(
      qs.stringify({ ...query }), 
      '/estrategias'
    )
    setEstrategia(
      resultE.data as {
        id: number;
        attributes: Entidad;
      }[]
    );
  };

  const fetchAllEstrategias = async (p: string, e: string, t: string) => {
    const valid = [p, e, t].every(
      (k) => k && k !== '0'
    );
    if(valid){
      const query = {
        sort: ['id:asc'],
        populate: '*',
        filters: { 
          habilidades: { id: { $eq: t } },
          propositos_de_ensenanza: { id: { $eq: p } },
          estrategia: { id: { $eq: e } },
        },
      };
      const resultED =  await getStrapiCatalog(
        qs.stringify({ ...query }), 
        '/estrategias-didacticas'
      )
      setResultEstrategia(
        resultED.data as {
          id: number;
          attributes: EstrategiasDidacticas;
        }[]
      );
      let f = {
        tem: '',
        prop: '',
        est: ''
      }
      tema.map((h) => {(`${h.id}` === t) ? f.tem = h.attributes.habilidades : '' });
      proposito.map((pr) => {(`${pr.id}` === p) ? f.prop = pr.attributes.Nombre : '' });
      estrategia.map((es) => {(`${es.id}` === e) ? f.est = es.attributes.Nombre : '' });
      
      trackEvent({
        event: 'filtersEstrategias',
        page: '/estrategias-didacticas',
        tema: f.tem,
        proposito: f.prop,
        estrategia: f.est, 
        fullPath: window.location.href,
      });
      setShowmsg(true);
    }else {
      if(p != '0') 
       Swal.fire('Ooops', 'Captura todos los datos.', 'warning') 
    }
  };

  const cleanFilters = async () => {
    setSearch({ propositos_de_ensenanza: '0', estrategia: '0', habilidades: '0' });
    setResultEstrategia([]);
    setTema([]);
    setPropositos([]);
    setEstrategia([]);
    fetchCatalogs();
  };
  
  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/estrategias-didacticas',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            fetchCatalogs();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
     
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="header-activity-banner header--confetti">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">Enseña matemáticas<br /> con PACO Max</h1>
              </div>
              <figure className="strategy-image">
                <img src="/assets/images/pacomax/paco.png" alt="PACO" />
              </figure>
            </div>
          </div>
        </section>
        <section className="container body-activity body--strategies">
          <div className="area-title area-title--decore row">
            <div className="col-md-4 col-12 mx-auto">
              <h3 className="text-center text-color-purple">Encuentra estrategias didácticas para tus clases de 5to y 6to grado de <span className="span-text-color-purple">matemáticas</span> con</h3>
              <img src="/assets/images/pacomax/paco-icon.png" alt="PACO Max" className="area-title__image"/>
            </div>
          </div>
          <div className="search-area row">
            <div className="search-area__title col-md-6 col-12 mx-auto">
              <h3 className="text-center mb-4">Inicia tu búsqueda</h3>
              <article className="text-center">
                <p>Descubre nuevas formas de aplicar los recursos que PACO Max ofrece y aprende junto a tus alumnos de manera divertida, por medio de estrategias didácticas diseñadas especialmente para ti y tus estudiantes.</p>
              </article>
            </div>
            <div className="col-md-10 col-12 mx-auto">
              <div className="strategies-forms row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>Tema <OverlayComponent
                            toolTipText="Sólo necesitas seleccionar el tema de aprendizaje, el propósito que quieres lograr y el tipo de estrategia que necesitas, y el sitio arrojará automáticamente los resultados de tu búsqueda."
                          /></label>
                    <SearchSelectComponent
                      placeholder='Busca un tema'
                      idItem="tema"
                      options={tema?.map((h) => {
                        return {
                          value: `${h.id}`,
                          label: h.attributes.habilidades,
                        };
                      })}
                      onChange={(evt) => {
                        setSearch({
                          ...search,
                          habilidades: evt?.value || '0'
                        });
                        fetchAllEstrategias(search.propositos_de_ensenanza, search.estrategia, (evt?.value || ''))
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <label>Propósito <OverlayComponent
                            toolTipText="Selecciona el propósito de enseñanza que quieres alcanzar (introducir, comprender, aplicar) con la estrategia."
                          /></label>
                    <SelectComponent
                      label=""
                      id="proposito"
                      option={
                        <>
                          <option defaultValue="" hidden>Seleccionar...</option>
                          {proposito.map((p, k) => {
                            return (
                              <option
                                key={k}
                                value={`${p.id}`}
                              >{`${p.attributes.Nombre}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setSearch({
                          ...search,
                          propositos_de_ensenanza: evt.target.value
                        });
                        fetchEstrategia(evt.target.value);
                      }}
                    />
                  </div>
                </div>
                {(estrategia.length > 0 ?
                  <div className="col-md-3 col-12">
                    <div className="form-group">
                      <label>Estrategia <OverlayComponent
                            toolTipText="Indica la acción principal que se llevará a cabo en la estrategia y a partir de la cual, lograrás el propósito de enseñanza-aprendizaje."
                          /></label>
                      <SelectComponent
                        label=""
                        id="estrategia"
                        option={
                          <>
                            <option defaultValue="" hidden>Seleccionar...</option>
                            {estrategia.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.Nombre}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          setSearch({
                            ...search,
                            estrategia: evt.target.value
                          });
                          fetchAllEstrategias(search.propositos_de_ensenanza, evt.target.value, search.habilidades)
                        }}
                      />
                    </div>
                  </div>
                  : <></>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <button className="btn--underline"
                    onClick={(evt) => {
                      evt.preventDefault();
                      cleanFilters();
                      trackEvent({
                        event: 'cleanFilters',
                        page: '/estrategias-didacticas',
                        fullPath: window.location.href,
                      });
                    }}>
                      Limpiar búsqueda</button>
                </div>
              </div>
            </div>
          </div>
          <div className="strategies-result row">
          {resultEstrategia && showmsg  ? (
            <div className="col-md-10 col-12 mx-auto">
              <p className="strategies-indicator">Resultados encontrados {resultEstrategia.length}</p>
              <br />
              {resultEstrategia.length == 0 ? 
                <h5 className="strategies-indicator">¡Lo sentimos, intenta otros filtros de busqueda!</h5>
                : <></>
              }
            </div>
           ) : <></>
          }
          </div>
          <div className="strategies-grid row">
            <div className="col-md-10 col-12 mx-auto">
              <div className="row">
                {resultEstrategia ? (
                  <>
                  {(resultEstrategia || []).map((e, k) => {
                    return(
                      <div className="strategies-grid__col col-md-3 col-12" key={k}>
                        <div className="strategy-card">
                          <div className="strategy-card__header">
                            <p>{e.attributes.Titulo}</p>
                          </div>
                          <div className="strategy-card__body">
                            <figure className="strategy__icon">
                              <span className="material-icons-outlined">assignment</span>
                            </figure>
                            <div className="strategy-card__row">
                              <p>Tema</p>
                              <p>
                                {( e.attributes.habilidades.data.map((t,key) => {
                                  return(
                                    <strong key={key}>
                                    {(t.id.toString() == search.habilidades) ?
                                      <>{t.attributes.habilidades}</>
                                      : <></>
                                    }
                                    </strong>
                                  )})
                                )}
                              </p>
                            </div>
                            <div className="strategy-card__row">
                              <p>Propósito</p>
                              <p><strong>{e.attributes.propositos_de_ensenanza?.data.attributes.Nombre}</strong></p>
                            </div>
                            <div className="strategy-card__row">
                              <p>Estrategia</p>
                              <p><strong>{e.attributes.estrategia?.data.attributes.Nombre}</strong></p>
                            </div>
                          </div>
                          <div className="strategy-card__footer">
                            <button><Link to={`/estrategias-didacticas-detalle/${e.id}/${search.habilidades}`}>Ver detalle</Link></button>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  </>
                  ) : <></>
                }
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default StrategyIndexScreen;
