import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './testAnswersChoose.screen.scss';
import { getPecExam } from '../../../strapi/pecContent';
import { PecExamData } from '../../../types/PECInterface';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import { Grado } from '../../../types/EntidadesInterface';

const TestAnswersChooseScreen = () => {
  const { gradoId, subject } = useParams();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exam, setExam] = useState<Partial<PecExamData>>({});
  const [grados, setGrado] = useState<{ id: number; attributes: Grado }[]>([]);

  const typesExam = [
    {
      id: 1,
      value: 'Exámenes anuales',
      value_eng: 'annualExams',
    },
    {
      id: 2,
      value: 'Exámenes por bloque',
      value_eng: 'blockExams',
    },
    {
      id: 3,
      value: 'Exámenes por lección',
      value_eng: 'lessonExams',
    },
    {
      id: 4,
      value: 'Exámenes por tema',
      value_eng: 'topicExams',
    },
    {
      id: 5,
      value: 'Ejercicio interactivo',
      value_eng: 'lessonExcercises',
    },
  ];

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchGrado = async () => {
    const query = {
      fields: ['Grado'],
      filters: {  id: { $eq: gradoId } },
    };
    const resultGrados =  await getStrapiCatalog(qs.stringify({ ...query, sort: ['id:asc'] }), '/grados')
    setGrado(
      resultGrados.data as {
        id: number;
        attributes: Grado;
      }[]
    );
  };

  const fetchExamType = async () => {
    try {
      const query = {
        sort: ['examType:asc', 'title:asc'],
        filters: { grado: { id: { $eq: gradoId } }, subjectTitle: { $eq: subject } },
        pagination: { pageSize: 100 }
      };
      const examResult = await getPecExam(qs.stringify(query));
      console.log(examResult)
      if (examResult && examResult.data && examResult.data.length) {
        setExam(examResult)
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/ponte-a-prueba-tipoexam',
      fullPath: window.location.href,
      gradoId,
      subject
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            fetchExamType();
            fetchGrado();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/ponte-a-prueba-materia/${gradoId}`}
          back="Regresar"
          urlInit="/ponte-a-prueba-grados"
          urlInitText="Grados"
          urlSecond={`/ponte-a-prueba-materia/${gradoId}`}
          urlSecondText="Selecciona tu materia"
          urlThird={`/ponte-a-prueba-tipo/${gradoId}/${subject}`}
          urlThirdText="Tipo de examen"
        />

        <section className="header-activity-banner">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">Reactivos para exámenes</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="container body-activity">
          <div className="row row--index">
            <figure className="activity-image">
              <img src="/assets/images/elements/taco.png" alt="Arti" />
            </figure>
            <div className=" mx-auto col-md-8 col-12">
              <section className="body-activity__content">
                <article className="header-activity__intro">
                <p>{`${grados[0]?.attributes.Grado} - ${subject}`}</p>
                  <h2 className="h4">Selecciona un examen</h2>
                </article>
                <section className="body-activity__grades col-12">
                  <div className="grid-grades row">
                  {exam.data && exam.data.length ? (
                      exam.data.map(examen => {
                        const tipoExamen = (typesExam).filter(
                          (tR) => tR.value_eng === examen.attributes.examType
                        );
                        return (
                          <div className="grid-choose__content col-12" key={examen.id}>
                            <div 
                              className={`grid-choose__content-inner choose--color${tipoExamen[0].id} choose--color`}
                            >
                              <button className="grid-choose__material" 
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`/ponte-a-prueba-detalle/${gradoId}/${subject}/${examen.id}`);
                                }}>
                                <article>
                                  <p className='choose-label'>{tipoExamen[0].value}</p> 
                                  <p>{examen.attributes.title}</p> 
                                </article>
                                <span className="material-icons-outlined icon">
                                  navigate_next
                                </span>
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default TestAnswersChooseScreen;
