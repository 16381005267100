import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { format, fromUnixTime } from 'date-fns';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import ButtonType3Component from '../../../components/Button/buttonType3/buttonType3.component';
import ButtonTypeOutlineComponent from '../../../components/Button/buttonTypeOutline/buttonTypeOutline.component';
import CheckboxComponent from '../../../components/Form/checkbox/checkbox.component';
import StepperPlanningComponent from '../../../components/StepperPlanning/stepperPlanning.component';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import './planningThree.screen.scss';
import { getUser } from '../../../api/usersApi';
import { getPlanning, updatePlanning } from '../../../api/planningsApi';
import { getAnStrapiEntry, getStrapiCatalog } from '../../../strapi/escuelas';
import { IUser } from '../../../types/UserInterface';
import { IPlanning, IPlanningSession } from '../../../types/PlaningInterface';
import { Escuela, RecursoEducativo, StrapiDoc } from '../../../types/EntidadesInterface';
import { searchPlanningSession } from '../../../api/planningSessionsApi';
import SwitchComponent from '../../../components/Form/switch/switch.component';
import ButtonActionComponent from '../../../components/Button/buttonAction/buttonAction.component';
import PrintPlanningScreen from '../../Print/planning/printPlanning.screen';
import JSPDF from 'jspdf';
import ModalSharePlanning from '../../Print/planning/sharePlanning.modal';
import ModalPublicPlanning from '../modalPublicPlanning/modalPublicPlanning.component';

const PlanningThreeScreen = () => {
  const { trackEvent } = useTracking();
  const { id: planningId } = useParams();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [showModalTooltipComponent, setShowModalTooltipComponent] =
    useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalPublic, setShowModalPublic] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [planning, setPlanning] = useState<Partial<IPlanning>>({});
  const [escuela, setEscuela] = useState<{
    id: number;
    attributes: Escuela;
  } | null>(null);
  const [escuelaSelected, setEscuelaSelected] = useState('');
  const [sessions, setSessions] = useState<IPlanningSession[]>([]);
  const [showPrint, setShowPrint] = useState(false);
  const [recursos, setRecursos] = useState<
    { id: number; attributes: RecursoEducativo }[]
  >([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getResources = async (allResources: number[]) => {
    try {
      if (allResources.length) {
        // @ts-ignore: Unreachable code error
        const ids = [...new Set(allResources)];
        const query = {
          sort: ['id:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            id: { $in: ids },
          },
        };
        const response = await getStrapiCatalog(
          qs.stringify(query),
          '/recursos-educativos'
        );
        setRecursos(
          response.data as {
            id: number;
            attributes: RecursoEducativo;
          }[]
        );
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getPlanningSessions = async (
    _planning: IPlanning,
    jwtToken: string
  ) => {
    try {
      const response = await searchPlanningSession(
        { planningId: _planning._id },
        jwtToken
      );
      setSessions(response.docs as IPlanningSession[]);
      const ses = response.docs as IPlanningSession[];
      if (ses.length > 0 ) {
        const allR: StrapiDoc[] = [];
        ses.map((s) => {
          const { introduccion, desarrollo, cierre } = s;
          [introduccion, desarrollo, cierre].forEach((section) => {
            if (section) {
              const { resources } = section;
              if (resources && resources.length) {
                allR.push(...resources);
              }
            }
          });
        })
        if (allR.length) {
          getResources(
            allR.map((r) => Number(r.documentId))
          );
        }
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchEscuela = async (documentId: string) => {
    try {
      const resp = await getAnStrapiEntry('/escuelas', documentId);
      setEscuela(
        resp.data as {
          id: number;
          attributes: Escuela;
        }
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const isSameUser = () => {
    if (user && planning) {
      const { _id: userId } = user;
      const { userId: planningUserId } = planning;
      if (planningUserId && userId !== (planningUserId as IUser)._id) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-resultado',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser && planningId) {
              setUser({ ...kameUser, token: jwtToken });
              getPlanning(planningId, jwtToken)
                .then((resp) => {
                  setPlanning(resp);
                  getPlanningSessions(resp, jwtToken);
                  if ('userCCT' in resp && resp.userCCT.documentId) {
                    fetchEscuela(resp.userCCT.documentId);
                  }
                })
                .catch((error) => setError(error));
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  const handlePrint = () => {
    window.print();
    setTimeout(() => {
      setShowModalTooltipComponent(true);
    }, 300);
  };

  const handlePdf = () => {
    trackEvent({
      event: 'pdfDownload',
      page: '/planeacion-resultado',
      planningId,
    });
    const data = document.getElementById('print-placeholder');
    if (data) {
      const pdf = new JSPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
      });

      pdf.setProperties({
        title: 'PACO Teacher | Planeación',
        subject: planning.name || '',
        author: planning.autor || '',
        keywords: planning.description || '',
        creator: `${user.name || ''} ${user.lastName || ''}`,
      });

      pdf.setDisplayMode(1);
      pdf.html(data, {
        callback: (pdf) => {
          const fileName = planning.name || '';
          pdf.save(fileName.toLowerCase().replace(/\s/g, '-'));
          setIsLoading(false);
          setShowPrint(false);
        },
        html2canvas: { scale: 0.42 },
        margin: [5, 10, 20, 13],
      });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {sessions.length ? (
        <ModalPublicPlanning
          planning={planning}
          sessions={sessions}
          show={showModalPublic}
          size="md"
          onHide={() => setShowModalPublic(!showModalPublic)}
          setPlanning={setPlanning}
        />
      ) : (
        <></>
      )}
      <ModalSharePlanning
        planning={planning}
        show={showModalShare}
        size="md"
        onHide={() => setShowModalShare(!showModalShare)}
      />
      {showModalTooltipComponent ? (
        <ModalTooltipComponent
          title="Revisar planeación"
          description="Revisa los campos que has capturado hasta el momento y editalos de ser necesario. Si no has vinculado una institución académica a tu planeación aquí podrás hacerlo."
        />
      ) : (
        <></>
      )}
      <HeaderLoginComponent />
      <div className="app-section__top">
        <BreadcrumbComponent
          url={
            isSameUser()
              ? `/planeacion-campos/${planning._id}`
              : `/planeacion-sesiones/${planningId}`
          }
          back="Regresar"
          urlSecond={isSameUser() ? '/mis-planeaciones' : '/planeaciones/reformas-anteriores'}
          urlSecondText={
            isSameUser() ? 'Mis planeaciones guardadas' : 'Planeaciones para descargar'
          }
          urlThird={`/planeacion-resultado/${planning._id}`}
          urlThirdText={`${planning.name || ''}`}
        />
        <div className="result-block__wrapper">
          <div className="result-block container">
            <div className="result-block__row row">
              <div className="result-block__planning col-lg-7 col-md-10 col-12 mx-auto">
                <StepperPlanningComponent
                  status={4}
                  planningId={planningId}
                  canGoNext={Object.keys(planning.userCCT || {}).length > 0}
                />
              </div>
              <div className="result-block__title col-12">
                <h1>{`${planning.name || ''}`}</h1>
              </div>
            </div>
            <div className="result-block__row row">
              {planning && planning.userId ? (
                <div className="result-block__col col-lg-4 col-md-6 col-12">
                  <p>Autor</p>
                  <h4>
                    <strong>{`${
                      planning.isAnonymous
                        ? 'Anónima'
                        : (planning.userId as IUser).alias || ''
                    }`}</strong>
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {isSameUser() && sessions && sessions.length ? (
                <>
                  <div className="result-block__col col-lg-3 col-6">
                    <p className="mb24">Hacer planeación pública</p>
                    <SwitchComponent
                      id="publicUrl"
                      checked={planning.isPublic}
                      onChangeEvent={(checked) => {
                        if (checked) {
                          setShowModalPublic(true);
                        } else {
                          trackEvent({
                            event: 'unPublishPlanning',
                            page: '/planeacion-resultado',
                            isPublic: checked,
                          });
                          updatePlanning(
                            planning._id || '',
                            { isPublic: checked, isAnonymous: false },
                            `${user.token}`
                          ).then(() =>
                            setPlanning({
                              ...planning,
                              isPublic: checked,
                              isAnonymous: false,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="result-block__col result-block__share col-lg-5 col-12">
                    <ButtonActionComponent
                      name="Compartir"
                      icon="share"
                      onClick={(evt: any) => {
                        evt.preventDefault();
                        setShowModalShare(true);
                      }}
                    />
                    <button
                      className="btn btn--action"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setShowModalTooltipComponent(false);
                        setTimeout(() => {
                          handlePrint();
                        }, 300);
                      }}
                    >
                      <p>Imprimir</p>
                      <span className="material-icons-outlined">print</span>
                    </button>
                    <button
                      className="btn btn--action"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsLoading(true);
                        setShowPrint(true);
                        setTimeout(() => {
                          handlePdf();
                        }, 200);
                      }}
                    >
                      <p>Descargar</p>
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="result-block__col col-md-4 col-12">
                <p>Reforma</p>
                <h4>
                  <strong>{`${planning.modeloEducativo?.value || ''}`}</strong>
                </h4>
              </div>
              {planning.campoFormacion && planning.campoFormacion.documentId ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Campo de formación académica y áreas de desarrollo</p>
                  <h4>{`${planning.campoFormacion?.value || ''}`}</h4>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="result-block__row row">
              <div className="result-block__col col-md-4 col-12">
                <p>Grado y grupo</p>
                <h4>{`${planning.gradoEscolar?.value || ''} - ${
                  planning.grupoEscolar?.value || ''
                }`}</h4>
              </div>
              <div className="result-block__col col-md-4 col-12">
                <p>Asignatura</p>
                <h4>{`${planning.asignatura?.value || ''}`}</h4>
              </div>
            </div>
            <div className="result-block__row row">
              {planning.bloque && planning.bloque.documentId ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Bloque</p>
                  <h4>{`${planning.bloque?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
              {planning.eje ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Eje</p>
                  <h4>{`${planning.eje?.value || ''}`}</h4>
                </div>
              ) : (
                <></>
              )}
              {planning.tema ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Tema</p>
                  <h4>{`${planning.tema?.value || ''}`}</h4>
                </div>
              ) : (
                <></>
              )}
            </div>
            {(planning.aprendizajesFundamentales || []).length ? (
              <div className="result-block__row row">
                <div className="result-block__col col-md-8 col-12">
                  <p>Aprendizajes fundamentales</p>
                  <ul className="result-block__li">
                    {(planning.aprendizajesFundamentales || []).map(
                      (aprendizaje, k) => (
                        <li key={k}>
                          <span className="material-icons-outlined">east</span>
                          <p>{aprendizaje.value}</p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
            {(planning.aprendizajes || []).length ? (
              <div className="result-block__row row">
                <div className="result-block__col col-md-8 col-12">
                  <p>Aprendizajes esperados</p>
                  <ul className="result-block__li">
                    {(planning.aprendizajes || []).map((aprendizaje, k) => (
                      <li key={k}>
                        <span className="material-icons-outlined">east</span>
                        <p>{aprendizaje.value}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
            {(planning.competencias || []).length ? (
              <div className="result-block__row row">
                <div className="result-block__col col-md-8 col-12">
                  <p>Competencias</p>
                  <ul className="result-block__li">
                    {(planning.competencias || []).map((competencia, k) => (
                      <li key={k}>
                        <span className="material-icons-outlined">east</span>
                        <p>{competencia.value}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {isSameUser() ? (
            <div className="result-block container">
              <div className="result-block__row row">
                <div className="result-block__col col-12">
                  <h3>Institución</h3>
                  {!user.userCCTs?.length ? (
                    <span className="badge bg-alert text-dark">
                      Completa la información en tu perfil para agregar tus
                      datos automáticamente.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {escuela ? (
                <div>
                  <div className="result-block__row row">
                    <div className="result-block__col col-md-4 col-12">
                      <p>Nombre de la Institución Educativa</p>
                      <h4>
                        <strong>{`${escuela.attributes.Nombre || ''}`}</strong>
                      </h4>
                    </div>
                    <div className="result-block__col col-md-4 col-12">
                      <p>Nombre del docente</p>
                      <h4>{`${planning.autor || ''}`}</h4>
                    </div>
                    <div className="result-block__col col-md-4 col-12">
                      <p>Clave del centro de trabajo CCT</p>
                      <h4>{`${escuela.attributes.CCT || ''}`}</h4>
                    </div>
                  </div>
                  <div className="result-block__row row">
                    <div className="result-block__col col-md-4 col-12">
                      <p>Localidad</p>
                      <h4>{`${escuela.attributes.Localidad || ''}`}</h4>
                    </div>
                    {planning.isNewCiclo ? (
                      <div className="result-block__col col-md-4 col-12">
                        <p>Ciclo escolar</p>
                        <h4>Ciclo escolar 2022 - 2023</h4>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!escuela && (user.userCCTs || []).length > 1 ? (
                <div>
                  Selecciona tu escuela
                  {(user.userCCTs || []).map((e, k) => {
                    return (
                      <CheckboxComponent
                        key={k}
                        id={`${e.documentId}`}
                        htmlFor={`${e.documentId}`}
                        text={`${e.cct} | ${e.Nombre}`}
                        checked={escuelaSelected === e.documentId}
                        onChangeEvent={(checked) => {
                          if (checked) {
                            setEscuelaSelected(e.documentId);
                            return Swal.fire({
                              icon: 'question',
                              title: 'Añade tu escuela',
                              text: `¿Deseas vincular el CCT: ${e.cct} a esta planeación?`,
                              showCancelButton: true,
                              confirmButtonText: 'Vincular',
                              cancelButtonText: 'Cancelar',
                              showLoaderOnConfirm: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                return fetchEscuela(e.documentId).then(() => {
                                  return updatePlanning(
                                    `${planning._id}`,
                                    {
                                      userCCT: {
                                        documentId: e.documentId,
                                        value: e.cct,
                                      },
                                    },
                                    `${user.token}`
                                  ).catch((error) => setError(error));
                                });
                              } else {
                                setEscuelaSelected('');
                              }
                            });
                          } else {
                            setEscuelaSelected('');
                          }
                          console.log(checked);
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
              <div className="result-block__row row">
                {!user.userCCTs?.length ? (
                  <div className="result-block__col col-12 mt24">
                    <ButtonTypeOutlineComponent
                      url={`/mi-centro-educativo/${planningId}`}
                      name="Completar información"
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="result-block container">
            {planning.description && planning.description !== '' ? (
              <div className="result-block__row row">
                <div className="result-block__colcol-12">
                  <p>Descripción</p>
                  <h4>{planning.description || ''}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            {planning.period && planning.period !== '' ? (
              <div className="result-block__row row">
                <div className="result-block__colcol-12">
                  <p>Periodo de tiempo</p>
                  <h4>{planning.period || ''}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            {planning.dateRanges &&
            planning.dateRanges.start > 0 &&
            planning.dateRanges.end > 0 ? (
              <div className="result-block__row row">
                <div className="result-block__colcol-12">
                  <p>Rango de fechas</p>
                  <h4>{`${
                    format(
                      fromUnixTime(planning.dateRanges.start),
                      'dd . MMMM . yy'
                    ) || ''
                  } - ${
                    format(
                      fromUnixTime(planning.dateRanges.end),
                      'dd . MMMM . yy'
                    ) || ''
                  }`}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            {planning.apClave && planning.apClave !== '' ? (
              <div className="result-block__row row">
                <div className="result-block__colcol-12">
                  <p>Aprendizajes clave</p>
                  <h4>{planning.apClave || ''}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            {planning.propPed && planning.propPed !== '' ? (
              <div className="result-block__row row">
                <div className="result-block__colcol-12">
                  <p>Propósitos pedagógicos</p>
                  <h4>{planning.propPed || ''}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            {planning.contexto && planning.contexto !== '' ? (
              <div className="result-block__row row">
                <div className="result-block__colcol-12">
                  <p>Contexto en el aula</p>
                  <h4>{planning.contexto || ''}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="result-block result-block__action container">
            <div className="form__row form__action form__action--both">
              {isSameUser() ? (
                <ButtonTypeOutlineComponent
                  url={`/planeacion-aprendizajes/${planning._id || ''}`}
                  name="Editar"
                />
              ) : (
                <></>
              )}
              <ButtonType3Component
                url={`/sesiones/${planning._id || ''}`}
                name="Continuar"
              />
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
      <div id="print-placeholder" className="toPrint">
        <PrintPlanningScreen
          planning={planning}
          sessions={sessions}
          escuela={escuela}
          show={showPrint}
          recursos={recursos}
        />
      </div>
    </section>
  );
};

export default PlanningThreeScreen;
