import React, { useState, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import AsideComponent from '../../../components/Aside/aside.component';
import InputComponent from '../../../components/Form/input/input.component';
import ButtonType3Component from '../../../components/Button/buttonType3/buttonType3.component';

import './password.screen.scss';

const PasswordScreen = () => {
  const { trackEvent } = useTracking();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordTwoShown, setPasswordTwoShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const togglePasswordTwo = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordTwoShown(!passwordTwoShown);
  };
  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/nueva-contrasena',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="app-section">
      <HeaderLogoutComponent />
      <div className="app-section__top">
        <BreadcrumbComponent url="/" back="Regresar" />
        <div className="container">
          <div className="row">
            <div className="register__col-left register--to-mobile login--to-mobile col-md-4 col-12">
              <AsideComponent
                title="Crea una nueva contraseña"
                list=""
                image="/assets/images/aside/aside1.png"
                alt="Registro"
              />
            </div>
            <div className="register__col-right col-md-8 col-12">
              <div className="form-init ">
                <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                  <h3 className="form__title">Ingresa tu contraseña nueva</h3>
                  <form>
                    <div className="form__row">
                      <label htmlFor="passwordOne" className="form__label">
                        Ingresar contraseña
                      </label>
                      <div className="form-control__password">
                        <InputComponent
                          type={passwordShown ? 'text' : 'password'}
                          id="passwordOne"
                          placeholder="Escribe una contraseña"
                        />
                        <button
                          type="button"
                          className="form-control__password-action"
                          onClick={togglePassword}
                          data-active={passwordShown ? 'visible' : 'off'}
                        >
                          <span className="material-icons-outlined form-control__password-visible">
                            visibility
                          </span>
                          <span className="material-icons-outlined form-control__password-off">
                            visibility_off
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="form__row">
                      <label htmlFor="passwordOne" className="form__label">
                        Ingresar contraseña nuevamente
                      </label>
                      <div className="form-control__password">
                        <InputComponent
                          type={passwordTwoShown ? 'text' : 'password'}
                          id="passwordOne"
                          placeholder="Escribe una contraseña"
                        />
                        <button
                          type="button"
                          className="form-control__password-action"
                          onClick={togglePasswordTwo}
                          data-active={passwordTwoShown ? 'visible' : 'off'}
                        >
                          <span className="material-icons-outlined form-control__password-visible">
                            visibility
                          </span>
                          <span className="material-icons-outlined form-control__password-off">
                            visibility_off
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="form__row form__action mt32">
                      <ButtonType3Component url="/" name="Recuperar" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PasswordScreen;
