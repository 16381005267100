import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './footer.component.scss';
import ModalReportsComponent from '../../components/Modal/modalReport/modalReports.component';
import ModalSuggestionComponent from '../Modal/modalSuggestion/modalSuggestion.component';

const FooterComponent = () => {
  const [modalOptionsShow, setModalOptionsShow] = useState(false);
  const [modalSugShow, setModalSugShow] = useState(false);
  return (
    <footer className="footer">
      <ModalReportsComponent
        show={modalOptionsShow}
        onHide={() => setModalOptionsShow(false)}
        size="md"
        url={`/`}
      />
      <ModalSuggestionComponent
        show={modalSugShow}
        onHide={() => setModalSugShow(false)}
        correo={true}
      />
      <div className="container">
        <div className="footer__row row">
          <div className="footer__col-logo col-lg-2 col-12">
            <Link to="/" className="footer__logo">
              <figure>
                <img src="/assets/images/logo/a22-logo.svg" alt="PACO Teacher" />
              </figure>
            </Link>
            <ul className="social-list">
              <li>
                <a
                  href="https://www.facebook.com/pacoteacher"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@pacoteachermx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-tiktok"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/pacoteachermx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className=" col-md-4 col-12">
            <ul className="footer__nav">
                <li>
                  <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
                </li>
                <li>
                  <Link to="/preguntas-frecuentes">Ayuda</Link>
                </li>
                <li>
                  <a href="mailto:contacto@pacoteacher.com">Contáctanos</a>
                </li>
            </ul>
          </div>
          <div className="footer__col-tools col-lg-4 col-12">
            <div className="footer__comparte">
              <a className='opinion'
                onClick={() => {
                  setModalSugShow(true)
                }}>
                <span className="">Comparte tu opinión</span>
              </a>
            </div>
            <div className="footer__tool">
              <button
                onClick={() => {
                  setModalOptionsShow(true)
                }}
                className="btn btn--tool">
                  <span className="material-icons-outlined">build</span>Reportar problema
              </button>
            </div>
            <article className="footer__privacy">
              <p>Copyright  PACOTeacher ©.<br /><Link to="/terminos-y-condiciones">Términos y condiciones</Link> | <Link to="/aviso-de-privacidad">Aviso de privacidad</Link></p>
            </article>
          </div>
        </div>
        
      </div>
    </footer>
  );
};

export default FooterComponent;
