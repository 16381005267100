import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './modalNps.component.scss';
import Slider from '@mui/material/Slider';
import { useTracking } from 'react-tracking';
import Swal from 'sweetalert2';
import TextareaComponent from '../../Form/textarea/textarea.component';


type Props = {
  show: boolean;
  onHide: () => void;
  size: string;
  url: string;
};


const ModalNPSComponent = ({ show, onHide, size, url }: Props) => {

  const { trackEvent } = useTracking();
  const [recommend, setRecommend] = useState<number | number []>(70);
  const [experience, setExperience] = useState<{}[]>([]);
  const [comments, setComments] = useState<string>('');

  const marks = [
    {
      value: 0,
      label: 'No'
    },
    {
      value: 10,
    },
    {
      value: 20,
    },
    {
      value: 30,
    },
    {
      value: 40,
    },
    {
      value: 50,
    },
    {
      value: 60,
    },
    {
      value: 70,
    },
    {
      value: 80,
    },
    {
      value: 90,
    },
    {
      value: 100,
      label: 'Sí'
    },
  ];
 
  const opciones = [
    {
      id: 1,
      value: 'Me gusta el contenido',
    },
    {
      id: 2,
      value: 'Me ayuda con mis clases',
    },
    {
      id: 3,
      value: 'Me ayuda con mis planeaciones',
    },
  ];

  const confirmSend = async () => {
    if (experience.length>0) {
      trackEvent({
        event: 'npsReport',
        fullPath: window.location.href,
        npsReport: (recommend as any /10),
        npsExperience: experience,
        comments
      });
      Swal.fire({
        html: `<div className='modal__sug-top'>
          <h4>¡Gracias! Tu opinión fue enviada a nuestro equipo.</h4>
        </div>`,
        imageUrl: "../../assets/images/elements/Julia-02.png",
        color: '#003980',
        imageWidth: 125,
        imageHeight: 100,
        imageAlt: 'Gracias',
        showCloseButton: true,
        showConfirmButton: false,
        timer: 3500,
      }).then(() => {
        onHide();
      });
    }else {
      Swal.fire('Ooops', 'Captura todos los datos', 'warning');
    }
    return true;
  };
  
  function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) ;
    
  }
 
  return (
    <Modal
      show={show}
      onHide={onHide}
      url={url}
      size={size as any}
      aria-labelledby="modal-nps"
      centered
      className="modal--nps"
      backdrop="static"
    >
      <Modal.Header className="modal__title-icon">
        <Modal.Title id="modal-nps">
          <h4>¡Hola!<br />Nos interesa<br /> tu opinión...</h4>
        </Modal.Title>
          <figure className="modal-image-back">
            <img src="../../assets/images/elements/Julia-02.png" alt="Julia" />
          </figure>
          <button
            className="modal__close close--red"
            onClick={(e) => {
              e.preventDefault();
              onHide();
            }}
          >
            <span className="material-icons-outlined icon">close</span>
          </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal__nps-top col-12">
          <h4 className="text-center">¿Recomendarías PACO Teacher a otro maestro?</h4>
        </div>
        <div className="modal__nps-slider col-12">
          <Slider
              aria-label="Always visible"
              defaultValue={70}
              valueLabelFormat={valueLabelFormat}
              step={10}
              marks={marks}
              valueLabelDisplay="on"
              onChangeCommitted={ (e, value) => setRecommend(value) }
            />
            <figure className="nps-emoji emoji--left">🙁</figure>
            <figure className="nps-emoji emoji--right">😍</figure>
        </div>
        <div className="modal__nps-middle col-12" 
          onChange={(event) => {
            setExperience([
              ...experience, (event.target as any).value
            ])
          }}>
          <h6 className="text-center">¿Cómo ha sido tu experiencia?</h6>
          <div className="form-group form-check nps-checkboxes">
            {opciones.map((nps, k) => {
              return (
                <div className="nps-checkbox" key={k}>
                  <input
                    type="radio"
                    name={`nps-checkbox-${nps.id}`}
                    id={`nps-checkbox-${nps.id}`}
                    value={nps.value}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${nps.id}`}
                  >
                    {nps.value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="modal__nps-middle col-12">
          <h6 className="text-center">¿Tienes algún comentario o sugerencia?</h6>
          <TextareaComponent
            id="comments"
            placeholder="Escribe un comentario o sugerencia..."
            value={comments || ''}
            onChangeCapture={(evt) =>
              setComments(
                (evt?.target as HTMLInputElement).value || '',
              )
            }
          />
        </div>
        <div className="modal__nps-bottom col-12">
          <p className="text-center">Ver <a href="/terminos-y-condiciones">términos y condiciones</a> y <a href="/aviso-de-privacidad">aviso de privacidad</a></p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={(e) => {
            e.preventDefault();
            confirmSend();
          }}
          className="btn btn--type3"
        >
          Listo
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNPSComponent;
