import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import SelectComponent from '../../../components/Form/select/select.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import CheckboxComponent from '../../../components/Form/checkbox/checkbox.component';
import ChatGpt from '../../../components/ChatGpt/chatGpt';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import {
  Aprendizaje,
  Bloque,
  ContenidoSintetico,
  Eje,
  Entidad,
  Grado,
  InstrumentosData,
  Materia,
  ModeloEducativoData,
  Pda,
  ProyectoNem,
} from '../../../types/EntidadesInterface';
import { getModeloEducativo, getStrapiCatalog } from '../../../strapi/escuelas';
import { getFlexiblePlanning } from '../../../api/flexiblePlanningsApi';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
} from '../../../types/FlexiblePlanningInterface';
import LoaderComponent from '../../../components/Loader/loader.component';

const InstrumentosIndexScreen = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { planningId, asignaturaId, sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IFlexiblePlanning>>({});
  const [asignatura, setAsignatura] = useState<
    Partial<IFlexiblePlanningAsignatura>
  >({});
  const [modelos, setModelos] = useState<ModeloEducativoData[]>([]);
  const [grados, setGrados] = useState<{ id: number; attributes: Grado }[]>([]);
  const [materias, setMaterias] = useState<
    { id: number; attributes: Materia }[]
  >([]);
  const [bloques, setBloques] = useState<{ id: number; attributes: Bloque }[]>(
    []
  );
  const [ejes, setEjes] = useState<{ id: number; attributes: Eje }[]>([]);
  const [temas, setTemas] = useState<{ id: number; attributes: Eje }[]>([]);
  const [aprendizajes, setAprendizajes] = useState<
    {
      id: number;
      attributes: Aprendizaje;
    }[]
  >([]);
  const [instrumento, setInstrumento] = useState<Partial<InstrumentosData>>({});
  const [habilidades, setHabilidades] = useState<
    { id: number; attributes: Entidad }[]
  >([]);
  const [reactivos, setReactivos] = useState<
    { id: number; attributes: Entidad }[]
  >([]);
  const [queevaluar, setQueEvaluar] = useState<
    { id: number; attributes: Entidad }[]
  >([]);
  const [evalOtro, setEvalOtro] = useState(false);
  const [criterios, setCriterios] = useState<
    { id: number; attributes: Entidad }[]
  >([]);
  const [desempeno, setDesempeno] = useState<
    { id: number; attributes: Entidad }[]
  >([]);
  const [detalles, setDetalles] = useState<
    { id: number; attributes: Entidad }[]
  >([]);
  const [campoF, setCampoF] = useState<{ id: number; attributes: Entidad }[]>(
    []
  );
  const [proyectos, setProyectos] = useState<
    { id: number; attributes: ProyectoNem }[]
  >([]);
  const [pda, setPda] = useState<{ id: number; attributes: Pda }[]>([]);
  const [contenidoS, setContenidoS] = useState<
    { id: number; attributes: ContenidoSintetico }[]
  >([]);
  const [libros, setLibros] = useState<{ id: number; attributes: Entidad }[]>(
    []
  );

  const type = [
    { id: 1, value: 'Examen' },
    { id: 2, value: 'Rúbrica' },
  ];

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchCatalog = async (query: string, route: string) => {
    try {
      const result = await getStrapiCatalog(query, route);
      if (result.data.length) {
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return [];
  };

  const fetchMaterias = async (modeloEducativoId: string, gradoId: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
    };
    let filt: any[] = [{ grados: { id: { $eq: gradoId } } }];
    if (modeloEducativoId != '') {
      filt.push({ modelo_educativo: { id: { $eq: modeloEducativoId } } });
    } else {
      filt.push({ modelo_educativo: { id: { $eq: '1' } } });
    }
    const resultMaterias = await fetchCatalog(
      qs.stringify({
        ...query,
        sort: ['Materia:asc'],
        filters: { $and: filt },
      }),
      '/materias'
    );
    setMaterias(
      resultMaterias as {
        id: number;
        attributes: Materia;
      }[]
    );
  };

  const fetchBloques = async (asignaturaS: string, gradoS: string) => {
    if (asignaturaS && asignaturaS !== '' && gradoS && gradoS !== '') {
      const query = {
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
        filters: {
          $and: [
            {
              materias: { id: { $eq: asignaturaS } },
              grados: { id: { $eq: gradoS } },
            },
          ],
        },
      };
      const resultBloques = await fetchCatalog(
        qs.stringify({ ...query }),
        '/bloques'
      );
      setBloques(
        resultBloques as {
          id: number;
          attributes: Bloque;
        }[]
      );
    }
  };

  const fetchEvaluaciones = async () => {
    try {
      const query = {
        sort: ['id:asc'],
      };
      const insthab = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/instrumento-habilidades'
      );
      const instreac = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/instrumento-reactivos'
      );
      setHabilidades(
        insthab as {
          id: number;
          attributes: Entidad;
        }[]
      );
      setReactivos(
        instreac as {
          id: number;
          attributes: Entidad;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchRubricas = async () => {
    try {
      const query = {
        sort: ['id:asc'],
      };
      const instevl = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/instrumento-evaluaciones'
      );
      const instcriterios = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/instrumento-criterios'
      );
      const instdes = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/instrumento-desempenos'
      );
      const instdetll = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/instrumento-detalles'
      );
      setQueEvaluar(
        instevl as {
          id: number;
          attributes: Entidad;
        }[]
      );
      setCriterios(
        instcriterios as {
          id: number;
          attributes: Entidad;
        }[]
      );
      setDesempeno(
        instdes as {
          id: number;
          attributes: Entidad;
        }[]
      );
      setDetalles(
        instdetll as {
          id: number;
          attributes: Entidad;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchCatalogs = async () => {
    try {
      const query = {
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
      };
      const resultGrados = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/grados'
      );

      setGrados(
        resultGrados as {
          id: number;
          attributes: Grado;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchEjesOrTemas = async (
    gradoS: string,
    materiaS: string,
    bloqueS: string,
    ejeS: string,
    path: '/ejes' | '/temas'
  ) => {
    try {
      const { modeloEducativo } = instrumento;
      const valid = [gradoS, materiaS, modeloEducativo?.documentId].every(
        (k) => k && k !== ''
      );
      const hasBloques =
        modeloEducativo?.documentId === '1'
          ? !!(bloqueS && bloqueS !== '')
          : true;
      if (valid && hasBloques) {
        const ands: any[] = [
          { grados: { id: { $eq: gradoS } } },
          { materia: { id: { $eq: materiaS } } },
          {
            modelo_educativo: { id: { $eq: modeloEducativo?.documentId } },
          },
        ];
        if (path === '/ejes' && modeloEducativo?.documentId === '1') {
          ands.push({ bloques: { id: { $eq: bloqueS } } });
        }
        if (path === '/temas' && ejeS) {
          ands.push({ ejes: { id: { $eq: ejeS } } });
        }
        const query = {
          sort: ['Nombre:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            $and: ands,
          },
        };
        const result = await getStrapiCatalog(qs.stringify(query), path);
        switch (path) {
          case '/ejes': {
            setEjes(
              result.data as {
                id: number;
                attributes: Eje;
              }[]
            );
            break;
          }
          case '/temas': {
            if (ejeS) {
              setTemas(
                result.data as {
                  id: number;
                  attributes: Eje;
                }[]
              );
            }
            break;
          }
        }
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return null;
  };

  const handleMateria = (evt: any) => {
    const materia = materias.filter((m) => `${m.id}` === evt);
    const resetInst = { ...instrumento };
    delete resetInst.eje;
    delete resetInst.tema;
    setInstrumento({
      ...resetInst,
      asignatura: {
        documentId: evt,
        value: materia.length ? materia[0].attributes.Materia : '',
      },
      bloque: {
        documentId: '',
        value: '',
      },
      aprendizajes: [],
    });
    if (instrumento.gradoEscolar) {
      fetchBloques(evt, instrumento.gradoEscolar.documentId);
      fetchEjesOrTemas(
        instrumento.gradoEscolar.documentId,
        evt,
        instrumento.bloque?.documentId || '',
        instrumento.eje?.documentId || '',
        '/ejes'
      );
    }
  };

  const fetchAprendizajes = async (
    bloque?: string,
    eje?: string,
    tema?: string
  ) => {
    if (instrumento.gradoEscolar && instrumento.asignatura) {
      const ands: any = [
        {
          grado: {
            id: { $eq: instrumento.gradoEscolar.documentId },
          },
        },
        {
          materia: {
            id: { $eq: instrumento.asignatura.documentId },
          },
        },
        {
          modelo_educativo: {
            id: { $eq: instrumento.modeloEducativo?.documentId },
          },
        },
      ];
      if (instrumento.eje || eje) {
        ands.push({
          ejes: {
            id: { $eq: eje || instrumento.eje?.documentId },
          },
        });
      }
      if (instrumento.tema || tema) {
        ands.push({
          temas: {
            id: { $eq: tema || instrumento.tema?.documentId },
          },
        });
      }
      if (instrumento.bloque?.documentId || bloque) {
        ands.push({
          bloque: {
            id: { $eq: bloque || instrumento.bloque?.documentId },
          },
        });
      }
      const query = {
        sort: ['Nombre:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
        filters: { $and: ands },
      };
      fetchCatalog(qs.stringify(query), '/aprendizajes')
        .then((r) =>
          setAprendizajes(
            r as {
              id: number;
              attributes: Aprendizaje;
            }[]
          )
        )
        .catch((error) => setError(error));
    }
  };

  const fetchCampos = async () => {
    const resultCampoF = await fetchCatalog(
      qs.stringify({ sort: ['id:asc'], pagination: { pageSize: 50 } }),
      '/nem-campos-formativos'
    );
    setCampoF(
      resultCampoF as {
        id: number;
        attributes: Entidad;
      }[]
    );
  };
  const searchContenido = async (g: string, cf: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
      filters: {
        $and: [
          { grados: { id: { $eq: g } } },
          {
            nem_campo_formativo: {
              id: { $eq: cf },
            },
          },
        ],
      },
    };
    const resultConten = await fetchCatalog(
      qs.stringify({ ...query }),
      '/nem-contenido-sinteticos'
    );
    setContenidoS(
      resultConten as {
        id: number;
        attributes: ContenidoSintetico;
      }[]
    );
  };

  const searchPda = async (cs: string, g: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
      filters: {
        $and: [
          { nem_contenido_sintetico: { id: { $eq: cs } } },
          { grado: { id: { $eq: g } } },
        ],
      },
    };
    const resultPda = await fetchCatalog(
      qs.stringify({ ...query }),
      '/nem-pdas'
    );
    setPda(
      resultPda as {
        id: number;
        attributes: Pda;
      }[]
    );
  };

  const searchLibros = async () => {
    const resultLibros = await fetchCatalog(
      qs.stringify({ sort: ['id:asc'], pagination: { pageSize: 50 } }),
      '/nem-libros'
    );
    setLibros(
      resultLibros as {
        id: number;
        attributes: Entidad;
      }[]
    );
  };

  const searchProyectos = async (g: string, l: string, cf: string) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
      filters: {
        $and: [
          { grado: { id: { $eq: g } } },
          { nem_libro: { id: { $eq: l } } },
          {
            nem_campo_formativo: {
              id: { $eq: cf },
            },
          },
        ],
      },
    };
    const resultProyectos = await fetchCatalog(
      qs.stringify({ ...query }),
      '/nem-proyectos'
    );
    setProyectos(
      resultProyectos as {
        id: number;
        attributes: ProyectoNem;
      }[]
    );
  };

  const handleChatButtonClick = (opt: number) => {
    if (opt == 8) {
      setIsLoadingChat(true);
    }
  };

  const handleChatOptionButtonClick = (msj: string, opt: number) => {
    if (msj === 'error') {
      setIsLoadingChat(false);
    } else if (msj && opt === 8) {
      navigate(`/instrumento-detalle/${msj}`);
    }
  };

  const getPlanningData = async (id: string, token: string) => {
    const foundedPlanning = await getFlexiblePlanning(id, token);

    if (foundedPlanning && Object.keys(foundedPlanning).length) {
      setIsLoading(false);
      setPlanning(foundedPlanning);
      const indexA = foundedPlanning.asignaturas?.findIndex(
        (asig) => asig._id == asignaturaId
      );
      const asigP = foundedPlanning.asignaturas
        ? foundedPlanning.asignaturas[indexA || 0]
        : {};
      setAsignatura(asigP);

      setInstrumento({
        ...instrumento,
        planningId,
        asignaturaId,
        sessionId,
        gradoEscolar: foundedPlanning.gradoEscolar,
        modeloEducativo: foundedPlanning.modeloEducativo,
        asignatura: asigP.asignatura,
        bloque: asigP.bloque ? asigP.bloque : undefined,
        eje: asigP.eje ? asigP.eje : undefined,
        tema: asigP.tema ? asigP.tema : undefined,
        aprendizajes: asigP.aprendizajes ? asigP.aprendizajes : undefined,
      });
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/instrumentos-evaluacion',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then(async (kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            setUser({ ...kameUser, token: jwtToken });
            if (planningId) {
              setIsLoading(true);
              getPlanningData(planningId, jwtToken);
            } else {
              getModeloEducativo()
                .then((resultEntidades) => {
                  if (resultEntidades.data.length) {
                    setModelos(resultEntidades.data);
                  }
                  setIsLoading(false);
                })
                .catch((error) => setError(error));
              fetchCatalogs();
            }
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  const handleLimpiar = async () => {
    trackEvent({
      event: 'cleanFilters',
      page: '/instrumentos-evaluacion',
      fullPath: window.location.href,
    });
    setGrados([]);
    setModelos([]);
    setBloques([]);
    setMaterias([]);
    setEjes([]);
    setTemas([]);
    setBloques([]);
    setAprendizajes([]);
    setCampoF([]);
    setContenidoS([]);
    setPda([]);
    setLibros([]);
    setProyectos([]);
    setInstrumento({
      infoAd: '',
    });
    getModeloEducativo()
      .then((resultEntidades) => {
        if (resultEntidades.data.length) {
          setModelos(resultEntidades.data);
        }
        setIsLoading(false);
      })
      .catch((error) => setError(error));
    fetchCatalogs();
  };

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <LoaderComponent
        dataClass={isLoadingChat}
        text="ARTI está generando tu evaluación..."
      />

      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={'/bienvenido'}
          customNavigate={() => {
            navigate(-1);
          }}
          back="Regresar"
          urlSecond={'/bienvenido'}
          urlSecondText={'Prepara tu clase'}
          urlThird={'/instrumentos-de-evaluacion'}
          urlThirdText={'Instrumentos de evaluación'}
        />
        <section className="header-activity-banner header--confetti">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">
                  Instrumentos de <br /> evaluación
                </h1>
              </div>
              <figure className="strategy-image strategy--left instrumento">
                <img src="/assets/images/elements/arti-icon.png" alt="Arti" />
              </figure>
            </div>
          </div>
        </section>
        <section className="container body-activity body--strategies">
          <div className="area-title area-title--decore area--button-action row">
            <div className="col-12">
              <div className="area-title__button">
                <Link to={'/mis-instrumentos'}>
                  <button className="btn btn--type1">
                    Consultar mis evaluaciones guardadas
                  </button>
                </Link>
              </div>
              <h3 className="text-center text-color-purple col-md-4 col-12 col-reset mx-auto">
                Genera{' '}
                <span className="span-text-color-purple">
                  rúbricas y exámenes
                </span>
                <br /> para tus alumnos (incluye NEM){' '}
              </h3>
            </div>
          </div>
          <div className="search-area row">
            <div className="search-area__title col-md-10 col-12 mx-auto">
              <article>
                <h6>¡Hola, maestr@!</h6>
                <p>
                  Con esta herramienta podrás generar cuestionarios cortos con
                  reactivos útiles para todas tus materias. Tendrás la ayuda de
                  ARTI, una inteligencia artificial que te ayudará con ideas
                  focalizadas en tus necesidades.{' '}
                </p>
              </article>
            </div>
            <div className="col-md-10 col-12 mx-auto">
              {planningId ? (
                <div className="strategies-forms row">
                  <div className="strategie--title col-12">
                    <h4>
                      1. Estos son los datos generales
                      <OverlayComponent toolTipText="Aquí esta la información que indica a quién va dirigida tu evaluación, bajo qué modelo educativo (o función libre) y qué temas se van a evaluar." />
                    </h4>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Grado escolar <small className="required">*</small>
                      </label>
                      <p>{planning.gradoEscolar?.value || ''}</p>
                    </div>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Modelo educativo <small className="required">*</small>
                      </label>
                      <p>{planning.modeloEducativo?.value || ''}</p>
                    </div>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Asignatura <small className="required">*</small>
                      </label>
                      <p>{asignatura.asignatura?.value || ''}</p>
                    </div>
                  </div>
                  {asignatura.bloque ? (
                    <div className="strategie__col col-md-4 col-12">
                      <div className="form-group">
                        <label>
                          Bloque <small className="required">*</small>
                        </label>
                        <p>{asignatura.bloque?.value || ''}</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {asignatura.eje ? (
                    <div className="strategie__col col-md-4 col-12">
                      <div className="form-group">
                        <label>
                          Eje <small className="required">*</small>
                        </label>
                        <p>{asignatura.eje?.value || ''}</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {asignatura.tema ? (
                    <div className="strategie__col col-md-4 col-12">
                      <div className="form-group">
                        <label>
                          Tema <small className="required">*</small>
                        </label>
                        <p>{asignatura.tema?.value || ''}</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {asignatura.aprendizajes?.length ? (
                    <div className="strategie__col col-12">
                      <div className="form-group">
                        <label>
                          Aprendizajes esperados{' '}
                          <small className="required">*</small>
                        </label>
                        {asignatura.aprendizajes.map((e, k) => {
                          return (
                            <div className="form-group__inner" key={k}>
                              {e.value}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="strategies-forms row">
                  <div className="strategie--title col-12">
                    <h4>
                      1. Elige los datos generales
                      <OverlayComponent toolTipText="Añade información para indicar a quién va dirigida tu evaluación, bajo qué modelo educativo (o  elige la función libre) y qué temas se van a evaluar." />
                    </h4>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Grado escolar <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label=""
                        id="grado-escolar"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {grados.map((e, k) => {
                              return (
                                <option key={k} value={`${e.id}`}>
                                  {e.id > 6
                                    ? `${e.attributes.Grado} - Secundaria`
                                    : `${e.attributes.Grado}`}
                                </option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          setContenidoS([]);
                          setBloques([]);
                          const grado = grados.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          setInstrumento({
                            ...instrumento,
                            gradoEscolar: {
                              documentId: evt.target.value,
                              value: grado.length
                                ? grado[0].attributes.Grado
                                : '',
                            },
                          });
                          setMaterias([]);
                          setEjes([]);
                          setTemas([]);
                          setBloques([]);
                          setAprendizajes([]);
                          setPda([]);
                          if (instrumento.modeloEducativo) {
                            setTimeout(() => {
                              if (
                                instrumento.modeloEducativo?.documentId != '3'
                              ) {
                                fetchMaterias(
                                  instrumento.modeloEducativo?.documentId || '',
                                  evt.target.value
                                );
                                if (evt.target.value > '6') {
                                  delete instrumento.modeloEducativo;
                                  setCampoF([]);
                                }
                              } else if (instrumento.campoF) {
                                searchContenido(
                                  evt.target.value,
                                  instrumento.campoF?.documentId
                                );
                              }
                            }, 500);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Modelo educativo <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label=""
                        id="modelo-educativo"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {parseInt(
                              instrumento.gradoEscolar?.documentId || '0'
                            ) > 6 ? (
                              <option value="3">Nueva Escuela Mexicana</option>
                            ) : (
                              <>
                                <option value="">Libre</option>
                                {modelos.map((e, k) => {
                                  return (
                                    <option
                                      key={k}
                                      value={`${e.id}`}
                                    >{`${e.attributes.modelo_educativo}`}</option>
                                  );
                                })}
                              </>
                            )}
                          </>
                        }
                        onChange={(evt) => {
                          const modId = evt.target.value;
                          const modelo = modelos.filter(
                            (m) => `${m.id}` === modId
                          );
                          setContenidoS([]);
                          setPda([]);
                          const instDel = { ...instrumento };
                          delete instDel.aprendizajes;
                          delete instDel.asignatura;
                          delete instDel.bloque;
                          delete instDel.eje;
                          delete instDel.tema;
                          delete instDel.campoF;
                          delete instDel.contenidoS;
                          delete instDel.pda;
                          delete instDel.rubricas?.libro;
                          delete instDel.rubricas?.proyecto;

                          setInstrumento({
                            ...instDel,
                            modeloEducativo: {
                              documentId: modId,
                              value: modelo.length
                                ? modelo[0].attributes.modelo_educativo
                                : 'Libre',
                            },
                          });
                          if (modId != '3') {
                            fetchMaterias(
                              modId,
                              instrumento.gradoEscolar?.documentId || ''
                            );
                            setMaterias([]);
                            setEjes([]);
                            setTemas([]);
                            setBloques([]);
                            setAprendizajes([]);
                          } else {
                            fetchCampos();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {instrumento.modeloEducativo?.value == 'Libre' ? (
                    <>
                      <div className="strategie__col col-md-4 col-12">
                        <div className="form-group">
                          <label>
                            Asignatura <small className="required">*</small>
                          </label>
                          <SelectComponent
                            label=""
                            id="asignatura"
                            option={
                              <>
                                <option defaultValue="" hidden>
                                  Seleccionar...
                                </option>
                                {materias.map((e, k) => {
                                  return (
                                    <option
                                      key={k}
                                      value={`${e.id}`}
                                    >{`${e.attributes.Materia}`}</option>
                                  );
                                })}
                              </>
                            }
                            onChange={(event) => {
                              const materia = materias.filter(
                                (m) => `${m.id}` === event.target.value
                              );
                              setTemas([]);
                              setAprendizajes([]);
                              setInstrumento({
                                ...instrumento,
                                asignatura: {
                                  documentId: '',
                                  value: materia.length
                                    ? materia[0].attributes.Materia
                                    : '',
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="strategie__col col-md-4 col-12">
                        <div className="form-group">
                          <label>
                            Tema <small className="required">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="tema"
                            aria-describedby="tema"
                            placeholder="Escribe el tema"
                            value={instrumento.tema?.value || ''}
                            onChangeCapture={(event) =>
                              setInstrumento({
                                ...instrumento,
                                tema: {
                                  documentId: '',
                                  value: (event.target as HTMLInputElement)
                                    .value,
                                },
                              })
                            }
                            onBlur={(event) => {
                              event.preventDefault();
                              if (event.target.value) {
                                setInstrumento({
                                  ...instrumento,
                                  tema: {
                                    documentId: '',
                                    value: (event.target as HTMLInputElement)
                                      .value,
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="strategie__col col-md-4 col-12">
                        <div className="form-group">
                          <label>
                            Aprendizajes esperados{' '}
                            <small className="required">*</small>
                          </label>
                          <textarea
                            className="form-control"
                            value={
                              instrumento.aprendizajes
                                ?.map(({ value }) => value)
                                .join(' ') || ''
                            }
                            onChangeCapture={(event) =>
                              setInstrumento({
                                ...instrumento,
                                aprendizajes: [
                                  {
                                    documentId: '',
                                    value: (event.target as HTMLInputElement)
                                      .value,
                                  },
                                ],
                              })
                            }
                            onBlur={(event) => {
                              event.preventDefault();
                              setInstrumento({
                                ...instrumento,
                                aprendizajes: [
                                  {
                                    documentId: '',
                                    value: (event.target as HTMLTextAreaElement)
                                      .value,
                                  },
                                ],
                              });
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {instrumento.modeloEducativo?.value ==
                      'Nueva Escuela Mexicana' ? (
                        <>
                          <div className="strategie__col col-md-4 col-12">
                            <div className="form-group">
                              <label>
                                Campo formativo{' '}
                                <small className="required">*</small>
                              </label>
                              <SelectComponent
                                label=""
                                id="campof"
                                option={
                                  <>
                                    <option defaultValue="" hidden>
                                      Seleccionar...
                                    </option>
                                    {campoF.map((e, k) => {
                                      return (
                                        <option
                                          key={k}
                                          value={`${e.id}`}
                                        >{`${e.attributes.Nombre}`}</option>
                                      );
                                    })}
                                  </>
                                }
                                onChange={(evt) => {
                                  setProyectos([]);
                                  setContenidoS([]);
                                  setPda([]);
                                  const campos = campoF.filter(
                                    (m) => `${m.id}` === evt.target.value
                                  );
                                  setInstrumento({
                                    ...instrumento,
                                    campoF: {
                                      documentId: evt.target.value,
                                      value: campos.length
                                        ? campos[0].attributes.Nombre
                                        : '',
                                    },
                                  });
                                  if (instrumento.gradoEscolar) {
                                    searchContenido(
                                      instrumento.gradoEscolar.documentId,
                                      evt.target.value
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {contenidoS.length > 0 ? (
                            <div className="strategie__col col-12">
                              <div className="form-group">
                                <label>
                                  Contenido sintético{' '}
                                  <small className="required">*</small>
                                </label>
                                <SelectComponent
                                  label=""
                                  id="contenido-sintetico"
                                  option={
                                    <>
                                      <option defaultValue="" hidden>
                                        Seleccionar...
                                      </option>
                                      {contenidoS.map((e, k) => {
                                        return (
                                          <option
                                            key={k}
                                            value={`${e.id}`}
                                          >{`${e.attributes.contenido}`}</option>
                                        );
                                      })}
                                    </>
                                  }
                                  onChange={(evt) => {
                                    setPda([]);
                                    const content = contenidoS.filter(
                                      (m) => `${m.id}` === evt.target.value
                                    );
                                    setInstrumento({
                                      ...instrumento,
                                      contenidoS: {
                                        documentId: evt.target.value,
                                        value: content.length
                                          ? content[0].attributes.contenido
                                          : '',
                                      },
                                    });
                                    searchPda(
                                      evt.target.value,
                                      instrumento.gradoEscolar?.documentId || ''
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {pda.length > 0 ? (
                            <div className="strategie__col col-12">
                              <div className="form-group">
                                <label>
                                  Proceso de Desarrollo de Aprendizaje (PDA){' '}
                                  <small className="required">*</small>
                                </label>
                                <SelectComponent
                                  label=""
                                  id="pda"
                                  option={
                                    <>
                                      <option defaultValue="" hidden>
                                        Seleccionar...
                                      </option>
                                      {pda.map((e, k) => {
                                        return (
                                          <option
                                            key={k}
                                            value={`${e.id}`}
                                          >{`${e.attributes.pda}`}</option>
                                        );
                                      })}
                                    </>
                                  }
                                  onChange={(evt) => {
                                    const pdas = pda.filter(
                                      (m) => `${m.id}` === evt.target.value
                                    );
                                    setInstrumento({
                                      ...instrumento,
                                      pda: {
                                        documentId: evt.target.value,
                                        value: pdas.length
                                          ? pdas[0].attributes.pda
                                          : '',
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="strategie__col col-md-4 col-12">
                            <div className="form-group">
                              <label>
                                Asignatura <small className="required">*</small>
                              </label>
                              <SelectComponent
                                label=""
                                id="asignatura"
                                option={
                                  <>
                                    <option defaultValue="" hidden>
                                      Seleccionar...
                                    </option>
                                    {materias.map((e, k) => {
                                      return (
                                        <option
                                          key={k}
                                          value={`${e.id}`}
                                        >{`${e.attributes.Materia}`}</option>
                                      );
                                    })}
                                  </>
                                }
                                onChange={(evt) => {
                                  setBloques([]);
                                  setEjes([]);
                                  setTemas([]);
                                  setAprendizajes([]);
                                  handleMateria(evt.target.value);
                                }}
                              />
                            </div>
                          </div>
                          {bloques.length ? (
                            <div className="strategie__col col-md-4 col-12">
                              <div className="form-group">
                                <label>
                                  Bloque <small className="required">*</small>
                                </label>
                                <SelectComponent
                                  label=""
                                  id="bloque"
                                  option={
                                    <>
                                      <option defaultValue="" hidden>
                                        Seleccionar...
                                      </option>
                                      {bloques.map((e, k) => {
                                        return (
                                          <option
                                            key={k}
                                            value={`${e.id}`}
                                          >{`${e.attributes.Bloque}`}</option>
                                        );
                                      })}
                                    </>
                                  }
                                  onChange={(evt) => {
                                    const bloque = bloques.filter(
                                      (m) => `${m.id}` === evt.target.value
                                    );
                                    setInstrumento({
                                      ...instrumento,
                                      bloque: {
                                        documentId: evt.target.value,
                                        value: bloque.length
                                          ? bloque[0].attributes.Bloque
                                          : '',
                                      },
                                      eje: undefined,
                                      tema: undefined,
                                      aprendizajes: [],
                                    });
                                    setAprendizajes([]);
                                    setEjes([]);
                                    setTemas([]);
                                    if (
                                      instrumento.asignatura &&
                                      instrumento.gradoEscolar
                                    ) {
                                      fetchEjesOrTemas(
                                        instrumento.gradoEscolar.documentId,
                                        instrumento.asignatura.documentId,
                                        evt.target.value,
                                        instrumento.eje?.documentId || '',
                                        '/ejes'
                                      );
                                      fetchEjesOrTemas(
                                        instrumento.gradoEscolar.documentId,
                                        instrumento.asignatura.documentId,
                                        evt.target.value,
                                        instrumento.eje?.documentId || '',
                                        '/temas'
                                      );
                                      fetchAprendizajes(evt.target.value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {ejes.length ? (
                            <div className="strategie__col col-md-4 col-12">
                              <div className="form-group">
                                <label>
                                  Eje <small className="required">*</small>
                                </label>
                                <SelectComponent
                                  label=""
                                  id="eje"
                                  option={
                                    <>
                                      <option defaultValue="" hidden>
                                        Seleccionar...
                                      </option>
                                      {ejes.map((e, k) => {
                                        return (
                                          <option
                                            key={k}
                                            value={`${e.id}`}
                                          >{`${e.attributes.Nombre}`}</option>
                                        );
                                      })}
                                    </>
                                  }
                                  onChange={(evt) => {
                                    const eje = ejes.filter(
                                      (m) => `${m.id}` === evt.target.value
                                    );
                                    setInstrumento({
                                      ...instrumento,
                                      eje: {
                                        documentId: evt.target.value,
                                        value: eje.length
                                          ? eje[0].attributes.Nombre
                                          : '',
                                      },
                                      tema: undefined,
                                      aprendizajes: [],
                                    });
                                    setTemas([]);
                                    fetchEjesOrTemas(
                                      instrumento.gradoEscolar?.documentId ||
                                        '',
                                      instrumento.asignatura?.documentId || '',
                                      instrumento.bloque?.documentId || '',
                                      evt.target.value,
                                      '/temas'
                                    );
                                    fetchAprendizajes(
                                      undefined,
                                      evt.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {temas.length ? (
                            <div className="strategie__col col-md-4 col-12">
                              <div className="form-group">
                                <label>
                                  Tema <small className="required">*</small>
                                </label>
                                <SelectComponent
                                  label=""
                                  id="tema"
                                  option={
                                    <>
                                      <option defaultValue="" hidden>
                                        Seleccionar...
                                      </option>
                                      {temas.map((t, k) => {
                                        return (
                                          <option
                                            key={k}
                                            value={`${t.id}`}
                                          >{`${t.attributes.Nombre}`}</option>
                                        );
                                      })}
                                    </>
                                  }
                                  onChange={(evt) => {
                                    if (evt) {
                                      const tema = temas.filter(
                                        (m) => `${m.id}` === evt.target.value
                                      );
                                      setInstrumento({
                                        ...instrumento,
                                        tema: {
                                          documentId: evt.target.value,
                                          value: tema.length
                                            ? tema[0].attributes.Nombre
                                            : '',
                                        },
                                        aprendizajes: [],
                                      });
                                      fetchAprendizajes(
                                        undefined,
                                        undefined,
                                        evt.target.value
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {aprendizajes.length ? (
                            <div className="strategie__col col-12">
                              <div className="form-group">
                                <label>
                                  Selecciona los aprendizajes esperados{' '}
                                  <small className="required">*</small>
                                </label>
                                {aprendizajes.map((e, k) => {
                                  return (
                                    <div className="form-group__inner" key={k}>
                                      <CheckboxComponent
                                        id={`${e.id}`}
                                        htmlFor={`${e.id}`}
                                        checked={
                                          instrumento.aprendizajes?.length
                                            ? instrumento.aprendizajes.filter(
                                                (a) =>
                                                  a.documentId === `${e.id}`
                                              ).length >= 1
                                            : false
                                        }
                                        text={e.attributes.Nombre}
                                        onChangeEvent={(checked) => {
                                          const aP =
                                            instrumento.aprendizajes || [];
                                          if (checked) {
                                            aP.push({
                                              documentId: `${e.id}`,
                                              value: e.attributes.Nombre,
                                            });
                                          } else {
                                            const index = aP.findIndex(
                                              (a) => a.documentId === `${e.id}`
                                            );
                                            aP.splice(index, 1);
                                          }
                                          setInstrumento({
                                            ...instrumento,
                                            aprendizajes: aP,
                                          });
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              <div className="strategies-forms row">
                <div className="strategie--title col-12">
                  <h4>
                    2. ¿Qué instrumento de evaluación quieres generar?*
                    <OverlayComponent toolTipText="Selecciona el tipo de instrumento que quieres generar.  " />
                  </h4>
                </div>
                <div className="strategie__col col-md-4 col-12">
                  <div className="form-group">
                    <div className="form-group__inner" key={`type-1`}>
                      {type.map((e, k) => {
                        return (
                          <div className="form-group__inner" key={`type-${k}`}>
                            <CheckboxComponent
                              key={`type-${k}`}
                              id={`type-${k}`}
                              htmlFor={`type-${k}`}
                              text={e.value}
                              checked={instrumento.type == e.id}
                              onChangeEvent={(checked) => {
                                if (checked) {
                                  const instDel = { ...instrumento };
                                  delete instDel.evaluacionCorta;
                                  delete instDel.rubricas;
                                  setInstrumento({
                                    ...instDel,
                                    type: e.id,
                                  });
                                  e.id == 1
                                    ? fetchEvaluaciones()
                                    : fetchRubricas();
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {instrumento.type == 1 ? (
                <div className="strategies-forms row">
                  <div className="strategie--title col-12">
                    <h4>
                      3. Agrega detalles para tu evaluación
                      <OverlayComponent toolTipText="Selecciona las habilidades que buscas a evaluar y, cuántos y qué tipo de reactivos vas a utilizar. Trata de limitar los tipos de reactivos 1-2. " />
                    </h4>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Tipos de pregunta <small className="required">*</small>
                      </label>
                      {habilidades.map((e, k) => {
                        return (
                          <div className="form-group__inner" key={`hab-${k}`}>
                            <CheckboxComponent
                              key={`hab-${k}`}
                              id={`hab-${e.id}`}
                              htmlFor={`hab-${e.id}`}
                              checked={
                                instrumento.evaluacionCorta?.habilidades?.length
                                  ? instrumento.evaluacionCorta?.habilidades.filter(
                                      (a) => a.id === `${e.id}`
                                    ).length >= 1
                                  : false
                              }
                              text={e.attributes.Nombre}
                              onChangeEvent={(checked) => {
                                if (checked) {
                                  setInstrumento({
                                    ...instrumento,
                                    evaluacionCorta: {
                                      ...instrumento.evaluacionCorta,
                                      habilidades: [
                                        {
                                          id: `${e.id}`,
                                          value: e.attributes.Nombre,
                                        },
                                      ],
                                    },
                                  });
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Tipo de reactivos <small className="required">*</small>
                      </label>
                      {reactivos.map((e, k) => {
                        return (
                          <div className="form-group__inner" key={k}>
                            <CheckboxComponent
                              id={`${e.id}`}
                              htmlFor={`${e.id}`}
                              checked={
                                instrumento.evaluacionCorta?.reactivos?.length
                                  ? instrumento.evaluacionCorta?.reactivos.filter(
                                      (a) => a.id === `${e.id}`
                                    ).length >= 1
                                  : false
                              }
                              text={e.attributes.Nombre}
                              onChangeEvent={(checked) => {
                                const re =
                                  instrumento.evaluacionCorta?.reactivos || [];
                                if (checked) {
                                  re.push({
                                    id: `${e.id}`,
                                    value: e.attributes.Nombre,
                                  });
                                } else {
                                  const index = re.findIndex(
                                    (a) => a.id === `${e.id}`
                                  );
                                  re.splice(index, 1);
                                }
                                setInstrumento({
                                  ...instrumento,
                                  evaluacionCorta: {
                                    ...instrumento.evaluacionCorta,
                                    reactivos: re,
                                  },
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="strategie__col col-md-4 col-12">
                    <div className="form-group">
                      <label>
                        Número de reactivos{' '}
                        <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label=""
                        id="numero-reactivos"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                              (e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e}`}
                                    selected={
                                      `${e}` ===
                                      instrumento.evaluacionCorta?.numReactivos
                                    }
                                  >
                                    {`${e}`}
                                  </option>
                                );
                              }
                            )}
                          </>
                        }
                        onChange={(evt) => {
                          setInstrumento({
                            ...instrumento,
                            evaluacionCorta: {
                              ...instrumento.evaluacionCorta,
                              numReactivos: evt.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="pt-4 strategie--title  col-12">
                      <h4>
                        Tu evaluación incluirá al final la guía de respuestas.
                      </h4>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {instrumento.type == 2 ? (
                    <>
                      <div className="strategies-forms row">
                        <div className="strategie--title col-12">
                          <h4>
                            3. ¿Qué vas a evaluar con esta rúbrica?*
                            <OverlayComponent toolTipText="Marca la casilla o escribe para indicar qué vas a evaluar con este instrumento. " />
                          </h4>
                        </div>
                        <div className="strategie__col col-md-4 col-12">
                          <div className="form-group">
                            <div className="form-group__inner" key={`type-1`}>
                              {queevaluar.map((e, k) => {
                                return (
                                  <div
                                    className="form-group__inner"
                                    key={`evl-${k}`}
                                  >
                                    <CheckboxComponent
                                      key={`evl-${k}`}
                                      id={`evl-${e.id}`}
                                      htmlFor={`evl-${e.id}`}
                                      text={e.attributes.Nombre}
                                      checked={
                                        instrumento.rubricas?.evaluar?.id ==
                                        `${e.id}`
                                      }
                                      onChangeEvent={(checked) => {
                                        if (checked) {
                                          e.attributes.Nombre === 'Otro'
                                            ? setEvalOtro(true)
                                            : setEvalOtro(false);
                                          if (e.id == 1) {
                                            searchLibros();
                                          }
                                          delete instrumento.rubricas?.libro;
                                          delete instrumento.rubricas?.proyecto;
                                          setInstrumento({
                                            ...instrumento,
                                            rubricas: {
                                              ...instrumento.rubricas,
                                              evaluar: {
                                                id: `${e.id}`,
                                                value:
                                                  e.attributes.Nombre !== 'Otro'
                                                    ? e.attributes.Nombre
                                                    : '',
                                              },
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              })}
                              {evalOtro ? (
                                <input
                                  type="text"
                                  className="form-control"
                                  id="eval-rubrica"
                                  aria-describedby="eval-rubrica"
                                  placeholder="Escribe lo que vas a evaluar con la rúbrica"
                                  value={
                                    instrumento.rubricas?.evaluar?.value || ''
                                  }
                                  onChangeCapture={(event) =>
                                    setInstrumento({
                                      ...instrumento,
                                      rubricas: {
                                        ...instrumento.rubricas,
                                        evaluar: {
                                          id:
                                            instrumento.rubricas?.evaluar?.id ||
                                            '',
                                          value: (
                                            event.target as HTMLInputElement
                                          ).value,
                                        },
                                      },
                                    })
                                  }
                                  onBlur={(event) => {
                                    event.preventDefault();
                                    if (event.target.value) {
                                      setInstrumento({
                                        ...instrumento,
                                        tema: {
                                          documentId: '',
                                          value: (
                                            event.target as HTMLInputElement
                                          ).value,
                                        },
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="strategie__col col-12">
                          {instrumento.modeloEducativo?.documentId === '3' &&
                          instrumento.rubricas?.evaluar?.id == '1' ? (
                            <div className="row">
                              <div className="strategie__col col-md-4 col-12">
                                <div className="form-group">
                                  <label>
                                    Libro de texto{' '}
                                    <small className="required">*</small>
                                  </label>
                                  <SelectComponent
                                    label=""
                                    id="libro"
                                    option={
                                      <>
                                        <option defaultValue="" hidden>
                                          Seleccionar...
                                        </option>
                                        {libros.map((e, k) => {
                                          if (
                                            parseInt(
                                              instrumento?.gradoEscolar?.documentId || '0'
                                            ) < 7
                                          ) {
                                            if (k < 3) {
                                              return (
                                                <option
                                                  key={k}
                                                  value={`${e.id}`}
                                                >
                                                  {`${e.attributes.Nombre}`}
                                                </option>
                                              );
                                            }
                                          } else {
                                            if (k >= 3) {
                                              return (
                                                <option
                                                  key={k}
                                                  value={`${e.id}`}
                                                >
                                                  {`${e.attributes.Nombre}`}
                                                </option>
                                              );
                                            }
                                          }
                                        })}
                                      </>
                                    }
                                    onChange={(evt) => {
                                      const libro = libros.filter(
                                        (m) => `${m.id}` === evt.target.value
                                      );
                                      setInstrumento({
                                        ...instrumento,
                                        rubricas: {
                                          ...instrumento.rubricas,
                                          libro: {
                                            documentId: evt.target.value,
                                            value: libro.length
                                              ? libro[0].attributes.Nombre
                                              : '',
                                          },
                                        },
                                      });
                                      if (
                                        instrumento.gradoEscolar &&
                                        instrumento.campoF
                                      ) {
                                        searchProyectos(
                                          instrumento.gradoEscolar.documentId,
                                          evt.target.value,
                                          instrumento.campoF.documentId
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              {proyectos.length > 0 ? (
                                <div className="strategie__col col-md-8 col-12">
                                  <div className="form-group">
                                    <label>
                                      Proyecto{' '}
                                      <small className="required">*</small>
                                    </label>
                                    <SelectComponent
                                      label=""
                                      id="proyecto"
                                      option={
                                        <>
                                          <option defaultValue="" hidden>
                                            Seleccionar...
                                          </option>
                                          {proyectos.map((e, k) => {
                                            return (
                                              <option
                                                key={k}
                                                value={`${e.id}`}
                                              >{`${e.attributes.nombre}`}</option>
                                            );
                                          })}
                                        </>
                                      }
                                      onChange={(evt) => {
                                        const proyecto = proyectos.filter(
                                          (m) => `${m.id}` === evt.target.value
                                        );
                                        setInstrumento({
                                          ...instrumento,
                                          rubricas: {
                                            ...instrumento.rubricas,
                                            proyecto: {
                                              documentId: evt.target.value,
                                              value: proyecto.length
                                                ? proyecto[0].attributes.resumen
                                                : '',
                                              nombre:
                                                proyecto[0].attributes.nombre,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="strategies-forms row">
                        <div className="strategie--title col-12">
                          <h4>
                            4. ¿Qué tipo de criterios de evaluación o
                            indicadores de aprendizaje tendrá tu rúbrica?*
                            <OverlayComponent toolTipText="Aquí puedes indicar el tipo de criterios e indicadores que tomarás en cuenta para la evaluación de tus alumnos. Puedes seleccionar criterios procedimentales para evaluar capacidades del alumno, los procesos que siguen o bien, criterios actitudinales, para evaluar su comportamiento y actitudes durante las secuencias didácticas.  " />
                          </h4>
                        </div>
                        <div className="strategie__col col-12">
                          <div className="form-group">
                            <div
                              className="form-group__inner"
                              key={`criterios-1`}
                            >
                              {criterios.map((e, k) => {
                                return (
                                  <div
                                    className="form-group__inner"
                                    key={`crs-${k}`}
                                  >
                                    <CheckboxComponent
                                      key={`crs-${k}`}
                                      id={`crs-${e.id}`}
                                      htmlFor={`crs-${e.id}`}
                                      text={e.attributes.Nombre}
                                      checked={
                                        instrumento.rubricas?.criterios?.length
                                          ? instrumento.rubricas?.criterios?.filter(
                                              (a) => a.id === `${e.id}`
                                            ).length >= 1
                                          : false
                                      }
                                      onChangeEvent={(checked) => {
                                        const cr =
                                          instrumento.rubricas?.criterios || [];
                                        if (checked) {
                                          cr.push({
                                            id: `${e.id}`,
                                            value: e.attributes.Nombre,
                                          });
                                        } else {
                                          const index = cr.findIndex(
                                            (c) => c.id === `${e.id}`
                                          );
                                          cr.splice(index, 1);
                                        }
                                        setInstrumento({
                                          ...instrumento,
                                          rubricas: {
                                            ...instrumento.rubricas,
                                            criterios: cr,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="strategies-forms row">
                        <div className="strategie--title col-12">
                          <h4>
                            5. Define cuántos criterios de evaluación o
                            indicadores de aprendizaje necesitas.*
                          </h4>
                        </div>
                        <div className="strategie__col col-md-4 col-12">
                          <div className="form-group">
                            <div
                              className="form-group__inner"
                              key={`numCriterios`}
                            >
                              <SelectComponent
                                label=""
                                id="numero-criterios"
                                option={
                                  <>
                                    <option defaultValue="" hidden>
                                      Seleccionar...
                                    </option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                      (e, k) => {
                                        return (
                                          <option
                                            key={k}
                                            value={`${e}`}
                                            selected={
                                              `${e}` ===
                                              instrumento.rubricas?.numCriterios
                                            }
                                          >
                                            {`${e}`}
                                          </option>
                                        );
                                      }
                                    )}
                                  </>
                                }
                                onChange={(evt) => {
                                  setInstrumento({
                                    ...instrumento,
                                    rubricas: {
                                      ...instrumento.rubricas,
                                      numCriterios: evt.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="strategies-forms row">
                        <div className="strategie--title col-12">
                          <h4>
                            6. ¿Qué niveles de desempeño tendrá tu rúbrica?*
                            <OverlayComponent toolTipText="Marca la casilla y señala los indicadores que vas a utilizar para definir el nivel de desempeño que alcanzaron tus alumnos." />
                          </h4>
                        </div>
                        <div className="strategie__col col-12">
                          <div className="form-group">
                            <div
                              className="form-group__inner"
                              key={`criterios-1`}
                            >
                              {desempeno.map((e, k) => {
                                return (
                                  <div
                                    className="form-group__inner"
                                    key={`des-${k}`}
                                  >
                                    <CheckboxComponent
                                      key={`des-${k}`}
                                      id={`des-${e.id}`}
                                      htmlFor={`des-${e.id}`}
                                      text={e.attributes.Nombre}
                                      checked={
                                        instrumento.rubricas?.desempeno?.id ==
                                        `${e.id}`
                                      }
                                      onChangeEvent={(checked) => {
                                        if (checked) {
                                          setInstrumento({
                                            ...instrumento,
                                            rubricas: {
                                              ...instrumento.rubricas,
                                              desempeno: {
                                                id: `${e.id}`,
                                                value: e.attributes.Nombre,
                                              },
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="strategies-forms row">
                        <div className="strategie--title col-12">
                          <h4>
                            7. ¿Qué más quieres agregar?
                            <OverlayComponent toolTipText="Puedes indicarle a ARTI que necesitarás espacio extra en el formato para agregar información específica. Marca la casilla para señalar el tipo de columna que necesitas para la tabla." />
                          </h4>
                        </div>
                        <div className="strategie__col col-12">
                          <div className="form-group">
                            <div
                              className="form-group__inner"
                              key={`details-1`}
                            >
                              {detalles.map((e, k) => {
                                return (
                                  <div
                                    className="form-group__inner"
                                    key={`det-${k}`}
                                  >
                                    <CheckboxComponent
                                      key={`det-${k}`}
                                      id={`det-${e.id}`}
                                      htmlFor={`det-${e.id}`}
                                      text={e.attributes.Nombre}
                                      checked={
                                        instrumento.rubricas?.details?.length
                                          ? instrumento.rubricas?.details?.filter(
                                              (a) => a.id === `${e.id}`
                                            ).length >= 1
                                          : false
                                      }
                                      onChangeEvent={(checked) => {
                                        const cr =
                                          instrumento.rubricas?.details || [];
                                        if (checked) {
                                          cr.push({
                                            id: `${e.id}`,
                                            value: e.attributes.Nombre,
                                          });
                                        } else {
                                          const index = cr.findIndex(
                                            (c) => c.id === `${e.id}`
                                          );
                                          cr.splice(index, 1);
                                        }
                                        setInstrumento({
                                          ...instrumento,
                                          rubricas: {
                                            ...instrumento.rubricas,
                                            details: cr,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {/* <div className="strategies-forms row">
                <div className="strategie--title col-12">
                  <h4>
                    3. ¿Quieres incluir más información que deba considerar para
                    crear la evaluación?
                    <OverlayComponent
                      toolTipText={`1. Especifica los conceptos de el tema que se está evaluando. 
                      2. Define el grado de dificultad (si es graduada o no)
                      3. Agrega información para dar detalle al contexto de la evaluación. (referencias, ejemplos y ejercicios  o conceptos específicos que se trabajaron en clase)`}
                    />
                  </h4>
                </div>

                <div className="strategie__col col-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      value={instrumento.infoAd}
                      onChangeCapture={(event) =>
                        setInstrumento({
                          ...instrumento,
                          infoAd: (event.target as HTMLTextAreaElement).value,
                        })
                      }
                      onBlur={(event) => {
                        event.preventDefault();
                        setInstrumento({
                          ...instrumento,
                          infoAd: (event.target as HTMLTextAreaElement).value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
              </div> */}

              <div className="strategies-forms row">
                <div className="strategie--title col-12">
                  <h6>* Campos obligatorios</h6>
                </div>

                <div className="strategie__col strategie--chat col-12">
                  <ChatGpt
                    user={user}
                    campo={''}
                    slug="instrumento-de-evaluacion"
                    chatTitle={
                      instrumento.type == 2
                        ? ' Da click para generar tu rúbrica'
                        : 'Da click para generar tu evaluación'
                    }
                    chatText={' '}
                    option={8}
                    onChatButtonClick={handleChatButtonClick}
                    onChatOptionButtonClick={handleChatOptionButtonClick}
                    instrumento={instrumento}
                  />
                </div>

                <div className="strategie__col strategie--chat col-12">
                  <button
                    className="btn btn--type-outline"
                    onClick={handleLimpiar}
                  >
                    Limpiar todo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default InstrumentosIndexScreen;
