import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import { getAnStrapiEntry } from '../../strapi/escuelas';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import './privacy.screen.scss';

const PrivacyScreen = () => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [siteContent, setSiteContent] = useState('');

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/aviso-de-privacidad',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
          }
        })
        .catch((error) => setError(error));
    });
    getAnStrapiEntry('aviso-de-privacidad', '')
      .then((entry) => {
        setSiteContent((entry.data.attributes as any).content);
      })
      .catch((error) => setError(`${error}`));
  }, []);

  return (
    <section className="app-section">
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="container-simple container">
          <div className="row">
            <div className="col-md-10 col-12 mx-auto">
              <article className="col-md-10 col-12 mx-auto">
                <ReactMarkdown
                  linkTarget={'_blank'}
                  remarkPlugins={[remarkGfm]}
                >
                  {`${siteContent || ''}`}
                </ReactMarkdown>
              </article>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PrivacyScreen;
