import React from 'react';
import { Link } from 'react-router-dom';
import './breadcrumb.component.scss';

const BreadcrumbComponent = (props: any) => {
  return (
    <div className="breadcrumb-block">
      <div className="container">
        <div className="row">
          {props.url && (
            <div className="breadcrumb-block__col-left breadcrumb-block__col col-md-2 col-6">
              {props.customNavigate ? (
                <Link
                  to={props.url}
                  className="breadcrumb-block__back"
                  onClick={(evt) => {
                    evt.preventDefault();
                    props.customNavigate();
                  }}
                >
                  <span className="material-icons-outlined">arrow_back</span>{' '}
                  {props.back}
                </Link>
              ) : (
                <Link to={props.url} className="breadcrumb-block__back">
                  <span className="material-icons-outlined">arrow_back</span>{' '}
                  {props.back}
                </Link>
              )}
            </div>
          )}

          <div className="breadcrumb-block__col-center breadcrumb-block__col col-md-7 col-12">
            <ul className="breadcrumb-block__list">
              {props.urlInit && (
                <li>
                  <Link to={props.urlInit}>{props.urlInitText}</Link>
                </li>
              )}
              {props.urlSecond && (
                <li>
                  <Link to={props.urlSecond}>{props.urlSecondText}</Link>
                </li>
              )}
              {props.urlThird && (
                <li>
                  <Link to={props.urlThird}>{props.urlThirdText}</Link>
                </li>
              )}
            </ul>
          </div>
          {props.addPlan && (
            <div className="breadcrumb-block__col-right col-md-3 col-6">
              <button type="button" className="btn btn--type-outline">
                {props.addPlan}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbComponent;
