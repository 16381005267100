import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import './modalReports.component.scss';
import { BugReportType } from '../../../types/EntidadesInterface';
import { getBugReportTypes } from '../../../strapi/bugReport';
import TextareaComponent from '../../Form/textarea/textarea.component';

type Props = {
  show: boolean;
  onHide: () => void;
  size: string;
  url: string;
};

const ModalReportsComponent = ({ show, onHide, size, url }: Props) => {
  const { trackEvent } = useTracking();
  const [bugReportTypes, setBugReportTypes] = useState<
    {
      id: number;
      attributes: BugReportType;
    }[]
  >([]);
  const [bugReportSelected, setBugReportSelected] = useState('');
  const [bugDescription, setBugDescription] = useState('');

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    if (!bugReportTypes.length) {
      getBugReportTypes()
        .then((resp) => {
          setBugReportTypes(resp.data);
        })
        .catch((error) => setError(error));
    }
  });
  return (
    <Modal
      show={show}
      onHide={onHide}
      url={url}
      size={size as any}
      aria-labelledby="modal-reports"
      centered
      className="modal--reports"
    >
      <Modal.Header className="modal__title-icon">
        <Modal.Title id="modal-reports">
          <div className="modal__title-icon-element">
            <span className="material-icons-outlined">bug_report</span>
          </div>
          <h4>Reportar error</h4>
          <button
            className="modal__close"
            onClick={(e) => {
              e.preventDefault();
              onHide();
            }}
          >
            <span className="material-icons-outlined icon">close</span>
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>He notado que esta página</h5>
        <div
          className="modal__actions"
          onChange={(event) => {
            console.log((event.target as any).value);
            setBugReportSelected((event.target as any).value);
          }}
        >
          {bugReportTypes.map((bugReportType, k) => {
            return (
              <div className="modal__actions-inner" key={k}>
                <div className="form-group form-check">
                  <input
                    type="radio"
                    name="bugReport"
                    className="form-check-input"
                    id={`${bugReportType.id}`}
                    value={bugReportType.attributes.name}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${bugReportType.id}`}
                  >
                    {bugReportType.attributes.name}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        <div className="modal__sug-middle col-12">
          { bugReportSelected != '' ? (
            <TextareaComponent
              id="bug"
              placeholder="Describe aquí el error que encontraste"
              value={bugDescription || ''}
              onChangeCapture={(evt) =>
                setBugDescription(
                  (evt?.target as HTMLInputElement).value || '',
                )
              }
            />
            ) :  <> </>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={(e) => {
            e.preventDefault();
            console.log(bugReportSelected, trackEvent);
            trackEvent({
              event: 'bugReport',
              fullPath: window.location.href,
              bugReport: bugReportSelected,
              bugDescription
            });
            Swal.fire({
              html: `<div className='modal__sug-top'>
                <h4>Gracias, tu reporte fue enviado a nuestro equipo.</h4>
              </div>`,
              imageUrl: "../../assets/images/elements/paco-02.png",
              color: '#003980',
              imageWidth: 100,
              imageHeight: 140,
              imageAlt: 'Arti',
              showCloseButton: true,
              showConfirmButton: false,
              timer: 3500,
            }).then(() => {
              onHide();
              setBugDescription('');
              setBugReportSelected('');
            });
          }}
          className="btn btn--type3"
        >
          Enviar reporte
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReportsComponent;
