import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import { useTracking } from 'react-tracking';
import { deletePlanning, getPlanning } from '../../../api/planningsApi';
import { searchPlanningSession } from '../../../api/planningSessionsApi';
import FooterComponent from '../../../components/Footer/footer.component';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import { IPlanning, IPlanningSession } from '../../../types/PlaningInterface';
import PrintPlanningScreen from './printPlanning.screen';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './printPlanning.screen.scss';
import { RecursoEducativo, StrapiDoc } from '../../../types/EntidadesInterface';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import JSPDF from 'jspdf';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';

const PrintPlanningWrapper = () => {
  const { trackEvent } = useTracking();
  const { planningId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IPlanning>>({});
  const [sessions, setSessions] = useState<IPlanningSession[]>([]);
  const [recursos, setRecursos] = useState<
    { id: number; attributes: RecursoEducativo }[]
  >([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getResources = async (allResources: number[]) => {
    try {
      if (allResources.length) {
        // @ts-ignore: Unreachable code error
        const ids = [...new Set(allResources)];
        const query = {
          sort: ['id:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            id: { $in: ids },
          },
        };
        const response = await getStrapiCatalog(
          qs.stringify(query),
          '/recursos-educativos'
        );
        setRecursos(
          response.data as {
            id: number;
            attributes: RecursoEducativo;
          }[]
        );
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getPlanningSessions = async (
    _planning: IPlanning,
    jwtToken: string
  ) => {
    try {
      const response = await searchPlanningSession(
        { planningId: _planning._id },
        jwtToken
      );
      setSessions(response.docs as IPlanningSession[]);
      const ses = response.docs as IPlanningSession[];
      if (ses.length > 0 ) {
        const allR: StrapiDoc[] = [];
        ses.map((s) => {
          const { introduccion, desarrollo, cierre } = s;
          [introduccion, desarrollo, cierre].forEach((section) => {
            if (section) {
              const { resources } = section;
              if (resources && resources.length) {
                allR.push(...resources);
              }
            }
          });
        })
        if (allR.length) {
          getResources(
            allR.map((r) => Number(r.documentId))
          );
        }
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-old',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    if (planningId) {
      getPlanning(planningId, '')
        .then((resp) => {
          setPlanning(resp);
          getPlanningSessions(resp, '');
          setIsLoading(false);
        })
        .catch((error) => setError(error));
    }
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  const isSameUser = () => {
    if (user && planning) {
      const { _id: userId } = user;
      const { userId: planningUserId } = planning;
      if (planningUserId && userId !== (planningUserId as IUser)._id) {
        console.log(false)
        return false;
      }
    }
    console.log(true)
    return true;
  };

  // const handlePrint = () => {
  //   window.print();
  //   trackEvent({
  //     event: 'planningPrint',
  //     page: '/planeacion-old',
  //     planningId,
  //   });
  // };

  const handlePdf = () => {
    trackEvent({
      event: 'planningPdfDownload',
      page: '/planeacion-old',
      planningId,
    });
    const data = document.getElementById('print-placeholder');
    if (data) {
      const pdf = new JSPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
      });

      pdf.setProperties({
        title: 'PACO Teacher | Planeación',
        subject: planning.name || '',
        author: planning.autor || '',
        keywords: planning.description || '',
        creator: `${user.name || ''} ${user.lastName || ''}`,
      });

      pdf.setDisplayMode(1);
      pdf.html(data, {
        callback: (pdf) => {
          const fileName = planning.name || '';
          pdf.save(fileName.toLowerCase().replace(/\s/g, '-'));
        },
        html2canvas: { scale: 0.42 },
        margin: [5, 10, 20, 13],
      });
    } 
  };

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
      <BreadcrumbComponent
        url={isSameUser() ? '/mis-planeaciones' : '/planeaciones/reformas-anteriores'}
        customNavigate={() => {
          console.log('Im here');
          navigate(-1);
        }}
        back="Regresar"
        urlInit={isSameUser() ? '/mis-planeaciones' : '/planeaciones/reformas-anteriores'}
        urlInitText={
          isSameUser() ? 'Mis planeaciones guardadas' : 'Planeaciones para descargar'
        }
        urlSecond={`/sesion-compartida/${planning._id}`}
        urlSecondText={`${planning.name || ''}`}
      />
        <div className="result-block__wrapper">
          <div className="result-block container">
            {isSameUser() ? (
              <>
              <div className="result-block__col result-block__share col-10">
                {/* <button
                  className="btn btn--action"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setTimeout(() => {
                      handlePrint();
                    }, 300);
                  }}
                >
                  <p>Imprimir</p>
                  <span className="material-icons-outlined">print</span>
                </button> */}
                <button
                  className="btn btn--action"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setTimeout(() => {
                      handlePdf();
                    }, 200);
                  }}
                >
                  <p>Descargar</p>
                  <span className="material-icons-outlined">
                    file_download
                  </span>
                </button>
              </div>
              </>
            ) : (
              <></>
            )}
            <div id="print-placeholder" className="toPrint">
              <PrintPlanningScreen
                planning={planning}
                sessions={sessions}
                escuela={null}
                show={true}
                recursos={recursos}
              />
            </div>
            <div className="result-block result-block__action-padding container">
              {isSameUser() ? (
                <>
                  <div className="form__row form__action form__action--both">
                    <button
                      className="btn btn--type-outline btn--auto btn--delete"
                      onClick={(evt) => {
                        evt.preventDefault();
                        return Swal.fire({
                          icon: 'question',
                          title: 'Borrar planeación',
                          text: `¿Deseas borrar esta planeación?`,
                          showCancelButton: true,
                          confirmButtonText: 'Sí, borrar',
                          cancelButtonText: 'Cancelar',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const { _id: planningId } = planning;
                            if (planningId) {
                              trackEvent({
                                event: 'deletePlanning',
                                page: '/planeacion-old',
                                fullPath: window.location.href,
                              });
                              return deletePlanning(planningId, `${user.token}`)
                                .then(() => navigate('/mis-planeaciones'))
                                .catch((error) => setError(error));
                            }
                          }
                        });
                      }}
                    >
                      Borrar planeación
                    </button>
                  </div>
                </>
              ) : <></>
              }
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PrintPlanningWrapper;
