import React, { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import './help.screen.scss';

const HelpScreen = () => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/ayuda',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);
  return (
    <section className="app-section">
      <HeaderLoginComponent />
      <div className="app-section__top">
        <section className="module">
          <div className="container">
            
            <div className="tab-forms row">
              <div className="col-md-10 col-12 mx-auto">
                <h3 className="form__title">Como usar tu plataforma PACO Teacher</h3>
                <article className="col-md-8 col-12 col-reset mx-auto">
                  <p>
                  PACO Teacher es un espacio diseñado para ayudar a crear y mejorar tus
                    planeaciones de una manera innovadora e intuitiva. Descubre
                    cientos de recursos didácticos y herramientas pedagógicas,
                    además de formar parte de una comunidad docente, en la cual
                    podrás compartir experiencias que enriquecerán tus prácticas
                    y mejorando tus planes de trabajo.
                  </p><br/>
                  <p>
                    Acompáñanos a utilizar una herramienta que será tu aliada
                    para tus planeaciones didácticas, haciendo de estas más
                    motivadoras y divertidas.
                  </p><br/>
                  <div className="d-flex justify-content-center mt-4">
                    <a
                      href="https://kame-docente-website-bucket.s3.amazonaws.com/Manual_De_Uso_A22_160922_84e9f40cc9.pdf?updated_at=2022-09-19T17:43:41.752Z"
                      className="btn btn--type1 btn--flex"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        trackEvent({
                          event: 'downloadTutorial',
                          page: '/ayuda',
                        });
                      }}
                    >
                      Descargar manual{' '}
                      <span className="material-icons-outlined icon">
                        description
                      </span>
                    </a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default HelpScreen;
