import React, { useEffect } from 'react';
import TextareaComponent from '../../Form/textarea/textarea.component';
import MultiSelectComponent from '../../Form/multiselect/multiselect.component';
import { Accordion } from 'react-bootstrap';
import './evaluacion.component.scss';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { IPlanningSession } from '../../../types/PlaningInterface';
import { PreguntaReflexion } from '../../../types/EntidadesInterface';

type Props = {
  session: Partial<IPlanningSession>;
  setSession: (
    key: keyof IPlanningSession,
    value: any
  ) => Partial<IPlanningSession>;
  updateSession: (newSession: Partial<IPlanningSession>) => Promise<void>;
  intrumentosEv: {
    id: number;
    attributes: PreguntaReflexion;
  }[];
  isSameUser: boolean;
};

const EvaluacionComponent = ({
  session,
  setSession,
  updateSession,
  intrumentosEv,
  isSameUser,
}: Props) => {
  const { evaluacion } = session;
  useEffect(() => {
    const currentEv = evaluacion || [];
    if (!evaluacion?.length) {
      currentEv.push({
        minutes: 0,
        description: '',
        criterios: '',
        evidencias: '',
        comentarios: '',
      });
      setSession('evaluacion', currentEv);
    }
  });
  return (
    <Accordion.Item eventKey="4" className="session-tab">
      <Accordion.Header>
        <div className="session-tab__toggle tab--color4">
          <h6>4. Evaluación</h6>
          <span className="material-icons-outlined icon">expand_more</span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="session-tab__open">
          {(session.evaluacion || []).map((ev, k) => {
            return (
              <div className="session-tab__open-row row" key={k}>
                {/**
                 * <div className="col-12 col-reset">
                    <button
                      className="btn btn--add"
                      onClick={() => {
                        if (evaluacion) {
                          const all = [...evaluacion] || [];
                          all.splice(k, 1);
                          setSession({
                            ...session,
                            evaluacion: all,
                          });
                        }
                      }}
                    >
                      <span className="material-icons-outlined">remove</span>
                      <p>Quitar instrumento</p>
                    </button>
                  </div>
                 */}
                <div className="col-12 col-reset">
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="form__row">
                        <label
                          htmlFor="description"
                          className="form__label tooltip-contain"
                        >
                          <div className="tooltip-text">
                            Instrumento de evaluación
                          </div>
                          <OverlayComponent toolTipText="Selecciona el instrumento con el que realizas tu evaluación " />
                        </label>
                        <MultiSelectComponent
                          disabled={!isSameUser}
                          options={(intrumentosEv || []).map((inst) => ({
                            value: `${inst.id}`,
                            label: inst.attributes.Nombre,
                          }))}
                          value={(ev.instrumentoEval || []).map((v) => ({
                            value: v.documentId,
                            label: v.value,
                          }))}
                          onChange={(values) => {
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.instrumentoEval = (values || []).map(
                                (v) => ({ documentId: v.value, value: v.label })
                              );
                              const all = evaluacion || [];
                              all[k] = current;
                              const newSession = setSession('evaluacion', all);
                              updateSession(newSession);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/**
                     * <div className="col-md-4 col-12">
                        <div className="form__row">
                          <label htmlFor="description" className="form__label">
                            Tiempo estimado
                          </label>
                          <TimepickerComponent
                            currentValue={
                              ev.minutes ? fromUnixTime(ev.minutes) : null
                            }
                            onTimeChange={(unix) => {
                              if (evaluacion) {
                                const current = { ...evaluacion[k] };
                                current.minutes = unix;
                                const all = [...evaluacion] || [];
                                all[k] = current;
                                setSession({
                                  ...session,
                                  evaluacion: all,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                     */}
                  </div>
                </div>
                <div className="col-12 col-reset">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="form__row">
                        <label
                          htmlFor="eval-description"
                          className="form__label tooltip-contain"
                        >
                          <div className="tooltip-text">
                            Actividades a desarrollar
                          </div>
                          <OverlayComponent toolTipText="Puedes añadir una breve descripción sobre la evaluación o aspectos importantes a tener en cuenta." />
                        </label>
                        <TextareaComponent
                          id="eval-description"
                          placeholder="Descripción"
                          value={ev.description || ''}
                          readOnly={!isSameUser}
                          onChangeCapture={(evt) => {
                            evt.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.description =
                                (evt.target as HTMLTextAreaElement).value || '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              setSession('evaluacion', all);
                            }
                          }}
                          onBlur={(evt) => {
                            evt?.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.description =
                                (evt?.target as HTMLTextAreaElement).value ||
                                '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              const newSession = setSession('evaluacion', all);
                              updateSession(newSession);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-reset">
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="form__row">
                        <label
                          htmlFor="criterios"
                          className="form__label tooltip-contain"
                        >
                          <div className="tooltip-text">
                            Criterios de evaluación
                          </div>
                          <OverlayComponent toolTipText="Describe los criterios que tendrás en cuenta para evaluar a tus alumnos." />
                        </label>
                        <TextareaComponent
                          id="criterios"
                          placeholder="Criterios de evaluación"
                          value={ev.criterios || ''}
                          readOnly={!isSameUser}
                          onChangeCapture={(evt) => {
                            evt.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.criterios =
                                (evt.target as HTMLTextAreaElement).value || '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              setSession('evaluacion', all);
                            }
                          }}
                          onBlur={(evt) => {
                            evt?.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.criterios =
                                (evt?.target as HTMLTextAreaElement).value ||
                                '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              const newSession = setSession('evaluacion', all);
                              updateSession(newSession);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form__row">
                        <label
                          htmlFor="criterios"
                          className="form__label tooltip-contain"
                        >
                          <div className="tooltip-text">Evidencias</div>
                          <OverlayComponent toolTipText="Toma nota de las evidencias que vas a considerar para esta evaluación." />
                        </label>
                        <TextareaComponent
                          id="criterios"
                          placeholder="Evidencias"
                          value={ev.evidencias || ''}
                          readOnly={!isSameUser}
                          onChangeCapture={(evt) => {
                            evt.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.evidencias =
                                (evt.target as HTMLTextAreaElement).value || '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              setSession('evaluacion', all);
                            }
                          }}
                          onBlur={(evt) => {
                            evt?.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.evidencias =
                                (evt?.target as HTMLTextAreaElement).value ||
                                '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              const newSession = setSession('evaluacion', all);
                              updateSession(newSession);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form__row">
                        <label
                          htmlFor="comentarios"
                          className="form__label tooltip-contain"
                        >
                          <div className="tooltip-text">
                            Comentarios adicionales
                          </div>
                          <OverlayComponent toolTipText="Añade comentarios sobre lo que pasó en tu evaluación o los resultados que obtuviste.  " />
                        </label>
                        <TextareaComponent
                          id="comentarios"
                          placeholder="Comentarios adicionales"
                          value={ev.comentarios || ''}
                          readOnly={!isSameUser}
                          onChangeCapture={(evt) => {
                            evt.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.comentarios =
                                (evt.target as HTMLTextAreaElement).value || '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              setSession('evaluacion', all);
                            }
                          }}
                          onBlur={(evt) => {
                            evt?.preventDefault();
                            if (evaluacion) {
                              const current = { ...evaluacion[k] };
                              current.comentarios =
                                (evt?.target as HTMLTextAreaElement).value ||
                                '';
                              const all = [...evaluacion] || [];
                              all[k] = current;
                              const newSession = setSession('evaluacion', all);
                              updateSession(newSession);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/**
             * <div className="session-tab__open-row row">
                <div className="col-12 form__action">
                  <button
                    className="btn btn--add"
                    onClick={() => {
                      const currentEv = evaluacion || [];
                      currentEv.push({
                        minutes: 0,
                        description: '',
                        criterios: '',
                        evidencias: '',
                        comentarios: '',
                      });
                      setSession({
                        ...session,
                        evaluacion: currentEv,
                      });
                    }}
                  >
                    <span className="material-icons-outlined">add</span>
                    <p>Agregar instrumento</p>
                  </button>
                </div>
              </div>
             */}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default EvaluacionComponent;
