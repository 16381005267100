import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../components/Footer/footer.component';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import LikeComponent from '../../components/Like/like.component';
import ModalGameComponent from '../../components/Modal/modalGame/modaGame.component';
import ModalPDFComponent from '../../components/Modal/modalPDF/modalPDF.component';
import ModalIframeComponent from '../../components/Modal/modalIframe/modalIframe.component';
import ModalWarningComponent from '../../components/Modal/modalWarning/modalWarning.component';
import { getUser } from '../../api/usersApi';
import { getAnStrapiEntry, getStrapiCatalog } from '../../strapi/escuelas';
import { IUser, StrapiDoc } from '../../types/UserInterface';
import { SearchResult } from '../../types/PlaningInterface';
import { LogoResult, RecursoEducativo } from '../../types/EntidadesInterface';
import './activity.screen.scss';
import {
  createResourceFavorite,
  searchResourceFavorite,
} from '../../api/resourceFavoritesApi';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import { getFlexiblePlanning } from '../../api/flexiblePlanningsApi';
import {
  IFlexiblePlanning,
  IFlexiblePlanningSession,
  IFlexiblePlanningSessionDoc,
} from '../../types/FlexiblePlanningInterface';
import { updateFlexiblePlanning } from '../../api/flexiblePlanningsApi';
import NavigationHints from '../../components/NavigationHints/navigationHints';

const ActivityScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { recursoId, planningId, asignaturaId, secuenciaId, bloqueId } =
    useParams();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IFlexiblePlanning>>({});
  const [session, setSession] = useState<Partial<IFlexiblePlanningSession>>({});
  const [recurso, setRecurso] = useState<{
    id: number;
    attributes: RecursoEducativo;
  }>();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowPDF, setModalShowPDF] = useState(false);
  const [modalShowIframe, setModalShowIframe] = useState(false);
  const [modalShowWarning, setModalShowWarning] = useState(false);
  const [recursoImage, setRecursoImage] = useState('');
  const [resourceFavorites, setResourceFavorites] = useState<SearchResult>({
    myFavorites: [],
    all: [],
  });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getTiposRecurso = async (_recurso: RecursoEducativo) => {
    const tiposR = await getStrapiCatalog(
      qs.stringify({ sort: ['Nombre:asc'], populate: ['Logo'] }),
      '/tipo-de-recursos'
    );
    if (tiposR.data?.length) {
      const tipoRecursoData = _recurso?.tipo_de_recursos?.data || [];
      const tipoRecursoImageId = tipoRecursoData.length
        ? tipoRecursoData[0].id
        : '';
      const index = tiposR.data.findIndex((r) => r.id === tipoRecursoImageId);
      if (index >= 0) {
        const image =
          (tiposR.data[index].attributes as LogoResult).Logo.data.attributes
            .url || '';
        setRecursoImage(image);
      }
    }
  };

  const isAlreadyInSession = () => {
    if (recurso) {
      const { inicio, desarrollo, cierre } = session;
      const bloques = [
        { nombre: 'inicio', inicio },
        { nombre: 'desarrollo', desarrollo },
        { nombre: 'cierre', cierre },
      ].filter((b) => b && (b as any)[b.nombre]?._id === bloqueId);
      if (bloques.length > 0) {
        const bloqueName = bloques[0].nombre;
        const bloque = (bloques[0] as any)[bloqueName];
        const resources =
          (bloque as IFlexiblePlanningSessionDoc).resources || [];

        const exists =
          resources.findIndex((r) => r.documentId === `${recurso.id}`) >= 0;
        return exists;
      }
    }
    return false;
  };

  const isSameUser = () => {
    if (user && planning) {
      const { _id: userId } = user;
      const { userId: planningUserId } = planning;
      if (planningUserId && userId !== planningUserId) {
        return false;
      }
    }
    return true;
  };

  const getFavorites = async (resourceId: string, token: string) => {
    try {
      const result = await searchResourceFavorite(
        { isFavorite: true, resourceId },
        token
      );
      if (result) {
        setResourceFavorites(result);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const setRFavorite = async (resourceId: string, checked: boolean) => {
    trackEvent({
      event: 'resourceFavorite',
      page: '/busqueda',
      resource: resourceId,
      favorite: checked,
    });
    const result = await createResourceFavorite(
      { userId: user._id, resourceId, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  const updatePlanning = async (newPlanning: Partial<IFlexiblePlanning>) => {
    try {
      const { _id: planningId } = newPlanning;
      if (planningId) {
        const update = await updateFlexiblePlanning(
          planningId,
          { ...newPlanning },
          `${user.token}`
        );
        setPlanning({ ...update });
        return update;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return {};
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            setUser({ ...kameUser, token: jwtToken });
            if (recursoId) {
              getAnStrapiEntry('/recursos-educativos', recursoId).then(
                (response) => {
                  if (response) {
                    const r = response.data as {
                      id: number;
                      attributes: RecursoEducativo;
                    };
                    setRecurso(r);
                    console.log(
                      String(
                        recurso?.attributes.recurso.match(/\bhttps?:\/\/\S+/gi)
                      ).replace(')', '')
                    );
                    trackEvent({
                      event: 'pageView',
                      page: '/actividad',
                      fullPath: window.location.href,
                      recursoId,
                      recursoName: r.attributes.Nombre,
                      recursoTipo:
                        r.attributes.tipo_de_recursos.data[0].attributes.Nombre,
                      recursoGrado: r.attributes.grado.data[0].id,
                      recursoMateria:
                        r.attributes.materia.data.attributes.Materia,
                      recursoFuente: r.attributes.fuente.data.attributes.Fuente,
                    });
                    getTiposRecurso(
                      response.data.attributes as RecursoEducativo
                    );
                  }
                }
              );
              getFavorites(recursoId, jwtToken);
            }
            if (kameUser && planningId) {
              setUser({ ...kameUser, token: jwtToken });
              getFlexiblePlanning(planningId, jwtToken)
                .then((resp) => {
                  setPlanning(resp);
                  if (resp && resp._id) {
                    const { asignaturas } = resp;
                    if (asignaturas?.length) {
                      asignaturas.forEach((a) => {
                        const { sessions } = a;
                        if (sessions?.length) {
                          sessions.forEach((s) => {
                            if (s._id === secuenciaId) {
                              setSession(s);
                            }
                          });
                        }
                      });
                    }
                  }
                })
                .catch((error) => setError(error));
            }
          })
          .catch((error) => setError(error));
      })
      .catch(() => {
        if (recursoId) {
          getAnStrapiEntry('/recursos-educativos', recursoId).then(
            (response) => {
              if (response) {
                setRecurso(
                  response.data as {
                    id: number;
                    attributes: RecursoEducativo;
                  }
                );
                getTiposRecurso(response.data.attributes as RecursoEducativo);
              }
            }
          );
          getFavorites(recursoId, '');
        }
      });
  }, []);

  const addRecursoToSession = () => {
    return Swal.fire({
      icon: 'question',
      title: 'Agregar recurso',
      text: `¿Deseas agregar este recurso a tu sesión?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, agregar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (recurso && result.isConfirmed) {
        const { asignaturas } = planning;
        if (asignaturas?.length) {
          let resources: StrapiDoc[] = [];
          asignaturas.forEach((asignatura, idx) => {
            const { sessions } = asignatura;
            (sessions || []).forEach((session, idy) => {
              if (session._id === secuenciaId && bloqueId) {
                const bloque: IFlexiblePlanningSessionDoc = session[
                  bloqueId as keyof IFlexiblePlanningSession
                ] as any;
                resources = bloque.resources || [];

                const exists = resources.findIndex(
                  (r) => r.documentId === `${recurso.id}`
                );
                console.log({ resources, exists });
                if (exists >= 0) {
                  return Swal.fire(
                    'Oops',
                    'Este recurso ya fue añadido previamente',
                    'warning'
                  );
                } else {
                  resources.push({
                    documentId: `${recurso.id}`,
                    value: recurso.attributes.Nombre,
                  });
                  const newPlanning = { ...planning };
                  (newPlanning as any).asignaturas[idx].sessions[idy][
                    bloqueId
                  ].resources = resources;
                  setPlanning({ ...newPlanning });

                  updatePlanning({ ...newPlanning })
                    .then(() => {
                      return Swal.fire({
                        icon: 'success',
                        title: 'Añadir recurso',
                        text: `El recurso fue añadido correctamente`,
                        showCancelButton: true,
                        confirmButtonText: 'Volver a mi sesión',
                        cancelButtonText: 'Continuar buscando',
                      }).then((response) => {
                        if (response.isConfirmed) {
                          navigate(
                            `/planeacion-flexible/${planningId}/${asignaturaId}/${secuenciaId}`
                          );
                        }
                      });
                    })
                    .catch((error) => setError(error));
                }
              }
            });
          });
        }
      }
    });
  };

  return (
    <section className="app-section">
      <ModalWarningComponent
        show={modalShowWarning}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowWarning(false)}
        click={() => setModalShowWarning(false)}
        type="alert"
        title="Estas a punto de salir de PACO Teacher"
        content={
          <>
            <p>
              Este recurso pertenece a un sitio de terceros. Podrás regresar a
              PACO Teacher en cualquier momento.
            </p>
          </>
        }
        url={
          recurso?.attributes?.fuente?.data?.attributes?.Fuente != 'Paco Max'
            ? String(
                recurso?.attributes.recurso.match(/\bhttps?:\/\/\S+/gi)
              ).replace(')', '')
            : ''
        }
        urlText="Aceptar"
        target="_blank"
      />
      <ModalGameComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        title="Apoyos visuales"
        image="/assets/images/games/game-banner.jpg"
      />
      <ModalPDFComponent
        show={modalShowPDF}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowPDF(false)}
        title={`${
          recurso?.attributes?.tipo_de_recursos?.data?.length
            ? recurso?.attributes.tipo_de_recursos.data
                .map((tR) => tR.attributes.Nombre)
                .join(', ')
            : ''
        }`}
        file={recurso?.attributes.documento?.data?.attributes?.url}
      />
      <ModalIframeComponent
        show={modalShowIframe}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowIframe(false)}
        title={`${
          recurso?.attributes?.tipo_de_recursos?.data?.length
            ? recurso?.attributes.tipo_de_recursos.data
                .map((tR) => tR.attributes.Nombre)
                .join(', ')
            : ''
        }`}
        src={recurso?.attributes?.recurso}
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/busqueda${
            secuenciaId && bloqueId
              ? `/${planningId}/${asignaturaId}/${secuenciaId}/${bloqueId}`
              : ''
          }`}
          back="Regresar"
          urlSecond={
            planning && planning._id
              ? `/planeacion-sesiones/${planning._id}`
              : ''
          }
          urlSecondText={planning && planning.name ? `${planning.name}` : ''}
          urlThird={
            session && session._id
              ? `/sesiones/${planning._id}/${session._id}`
              : ''
          }
          urlThirdText={session.name ? `${session.name}` : ''}
        />
      </div>
      <section className="game-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {recursoImage !== '' ? (
                <figure className="game__image">
                  <img src={recursoImage} alt="Game Banner" />
                </figure>
              ) : (
                <div></div>
              )}
              <div className="game__aside game-info-mobile">
                <button className="game__aside-add">
                  <span className="material-icons-outlined">
                    add_circle_outline
                  </span>
                </button>
                <article className="game__aside-title">
                  <p className="game__aside-label">Recurso</p>
                  <p>{`${recurso?.attributes.Nombre || ''}`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Tipo de Recurso</p>
                  <p>{`${
                    recurso?.attributes?.tipo_de_recursos?.data?.length
                      ? recurso?.attributes.tipo_de_recursos.data
                          .map((tR) => tR.attributes.Nombre)
                          .join(', ')
                      : ''
                  }`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Grado</p>
                  <p>{`${
                    recurso?.attributes?.grado?.data?.length
                      ? recurso?.attributes.grado.data
                          .map((g) => g.attributes.Grado)
                          .join(', ')
                      : ''
                  }`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Materia</p>
                  <p>{`${
                    recurso?.attributes?.materia?.data?.attributes?.Materia ||
                    ''
                  }`}</p>
                </article>
                {/* <article className="game__aside-title">
                  <p className="game__aside-label">Aprendizajes esperados</p>
                  <p>{`${
                    recurso?.attributes?.aprendizajes?.data?.length
                      ? recurso?.attributes?.aprendizajes.data[0].attributes
                          .Nombre
                      : ''
                  }`}</p>
                </article> */}
                <div className="game__aside-actions">
                  <div className="game__aside-sources">
                    <article className="game__aside-title">
                      <p className="game__aside-label">Fuente</p>
                      <p>{`${
                        recurso?.attributes?.fuente?.data?.attributes?.Fuente ||
                        ''
                      }`}</p>
                    </article>
                  </div>
                  <LikeComponent
                    totalFavs={resourceFavorites.myFavorites.length}
                    checked={resourceFavorites.myFavorites.some(
                      (f) => f.userId === `${user._id}`
                    )}
                    onCheck={(event) => {
                      event?.preventDefault();
                      const checked = (event?.target as any).checked;
                      setRFavorite(`${recurso?.id}`, checked).then(() => {
                        getFavorites(`${recurso?.id}`, `${user.token}`);
                      });
                    }}
                  />
                </div>
                {secuenciaId && !isAlreadyInSession() ? (
                  <div>
                    <button
                      className="btn btn--type-outline"
                      onClick={(evt) => {
                        evt.preventDefault();
                        addRecursoToSession();
                      }}
                    >
                      Agregar recurso
                    </button>
                    <div className="alert alert-neutral">
                      <p>
                        Selecciona el recurso que mejor te ayude a complementar
                        tus sesiones
                      </p>
                    </div>
                  </div>
                ) : secuenciaId && isSameUser() ? (
                  <div>
                    <div className="alert alert-ready">
                      <p>
                        <span className="material-icons">done</span>
                        Este recurso ya está añadido a tu sesión
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="game__info">
                <article className="game__article">
                  <h6>Descripción</h6>
                  <ReactMarkdown
                    linkTarget={'_blank'}
                    skipHtml={false}
                    rehypePlugins={[rehypeRaw]}
                  >{`${recurso?.attributes.descripcion || ''}`}</ReactMarkdown>
                </article>
                <article className="game__article">
                  <h6>Recurso</h6>
                  {recurso?.attributes?.fuente?.data?.attributes?.Fuente !=
                  'Paco Max' ? (
                    <>
                      {/*<p className="game__link">
                        <ReactMarkdown linkTarget={'_blank'}>{`${
                          recurso?.attributes.recurso || ''
                        }`}</ReactMarkdown>
                      </p> */}
                      <button
                        type="button"
                        className="game__link-external"
                        onClick={() => {
                          setModalShowWarning(true);
                          trackEvent({
                            event: 'verRecurso',
                            page: '/actividad',
                            fullPath: window.location.href,
                            recursoId: recurso?.id,
                            recursoName: recurso?.attributes.Nombre,
                          });
                        }}
                      >
                        Ver recurso
                        <span className="material-icons">north_east</span>
                      </button>
                    </>
                  ) : (
                    <> </>
                  )}
                  {recurso?.attributes?.fuente?.data?.attributes?.Fuente ==
                  'Paco Max' ? (
                    <article className="game__aside-title">
                      {recurso?.attributes.documento?.data ? (
                        <button
                          onClick={() => {
                            setModalShowPDF(true);
                            trackEvent({
                              event: 'verContenido',
                              page: '/actividad',
                              fullPath: window.location.href,
                              recursoId: recurso?.id,
                              recursoName: recurso?.attributes.Nombre,
                            });
                          }}
                          className="btn btn--type-outline btn--small"
                        >
                          Ver contenido
                        </button>
                      ) : (
                        <> </>
                      )}
                      {recurso?.attributes.recurso ? (
                        <button
                          onClick={() => {
                            setModalShowIframe(true);
                            trackEvent({
                              event: 'verContenido',
                              page: '/actividad',
                              fullPath: window.location.href,
                              recursoId: recurso?.id,
                              recursoName: recurso?.attributes.Nombre,
                            });
                          }}
                          className="btn btn--type-outline btn--small"
                        >
                          Ver contenido
                        </button>
                      ) : (
                        <> </>
                      )}
                    </article>
                  ) : (
                    <> </>
                  )}
                </article>
                {/**
                 * <article className="game__article">
                  <h6>Apoyos visuales</h6>
                  <p>
                    Jujubes dragée lemon drops cookie jelly halvah powder
                    croissant bear claw. Chupa chups tootsie roll caramels oat
                    cake tart. Sweet roll sesame snaps cake bonbon ice cream
                    jelly beans.
                  </p>
                </article>
                 */}
              </div>
              {/**
               * <div className="game__list">
                  <div className="row">
                    <div className="game__list-col col-lg-3 col-6">
                      <figure className="game__list-item">
                        <button onClick={() => setModalShow(true)}>
                          <div className="game__list-view">
                            <span className="material-icons-outlined">
                              zoom_in
                            </span>
                          </div>
                          <img src="/assets/images/games/game.png" alt="Game" />
                        </button>
                      </figure>
                    </div>
                    <div className="game__list-col col-lg-3 col-6">
                      <figure className="game__list-item">
                        <button>
                          <div className="game__list-view">
                            <span className="material-icons-outlined">
                              zoom_in
                            </span>
                          </div>
                          <img src="/assets/images/games/game.png" alt="Game" />
                        </button>
                      </figure>
                    </div>
                    <div className="game__list-col col-lg-3 col-6">
                      <figure className="game__list-item">
                        <button>
                          <div className="game__list-view">
                            <span className="material-icons-outlined">
                              zoom_in
                            </span>
                          </div>
                          <img src="/assets/images/games/game.png" alt="Game" />
                        </button>
                      </figure>
                    </div>
                    <div className="game__list-col col-lg-3 col-6">
                      <figure className="game__list-item">
                        <button>
                          <div className="game__list-view">
                            <span className="material-icons-outlined">
                              zoom_in
                            </span>
                          </div>
                          <img src="/assets/images/games/game.png" alt="Game" />
                        </button>
                      </figure>
                    </div>
                  </div>
                </div>
               */}
            </div>
            <aside className="game__col-right col-md-4">
              <div className="game__aside">
                <article className="game__aside-title">
                  <p className="game__aside-label">Recurso</p>
                  <p>{`${recurso?.attributes.Nombre || ''}`} </p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Tipo de Recurso</p>
                  <p>{`${
                    recurso?.attributes?.tipo_de_recursos?.data?.length
                      ? recurso?.attributes.tipo_de_recursos.data
                          .map((tR) => tR.attributes.Nombre)
                          .join(', ')
                      : ''
                  }`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Grado</p>
                  <p>{`${
                    recurso?.attributes?.grado?.data?.length
                      ? recurso?.attributes.grado.data
                          .map((g) => g.attributes.Grado)
                          .join(', ')
                      : ''
                  }`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Materia</p>
                  <p>{`${
                    recurso?.attributes?.materia?.data?.attributes?.Materia ||
                    ''
                  }`}</p>
                </article>
                {/* <article className="game__aside-title">
                  <p className="game__aside-label">Aprendizajes esperados</p>
                  <p>{`${
                    recurso?.attributes?.aprendizajes?.data?.length
                      ? recurso?.attributes?.aprendizajes.data[0].attributes
                          .Nombre
                      : ''
                  }`}</p>
                </article> */}
                <article className="game__aside-title">
                  <p className="game__aside-label">Fuente</p>
                  {/* className="pacomax" */}
                  <p>{`${
                    recurso?.attributes?.fuente?.data?.attributes?.Fuente || ''
                  }`}</p>
                </article>
                <div className="game__aside-actions">
                  <LikeComponent
                    totalFavs={resourceFavorites.myFavorites.length}
                    checked={resourceFavorites.myFavorites.some(
                      (f) => f.userId === `${user._id}`
                    )}
                    onCheck={(event) => {
                      event?.preventDefault();
                      const checked = (event?.target as any).checked;
                      setRFavorite(`${recurso?.id}`, checked).then(() => {
                        getFavorites(`${recurso?.id}`, `${user.token}`);
                      });
                    }}
                  />
                </div>
                {secuenciaId && !isAlreadyInSession() ? (
                  <div>
                    <button
                      className="btn btn--type-outline"
                      onClick={(evt) => {
                        evt.preventDefault();
                        addRecursoToSession();
                      }}
                    >
                      Agregar recurso
                    </button>
                    <div className="alert alert-neutral">
                      <p>
                        Selecciona el recurso que mejor te ayude a complementar
                        tus sesiones
                      </p>
                    </div>
                  </div>
                ) : secuenciaId && isSameUser() ? (
                  <div>
                    <div className="alert alert-ready">
                      <p>
                        <span className="material-icons">done</span>
                        Este recurso ya está añadido a tu sesión
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {recurso?.attributes.print ? (
                  <article className="game__aside-info">
                    <p>
                      Este recurso esta disponible para impresión{' '}
                      <span className="material-icons-outlined icon">
                        print
                      </span>
                    </p>
                  </article>
                ) : (
                  <></>
                )}
              </div>
            </aside>
          </div>
        </div>
        <NavigationHints
          button1={{
            link: '/estrategias-didacticas',
            texto: 'Enseña Matemáticas con PACO Max',
            color: 'btn--color3',
            iconText: 'icon--grid7',
            icon: true,
            arti: false,
            max: true,
          }}
          button2={{
            link: '/planeaciones/nueva-escuela-mexicana',
            texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
            color: 'btn--color2',
            iconText: 'icon--grid2',
            icon: true,
            arti: false,
            max: false,
          }}
          button3={{
            link: '/crea-tu-planeacion-nem',
            texto: 'Planeaciones NEM con Inteligencia Artificial',
            iconText: 'icon--grid3',
            color: 'btn--color2',
            icon: true,
            arti: true,
            max: false,
          }}
          user={user}
        />
      </section>
      <FooterComponent />
    </section>
  );
};

export default ActivityScreen;
