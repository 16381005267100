import React, { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { fromUnixTime } from 'date-fns';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ToggleEnabledComponent from '../../../Form/toggleEnabled/toggleEnabled.component';
import ModalInfoComponent from '../../../Modal/modalInfo/modalInfo.component';
import TimepickerComponent from '../../../Form/timepicker/timepicker.component';
import OverlayComponent from '../../../OverlayTrigger/OverlayComponent';
import RecursoDidacticoComponent from '../recursosDidacticos/recursosDidacticos.component';
import MultiSelectComponent from '../../../Form/multiselect/multiselect.component';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
  IFlexiblePlanningSession,
} from '../../../../types/FlexiblePlanningInterface';
import {
  GenericResult,
  Logo,
  PreguntaReflexion,
  RecursoEducativo,
} from '../../../../types/EntidadesInterface';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import ChatGpt from '../../../ChatGpt/chatGpt';
import { IUser } from '../../../../types/UserInterface';

interface Props {
  user: Partial<IUser>;
  planning: Partial<IFlexiblePlanning>;
  planningAsignatura: Partial<IFlexiblePlanningAsignatura>;
  session: Partial<IFlexiblePlanningSession>;
  pReflexion: {
    id: Number;
    attributes: PreguntaReflexion;
  }[];
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
  tiposRecurso: GenericResult;
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
  updateSecuenciaState: (
    session: Partial<IFlexiblePlanningSession>,
    key: keyof IFlexiblePlanningSession,
    value: any
  ) => Partial<IFlexiblePlanningSession>;
  updateSecuencia: (
    session: Partial<IFlexiblePlanningSession>
  ) => Promise<IFlexiblePlanningSession>;
}

const CierreSecuenciaComponent = ({
  user,
  planning,
  planningAsignatura,
  session,
  pReflexion,
  recursos,
  tiposRecurso,
  updatePlanning,
  updatePlanningState,
  updateSecuenciaState,
  updateSecuencia,
}: Props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [modalShow, setModalShow] = useState(false);
  const [activo, setActivo] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [loading, setloading] = useState(false);

  const { cierre } = session;
  const introRecursos = (cierre?.resources || []).map((r) => r.documentId);
  const strapiRecursos = recursos.filter(
    (r) => introRecursos.indexOf(`${r.id}`) >= 0
  );

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    let disabledFields = (planning.disabledFields?.sessions || []).filter(
      (dF) => dF.sessionId === session._id
    );
    if (!disabledFields.length) {
      (planning.disabledFields?.sessions || []).push({
        sessionId: `${session._id}`,
        fields: ['cierre'],
        cierre: ['minutes', 'description', 'preguntasReflexion'],
      });
      disabledFields = [
        {
          sessionId: `${session._id}`,
          fields: ['cierre'],
          cierre: ['minutes', 'description', 'preguntasReflexion'],
        },
      ];
    }
    if (checked) {
      if (disabledFields && disabledFields.length) {
        disabledFields[0].cierre = [];
        disabledFields[0].fields = disabledFields[0].fields.filter(
          (f: string) => f !== 'cierre'
        );
        const newPlanning = updatePlanningState('disabledFields', {
          ...planning.disabledFields,
          session: (planning.disabledFields?.sessions || []).map((dF) => {
            if (dF.sessionId === session._id) {
              return {
                ...disabledFields[0],
              };
            }
            return {
              ...dF,
            };
          }),
        });
        updatePlanning({ ...newPlanning });
      }
    } else {
      updateSecuenciaState(session, 'cierre', {
        minutes: undefined,
        description: undefined,
        resources: [],
        preguntasReflexion: [],
      });
      disabledFields = (planning.disabledFields?.sessions || [])
        .filter((dF) => dF.sessionId === session._id)
        .map((dF) => {
          dF.fields.push('cierre');
          return {
            ...dF,
            fields: [...new Set(dF.fields)],
            cierre: ['minutes', 'description', 'preguntasReflexion'],
          };
        });
    }
    const newPlanning = updatePlanningState('disabledFields', {
      ...planning.disabledFields,
      sessions: (planning.disabledFields?.sessions || []).map((dF) => {
        if (dF.sessionId === session._id) {
          return {
            ...disabledFields[0],
          };
        }
        return {
          ...dF,
        };
      }),
    });
    updatePlanning({ ...newPlanning });
  };

  const handleChatButtonClick = (opt: number) => {
    if(opt === 7) {
      setShowTextArea(true)
      setloading(true);
    }
  };

  const handleChatOptionButtonClick = (msj: string, opt: number) => {
    let txt = '';
    if(opt === 7) {
      setShowTextArea(true)
      txt = (textAreaValue === '') 
                ? `Mi primera sugerencia es: ${msj} \n\n`
                : `Mi segunda sugerencia es: ${msj} \n\n ${textAreaValue}`;
      setTextAreaValue(txt)
      setloading(false);
    }
  };

  const copytxt = () => {
    trackEvent({
      event: 'copyArti',
      page: '/planeacion-flexible',
      planningId: planning._id,
      asignaturaId: planningAsignatura._id,
      sessionId: session._id,
      campo: 'cierre-secuencia',
      fullPath: window.location.href,
    });
    const textC = textAreaValue.replace('Mi primera sugerencia es: ', '')
                          .replace('Mi segunda sugerencia es: ', '').trim();
    let descc = (session.cierre?.description) 
            ? session.cierre.description + textC
            : textC;
    updateSecuenciaState(session, 'cierre', {
      ...cierre,
      description: descc
    });
    updateSecuencia({
      ...session,
      cierre: {
        ...cierre,
        description: descc
      },
    });
  };

  useEffect(() => {}, [planning]);

  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        class="modal--secondary"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Cierre de la secuencia didáctica</h4>
            <p>
              Describe las actividades que vas a implementar para la última
              etapa de tu actividad.{' '}
            </p>
          </>
        }
      />
      <div
        className={`session-complete ${
          !planning.disabledFields?.sessions?.filter(
            (dF) =>
              dF.sessionId === session._id && (dF.cierre || []).length >= 3
          ).length
            ? ''
            : 'disabled'
        }`}
      >
        <Accordion
          defaultActiveKey="0"
          className="new-accordion-planning accordion--color2"
        >
          <Card>
            <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
              <div className="accordion-planning__title">
                <div className="toggle-complete">
                  <div className="toggle-action">
                    <input
                      type="checkbox"
                      id="toggle"
                      checked={
                        !!!planning.disabledFields?.sessions?.filter(
                          (dF) =>
                            dF.sessionId === session._id &&
                            (dF.cierre || []).length >= 3
                        ).length
                      }
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <figure></figure>
                    </div>
                  </div>
                  <label>
                    C. Cierre
                    <button
                      onClick={() => {
                        setModalShow(true);
                      }}
                      className="toggle-moda-tooltip"
                    >
                      <span className="material-icons-outlined">info</span>
                    </button>
                  </label>
                </div>
              </div>
              <CustomToggle eventKey="0">
                <span className="material-icons-outlined accordion-header__arrow">
                  keyboard_arrow_down
                </span>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'cierre',
                            cierre: 'minutes',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'cierre',
                              {
                                ...cierre,
                                minutes: undefined,
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Tiempo estimado
                            <OverlayComponent toolTipText="Añade el tiempo destinado a esta etapa de la sesión. " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <TimepickerComponent
                              currentValue={
                                cierre?.minutes
                                  ? fromUnixTime(cierre.minutes)
                                  : null
                              }
                              onTimeChange={(unix) => {
                                const newSession = updateSecuenciaState(
                                  session,
                                  'cierre',
                                  {
                                    ...cierre,
                                    minutes: unix,
                                  }
                                );
                                updateSecuencia(newSession);
                              }}
                            />
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'cierre',
                            cierre: 'description',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'cierre',
                              {
                                ...cierre,
                                description: '',
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Actividades a desarrollar
                            <OverlayComponent toolTipText="Describe las actividades que vas a desarrollar para la última etapa de tu actividad. ARTI te puede ayudar con algunas ideas " />
                          </>
                        }
                        contentToEnabled={
                          <> 
                            <ChatGpt 
                              user={user}
                              onChatButtonClick={handleChatButtonClick} 
                              onChatOptionButtonClick={handleChatOptionButtonClick} 
                              campo='actividades para el cierre de tu secuencia'
                              slug='cierre-secuencia'
                              planningId={planning._id}
                              asignaturaId={planningAsignatura._id}
                              sessionId={session._id}
                              option={7}
                            />
                            {showTextArea ? (
                            <div className="form-control form--hide-element">
                              <textarea
                              className="form-control"
                              value={cierre?.description}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'cierre', {
                                  ...cierre,
                                  description: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  cierre: {
                                    ...cierre,
                                    description: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                              <p className="autocomplete-field__indicator">Texto sugerido por ARTI, puedes copiarlo y pegarlo en el campo de tu planeación, ahí podrás editarlo. <br /> Solo puedes usar ARTI 2 veces en este campo.</p>
                              <div className="autocomplete-field__container">
                                { loading ? <img className="loader-small" src="/assets/images/elements/arthinking.gif" /> : <></> }
                                <textarea className="autocomplete-field" value={textAreaValue} />
                                { textAreaValue != '' ? 
                                  <div className='autocomplete-field__btn'>
                                    <button
                                      className="btn btn--type-outline btn--small"
                                      onClick={(evt) => {
                                        evt.preventDefault();
                                        copytxt();
                                      }}
                                    >
                                      Copiar
                                    </button>
                                  </div>
                                  : <></>
                                }
                              </div>
                             
                            </div>
                            ) : <>
                            <textarea
                              className="form-control"
                              value={cierre?.description}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'cierre', {
                                  ...cierre,
                                  description: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  cierre: {
                                    ...cierre,
                                    description: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                            </>}
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'cierre',
                            cierre: 'preguntasReflexion',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'cierre',
                              {
                                ...cierre,
                                preguntasReflexion: [],
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Preguntas de reflexión
                            <OverlayComponent toolTipText="Realiza preguntas para promover la reflexión de tus estudiantes sobre la actividad y/o los aprendizajes que alcanzaron.  " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <MultiSelectComponent
                              from="search"
                              value={
                                cierre?.preguntasReflexion?.length
                                  ? cierre.preguntasReflexion.map((pR) => ({
                                      label: pR.value,
                                      value: pR.documentId,
                                    }))
                                  : []
                              }
                              options={pReflexion.map((pregunta) => {
                                return {
                                  label: pregunta.attributes.Nombre,
                                  value: `${pregunta.id}`,
                                };
                              })}
                              onChange={(values) => {
                                const preguntasReflexion = values || [];
                                const secuencia = updateSecuenciaState(
                                  session,
                                  'cierre',
                                  {
                                    ...cierre,
                                    preguntasReflexion: preguntasReflexion.map(
                                      (pR) => ({
                                        documentId: pR.value,
                                        value: pR.label,
                                      })
                                    ),
                                  }
                                );
                                updateSecuencia({ ...secuencia });
                              }}
                            />
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <section className="session-browser">
                  <div className="sessionbrowser__item browser--button col-md-2 col-12">
                    <div>
                      <p>Agregar recurso educativo</p>
                      <button
                        className="btn btn--type-outline btn--small"
                        onClick={(evt) => {
                          evt.preventDefault();
                          trackEvent({
                            event: 'addRecurso',
                            page: '/planeacion-flexible',
                            planningId: planning._id,
                            asignaturaId: planningAsignatura._id,
                            sessionId: session._id,
                            section: 'cierre',
                            fullPath: window.location.href,
                          });
                          navigate(
                            `/busqueda/${planning._id}/${planningAsignatura._id}/${session._id}/cierre`
                          );
                        }}
                      >
                        + Agregar
                      </button>
                    </div>
                  </div>
                  {(strapiRecursos || []).map((recurso, k) => {
                    const {
                      attributes: {
                        Nombre,
                        fuente,
                        tipo_de_recursos: tipoDeRecurso,
                      },
                    } = recurso;
                    const recursoFiltro = (tiposRecurso.data || []).filter(
                      (tR) => tR.id === tipoDeRecurso.data[0].id
                    );
                    const logo = recursoFiltro.length
                      ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                      : null;
                    return (
                      <div
                        className="sessionbrowser__item browser--element col-md-2 col-12"
                        key={k}
                      >
                        <RecursoDidacticoComponent
                          name={Nombre}
                          url={`/actividad/${recurso.id}`}
                          image={
                            logo
                              ? logo.data.attributes.formats.thumbnail.url
                              : ''
                          }
                          content={
                            <>
                              <article>
                                <p>
                                  <small>Actividad</small>
                                </p>
                                <p>{`${tipoDeRecurso.data
                                  .map((tR) => tR.attributes.Nombre)
                                  .join(', ')}`}</p>
                              </article>
                              <article>
                                <p>
                                  <small>Fuente</small>
                                </p>
                                <p>{`${fuente.data.attributes.Fuente}`}</p>
                              </article>
                            </>
                          }
                          onDeleteResource={() => {
                            return Swal.fire({
                              icon: 'question',
                              title: 'Eliminar recurso',
                              text: `¿Deseas quitar este recurso de tu secuencia didáctica?`,
                              showCancelButton: true,
                              confirmButtonText: 'Sí, quitar',
                              cancelButtonText: 'Cancelar',
                              showLoaderOnConfirm: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const currentRs = cierre?.resources || [];
                                const index = currentRs.findIndex(
                                  (r) => r.documentId === `${recurso.id}`
                                );
                                currentRs.splice(index, 1);
                                updateSecuencia({
                                  ...session,
                                  cierre: {
                                    ...cierre,
                                    resources: currentRs,
                                  },
                                });
                              }
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </section>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </>
  );
};

export default CierreSecuenciaComponent;
