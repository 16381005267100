import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { es } from 'date-fns/locale';
import './timepicker.component.scss';
import { getUnixTime } from 'date-fns';

type Props = {
  disabled?: boolean;
  onTimeChange?: (unix: number) => void;
  currentValue?: Date | null;
};

const TimepickerComponent = ({
  disabled,
  onTimeChange,
  currentValue,
}: Props) => {
  const handleChange = (date: Date | null) => {
    if (date && onTimeChange) {
      const unixDate = date ? getUnixTime(date) : 0;
      onTimeChange(unixDate);
    }
  };
  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
      <Stack>
        <div className="timepicker">
          <TimePicker
            label="Time"
            value={currentValue}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            views={['hours', 'minutes']}
            minTime={new Date('2023-01-01T00:01')}
            maxTime={new Date('2023-01-01T04:59')}
            disabled={disabled || false}
            inputFormat="HH:mm"
          />
          
        </div>
      </Stack>
    </LocalizationProvider>
  );
};

export default TimepickerComponent;
