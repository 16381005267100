import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Auth, Amplify, Hub } from 'aws-amplify';
import Swal from 'sweetalert2';
import HomeScreen from './pages/Home/home.screen';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import { track, TrackingProp } from 'react-tracking';
import RegisterScreen from './pages/Auth/register/register.screen';
import LoginScreen from './pages/Auth/login/login.screen';
import RecoverScreen from './pages/Auth/recover/recover.screen';
import PasswordScreen from './pages/Auth/password/password.screen';
import StepOneScreen from './pages/Steps/stepOne/stepOne.screen';
import StepTwoScreen from './pages/Steps/stepTwo/stepTwo.screen';
import StepThreeScreen from './pages/Steps/stepThree/stepThree.screen';
import PlanningOneScreen from './pages/Planning/planningOne/planningOne.screen';
import PlanningTwoScreen from './pages/Planning/planningTwo/planningTwo.screen';
import PlanningThreeScreen from './pages/Planning/planningThree/planningThree.screen';
import SessionInitScreen from './pages/Sessions/sessionInit/sessionInit.screen';
import SessionPrivateScreen from './pages/Session/sessionPrivate/sessionPrivate.screen';
import SearchScreen from './pages/Search/search.screen';
import ActivityScreen from './pages/Activity/activity.screen';
import ActivityPecScreen from './pages/ActivityPec/activityPec.screen';
import ActivityIntroScreen from './pages/ActivityIntro/activityIntro.screen';
import AnswersIndexScreen from './pages/Answers/index/answersIndex.screen';
import AnswersContentScreen from './pages/Answers/content/answersContent.screen';
import ExplainContentScreen from './pages/Explain/content/explainContent.screen';
import ExplainIntroScreen from './pages/Explain/intro/explainIntro.screen';
import ExplainDetailScreen from './pages/Explain/detail/explainDetail.screen';
import DashboardScreen from './pages/Profile/dashboard/dashboard.screen';
import FavoritesScreen from './pages/Profile/favorites/favorites.screen';
import ProfileScreen from './pages/Profile/personalData/personalData.screen';
import EducationCenterScreen from './pages/Profile/educationCenter/educationCenter.screen';
import PlanningsScreen from './pages/Materials/plannings/plannings.screen';
import FaqsScreen from './pages/Faqs/faqs.screen';
import ContactScreen from './pages/Contact/contact.screen';
import PrivacyScreen from './pages/Privacy/privacy.screen';
import TermsScreen from './pages/Terms/terms.screen';
import HelpScreen from './pages/Profile/help/help.screen';
import QuickDashboardScreen from './pages/QuickDashboard/quickDashboard.screen';
import TestAnswersIndexScreen from './pages/TestAnswers/index/testAnswersIndex.screen';
import TestAnswersContentScreen from './pages/TestAnswers/content/testAnswersContent.screen';
import TestAnswersChooseScreen from './pages/TestAnswers/choose/testAnswersChoose.screen';
import TestAnswersDetailScreen from './pages/TestAnswers/detail/testAnswersDetail.screen';
import PlaneaionesFlexiblesScreen from './pages/PlaneacionesFlexibles/planeacionesFlexibles.screen';
import './sass/App.scss';
import awsExports from './aws-exports';
import { deleteSession, getSession, saveSession } from './utils/helpers';
import { getUser, searchUser, userSignUp } from './api/usersApi';
import { IUser } from './types/UserInterface';
import PrintPlanningWrapper from './pages/Print/planning/printPlanningWrappet';
import { IdleTimer } from './components/IdleTimer/idleTimerComponent.component';
import { createSessionEvent } from './api/sessionEventsApi';
import StrategyIndexScreen from './pages/Strategies/index/strategiesIndex.screen';
import DetailIndexScreen from './pages/Strategies/detail/strategiesDetail.screen';
import PlaneaionesFlexiblesPreview from './pages/PlaneacionesFlexibles/planeacionesFlexibles.preview';
import InstrumentosIndexScreen from './pages/Instrumentos/index/instrumentosIndex.screen';
import InstrumentosDetailScreen from './pages/Instrumentos/detail/instrumentosDetail.screen';
import InstrumentosDashboardScreen from './pages/Instrumentos/dashboard/instrumentosDashboard.screen';
import NemBlog from './pages/NemBlog/nemblog.screen';
import RPapaloteScreen from './pages/ResourcesPapalote/papalote.screen';
import ActivityPapaloteScreen from './pages/ActivityPapalote/activityPapalote.screen';
import PlanningsViewScreen from './pages/Materials/planningsView/planningsView.screen';
import PlaneacionNemIndexScreen from './pages/PlanningNem/index/planningNemIndex.screen';
import PlanningNemDashboardScreen from './pages/PlanningNem/dashboard/planningNemDashboard.screen';
import PlanningNemDetailScreen from './pages/PlanningNem/detail/planningNemDetail.screen';

setDefaultOptions({ locale: es });

awsExports.oauth.domain = 'auth.pacoteacher.com';
Amplify.configure(awsExports);

type State = {
  session: IUser | null;
  userLoggedIn: boolean;
  isTimedOut: boolean;
  isLoading: boolean;
};

interface Props {
  tracking?: TrackingProp | undefined;
}

@track({ page: 'app' })
class App extends React.Component<Props, State> {
  idleTimer: any;

  constructor(props: any) {
    super(props);

    this.state = {
      session: null,
      userLoggedIn: false,
      isTimedOut: false,
      isLoading: false,
    };

    this.idleTimer = null;
    this.onPrompt = this.onPrompt.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
  }

  onPrompt() {
    console.log('onPromp');
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          console.log('user', { user });
          Swal.fire({
            icon: 'warning',
            title: '¿Aún estas en PACO Teacher?',
            text: `Confirma que sigues aquí o tu sesión cerrara pronto. `,
            showCancelButton: true,
            confirmButtonText: 'Sigo aquí',
            cancelButtonText: 'Cerrar sesión',
            showLoaderOnConfirm: true,
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.setState({ isTimedOut: false });
              this.idleTimer.reset();
              return 0;
            } else {
              this.cerrarSesion();
            }
          });
          this.setState({ isTimedOut: true });
        }
      })
      .catch(() => {
        console.log('onPromp - No user');
      });
  }

  onIdle() {
    const { isTimedOut } = this.state;
    console.log('onIdle', { isTimedOut });
    if (isTimedOut) {
      this.cerrarSesion();
    }
  }

  cerrarSesion() {
    deleteSession();
    Auth.signOut().then(() => {
      window.location.href = '/';
    });
  }

  onActive() {
    // event: Event | undefined
    // Close Modal Prompt
    // Do some active action
  }

  onAction() {
    // event: Event | undefined
    // Do something when a user triggers a watched event
  }

  onHubAction = async (autData: any): Promise<true> => {
    console.log('auth - onHubAction', { autData });
    const {
      payload: { event, data },
    } = autData;
    console.log('auth - Register', { autData });
    await createSessionEvent(event, { ...autData });
    if (event === 'signIn') {
      try {
        this.setState({ isLoading: true });
        const {
          signInUserSession: {
            accessToken: { jwtToken },
            idToken: { payload: attributes },
          },
        } = data;
        if (attributes && jwtToken) {
          const kameUserId = attributes['custom:kameUserId'] || null;
          if (kameUserId) {
            const kameUser = await getUser(kameUserId, jwtToken);
            await saveSession({ ...kameUser, token: jwtToken });
            this.setState({ session: { ...kameUser, token: jwtToken } });
            const { name, lastName, middleName, phone } = kameUser;
            const valid = [name, lastName, middleName, phone].every(
              (k) => k && k !== ''
            );
            if (valid) {
              const isHomeLayout =
                window.location.pathname === '/' ||
                window.location.pathname === '/iniciar-sesion';
              const buttonTo = localStorage.getItem('buttonTo');
              if (buttonTo) {
                window.location.href = buttonTo;
              } else {
                if (isHomeLayout) {
                  window.location.href = '/bienvenido';
                }
              }
              localStorage.removeItem('buttonTo');
              this.setState({ isLoading: false });
              return true;
            } else {
              window.location.href = '/paso-1-perfil';
            }
          } else {
            await this.kameUser(data).then(() => {
              window.location.href = '/paso-1-perfil';
            });
          }
          this.setState({ isLoading: false });
          return true;
        }
      } catch (err) {
        //this.setError((err as any).message);
        this.setError('Por favor, intentalo de nuevo.');
      }
    }
    this.setState({ isLoading: false });
    return true;
  };

  componentDidMount() {
    this.idleTimer.start();
    const session = getSession();
    this.setState({ session });
    Hub.listen('auth', async (autData) => {
      await this.onHubAction(autData);
    });
  }

  async kameUser(user: any) {
    try {
      const {
        signInUserSession: {
          accessToken: { jwtToken },
          idToken: {
            payload: { email },
          },
        },
      } = user;
      const searchResult = await searchUser(0, 1, '', { email }, jwtToken);
      if (searchResult && searchResult.docs.length) {
        const currentKameUser = searchResult.docs[0];
        await Auth.updateUserAttributes(user, {
          'custom:kameUserId': currentKameUser._id,
        });
        await saveSession({ ...currentKameUser, token: jwtToken });
        this.setState({ session: { ...currentKameUser, token: jwtToken } });
      } else {
        const kameUser = await userSignUp(
          {
            email,
          },
          jwtToken
        );
        await saveSession({ ...kameUser, token: jwtToken });
        await Auth.updateUserAttributes(user, {
          'custom:kameUserId': kameUser._id,
        });
        this.setState({ session: { ...kameUser, token: jwtToken } });
      }
    } catch (err) {
      //this.setError((err as any).message);
      this.setError('Por favor, intentalo de nuevo.');
    }
  }

  setError(errorMessage: string) {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  }

  render(): React.ReactNode {
    const { session } = this.state;
    const isHomeLayout = window.location.pathname === '/'; // Verifica si el layout actual es el HOME
    return (
      <>
        <IdleTimer
          ref={(ref: any) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 35}
          promptTimeout={1000 * 30}
          element={document}
          debounce={250}
          onPrompt={this.onPrompt}
          onIdle={this.onIdle}
          onAction={this.onAction}
          onActive={this.onActive}
        />
        <main className={`app-main${isHomeLayout ? '' : ' no-index-layout'}`}>
          <div className="loader__wrapp" data-class={this.state.isLoading}>
            <div className="loader__inner">
              <div className="loader"></div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/registro" element={<RegisterScreen />} />
            <Route path="/iniciar-sesion" element={<LoginScreen />} />
            <Route path="/recuperar-contrasena" element={<RecoverScreen />} />
            <Route path="/nueva-contrasena" element={<PasswordScreen />} />
            <Route
              path="/paso-1-perfil"
              element={this.state.session ? <StepOneScreen /> : <LoginScreen />}
            />
            <Route
              path="/paso-2-centro-educativo"
              element={session ? <StepTwoScreen /> : <LoginScreen />}
            />
            <Route
              path="/crear-planeacion"
              element={session ? <StepThreeScreen /> : <LoginScreen />}
            />
            <Route
              path="/planeacion-aprendizajes/:id"
              element={session ? <PlanningOneScreen /> : <LoginScreen />}
            />
            <Route
              path="/planeacion-campos/:id"
              element={session ? <PlanningTwoScreen /> : <LoginScreen />}
            />
            <Route
              path="/planeacion-resultado/:id"
              element={session ? <PlanningThreeScreen /> : <LoginScreen />}
            />
            <Route
              path="/planeacion-sesiones/:id"
              element={session ? <SessionInitScreen /> : <LoginScreen />}
            />
            <Route
              path="/busqueda"
              element={session ? <SearchScreen /> : <LoginScreen />}
            >
              <Route
                path=":sessionId/:bloqueId"
                element={session ? <SearchScreen /> : <LoginScreen />}
              />
              <Route
                path=":planningId/:asignaturaId/:secuenciaId/:bloqueId"
                element={session ? <SearchScreen /> : <LoginScreen />}
              />
            </Route>
            <Route
              path="/sesiones/:id"
              element={session ? <SessionPrivateScreen /> : <LoginScreen />}
            >
              <Route
                path=":sessionId"
                element={session ? <SessionPrivateScreen /> : <LoginScreen />}
              />
            </Route>
            <Route path="/actividad/:recursoId" element={<ActivityScreen />}>
              <Route
                path=":sessionId/:bloqueId"
                element={session ? <ActivityScreen /> : <LoginScreen />}
              />
              <Route
                path=":planningId/:asignaturaId/:secuenciaId/:bloqueId"
                element={session ? <ActivityScreen /> : <LoginScreen />}
              />
            </Route>
            <Route
              path="/respuestas-grado/"
              element={session ? <AnswersIndexScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/respuestas-materia/:gradoId"
              element={session ? <AnswersContentScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/explicaciones-materia/"
              element={session ? <ExplainContentScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/explicaciones-indice"
              element={session ? <ExplainIntroScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/paco-te-explica/:materia/:slug"
              element={session ? <ExplainDetailScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/actividad-indice/:gradoId/:bookId"
              element={session ? <ActivityIntroScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/actividad-pec/:bookId/:lessonId/:pageNumber"
              element={session ? <ActivityPecScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/mis-planeaciones"
              element={session ? <DashboardScreen /> : <LoginScreen />}
            />
            <Route
              path="/mis-favoritos"
              element={session ? <FavoritesScreen /> : <LoginScreen />}
            />
            <Route
              path="/mi-perfil"
              element={session ? <ProfileScreen /> : <LoginScreen />}
            />
            <Route
              path="/mi-centro-educativo"
              element={session ? <EducationCenterScreen /> : <LoginScreen />}
            >
              <Route
                path=":planningId"
                element={session ? <EducationCenterScreen /> : <LoginScreen />}
              />
            </Route>
            <Route
              path="/sesion-compartida/:planningId"
              element={<PrintPlanningWrapper />}
            />
            <Route
              path="/planeaciones/:type"
              element={session ? <PlanningsScreen /> : <LoginScreen />}
            />
            <Route
              path="/planeaciones-nueva-escuela-mexicana/:id"
              element={session ? <PlanningsViewScreen /> : <LoginScreen />}
            />
            <Route path="/recursos-digitales" element={<SearchScreen />} />
            <Route path="/preguntas-frecuentes" element={<FaqsScreen />} />
            <Route path="/contacto" element={<ContactScreen />} />
            <Route path="/aviso-de-privacidad" element={<PrivacyScreen />} />
            <Route path="/terminos-y-condiciones" element={<TermsScreen />} />
            <Route path="/ayuda" element={<HelpScreen />} />
            <Route
              path="/instrumentos-de-evaluacion"
              element={session ? <InstrumentosIndexScreen /> : <LoginScreen />}
            />
            <Route
              path="/instrumentos-de-evaluacion/:planningId/:asignaturaId/:sessionId"
              element={session ? <InstrumentosIndexScreen /> : <LoginScreen />}
            />
            <Route
              path="/instrumento-detalle/:id"
              element={session ? <InstrumentosDetailScreen /> : <LoginScreen />}
            />
            <Route
              path="/mis-instrumentos"
              element={
                session ? <InstrumentosDashboardScreen /> : <LoginScreen />
              }
            />
            <Route
              path="/ponte-a-prueba-grados"
              element={session ? <TestAnswersIndexScreen /> : <LoginScreen />}
            />
            <Route
              path="/ponte-a-prueba-materia/:gradoId"
              element={session ? <TestAnswersContentScreen /> : <LoginScreen />}
            />
            <Route
              path="/ponte-a-prueba-tipo/:gradoId/:subject"
              element={session ? <TestAnswersChooseScreen /> : <LoginScreen />}
            />
            <Route
              path="/ponte-a-prueba-detalle/:gradoId/:subject/:idExam"
              element={session ? <TestAnswersDetailScreen /> : <LoginScreen />}
            />
            <Route
              path="/bienvenido"
              element={session ? <QuickDashboardScreen /> : <HomeScreen />}
            />
            <Route
              path="/planeacion-flexible"
              element={
                session ? <PlaneaionesFlexiblesScreen /> : <LoginScreen />
              }
            >
              <Route
                path=":planningId"
                element={
                  session ? <PlaneaionesFlexiblesScreen /> : <LoginScreen />
                }
              />
              <Route
                path=":planningId/:asignaturaId/:secuenciaId"
                element={
                  session ? <PlaneaionesFlexiblesScreen /> : <LoginScreen />
                }
              />
            </Route>
            <Route
              path="/planeacion-flexible-vista-previa/:planningId"
              element={
                session ? <PlaneaionesFlexiblesPreview /> : <LoginScreen />
              }
            />
            <Route
              path="/estrategias-didacticas"
              element={session ? <StrategyIndexScreen /> : <LoginScreen />}
            />
            <Route
              path="/estrategias-didacticas-detalle/:estrategiaId/:temaId"
              element={session ? <DetailIndexScreen /> : <LoginScreen />}
            />
            <Route
              path="/boletin-nueva-escuela-mexicana"
              element={session ? <NemBlog /> : <LoginScreen />}
            />
            <Route
              path="/recursos-papalote"
              element={session ? <RPapaloteScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/actividad-papalote/:recursoId"
              element={session ? <ActivityPapaloteScreen /> : <LoginScreen />}
            ></Route>
            <Route
              path="/crea-tu-planeacion-nem"
              element={session ? <PlaneacionNemIndexScreen /> : <LoginScreen />}
            />
            <Route
              path="/planeacion-nem-detalle/:id"
              element={session ? <PlanningNemDetailScreen /> : <LoginScreen />}
            />
            <Route
              path="/mis-planeaciones-nem"
              element={
                session ? <PlanningNemDashboardScreen /> : <LoginScreen />
              }
            />
          </Routes>
        </main>
      </>
    );
  }
}

export default App;
