import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import AsideComponent from '../../../components/Aside/aside.component';
import InputComponent from '../../../components/Form/input/input.component';
import CheckboxComponent from '../../../components/Form/checkbox/checkbox.component';
import SelectComponent from '../../../components/Form/select/select.component';
import StepperPlanningComponent from '../../../components/StepperPlanning/stepperPlanning.component';
import ModalWarningComponent from '../../../components/Modal/modalWarning/modalWarning.component';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import './stepThree.screen.scss';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { getModeloEducativo, getStrapiCatalog } from '../../../strapi/escuelas';
import {
  Grado,
  Grupo,
  CampoFormacion,
  Materia,
  ModeloEducativoData,
  Eje,
  Bloque,
  Escuela,
  PracticaSocial,
  TipoTexto,
  LenguajeArtistico,
} from '../../../types/EntidadesInterface';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';
import { IPlanning } from '../../../types/PlaningInterface';
import { createPlanning } from '../../../api/planningsApi';
import { getAnStrapiEntry } from '../../../strapi/escuelas';
import { Link } from 'react-router-dom';

const StepThreeScreen = () => {
  const { trackEvent } = useTracking();
  const [modalShow, setModalShow] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [modelos, setModelos] = useState<ModeloEducativoData[]>([]);
  const [grados, setGrados] = useState<{ id: number; attributes: Grado }[]>([]);
  const [grupos, setGrupos] = useState<{ id: number; attributes: Grupo }[]>([]);
  const [camposFormacion, setCamposFormacion] = useState<
    { id: number; attributes: CampoFormacion }[]
  >([]);
  const [materias, setMaterias] = useState<
    { id: number; attributes: Materia }[]
  >([]);
  const [bloques, setBloques] = useState<{ id: number; attributes: Bloque }[]>(
    []
  );
  const [ejes, setEjes] = useState<{ id: number; attributes: Eje }[]>([]);
  const [temas, setTemas] = useState<{ id: number; attributes: Eje }[]>([]);
  const [tipotexto, setTipotext] = useState<
    { id: number; attributes: TipoTexto }[]
  >([]);
  const [pslenguaje, setPslenguaje] = useState<
    { id: number; attributes: PracticaSocial }[]
  >([]);
  const [lengart, setLengart] = useState<
    { id: number; attributes: LenguajeArtistico }[]
  >([]);

  const [planeacion, setPlaneacion] = useState<Partial<IPlanning>>({});

  const [escuela, setEscuela] = useState<{
    id: number;
    attributes: Escuela;
  } | null>(null);
  const [escuelaSelected, setEscuelaSelected] = useState('');
  const [escuelaSelect, setEscuelaSelect] = useState<{
    documentId: String;
    cct: String;
  }>();

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchEscuela = async (documentId: string) => {
    try {
      const resp = await getAnStrapiEntry('/escuelas', documentId);
      setEscuela(
        resp.data as {
          id: number;
          attributes: Escuela;
        }
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchCatalog = async (query: string, route: string) => {
    try {
      const result = await getStrapiCatalog(query, route);
      if (result.data.length) {
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return [];
  };

  const fetchMaterias = async (
    modeloEducativoId: string,
    gradoId: string,
    campoFA?: string
  ) => {
    const query = {
      sort: ['id:asc'],
      populate: '*',
      pagination: { pageSize: 50 },
    };
    const filt: any[] = [
      { modelo_educativo: { id: { $eq: modeloEducativoId } } },
      { grados: { id: { $eq: gradoId } } },
    ];
    if (campoFA) {
      filt.push({ campos_de_formacion: { id: { $eq: campoFA } } });
    }
    const resultMaterias = await fetchCatalog(
      qs.stringify({
        ...query,
        sort: ['Materia:asc'],
        filters: { $and: filt },
      }),
      '/materias'
    );
    setMaterias(
      resultMaterias as {
        id: number;
        attributes: Materia;
      }[]
    );
  };

  const fetchBloques = async (asignaturaS: string, gradoS: string) => {
    if (asignaturaS && asignaturaS !== '' && gradoS && gradoS !== '') {
      const query = {
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
        filters: {
          $and: [
            {
              materias: { id: { $eq: asignaturaS } },
              grados: { id: { $eq: gradoS } },
            },
          ],
        },
      };
      const resultBloques = await fetchCatalog(
        qs.stringify({ ...query }),
        '/bloques'
      );
      setBloques(
        resultBloques as {
          id: number;
          attributes: Bloque;
        }[]
      );
    }
  };

  const fetchCatalogs = async () => {
    try {
      const query = {
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
      };
      const resultGrados = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/grados'
      );
      const resultGrupos = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/grupos'
      );
      const resultCF = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/campos-de-formacions'
      );

      setGrados(
        resultGrados as {
          id: number;
          attributes: Grado;
        }[]
      );
      setGrupos(
        resultGrupos as {
          id: number;
          attributes: Grupo;
        }[]
      );
      setCamposFormacion(
        resultCF as {
          id: number;
          attributes: CampoFormacion;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchLengArt = async (bloqueS: string, temaS: string) => {
    try {
      const { modeloEducativo } = planeacion;
      const valid = [modeloEducativo?.documentId].every((k) => k && k !== '');
      const hasBloques =
        modeloEducativo?.documentId === '1'
          ? !!(bloqueS && bloqueS !== '')
          : true;
      if (valid && hasBloques) {
        const query = {
          sort: ['Nombre:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            $and: [
              {
                temas: { id: { $eq: temaS } },
              },
            ],
          },
        };
        const result = await getStrapiCatalog(
          qs.stringify(query),
          '/lenguaje-artisticos'
        );
        setLengart(
          result.data as {
            id: number;
            attributes: LenguajeArtistico;
          }[]
        );
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return null;
  };

  const fetchTipoTextPsl = async (
    ejeS: string,
    bloqueS: string,
    gradoS: string,
    caseS: string
  ) => {
    try {
      const { modeloEducativo } = planeacion;
      const valid = [modeloEducativo?.documentId].every((k) => k && k !== '');
      const hasBloques =
        modeloEducativo?.documentId === '1'
          ? !!(bloqueS && bloqueS !== '')
          : true;
      if (valid && hasBloques) {
        if (caseS == 'psl') {
          setPslenguaje([]);
          setTipotext([]);
          const query = {
            sort: ['Nombre:asc'],
            populate: '*',
            pagination: { pageSize: 50 },
            filters: {
              $and: [
                {
                  eje: { id: { $eq: ejeS } },
                  grado: { id: { $eq: gradoS } },
                  bloque: { id: { $eq: bloqueS } },
                },
              ],
            },
          };
          const result = await getStrapiCatalog(
            qs.stringify(query),
            '/practicas-sociales'
          );
          setPslenguaje(
            result.data as {
              id: number;
              attributes: PracticaSocial;
            }[]
          );
          return result.data;
        } else {
          setTipotext([]);
          const query = {
            sort: ['Nombre:asc'],
            populate: '*',
            pagination: { pageSize: 50 },
            filters: {
              $and: [
                {
                  practica_socials: { id: { $eq: caseS } },
                },
              ],
            },
          };
          const result = await getStrapiCatalog(
            qs.stringify(query),
            '/tipo-de-textos'
          );
          setTipotext(
            result.data as {
              id: number;
              attributes: TipoTexto;
            }[]
          );
          return result.data;
        }
      }
    } catch (error) {
      setError(`${error}`);
    }
    return null;
  };

  const fetchEjesOrTemas = async (
    gradoS: string,
    materiaS: string,
    bloqueS: string,
    ejeS: string,
    path: '/ejes' | '/temas'
  ) => {
    try {
      const { modeloEducativo } = planeacion;
      const valid = [gradoS, materiaS, modeloEducativo?.documentId].every(
        (k) => k && k !== ''
      );
      const hasBloques =
        modeloEducativo?.documentId === '1'
          ? !!(bloqueS && bloqueS !== '')
          : true;
      console.log({ hasBloques });
      if (valid && hasBloques) {
        const ands: any[] = [
          { grados: { id: { $eq: gradoS } } },
          { materia: { id: { $eq: materiaS } } },
          {
            modelo_educativo: { id: { $eq: modeloEducativo?.documentId } },
          },
        ];
        if (path === '/ejes' && modeloEducativo?.documentId === '1') {
          ands.push({ bloques: { id: { $eq: bloqueS } } });
        }
        if (path === '/temas' && ejeS) {
          ands.push({ ejes: { id: { $eq: ejeS } } });
        }
        const query = {
          sort: ['Nombre:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            $and: ands,
          },
        };
        const result = await getStrapiCatalog(qs.stringify(query), path);
        switch (path) {
          case '/ejes': {
            setEjes(
              result.data as {
                id: number;
                attributes: Eje;
              }[]
            );
            break;
          }
          case '/temas': {
            if (ejeS) {
              setTemas(
                result.data as {
                  id: number;
                  attributes: Eje;
                }[]
              );
            }
            break;
          }
        }
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return null;
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/crear-planeacion',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              getModeloEducativo()
                .then((resultEntidades) => {
                  if (resultEntidades.data.length) {
                    setModelos(resultEntidades.data);
                  }
                  if (kameUser.userCCTs?.length === 1) {
                    fetchEscuela(kameUser.userCCTs[0].documentId);
                  }
                })
                .catch((error) => setError(error));
              fetchCatalogs();
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  const validPlaneacion = () => {
    const { modeloEducativo, gradoEscolar, grupoEscolar, asignatura, name } =
      planeacion;
    const valid = [
      modeloEducativo,
      gradoEscolar,
      grupoEscolar,
      asignatura,
      name,
      (user.userCCTs || []).length >= 1 && escuela
        ? escuela
        : (user.userCCTs || []).length < 1
        ? 'ok'
        : '',
    ].every((k) => k && (k !== '' || Object.keys(k).length));
    return valid;
  };

  const handleMateria = (evt: any) => {
    const materia = materias.filter((m) => `${m.id}` === evt);
    const resetPlaneacion = { ...planeacion };
    delete resetPlaneacion.eje;
    delete resetPlaneacion.tema;
    setPlaneacion({
      ...resetPlaneacion,
      asignatura: {
        documentId: evt,
        value: materia.length ? materia[0].attributes.Materia : '',
      },
      bloque: {
        documentId: '',
        value: '',
      },
    });
    if (planeacion.gradoEscolar) {
      fetchBloques(evt, planeacion.gradoEscolar.documentId);
      fetchEjesOrTemas(
        planeacion.gradoEscolar.documentId,
        evt,
        planeacion.bloque?.documentId || '',
        planeacion.eje?.documentId || '',
        '/ejes'
      );
    }
  };

  return (
    <section className="app-section">
      <ModalWarningComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        type="success"
        title="¡Felicidades!"
        target=""
        content={
          <>
            <p>
              Ahora puedes agregar actividades a tu planeación de{' '}
              {`${planeacion.asignatura?.value || ''}`}.
            </p>
            <p>
              Agrega los temas y recursos necesarios para enriquecer tu
              planeación.
            </p>
          </>
        }
        url={`/planeacion-aprendizajes/${planeacion._id || ''}`}
        urlText="Continuar"
      />
      <ModalTooltipComponent
        title="Iniciar planeación"
        description="<p>Escoge el modelo educativo que sigues en tu institución educativa, selecciona el grado que impartes y elige la información referente a la asignatura para la que vas a planear. </p><p><strong>¿Tienes dudas sobre cómo usar la plataforma?</strong>. Ingresa a nuestro canal de YouTube y aprende a usar la plataforma, <a href='/ayuda'>Ir a los tutoriales</a> o <a href='/preguntas-frecuentes'>Ir a las preguntas frecuentes</a></p>"
      />
      <HeaderLoginComponent />
      <div className="app-section__top">
        <BreadcrumbComponent />
        <div className="container">
          <div className="row">
            <div className="register__col-left col-md-4 col-12">
              <AsideComponent
                title="¡Planeación sin dolor! Comienza tu planeación aquí"
                list=""
                image="/assets/images/aside/aside4.png"
                alt="Registro"
              />
            </div>
            <div className="register__col-right col-md-8 col-12">
              <div className="form-init ">
                <div className="form-init__inner col-lg-8 col-md-10 col-12 mx-auto">
                  <figure className="beta__image">
                    <img
                      src="assets/images/elements/planning-beta.png"
                      alt="Espera muy pronto una version mejorada"
                    />
                  </figure>
                </div>
                <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                  <StepperPlanningComponent status={1} />
                  <h3 className="form__title">Inicia tu planeación</h3>
                  <div className="insti-block">
                    <div className="result-block__row row">
                      <div className="result-block__col col-12">
                        {!user.userCCTs?.length ? (
                          <>
                            <h3>Institución</h3>
                            <span className="badge bg-alert text-dark">
                              Completa la información en tu perfil para agregar
                              tus datos automáticamente.
                            </span>
                            <Link to={`/mi-centro-educativo`}>
                              Completar información
                            </Link>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {escuela ? (
                      <div>
                        <div className="result-block__row row">
                          <div className="result-block__col col-md-6 col-12">
                            <label className="form__label">
                              Nombre de la Institución Educativa
                            </label>
                            <p>{`${escuela.attributes.Nombre || ''}`}</p>
                          </div>
                          <div className="result-block__col col-md-6 col-12">
                            <label className="form__label">
                              Clave del centro de trabajo CCT
                            </label>
                            <p>{`${escuela.attributes.CCT || ''}`}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {!escuela && (user.userCCTs || []).length > 1 ? (
                      <div className="school">
                        <label className="form__label">
                          Selecciona tu escuela{' '}
                          <small className="required">*</small>
                        </label>
                        {(user.userCCTs || []).map((e, k) => {
                          return (
                            <CheckboxComponent
                              key={k}
                              id={`${e.documentId}`}
                              htmlFor={`${e.documentId}`}
                              text={`${e.cct} | ${e.Nombre}`}
                              checked={escuelaSelected === e.documentId}
                              onChangeEvent={(checked) => {
                                if (checked) {
                                  setEscuelaSelected(e.documentId);
                                  return Swal.fire({
                                    icon: 'question',
                                    title: 'Añade tu escuela',
                                    text: `¿Deseas vincular el CCT: ${e.cct} a esta planeación?`,
                                    showCancelButton: true,
                                    confirmButtonText: 'Vincular',
                                    cancelButtonText: 'Cancelar',
                                    showLoaderOnConfirm: true,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      return fetchEscuela(e.documentId).then(
                                        () => {
                                          setEscuelaSelect({
                                            documentId: e.documentId,
                                            cct: e.cct,
                                          });
                                        }
                                      );
                                    } else {
                                      setEscuelaSelected('');
                                    }
                                  });
                                } else {
                                  setEscuelaSelected('');
                                }
                                console.log(checked);
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <form>
                    <div className="form__row">
                      <label htmlFor="model" className="form__label">
                        Modelo educativo <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label="Modelo educativo"
                        id="model"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {modelos.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.modelo_educativo}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          const modelo = modelos.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          // Add Class item on selected
                          let Item = evt.target;
                          if (evt.target.value !== 'Seleccionar...') {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlaneacion({
                            modeloEducativo: {
                              documentId: evt.target.value,
                              value: modelo.length
                                ? modelo[0].attributes.modelo_educativo
                                : '',
                            },
                            gradoEscolar: {
                              documentId:
                                planeacion.gradoEscolar?.documentId || '',
                              value: planeacion.gradoEscolar?.value || '',
                            },
                          });
                          setMaterias([]);
                          setEjes([]);
                          setTemas([]);
                          setBloques([]);
                          setTipotext([]);
                          setPslenguaje([]);
                          setLengart([]);
                          if (planeacion.gradoEscolar) {
                            setTimeout(
                              () =>
                                fetchMaterias(
                                  evt.target.value,
                                  planeacion.gradoEscolar?.documentId || ''
                                ),
                              500
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="grade" className="form__label">
                        Seleccionar el grado escolar{' '}
                        <small className="required">*</small>
                      </label>
                      <SelectComponent
                        label="Seleccionar el grado escolar"
                        id="grade"
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {grados.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.Grado}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          setBloques([]);
                          const grado = grados.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          // Add Class item on selected
                          let Item = evt.target;
                          if (evt.target.value !== 'Seleccionar...') {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlaneacion({
                            ...planeacion,
                            gradoEscolar: {
                              documentId: evt.target.value,
                              value: grado.length
                                ? grado[0].attributes.Grado
                                : '',
                            },
                          });
                          setMaterias([]);
                          setEjes([]);
                          setTemas([]);
                          setBloques([]);
                          setPslenguaje([]);
                          setTipotext([]);
                          setLengart([]);
                          if (
                            planeacion.modeloEducativo &&
                            planeacion.campoFormacion
                          ) {
                            setTimeout(
                              () =>
                                fetchMaterias(
                                  planeacion.modeloEducativo?.documentId || '',
                                  evt.target.value,
                                  planeacion.campoFormacion?.documentId
                                ),
                              500
                            );
                          } else if (planeacion.modeloEducativo) {
                            setTimeout(
                              () =>
                                fetchMaterias(
                                  planeacion.modeloEducativo?.documentId || '',
                                  evt.target.value
                                ),
                              500
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="grade" className="form__label">
                        Seleccionar el grupo escolar{' '}
                        <small className="required">*</small>
                      </label>
                      {/* Multicheckbox para opciones fijas*/}
                      <div className="multicheckboxes">
                        {grupos.map((e, k) => {
                          return (
                            <CheckboxComponent
                              key={k}
                              text={`${e.attributes.Grupo}`}
                              id={`${e.id}`}
                              checked={
                                planeacion.grupoEscolar?.value ==
                                e.attributes.Grupo
                                  ? true
                                  : false
                              }
                              onChangeEvent={() => {
                                const grupo = grupos.filter(
                                  (m) => `${m.id}` === `${e.id}`
                                );
                                setPlaneacion({
                                  ...planeacion,
                                  grupoEscolar: {
                                    documentId: `${e.id}`,
                                    value: grupo.length
                                      ? grupo[0].attributes.Grupo
                                      : '',
                                  },
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                    {planeacion.modeloEducativo &&
                    planeacion.modeloEducativo.documentId === '2' ? (
                      <div className="form__row">
                        <label htmlFor="area" className="form__label">
                          Selecciona un campo de formación académica y áreas de
                          desarrollo <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona un campo de formación académica y áreas de desarroll"
                          id="area"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {camposFormacion.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Nombre}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            const campoFormacion = camposFormacion.filter(
                              (m) => `${m.id}` === evt.target.value
                            );
                            // Add Class item on selected
                            let Item = evt.target;
                            if (evt.target.value !== 'Seleccionar...') {
                              Item.classList.add('form-select--selected');
                            } else {
                              setTimeout(() => {
                                Item.classList.remove('form-select--selected');
                              }, 500);
                            }
                            setPlaneacion({
                              ...planeacion,
                              campoFormacion: {
                                documentId: evt.target.value,
                                value: campoFormacion.length
                                  ? campoFormacion[0].attributes.Nombre
                                  : '',
                              },
                            });
                            setMaterias([]);
                            setEjes([]);
                            setTemas([]);
                            if (
                              planeacion.modeloEducativo &&
                              planeacion.gradoEscolar
                            ) {
                              setTimeout(
                                () =>
                                  fetchMaterias(
                                    planeacion.modeloEducativo?.documentId ||
                                      '',
                                    planeacion.gradoEscolar?.documentId || '',
                                    evt.target.value
                                  ),
                                500
                              );
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {materias.length ? (
                      <div className="form__row">
                        <label htmlFor="asginature" className="form__label">
                          Asignatura <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona una asignatura"
                          id="asginature"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {materias.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Materia}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            setEjes([]);
                            setTemas([]);
                            setTipotext([]);
                            setPslenguaje([]);
                            setLengart([]);
                            handleMateria(evt.target.value);
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {bloques.length &&
                    planeacion.modeloEducativo &&
                    planeacion.modeloEducativo.documentId === '1' ? (
                      <div className="form__row">
                        <label htmlFor="bloque" className="form__label">
                          Selecciona un bloque{' '}
                          <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona una asignatura"
                          id="bloque"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {bloques.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Bloque}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            const bloque = bloques.filter(
                              (m) => `${m.id}` === evt.target.value
                            );
                            // Add Class item on selected
                            let Item = evt.target;
                            if (evt.target.value !== 'Seleccionar...') {
                              Item.classList.add('form-select--selected');
                            } else {
                              setTimeout(() => {
                                Item.classList.remove('form-select--selected');
                              }, 500);
                            }
                            setPlaneacion({
                              ...planeacion,
                              bloque: {
                                documentId: evt.target.value,
                                value: bloque.length
                                  ? bloque[0].attributes.Bloque
                                  : '',
                              },
                            });
                            setEjes([]);
                            setTemas([]);
                            setTipotext([]);
                            setPslenguaje([]);
                            setLengart([]);
                            if (
                              planeacion.asignatura &&
                              planeacion.gradoEscolar
                            ) {
                              fetchEjesOrTemas(
                                planeacion.gradoEscolar.documentId,
                                planeacion.asignatura.documentId,
                                evt.target.value,
                                planeacion.eje?.documentId || '',
                                '/ejes'
                              );
                              fetchEjesOrTemas(
                                planeacion.gradoEscolar.documentId,
                                planeacion.asignatura.documentId,
                                evt.target.value,
                                planeacion.eje?.documentId || '',
                                '/temas'
                              );
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {ejes.length ? (
                      <div className="form__row">
                        <label htmlFor="eje" className="form__label">
                          {planeacion.asignatura?.documentId == '15' ||
                          planeacion.asignatura?.documentId == '13'
                            ? 'Selecciona un ámbito'
                            : 'Selecciona un eje'}
                          <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona un eje"
                          id="eje"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {ejes.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Nombre}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            const eje = ejes.filter(
                              (m) => `${m.id}` === evt.target.value
                            );
                            // Add Class item on selected
                            let Item = evt.target;
                            if (evt.target.value !== 'Seleccionar...') {
                              Item.classList.add('form-select--selected');
                            } else {
                              setTimeout(() => {
                                Item.classList.remove('form-select--selected');
                              }, 500);
                            }
                            setPlaneacion({
                              ...planeacion,
                              eje: {
                                documentId: evt.target.value,
                                value: eje.length
                                  ? eje[0].attributes.Nombre
                                  : '',
                              },
                            });
                            setTemas([]);
                            fetchEjesOrTemas(
                              planeacion.gradoEscolar?.documentId || '',
                              planeacion.asignatura?.documentId || '',
                              planeacion.bloque?.documentId || '',
                              evt.target.value,
                              '/temas'
                            );
                            if (
                              planeacion.bloque &&
                              planeacion.gradoEscolar &&
                              planeacion.asignatura?.documentId == '13'
                            ) {
                              fetchTipoTextPsl(
                                evt.target.value,
                                planeacion.bloque?.documentId,
                                planeacion.gradoEscolar?.documentId,
                                'psl'
                              );
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="form__row"></div>
                    )}
                    {temas.length ? (
                      <div className="form__row">
                        <label htmlFor="task" className="form__label">
                          Selecciona un tema{' '}
                          <small className="required">*</small>
                        </label>
                        <SearchSelectComponent
                          idItem="tema"
                          options={temas.map((m) => {
                            const {
                              attributes: { Nombre: label },
                              id: value,
                            } = m;
                            return {
                              value: `${value}`,
                              label,
                            };
                          })}
                          onChange={(newValue) => {
                            if (newValue) {
                              const tema = temas.filter(
                                (m) => `${m.id}` === newValue.value
                              );
                              // Add Class item on selected
                              const Item = document.getElementById('tema');
                              if (Item != null) {
                                if (newValue !== undefined) {
                                  Item.classList.add('form-select--selected');
                                } else {
                                  setTimeout(() => {
                                    Item.classList.remove(
                                      'form-select--selected'
                                    );
                                  }, 500);
                                }
                              }

                              setPlaneacion({
                                ...planeacion,
                                tema: {
                                  documentId: newValue.value,
                                  value: tema.length
                                    ? tema[0].attributes.Nombre
                                    : '',
                                },
                              });
                              if (
                                planeacion.bloque &&
                                planeacion.asignatura?.documentId == '12'
                              ) {
                                fetchLengArt(
                                  planeacion.bloque?.documentId,
                                  newValue.value
                                );
                              }
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="form__row"></div>
                    )}
                    {pslenguaje.length ? (
                      <div className="form__row">
                        <label htmlFor="psl" className="form__label">
                          Selecciona una practica social del lenguaje{' '}
                          <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona una practica social del lenguaje"
                          id="tipotext"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {pslenguaje.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Nombre}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            const psl = pslenguaje.filter(
                              (m) => `${m.id}` === evt.target.value
                            );
                            // Add Class item on selected
                            let Item = evt.target;
                            if (evt.target.value !== 'Seleccionar...') {
                              Item.classList.add('form-select--selected');
                            } else {
                              setTimeout(() => {
                                Item.classList.remove('form-select--selected');
                              }, 500);
                            }
                            setPlaneacion({
                              ...planeacion,
                              pslenguaje: {
                                documentId: evt.target.value,
                                value: psl.length
                                  ? psl[0].attributes.Nombre
                                  : '',
                              },
                            });
                            if (
                              planeacion.bloque &&
                              planeacion.gradoEscolar &&
                              planeacion.eje
                            ) {
                              fetchTipoTextPsl(
                                planeacion.eje?.documentId,
                                planeacion.bloque?.documentId,
                                planeacion.gradoEscolar?.documentId,
                                evt.target.value
                              );
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="form__row"></div>
                    )}
                    {tipotexto.length ? (
                      <div className="form__row">
                        <label htmlFor="tipotext" className="form__label">
                          Selecciona un tipo de texto{' '}
                          <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona un tipo de texto"
                          id="tipotext"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {tipotexto.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Nombre}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            const tipot = tipotexto.filter(
                              (m) => `${m.id}` === evt.target.value
                            );
                            // Add Class item on selected
                            let Item = evt.target;
                            if (evt.target.value !== 'Seleccionar...') {
                              Item.classList.add('form-select--selected');
                            } else {
                              setTimeout(() => {
                                Item.classList.remove('form-select--selected');
                              }, 500);
                            }
                            setPlaneacion({
                              ...planeacion,
                              tipotexto: {
                                documentId: evt.target.value,
                                value: tipot.length
                                  ? tipot[0].attributes.Nombre
                                  : '',
                              },
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div className="form__row"></div>
                    )}
                    {lengart.length ? (
                      <div className="form__row">
                        <label htmlFor="lengart" className="form__label">
                          Selecciona un lenguaje artístico{' '}
                          <small className="required">*</small>
                        </label>
                        <SelectComponent
                          label="Selecciona un lenguaje artístico"
                          id="lengart"
                          option={
                            <>
                              <option defaultValue="" hidden>
                                Seleccionar...
                              </option>
                              {lengart.map((e, k) => {
                                return (
                                  <option
                                    key={k}
                                    value={`${e.id}`}
                                  >{`${e.attributes.Nombre}`}</option>
                                );
                              })}
                            </>
                          }
                          onChange={(evt) => {
                            const lart = lengart.filter(
                              (m) => `${m.id}` === evt.target.value
                            );
                            // Add Class item on selected
                            let Item = evt.target;
                            if (evt.target.value !== 'Seleccionar...') {
                              Item.classList.add('form-select--selected');
                            } else {
                              setTimeout(() => {
                                Item.classList.remove('form-select--selected');
                              }, 500);
                            }
                            setPlaneacion({
                              ...planeacion,
                              lengart: {
                                documentId: evt.target.value,
                                value: lart.length
                                  ? lart[0].attributes.Nombre
                                  : '',
                              },
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div className="form__row"></div>
                    )}
                    <div className="form__row">
                      <CheckboxComponent
                        id="ciclo"
                        text={
                          <React.Fragment>
                            Ciclo escolar 2022 - 2023{' '}
                            <small className="color-gray">(opcional)</small>
                          </React.Fragment>
                        }
                        checked={planeacion.isNewCiclo || false}
                        onChangeEvent={(checked) =>
                          setPlaneacion({ ...planeacion, isNewCiclo: checked })
                        }
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="planName" className="form__label">
                        Nombre de la planeación{' '}
                        <small className="required">*</small>{' '}
                        <small>(Para que tú la identifiques)</small>
                      </label>
                      <InputComponent
                        type="text"
                        id="planName"
                        placeholder="Nombre de la planeación"
                        value={planeacion.name || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          // Add Class item on selected
                          let Item = event?.target as HTMLTextAreaElement;
                          if (
                            (
                              event?.target as HTMLTextAreaElement
                            ).value.trim() !== ''
                          ) {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }

                          setPlaneacion({
                            ...planeacion,
                            name:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                    <div className="form__row form__action mt32">
                      {validPlaneacion() ? (
                        <div className="col-12 col-reset">
                          <button
                            type="button"
                            className="btn btn--type3"
                            onClick={(evt) => {
                              evt.preventDefault();
                              const { userCCTs, _id: userId } = user;
                              const currentP = { ...planeacion };
                              if (userCCTs && userCCTs.length === 1) {
                                Object.assign(currentP, {
                                  userCCT: {
                                    documentId: userCCTs[0].documentId,
                                    value: userCCTs[0].cct,
                                  },
                                });
                              } else if (escuela && escuelaSelect) {
                                Object.assign(currentP, {
                                  userCCT: {
                                    documentId: escuelaSelect.documentId,
                                    value: escuelaSelect.cct,
                                  },
                                });
                              }
                              if (
                                'bloque' in currentP &&
                                currentP.modeloEducativo?.documentId === '2'
                              ) {
                                delete currentP.bloque;
                              }
                              createPlanning(
                                {
                                  ...currentP,
                                  userId,
                                  autor: `${user.name || ''} ${
                                    user.lastName || ''
                                  } ${user.middleName || ''}`,
                                  isNewCiclo: currentP.isNewCiclo || false,
                                },
                                user.token || ''
                              )
                                .then((resp) => {
                                  setPlaneacion({
                                    ...planeacion,
                                    _id: resp._id,
                                  });
                                  trackEvent({
                                    event: 'createPlanning',
                                    page: '/crear-planeacion',
                                    fullPath: window.location.href,
                                  });
                                  setModalShow(true);
                                })
                                .catch((error) => setError(error));
                            }}
                          >
                            Continuar
                          </button>
                        </div>
                      ) : (
                        <div className="col-12 col-reset"></div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default StepThreeScreen;
