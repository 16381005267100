import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getListWelcome, getTextButton } from '../../../strapi/artiChat';
import {
  ArtiChat,
  Chats,
  ResponseArtiChat,
} from '../../../types/EntidadesInterface';
import { artiChat, artiChatNoUser } from '../../../api/chatGptApi';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';

type Props = {
  show: boolean;
  onHide: () => void;
};

const ModalArtiComponent = ({ show, onHide }: Props) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useTracking();
  const [sendTracking, setSendTracking] = useState(false);
  const [userId, setUserId] = useState('');
  const [converId, setConverId] = useState('');
  const [token, setToken] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [thinking, setThinking] = useState(false);
  const [chatHistory, setChatHistroy] = useState<Chats[]>([]);
  const [chats, setChats] = useState<
    { role: string; content: string; type: string }[]
  >([]);
  const [message, setMessage] = useState('');
  const [rechazado, setRechazado] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const sendMessage = (message: string, type?: string) => {
    if (userId) {
      const arti = {
        message,
        userId,
        chatHistory,
        _id: converId,
      } as ArtiChat;
      artiChat(arti, `${token}`)
        .then((r: ResponseArtiChat) => {
          setConverId(r.conversationId);
          setChatHistroy([...r.chatHistory]);
          setChats([
            ...chats,
            {
              role: 'assistant',
              content: r.response,
              type: type ? type : '',
            },
          ]);
          setThinking(false);
          if (!sendTracking) {
            trackEvent({
              event: 'newArtiChat',
              page: window.location.pathname,
              fullPath: window.location.href,
              conversationId: r.conversationId,
            });
            setSendTracking(true);
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            'Lo sentimos...',
            'Por el momento no pude entenderte, intentalo más tarde.',
            'error'
          );
        });
    } else {
      const arti = {
        message,
        chatHistory,
        _id: converId,
      } as ArtiChat;
      artiChatNoUser(arti, '')
        .then((r: any) => {
          setConverId(r.conversationId);
          setChatHistroy([...r.chatHistory]);
          setChats([
            ...chats,
            {
              role: 'assistant',
              content: r.response,
              type: type ? type : '',
            },
          ]);
          if (r.rechazado) {
            setRechazado(true);
            localStorage.setItem('artiChatReject', 'true');
          }
          setThinking(false);
          if (!sendTracking) {
            trackEvent({
              event: 'newArtiChat',
              page: window.location.pathname,
              fullPath: window.location.href,
              conversationId: r.conversationId,
            });
            setSendTracking(true);
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            'Lo sentimos...',
            'Por el momento no pude entenderte, intentalo más tarde.',
            'error'
          );
        });
    }
  };

  const handleGameClick = (type: string, button: string) => {
    trackEvent({
      event: 'clickButtonArti',
      page: window.location.pathname,
      fullPath: window.location.href,
      button,
      conversationId: converId,
    });
    if (!rechazado) {
      getTextButton(button).then((prompt) => {
        setThinking(true);
        sendMessage(prompt.Text, type);
      });
    }
  };

  useEffect(() => {
    if (chats.length == 0) {
      Auth.currentAuthenticatedUser()
        .then((currentUser) => {
          localStorage.removeItem('artiChatReject');
          const {
            attributes,
            signInUserSession: {
              accessToken: { jwtToken },
            },
          } = currentUser;
          const kameUserId = attributes['custom:kameUserId'];
          setUserId(kameUserId);
          setToken(jwtToken);
        })
        .catch((err) => {
          const artRej = localStorage.getItem('artiChatReject');
          setRechazado(artRej === 'true' ? true : false);
          console.log(err);
        });

      getListWelcome().then((saludo) => {
        setChats([
          {
            role: 'assistant',
            content: saludo.Text,
            type: '',
          },
        ]);
        setChatHistroy([
          {
            role: 'assistant',
            content: saludo.Text,
          },
        ]);
      });
    }
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="modal-sug"
      centered
      className="modal--arti"
      backdrop="static"
    >
      <Modal.Header className="modal__title-icon">
        <button
          className="modal__close close--red"
          onClick={(e) => {
            e.preventDefault();
            onHide();
          }}
        >
          <span className="material-icons-outlined icon">close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="arti-chat-widget">
          <section className="arti-chat-wrapp">
            <div className="arti-chat__body">
              <section className="arti-chat__inner">
                <div className="arti-chat__header">
                  <figure>
                    <img
                      src="/assets/images/pacomax/arti-banner.png"
                      alt="Arti Chat"
                    />
                  </figure>
                </div>
                <section className="arti-chat__message" ref={chatRef}>
                  {chats.map((chat, k) => {
                    const { role, content, type } = chat;
                    return (
                      <>
                        {role === 'assistant' && (
                          <div className={`message-row ${type}`} key={k}>
                            <figure className="message-row__avatar">
                              <img
                                src="/assets/images/pacomax/arti-profile-pic.jpg"
                                alt="Avatar"
                              />
                            </figure>
                            <article className="message-row__message">
                              <p>{content}</p>
                            </article>
                          </div>
                        )}
                        {role === 'user' && (
                          <div className="message-row message--right">
                            <article className="message-row__message">
                              <p>{content}</p>
                            </article>
                            <figure className="message-row__avatar">
                              <img
                                src="/assets/images/pacomax/user_icon_chat.png"
                                alt="Avatar"
                              />
                            </figure>
                          </div>
                        )}
                      </>
                    );
                  })}
                  {thinking && (
                    <div className="message-row message--think">
                      <figure className="message-row__avatar">
                        <img
                          src="/assets/images/pacomax/arti-profile-pic.jpg"
                          alt="Avatar"
                        />
                      </figure>
                      <article className="message-row__message">
                        <img
                          src="/assets/images/elements/arthinking1.gif"
                          alt="thinking"
                        />
                      </article>
                    </div>
                  )}
                  <div className="message-row" id="ref" ref={ref}></div>
                </section>
              </section>
              <section>
                <form
                  className="arti-chat__bottom"
                  onSubmit={(evt) => {
                    evt.preventDefault();
                    if (!rechazado) {
                      chats.push({
                        role: 'user',
                        content: message,
                        type: '',
                      });
                      sendMessage(message);
                      setMessage('');
                      setThinking(true);
                    }
                  }}
                >
                  <input
                    type="text"
                    placeholder="Mensaje para ARTI"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <button type="submit">
                    <span className="material-icons-outlined">send</span>
                  </button>
                </form>
              </section>
            </div>
            <div className="arti-chat__power">
              <button
                className={`arti-chat__toggle ${isActive ? 'active' : ''}`}
                onClick={handleToggle}
              >
                <span>Súper poderes de ARTI</span>
                <span className="material-icons-outlined icon">
                  expand_less
                </span>
              </button>
              <section className={`power-assets ${isActive ? 'active' : ''}`}>
                <button
                  className="btn btn--typeGame game--1"
                  onClick={() =>
                    handleGameClick('message--type1', 'sorprendeme')
                  }
                >
                  ¡Sorpréndeme!
                </button>
                <button
                  className="btn btn--typeGame game--2"
                  onClick={() => handleGameClick('message--type2', 'tips')}
                >
                  Tips pedagógicos
                </button>
                <button
                  className="btn btn--typeGame game--3"
                  onClick={() =>
                    handleGameClick('message--type3', 'explicacion')
                  }
                >
                  Explicación a un tema
                </button>
                <button
                  className="btn btn--typeGame game--4"
                  onClick={() => handleGameClick('message--type4', 'tareas')}
                >
                  Ideas de tareas
                </button>
              </section>
              <div className="arti-chat__legal">
                <p>
                  ARTI aún está aprendiendo, siempre verifica sus respuestas.{' '}
                  <a href="/terminos-y-condiciones">Más info aquí</a>.
                </p>
              </div>
            </div>
          </section>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalArtiComponent;
