import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
const html2pdf = require('html2pdf.js');
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import InstrumentosPrintComponent from '../print/instrumentosPrint.component';
import InputComponent from '../../../components/Form/input/input.component';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  getInstrumentoArti,
  updateInstrumentoArti,
} from '../../../api/chatGptApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Instrumento } from '../../../types/EntidadesInterface';
import NavigationHints from '../../../components/NavigationHints/navigationHints';

pdfMake.vfs = pdfFonts.pdfMake.vfs; // Load pdfMake fonts (optional)

interface MSNavigator extends Navigator {
  msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
}

declare var navigator: MSNavigator;

const InstrumentosDashboardScreen = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { id: instrumentoId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const componentRef = useRef<HTMLDivElement>(null);
  const [showPrint, setShowPrint] = useState(false);
  const [instrumento, setInstrumento] = useState<Partial<Instrumento>>({});
  const [htmlText, setHtmlText] = useState('');
  const [type, setType] = useState('');

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/instrumento-detalle',
      instrumentoId,
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            getInstrumentoArti(instrumentoId, jwtToken).then((instrumento) => {
              instrumento.type == 1
                ? setHtmlText(
                    `<p> ${instrumento.respuestaGpt?.replace(
                      /\n/g,
                      '<br />'
                    )} </p> `
                  )
                : setHtmlText(
                    instrumento.respuestaGpt?.replace(
                      /```|html|en formato HTML|en formato de tabla HTML|HTML/gi,
                      ''
                    )
                  );
              setInstrumento(instrumento);
              setType(instrumento.type);
              setIsLoading(false);
            });
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  const handlePrint = () => {
    trackEvent({
      event: 'printInstrumento',
      page: '/instrumento-detalle',
      instrumentoId,
      fullPath: window.location.href,
    });
    window.print();
  };

  const handlePdf = async () => {
    trackEvent({
      event: 'downloadInstrumento',
      page: '/instrumento-detalle',
      instrumentoId,
      fullPath: window.location.href,
    });
    const printElement = componentRef.current;

    if (!printElement) {
      setIsLoading(false);
      return;
    }
    const fileName = instrumento.name || 'InstrumentoEvaluacion';
    const options = {
      margin: 5,
      filename: `${fileName.toLowerCase().replace(/\s/g, '-')}.pdf`,
      image: { type: 'jpeg', quality: 1 }, // Increase the image quality
      html2canvas: { scale: 2, dpi: 300 }, // Increase the scale and dpi for better resolution
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    // Wait for html2pdf to load fonts and render the PDF
    await html2pdf().set(options).from(printElement).save();

    setIsLoading(false);
    setShowPrint(false);
  };

  // Word generator
  function Export2Doc(elementId: any, filename = '') {
    const preHtml = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
      <meta charset='utf-8'>
      <title>Export HTML To Doc</title>
      <style>
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      body {
        background: url(https://www.pacoteacher.com/assets/images/elements/paco-a4.jpg) center center no-repeat;
        background-size: cover;
      }
      h1 {
        color: #4b0082;
      }
        h5 {
          color: #8439FF;
          font-size: 18px;
        }
        h6 {
          font-size: 12px;
        }
        p {
          font-size: 14px;
        }
        img,
        .print-plan__logo {
          width: 60px !important;
        }
        .print-section__item {
          border-bottom: 1px solid #DEE6F2;
        }
      </style>
    </head>
    <body>
    `;
        const postHtml = "</body></html>";
        const element = document.getElementById(elementId);
        // console.log('ELEMENT::', element);
        if (!element) {
            console.error("Element not found!");
            return;
        }
        const html = preHtml + element.innerHTML + postHtml;
        // console.log('HTML::', html);
        const blob = new Blob(['ufeff', html], {
            type: 'application/msword'
        });

        const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        filename = filename ? filename + '.doc' : 'document.doc';

        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            downloadLink.href = url;
            downloadLink.download = filename;
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    }
    const downloadWordDocument = () => {
        Export2Doc('print-placeholder', `instrumentosEvaluacion`);
    };

  const updateInstrumento = async (instru: Partial<Instrumento>) => {
    try {
      const { _id: instrumentoId } = instru;
      if (instrumentoId) {
        const update = await updateInstrumentoArti(
          instrumentoId,
          { ...instru },
          `${user.token}`
        );
        return update;
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const saveInstrumento = () => {
    trackEvent({
      event: 'saveInstrumento',
      page: '/instrumento-detalle',
      instrumentoId,
      fullPath: window.location.href,
    });
    updateInstrumento({ ...instrumento })
      .then(() => {
        return Swal.fire({
          icon: 'success',
          text: `El instrumento de evaluación fue guardado correctamente.`,
          showCancelButton: true,
          confirmButtonText: 'Ir a Mis instrumentos de evaluación',
          cancelButtonText: 'Continuar editando',
        }).then((response) => {
          if (response.isConfirmed) {
            navigate('/mis-instrumentos');
          }
        });
      })
      .catch((error) => setError(error));
  };

  const deleteInstrumento = () => {
    Swal.fire({
      icon: 'warning',
      text: `¿Estas seguro de eliminar este instrumento de evaluación?.`,
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No, cancelar',
    }).then((response) => {
      if (response.isConfirmed) {
        trackEvent({
          event: 'deleteInstrumento',
          page: '/instrumento-detalle',
          instrumentoId,
          fullPath: window.location.href,
        });
        navigate('/mis-instrumentos');
        updateInstrumento({ ...instrumento, isDelete: true })
          .then(() => {
            Swal.fire('', `Instrumento eliminado`, 'success');
            navigate('/mis-instrumentos');
          })
          .catch((error) => setError(error));
      }
    });
  };

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={'/mis-instrumentos'}
          customNavigate={() => {
            navigate(-1);
          }}
          back="Regresar"
          urlSecond={`/instrumento-detalle/${instrumentoId}`}
          urlSecondText={'Instrumentos de evaluación'}
        />

        <section className="custom-content">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12 mx-auto">
                <div className="custom-content__head">
                  <article>
                    <h3 className="text-center text-color-purple">
                      {type == '1'
                        ? 'Nueva evaluación generada'
                        : 'Nueva rúbrica generada'}
                    </h3>
                    <p>
                      Aquí tienes la sugerencia generada con la inteligencia
                      artificial ARTI. Puedes editarla y modificarla de acuerdo
                      a tus necesidades.{' '}
                    </p>
                  </article>
                  <div className="planning-options col-12">
                    <button
                      onClick={handlePrint}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">print</span>
                      Imprimir
                    </button>
                    <button
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsLoading(true);
                        setShowPrint(true);
                        setTimeout(() => {
                          handlePdf();
                        }, 200);
                      }}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                      Descargar
                    </button>
                    <button
                        type='button'
                        onClick={downloadWordDocument}
                        className="planning-options__button"
                    >
                        <span className="material-icons-outlined">text_snippet</span>
                        Documento Word
                    </button>
                  </div>
                </div>
                <div className="custom-content__body">
                  <div className="form__row strategie--title">
                    <label htmlFor="instrumento" className="form__label">
                      <h4>
                        {type == '1'
                          ? 'Ponle un nombre a tu instrumento de evaluación para que lo identifiques'
                          : 'Ponle un nombre a tu rúbrica para que la identifiques'}
                      </h4>
                    </label>
                    <div className="form__validation-input">
                      <InputComponent
                        type="text"
                        id="instrumento"
                        placeholder="Escribe un nombre para tu instrumento de evaluación"
                        value={instrumento.name || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setInstrumento({
                            ...instrumento,
                            name:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__row strategie--title">
                    <label htmlFor="editor" className="form__label">
                      <h4>
                        {type == '1'
                          ? 'Edita tu evaluación libremente '
                          : 'Edita tu rúbrica libremente '}
                      </h4>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: {
                          removeItems: ['uploadImage'],
                        },
                      }}
                      data={
                        instrumento.respuestaGptEdit
                          ? instrumento.respuestaGptEdit
                          : htmlText
                      }
                      onReady={(editor) => {
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        instrumento._id
                          ? setInstrumento({
                              ...instrumento,
                              respuestaGptEdit: data,
                            })
                          : '';
                      }}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setInstrumento({
                          ...instrumento,
                          respuestaGptEdit: data,
                        });
                      }}
                    />
                    <div className="strategie--footer">
                      <p>
                        ARTI aún está aprendiendo, siempre verifica sus
                        respuestas.
                        <Link to="/terminos-y-condiciones">
                          {' '}
                          Conoce más aquí.
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="form__row form__row--bottom">
                    {/* <figure className="strategy-image">
                      <img src="/assets/images/elements/arti-happy.png" alt="Arti" />
                    </figure> */}
                    <div className="form__row-actions">
                      <button
                        className="btn btn--type1"
                        onClick={saveInstrumento}
                      >
                        Guardar en Mis instrumentos de evaluación{' '}
                      </button>
                    </div>
                  <div className="planning-options col-12">
                    <button
                      onClick={handlePrint}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">print</span>
                      Imprimir
                    </button>
                    <button
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsLoading(true);
                        setShowPrint(true);
                        setTimeout(() => {
                          handlePdf();
                        }, 200);
                      }}
                      className="planning-options__button"
                    >
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                      Descargar
                    </button>
                  </div>
                    <div className="modal-info__bottom modal-info--border mt-4 col-12">
                      <button className="btn" onClick={deleteInstrumento}>
                        Eliminar instrumento de evaluación
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <NavigationHints
          button1={{
            link: '/planeaciones/nueva-escuela-mexicana',
            texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
            color: 'btn--color2',
            iconText: 'icon--grid2',
            icon: true,
            arti: false,
            max: false,
          }}
          button2={{
            link: '/crea-tu-planeacion-nem',
            texto: 'Planeaciones NEM con Inteligencia Artificial',
            iconText: 'icon--grid3',
            color: 'btn--color2',
            icon: true,
            arti: true,
            max: false,
          }}
          button3={{
            link: '/estrategias-didacticas',
            texto: 'Enseña Matemáticas con PACO Max',
            color: 'btn--color3',
            iconText: 'icon--grid7',
            icon: true,
            arti: false,
            max: true,
          }}
          user={user}
        />
      </div>
      <FooterComponent />
      <div id="print-placeholder" ref={componentRef}>
        <InstrumentosPrintComponent
          show={showPrint}
          instrumento={instrumento}
          whtml={htmlText}
        />
      </div>
    </section>
  );
};

export default InstrumentosDashboardScreen;
