import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { Link, useNavigate } from 'react-router-dom';
import { getMisInstrumentosArti } from '../../../api/chatGptApi';
import {
  Instrumento,
  ResultInstrumento,
} from '../../../types/EntidadesInterface';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';

const InstrumentosIndexScreen = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [instrumentos, setInstrumentos] = useState<Partial<ResultInstrumento>>(
    {}
  );

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/mis-instrumentos',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            getMisInstrumentosArti(kameUser._id, jwtToken).then((inst) => {
              setInstrumentos(inst);
              setIsLoading(false);
            });
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>

      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="header-activity-banner header--confetti">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">
                  Instrumentos de <br /> evaluación
                </h1>
              </div>
              {/* <figure className="strategy-image strategy--left">
                <img src="/assets/images/elements/arti-happy.png" alt="Arti" />
              </figure> */}
            </div>
          </div>
        </section>
        <section className="container body-activity body--strategies">
          <div className="area-title area-title--decore area--button-action row">
            <div className="col-12">
              <div className="area-title__button">
                <Link to={'/instrumentos-de-evaluacion'}>
                  <button className="btn btn--type1">
                    Crear un nuevo instrumento
                  </button>
                </Link>
              </div>

              <div className="search__engine search__inner-page col-md-10 col-12 col-reset mx-auto">
                <h3 className="text-center mb-4">
                  Mis instrumentos de evaluación
                </h3>
                <div className="instrumentos">
                  <SearchSelectComponent
                    idItem="instrumento"
                    options={(instrumentos.docs || []).map((i) => {
                      const { name, _id: id } = i;
                      return {
                        value: id,
                        label: name,
                      };
                    })}
                    onChange={(ins) => {
                      navigate(`/instrumento-detalle/${ins?.value}`);
                    }}
                  />
                </div>
                <p className="search__engine__results">
                  Resultados encontrados: {instrumentos.docs?.length}
                </p>
              </div>
            </div>
          </div>
          <div className="strategies-grid row">
            <div className="col-md-10 col-12 mx-auto">
              <div className="row">
                {(instrumentos.docs || []).map((instrumento, key) => {
                  const {
                    datosGenerales,
                    name,
                    _id: id,
                    namePlanning,
                    type,
                  } = instrumento as Instrumento;
                  const { asignatura, gradoEscolar, campoF } = datosGenerales;
                  return (
                    <div
                      className="strategies-grid__col col-md-3 col-12"
                      key={key}
                    >
                      <div className="strategy-card">
                        <div className="strategy-card__header">
                          <p>
                            {type == 1 ? 'Evaluación' : 'Rúbrica'} {name}
                          </p>
                        </div>
                        <div className="strategy-card__body">
                          <figure className="strategy__icon">
                            <span className="material-icons-outlined">
                              auto_awesome_mosaic
                            </span>
                          </figure>
                          {asignatura ? (
                            <div className="strategy-card__row">
                              <p>Asignatura</p>
                              <p>
                                <strong>{asignatura.value}</strong>
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {campoF ? (
                            <div className="strategy-card__row">
                              <p>Campo formativo</p>
                              <p>
                                <strong>{campoF.value}</strong>
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="strategy-card__row">
                            <p>Grado</p>
                            <p>
                              <strong>
                                {gradoEscolar.documentId > '6'
                                  ? `${gradoEscolar.value} - Secundaria`
                                  : `${gradoEscolar.value}`}
                              </strong>
                            </p>
                          </div>
                          {instrumento.planningId ? (
                            <div className="strategy-card__row">
                              <p>Planeación asignada</p>
                              <p>
                                <strong>
                                  {namePlanning ? namePlanning[0].name : ''}
                                </strong>
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="strategy-card__footer">
                          <button>
                            <Link to={`/instrumento-detalle/${id}`}>
                              Ver detalle
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default InstrumentosIndexScreen;
