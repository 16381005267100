import api from './index';
import qs from 'qs';
import { BugReportTypeResult } from '../types/EntidadesInterface';

export const getBugReportTypes = async () => {
  const query = qs.stringify({
    sort: ['position:asc'],
  });
  const response = await api.get<BugReportTypeResult>(
    `/bug-report-types?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
