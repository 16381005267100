import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { useParams, useNavigate } from 'react-router-dom';
import qs from 'qs';
import Swal from 'sweetalert2';
import { format, fromUnixTime } from 'date-fns';
import { useTracking } from 'react-tracking';
import { getStrapiCatalog } from '../../strapi/escuelas';
import { getUser } from '../../api/usersApi';
import {
  createFlexiblePlanning,
  getFlexiblePlanning,
} from '../../api/flexiblePlanningsApi';
import {
  IFlexiblePlanning,
  StrapiDoc,
} from '../../types/FlexiblePlanningInterface';
import { IUser } from '../../types/UserInterface';
import {
  GenericResult,
  RecursoEducativo,
} from '../../types/EntidadesInterface';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import PrintPlanComponent from '../../components/planeacionesComponents/printPlan/printPlan.component';
const html2pdf = require('html2pdf.js');

const PlaneaionesFlexiblesPreview = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useTracking();
  const { planningId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IFlexiblePlanning>>({});
  const [resources, setRecursos] = useState<
    { id: number; attributes: RecursoEducativo }[]
  >([]);
  const [tiposRecurso, setTiposRecurso] = useState<GenericResult>({ data: [] });
  console.log({ tiposRecurso });
  const [notasHasDisabledField, setNotasDisabled] = useState<any>([]);
  const [showPrint, setShowPrint] = useState(false);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const isSameUser = () => {
    const { userId } = planning;
    const { _id } = user;

    if (userId) {
      const { _id: planningUserId } = userId as any;
      return planningUserId === _id;
    }

    return false;
  };

  const getResources = async (allResources: number[]) => {
    try {
      if (allResources.length) {
        // @ts-ignore: Unreachable code error
        const ids = [...new Set(allResources)];
        const query = {
          sort: ['id:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            id: { $in: ids },
          },
        };
        const response = await getStrapiCatalog(
          qs.stringify(query),
          '/recursos-educativos'
        );
        const tiposRecurso = await getStrapiCatalog(
          qs.stringify({ sort: ['Nombre:asc'], populate: '*' }),
          '/tipo-de-recursos'
        );
        setRecursos(
          response.data as {
            id: number;
            attributes: RecursoEducativo;
          }[]
        );
        setTiposRecurso(tiposRecurso);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getCurrentPlanning = async (id: string, token: string, userId: string) => {
    try {
      const foundedPlanning = await getFlexiblePlanning(id, token);
      if (foundedPlanning && Object.keys(foundedPlanning).length) {
        const { _id: planningUserId } = foundedPlanning.userId as any;
        trackEvent({
          event: 'pageView',
          page: '/planeacion-flexible-vista-previa',
          planningId: foundedPlanning._id,
          gradoId: foundedPlanning.gradoEscolar?.value,
          reforma: foundedPlanning.modeloEducativo?.value,
          isSameUser: planningUserId === userId
        });
        setPlanning(foundedPlanning);
        setNotasDisabled(foundedPlanning.disabledFields?.planning?.fields);
        if (foundedPlanning.asignaturas?.length) {
          const allResources: StrapiDoc[] = [];
          foundedPlanning.asignaturas.forEach((asignatura) => {
            const sessions = asignatura.sessions;
            sessions?.forEach((session) => {
              const { inicio, desarrollo, cierre } = session;
              [inicio, desarrollo, cierre].forEach((section) => {
                if (section) {
                  const { resources } = section;
                  if (resources && resources.length) {
                    allResources.push(...resources);
                  }
                }
              });
            });
          });
          if (allResources.length) {
            getResources(allResources.map((r) => Number(r.documentId)));
          }
        }
      } else {
        navigate('/mis-planeaciones');
      }
    } catch (error) {
      console.error(error);
      setError('Hubo un error intenta de nuevo');
    }
    setIsLoading(false);
  };

  const getResourceContent = (resource: StrapiDoc, idx: number) => {
    const strapiR = resources.filter((r) => `${r.id}` === resource.documentId);
    if (strapiR && strapiR.length) {
      const newResource = strapiR[0];
      const {
        attributes: {
          Nombre,
          recurso,
          descripcion,
          fuente,
          tipo_de_recursos: tipoDeRecurso,
        },
      } = newResource;
      return (
        <li key={idx}>
          <h6>{`${Nombre}`}</h6>
          <p>
            <ReactMarkdown linkTarget={'_blank'}>{`${
              descripcion || ''
            }`}</ReactMarkdown>
          </p>
          {fuente.data.attributes.Fuente != 'Paco Max' ? (
            <p className="print-section__url">
              <ReactMarkdown linkTarget={'_blank'}>{`${
                recurso || ''
              }`}</ReactMarkdown>
            </p>
          ) : (
            <></>
          )}
          <p>
            <strong>Ejercicios&nbsp;</strong>
            {`${tipoDeRecurso.data
              .map((tR) => tR.attributes.Nombre)
              .join(', ')}`}
          </p>
          <p>
            <strong>Fuente</strong> {`${fuente.data.attributes.Fuente}`}
          </p>
        </li>
      );
    }
    return <></>;
  };

  const getTotalMinutes = (value: number) => {
    if (value) {
      const date = fromUnixTime(value);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return hours * 60 + minutes;
    }
    return 0;
  };

  const duplicatePlanning = async () => {
    try {
      if (planning) {
        const copyPlanning = { ...planning };
        delete copyPlanning._id;
        const newPlanning = await createFlexiblePlanning(
          {
            ...copyPlanning,
            copiedFrom: planning._id,
            autor: `${user.alias || ''}`,
            isPublic: false,
            name: `Copia de ${copyPlanning.name || ''}`,
            userCCT: { documentId: '', value: '' },
            userId: user._id,
          },
          `${user.token}`
        );

        if (newPlanning && newPlanning._id) {
          trackEvent({
            event: 'copyPlanning',
            page: '/planeacion-flexible-vista-previa',
            planning: planning._id,
            newPlanning: newPlanning._id,
          });
          Swal.fire(
            '¡Listo!',
            `Se ha copiado la planeación. 
            Ahora podrás encontrarla en la sección "Mis planeaciones guardadas" y editarla. 
            Esta planeación se vinculará a los datos de tu cuenta`,
            'success'
          ).then(() => {
            navigate(`/planeacion-flexible/${newPlanning._id}`, {
              replace: true,
              state: { planningId: newPlanning._id },
            });
          });
        }
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const handlePdf = async () => {
    const printElement = componentRef.current;

    if (!printElement) {
      setIsLoading(false);
      return;
    }
    const fileName = planning.name || 'Planeacion';
    const options = {
      margin: 5,
      filename: `${fileName.toLowerCase().replace(/\s/g, '-')}.pdf`,
      image: { type: 'jpeg', quality: 1 }, // Increase the image quality
      html2canvas: { scale: 2, dpi: 300 }, // Increase the scale and dpi for better resolution
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    // Wait for html2pdf to load fonts and render the PDF
    await html2pdf().set(options).from(printElement).save();

    setIsLoading(false);
    setShowPrint(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            setUser({ ...kameUser, token: jwtToken });
            if (planningId) {
              getCurrentPlanning(planningId, jwtToken, kameUser._id);
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <section className='container'>
      <div className="planning-reformas col-lg-8 col-md-10 col-sm-12 col-12">
        <div className="print-plan__title">
          <p>Mi planeación</p>
          <h1>{`${planning.name || ''}`}</h1>
        </div>
        <section className="print-plan__section">
          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col col-12">
                <h5>Datos generales de tu planeación</h5>
              </div>
            </div>
            <div className="print-section__row row">
              {(!!planning.userCCT?.documentId || !!planning.otherCCT) && (
                <div className="print-section__row__col col-12">
                  <h6>Institución Educativa</h6>
                  <p>
                    {`${
                      planning.userCCT?.documentId
                        ? planning.userCCT.value
                        : planning.otherCCT || ''
                    }`}
                  </p>
                </div>
              )}
            </div>
            <div className="print-section__row row">
              {!!planning.cicloEscolar?.documentId && (
                <div className="print-section__row__col col-md-4 col-6">
                  <h6>Ciclo escolar</h6>
                  <p>{`${planning.cicloEscolar.value}`}</p>
                </div>
              )}
              {!!planning.modeloEducativo?.documentId && (
                <div className="print-section__row__col col-md-4 col-6">
                  <h6>Modelo educativo</h6>
                  <p>{`${planning.modeloEducativo.value}`}</p>
                </div>
              )}
              {!!planning.gradoEscolar?.documentId && (
                <div className="print-section__row__col col-md-4 col-6">
                  <h6>Grado escolar</h6>
                  <p>{`${planning.gradoEscolar.value}`}</p>
                </div>
              )}
              {!!planning.grupoEscolar?.documentId && (
                <div className="print-section__row__col col-md-4 col-6">
                  <h6>Grupo escolar</h6>
                  <p>{`${planning.grupoEscolar.value}`}</p>
                </div>
              )}
            </div>
          </div>
          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col col-12">
                <h5>Asignaturas y detalles para tu planeación</h5>
              </div>
            </div>
            {(planning.asignaturas || []).map((asignatura, idx) => {
              const {
                campoFormacion,
                period,
                bloque,
                dateRanges,
                eje,
                tema,
                pSocial,
                tTexto,
                lArtistico,
                aprendizajes,
                competencias,
                aprendizajesFundamentales,
                description,
                apClave,
                propPed,
                contexto,
                sessions,
              } = asignatura;
              return (
                <div className="m-0 print-section__row row" key={idx}>
                  <section className="print-subsection">
                    <div className="print-section__row row-col--subtitle row">
                      <div className="print-section__row__col col-12">
                        <h5>{`${asignatura.asignatura?.value || ''}`}</h5>
                      </div>
                    </div>
                    <div className="print-section__row row">
                      {!!campoFormacion?.documentId && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Campo de formación</h6>
                          <p>{`${campoFormacion.value}`}</p>
                        </div>
                      )}
                      <div className="print-section__row__col col-md-4 col-12">
                        <h6>Asignatura</h6>
                        <p>{`${asignatura.asignatura?.value || ''}`}</p>
                      </div>
                      {!!bloque?.documentId && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Bloque</h6>
                          <p>{`${bloque.value}`}</p>
                        </div>
                      )}
                      {!!period && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Período de tiempo</h6>
                          <p>{`${period}`}</p>
                        </div>
                      )}
                      {!!dateRanges?.end && !!dateRanges.start && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Rango de fechas</h6>
                          <p>{`${
                            format(
                              fromUnixTime(dateRanges.start),
                              'dd . MMMM . yy'
                            ) || ''
                          } - ${
                            format(
                              fromUnixTime(dateRanges.end),
                              'dd . MMMM . yy'
                            ) || ''
                          }`}</p>
                        </div>
                      )}
                      {!!planning.modeloEducativo?.documentId && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Modelo educativo</h6>
                          <p>{`${planning.modeloEducativo.value}`}</p>
                        </div>
                      )}
                      {!!eje?.documentId && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Eje</h6>
                          <p>{`${eje.value}`}</p>
                        </div>
                      )}
                      {!!tema?.value && (
                        <div className="print-section__row__col col-md-4 col-12">
                          <h6>Tema</h6>
                          <p>{`${tema.value}`}</p>
                        </div>
                      )}
                      {!!pSocial?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Práctica social del lenguaje</h6>
                          <p>{`${pSocial.value}`}</p>
                        </div>
                      )}
                      {!!tTexto?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Tipo de texto</h6>
                          <p>{`${tTexto.value}`}</p>
                        </div>
                      )}
                      {!!lArtistico?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Lenguaje artístico</h6>
                          <p>{`${lArtistico.value}`}</p>
                        </div>
                      )}
                      {!!aprendizajes && (
                        <div className="print-section__row__col col-12">
                          <h6>Aprendizajes esperados</h6>
                          {aprendizajes.map((ap, idx) => (
                            <p key={idx}>{`${ap.value}`}</p>
                          ))}
                        </div>
                      )}
                      {!!(competencias && competencias.length) && (
                        <div className="print-section__row__col col-12">
                          <h6>Competencias</h6>
                          {competencias.map((comp, idx) => (
                            <p key={idx}>{`${comp.value}`}</p>
                          ))}
                        </div>
                      )}
                      {!!aprendizajesFundamentales?.length && (
                        <div className="print-section__row__col col-12">
                          <h6>Aprendizajes fundamentales</h6>
                          {aprendizajesFundamentales.map((apF, idx) => (
                            <p key={idx}>{`${apF.value}`}</p>
                          ))}
                        </div>
                      )}
                      {!!description && (
                        <div className="print-section__row__col col-12">
                          <h6>Descripción general</h6>
                          <p>{`${description}`}</p>
                        </div>
                      )}
                    </div>
                    {(!!apClave || !!propPed || !!contexto) && (
                      <div className="print-section__row section__row-subsection row">
                        <div className="print-section__row__col row-col--thirdtitle col-12">
                          <h6>Campos adicionales</h6>
                        </div>
                        {!!apClave && (
                          <div className="print-section__row__col col-12">
                            <h6>Aprendizajes clave</h6>
                            <p>{`${apClave}`}</p>
                          </div>
                        )}
                        {!!propPed && (
                          <div className="print-section__row__col col-12">
                            <h6>Propósitos pedagógicos</h6>
                            <p>{`${propPed}`}</p>
                          </div>
                        )}
                        {!!contexto && (
                          <div className="print-section__row__col col-12">
                            <h6>Contexto en el aula</h6>
                            <p>{`${contexto}`}</p>
                          </div>
                        )}
                      </div>
                    )}
                    {!!sessions?.length && (
                      <>
                        {sessions.map((session, key) => {
                          const {
                            name,
                            startDate,
                            minutes,
                            description,
                            materialFisico,
                            pausaActiva,
                            inicio,
                            desarrollo,
                            cierre,
                            evaluacion,
                          } = session;
                          const inicioHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('inicio')
                              ).length;
                          const desarrolloHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('desarrollo')
                              ).length;
                          const cierreHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('cierre')
                              ).length;
                          const evaluacionHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('evaluacion')
                              ).length;
                          return (
                            <div
                              className="print-section__row section__row-subsection row"
                              key={key}
                            >
                              <div className="row-col--thirdtitle col-12">
                                <h6>Secuencias didácticas</h6>
                              </div>
                              <div className="print-section__row__col col-12">
                                <h6>{`${name || ''}`}</h6>
                                <section className="prin-section__session">
                                  <div className="print-section__row row">
                                    {!!startDate && (
                                      <div className="print-section__row__col col-md-4 col-12">
                                        <h6>Fecha de la sesión</h6>
                                        <p>{`${
                                          format(
                                            fromUnixTime(startDate),
                                            'dd . MMM . yyyy'
                                          ) || ''
                                        }`}</p>
                                      </div>
                                    )}
                                    {!!minutes && (
                                      <div className="print-section__row__col col-md-4 col-12">
                                        <h6>
                                          Tiempo total de la secuencia didáctica
                                        </h6>
                                        <p>{`${getTotalMinutes(
                                          minutes || 0
                                        )} min.`}</p>
                                      </div>
                                    )}
                                    {!!description && (
                                      <div className="print-section__row__col col-12">
                                        <h6>Descripción de la sesión</h6>
                                        <p>{`${description}`}</p>
                                      </div>
                                    )}
                                    {!!materialFisico && (
                                      <div className="print-section__row__col col-12">
                                        <h6>Material físico</h6>
                                        <p>{`${materialFisico}`}</p>
                                      </div>
                                    )}
                                    {!!pausaActiva && (
                                      <div className="print-section__row__col col-12">
                                        <h6>Pausa activa</h6>
                                        <p>{`${pausaActiva}`}</p>
                                      </div>
                                    )}
                                  </div>
                                  {(!inicioHasDisabledField || inicioHasDisabledField < 4) && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>A. Inicio</h5>
                                      </div>
                                      {!!inicio?.minutes && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Tiempo estimado</h6>
                                          <p>{`${getTotalMinutes(
                                            inicio.minutes || 0
                                          )} min.`}</p>
                                        </div>
                                      )}
                                      {!!inicio?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividad a desarrollar</h6>
                                          <p>{`${inicio.description}`}</p>
                                        </div>
                                      )}
                                      {!!inicio?.resources?.length && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Recursos educativos</h6>
                                          <ul>
                                            {inicio.resources.map(
                                              (resource, idx) => {
                                                return getResourceContent(
                                                  resource,
                                                  idx
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {(!desarrolloHasDisabledField || desarrolloHasDisabledField < 4) && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>B. Desarrollo</h5>
                                      </div>
                                      {!!desarrollo?.minutes && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Tiempo estimado</h6>
                                          <p>{`${getTotalMinutes(
                                            desarrollo.minutes || 0
                                          )} min.`}</p>
                                        </div>
                                      )}
                                      {!!desarrollo?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividad a desarrollar</h6>
                                          <p>{`${desarrollo.description}`}</p>
                                        </div>
                                      )}
                                      {!!desarrollo?.resources?.length && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Recursos educativos</h6>
                                          <ul>
                                            {desarrollo.resources.map(
                                              (resource, idx) => {
                                                return getResourceContent(
                                                  resource,
                                                  idx
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {(!cierreHasDisabledField || cierreHasDisabledField < 5) && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>C. Cierre</h5>
                                      </div>
                                      {!!cierre?.minutes && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Tiempo estimado</h6>
                                          <p>{`${getTotalMinutes(
                                            cierre.minutes || 0
                                          )} min.`}</p>
                                        </div>
                                      )}
                                      {!!cierre?.preguntasReflexion && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Preguntas de reflexión</h6>
                                          {cierre.preguntasReflexion.map(
                                            (pR, idx) => (
                                              <p key={idx}>{`${pR.value}`}</p>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {!!cierre?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividad a desarrollar</h6>
                                          <p>{`${cierre.description}`}</p>
                                        </div>
                                      )}
                                      {!!cierre?.resources?.length && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Recursos educativos</h6>
                                          <ul>
                                            {cierre.resources.map(
                                              (resource, idx) => {
                                                return getResourceContent(
                                                  resource,
                                                  idx
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {(!evaluacionHasDisabledField || evaluacionHasDisabledField < 7) && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>D. Evaluación</h5>
                                      </div>
                                      {!!evaluacion?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividades a desarrollar</h6>
                                          <p>{`${evaluacion.description}`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.instrumentoEval && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Instrumentos de evaluación</h6>
                                          {evaluacion.instrumentoEval.map(
                                            (pR, idx) => (
                                              <p key={idx}>{`${pR.value}`}</p>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {!!evaluacion?.criterios && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Criterios de evaluación</h6>
                                          <p>{`${evaluacion.criterios}`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.evidencias && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Evidencias</h6>
                                          <p>{`${
                                            evaluacion.evidencias || ''
                                          }`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.comentarios && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Comentarios adicionales</h6>
                                          <p>{`${evaluacion.comentarios}`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.adecuaciones && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Adecuaciones curriculares</h6>
                                          <p>{`${evaluacion.adecuaciones}`}</p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </section>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </section>
                </div>
              );
            })}
          </div>
          {notasHasDisabledField.length < 4 && (
            <div className="print-section__item">
              <div className="print-section__row row-col--title row">
                <div className="print-section__row__col col-12">
                  <h5>Notas adicionales</h5>
                </div>
              </div>
              <div className="print-section__row row">
                {!notasHasDisabledField.includes('comentarios') && (
                  <div className="print-section__row__col col-12">
                    <h6>Comentarios de la dirección</h6>
                    <p>{`${planning.comentarios || ' '}`}</p>
                  </div>
                )}
                {!notasHasDisabledField.includes('firma') && (
                  <div className="print-section__row__col col-md-6 col-12">
                    <h6>Firma o visto bueno</h6>
                    <p>{`${planning.firma || ''}`}</p>
                    <div className="print-sign"></div>
                  </div>
                )}
                {!notasHasDisabledField.includes('sello') && (
                  <div className="print-section__row__col col-md-6 col-12">
                    <h6>Sello</h6>
                    <p>{`${planning.sello || ''}`}</p>
                    <div className="print-sign"></div>
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      </div>
      </section>

      {!isSameUser() && (
        <div className="new-planning__actions">
          <div className="planning-options col-12">
            <button
              onClick={(evt) => {
                evt.preventDefault();
                setIsLoading(true);
                setShowPrint(true);
                trackEvent({
                  event: 'downloadPlanningCom',
                  page: '/planeacion-flexible',
                  planningId: planning._id,
                  fullPath: window.location.href,
                });
                setTimeout(() => {
                  handlePdf();
                }, 200);
              }}
              className="planning-options__button"
            >
              <span className="material-icons-outlined">file_download</span>
              Descargar
            </button>
          </div>
        </div>
      )}
      <div className="new-planning__bottom">
        {isSameUser() && (
          <button
            className="btn btn--type1"
            onClick={(evt) => {
              evt.preventDefault();
              trackEvent({
                event: 'savePlanning',
                page: '/planeacion-flexible-vista-previa',
                planningId: planning._id
              });
              return Swal.fire({
                icon: 'success',
                title: '¡Listo!',
                text: `Tu planeación se ha guardado con éxito`,
                showCancelButton: true,
                confirmButtonText: 'Ver mis planeaciones',
                cancelButtonText: 'Continuar editando',
                showLoaderOnConfirm: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/mis-planeaciones');
                } else {
                  navigate(`/planeacion-flexible/${planning._id}`);
                }
              });
            }}
          >
            Guardar y publicar
          </button>
        )}
        {!isSameUser() && (
          <button
            className="btn btn--type1"
            onClick={(evt) => {
              evt.preventDefault();
              return Swal.fire({
                icon: 'question',
                title: 'Copiar planeación',
                text: `¿Deseas añadir la planeación: ${
                  planning.name || ''
                } a tus planeaciones?`,
                showCancelButton: true,
                confirmButtonText: 'Añadir',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  return duplicatePlanning();
                }
              });
            }}
          >
            Copiar esta planeación
          </button>
        )}
        {!!planning._id && (
          <button
            className="btn btn--type5"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            Regresar
          </button>
        )}
      </div>
      <div id="print-placeholder" ref={componentRef}>
        <PrintPlanComponent
          show={showPrint}
          planning={planning}
          resources={resources}
        />
      </div>
    </section>
  );
};

export default PlaneaionesFlexiblesPreview;
