import React, { useState, useEffect } from 'react';
import { fromUnixTime } from 'date-fns';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
  IFlexiblePlanningSession,
} from '../../../../types/FlexiblePlanningInterface';
import DatepickerComponent from '../../../Form/datepicker/datepicker.component';
import TimepickerComponent from '../../../Form/timepicker/timepicker.component';
import ToggleEnabledComponent from '../../../Form/toggleEnabled/toggleEnabled.component';
import ModalInfoComponent from '../../../Modal/modalInfo/modalInfo.component';
import OverlayComponent from '../../../OverlayTrigger/OverlayComponent';
import ChatGpt from '../../../ChatGpt/chatGpt';
import { IUser } from '../../../../types/UserInterface';
import { useTracking } from 'react-tracking';
import MultiSelectComponent from '../../../Form/multiselect/multiselect.component';
import { Libros } from '../../../../types/EntidadesInterface';

interface Props {
  user: Partial<IUser>;
  planning: Partial<IFlexiblePlanning>;
  planningAsignatura: Partial<IFlexiblePlanningAsignatura>;
  session: Partial<IFlexiblePlanningSession>;
  libros: {
    id: Number;
    attributes: Libros;
  }[];
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
  updateSecuenciaState: (
    session: Partial<IFlexiblePlanningSession>,
    key: keyof IFlexiblePlanningSession,
    value: any
  ) => Partial<IFlexiblePlanningSession>;
  updateSecuencia: (
    session: Partial<IFlexiblePlanningSession>
  ) => Promise<IFlexiblePlanningSession>;
}

const IndexSecuenciaComponent = ({
  user,
  planning,
  libros,
  session,
  planningAsignatura,
  updatePlanning,
  updatePlanningState,
  updateSecuenciaState,
  updateSecuencia,
}: Props) => {
  const { trackEvent } = useTracking();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');

  const handleChatButtonClick = (opt: number) => {
    if(opt === 4) {
      setShowTextArea(true)
      setloading(true);
    }
  };

  const handleChatOptionButtonClick = (msj: string, opt: number) => {
    let txt = '';
    if(opt === 4) {
      setShowTextArea(true)
      txt = (textAreaValue === '') 
                ? `Mi primera sugerencia es: ${msj} \n\n`
                : `Mi segunda sugerencia es: ${msj} \n\n ${textAreaValue}`;
      setTextAreaValue(txt)
      setloading(false);
    }
  };

  const copytxt = () => {
    trackEvent({
      event: 'copyArti',
      page: '/planeacion-flexible',
      planningId: planning._id,
      asignaturaId: planningAsignatura._id,
      sessionId: session._id,
      campo: 'descripcion-secuencia',
      fullPath: window.location.href,
    });
    const textI = textAreaValue.replace('Mi primera sugerencia es: ', '')
                          .replace('Mi segunda sugerencia es: ', '').trim();
    let desc = (session.description) ? session.description + textI : textI;
    updateSecuenciaState(
      session,
      'description',
      desc
    );
    updateSecuencia({
      ...session,
      description: desc
    });
  };

  useEffect(() => {}, [session, planning]);
  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        class="modal--secondary"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Crear secuencias didácticas</h4>
            <p>
              Este paso corresponde a la parte central y más importante de tu
              planeación. Aquí defines la duración de tu planeación (si es para
              un día o una semana), el número de secuencias didácticas que la
              integran y la descripción detallada de cada secuencia didáctica
              (inicio, desarrollo y cierre). Incluye al final una sección de
              evaluación y campos para señalar cambios o adecuaciones
              curriculares.
            </p>
          </>
        }
      />
      <section className="index-planning">
        <div className="new-planning-header__top row">
          <div className="col-12">
            <h1 className="h4 text-purple1">
              Detalles de la secuencia didáctica
              <button
                onClick={() => {
                  setModalShow(true);
                }}
                className="toggle-moda-tooltip"
              >
                <span className="material-icons-outlined">info</span>
              </button>
            </h1>
          </div>
        </div>
        <div className="index-planning-content">
          <div className="planning-row-form">
            <div className="row">
              <div className="col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'name',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'name',
                        ''
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Nombre de la secuencia didáctica
                      <OverlayComponent toolTipText="Puedes añadir un nombre a tu secuencia didáctica, de esta manera puedes identificarla con facilidad.  " />
                    </>
                  }
                  contentToEnabled={
                    <>
                      <input
                        type="text"
                        className="form-control"
                        id="session-name"
                        aria-describedby="emailHelp"
                        value={session.name || ''}
                        onChangeCapture={(event) =>
                          updateSecuenciaState(
                            session,
                            'name',
                            (event.target as HTMLInputElement).value
                          )
                        }
                        onBlur={(event) => {
                          event.preventDefault();
                          updateSecuencia({
                            ...session,
                            name: (event.target as HTMLInputElement).value,
                          });
                        }}
                      />
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="planning-row-form">
            <div className="row">
              <div className="planning-row-form__col col-md-6 col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'startDate',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'startDate',
                        undefined
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Fecha de la secuencia didáctica
                      <OverlayComponent toolTipText="Añade la fecha en la que vas a implementar esta secuencia didáctica.  " />
                    </>
                  }
                  contentToEnabled={
                    <>
                      <DatepickerComponent
                        currentValue={
                          session.startDate
                            ? fromUnixTime(session.startDate)
                            : null
                        }
                        onDateChange={(unix) => {
                          const newSession = updateSecuenciaState(
                            session,
                            'startDate',
                            unix
                          );
                          updateSecuencia(newSession);
                        }}
                      />
                    </>
                  }
                />
              </div>
              <div className="planning-row-form__col col-md-6 col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'minutes',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'minutes',
                        undefined
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Tiempo total de la secuencia didáctica
                      <OverlayComponent toolTipText="Añade el tiempo total destinado a esta secuencia didáctica. Formato horas:minutos  " />
                    </>
                  }
                  contentToEnabled={
                    <>
                      <TimepickerComponent
                        currentValue={
                          session?.minutes
                            ? fromUnixTime(session.minutes)
                            : null
                        }
                        onTimeChange={(unix) => {
                          const newSession = updateSecuenciaState(
                            session,
                            'minutes',
                            unix
                          );
                          updateSecuencia(newSession);
                        }}
                      />
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="planning-row-form">
            <div className="row">
              <div className="col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'description',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'description',
                        ''
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Descripción de la secuencia didáctica
                      <OverlayComponent toolTipText="Añade una descripción breve para ayudarte a identificar el contenido de cada secuencia didáctica. ARTI te puede ayudar con algunas ideas " />
                    </>
                  }
                  contentToEnabled={
                    <> 
                      <ChatGpt 
                        user={user}
                        onChatButtonClick={handleChatButtonClick} 
                        onChatOptionButtonClick={handleChatOptionButtonClick} 
                        campo='una descripción para la secuencia didáctica'
                        slug='descripcion-secuencia'
                        planningId={planning._id}
                        asignaturaId={planningAsignatura._id}
                        sessionId={session._id}
                        option={4}
                      />
                      {showTextArea ? (
                      <div className="form-control form--hide-element">
                         <textarea
                            className="form-control"
                            value={session.description}
                            onChangeCapture={(event) =>
                              updateSecuenciaState(
                                session,
                                'description',
                                (event.target as HTMLTextAreaElement).value
                              )
                            }
                            onBlur={(event) => {
                              event.preventDefault();
                              updateSecuencia({
                                ...session,
                                description: (event.target as HTMLTextAreaElement)
                                  .value,
                              });
                            }}
                          ></textarea>
                        <p className="autocomplete-field__indicator">Texto sugerido por ARTI, puedes copiarlo y pegarlo en el campo de tu planeación, ahí podrás editarlo. <br /> Solo puedes usar ARTI 2 veces en este campo.</p>
                        <div className="autocomplete-field__container">
                          { loading ? <img className="loader-small" src="/assets/images/elements/arthinking.gif" /> : <></> }
                          <textarea className="autocomplete-field" value={textAreaValue} />
                          { textAreaValue != '' ? 
                            <div className='autocomplete-field__btn'>
                              <button
                                className="btn btn--type-outline btn--small"
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  copytxt();
                                }}
                              >
                                Copiar
                              </button>
                            </div>
                            : <></>
                          }
                        </div>
                        
                      </div>
                      ) : <>
                        <textarea
                          className="form-control"
                          value={session.description}
                          onChangeCapture={(event) =>
                            updateSecuenciaState(
                              session,
                              'description',
                              (event.target as HTMLTextAreaElement).value
                            )
                          }
                          onBlur={(event) => {
                            event.preventDefault();
                            updateSecuencia({
                              ...session,
                              description: (event.target as HTMLTextAreaElement)
                                .value,
                            });
                          }}
                        ></textarea>
                      </>}
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="planning-row-form">
            <div className="row">
              <div className="planning-row-form__col col-md-6 col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'libros',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'libros',
                        []
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Agrega un libro de texto
                    </>
                  }
                  contentToEnabled={
                    <>
                      <MultiSelectComponent
                        from="search"
                        value={
                          session.libros?.length 
                            ? session.libros.map((book) => ({
                                label: book.value,
                                value: book.documentId,
                             }))
                           : []
                        }
                        options={libros.map((book) => {
                          return {
                            label: book.attributes.Nombre,
                            value: `${book.id}`,
                          };
                        })}
                        onChange={(values) => {
                          const books = values || [];
                          const secuencia = updateSecuenciaState(
                            session,
                            'libros',
                            books.map((b) => {
                              const libro = libros.find((l) => `${l.id}` === b.value);
                              return {
                                documentId: b.value,
                                value: b.label,
                                link: libro ? libro.attributes.Link : null,
                              };
                            })
                            
                          );
                          updateSecuencia({ ...secuencia });
                        }}
                      />
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className='row'>
          {session.libros?.length && session.libros[0].documentId ? (
            (session.libros ).map((book, k) => {
              const {
                  value: name,
                  link,
              } = book;
              return (
                <div className="sessionbrowser__item browser--element books-plan col-md-2 col-2" key={k}>
                  <p>{name}</p>
                  <button
                    className="btn btn--type-outline btn--small"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent({
                        event: 'clickBookPlanning',
                        page: '/planeacion-flexible',
                        planningId: planning._id,
                        asignaturaId: planningAsignatura._id,
                        sessionId: session._id,
                        book: name,
                        fullPath: window.location.href,
                      });
                      window.open(link, '_blank');
                    }}
                  >
                  Consultar aquí
                  </button>
                </div>
              );
            })
          ) : (
            <></>
          )}
          </div>
          <div className="planning-row-form">
            <div className="row">
              <div className="col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'materialFisico',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'materialFisico',
                        ''
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Material físico
                      <OverlayComponent toolTipText="Añade el material físico que se requiere para cada secuencia didáctica (proyector, plumones, cartulinas, etc).  " />
                    </>
                  }
                  contentToEnabled={
                    <>
                      <textarea
                        className="form-control"
                        value={session.materialFisico || ''}
                        onChangeCapture={(event) =>
                          updateSecuenciaState(
                            session,
                            'materialFisico',
                            (event.target as HTMLTextAreaElement).value
                          )
                        }
                        onBlur={(event) => {
                          event.preventDefault();
                          updateSecuencia({
                            ...session,
                            materialFisico: (
                              event.target as HTMLTextAreaElement
                            ).value,
                          });
                        }}
                      ></textarea>
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="planning-row-form">
            <div className="row">
              <div className="col-12">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanning,
                    updatePlanningState,
                    toUpdate: 'session',
                    session: {
                      sessionId: session._id || '',
                      field: 'pausaActiva',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked) {
                      const newSession = updateSecuenciaState(
                        session,
                        'pausaActiva',
                        ''
                      );
                      updateSecuencia(newSession);
                    }
                  }}
                  contentTitle={
                    <>
                      Pausa activa
                      <OverlayComponent toolTipText="Realiza una actividad previa para preparar a tu grupo." />
                    </>
                  }
                  contentToEnabled={
                    <>
                      <textarea
                        className="form-control"
                        value={session.pausaActiva}
                        onChangeCapture={(event) =>
                          updateSecuenciaState(
                            session,
                            'pausaActiva',
                            (event.target as HTMLTextAreaElement).value
                          )
                        }
                        onBlur={(event) => {
                          event.preventDefault();
                          updateSecuencia({
                            ...session,
                            pausaActiva: (event.target as HTMLTextAreaElement)
                              .value,
                          });
                        }}
                      ></textarea>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndexSecuenciaComponent;
