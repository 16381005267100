import React from 'react';

type Props = {
  dataClass?: any;
  text?: string;
};

const LoaderComponent = ({ dataClass, text }: Props) => {
  return (
    <>
    <div className="loader__wrapp" data-class={dataClass}>
        <div className="loader__inner">
          <div className="loader__arti-element col-12 col-reset">
            <img
              src="/assets/images/elements/arti-happy.png"
              alt="Arti Chat"
              className="animate__animated animate__jackInTheBox animate__infinite animate__slow"
            />
          </div>
          <div className="loader"></div>
          <div className="loader__arti-element col-12 col-reset text-center mt-4 mb-0">
            <p className="h5">{text}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoaderComponent;
