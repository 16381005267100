import qs from 'qs';
import api from './index';
import {
  IFlexiblePlanning,
  IFlexiblePlanningQuery,
  ResultPaginateFlexible,
} from '../types/FlexiblePlanningInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createFlexiblePlanning = async (
  planning: Partial<IFlexiblePlanning>,
  token: string
) => {
  console.log('------ createPlanning ------');
  const response = await api
    .post<IFlexiblePlanning>('/flexible-plannings', planning, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getFlexiblePlanning = async (id: string, token: string) => {
  console.log('------ getPlanning ------', { id, token });
  const response = await api
    .get<IFlexiblePlanning>(`/flexible-plannings/${id}`, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const deleteFlexiblePlanning = async (id: string, token: string) => {
  console.log('------ deletePlanning ------', { id, token });
  const response = await api
    .delete<IFlexiblePlanning>(`/flexible-plannings/${id}`, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateFlexiblePlanning = async (
  id: string,
  planning: Partial<IFlexiblePlanning>,
  token: string
): Promise<Partial<IFlexiblePlanning>> => {
  console.log('------ updatePlanning ------');
  const response = await api
    .patch<IFlexiblePlanning>(`/flexible-plannings/${id}`, planning, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchFlexiblePlanning = async (
  query: Partial<IFlexiblePlanningQuery>,
  token: string
) => {
  console.log('------ searchPlanning ------');
  const response = await api
    .post<ResultPaginateFlexible>(
      `/flexible-plannings/search?${qs.stringify({ limit: 50 })}`,
      query,
      {
        headers: {
          ...commonHeaders,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
