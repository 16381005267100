import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './testAnswersDetail.screen.scss';
import { PecExam } from '../../../types/PECInterface';
import { getPecExam } from '../../../strapi/pecContent';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import NavigationHints from '../../../components/NavigationHints/navigationHints';

const TestAnswersDetailScreen = () => {
  const { gradoId, subject, idExam } = useParams();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [examen, setExamen] = useState<Partial<PecExam>>({});
  const [type, setType] = useState<
    {
      id: number;
      value: string;
      value_eng: string;
    }[]
  >([]);

  const typesExam = [
    {
      id: 1,
      value: 'Examen anual',
      value_eng: 'annualExams',
    },
    {
      id: 2,
      value: 'Examen por bloque',
      value_eng: 'blockExams',
    },
    {
      id: 3,
      value: 'Examen por lección',
      value_eng: 'lessonExams',
    },
    {
      id: 4,
      value: 'Examen por tema',
      value_eng: 'topicExams',
    },
    {
      id: 5,
      value: 'Ejercicio interactivo',
      value_eng: 'lessonExcercises',
    },
  ];

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchExamType = async () => {
    try {
      const query = {
        populate: { questions: { populate: '*' } },
        filters: { id: { $eq: idExam } },
      };
      const examResult = await getPecExam(qs.stringify(query));
      if (examResult && examResult.data && examResult.data.length) {
        setExamen(examResult.data[0].attributes);
        const tipoExamen = typesExam.filter(
          (tR) => tR.value_eng === examResult.data[0].attributes.examType
        );
        setType(tipoExamen);
      }
      trackEvent({
        event: 'pageView',
        page: '/ponte-a-prueba-detalle',
        fullPath: window.location.href,
        gradoId,
        subject,
        idExam,
        examTitle: examResult.data[0].attributes.title,
      });
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            fetchExamType();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  const handlePrint = () => {
    window.print();
    trackEvent({
      event: 'printExam',
      page: '/ponte-a-prueba-detalle',
      fullPath: window.location.href,
      idExam,
      examTitle: examen.title,
    });
  };

  return (
    <section className="app-section app--background app--print">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/ponte-a-prueba-tipo/${gradoId}/${subject}`}
          back="Regresar"
          urlInit={`/ponte-a-prueba-materia/${gradoId}`}
          urlInitText="Selecciona tu materia"
          urlSecond={`/ponte-a-prueba-tipo/${gradoId}/${subject}`}
          urlSecondText="Tipo de examen"
          urlThird={`/ponte-a-prueba-detalle/${gradoId}/${subject}/${idExam}`}
          urlThirdText="Examen"
        />

        <section className="container body-activity">
          <div className="row row--index">
            <div className=" mx-auto col-lg-6 col-12">
              <section className="body-activity__content">
                <article className="header-activity__intro">
                  <p>{type[0]?.value}</p>
                  <h2 className="h4">{examen.title}</h2>
                </article>
                <section className="body-activity__grades col-12">
                  <div className="activity-detail-options">
                    <div className="activity-detail-options__item">
                      <button
                        className="detail-button"
                        onClick={() => {
                          setTimeout(() => {
                            handlePrint();
                          }, 300);
                        }}
                      >
                        <span className="material-icons-outlined icon">
                          print
                        </span>
                        <span>Imprimir</span>
                      </button>
                      {/* <p>Dificultad: intermedia</p> */}
                    </div>
                  </div>
                  <article className="activity-detail-intro">
                    <h6>Instrucciones</h6>
                    <ReactMarkdown>{`${examen.instructions}`}</ReactMarkdown>
                  </article>
                  <section className="activity-detail-content">
                    {examen.questions && examen.questions.length ? (
                      examen.questions.map((questions, index) => {
                        return (
                          <div className="block-activity" key={questions.id}>
                            <div className="block-activity__item-head">
                              <h2 className="h5">{`Pregunta ${index + 1}/${
                                examen.questions?.length
                              }`}</h2>
                            </div>
                            <article className="block-activity__article">
                              <ReactMarkdown>{`${questions.text}`}</ReactMarkdown>
                            </article>
                            <div className="block-activity__item-options">
                              <article>
                                {questions.options &&
                                questions.options.length ? (
                                  questions.options.map((options, i) => {
                                    return (
                                      <div key={options.id}>
                                        <p>
                                          <strong>{`${i + 1} - `}</strong>
                                          <ReactMarkdown>
                                            {`${options.text}`}
                                          </ReactMarkdown>
                                          {options.isCorrect ? (
                                            <span className="activity-pill">
                                              Respuesta
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </p>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </article>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </section>
                </section>
              </section>
            </div>
          </div>
        </section>
      </div>
      <NavigationHints
        button1={{
          link: '/instrumentos-de-evaluacion',
          texto: 'Instrumentos de evaluación con Inteligencia Artificial',
          color: 'btn--color4',
          iconText: 'icon--grid8',
          icon: true,
          arti: true,
          max: false,
        }}
        button2={{
          link: '/planeaciones/nueva-escuela-mexicana',
          texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
          color: 'btn--color2',
          iconText: 'icon--grid2',
          icon: true,
          arti: false,
          max: false,
        }}
        button3={{
          link: '/crea-tu-planeacion-nem',
          texto: 'Planeaciones NEM con Inteligencia Artificial',
          iconText: 'icon--grid3',
          color: 'btn--color2',
          icon: true,
          arti: true,
          max: false,
        }}
        user={user}
      />
      <FooterComponent />
    </section>
  );
};

export default TestAnswersDetailScreen;
