import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import ReactMarkdown from 'react-markdown';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import { Accordion } from 'react-bootstrap';
import './faqs.screen.scss';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import { getStrapiCatalog } from '../../strapi/escuelas';
import { FAQs } from '../../types/EntidadesInterface';

const FaqsScreen = () => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [FAQs, setFAQs] = useState<{ id: number; attributes: FAQs }[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/preguntas-frecuentes',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
          }
        })
        .catch((error) => setError(error));
    });
    const query = {
      sort: ['id:asc'],
      pagination: { pageSize: 100 },
    };
    getStrapiCatalog(qs.stringify(query), 'faqs')
      .then((response) => {
        setFAQs(
          response.data as {
            id: number;
            attributes: FAQs;
          }[]
        );
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <section className="app-section">
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="container-simple container">
          <div className="row">
            <div className="col-md-8 col-12 mx-auto">
              <h3 className="form__title">Preguntas frecuentes</h3>
              <Accordion defaultActiveKey="0" className="simple-accordion">
                {(FAQs || [])
                  .map((question) => question.attributes.Tema)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((tema, key) => {
                    return (
                      <div key={key}>
                        {key === 0 ? (
                          <></>
                        ) : (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                        <h3>{`${tema}`}</h3>
                        {(FAQs || []).map((question, key) => {
                          const { Nombre, Answer, Tema } = question.attributes;
                          if (tema === Tema) {
                            return (
                              <Accordion.Item key={key} eventKey={`${key}`}>
                                <Accordion.Header>
                                  {Nombre}
                                  <span className="material-icons-outlined ">
                                    expand_more
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ReactMarkdown linkTarget={'_blank'}>
                                    {Answer}
                                  </ReactMarkdown>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          } else {
                            return <div key={key}></div>;
                          }
                        })}
                      </div>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </section>
      </div>

      <FooterComponent />
    </section>
  );
};

export default FaqsScreen;
