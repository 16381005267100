import React, { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ModalInfoComponent from '../../Modal/modalInfo/modalInfo.component';
import ToggleEnabledComponent from '../../Form/toggleEnabled/toggleEnabled.component';
import SelectComponent from '../../Form/select/select.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import CheckboxComponent from '../../Form/checkbox/checkbox.component';
import CamposAdicionalesComponent from '../camposAdicionales/camposAdicionales.component';
import SecuenciaDidacticaComponent from '../secuenciaDidactica/secuenciaDidactica.component';
import { IUser } from '../../../types/UserInterface';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
} from '../../../types/FlexiblePlanningInterface';
import {
  Bloque,
  Eje,
  GenericResult,
  Materia,
  Tema,
  Aprendizaje,
  Competencia,
  AprendizajeFundamental,
  PreguntaReflexion,
  RecursoEducativo,
  CampoFormacion,
  PracticaSocial,
  TipoTexto,
  LenguajeArtistico,
  Libros,
} from '../../../types/EntidadesInterface';
import DateRangePickerComponent from '../../Dates/DateRangePicker.component';
import MultiSelectComponent from '../../Form/multiselect/multiselect.component';
import Swal from 'sweetalert2';
import ChatGpt from '../../ChatGpt/chatGpt';
import { useTracking } from 'react-tracking';

interface Props {
  planning: Partial<IFlexiblePlanning>;
  user: Partial<IUser>;
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
  tiposRecurso: GenericResult;
  filtros: {
    camposFormacion: GenericResult;
    materias: GenericResult;
    pReflexion: GenericResult;
    instEvaluacion: GenericResult;
    libros: GenericResult;
  };
  bloques: {
    id: number;
    attributes: Bloque;
  }[];
  ejes: {
    id: number;
    attributes: Eje;
  }[];
  pSociales: {
    id: number;
    attributes: PracticaSocial;
  }[];
  tipoTextos: {
    id: number;
    attributes: TipoTexto;
  }[];
  temas: {
    id: number;
    attributes: Tema;
  }[];
  lenguajeArtistico: {
    id: number;
    attributes: LenguajeArtistico;
  }[];
  aprendizajes: {
    id: number;
    attributes: Aprendizaje;
  }[];
  competencias: {
    id: number;
    attributes: Competencia;
  }[];
  fundamentales: {
    id: number;
    attributes: AprendizajeFundamental;
  }[];
  getCustomCatalogs: (
    currentPlanning: Partial<IFlexiblePlanning>
  ) => Promise<void>;
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
}

const AsignaturaComponent = ({
  user,
  planning,
  filtros,
  bloques,
  ejes,
  pSociales,
  tipoTextos,
  temas,
  lenguajeArtistico,
  aprendizajes,
  competencias,
  fundamentales,
  getCustomCatalogs,
  recursos,
  tiposRecurso,
  updatePlanning,
  updatePlanningState,
}: Props) => {
  const { trackEvent } = useTracking();
  const [modalShow, setModalShow] = useState(false);
  const [activo, setActivo] = useState(false);
  const [loading, setloading] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [camposad, setCamposad] = useState({estado: false, est: 0});

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const updateAsignaturaState = (
    planningAsignatura: IFlexiblePlanningAsignatura,
    key: keyof IFlexiblePlanningAsignatura,
    value: any
  ) => {
    const { _id: asignaturaId } = planningAsignatura;
    const currentPlanning = { ...planning };
    if (asignaturaId) {
      console.log('updateAsignaturaState', { key, value });
      currentPlanning.asignaturas?.forEach((asignatura, idx) => {
        if (asignatura._id === asignaturaId && currentPlanning.asignaturas) {
          currentPlanning.asignaturas[idx][key] = value;
        }
      });
      updatePlanningState('asignaturas', currentPlanning.asignaturas);
    }
  };

  const updateAsignatura = async (
    planningAsignatura: Partial<IFlexiblePlanningAsignatura>,
    key: keyof IFlexiblePlanningAsignatura
  ): Promise<Partial<IFlexiblePlanningAsignatura>> => {
    const keysToRefresh: Array<keyof IFlexiblePlanningAsignatura> = [
      'asignatura',
      'bloque',
      'eje',
      'tema',
      'pSocial',
    ];
    const { _id: asignaturaId } = planningAsignatura;
    const currentPlanning = { ...planning };
    let position = 0;
    if (asignaturaId) {
      currentPlanning.asignaturas?.forEach((asignatura, idx) => {
        if (
          asignatura._id === asignaturaId &&
          currentPlanning.asignaturas?.length
        ) {
          currentPlanning.asignaturas[idx] = {
            ...planningAsignatura,
          };
          position = idx;
        }
      });
    } else {
      currentPlanning.asignaturas?.push({ ...planningAsignatura });
      position = currentPlanning.asignaturas
        ? currentPlanning.asignaturas.length - 1
        : position;
    }
    const newPlanning = await updatePlanning({ ...currentPlanning });
    if (keysToRefresh.includes(key)) {
      await getCustomCatalogs({ ...currentPlanning });
    }

    return newPlanning.asignaturas?.length
      ? newPlanning.asignaturas[position]
      : {};
  };

  const handleChatButtonClick = (opt: number) => {
    if(opt === 1) {
      setShowTextArea(true)
      setloading(true);
    }
  };

  const handleChatOptionButtonClick = (msj: string, opt: number) => {
    let txt = '';
    if(opt === 1) {
      setShowTextArea(true)
      txt = (textAreaValue === '') 
                ? `Mi primera sugerencia es: ${msj} \n\n`
                : `Mi segunda sugerencia es: ${msj} \n\n ${textAreaValue}`;
      setTextAreaValue(txt)
      setloading(false);
    }
  };

  const copytxt = (planningAsignatura:any) => {
    trackEvent({
      event: 'copyArti',
      page: '/planeacion-flexible',
      planningId: planning._id,
      asignaturaId: planningAsignatura._id,
      campo: 'aprendizajes-esperados',
      fullPath: window.location.href,
    });
    let aprentxt = (planningAsignatura.aprendizajes
                ?.map((ap:any) => ap.value)
                .join(' ') || '') 
                + textAreaValue.replace('Mi primera sugerencia es: ', '')
                        .replace('Mi segunda sugerencia es: ', '').trim();
    updateAsignaturaState(
      planningAsignatura,
      'aprendizajes',
      [
        {
          documentId: '',
          value: aprentxt
        },
      ]
    );
    updateAsignatura(
      {
        ...planningAsignatura,
        aprendizajes: [
          {
            documentId: '',
            value: aprentxt
          },
        ],
      },
      'aprendizajes'
    );
  };

  useEffect(() => {}, [planning]);

  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Asignaturas y detalles para tu planeación</h4>
            <p>
              Describe la información que necesitas por asignatura: eje, bloque,
              aprendizajes esperados, aprendizajes fundamentales, competencias
              que se favorecen, etc. Puedes agregar detalles sobre las
              asignaturas que vas a trabajar con esta planeación.
            </p>
          </>
        }
      />
      <Accordion
        defaultActiveKey="0"
        className="new-accordion-planning accordion--color1"
      >
        <Card>
          <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
            <div id="asignatura" className="plan-nav__click"></div>
            <div className="accordion-planning__title">
              <span className="material-icons-outlined icon">library_add</span>
              <p>
                Asignaturas y detalles para tu planeación
                <button
                  onClick={() => {
                    setModalShow(true);
                  }}
                  className="toggle-moda-tooltip"
                >
                  <span className="material-icons-outlined">info</span>
                </button>
              </p>
            </div>
            <CustomToggle eventKey="0">
              <span className="material-icons-outlined accordion-header__arrow">
                keyboard_arrow_down
              </span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Accordion defaultActiveKey="0" className="subaccordion-planning">
                {planning.asignaturas?.map((planningAsignatura, idx) => {
                  const filteredAsignaturas = filtros.materias.data.filter(
                    (materia) => {
                      const {
                        modelo_educativo,
                        grados: { data: grados },
                        campos_de_formacion,
                      } = materia.attributes as Materia;
                      const { gradoEscolar } = planning;
                      const valid =
                        `${modelo_educativo.data.id}` ===
                          planning.modeloEducativo?.documentId &&
                        grados
                          .map((g) => `${g.id}`)
                          .includes(gradoEscolar?.documentId || '');
                      if (planning.modeloEducativo?.documentId === '2') {
                        return (
                          valid &&
                          `${campos_de_formacion.data.id}` ===
                            planningAsignatura.campoFormacion?.documentId
                        );
                      }
                      return valid;
                    }
                  );
                  const filteredBloques = bloques.filter((b) => {
                    const materias = b.attributes.materias.data.map(
                      (m) => m.id
                    );
                    return materias.some(
                      (m) =>
                        planningAsignatura.asignatura?.documentId === `${m}`
                    );
                  });
                  const filteredEjes = ejes.filter((e) => {
                    return (
                      `${e.attributes.materia.data.id}` ===
                      planningAsignatura.asignatura?.documentId
                    );
                  });
                  const filteredPSociales = pSociales.filter(
                    (pS) =>
                      `${pS.attributes.bloque.data.id}` ===
                        planningAsignatura.bloque?.documentId &&
                      `${pS.attributes.eje.data.id}` ===
                        planningAsignatura.eje?.documentId
                  );
                  const filteredTipoTextos = tipoTextos.filter((tT) => {
                    const pSociales = tT.attributes.practica_socials.data.map(
                      (pS) => pS.id
                    );
                    return pSociales.some(
                      (pS) => planningAsignatura.pSocial?.documentId === `${pS}`
                    );
                  });
                  const filteredTemas = temas.filter((t) => {
                    const ejes = t.attributes.ejes.data.filter((e) => e.id);
                    return (
                      `${t.attributes.materia.data.id}` ===
                        planningAsignatura.asignatura?.documentId &&
                      ejes.some(
                        (ej) =>
                          `${ej.id}` === planningAsignatura.eje?.documentId
                      )
                    );
                  });
                  const filteredLArtistico = lenguajeArtistico.filter((lA) => {
                    const ejes =
                      lA.attributes.ejes.data?.map((e) => e.id) || [];
                    const temas =
                      lA.attributes.temas.data?.map((e) => e.id) || [];
                    return (
                      ejes.some(
                        (eId) => `${eId}` === planningAsignatura.eje?.documentId
                      ) &&
                      temas.some(
                        (t) => `${t}` === planningAsignatura.tema?.documentId
                      )
                    );
                  });
                  const filteredAprendizajes = aprendizajes.filter((ap) => {
                    const {
                      materia: {
                        data: { id: asignaturaId },
                      },
                      ejes,
                      temas,
                      bloque,
                      practica_social,
                    } = ap.attributes;
                    const planningAsignaturaId =
                      planningAsignatura.asignatura?.documentId;
                    const ejesIds = ejes.data?.map((e) => e.id) || [];
                    const temasIds = temas.data?.map((e) => e.id) || [];
                    const valid =
                      ejesIds.some(
                        (eId) => `${eId}` === planningAsignatura.eje?.documentId
                      ) ||
                      temasIds.some(
                        (tId) =>
                          `${tId}` === planningAsignatura.tema?.documentId
                      ) ||
                      `${bloque.data?.id}` ===
                        planningAsignatura.bloque?.documentId ||
                      `${practica_social.data?.id}` ===
                        planningAsignatura.pSocial?.documentId;
                    return `${asignaturaId}` === planningAsignaturaId && valid;
                  });
                  const filteredCompetencias = competencias.filter((ap) => {
                    const {
                      materia: {
                        data: { id: asignaturaId },
                      },
                    } = ap.attributes;
                    const planningAsignaturaId =
                      planningAsignatura.asignatura?.documentId;
                    return `${asignaturaId}` === planningAsignaturaId;
                  });
                  const filteredFundamentales = fundamentales.filter((ap) => {
                    const { materias } = ap.attributes;
                    const materiasIds = materias.data.map((e) => e.id);
                    return materiasIds.some(
                      (eId) =>
                        `${eId}` === planningAsignatura.asignatura?.documentId
                    );
                  });
                  return (
                    <>
                      <Accordion.Item eventKey={`${idx}`} key={idx} className="mb-2">
                      <Accordion.Header>
                        {planningAsignatura.asignatura?.value ||
                          'Nueva asignatura'}
                        <span className="material-icons-outlined accordion-header__arrow">
                          keyboard_arrow_down
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="planning-row-form">
                          <div className="row">
                            <div className="planning-row-form__col col-lg-6 col-12">
                              <ToggleEnabledComponent
                                updates={{
                                  currentPlanning: planning,
                                  updatePlanning,
                                  updatePlanningState,
                                  toUpdate: 'asignatura',
                                  asignatura: {
                                    asignaturaId: planningAsignatura._id || '',
                                    field: 'period',
                                  },
                                }}
                                disableField={(checked) => {
                                  if (!checked) {
                                    updateAsignatura(
                                      {
                                        ...planningAsignatura,
                                        period: undefined,
                                      },
                                      'period'
                                    );
                                  }
                                }}
                                contentTitle={
                                  <>
                                    Selecciona un periodo de tiempo
                                    <OverlayComponent toolTipText="Elige si el periodo de tiempo de tu planeación será por día, semana o quincena." />
                                  </>
                                }
                                contentToEnabled={
                                  <>
                                    <SelectComponent
                                      label={''}
                                      id={'period'}
                                      option={
                                        <>
                                          <option defaultValue="">
                                            Seleccionar...
                                          </option>
                                          {['Día', 'Semana', 'Quincena'].map(
                                            (periodo, idx) => {
                                              return (
                                                <option
                                                  key={idx}
                                                  value={periodo}
                                                  selected={
                                                    periodo ===
                                                    planningAsignatura.period
                                                  }
                                                >
                                                  {`${periodo}`}
                                                </option>
                                              );
                                            }
                                          )}
                                        </>
                                      }
                                      onChange={(evt) => {
                                        evt.preventDefault();
                                        if (evt.target.value) {
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              period: evt.target.value,
                                            },
                                            'period'
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                }
                              />
                            </div>
                            <div className="planning-row-form__col col-lg-6 col-12">
                              <ToggleEnabledComponent
                                updates={{
                                  currentPlanning: planning,
                                  updatePlanning,
                                  updatePlanningState,
                                  toUpdate: 'asignatura',
                                  asignatura: {
                                    asignaturaId: planningAsignatura._id || '',
                                    field: 'dateRanges',
                                  },
                                }}
                                disableField={(checked) => {
                                  if (!checked) {
                                    updateAsignatura(
                                      {
                                        ...planningAsignatura,
                                        dateRanges: undefined,
                                      },
                                      'dateRanges'
                                    );
                                  }
                                }}
                                contentTitle={
                                  <>
                                    Selecciona un rango de fechas
                                    <OverlayComponent toolTipText="Elige el rango de fechas que abarcará tu planeación. " />
                                  </>
                                }
                                contentToEnabled={
                                  <>
                                    <DateRangePickerComponent
                                      value={
                                        planningAsignatura.dateRanges &&
                                        planningAsignatura.dateRanges.start >
                                          0 &&
                                        planningAsignatura.dateRanges.end > 0
                                          ? [
                                              fromUnixTime(
                                                planningAsignatura.dateRanges
                                                  .start
                                              ),
                                              fromUnixTime(
                                                planningAsignatura.dateRanges
                                                  .end
                                              ),
                                            ]
                                          : null
                                      }
                                      onChange={(value, event) => {
                                        event.preventDefault();
                                        if (value) {
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              dateRanges: {
                                                start: getUnixTime(value[0]),
                                                end: getUnixTime(value[1]),
                                              },
                                            },
                                            'dateRanges'
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {!planning.modeloEducativo?.documentId &&
                        !filteredAsignaturas.length ? (
                          <>
                            <div className="planning-row-form">
                              <div className="row">
                                <div className="planning-row-form__col col-lg-6 col-12">
                                  <div className="form-input-element">
                                    <label
                                      htmlFor="asignatura"
                                      className="form-label"
                                    >
                                      Selecciona la asignatura <small>*</small>
                                    </label>
                                    <SelectComponent
                                      label={''}
                                      id="asignatura"
                                      option={
                                        <>
                                          <option value="">
                                            Seleccionar...
                                          </option>
                                          {filtros.materias.data
                                            .filter(
                                              (m) =>
                                                (m.attributes as Materia)
                                                  .modelo_educativo.data.id ===
                                                1
                                            )
                                            .map((materia, idx) => {
                                              return (
                                                <option
                                                  key={idx}
                                                  id={`${idx}`}
                                                  selected={
                                                    `${materia.id}` ===
                                                    planningAsignatura
                                                      .asignatura?.value
                                                  }
                                                  value={`${materia.id}`}
                                                >{`${
                                                  (
                                                    materia.attributes as Materia
                                                  ).Materia
                                                }`}</option>
                                              );
                                            })}
                                        </>
                                      }
                                      onChange={(evt) => {
                                        evt.preventDefault();
                                        if (evt.target.value) {
                                          setCamposad({estado: false, est: 1});
                                          setTextAreaValue('');
                                          setShowTextArea(false);
                                          console.log(camposad);
                                          const result =
                                            filtros.materias.data.filter(
                                              (s) =>
                                                `${s.id}` === evt.target.value
                                            );
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              asignatura: {
                                                documentId: evt.target.value,
                                                value: result.length
                                                  ? (
                                                      result[0]
                                                        .attributes as Materia
                                                    ).Materia
                                                  : '',
                                              },
                                              bloque: undefined,
                                              eje: undefined,
                                              pSocial: undefined,
                                              tTexto: undefined,
                                              lArtistico: undefined,
                                              tema: undefined,
                                              aprendizajes: [],
                                              competencias: [],
                                              aprendizajesFundamentales: [],
                                              apClave: undefined,
                                              propPed: undefined
                                            },
                                            'asignatura'
                                          );
                                          setTimeout(() => {
                                            setCamposad({estado: false, est: 0});
                                          }, 2000);
                                        } else {
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              asignatura: undefined,
                                              bloque: undefined,
                                              eje: undefined,
                                              pSocial: undefined,
                                              tTexto: undefined,
                                              lArtistico: undefined,
                                              tema: undefined,
                                              aprendizajes: [],
                                              competencias: [],
                                              aprendizajesFundamentales: [],
                                              apClave: undefined,
                                              propPed: undefined
                                            },
                                            'description'
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="planning-row-form__col col-lg-6 col-12">
                                  <div className="form-input-element">
                                    <label
                                      htmlFor="tema"
                                      className="form-label"
                                    >
                                      Escribe el tema <small>*</small>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="tema"
                                      aria-describedby="tema"
                                      placeholder="Escribe el tema"
                                      value={
                                        planningAsignatura.tema?.value || ''
                                      }
                                      onChangeCapture={(event) =>
                                        updateAsignaturaState(
                                          { ...planningAsignatura },
                                          'tema',
                                          {
                                            documentId: '',
                                            value: (
                                              event.target as HTMLInputElement
                                            ).value,
                                          }
                                        )
                                      }
                                      onBlur={(event) => {
                                        event.preventDefault();
                                        if (event.target.value) {
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              tema: {
                                                documentId: '',
                                                value: (
                                                  event.target as HTMLInputElement
                                                ).value,
                                              },
                                            },
                                            'description'
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="planning-row-form">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-input-element">
                                    <label
                                      htmlFor="tema"
                                      className="form-label"
                                    >
                                      Escribe los aprendizajes esperados{' '}
                                      <small>*</small>
                                    </label>
                                    <> 
                                      <ChatGpt 
                                        user={user}
                                        onChatButtonClick={handleChatButtonClick} 
                                        onChatOptionButtonClick={handleChatOptionButtonClick} 
                                        campo='aprendizajes esperados'
                                        slug='aprendizajes-esperados'
                                        planningId={planning._id}
                                        asignaturaId={planningAsignatura._id}
                                        sessionId=''
                                        option={1}
                                      />
                                      {showTextArea ? (
                                      <div className="form-control form--hide-element">
                                        <textarea
                                          className="form-control"
                                          value={
                                            planningAsignatura.aprendizajes
                                              ?.map(({ value }) => value)
                                              .join(' ') || ''
                                          }
                                          onChangeCapture={(event) =>
                                            updateAsignaturaState(
                                              planningAsignatura,
                                              'aprendizajes',
                                              [
                                                {
                                                  documentId: '',
                                                  value: (
                                                    event.target as HTMLTextAreaElement
                                                  ).value,
                                                },
                                              ]
                                            )
                                          }
                                          onBlur={(event) => {
                                            event.preventDefault();
                                            updateAsignatura(
                                              {
                                                ...planningAsignatura,
                                                aprendizajes: [
                                                  {
                                                    documentId: '',
                                                    value: (
                                                      event.target as HTMLTextAreaElement
                                                    ).value,
                                                  },
                                                ],
                                              },
                                              'aprendizajes'
                                            );
                                          }}
                                        ></textarea>
                                        <p className="autocomplete-field__indicator">Texto sugerido por ARTI, puedes copiarlo y pegarlo en el campo de tu planeación, ahí podrás editarlo. <br /> Solo puedes usar ARTI 2 veces en este campo.</p>
                                        <div className="autocomplete-field__container">
                                          { loading ? <img className="loader-small" src="/assets/images/elements/arthinking.gif" /> : <></> }
                                          <textarea className="autocomplete-field" value={textAreaValue} />
                                          { textAreaValue != '' ? 
                                            <div className='autocomplete-field__btn'>
                                              <button
                                                className="btn btn--type-outline btn--small"
                                                onClick={(evt) => {
                                                  evt.preventDefault();
                                                  copytxt(planningAsignatura);
                                                }}
                                              >
                                                Copiar
                                              </button>
                                            </div>
                                            : <></>
                                          }
                                        </div>
                                        
                                      </div>
                                      ) : <>
                                        <textarea
                                          className="form-control"
                                          value={
                                            planningAsignatura.aprendizajes
                                              ?.map(({ value }) => value)
                                              .join(' ') || ''
                                          }
                                          onChangeCapture={(event) =>
                                            updateAsignaturaState(
                                              planningAsignatura,
                                              'aprendizajes',
                                              [
                                                {
                                                  documentId: '',
                                                  value: (
                                                    event.target as HTMLTextAreaElement
                                                  ).value,
                                                },
                                              ]
                                            )
                                          }
                                          onBlur={(event) => {
                                            event.preventDefault();
                                            updateAsignatura(
                                              {
                                                ...planningAsignatura,
                                                aprendizajes: [
                                                  {
                                                    documentId: '',
                                                    value: (
                                                      event.target as HTMLTextAreaElement
                                                    ).value,
                                                  },
                                                ],
                                              },
                                              'aprendizajes'
                                            );
                                          }}
                                        ></textarea>
                                      </>}
                                    </>
                                    {/* <textarea
                                      className="form-control"
                                      value={
                                        planningAsignatura.aprendizajes
                                          ?.map(({ value }) => value)
                                          .join(' ') || ''
                                      }
                                      onChangeCapture={(event) =>
                                        updateAsignaturaState(
                                          planningAsignatura,
                                          'aprendizajes',
                                          [
                                            {
                                              documentId: '',
                                              value: (
                                                event.target as HTMLTextAreaElement
                                              ).value,
                                            },
                                          ]
                                        )
                                      }
                                      onBlur={(event) => {
                                        event.preventDefault();
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            aprendizajes: [
                                              {
                                                documentId: '',
                                                value: (
                                                  event.target as HTMLTextAreaElement
                                                ).value,
                                              },
                                            ],
                                          },
                                          'aprendizajes'
                                        );
                                      }}
                                    ></textarea> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="planning-row-form">
                            <div className="row">
                              {planning.modeloEducativo?.documentId === '2' && (
                                <div className="planning-row-form__col col-lg-6 col-12">
                                  <div className="form-input-element">
                                    <label
                                      htmlFor="asignatura"
                                      className="form-label"
                                    >
                                      Selecciona un campo de formación{' '}
                                      <small>*</small>
                                    </label>
                                    <SelectComponent
                                      label=""
                                      id="campoFormacion"
                                      option={
                                        <>
                                          <option defaultValue="">
                                            Seleccionar...
                                          </option>
                                          {filtros.camposFormacion.data.map(
                                            (cF, idx) => {
                                              const { id, attributes } = cF;
                                              const { Nombre } =
                                                attributes as CampoFormacion;
                                              return (
                                                <option
                                                  key={idx}
                                                  id={`${id}`}
                                                  selected={
                                                    `${id}` ===
                                                    planningAsignatura
                                                      .campoFormacion
                                                      ?.documentId
                                                  }
                                                  value={`${id}`}
                                                >{`${Nombre}`}</option>
                                              );
                                            }
                                          )}
                                        </>
                                      }
                                      onChange={(evt) => {
                                        evt.preventDefault();
                                        if (evt.target.value) {
                                          const result =
                                            filtros.camposFormacion.data.filter(
                                              (cF) =>
                                                `${cF.id}` === evt.target.value
                                            );
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              campoFormacion: {
                                                documentId: evt.target.value,
                                                value: result.length
                                                  ? (
                                                      result[0]
                                                        .attributes as CampoFormacion
                                                    ).Nombre
                                                  : '',
                                              },
                                              asignatura: undefined,
                                              bloque: undefined,
                                              eje: undefined,
                                              pSocial: undefined,
                                              tTexto: undefined,
                                              lArtistico: undefined,
                                              tema: undefined,
                                              aprendizajes: [],
                                              competencias: [],
                                              aprendizajesFundamentales: [],
                                            },
                                            'campoFormacion'
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {!!filteredAsignaturas.length && (
                                <div className="planning-row-form__col col-lg-6 col-12">
                                  <div className="form-input-element">
                                    <label
                                      htmlFor="asignatura"
                                      className="form-label"
                                    >
                                      Selecciona la asignatura <small>*</small>
                                    </label>
                                    <SelectComponent
                                      label={''}
                                      id="asignatura"
                                      option={
                                        <>
                                          <option value="">
                                            Seleccionar...
                                          </option>
                                          {filteredAsignaturas.map(
                                            (materia, idx) => {
                                              const { attributes, id } =
                                                materia;
                                              const { Materia } =
                                                attributes as Materia;
                                              return (
                                                <option
                                                  key={idx}
                                                  id={`${id}`}
                                                  selected={
                                                    `${id}` ===
                                                    planningAsignatura
                                                      .asignatura?.documentId
                                                  }
                                                  value={`${id}`}
                                                >{`${Materia}`}</option>
                                              );
                                            }
                                          )}
                                        </>
                                      }
                                      onChange={(evt) => {
                                        evt.preventDefault();
                                        if (evt.target.value) {
                                          setCamposad({estado: false, est: 1});
                                          const result =
                                            filtros.materias.data.filter(
                                              (s) =>
                                                `${s.id}` === evt.target.value
                                            );
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              asignatura: {
                                                documentId: evt.target.value,
                                                value: result.length
                                                  ? (
                                                      result[0]
                                                        .attributes as Materia
                                                    ).Materia
                                                  : '',
                                              },
                                              bloque: undefined,
                                              eje: undefined,
                                              pSocial: undefined,
                                              tTexto: undefined,
                                              lArtistico: undefined,
                                              tema: undefined,
                                              aprendizajes: [],
                                              competencias: [],
                                              aprendizajesFundamentales: [],
                                              apClave: undefined,
                                              propPed: undefined
                                            },
                                            'asignatura'
                                          );
                                          setTimeout(() => {
                                            setCamposad({estado: false, est: 0});
                                          }, 2000);
                                        } else {
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              asignatura: undefined,
                                              bloque: undefined,
                                              eje: undefined,
                                              pSocial: undefined,
                                              tTexto: undefined,
                                              lArtistico: undefined,
                                              tema: undefined,
                                              aprendizajes: [],
                                              competencias: [],
                                              aprendizajesFundamentales: [],
                                              apClave: undefined,
                                              propPed: undefined
                                            },
                                            'description'
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {planningAsignatura.asignatura?.documentId &&
                              filteredBloques.length ? (
                                <div className="planning-row-form__col col-lg-6 col-12">
                                  <div className="form-input-element">
                                    <label
                                      htmlFor="bloque"
                                      className="form-label"
                                    >
                                      Selecciona el bloque <small>*</small>
                                    </label>
                                    <SelectComponent
                                      label={''}
                                      id="bloque"
                                      option={
                                        <>
                                          <option defaultValue="">
                                            Seleccionar...
                                          </option>
                                          {filteredBloques.map(
                                            (bloque, idx) => {
                                              const { id, attributes } = bloque;
                                              const { Bloque } = attributes;
                                              return (
                                                <option
                                                  key={idx}
                                                  id={`${id}`}
                                                  selected={
                                                    `${id}` ===
                                                    planningAsignatura.bloque
                                                      ?.documentId
                                                  }
                                                  value={`${id}`}
                                                >{`${Bloque}`}</option>
                                              );
                                            }
                                          )}
                                        </>
                                      }
                                      onChange={(evt) => {
                                        evt.preventDefault();
                                        if (evt.target.value) {
                                          const result = filteredBloques.filter(
                                            (s) =>
                                              `${s.id}` === evt.target.value
                                          );
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              bloque: {
                                                documentId: evt.target.value,
                                                value: result.length
                                                  ? result[0].attributes.Bloque
                                                  : '',
                                              },
                                              eje: undefined,
                                              pSocial: undefined,
                                              tTexto: undefined,
                                              lArtistico: undefined,
                                              tema: undefined,
                                              aprendizajes: [],
                                              competencias: [],
                                              aprendizajesFundamentales: [],
                                            },
                                            'bloque'
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="planning-row-form">
                          <div className="row">
                            {planningAsignatura.asignatura?.documentId &&
                            filteredEjes.length ? (
                              <div className="planning-row-form__col col-lg-6 col-12">
                                <div className="form-input-element">
                                  <label htmlFor="eje" className="form-label">
                                    Selecciona un eje <small>*</small>
                                  </label>
                                  <SelectComponent
                                    label={''}
                                    id="eje"
                                    option={
                                      <>
                                        <option defaultValue="">
                                          Seleccionar...
                                        </option>
                                        {filteredEjes.map((bloque, idx) => {
                                          const { id, attributes } = bloque;
                                          const { Nombre } = attributes;
                                          return (
                                            <option
                                              key={idx}
                                              id={`${id}`}
                                              selected={
                                                `${id}` ===
                                                planningAsignatura.eje
                                                  ?.documentId
                                              }
                                              value={`${id}`}
                                            >{`${Nombre}`}</option>
                                          );
                                        })}
                                      </>
                                    }
                                    onChange={(evt) => {
                                      evt.preventDefault();
                                      if (evt.target.value) {
                                        const result = filteredEjes.filter(
                                          (s) => `${s.id}` === evt.target.value
                                        );
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            eje: {
                                              documentId: evt.target.value,
                                              value: result.length
                                                ? result[0].attributes.Nombre
                                                : '',
                                            },
                                            pSocial: undefined,
                                            tTexto: undefined,
                                            lArtistico: undefined,
                                            tema: undefined,
                                            aprendizajes: [],
                                            competencias: [],
                                            aprendizajesFundamentales: [],
                                          },
                                          'eje'
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {!!filteredTemas.length && (
                              <div className="planning-row-form__col col-lg-6 col-12">
                                <div className="form-input-element">
                                  <label htmlFor="tema" className="form-label">
                                    Selecciona un tema <small>*</small>
                                  </label>
                                  <SelectComponent
                                    label={''}
                                    id="tema"
                                    option={
                                      <>
                                        <option defaultValue="">
                                          Seleccionar...
                                        </option>
                                        {filteredTemas.map((bloque, idx) => {
                                          const { id, attributes } = bloque;
                                          const { Nombre } = attributes;
                                          return (
                                            <option
                                              key={idx}
                                              id={`${id}`}
                                              selected={
                                                `${id}` ===
                                                planningAsignatura.tema
                                                  ?.documentId
                                              }
                                              value={`${id}`}
                                            >{`${Nombre}`}</option>
                                          );
                                        })}
                                      </>
                                    }
                                    onChange={(evt) => {
                                      evt.preventDefault();
                                      if (evt.target.value) {
                                        const result = filteredTemas.filter(
                                          (s) => `${s.id}` === evt.target.value
                                        );
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            tema: {
                                              documentId: evt.target.value,
                                              value: result.length
                                                ? result[0].attributes.Nombre
                                                : '',
                                            },
                                            lArtistico: undefined,
                                            aprendizajes: [],
                                            competencias: [],
                                            aprendizajesFundamentales: [],
                                          },
                                          'tema'
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            {!!filteredPSociales.length && (
                              <div className="planning-row-form__col col-lg-6 col-12">
                                <div className="form-input-element">
                                  <label htmlFor="tema" className="form-label">
                                    Selecciona un práctica social del lenguaje{' '}
                                    <small>*</small>
                                  </label>
                                  <SelectComponent
                                    label={''}
                                    id="tema"
                                    option={
                                      <>
                                        <option defaultValue="">
                                          Seleccionar...
                                        </option>
                                        {filteredPSociales.map(
                                          (pSocial, idx) => {
                                            const { id, attributes } = pSocial;
                                            const { Nombre } = attributes;
                                            return (
                                              <option
                                                key={idx}
                                                id={`${id}`}
                                                selected={
                                                  `${id}` ===
                                                  planningAsignatura.pSocial
                                                    ?.documentId
                                                }
                                                value={`${id}`}
                                              >{`${Nombre}`}</option>
                                            );
                                          }
                                        )}
                                      </>
                                    }
                                    onChange={(evt) => {
                                      evt.preventDefault();
                                      if (evt.target.value) {
                                        const result = filteredPSociales.filter(
                                          (s) => `${s.id}` === evt.target.value
                                        );
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            pSocial: {
                                              documentId: evt.target.value,
                                              value: result.length
                                                ? result[0].attributes.Nombre
                                                : '',
                                            },
                                            tema: undefined,
                                            aprendizajes: [],
                                            competencias: [],
                                            aprendizajesFundamentales: [],
                                          },
                                          'pSocial'
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {!!filteredTipoTextos.length && (
                          <div className="planning-row-form">
                            <div className="row">
                              <div className="planning-row-form__col col-lg-6 col-12">
                                <div className="form-input-element">
                                  <label htmlFor="tema" className="form-label">
                                    Selecciona un tipo de texto <small>*</small>
                                  </label>
                                  <SelectComponent
                                    label={''}
                                    id="tipoTexto"
                                    option={
                                      <>
                                        <option defaultValue="">
                                          Seleccionar...
                                        </option>
                                        {filteredTipoTextos.map(
                                          (pSocial, idx) => {
                                            const { id, attributes } = pSocial;
                                            const { Nombre } = attributes;
                                            return (
                                              <option
                                                key={idx}
                                                id={`${id}`}
                                                selected={
                                                  `${id}` ===
                                                  planningAsignatura.tTexto
                                                    ?.documentId
                                                }
                                                value={`${id}`}
                                              >{`${Nombre}`}</option>
                                            );
                                          }
                                        )}
                                      </>
                                    }
                                    onChange={(evt) => {
                                      evt.preventDefault();
                                      if (evt.target.value) {
                                        const result =
                                          filteredTipoTextos.filter(
                                            (s) =>
                                              `${s.id}` === evt.target.value
                                          );
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            tTexto: {
                                              documentId: evt.target.value,
                                              value: result.length
                                                ? result[0].attributes.Nombre
                                                : '',
                                            },
                                          },
                                          'tTexto'
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {!!filteredLArtistico.length && (
                          <div className="planning-row-form">
                            <div className="row">
                              <div className="planning-row-form__col col-lg-6 col-12">
                                <div className="form-input-element">
                                  <label htmlFor="tema" className="form-label">
                                    Selecciona un lenguaje artístico{' '}
                                    <small>*</small>
                                  </label>
                                  <SelectComponent
                                    label={''}
                                    id="tipoTexto"
                                    option={
                                      <>
                                        <option defaultValue="">
                                          Seleccionar...
                                        </option>
                                        {filteredLArtistico.map(
                                          (pSocial, idx) => {
                                            const { id, attributes } = pSocial;
                                            const { Nombre } = attributes;
                                            return (
                                              <option
                                                key={idx}
                                                id={`${id}`}
                                                selected={
                                                  `${id}` ===
                                                  planningAsignatura.lArtistico
                                                    ?.documentId
                                                }
                                                value={`${id}`}
                                              >{`${Nombre}`}</option>
                                            );
                                          }
                                        )}
                                      </>
                                    }
                                    onChange={(evt) => {
                                      evt.preventDefault();
                                      if (evt.target.value) {
                                        const result =
                                          filteredLArtistico.filter(
                                            (s) =>
                                              `${s.id}` === evt.target.value
                                          );
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            lArtistico: {
                                              documentId: evt.target.value,
                                              value: result.length
                                                ? result[0].attributes.Nombre
                                                : '',
                                            },
                                          },
                                          'lArtistico'
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {!!filteredAprendizajes.length && (
                          <div className="planning-row-form">
                            <div className="row">
                              <div className="col-12">
                                <div className="form-input-element">
                                  <label
                                    htmlFor="aprendeizajes"
                                    className="form-label"
                                  >
                                    Selecciona los aprendizajes esperados{' '}
                                    <small>*</small>
                                    <OverlayComponent toolTipText="Marca todos los aprendizajes esperados que buscas lograr a través de esta planeación..  " />
                                  </label>
                                  {filteredAprendizajes.map((ap, idx) => {
                                    return (
                                      <CheckboxComponent
                                        key={idx}
                                        id={`aprendeizaje-${idx}`}
                                        htmlFor={`aprendeizaje-${idx}`}
                                        text={<>{`${ap.attributes.Nombre}`}</>}
                                        checked={
                                          planningAsignatura.aprendizajes
                                            ?.length
                                            ? planningAsignatura.aprendizajes.filter(
                                                (a) =>
                                                  a.documentId === `${ap.id}`
                                              ).length >= 1
                                            : false
                                        }
                                        onChangeEvent={(checked) => {
                                          const currentApE =
                                            planningAsignatura.aprendizajes ||
                                            [];
                                          if (checked) {
                                            currentApE.push({
                                              documentId: `${ap.id}`,
                                              value: ap.attributes.Nombre,
                                            });
                                          } else {
                                            const index = currentApE.findIndex(
                                              (a) => a.documentId === `${ap.id}`
                                            );
                                            currentApE.splice(index, 1);
                                          }
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              aprendizajes: currentApE,
                                            },
                                            'aprendizajes'
                                          );
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {(!!planningAsignatura.bloque?.documentId ||
                          !!planningAsignatura.eje?.documentId) &&
                          !!filteredCompetencias.length && (
                            <div className="planning-row-form">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-input-element">
                                    <label className="form-label">
                                      Competencias que se favorecen{' '}
                                      <small>*</small>
                                    </label>
                                    {filteredCompetencias.map((ap, idx) => {
                                      return (
                                        <CheckboxComponent
                                          key={idx}
                                          id={`compentencia-${idx}`}
                                          htmlFor={`compentencia-${idx}`}
                                          text={
                                            <>{`${ap.attributes.Nombre}`}</>
                                          }
                                          checked={
                                            planningAsignatura.competencias
                                              ?.length
                                              ? planningAsignatura.competencias.filter(
                                                  (a) =>
                                                    a.documentId === `${ap.id}`
                                                ).length >= 1
                                              : false
                                          }
                                          onChangeEvent={(checked) => {
                                            const currentC =
                                              planningAsignatura.competencias ||
                                              [];
                                            if (checked) {
                                              currentC.push({
                                                documentId: `${ap.id}`,
                                                value: ap.attributes.Nombre,
                                              });
                                            } else {
                                              const index = currentC.findIndex(
                                                (a) =>
                                                  a.documentId === `${ap.id}`
                                              );
                                              currentC.splice(index, 1);
                                            }
                                            updateAsignatura(
                                              {
                                                ...planningAsignatura,
                                                competencias: currentC,
                                              },
                                              'competencias'
                                            );
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {!!filteredFundamentales.length && (
                          <div className="planning-row-form">
                            <div className="row">
                              <div className="col-12">
                                <ToggleEnabledComponent
                                  updates={{
                                    currentPlanning: planning,
                                    updatePlanning,
                                    updatePlanningState,
                                    toUpdate: 'asignatura',
                                    asignatura: {
                                      asignaturaId:
                                        planningAsignatura._id || '',
                                      field: 'aprendizajesFundamentales',
                                    },
                                  }}
                                  disableField={(checked) => {
                                    if (!checked) {
                                      updateAsignatura(
                                        {
                                          ...planningAsignatura,
                                          aprendizajesFundamentales: [],
                                        },
                                        'aprendizajesFundamentales'
                                      );
                                    }
                                  }}
                                  contentTitle={
                                    <>
                                      Selecciona los aprendizajes fundamentales
                                      <OverlayComponent toolTipText="Los aprendizajes fundamentales nos indican el contenido imprescindible que los estudiantes deben dominar para lograr la adquisición de otros aprendizajes.  " />
                                    </>
                                  }
                                  contentToEnabled={
                                    <>
                                      <MultiSelectComponent
                                        id="aprendizajes-fundamentales"
                                        options={filteredFundamentales.map(
                                          (ap) => ({
                                            value: `${ap.id}`,
                                            label: ap.attributes.Nombre,
                                          })
                                        )}
                                        value={(
                                          planningAsignatura.aprendizajesFundamentales ||
                                          []
                                        ).map((aF) => ({
                                          value: aF.documentId,
                                          label: aF.value,
                                        }))}
                                        from="search"
                                        onChange={(values) => {
                                          const aprendizajesFundamentales =
                                            values || [];
                                          updateAsignatura(
                                            {
                                              ...planningAsignatura,
                                              aprendizajesFundamentales:
                                                aprendizajesFundamentales.map(
                                                  (aF) => ({
                                                    documentId: aF.value,
                                                    value: aF.label,
                                                  })
                                                ),
                                            },
                                            'aprendizajesFundamentales'
                                          );
                                        }}
                                      />
                                    </>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="planning-row-form">
                          <div className="row">
                            <div className="col-12">
                              <ToggleEnabledComponent
                                updates={{
                                  currentPlanning: planning,
                                  updatePlanning,
                                  updatePlanningState,
                                  toUpdate: 'asignatura',
                                  asignatura: {
                                    asignaturaId: planningAsignatura._id || '',
                                    field: 'description',
                                  },
                                }}
                                disableField={(checked) => {
                                  if (!checked) {
                                    updateAsignatura(
                                      {
                                        ...planningAsignatura,
                                        description: undefined,
                                      },
                                      'description'
                                    );
                                  }
                                }}
                                contentTitle={
                                  <>
                                    Descripción general de tu planeación en esta asignatura
                                    <OverlayComponent toolTipText="Añade una explicación breve de esta asignatura para ayudarte a identificar fácilmente su contenido en esta planeación  " />
                                  </>
                                }
                                contentToEnabled={
                                  <>
                                    <textarea
                                      className="form-control"
                                      value={
                                        planningAsignatura.description || ''
                                      }
                                      onChangeCapture={(event) =>
                                        updateAsignaturaState(
                                          planningAsignatura,
                                          'description',
                                          (event.target as HTMLTextAreaElement)
                                            .value
                                        )
                                      }
                                      onBlur={(event) => {
                                        event.preventDefault();
                                        updateAsignatura(
                                          {
                                            ...planningAsignatura,
                                            description: (
                                              event.target as HTMLTextAreaElement
                                            ).value,
                                          },
                                          'description'
                                        );
                                      }}
                                    ></textarea>
                                  </>
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <CamposAdicionalesComponent
                          user={user}
                          planning={planning}
                          updatePlanningState={updatePlanningState}
                          updatePlanning={updatePlanning}
                          planningAsignatura={planningAsignatura}
                          updateAsignaturaState={updateAsignaturaState}
                          updateAsignatura={updateAsignatura}
                          stateArti={camposad}
                        />
                        <SecuenciaDidacticaComponent
                          user={user}
                          pReflexion={
                            filtros.pReflexion.data as {
                              id: number;
                              attributes: PreguntaReflexion;
                            }[]
                          }
                          instEvaluacion={
                            filtros.instEvaluacion.data as {
                              id: number;
                              attributes: PreguntaReflexion;
                            }[]
                          }
                          libros={
                            filtros.libros.data.filter(
                              (b) =>
                                `${(b.attributes as Libros).grado.data.id}` ===
                                planning.gradoEscolar?.documentId
                            ) as {
                              id: number;
                              attributes: Libros;
                            }[]
                          }
                          planning={planning}
                          updatePlanningState={updatePlanningState}
                          updatePlanning={updatePlanning}
                          planningAsignatura={planningAsignatura}
                          recursos={recursos}
                          tiposRecurso={tiposRecurso}
                          updateAsignaturaState={updateAsignaturaState}
                          updateAsignatura={updateAsignatura}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <div className="mb-2 asignatura-bottom-delete">
                      <button className="btn btn--delete btn--delete-small"
                        onClick={(evt) => {
                          evt.preventDefault();
                          return Swal.fire({
                            icon: 'question',
                            title: 'Eliminar asignatura',
                            text: `¿Deseas eliminar esta asignatura de tu planeación?`,
                            showCancelButton: true,
                            confirmButtonText: 'Si, eliminar',
                            cancelButtonText: 'Cancelar',
                            showLoaderOnConfirm: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              const index = (
                                planning.asignaturas || []
                              ).findIndex(
                                (a) =>
                                  a._id === `${planningAsignatura._id}`
                              );
                              const newAsignaturas =
                                planning.asignaturas || [];
                              newAsignaturas.splice(index, 1);
                              updatePlanning({
                                ...planning,
                                asignaturas: newAsignaturas,
                              });
                            }
                          });
                        }}>
                        <span className="material-icons">delete_outline</span>
                      </button>
                    </div>
                    </>
                  );
                })}
              </Accordion>
              <div className="planning-row-form">
                <button
                  className="btn btn--type1"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (planning._id) {
                      updateAsignatura(
                        {
                          description: '',
                        },
                        'description'
                      );
                    } else {
                      Swal.fire(
                        '',
                        'Primero define el nombre y grado escolar de tu planeación',
                        'warning'
                      );
                    }
                  }}
                >
                  Agregar asignatura
                </button>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default AsignaturaComponent;
