import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ModalInfoComponent from '../../Modal/modalInfo/modalInfo.component';
import ModalSecuenciaComponent from '../../Modal/modalSecuencia/modalSecuencia.component';
import CierreSecuenciaComponent from './cierre/cierreSecuencia.component';
import DesarrolloSecuenciaComponent from './desarrollo/desarrolloSecuencia.component';
import IndexSecuenciaComponent from './index/indexSecuencia.component';
import InicioSecuenciaComponent from './Inicio/InicioSecuencia.component';
import ResumeSecuenciaComponent from './resume/resumeSecuencia.component';
import EvaluacionSecuenciaComponent from './evaluacion/EvaluacionSecuencia.component';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
  IFlexiblePlanningSession,
} from '../../../types/FlexiblePlanningInterface';
import {
  GenericResult,
  Libros,
  PreguntaReflexion,
  RecursoEducativo,
} from '../../../types/EntidadesInterface';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { IUser } from '../../../types/UserInterface';

interface Props {
  user: Partial<IUser>;
  planning: Partial<IFlexiblePlanning>;
  planningAsignatura: IFlexiblePlanningAsignatura;
  pReflexion: { id: Number; attributes: PreguntaReflexion }[];
  instEvaluacion: { id: Number; attributes: PreguntaReflexion }[];
  libros: { id: Number; attributes: Libros }[];
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
  tiposRecurso: GenericResult;
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
  updateAsignaturaState: (
    planningAsignatura: IFlexiblePlanningAsignatura,
    key: keyof IFlexiblePlanningAsignatura,
    value: any
  ) => void;
  updateAsignatura: (
    planningAsignatura: Partial<IFlexiblePlanningAsignatura>,
    key: keyof IFlexiblePlanningAsignatura
  ) => Promise<Partial<IFlexiblePlanningAsignatura>>;
}

const SecuenciaDidacticaComponent = ({
  user,
  planning,
  planningAsignatura,
  pReflexion,
  instEvaluacion,
  libros,
  recursos,
  tiposRecurso,
  updatePlanning,
  updatePlanningState,
  updateAsignaturaState,
  updateAsignatura,
}: Props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [activo, setActivo] = useState(false);
  const { asignaturaId, secuenciaId } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [modalSecuenciaShow, setModalSecuenciaShow] = useState(false);
  const [selectedSession, setSelectedSession] = useState<
    Partial<IFlexiblePlanningSession>
  >({});

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const openSecuenciaModal = (session: IFlexiblePlanningSession) => {
    setSelectedSession(session);
    setModalSecuenciaShow(true);
  };

  const updateSecuenciaState = (
    session: Partial<IFlexiblePlanningSession>,
    key: keyof IFlexiblePlanningSession,
    value: any
  ) => {
    const { _id: sessionId } = session;
    const currentPlanningAsignatura = { ...planningAsignatura };
    if (sessionId) {
      currentPlanningAsignatura.sessions?.forEach((s, idx) => {
        if (s._id === sessionId && currentPlanningAsignatura.sessions?.length) {
          currentPlanningAsignatura.sessions[idx][key] = value;
          session[key] = value;
        }
      });
      updateAsignaturaState(
        planningAsignatura,
        'sessions',
        currentPlanningAsignatura.sessions
      );
    }
    return { ...session } as Partial<IFlexiblePlanningSession>;
  };

  const updateSecuencia = async (
    session: Partial<IFlexiblePlanningSession>
  ) => {
    const { _id: sessionId } = session;
    const currentPlanningAsignatura = { ...planningAsignatura };
    if (sessionId) {
      currentPlanningAsignatura.sessions?.forEach((s, idx) => {
        if (s._id === sessionId && currentPlanningAsignatura.sessions?.length) {
          currentPlanningAsignatura.sessions[idx] = {
            ...session,
          };
        }
      });
      await updateAsignatura({ ...currentPlanningAsignatura }, 'sessions');
      return session;
    } else {
      currentPlanningAsignatura.sessions?.push({
        ...session,
        inicio: { resources: [] },
        desarrollo: { resources: [] },
        cierre: { resources: [] },
      });
      const newPlanningA = await updateAsignatura(
        { ...currentPlanningAsignatura },
        'sessions'
      );

      const newSelectedSession = newPlanningA.sessions
        ? newPlanningA.sessions[newPlanningA.sessions?.length - 1]
        : {};
      setSelectedSession(newSelectedSession);
      return newSelectedSession;
    }
  };

  const getDatesLabel = () => {
    const { dateRanges } = planningAsignatura;
    if (dateRanges) {
      const { start, end } = dateRanges;
      if (start && end) {
        const startDate = fromUnixTime(start);
        const endDate = fromUnixTime(end);
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        if (startMonth === endMonth) {
          return `Planeación del ${format(startDate, 'dd')} al ${format(
            endDate,
            "dd 'de' MMMM"
          )}`;
        } else {
          return `Planeación del ${format(
            startDate,
            "dd 'de' MMMM"
          )} al ${format(endDate, "dd 'de' MMMM")}`;
        }
      }
    }
    return 'Planeación';
  };

  useEffect(() => {
    if (planningAsignatura._id === asignaturaId) {
      planningAsignatura.sessions?.forEach((session) => {
        if (session._id === secuenciaId) {
          setSelectedSession(session);
          setModalSecuenciaShow(true);
          navigate(`/planeacion-flexible/${planning._id}`);
        }
      });
    }
  }, [planning, planningAsignatura]);

  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Crear secuencias didácticas</h4>
            <p>
              Agregar información general sobre tu secuencia didáctica: nombre,
              fecha y tiempo de aplicación, materiales que necesitas y una breve
              descripción de lo que vas a realizar. <br />
              Inicia con la descripción de las actividades a realizar.
            </p>
          </>
        }
      />
      <ModalSecuenciaComponent
        show={modalSecuenciaShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalSecuenciaShow(false)}
        click={() => setModalSecuenciaShow(false)}
        onDelete={() => {
          return Swal.fire({
            icon: 'question',
            title: 'Eliminar secuencia',
            text: `¿Deseas eliminar esta secuencia de tu asignatura?`,
            showCancelButton: true,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isConfirmed) {
              const newAsignaturas = (planning.asignaturas || []).map((a) => {
                const index = (a.sessions || []).findIndex(
                  (s) => s._id === `${selectedSession._id}`
                );
                const newSessions = a.sessions || [];
                if (index >= 0) {
                  newSessions.splice(index, 1);
                }
                return {
                  ...a,
                  sessions: newSessions,
                };
              });
              updatePlanning({
                ...planning,
                asignaturas: newAsignaturas,
              });
              setModalSecuenciaShow(false);
            }
          });
        }}
        title={
          <>
            <p>{`${getDatesLabel()}`}</p>
          </>
        }
        content={
          <>
            <h4>{`Resumen de la asignatura (${
              planningAsignatura.asignatura?.value || ''
            })`}</h4>
            <ResumeSecuenciaComponent planningAsignatura={planningAsignatura} />
            <IndexSecuenciaComponent
              user={user}
              planning={planning}
              planningAsignatura={planningAsignatura}
              session={selectedSession}
              libros={libros}
              updatePlanningState={updatePlanningState}
              updatePlanning={updatePlanning}
              updateSecuenciaState={updateSecuenciaState}
              updateSecuencia={updateSecuencia}
            />
            <div className="sessions-blocks">
              <InicioSecuenciaComponent
                user={user}
                planning={planning}
                planningAsignatura={planningAsignatura}
                session={selectedSession}
                recursos={recursos}
                tiposRecurso={tiposRecurso}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
                updateSecuenciaState={updateSecuenciaState}
                updateSecuencia={updateSecuencia}
              />
              <DesarrolloSecuenciaComponent
                user={user}
                planning={planning}
                planningAsignatura={planningAsignatura}
                session={selectedSession}
                recursos={recursos}
                tiposRecurso={tiposRecurso}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
                updateSecuenciaState={updateSecuenciaState}
                updateSecuencia={updateSecuencia}
              />
              <CierreSecuenciaComponent
                user={user}
                planning={planning}
                planningAsignatura={planningAsignatura}
                session={selectedSession}
                pReflexion={pReflexion}
                recursos={recursos}
                tiposRecurso={tiposRecurso}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
                updateSecuenciaState={updateSecuenciaState}
                updateSecuencia={updateSecuencia}
              />
              <EvaluacionSecuenciaComponent
                user={user}
                planning={planning}
                planningAsignatura={planningAsignatura}
                session={selectedSession}
                instEvaluacion={instEvaluacion}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
                updateSecuenciaState={updateSecuenciaState}
                updateSecuencia={updateSecuencia}
              />
            </div>
          </>
        }
      />

      <section className="planning-row-index">
        <Accordion
          defaultActiveKey="0"
          className="new-accordion-planning accordion--color3"
        >
          <Card>
            <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
              <div className="accordion-planning__title">
                <span className="material-icons-outlined icon">analytics</span>
                <p>
                  Secuencia Didáctica
                  <button
                    onClick={() => {
                      setModalShow(true);
                    }}
                    className="toggle-moda-tooltip"
                  >
                    <span className="material-icons-outlined">info</span>
                  </button>
                </p>
              </div>
              <CustomToggle eventKey="0">
                <span className="material-icons-outlined accordion-header__arrow">
                  keyboard_arrow_down
                </span>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="planning-placeholder">
                  <div className="planning-placeholder__top">
                    {!planningAsignatura.sessions?.length && (
                      <p className="col-12">
                        Aún no tienes una secuencia didáctica, agrégala aquí
                      </p>
                    )}
                    <button
                      className="btn  btn--type-outline btn--small"
                      onClick={(evt) => {
                        evt.preventDefault();
                        trackEvent({
                          event: 'addSecuencia',
                          page: '/planeacion-flexible',
                          planningId: planning._id,
                          asignaturaId,
                          fullPath: window.location.href,
                        });
                        updateSecuencia({
                          name: '',
                        }).then((secuencia) => {
                          const newSessions =
                            planning.disabledFields?.sessions || [];
                          newSessions.push({
                            sessionId: secuencia._id || '',
                            fields: [],
                          });
                          setModalSecuenciaShow(true);
                        });
                      }}
                    >
                      + Agregar secuencia
                    </button>
                  </div>
                  <div className="planning-placeholder__bottom">
                    {planningAsignatura.sessions?.map((session, idx) => {
                      const { name, startDate } = session;
                      return (
                        <div key={idx} className="planning-session">
                          <article>
                            <p>{`${name}`}</p>
                            {startDate && (
                              <p>
                                <small>{`${format(
                                  fromUnixTime(startDate),
                                  'dd . MMM . yyyy'
                                )}`}</small>
                              </p>
                            )}
                          </article>
                          <div className="planning-sessions__actions">
                            <button
                              className="planning-actions__btn planning-session__edit"
                              onClick={(e) => {
                                e.preventDefault();
                                openSecuenciaModal(session);
                              }}
                            >
                              <span className="material-icons-outlined">
                                edit
                              </span>
                            </button>
                            <button
                              className="planning-actions__btn planning-session__delete"
                              onClick={(evt) => {
                                evt.preventDefault();
                                return Swal.fire({
                                  icon: 'question',
                                  title: 'Eliminar secuencia',
                                  text: `¿Deseas eliminar esta secuencia de tu asignatura?`,
                                  showCancelButton: true,
                                  confirmButtonText: 'Si, eliminar',
                                  cancelButtonText: 'Cancelar',
                                  showLoaderOnConfirm: true,
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const newAsignaturas = (
                                      planning.asignaturas || []
                                    ).map((a) => {
                                      const index = (
                                        a.sessions || []
                                      ).findIndex(
                                        (s) => s._id === `${session._id}`
                                      );
                                      const newSessions = a.sessions || [];
                                      if (index >= 0) {
                                        newSessions.splice(index, 1);
                                      }
                                      return {
                                        ...a,
                                        sessions: newSessions,
                                      };
                                    });
                                    updatePlanning({
                                      ...planning,
                                      asignaturas: newAsignaturas,
                                    });
                                  }
                                });
                              }}
                            >
                              <span className="material-icons-outlined">
                                delete
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>
    </>
  );
};

export default SecuenciaDidacticaComponent;
