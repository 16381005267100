import React from 'react';
import { Link } from 'react-router-dom';
type Props = {
  name: string;
  image: any;
  content: any;
  url?: string;
  onDeleteResource: () => void;
};

const RecursoDidacticoComponent = ({
  name,
  image,
  content,
  url,
  onDeleteResource,
}: Props) => {
  return (
    <>
      <div className="session-component">
        <div className="session-element__top">
          <Link to={url || '/'}>
            <p>{name}</p>
          </Link>
        </div>
        <div className="session-element__middle">
          <button>
            <figure>
              <img src={image} alt="" />
            </figure>
          </button>
        </div>
        <div className="session-element__bottom">
          <div>{content}</div>
          <div className="session-element__delete">
            <button
              className="btn"
              onClick={(evt) => {
                evt.preventDefault();
                onDeleteResource();
              }}
            >
              Quitar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecursoDidacticoComponent;
