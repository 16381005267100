import React, { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ToggleEnabledComponent from '../../../Form/toggleEnabled/toggleEnabled.component';
import OverlayComponent from '../../../OverlayTrigger/OverlayComponent';
import MultiSelectComponent from '../../../Form/multiselect/multiselect.component';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
  IFlexiblePlanningSession,
} from '../../../../types/FlexiblePlanningInterface';
import { PreguntaReflexion } from '../../../../types/EntidadesInterface';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { IUser } from '../../../../types/UserInterface';
import { checkSessionInstruments } from '../../../../api/chatGptApi';

interface Props {
  user: Partial<IUser>;
  planning: Partial<IFlexiblePlanning>;
  planningAsignatura: Partial<IFlexiblePlanningAsignatura>;
  session: Partial<IFlexiblePlanningSession>;
  instEvaluacion: {
    id: Number;
    attributes: PreguntaReflexion;
  }[];
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
  updateSecuenciaState: (
    session: Partial<IFlexiblePlanningSession>,
    key: keyof IFlexiblePlanningSession,
    value: any
  ) => Partial<IFlexiblePlanningSession>;
  updateSecuencia: (
    session: Partial<IFlexiblePlanningSession>
  ) => Promise<IFlexiblePlanningSession>;
}

const EvaluacionSecuenciaComponent = ({
  user,
  planningAsignatura,
  session,
  instEvaluacion,
  planning,
  updatePlanning,
  updatePlanningState,
  updateSecuenciaState,
  updateSecuencia,
}: Props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [activo, setActivo] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [instrumentoId, setInstrumentoId] = useState('');
  const { evaluacion, _id: sessionsId } = session;
  const { _id: planningId } = planning;
  const { _id: asignaturaId } = planningAsignatura;

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    let disabledFields = (planning.disabledFields?.sessions || []).filter(
      (dF) => dF.sessionId === session._id
    );
    if (!disabledFields.length) {
      (planning.disabledFields?.sessions || []).push({
        sessionId: `${session._id}`,
        fields: ['evaluacion'],
        evaluacion: [
          'description',
          'instrumentoEval',
          'criterios',
          'evidencias',
          'comentarios',
          'adecuaciones',
        ],
      });
      disabledFields = [
        {
          sessionId: `${session._id}`,
          fields: ['evaluacion'],
          evaluacion: [
            'description',
            'instrumentoEval',
            'criterios',
            'evidencias',
            'comentarios',
            'adecuaciones',
          ],
        },
      ];
    }
    if (checked) {
      if (disabledFields && disabledFields.length) {
        disabledFields[0].evaluacion = [];
        disabledFields[0].fields = disabledFields[0].fields.filter(
          (f: string) => f !== 'evaluacion'
        );
        const newPlanning = updatePlanningState('disabledFields', {
          ...planning.disabledFields,
          session: (planning.disabledFields?.sessions || []).map((dF) => {
            if (dF.sessionId === session._id) {
              return {
                ...disabledFields[0],
              };
            }
            return {
              ...dF,
            };
          }),
        });
        updatePlanning({ ...newPlanning });
      }
    } else {
      updateSecuenciaState(session, 'evaluacion', {
        description: undefined,
        instrumentoEval: [],
        criterios: '',
        evidencias: '',
        comentarios: '',
        adecuaciones: '',
      });
      disabledFields = (planning.disabledFields?.sessions || [])
        .filter((dF) => dF.sessionId === session._id)
        .map((dF) => {
          dF.fields.push('evaluacion');
          return {
            ...dF,
            fields: [...new Set(dF.fields)],
            evaluacion: [
              'description',
              'instrumentoEval',
              'criterios',
              'evidencias',
              'comentarios',
              'adecuaciones',
            ],
          };
        });
    }
    const newPlanning = updatePlanningState('disabledFields', {
      ...planning.disabledFields,
      sessions: (planning.disabledFields?.sessions || []).map((dF) => {
        if (dF.sessionId === session._id) {
          return {
            ...disabledFields[0],
          };
        }
        return {
          ...dF,
        };
      }),
    });
    updatePlanning({ ...newPlanning });
  };

  const checkInstruments = () => {
    checkSessionInstruments( (sessionsId || ''), user.token)
    .then((r) => {
      const { _id: id } = r; 
      if(id){
        setInstrumentoId(id);
        console.log(r);
        setShowButton(false);
      }
    }).catch((errr) => {console.log(errr)});
  }

  useEffect(() => {
    checkInstruments();
  }, [planning]);

  return (
    <>
      <div
        className={`session-complete ${
          !planning.disabledFields?.sessions?.filter(
            (dF) =>
              dF.sessionId === session._id && (dF.evaluacion || []).length >= 6
          ).length
            ? ''
            : 'disabled'
        }`}
      >
        <Accordion defaultActiveKey="0" className="new-accordion-planning">
          <Card>
            <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
              <div className="accordion-planning__title">
                <div className="toggle-complete">
                  <div className="toggle-action">
                    <input
                      type="checkbox"
                      id="toggle"
                      checked={
                        !!!planning.disabledFields?.sessions?.filter(
                          (dF) =>
                            dF.sessionId === session._id &&
                            (dF.evaluacion || []).length >= 6
                        ).length
                      }
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <figure></figure>
                    </div>
                  </div>
                  <label>
                    <span className="material-icons-outlined">school</span>
                    Evaluación
                  </label>
                </div>
              </div>
              <CustomToggle eventKey="0">
                <span className="material-icons-outlined accordion-header__arrow">
                  keyboard_arrow_down
                </span>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'evaluacion',
                            evaluacion: 'description',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'evaluacion',
                              {
                                ...evaluacion,
                                description: '',
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Actividades a desarrollar
                            <OverlayComponent toolTipText="Puedes añadir una breve descripción sobre la evaluación o aspectos importantes a tener en cuenta. " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={evaluacion?.description || ''}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'evaluacion', {
                                  ...evaluacion,
                                  description: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  evaluacion: {
                                    ...evaluacion,
                                    description: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'evaluacion',
                            evaluacion: 'instrumentoEval',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'evaluacion',
                              {
                                ...evaluacion,
                                instrumentoEval: [],
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Instrumento de evaluación
                            <OverlayComponent toolTipText="Selecciona el instrumento con el que realizas tu evaluación  " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <MultiSelectComponent
                              from="search"
                              value={
                                evaluacion?.instrumentoEval?.length
                                  ? evaluacion.instrumentoEval.map((instE) => ({
                                      label: instE.value,
                                      value: instE.documentId,
                                    }))
                                  : []
                              }
                              options={instEvaluacion.map((instrumento) => {
                                return {
                                  label: instrumento.attributes.Nombre,
                                  value: `${instrumento.id}`,
                                };
                              })}
                              onChange={(values) => {
                                const instEvaluacionValues = values || [];
                                const secuencia = updateSecuenciaState(
                                  session,
                                  'evaluacion',
                                  {
                                    ...evaluacion,
                                    instrumentoEval: instEvaluacionValues.map(
                                      (pR) => ({
                                        documentId: pR.value,
                                        value: pR.label,
                                      })
                                    ),
                                  }
                                );
                                updateSecuencia({ ...secuencia });
                              }}
                            />
                          </>
                        }
                      />
                    </div>
                    <div className="planning-row-form__col inst col-md-6 col-12">
                      { showButton ? (
                        <>
                        <button
                          className="btn btn--type1"
                          onClick={(evt) => {
                            evt.preventDefault();
                            trackEvent({
                              event: 'clickGenerarInst',
                              page: '/planeacion-flexible',
                              planningId,
                              asignaturaId,
                              sessionsId,
                              fullPath: window.location.href,
                            });
                            navigate(`/instrumentos-de-evaluacion/${planningId}/${asignaturaId}/${sessionsId}`);
                          }}
                        >
                          Generar instrumento de evaluación
                        </button>
                        <button className="chat-button">
                          <figure>
                            <img src="/assets/images/elements/arti-happy.png " alt="Arti Chat" />
                          </figure>
                        </button>
                        </>
                      ) : (
                        <button
                          className="btn btn--type6"
                          onClick={(evt) => {
                            evt.preventDefault();
                            trackEvent({
                              event: 'showInstrument',
                              page: '/planeacion-flexible',
                              planningId,
                              asignaturaId,
                              sessionsId,
                              fullPath: window.location.href,
                            });
                            navigate(`/instrumento-detalle/${instrumentoId}`);
                          }}
                        >
                          Ver mi instrumento de evaluación
                        </button>
                      )

                      }
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'evaluacion',
                            evaluacion: 'criterios',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'evaluacion',
                              {
                                ...evaluacion,
                                criterios: '',
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Criterios de evaluación
                            <OverlayComponent toolTipText="Describe los criterios que tendrás en cuenta para evaluar a tus alumnos.  " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={evaluacion?.criterios}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'evaluacion', {
                                  ...evaluacion,
                                  criterios: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  evaluacion: {
                                    ...evaluacion,
                                    criterios: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'evaluacion',
                            evaluacion: 'evidencias',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'evaluacion',
                              {
                                ...evaluacion,
                                evidencias: '',
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Evidencias
                            <OverlayComponent toolTipText="Toma nota de las evidencias que vas a considerar para esta evaluación.   " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={evaluacion?.evidencias}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'evaluacion', {
                                  ...evaluacion,
                                  evidencias: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  evaluacion: {
                                    ...evaluacion,
                                    evidencias: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'evaluacion',
                            evaluacion: 'comentarios',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'evaluacion',
                              {
                                ...evaluacion,
                                comentarios: '',
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Comentarios adicionales
                            <OverlayComponent toolTipText="Añade comentarios sobre lo que pasó en tu evaluación o los resultados que obtuviste.   " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={evaluacion?.comentarios}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'evaluacion', {
                                  ...evaluacion,
                                  comentarios: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  evaluacion: {
                                    ...evaluacion,
                                    comentarios: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanning,
                          updatePlanningState,
                          toUpdate: 'session',
                          session: {
                            sessionId: session._id || '',
                            field: 'evaluacion',
                            evaluacion: 'adecuaciones',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            const newSession = updateSecuenciaState(
                              session,
                              'evaluacion',
                              {
                                ...evaluacion,
                                adecuaciones: '',
                              }
                            );
                            updateSecuencia(newSession);
                          }
                        }}
                        contentTitle={
                          <>
                            Adecuaciones curriculares
                            <OverlayComponent toolTipText="Describe las adecuaciones que realizaste para esta secuencia didáctica.  " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={evaluacion?.adecuaciones}
                              onChangeCapture={(event) =>
                                updateSecuenciaState(session, 'evaluacion', {
                                  ...evaluacion,
                                  adecuaciones: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                })
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updateSecuencia({
                                  ...session,
                                  evaluacion: {
                                    ...evaluacion,
                                    adecuaciones: (
                                      event.target as HTMLTextAreaElement
                                    ).value,
                                  },
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </>
  );
};

export default EvaluacionSecuenciaComponent;
