import React from 'react';
import './select.component.scss';

type Props = {
  size?: string;
  label: string;
  id: string;
  option: JSX.Element;
  value?: string;
  onChange?: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
};

const SelectComponent = ({ size, label, id, option, value, onChange }: Props) => {
  return (
    <select
      className={`form-select ${size}`}
      aria-label={label}
      id={id}
      value={value}
      onChange={onChange}
    >
      {option}
    </select>
  );
};

export default SelectComponent;
