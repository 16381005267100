import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { getUser } from '../../api/usersApi';
import { useTracking } from 'react-tracking';
import { IUser } from '../../types/UserInterface';
import Swal from 'sweetalert2';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../components/Footer/footer.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';

const NemBlog = () => {
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const webPageURL = 'https://nem.pacoteacher.com';

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    setIsLoading(true);
    trackEvent({
      event: 'pageView',
      page: '/nem',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(false);
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div>
        <section className="body-activity">
          <iframe
            src={webPageURL}
            title="Página Web Incrustada"
            width="100%" 
            height="950" 
          />
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default NemBlog;
