import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import ModalPDFComponent from '../../components/Modal/modalPDF/modalPDF.component';
import ModalIframeComponent from '../../components/Modal/modalIframe/modalIframe.component';

const HomeScreen = () => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [modalShowIframe, setModalShowIframe] = useState(false);
  const [modalShowPDF, setModalShowPDF] = useState(false);
  const [recurso, setRecurso] = useState({
    url: '',
    nombre: '',
  });
  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const tracking = (type: string) => {
    trackEvent({
      event: 'recursoHome',
      page: '/',
      fullPath: window.location.href,
      type,
    });
  };

  const trackingButton = (button: string, linkTo: string) => {
    trackEvent({
      event: 'clickBHome',
      page: '/',
      fullPath: window.location.href,
      button,
    });
    localStorage.setItem('buttonTo', linkTo);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      if (kameUserId) {
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
            }
          })
          .catch((error) => setError(error));
      }
    });
  }, []);

  return (
    <section className="app-section app--home">
      <ModalPDFComponent
        show={modalShowPDF}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowPDF(false)}
        title={recurso.nombre}
        file={recurso.url}
      />
      <ModalIframeComponent
        show={modalShowIframe}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowIframe(false)}
        title={recurso.nombre}
        src={recurso.url}
      />
      
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}

      <section className="center-welcome">
        <div className="container">
          <div className="center-welcome__card col-lg-10 col-xl-8 col-12 mx-auto">
            <div className="col-md-10 col-12">
              <h1 className="center-welcome__title text-center col-md-10 col-12 col-reset mx-auto">
                ¡Bienvenidos <span>maestras</span> y maestros!
              </h1>
              <article className="center-welcome__article text-center col-md-10 col-12 col-reset mx-auto">
                <p>
                  <span className="text-color-primary">PACO Teacher</span> es un
                  espacio creado <br />
                  especialmente para ti,{' '}
                  <span className="text-color-primary"> sin costo</span>.
                </p>
                <p className="welcome-small">
                  ¿Cómo podemos ayudarte el día de hoy?
                </p>
              </article>
            </div>
            <figure className="center-welcome__image col-md-2">
              <img
                src="/assets/images/pacomax/paco-team-complete.png"
                alt="PACO Team"
              />
            </figure>
          </div>
        </div>
      </section>

      <section className="grid-services ">
        <div className="container">
          <div className="row">
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== '' ? '/respuestas-grado' : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color1"
                onClick={() => {
                  trackingButton('respuestas', '/respuestas-grado');
                }}
              >
                <i className="icon icon--grid1"></i>
                <p>Respuestas a todos los libros SEP (incluye NEM)</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== ''
                    ? '/planeaciones/nueva-escuela-mexicana'
                    : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color2"
                onClick={() => {
                  trackingButton(
                    'plcomunidad',
                    '/planeaciones/nueva-escuela-mexicana'
                  );
                }}
              >
                <i className="icon icon--grid2"></i>
                <p>Planeaciones para descargar (incluye NEM y <span>dosificaciones</span>)</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== ''
                    ? '/crea-tu-planeacion-nem'
                    : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color2"
                onClick={() => {
                  trackingButton('planeacionNem', '/crea-tu-planeacion-nem');
                }}
              >
                <i className="icon icon--grid3"></i>
                <p className="icon-ia">Planeaciones NEM con Inteligencia Artificial</p>
                <img
                  src="/assets/images/elements/arti-icon.png"
                  alt="ARTI"
                  className="grid-button__image"
                />
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={user.email !== '' ? '/busqueda' : '/iniciar-sesion'}
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('recursos', '/busqueda');
                }}
              >
                <i className="icon icon--grid4"></i>
                <p>Recursos educativos</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== '' ? '/recursos-papalote' : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('papalote', '/recursos-papalote');
                }}
              >
                <i className="icon icon--grid6"></i>
                <p>Recursos educativos Papalote Museo del Niño</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== ''
                    ? '/estrategias-didacticas'
                    : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('estrategias', '/estrategias-didacticas');
                }}
              >
                <i className="icon icon--grid7"></i>
                <p className="icon-ia">Enseña matemáticas con PACO Max</p>
                <img
                  src="/assets/images/pacomax/paco-icon.png"
                  alt="PACO Max"
                  className="grid-button__image"
                />
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== ''
                    ? '/explicaciones-materia'
                    : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('explicaciones', '/explicaciones-materia');
                }}
              >
                <i className="icon icon--grid5"></i>
                <p>Explicaciones por tema</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== ''
                    ? '/instrumentos-de-evaluacion'
                    : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color4"
                onClick={() => {
                  trackingButton('instrumentos', '/instrumentos-de-evaluacion');
                }}
              >
                <i className="icon icon--grid8"></i>
                <p className="icon-ia">
                  Instrumentos de evaluación con Inteligencia Artificial
                </p>
                <img
                  src="/assets/images/elements/arti-icon.png"
                  alt="ARTI"
                  className="grid-button__image"
                />
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={
                  user.email !== ''
                    ? '/ponte-a-prueba-grados'
                    : '/iniciar-sesion'
                }
                className="grid-services__btn btn--color4"
                onClick={() => {
                  trackingButton('reactivos', '/ponte-a-prueba-grados');
                }}
              >
                <i className="icon icon--grid9"></i>
                <p>Reactivos para exámenes</p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="resource-section module-small">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="label-title label--color1">
                <h2>
                  Aprovecha miles de <span>recursos</span>
                </h2>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item">
                <button
                  onClick={() => {
                    setModalShowPDF(true);
                    tracking('Explicaciones');
                    setRecurso({
                      nombre:
                        '¿Cómo ordenamos números naturales de mayor a menor?',
                      url: 'https://kame-docente-website-bucket.s3.amazonaws.com/INFOGRAFIA_1_3_Como_ordenamos_numeros_naturales_de_mayor_a_menor_26fc460764.pdf',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--explain"></i>
                    <p>Explicaciones</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/explicaciones.jpg"
                      alt="Explicaciones"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item resource__item--color1">
                <button
                  onClick={() => {
                    setModalShowIframe(true);
                    tracking('Video Animado');
                    setRecurso({
                      nombre: '¿Cómo comparamos razones?',
                      url: 'https://player.vimeo.com/video/748061604?h=16c252da52&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--play"></i>
                    <p>Videos Animados</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/animados.png"
                      alt="Videos Animados"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item resource__item--color2">
                <button
                  onClick={() => {
                    setModalShowIframe(true);
                    tracking('Videojuegos');
                    setRecurso({
                      nombre: 'Invasión Extraterrestre ',
                      url: 'https://e1.disandatdev.com/Fase4/E1/J20_InvasionExtraterrestre_v2/                        ',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--videogame"></i>
                    <p>Videojuegos</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/videojuego.png"
                      alt="Videojuegos"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item resource__item--color3">
                <button
                  onClick={() => {
                    setModalShowIframe(true);
                    tracking('Videos paso a paso');
                    setRecurso({
                      nombre:
                        'El Pizarrón de Paco: Pg. 15 Lección 6 Vamos a completar',
                      url: 'https://player.vimeo.com/video/739671077?h=d51f383f5f&badge=0&autopause=0&player_id=0&app_id=58479',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--play"></i>
                    <p>Videos paso a paso</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/paso-paso.jpg"
                      alt="Videos paso a paso"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item resource__item--color3">
                <button
                  onClick={() => {
                    setModalShowPDF(true);
                    tracking('Respuestas a tareas');
                    setRecurso({
                      nombre:
                        '¿Cómo sabemos si un número natural es múltiplo de otro?',
                      url: 'https://kame-docente-website-bucket.s3.amazonaws.com/14_3_Como_sabemos_si_un_numero_natural_es_multiplo_de_otro_78450a32b7.pdf',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--list"></i>
                    <p>Respuestas a tareas</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/respuestas.jpg"
                      alt="Respuestas a tareas"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item">
                <button
                  onClick={() => {
                    setModalShowPDF(true);
                    tracking('Infografías');
                    setRecurso({
                      nombre:
                        '¿Cómo identificamos los elementos de una pirámide?',
                      url: 'https://kame-docente-website-bucket.s3.amazonaws.com/INFOGRAFIA_15_2_Como_identificamos_los_elementos_de_una_piramide_904af58f1b.pdf',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--infographic"></i>
                    <p>Infografías</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/infografias.jpg"
                      alt="Infografías"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item resource__item--color4">
                <button
                  onClick={() => {
                    setModalShowIframe(true);
                    tracking('Historias');
                    setRecurso({
                      nombre: 'Leer y comparar números decimales',
                      url: 'https://player.vimeo.com/video/731050126?h=3e74fbfb29&badge=0&autopause=0&player_id=0&app_id=58479/embed',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--camera"></i>
                    <p>Historias</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/historia.jpg"
                      alt="Historias"
                    />
                  </figure>
                </button>
              </div>
            </div>
            <div className="resource__col col-lg-4 col-md-6 col-12">
              <div className="resource__item resource__item--color2">
                <button
                  onClick={() => {
                    setModalShowPDF(true);
                    tracking('Reto de conocimiento');
                    setRecurso({
                      nombre: '¿Cómo interpretamos datos en gráficas?',
                      url: 'https://kame-docente-website-bucket.s3.amazonaws.com/INFOGRAFIA_13_2_Como_interpretamos_datos_en_graficas_7165b43474.pdf',
                    });
                  }}
                >
                  <article className="resource__item-bottom">
                    <i className="icon-item icon--checklist"></i>
                    <p>Reto de conocimiento</p>
                  </article>
                  <figure className="resource__item-image">
                    <img
                      src="/assets/images/recursos/reto.jpg"
                      alt="Reto de conocimiento"
                    />
                  </figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials-section module-small">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="label-title">
                <h2>La voz de nuestra comunidad</h2>
              </div>
            </div>
            <div className="testimonial-section__col col-12">
              <div className="testimonial-section__item">
                <article className="testimonial-section__article">
                  <p>
                    Me gusta PACO Teacher porque me ayuda a optimizar el tiempo
                    que le dedico a planificar mis clases. Me brinda una gran
                    variedad de recursos que convierten mis sesiones en una gran
                    experiencia para mis alumnos. Además, cumple con todos los
                    elementos necesarios pedagógica y administrativamente para
                    mí y mis alumnos.
                  </p>
                  <figure className="testimonial-section__photo">
                    <img
                      src="/assets/images/testimonials/emontelongo.png"
                      alt="Maestra Erika"
                    />
                  </figure>
                </article>
                <div className="testimonial-section__bottom">
                  <h6>Maestra Erika Montelongo</h6>
                  <p>Auxiliar de coordinación primaria, Sinaloa</p>
                </div>
              </div>
              <div className="testimonial-section__item">
                <article className="testimonial-section__article">
                  <p>
                    PACO Teacher cuenta con muchísimos materiales y herramientas
                    que aligeran la carga docente.
                  </p>
                  <figure className="testimonial-section__photo">
                    <img
                      src="/assets/images/testimonials/julisa-paola.png"
                      alt="Maestra JPaola"
                    />
                  </figure>
                </article>
                <div className="testimonial-section__bottom">
                  <h6>Maestra Julisa Medina</h6>
                  <p>Primaria multigrado, Jalisco</p>
                </div>
              </div>
            </div>
            <div className="testimonial-section__col col-12">
              <div className="testimonial-section__item">
                <article className="testimonial-section__article">
                  <p>
                    La plataforma es muy interactiva, te guía paso a paso para
                    elaborar una planeación de manera muy fácil, con tan solo
                    seleccionar el modelo educativo me ofrece toda la
                    información que necesito, como el campo formativo,
                    asignatura, eje, tema, aprendizajes esperados, etcétera.
                  </p>
                  <figure className="testimonial-section__photo">
                    <img
                      src="/assets/images/testimonials/alexis.png"
                      alt="Maestro Alexis"
                    />
                  </figure>
                </article>
                <div className="testimonial-section__bottom">
                  <h6>Maestro Alexis Smith</h6>
                  <p>5to de primaria, Sinaloa</p>
                </div>
              </div>
              <div className="testimonial-section__item">
                <article className="testimonial-section__article">
                  <p>
                    Es una plataforma muy fácil de usar y nos brinda la
                    oportunidad de crear nuestras propias planeaciones de manera
                    creativa, con la ayuda de múltiples materiales novedosos y
                    atractivos.
                  </p>
                  <figure className="testimonial-section__photo">
                    <img
                      src="/assets/images/testimonials/erika.png"
                      alt="Maestra Erika"
                    />
                  </figure>
                </article>
                <div className="testimonial-section__bottom">
                  <h6>Maestra Erika Ortiz</h6>
                  <p>5to de primaria, CDMX</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterComponent />
    </section>
  );
};

export default HomeScreen;
