import * as React from 'react';
import { CustomProvider, DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import esES from 'rsuite/locales/es_ES';
import 'rsuite/dist/rsuite.min.css';

type Props = {
  value: [Date, Date] | null;
  onChange: (
    value: DateRange | null,
    event: React.SyntheticEvent<Element, Event>
  ) => void;
};

const DateRangePickerComponent = ({ value, onChange }: Props) => {
  const { beforeToday, allowedMaxDays, combine} = DateRangePicker;
  return (
    <CustomProvider locale={esES}>
      <DateRangePicker
        block
        showOneCalendar
        value={value}
        ranges={[]}
        format="dd . MMMM . yy"
        locale={esES}
        disabledDate={combine ? combine(allowedMaxDays ? allowedMaxDays(30) : undefined, beforeToday ? beforeToday() : undefined) : undefined}
        placeholder="Selecciona un rango de fechas"
        onChange={onChange}
      />
    </CustomProvider>
  );
};

export default DateRangePickerComponent;
