import React from 'react';
import TextareaComponent from '../../Form/textarea/textarea.component';
import { Accordion } from 'react-bootstrap';
import './notas.component.scss';
import { IPlanningSession } from '../../../types/PlaningInterface';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
type Props = {
  session: Partial<IPlanningSession>;
  setSession: (
    key: keyof IPlanningSession,
    value: any
  ) => Partial<IPlanningSession>;
  updateSession: (newSession: Partial<IPlanningSession>) => Promise<void>;
  isSameUser: boolean;
};

const NotasComponent = ({
  session,
  setSession,
  updateSession,
  isSameUser,
}: Props) => {
  const { notes } = session;
  return (
    <Accordion.Item eventKey="5" className="session-tab">
      <Accordion.Header>
        <div className="session-tab__toggle tab--color5">
          <h6>5. Notas personales (no se comparten)</h6>
          <span className="material-icons-outlined icon">expand_more</span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="session-tab__open">
          <div className="session-tab__open-row row">
            <div className="col-12 col-reset">
              <div className="row">
                <div className="col-12">
                  <div className="form__row">
                    <label
                      htmlFor="adecuaciones"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Adecuaciones curriculares
                      </div>
                      <OverlayComponent toolTipText="Describe las adecuaciones que realizaste para esta sesión." />
                    </label>
                    <TextareaComponent
                      id="adecuaciones"
                      placeholder="Adecuaciones"
                      value={notes ? notes.adecuacionesC : ''}
                      readOnly={!isSameUser}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        setSession('notes', {
                          adecuacionesC:
                            (evt.target as HTMLTextAreaElement).value || '',
                          notasD: notes?.notasD || '',
                          notas: notes?.notas || '',
                        });
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = setSession('notes', {
                          adecuacionesC:
                            (evt?.target as HTMLTextAreaElement).value || '',
                          notasD: notes?.notasD || '',
                          notas: notes?.notas || '',
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="form__row">
                    <label htmlFor="notas-direccion" className="form__label">
                      Notas de la dirección
                    </label>
                    <TextareaComponent
                      id="notas-direccion"
                      placeholder="Notas de la dirección"
                      value={notes ? notes.notasD : ''}
                      readOnly={!isSameUser}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        setSession('notes', {
                          adecuacionesC: notes?.adecuacionesC || '',
                          notasD:
                            (evt.target as HTMLTextAreaElement).value || '',
                          notas: notes?.notas || '',
                        });
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = setSession('notes', {
                          adecuacionesC: notes?.adecuacionesC || '',
                          notasD:
                            (evt?.target as HTMLTextAreaElement).value || '',
                          notas: notes?.notas || '',
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="form__row">
                    <label
                      htmlFor="notas"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Notas personales{' '}
                        <small className="color-gray">(opcional)</small>
                      </div>
                      <OverlayComponent toolTipText="Añade otros comentarios sobre tu clase o alguna situación relacionada." />
                    </label>
                    <TextareaComponent
                      id="notas"
                      placeholder="Notas"
                      value={notes ? notes.notas : ''}
                      readOnly={!isSameUser}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        setSession('notes', {
                          adecuacionesC: notes?.adecuacionesC || '',
                          notasD: notes?.notasD || '',
                          notas:
                            (evt.target as HTMLTextAreaElement).value || '',
                        });
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = setSession('notes', {
                          adecuacionesC: notes?.adecuacionesC || '',
                          notasD: notes?.notasD || '',
                          notas:
                            (evt?.target as HTMLTextAreaElement).value || '',
                        });
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default NotasComponent;
