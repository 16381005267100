import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import InputComponent from '../../../components/Form/input/input.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { getUser, updateUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './personalData.screen.scss';
import SelectComponent from '../../../components/Form/select/select.component';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import { Ages } from '../../../types/EntidadesInterface';

const ProfileScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [ages, setAges] = useState<{ id: number; attributes: Ages }[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const updateCurrentUser = async () => {
    try {
      if (user._id && user.token) {
        const { name, lastName, middleName, age } = user;
        const valid = [name, lastName, middleName, age].every(
          (attr) => attr && attr !== ''
        );
        console.log(user)
        if (valid) {
          const updated = await updateUser(user._id, { ...user }, user.token);
          if (updated) {
            Swal.fire(
              '¡Listo!',
              'Tu perfil ha sido actualizado',
              'success'
            ).then(() => navigate('/bienvenido'));
          }
          return updated;
        } else {
          setError('Faltan campos por llenar');
        }
      }
    } catch (error) {
      setError(error as string);
    }
  };

  const fetchAges = async () => {
    try {
      const query = {
        sort: ['id:asc']
      };
      const respAges = await getStrapiCatalog(
        qs.stringify({ ...query }), 
          '/age-brackets'
      );
      setAges(
        respAges.data as {
          id: number;
          attributes: Ages;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/mi-perfil',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        fetchAges()
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);
  return (
    <section className="app-section">
      <HeaderLoginComponent />
      <div className="app-section__top">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tab-change">
                  <Link to="/mi-perfil" className="active">
                    Mis datos personales
                  </Link>
                  <Link to="/mi-centro-educativo">Mis centros educativos</Link>
                </div>
              </div>
            </div>
            <div className="tab-forms row">
              <div className="col-md-6 col-12 mx-auto">
                <form
                  onSubmit={(evt) => {
                    evt.preventDefault();
                    setIsLoading(true);
                    return Swal.fire({
                      icon: 'question',
                      title: 'Actualizar datos',
                      text: `¿Deseas actualizar tus datos?`,
                      showCancelButton: true,
                      confirmButtonText: 'Sí, actualizar',
                      cancelButtonText: 'Cancelar',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        updateCurrentUser();
                      } else {
                        setIsLoading(false);
                      }
                    });
                  }}
                >
                  <h3 className="form__title">Tus datos personales</h3>
                  <div className="form__row">
                    <label htmlFor="name" className="form__label">
                      Nombre (s)
                    </label>
                    <InputComponent
                      type="text"
                      id="name"
                      placeholder=""
                      value={user.name || ''}
                      onChangeCapture={(evt) =>
                        setUser({
                          ...user,
                          name: (evt?.target as HTMLInputElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="lastName" className="form__label">
                      Apellido paterno
                    </label>
                    <InputComponent
                      type="text"
                      id="lastName"
                      placeholder=""
                      value={user.lastName || ''}
                      onChangeCapture={(evt) =>
                        setUser({
                          ...user,
                          lastName:
                            (evt?.target as HTMLInputElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="lastNameTwo" className="form__label">
                      Apellido materno
                    </label>
                    <InputComponent
                      type="text"
                      id="lastNameTwo"
                      placeholder=""
                      value={user.middleName || ''}
                      onChangeCapture={(evt) =>
                        setUser({
                          ...user,
                          middleName:
                            (evt?.target as HTMLInputElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="form__row">
                    <label
                      htmlFor="alias"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">Alias&nbsp;</div>
                      <small className="color-gray">(opcional)</small>
                      <OverlayComponent toolTipText="Así serás reconocido ante la comunidad de PACO Teacher" />
                    </label>
                    <InputComponent
                      type="text"
                      id="alias"
                      placeholder=""
                      value={user.alias || ''}
                      onChangeCapture={(event) => {
                        event?.preventDefault();
                        setUser({
                          ...user,
                          alias:
                            (event?.target as HTMLTextAreaElement).value || '',
                        });
                      }}
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="email" className="form__label">
                      Correo electrónico
                    </label>
                    <InputComponent
                      type="text"
                      id="email"
                      readOnly={true}
                      placeholder=""
                      value={user.email || ''}
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="phone" className="form__label">
                      Teléfono
                    </label>
                    <InputComponent
                      type="tel"
                      id="phone"
                      placeholder="55 1234 5678"
                      value={user.phone || ''}
                      onChangeCapture={(evt) =>
                        setUser({
                          ...user,
                          phone: (evt?.target as HTMLInputElement).value || '',
                        })
                      }
                    />
                  </div>
                  <div className="form__row">
                    <label htmlFor="age" className="form__label">
                      Rango de edad
                    </label>
                    <SelectComponent
                      label="Edad"
                      id="age"
                      value={user.age?.documentId || ''}
                      option={
                        <>
                        <option defaultValue="" hidden>Seleccionar...</option>
                          {ages?.map((e, k) => {
                            return (
                              <option
                                key={k}
                                value={`${e.id}`}
                              >{`${e.attributes.Age}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        const age = ages.filter(
                          (m) => `${m.id}` === evt.target.value
                        );
                        setUser({
                          ...user,
                          age: {
                            documentId: evt.target.value,
                            value: age.length
                              ? age[0].attributes.Age
                              : '',
                          }
                        })
                      }}
                    />
                  </div>
                  <div className="form__row form__action mt32">
                    <button
                      type="submit"
                      className="btn btn--type3"
                      disabled={isLoading}
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default ProfileScreen;
