import React from 'react';
import { Link } from 'react-router-dom';
import './sessionBlock.component.scss';

type Properties = {
  title: string;
  date: string;
  time: string;
  edit?: string;
  url?: string;
  detail?: string;
  onDelete?: () => void;
};

const SessionBlockComponent = ({
  title,
  date,
  time,
  edit,
  url,
  detail,
  onDelete,
}: Properties) => {
  return (
    <div className="session-block">
      <h6>{title}</h6>
      {edit !== '' ? (
        <button
          className="session-block__delete"
          onClick={(evt) => {
            evt.preventDefault();
            if (onDelete) {
              onDelete();
            }
          }}
        >
          <span className="material-icons-outlined icon">delete</span>
        </button>
      ) : (
        <></>
      )}
      <div className="session-block__info">
        <div className="session-block__info-item">
          <span className="material-icons-outlined">calendar_today</span>
          <p>{date}</p>
        </div>
        <div className="session-block__info-item">
          <span className="material-icons-outlined">watch_later</span>
          <p>{time}</p>
        </div>
      </div>
      {edit && (
        <>
          <Link to={url || ''} className="btn--simple-icon">
            <span>{edit}</span>{' '}
            <span className="material-icons-outlined icon">edit</span>
          </Link>
        </>
      )}
      {detail && (
        <Link to={url || ''} className="btn--simple-icon">
          <span>{detail}</span>
        </Link>
      )}
    </div>
  );
};

export default SessionBlockComponent;
