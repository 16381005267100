import React from 'react';
import { Link } from 'react-router-dom';
import LikeComponent from '../../Like/like.component';
import './materialAdded.component.scss';

type Props = {
  url: string;
  image: string;
  alt: string;
  deleteLabel?: string;
  add?: string;
  type: string;
  name: string;
  aprendizaje: string;
  pacomax?: string;
  fuente: string;
  time?: string;
  myFavorite?: number;
  allFavorites?: number;
  onCheckFavorite?: (
    event: React.FormEvent<HTMLInputElement> | undefined
  ) => void;
  onClickAdd?: () => void;
  onClickDelete?: () => void;
};

const MaterialItemAddedComponent = ({
  url,
  image,
  alt,
  deleteLabel,
  add,
  type,
  name,
  pacomax,
  fuente,
  myFavorite,
  allFavorites,
  onCheckFavorite,
  onClickAdd,
  onClickDelete,
}: Props) => {
  return (
    <div className="material-added">
      <div className="row">
        <div className="material-added__col-left col-md-4 col-sm-3 col-4">
          <div className="material-added__image">
            <figure>
              <Link to={url}>
                <img src={image} alt={`${alt}`} />
              </Link>
            </figure>
            <LikeComponent
              totalFavs={allFavorites || 0}
              checked={!!myFavorite}
              onCheck={onCheckFavorite}
            />
          </div>
        </div>
        <div className="material-added__col-rigth col-md-8 col-sm-9 col-8">
          {deleteLabel && (
            <button className="material-added__delete" onClick={onClickDelete}>
              <span className="material-icons-outlined">{deleteLabel}</span>
            </button>
          )}
          {add && (
            <button className="material-added__add" onClick={onClickAdd}>
              <span className="material-icons-outlined">{add}</span>
            </button>
          )}
          <Link to={url}>
            <div className="material-added__text">
              <p>{type}</p>
              <h4>{name}</h4>
            </div>
            {/* <div className="material-added__text">
              <p>Aprendizajes esperados</p>
              <h4>{aprendizaje}</h4>
            </div> */}
            <div className="material-added__text">
              <p>Fuente</p>

              <h4 className={pacomax}>{
                (fuente != 'Paco Max') ? (
                 <>
                  {fuente}<span className="material-icons">north_east</span>
                 </>
                  ) : fuente
                }
              </h4>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MaterialItemAddedComponent;
