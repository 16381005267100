import {
  BookAnswerQueryResult,
  BookExplanationTopicResult,
  BookIdResult,
  BookQueryResult,
  BookSectionQueryResult,
  PecExamData,
} from '../types/PECInterface';
import api from './index';

export const getPecBook = async (query: string) => {
  console.log('------ getPecBook ------');
  const response = await api.get<BookQueryResult>(`/pec-books?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPecBookById = async (id: string) => {
  console.log('------ getPecBookById ------');
  const response = await api.get<BookIdResult>(`/pec-books/${id}?populate=*`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPecBookSection = async (query: string) => {
  console.log('------ getPecBookSection ------');
  const response = await api.get<BookSectionQueryResult>(
    `/pec-book-sections?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPecBookAnswer = async (query: string) => {
  console.log('------ getPecBookAnswer ------');
  const response = await api.get<BookAnswerQueryResult>(
    `/pec-book-answers?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPecExplanationTopic = async (query: string) => {
  console.log('------ getPecExplanationTopic ------');
  const response = await api.get<BookExplanationTopicResult>(
    `/pec-explanation-topics?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPecExam = async (query: string) => {
  console.log('------ getPecTypeExam ------');
  const response = await api.get<PecExamData>(`/pec-exams?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};