import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import TextareaComponent from '../../../components/Form/textarea/textarea.component';
import StepperPlanningComponent from '../../../components/StepperPlanning/stepperPlanning.component';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import './planningTwo.screen.scss';
import { getUser } from '../../../api/usersApi';
import { getPlanning, updatePlanning } from '../../../api/planningsApi';
import { IUser } from '../../../types/UserInterface';
import { IPlanning } from '../../../types/PlaningInterface';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import DateRangePickerComponent from '../../../components/Dates/DateRangePicker.component';
import { format, fromUnixTime, getUnixTime } from 'date-fns';

const PlanningTwoScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { id: planningId } = useParams();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IPlanning>>({});

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const updatePlanningName = () => {
    Swal.fire({
      icon: 'info',
      title: 'Editar planeación',
      text: 'Ingresa el nombre de tu planeación',
      input: 'text',
      inputValue: planning.name || '',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (newName: string) => {
        if (!newName || newName.trim() === '') {
          throw new Error('El nombre de tu planeación no puede estar vacío');
        }
        return updatePlanning(
          planningId || '',
          { name: newName },
          `${user.token}`
        )
          .then(() => {
            setPlanning({
              ...planning,
              name: newName,
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Listo', 'Tu planeación se ha actualizado', 'success');
      }
    });
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-campos',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser && planningId) {
              setUser({ ...kameUser, token: jwtToken });
              getPlanning(planningId, jwtToken)
                .then((resp) => {
                  setPlanning(resp);
                })
                .catch((error) => setError(error));
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <section className="app-section">
      <ModalTooltipComponent
        title="Definir planeación"
        description="Agrega información de la planeación como la descripción general, los aprendizajes clave que trabajarás en la o las sesiones de esta planeación y la información sobre los propósitos pedagógicos y el contexto del aula de tu clase. Recuerda que estos campos son opcionales de acuerdo con lo que necesites especificar de tu planeación. "
      />
      <HeaderLoginComponent />
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/planeacion-aprendizajes/${planning._id}`}
          back="Regresar"
          x
          urlSecond="/mis-planeaciones"
          urlSecondText="Mis planeaciones guardadas"
          urlThird={`/planeacion-campos/${planningId}`}
          urlThirdText={`${planning.name || ''}`}
        />
        <div className="result-block__wrapper">
          <div className="result-block container">
            <div className="planning__row row m-0">
              <div className="result-block__planning col-lg-7 col-md-10 col-12 mx-auto">
                <StepperPlanningComponent
                  status={3}
                  planningId={planningId}
                  canGoNext={Object.keys(planning.userCCT || {}).length > 0}
                />
              </div>
              <div className="planning__col-left col-md-6 col-12 col-reset">
                <div className="result-block__row row">
                  <div className="result-block__title col-12">
                    <div className="btn--simple-icon btn--edition">
                      <h1>{`${planning.name || ''}`}</h1>
                      <span
                          className="material-icons-outlined icon"
                          onClick={(evt) => {
                            evt.preventDefault();
                            updatePlanningName();
                          }}
                        >
                          edit
                        </span>
                    </div>
                  </div>
                </div>
                <div className="result-block__row row">
                  <div className="result-block__col col-md-6 col-12">
                    <p>Autor</p>
                    <h4>
                      <strong>{`${planning.autor || ''}`}</strong>
                    </h4>
                  </div>
                  <div className="result-block__col col-md-6 col-12">
                    <p>Reforma</p>
                    <h4>
                      <strong>{`${
                        planning.modeloEducativo?.value || ''
                      }`}</strong>
                    </h4>
                  </div>
                </div>
                <div className="result-block__row col-12">
                <div className="session-info">
                  <p>La planeación se guarda automáticamente</p>
                    {planning.updatedAt ? (
                      <p>
                        Última actualización:{' '}
                        <strong>
                          {`${format(
                            new Date(planning.updatedAt),
                            'dd . MMM . yyyy - HH:mm aaa'
                          )}`}
                        </strong>
                      </p>
                    ) : (
                      <p></p>
                    )}
                </div>
              </div>
                <div className="result-block__row row">
                  <div className="result-block__col col-12">
                    <div className="form__row">
                      <label
                        htmlFor="aprendizaje"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">
                          Descripción de tu planeación{' '}
                          <small className="color-gray">(opcional)</small>
                        </div>
                        <OverlayComponent toolTipText="Añade una explicación breve de tu planeación para ayudarte a identificar fácilmente su contenido" />
                      </label>
                      <TextareaComponent
                        id="description"
                        value={planning.description || ''}
                        onChangeCapture={(evt) => {
                          evt.preventDefault();
                          // Add Class item on selected
                          let Item = evt?.target as HTMLTextAreaElement;
                          if (
                            (
                              evt?.target as HTMLTextAreaElement
                            ).value.trim() !== ''
                          ) {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlanning({
                            ...planning,
                            description:
                              (evt.target as HTMLTextAreaElement).value || '',
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="result-block__row row">
                  <div className="result-block__col col-md6-6">
                    <div className="form__row">
                      <label
                        htmlFor="aprendizaje"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">
                          Periodo de tiempo
                          <small className="color-gray"> (opcional)</small>
                        </div>
                        <OverlayComponent toolTipText="Elige si el periodo de tiempo de tu planeación será por día, semana o quincena" />
                      </label>
                      <select
                        className={`form-select`}
                        aria-label="Periodo de tiempo"
                        id="period"
                        value={planning.period || ''}
                        onChange={(evt) => {
                          // Add Class item on selected
                          let Item = evt.target;
                          if (evt.target.value !== 'Seleccionar...') {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlanning({
                            ...planning,
                            period: evt.target.value,
                          });
                        }}
                      >
                        <option defaultValue="">Seleccionar...</option>
                        <option value="Día">Día</option>
                        <option value="Semana">Semana</option>
                        <option value="Quincena">Quincena</option>
                      </select>
                    </div>
                  </div>
                  <div className="result-block__col col-md6-6">
                    <div className="form__row">
                      <label
                        htmlFor="aprendizaje"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">
                          Rango de fechas
                          <small className="color-gray"> (opcional)</small>
                        </div>
                        <OverlayComponent toolTipText="Elige el rango de fechas que abarcará tu planeación" />
                      </label>
                      <DateRangePickerComponent
                        value={
                          planning.dateRanges &&
                          planning.dateRanges.start > 0 &&
                          planning.dateRanges.end > 0
                            ? [
                                fromUnixTime(planning.dateRanges.start),
                                fromUnixTime(planning.dateRanges.end),
                              ]
                            : null
                        }
                        onChange={(value, event) => {
                          event.preventDefault();
                          if (value) {
                            setPlanning({
                              ...planning,
                              dateRanges: {
                                start: getUnixTime(value[0]),
                                end: getUnixTime(value[1]),
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="result-block__row row">
                  <div className="result-block__col col-12">
                    <div className="form__row">
                      <label
                        htmlFor="aprendizaje"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">
                          Aprendizaje clave{' '}
                          <small className="color-gray">(opcional)</small>
                        </div>
                        <OverlayComponent toolTipText="Enlista los conceptos o habilidades específicas en los que se centrará esta sesión de tu planeación." />
                      </label>
                      <TextareaComponent
                        id="aprendizaje"
                        value={planning.apClave || ''}
                        onChangeCapture={(evt) => {
                          evt.preventDefault();
                          // Add Class item on selected
                          let Item = evt.target as HTMLTextAreaElement;
                          if (
                            (evt.target as HTMLTextAreaElement).value !==
                            'Seleccionar...'
                          ) {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlanning({
                            ...planning,
                            apClave:
                              (evt.target as HTMLTextAreaElement).value || '',
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="result-block__row row">
                  <div className="result-block__col col-12">
                    <div className="form__row">
                      <label
                        htmlFor="propositos"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">
                          Propósitos pedagógicos{' '}
                          <small className="color-gray">(opcional)</small>
                        </div>
                        <OverlayComponent toolTipText="En este campo describe la razón de la planeación y los objetivos que buscas que tus alumnos alcancen en la sesión." />
                      </label>
                      <TextareaComponent
                        id="propositos"
                        value={planning.propPed || ''}
                        onChangeCapture={(evt) => {
                          evt.preventDefault();
                          // Add Class item on selected
                          let Item = evt.target as HTMLTextAreaElement;
                          if (
                            (evt.target as HTMLTextAreaElement).value !==
                            'Seleccionar...'
                          ) {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlanning({
                            ...planning,
                            propPed:
                              (evt.target as HTMLTextAreaElement).value || '',
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="result-block__row row">
                  <div className="result-block__col col-12">
                    <div className="form__row">
                      <label
                        htmlFor="contexto"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">
                          Contexto en el aula{' '}
                          <small className="color-gray">(opcional)</small>
                        </div>
                        <OverlayComponent toolTipText="Describe el contexto en el cual se lleva a cabo la sesión." />
                      </label>
                      <TextareaComponent
                        id="contexto"
                        value={planning.contexto || ''}
                        onChangeCapture={(evt) => {
                          evt.preventDefault();
                          // Add Class item on selected
                          let Item = evt.target as HTMLTextAreaElement;
                          if (
                            (evt.target as HTMLTextAreaElement).value !==
                            'Seleccionar...'
                          ) {
                            Item.classList.add('form-select--selected');
                          } else {
                            setTimeout(() => {
                              Item.classList.remove('form-select--selected');
                            }, 500);
                          }
                          setPlanning({
                            ...planning,
                            contexto:
                              (evt.target as HTMLTextAreaElement).value || '',
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="planning__col-aside col-md-6 col-12">
                <div className="alert alert-neutral">
                  <p>
                    Si tus estudiantes y tú tienen claro el objetivo de la
                    sesión y los aprendizajes, serán capaces de determinar si
                    estos se alcanzaron al final de la clase y tomar medidas en
                    caso de que no sea así.
                  </p>
                  <p>
                    <strong>
                      <i className="fas fa-info-circle"></i> Tip.
                    </strong>{' '}
                    No olvides realizar las adecuaciones curriculares necesarias
                    dentro de tu planeación, así todos tus alumnos podrán
                    aprovechar al máximo las actividades.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="result-block result-block__action container">
            <div className="form__row form__action">
              <button
                type="button"
                className="btn btn--type3"
                onClick={(evt) => {
                  evt.preventDefault();
                  const {
                    description,
                    period,
                    dateRanges,
                    apClave,
                    propPed,
                    contexto,
                  } = planning;
                  updatePlanning(
                    `${planning._id}`,
                    {
                      description: description || '',
                      period: period || '',
                      dateRanges: dateRanges || { start: 0, end: 0 },
                      apClave: apClave || '',
                      propPed: propPed || '',
                      contexto: contexto || '',
                    },
                    `${user.token}`
                  )
                    .then(() =>
                      navigate(`/sesiones/${planning._id || ''}`)
                    )
                    .catch((error) => setError(error));
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PlanningTwoScreen;
