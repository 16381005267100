import React from 'react';
import './checkbox.component.scss';

interface Props {
  id: string;
  htmlFor?: string;
  text: any;
  checked?: boolean;
  onChangeEvent?: (checked: boolean) => void;
}

const CheckboxComponent = ({
  id,
  htmlFor,
  text,
  checked,
  onChangeEvent,
}: Props) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={checked || false}
        id={id}
        onChange={(event) =>
          onChangeEvent ? onChangeEvent(event.target.checked) : null
        }
      />
      <label className="form-check-label" htmlFor={htmlFor || ''}>
        {text}
      </label>
    </div>
  );
};

export default CheckboxComponent;
