import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import { format } from 'date-fns';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import CheckboxComponent from '../../../components/Form/checkbox/checkbox.component';
import StepperPlanningComponent from '../../../components/StepperPlanning/stepperPlanning.component';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import './planningOne.screen.scss';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { getPlanning, updatePlanning } from '../../../api/planningsApi';
import { IPlanning } from '../../../types/PlaningInterface';
import { getAnStrapiEntry, getStrapiCatalog } from '../../../strapi/escuelas';
import {
  Aprendizaje,
  AprendizajeFundamental,
  Competencia,
  Escuela,
} from '../../../types/EntidadesInterface';
import MultiSelectComponent from '../../../components/Form/multiselect/multiselect.component';

const PlanningOneScreen = () => {
  const { trackEvent } = useTracking();
  // Tooltip sections
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [showB, setShowB] = useState(false);
  const targetB = useRef(null);

  const navigate = useNavigate();
  const { id: planningId } = useParams();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IPlanning>>({});
  const [aprendizajes, setAprendizajes] = useState<
    {
      id: number;
      attributes: Aprendizaje;
    }[]
  >([]);
  const [competencias, setCompetencias] = useState<
    {
      id: number;
      attributes: Competencia;
    }[]
  >([]);
  const [fundamentales, setFundamentales] = useState<
    {
      id: number;
      attributes: AprendizajeFundamental;
    }[]
  >([]);
  const [escuela, setEscuela] = useState<{
    id: number;
    attributes: Escuela;
  } | null>(null);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchCatalog = async (query: string, route: string) => {
    try {
      const result = await getStrapiCatalog(query, route);
      if (result.data.length) {
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return [];
  };

  const fetchEscuela = async (documentId: string) => {
    try {
      const resp = await getAnStrapiEntry('/escuelas', documentId);
      setEscuela(
        resp.data as {
          id: number;
          attributes: Escuela;
        }
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const updatePlanningName = () => {
    Swal.fire({
      icon: 'info',
      title: 'Editar planeación',
      text: 'Ingresa el nombre de tu planeación',
      input: 'text',
      inputValue: planning.name || '',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (newName: string) => {
        if (!newName || newName.trim() === '') {
          throw new Error('El nombre de tu planeación no puede estar vacío');
        }
        return updatePlanning(
          planningId || '',
          { name: newName },
          `${user.token}`
        )
          .then(() => {
            console.log(planning)
            setPlanning({
              ...planning,
              name: newName,
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Listo', 'Tu planeación se ha actualizado', 'success');
      }
    });
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-aprendizajes',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser && planningId) {
              setUser({ ...kameUser, token: jwtToken });
              getPlanning(planningId, jwtToken)
                .then((resp) => {
                  setPlanning(resp);
                  const { gradoEscolar, asignatura } = resp;
                  if ('userCCT' in resp && resp.userCCT.documentId) {
                    fetchEscuela(resp.userCCT.documentId);
                  }
                  if (gradoEscolar && asignatura) {
                    const ands: any = [
                      {
                        grado: {
                          id: { $eq: resp.gradoEscolar.documentId },
                        },
                      },
                      {
                        materia: {
                          id: { $eq: resp.asignatura.documentId },
                        },
                      },
                      {
                        modelo_educativo: {
                          id: { $eq: resp.modeloEducativo.documentId },
                        },
                      },
                    ];
                    const andsC: any[] = [
                      {
                        grados: {
                          id: { $eq: resp.gradoEscolar.documentId },
                        },
                      },
                      {
                        materia: {
                          id: { $eq: resp.asignatura.documentId },
                        },
                      },
                      {
                        modelo_educativo: {
                          id: { $eq: resp.modeloEducativo.documentId },
                        },
                      },
                    ];
                    const andsF: any = [
                      {
                        grado: {
                          id: { $eq: resp.gradoEscolar.documentId },
                        },
                      },
                      {
                        materias: {
                          id: { $eq: resp.asignatura.documentId },
                        },
                      },
                    ];
                    if (resp.eje) {
                      ands.push({
                        ejes: {
                          id: { $eq: resp.eje.documentId },
                        },
                      });
                      andsC.push({
                        ejes: {
                          id: { $eq: resp.eje.documentId },
                        },
                      });
                    }
                    if (resp.tema) {
                      ands.push({
                        temas: {
                          id: { $eq: resp.tema.documentId },
                        },
                      });
                      andsC.push({
                        temas: {
                          id: { $eq: resp.tema.documentId },
                        },
                      });
                    }
                    if (resp.bloque) {
                      ands.push({
                        bloque: {
                          id: { $eq: resp.bloque.documentId },
                        },
                      });
                    }
                    const query = {
                      sort: ['Nombre:asc'],
                      populate: '*',
                      pagination: { pageSize: 50 },
                      filters: { $and: ands },
                    };
                    const queryCompetencias = {
                      sort: ['Nombre:asc'],
                      populate: '*',
                      pagination: { pageSize: 50 },
                      filters: {
                        $and: andsC,
                      },
                    };
                    const queryFundamentales = {
                      sort: ['Nombre:asc'],
                      populate: '*',
                      pagination: { pageSize: 50 },
                      filters: {
                        $and: andsF,
                      },
                    };
                    fetchCatalog(qs.stringify(query), '/aprendizajes')
                      .then((r) =>
                        setAprendizajes(
                          r as {
                            id: number;
                            attributes: Aprendizaje;
                          }[]
                        )
                      )
                      .catch((error) => setError(error));
                    fetchCatalog(
                      qs.stringify(queryCompetencias),
                      '/competencias'
                    )
                      .then((r) =>
                        setCompetencias(
                          r as {
                            id: number;
                            attributes: Competencia;
                          }[]
                        )
                      )
                      .catch((error) => setError(error));
                    if(asignatura.value == 'Matemáticas' || asignatura.value == 'Español' || asignatura.value == 'Lengua Materna'){
                      fetchCatalog(
                        qs.stringify(queryFundamentales),
                        '/aprendizajes-fundamentales'
                      )
                        .then((r) =>
                          setFundamentales(
                            r as {
                              id: number;
                              attributes: AprendizajeFundamental;
                            }[]
                          )
                        )
                        .catch((error) => setError(error));
                    }
                  }
                })
                .catch((error) => setError(error));
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <section className="app-section">
      <ModalTooltipComponent
        title="Seleccionar aprendizajes"
        description="Selecciona los aprendizajes que serán apoyados con las actividades de esta planeación. "
      />
      <HeaderLoginComponent />
      <div className="app-section__top">
        <BreadcrumbComponent
          urlSecond="/mis-planeaciones"
          urlSecondText="Mis planeaciones guardadas"
          urlThird={`/planeacion-aprendizajes/${planning._id}`}
          urlThirdText={`${planning.name || ''}`}
        />

        <div className="result-block__wrapper">
          <div className="result-block container">
            <div className="result-block__row row">
              <div className="result-block__planning col-lg-7 col-md-10 col-12 mx-auto">
                <StepperPlanningComponent
                  status={2}
                  planningId={planningId}
                  canGoNext={
                    (planning.aprendizajes?.length || 0) > 0 &&
                    Object.keys(planning.userCCT || {}).length > 0
                  }
                />
              </div>
              <div className="result-block__title col-12">
                <div className="btn--simple-icon btn--edition">
                  <h1>{`${planning.name || ''}`}</h1>
                  <span
                    className="material-icons-outlined icon"
                    onClick={(evt) => {
                      evt.preventDefault();
                      updatePlanningName();
                    }}
                  >
                  edit
                  </span>
                </div>
              </div>
            </div>
            {escuela? (
              <div className="result-block__row row">
                <div className="result-block__col col-md-4 col-12">
                  <p>Nombre de la Institución Educativa</p>
                    <h4>
                      <strong>{`${escuela.attributes.Nombre || ''}`}</strong>
                    </h4>
                </div>
                <div className="result-block__col col-md-4 col-12">
                  <p>Clave del centro de trabajo CCT</p>
                  <h4>{`${escuela.attributes.CCT || ''}`}</h4>
                </div>
                <div className="result-block__col col-md-4 col-12">
                  <p>Localidad</p>
                  <h4>{`${escuela.attributes.Localidad || ''}`}</h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="result-block__row row">
              <div className="result-block__col col-md-4 col-12">
                <p>Autor</p>
                <h4>
                  <strong>{`${planning.autor || ''}`}</strong>
                </h4>
              </div>
              <div className="result-block__col col-md-4 col-12">
                <p>Reforma</p>
                <h4>
                  <strong>{`${planning.modeloEducativo?.value || ''}`}</strong>
                </h4>
              </div>
            </div>
            <div className="result-block__row row">
              <div className="result-block__col col-md-4 col-12">
                <p>Grado y grupo</p>
                <h4>{`${planning.gradoEscolar?.value || ''} - ${
                  planning.grupoEscolar?.value || ''
                }`}</h4>
              </div>
              {planning.campoFormacion && planning.campoFormacion.documentId ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Campo de formación académica y áreas de desarrollo</p>
                  <h4>{`${planning.campoFormacion?.value || ''}`}</h4>
                </div>
              ) : (
                <></>
              )}
              <div className="result-block__col col-md-4 col-12">
                <p>Asignatura</p>
                <h4>{`${planning.asignatura?.value}`}</h4>
              </div>
            </div>
            <div className="result-block__row row">
              {planning.bloque && planning.bloque.documentId ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Bloque</p>
                  <h4>{`${planning.bloque?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
              {planning.eje ? (
                <div className="result-block__col col-md-4 col-12">
                  {(planning.asignatura?.documentId == '15' || planning.asignatura?.documentId == '13') 
                    ? <p>Ámbito</p> : <p>Eje</p>
                  }
                  <h4>{`${planning.eje?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
              {planning.tema ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Tema</p>
                  <h4>{`${planning.tema?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="result-block__row row">
              {planning.lengart ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Lenguaje Artístico</p>
                  <h4>{`${planning.lengart?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
              {planning.tipotexto ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Tipo de texto</p>
                  <h4>{`${planning.tipotexto?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
              {planning.pslenguaje ? (
                <div className="result-block__col col-md-4 col-12">
                  <p>Practica social del lenguaje</p>
                  <h4>{`${planning.pslenguaje?.value}`}</h4>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="result-block container">
            <div className="result-block__row row">
              <div className="col-md-5 col-12">
                <div className="alert alert-neutral">
                  <p>
                    Identificar los aprendizajes previos de tus estudiantes
                    puede ayudarte a encontrar la forma más adecuada de abordar
                    un tema nuevo en clase.
                  </p>
                  <p>
                    <strong>
                      <i className="fas fa-info-circle"></i> Tip.
                    </strong>{' '}
                    Recuerda incluir actividades que desarrollen las distintas
                    formas de aprender de tus alumnos.
                  </p>
                </div>
              </div>
            </div>
            <div className="result-block__row col-12">
              <div className="session-info">
                <p>La planeación se guarda automáticamente</p>
                  {planning.updatedAt ? (
                    <p>
                      Última actualización:{' '}
                      <strong>
                        {`${format(
                          new Date(planning.updatedAt),
                          'dd . MMM . yyyy - HH:mm aaa'
                        )}`}
                      </strong>
                    </p>
                  ) : (
                    <p></p>
                  )}
              </div>
            </div>
            <div className="result-block__row row">
              {aprendizajes.length ? (
                <div className="col-md-12 col-12">
                  <h4 className="tooltip-contain">
                    <div className="tooltip-text">
                      <strong>Selecciona los aprendizajes esperados</strong>{' '}
                      <small className="required">*</small>
                    </div>

                    <button
                      className="tooltip-trigger"
                      ref={target}
                      onClick={() => setShow(!show)}
                    >
                      <span className="material-icons-outlined icon">info</span>
                    </button>
                    <Overlay
                      target={target.current}
                      show={show}
                      placement="right"
                    >
                      {(props) => (
                        <Tooltip {...props}>
                          Marca todos los aprendizajes esperados que buscas
                          lograr a través de esta planeación.
                        </Tooltip>
                      )}
                    </Overlay>
                  </h4>
                  <div className="form-check-group form-check--wrap">
                    {aprendizajes.map((e, k) => {
                      return (
                        <div className="col-12 col-reset mb-3" key={k}>
                          <CheckboxComponent
                            id={`${e.id}`}
                            htmlFor={`${e.id}`}
                            checked={
                              planning.aprendizajes?.length
                                ? planning.aprendizajes.filter(
                                    (a) => a.documentId === `${e.id}`
                                  ).length >= 1
                                : false
                            }
                            text={e.attributes.Nombre}
                            onChangeEvent={(checked) => {
                              const aP = planning.aprendizajes || [];
                              if (checked) {
                                aP.push({
                                  documentId: `${e.id}`,
                                  value: e.attributes.Nombre,
                                });
                              } else {
                                const index = aP.findIndex(
                                  (a) => a.documentId === `${e.id}`
                                );
                                aP.splice(index, 1);
                              }
                              console.log(aP);
                              setPlanning({
                                ...planning,
                                aprendizajes: aP,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {competencias.length ? (
                <div className="col-md-12 col-12">
                  <h4>
                    <strong>Competencias que se favorecen</strong>{' '}
                    <small className="required">*</small>
                  </h4>

                  <div className="form-check-group form-check--wrap">
                    {competencias.map((e, k) => {
                      return (
                        <div className="col-12 col-reset mb-3" key={k}>
                          <CheckboxComponent
                            id={`${e.id}`}
                            htmlFor={`${e.id}`}
                            text={e.attributes.Nombre}
                            checked={
                              planning.competencias?.length
                                ? planning.competencias.filter(
                                    (a) => a.documentId === `${e.id}`
                                  ).length >= 1
                                : false
                            }
                            onChangeEvent={(checked) => {
                              const comp = planning.competencias || [];
                              if (checked) {
                                comp.push({
                                  documentId: `${e.id}`,
                                  value: e.attributes.Nombre,
                                });
                              } else {
                                const index = comp.findIndex(
                                  (a) => a.documentId === `${e.id}`
                                );
                                comp.splice(index, 1);
                              }
                              setPlanning({
                                ...planning,
                                competencias: comp,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {(fundamentales && (planning.asignatura?.value == 'Matemáticas' || planning.asignatura?.value == 'Español' 
                          || planning.asignatura?.value == 'Lengua Materna') ) ? 
            <div className="result-block__row row">
              <div className="result-block__col col-md-6 col-12">
                <div className="form__row">
                  <label
                    htmlFor="learn"
                    className="form__label tooltip-contain"
                  >
                    <div className="tooltip-text">
                      Aprendizajes fundamentales{' '}
                      <small className="color-gray">(opcional)</small>
                    </div>
                    <button
                      className="tooltip-trigger"
                      ref={targetB}
                      onClick={() => setShowB(!showB)}
                    >
                      <span className="material-icons-outlined icon">info</span>
                    </button>
                    <Overlay
                      target={targetB.current}
                      show={showB}
                      placement="right"
                    >
                      {(props) => (
                        <Tooltip {...props}>
                          En caso de ser necesario, selecciona de acuerdo con el
                          Cuaderno de aprendizajes fundamentales imprescindibles
                          para Matemáticas.
                        </Tooltip>
                      )}
                    </Overlay>
                  </label>

                  <MultiSelectComponent
                    id="learn"
                    options={(fundamentales || []).map((e) => ({
                      value: `${e.id}`,
                      label: (e.attributes as any).Nombre,
                    }))}
                    value={(planning.aprendizajesFundamentales || []).map(
                      (aF) => ({
                        value: aF.documentId,
                        label: aF.value,
                      })
                    )}
                    onChange={(values) => {
                      const aprendizajesFundamentales = values || [];

                      // Add Class item on selected
                      const Item = document.getElementById('learn');
                      if (Item != null) {
                        if (values !== undefined) {
                          Item.classList.add('form-select--selected');
                        } else {
                          setTimeout(() => {
                            Item.classList.remove('form-select--selected');
                          }, 500);
                        }
                      }
                      setPlanning({
                        ...planning,
                        aprendizajesFundamentales:
                          aprendizajesFundamentales.map((aF) => ({
                            documentId: aF.value,
                            value: aF.label,
                          })),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            : <></>
            }
          </div>
          <div className="result-block result-block__action container">
            <div className="form__row form__action">
              <button
                type="button"
                className="btn btn--type3"
                onClick={(evt) => {
                  evt.preventDefault();
                  const {
                    aprendizajes,
                    competencias,
                    aprendizajesFundamentales,
                  } = planning;
                  if (
                    aprendizajesFundamentales?.length ||
                    aprendizajes?.length ||
                    competencias?.length
                  ) {
                    console.log(aprendizajesFundamentales);
                    updatePlanning(
                      `${planning._id}`,
                      {
                        aprendizajesFundamentales:
                          aprendizajesFundamentales || [],
                        aprendizajes: aprendizajes || [],
                        competencias: competencias || [],
                      },
                      `${user.token}`
                    )
                      .then(() =>
                        navigate(`/planeacion-campos/${planning._id || ''}`)
                      )
                      .catch((error) => setError(error));
                  } else {
                    Swal.fire(
                      'Oops',
                      'Selecciona los aprendizajes esperados y/o Aprendizajes fundamentales',
                      'warning'
                    );
                  }
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PlanningOneScreen;
