import { Component } from 'react';
import { withIdleTimer, IIdleTimer } from 'react-idle-timer';

interface IAppProps extends IIdleTimer {}

class IdleTimerComponent extends Component<IAppProps> {
  render() {
    return (this.props as any).children;
  }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent);
