import qs from 'qs';
import {
  EntidadResult,
  EscuelaResult,
  GenericResult,
  ModeloEducativoResult,
  SingleGenericResult,
  StrapiPlanning,
} from '../types/EntidadesInterface';
import { IPlanning } from '../types/PlaningInterface';
import api from './index';
import { IFlexiblePlanning } from '../types/FlexiblePlanningInterface';

export const getEntidades = async () => {
  console.log('------ getEntidades ------');
  const query = qs.stringify({
    sort: ['Nombre:asc'],
    populate: '*',
    pagination: {
      pageSize: 50,
    },
  });
  const response = await api.get<EntidadResult>(`/entidades?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getMunicipios = async (entidadId: number, contains: string) => {
  console.log('------ getMunicipios ------');
  const query = qs.stringify({
    sort: ['Nombre:asc'],
    filters: {
      $and: [
        {
          Entidad: {
            id: {
              $eq: entidadId,
            },
          },
        },
        {
          Nombre: {
            $containsi: contains,
          },
        },
      ],
    },
    populate: '*',
    pagination: {
      pageSize: 100,
    },
  });
  const response = await api.get<EntidadResult>(`/municipios?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getEscuelas = async (municipioId: number, contains: string) => {
  console.log('------ getEscuelas ------');
  const ors: any[] = [
    {
      Nombre: {
        $containsi: contains,
      },
    },
    {
      CCT: {
        $containsi: contains,
      },
    },
  ];
  const ands: any[] = [];
  if (municipioId) {
    ands.push({ municipio: { id: { $eq: municipioId } } });
  }
  const query = qs.stringify({
    sort: ['Nombre:asc'],
    filters: {
      $and: ands,
      $or: ors,
    },
    populate: '*',
    pagination: {
      pageSize: 50,
    },
  });
  const response = await api.get<EscuelaResult>(`/escuelas?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getModeloEducativo = async () => {
  console.log('------ getModeloEducativo ------');
  const query = qs.stringify({
    sort: ['modelo_educativo:asc'],
    populate: '*',
    pagination: {
      pageSize: 50,
    },
  });
  const response = await api.get<ModeloEducativoResult>(
    `/modelos-educativos?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getStrapiCatalog = async (query: string, route: string) => {
  console.log('------ getStrapiCatalog ------');
  const response = await api.get<GenericResult>(`${route}?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getAnStrapiEntry = async (route: string, id: string) => {
  console.log('------ getAnStrapiEntry ------');
  const query = {
    populate: '*',
  };
  const response = await api.get<SingleGenericResult>(
    `${route}${id ? `/${id}` : ''}?${qs.stringify(query)}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const setPlaneacionStrapi = async (
  planning: IPlanning,
  tags: string
) => {
  const query = {
    filters: { planningId: planning._id },
  };

  const {
    _id: planningId,
    userId,
    name,
    autor,
    isPublic,
    asignatura,
    gradoEscolar,
  } = planning;
  const body: StrapiPlanning = {
    planningId,
    userId: `${userId}`,
    name,
    autor,
    isPublic,
    tags,
    materias: [{ id: asignatura.documentId }],
    grado: gradoEscolar.documentId,
  };
  const { data: response } = await api.get<GenericResult>(
    `/planeaciones-de-la-comunidads?${qs.stringify(query)}`
  );

  if (response && response.data.length) {
    const responseId = response.data[0].id;
    const update = await api.put<SingleGenericResult>(
      `/planeaciones-de-la-comunidads/${responseId}`,
      { data: body }
    );
    return update;
  } else {
    const newEntry = await api.post<SingleGenericResult>(
      '/planeaciones-de-la-comunidads',
      { data: body }
    );
    return newEntry;
  }
};

export const setFlexiblePlanningStrapi = async (
  planning: IFlexiblePlanning,
  tags: string
) => {
  console.log('setFlexiblePlanningStrapi', planning);
  const query = {
    filters: { planningId: planning._id },
  };
  const {
    _id: planningId,
    userId,
    name,
    autor,
    isPublic,
    asignaturas,
    gradoEscolar,
  } = planning;

  const body: StrapiPlanning = {
    planningId,
    userId: `${userId}`,
    name,
    autor: autor || '',
    isPublic,
    tags,
    materias:
      asignaturas?.map((a) => ({ id: a.asignatura?.documentId || '' })) || [],
    grado: gradoEscolar?.documentId || '',
  };

  const { data: response } = await api.get<GenericResult>(
    `/planeaciones-de-la-comunidads?${qs.stringify(query)}`
  );

  if (response && response.data.length) {
    const responseId = response.data[0].id;
    const update = await api.put<SingleGenericResult>(
      `/planeaciones-de-la-comunidads/${responseId}`,
      { data: body }
    );
    return update;
  } else {
    const newEntry = await api.post<SingleGenericResult>(
      '/planeaciones-de-la-comunidads',
      { data: body }
    );
    return newEntry;
  }
};
