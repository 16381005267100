import React from 'react';

type Properties = {
  recursoImage: string;
  recursoNombre: string;
  recursoDesc: string;
  onClick: () => void;
};

const ResourceStrategyComponent = ({
  recursoImage,
  recursoNombre,
  recursoDesc,
  onClick,
}: Properties) => {

  return (
    <div className="strategy-asset">
      <div className="row">
        <div className="col-lg-2 col-12">
          <figure className="strategy-asset__image">
            <img src={recursoImage} alt="Imagen de la actividad" />
          </figure>
        </div>
        <div className="col-lg-10 col-12">
          <article className="strategy-asset__article">
            <h6>{recursoNombre}</h6>
            <p>{recursoDesc}</p>
          </article>
          <button
            onClick={onClick}
            className="btn btn--type1"
          >
            Ver contenido
            <span className="material-icons-outlined icon">videocam</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResourceStrategyComponent;
