import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LikeComponent from '../../Like/like.component';
import ShareComponent from '../../Share/share.component';
import { IUser } from '../../../types/UserInterface';
import ModalWarningComponent from '../../../components/Modal/modalWarning/modalWarning.component';
import './planeacion.component.scss';

type Props = {
  type: string;
  classTitle: string;
  plan: string;
  grade: string;
  url?: string;
  totalFavs?: number;
  totalShare?: number;
  checked?: boolean;
  disabled?: boolean;
  hide?: boolean;
  hideShare?: boolean;
  user: Partial<IUser>;
  authorId?: string;
  onCheck?: (event: React.FormEvent<HTMLInputElement> | undefined) => void;
  onCheckShare?: (event: React.FormEvent<HTMLInputElement> | undefined) => void;
  aprendizajes?: string;
  asignaturas?: string;
};

const PlaneacionItemComponent = ({
  type,
  classTitle,
  plan,
  grade,
  url,
  totalFavs,
  totalShare,
  checked,
  disabled,
  hide,
  hideShare,
  onCheck,
  onCheckShare,
  aprendizajes,
  asignaturas,
  user,
  authorId,
}: Props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="plan-item" data-type={type}>
      <ModalWarningComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        type="alert"
        title="Registrate a PACO Teacher"
        target=""
        content={
          <>
            <p>
              Para tener acceso a este y más recursos. 
            </p>
          </>
        }
        url={`/registro`}
        urlText="Regístrate"
      />
      <div className="plan-item__inner">
        <div className="plan-item__wrapp">
          <div className="plan-item__title">
            <p className="plan-item__extrafont">{classTitle}</p>
            <h4>{plan}</h4>
          </div>
          { authorId === '64224357b047f04f05387250' || 
            authorId === '63dd9d219dea3ee5641f6741' || 
            authorId === '64220d26b047f04f05386503' ? (
            <div className="plan-item__grade pteacher">
              <p className="plan-item__extrafont">Por PACO Teacher</p>
            </div>
          ) : <></>
          }
          <div className="plan-item__grade">
            <p className="plan-item__extrafont">Grado</p>
            <p>{grade}</p>
          </div>
          {aprendizajes ? (
            <div className="plan-item__grade grade--highlight">
              <p className="plan-item__extrafont">Aprendizaje esperado</p>
              <p className="plan-item__extrainfo">{`${aprendizajes}` || ''}</p>
            </div>
          ) : (
            <></>
          )}
          {asignaturas ? (
            <div className="plan-item__grade grade--highlight">
              <p className="plan-item__extrafont">Asignaturas</p>
              <p className="plan-item__extrainfo">{`${asignaturas}` || ''}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="plan-item__bottom">
         
          {user.email !== '' ? <Link to={url || '/'} >Ver detalle</Link> :  <div className=""><Link to={url || '/'} className="plan-go">Ver detalle</Link><button onClick={() => setModalShow(true)} className="plan-open">Ver detalle</button></div>}
          
        </div>
      </div>
      <div className="liker-footer">
        {hide ? (
          <></>
        ) : (
          <LikeComponent
            totalFavs={totalFavs || 0}
            onCheck={onCheck}
            checked={checked || false}
            disabled={disabled || false}
          />
        )}
        {hideShare ? (
          <></>
        ) : (
          <ShareComponent
            totalShare={totalShare || 0}
            onCheck={onCheckShare}
            checked={!!(totalShare && totalShare > 0)}
            disabled={disabled || false}
          />
        )}
      </div>
    </div>
  );
};

export default PlaneacionItemComponent;
