import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalWarning.component.scss';



const ModalWarningComponent = (props: any) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="modal-warning"
      centered
      className="modal--warning"
      data-type={props.type}
    >
      <Modal.Header className="modal__title-icon" closeButton>
       
        <Modal.Title id="modal-warning">
          <figure className="modal__icon">
            <img
              src="/assets/images/icon/check.gif"
              alt="Success"
              className="icon-success"
            />
            <img
              src="/assets/images/icon/warning.gif"
              alt="Danger"
              className="icon-danger"
            />
            <img
              src="/assets/images/icon/alert.gif"
              alt="Alert"
              className="icon-alert"
            />
          </figure>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.title}</h4>
        <article>{props.content}</article>
      </Modal.Body>
      <Modal.Footer>
        <a href={props.url} className="btn btn--type3" target={props.target} onClick={props.click}>{props.urlText}</a>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWarningComponent;
