import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTracking } from 'react-tracking';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import './modalPDF.component.scss';

type PDF = {
  numPages: any;
};

const ModalPDFComponent = (props: any) => {
  const { trackEvent } = useTracking();
  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }: PDF) {
    setNumPages(numPages);
    const textLayers = window.document.querySelectorAll(
      '.react-pdf__Page__textContent'
    );
    textLayers.forEach((layer: any) => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  }

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (numPages && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="modal-warning"
      centered
      className="modal--game"
    >
      <Modal.Header className="modal__title-icon" closeButton>
        <Modal.Title id="modal-warning">
          <h4>{props.title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Document
          file={props.file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={'Cargando PDF...'}
        >
          <Page
            pageNumber={pageNumber}
            onClick={() => {
              pageNumber === numPages
                ? setPageNumber(1)
                : setPageNumber(pageNumber + 1);
            }}
          />
        </Document>
        {numPages && numPages > 1 ? (
          <div className='arrows-pdf'>
            <button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
              {'<'}
            </button>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= numPages}
            >
              {'>'}
            </button>
          </div>
        ) : (
          <></>
        )}
        <p>
          Página {pageNumber} de {numPages}
        </p>
        {props.download && props.fileDown ? (
          <p>
            <a
              href={props.file}
              download={`${props.fileDown.hash}.${props.fileDown.ext}`}
              target="__blank"
              onClick={() => {
                trackEvent({
                  event: 'downloadRecursoPapalote',
                  page: window.location.pathname,
                  fullPath: window.location.href,
                  recurso: props.title,
                });
              }}
            >
              Descargar archivo
            </a>
          </p>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalPDFComponent;
