import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import { IUser } from '../../types/UserInterface';
import { getUser } from '../../api/usersApi';
import { searchSignUpUser } from '../../api/sessionEventsApi';
import ModalNPSComponent from '../../components/Modal/modalNps/modalNps.component';
import ModalArtiComponent from '../../components/Modal/modalArti/modalArti.component';

const QuickDashboardScreen = () => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [modalNPSShow, setModalNPSShow] = useState(false);
  const [modalArtiShow, setModalArtiShow] = useState(false);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const trackingButton = (button: string) => {
    trackEvent({
      event: 'clickBDashboard',
      page: '/dashboard',
      button,
      fullPath: window.location.href,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({
      event: 'pageView',
      page: '/dashboard',
      fullPath: window.location.href,
    });
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              searchSignUpUser(kameUserId, jwtToken).then((userSign) => {
                if (
                  userSign &&
                  userSign.npsalready == 0 &&
                  userSign.usersignin > 2 &&
                  userSign.usersignin % 3 == 0
                ) {
                  setModalNPSShow(true);
                }
              });
              setUser({ ...kameUser, token: jwtToken });
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="app-section">
      <ModalNPSComponent
        show={modalNPSShow}
        onHide={() => setModalNPSShow(false)}
        size="sm"
        url={`/`}
      />
      <ModalArtiComponent
        show={modalArtiShow}
        onHide={() => setModalArtiShow(false)}
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}

      <section className="welcome-banner">
        <div className="container">
          <div className="row">
            <div className="welcome-banner__col col-xl-8 col-12 mx-auto">
              <figure className="welcome-banner__image">
                <img
                  src="/assets/images/pacomax/paco-children.png"
                  alt="PACO Children"
                />
              </figure>
              <article className="welcome-banner__article">
                <h1 className="h2">
                  Bienvenid@ {user.name} {user.lastName}
                </h1>
                <p>¿Qué tareas tienes hoy?</p>
              </article>
              <figure className="welcome-banner__image-absolute">
                <img src="/assets/images/pacomax/taco.png" alt="Taco" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="grid-services grid--quick">
        <div className="container">
          <div className="row">
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/respuestas-grado'}
                className="grid-services__btn btn--color1"
                onClick={() => {
                  trackingButton('respuestas');
                }}
              >
                <i className="icon icon--grid1"></i>
                <p>Respuestas a todos los libros SEP (incluye NEM)</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/planeaciones/nueva-escuela-mexicana'}
                className="grid-services__btn btn--color2"
                onClick={() => {
                  trackingButton('plcomunidad');
                }}
              >
                <i className="icon icon--grid2"></i>
                <p>Planeaciones para descargar (incluye NEM y <span>dosificaciones</span>)</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/crea-tu-planeacion-nem'}
                className="grid-services__btn btn--color2"
                onClick={() => {
                  trackingButton('planeacionNem');
                }}
              >
                <i className="icon icon--grid3"></i>
                <p className="icon-ia">
                  Planeaciones NEM con Inteligencia Artificial
                </p>
                <img
                  src="/assets/images/elements/arti-icon.png"
                  alt="ARTI"
                  className="grid-button__image"
                />
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/busqueda'}
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('recursos');
                }}
              >
                <i className="icon icon--grid4"></i>
                <p>Recursos educativos</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/recursos-papalote'}
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('papalote');
                }}
              >
                <i className="icon icon--grid6"></i>
                <p>Recursos educativos Papalote Museo del Niño</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/estrategias-didacticas'}
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('estrategias');
                }}
              >
                <i className="icon icon--grid7"></i>
                <p className="icon-ia">Enseña matemáticas con PACO Max</p>
                <img
                  src="/assets/images/pacomax/paco-icon.png"
                  alt="PACO Max"
                  className="grid-button__image"
                />
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/explicaciones-materia'}
                className="grid-services__btn btn--color3"
                onClick={() => {
                  trackingButton('explicaciones');
                }}
              >
                <i className="icon icon--grid5"></i>
                <p>Explicaciones por tema</p>
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/instrumentos-de-evaluacion'}
                className="grid-services__btn btn--color4"
                onClick={() => {
                  trackingButton('instrumentos');
                }}
              >
                <i className="icon icon--grid8"></i>
                <p className="icon-ia">
                  Instrumentos de evaluación con Inteligencia Artificial
                </p>
                <img
                  src="/assets/images/elements/arti-icon.png"
                  alt="ARTI"
                  className="grid-button__image"
                />
              </a>
            </div>
            <div className="grid-services__col col-lg-4 col-md-4 col-sm-6 col-6">
              <a
                href={'/ponte-a-prueba-grados'}
                className="grid-services__btn btn--color4"
                onClick={() => {
                  trackingButton('reactivos');
                }}
              >
                <i className="icon icon--grid9"></i>
                <p>Reactivos para exámenes</p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <FooterComponent />
    </section>
  );
};

export default QuickDashboardScreen;
