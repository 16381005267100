import React, { useEffect, useState } from 'react';
import {  useParams, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { format, fromUnixTime } from 'date-fns';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import ButtonAddComponent from '../../../components/Button/buttonAdd/buttonAdd.component';
import StepperPlanningComponent from '../../../components/StepperPlanning/stepperPlanning.component';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import './sessionInit.screen.scss';
import { getUser } from '../../../api/usersApi';
import {
  createPlanning,
  deletePlanning,
  getPlanning,
  updatePlanning,
} from '../../../api/planningsApi';
import { IUser } from '../../../types/UserInterface';
import { IPlanning, IPlanningSession } from '../../../types/PlaningInterface';
import {
  createPlanningSession,
  deletePlanningSession,
  searchPlanningSession,
} from '../../../api/planningSessionsApi';
import SessionBlockComponent from '../../../components/Cards/sessionBlock/sessionBlock.component';
import SwitchComponent from '../../../components/Form/switch/switch.component';
import PrintPlanningScreen from '../../Print/planning/printPlanning.screen';
import { Escuela, RecursoEducativo, StrapiDoc } from '../../../types/EntidadesInterface';
import { getAnStrapiEntry, getStrapiCatalog } from '../../../strapi/escuelas';
import ModalPublicPlanning from '../../Planning/modalPublicPlanning/modalPublicPlanning.component';
import JSPDF from 'jspdf';
import ModalSharePlanning from '../../Print/planning/sharePlanning.modal';
import ButtonTypeOutlineComponent from '../../../components/Button/buttonTypeOutline/buttonTypeOutline.component';

const SessionInitScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { id: planningId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [showModalPublic, setShowModalPublic] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [showModalTooltipComponent, setShowModalTooltipComponent] =
    useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IPlanning>>({});
  const [sessions, setSessions] = useState<IPlanningSession[]>([]);
  const [escuela, setEscuela] = useState<{
    id: number;
    attributes: Escuela;
  } | null>(null);
  const [showPrint, setShowPrint] = useState(false);
  const [recursos, setRecursos] = useState<
    { id: number; attributes: RecursoEducativo }[]
  >([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchEscuela = async (documentId: string) => {
    try {
      const resp = await getAnStrapiEntry('/escuelas', documentId);
      setEscuela(
        resp.data as {
          id: number;
          attributes: Escuela;
        }
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getResources = async (allResources: number[]) => {
    try {
      if (allResources.length) {
        // @ts-ignore: Unreachable code error
        const ids = [...new Set(allResources)];
        const query = {
          sort: ['id:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            id: { $in: ids },
          },
        };
        const response = await getStrapiCatalog(
          qs.stringify(query),
          '/recursos-educativos'
        );
        setRecursos(
          response.data as {
            id: number;
            attributes: RecursoEducativo;
          }[]
        );
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getPlanningSessions = async (
    _planning: IPlanning,
    jwtToken: string
  ) => {
    try {
      const response = await searchPlanningSession(
        { planningId: _planning._id },
        jwtToken
      );
      setSessions(response.docs as IPlanningSession[]);
      const ses = response.docs as IPlanningSession[];
      if (ses.length > 0 ) {
        const allR: StrapiDoc[] = [];
        ses.map((s) => {
          const { introduccion, desarrollo, cierre } = s;
          [introduccion, desarrollo, cierre].forEach((section) => {
            if (section) {
              const { resources } = section;
              if (resources && resources.length) {
                allR.push(...resources);
              }
            }
          });
        })
        if (allR.length) {
          getResources(
            allR.map((r) => Number(r.documentId))
          );
        }
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const updatePlanningName = () => {
    Swal.fire({
      icon: 'info',
      title: 'Editar planeación',
      text: 'Ingresa el nombre de tu planeación',
      input: 'text',
      inputValue: planning.name || '',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (newName: string) => {
        if (!newName || newName.trim() === '') {
          throw new Error('El nombre de tu planeación no puede estar vacío');
        }
        return updatePlanning(
          planningId || '',
          { name: newName },
          `${user.token}`
        )
          .then(() => {
            setPlanning({
              ...planning,
              name: newName,
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Listo', 'Tu planeación se ha actualizado', 'success');
      }
    });
  };

  const isSameUser = () => {
    if (user && planning) {
      const { _id: userId } = user;
      const { userId: planningUserId } = planning;
      if (planningUserId && userId !== (planningUserId as IUser)._id) {
        return false;
      }
    }
    return true;
  };

  const duplicatePlanning = async () => {
    try {
      if (planning && sessions && sessions.length) {
        const copyPlanning = { ...planning };
        delete copyPlanning._id;
        const newPlanning = await createPlanning(
          {
            ...copyPlanning,
            copiedFrom: planning._id,
            autor: `${user.alias || ''}`,
            isPublic: false,
            name: `Copia de ${copyPlanning.name || ''}`,
            userCCT: { documentId: '', value: '' },
            userId: user._id,
            createdAt: '',
          },
          `${user.token}`
        );
        if (newPlanning && newPlanning._id) {
          const newSessions = await Promise.all(
            sessions.map(async (s) => {
              const copySession = { ...s };
              delete copySession._id;
              const newSession = await createPlanningSession(
                {
                  ...copySession,
                  planningId: newPlanning._id,
                },
                `${user.token}`
              );
              return newSession;
            })
          );

          if (newPlanning && newSessions) {
            trackEvent({
              event: 'copyPlanning',
              page: '/planeacion-sesiones',
              planning: planning._id,
              newPlanning: newPlanning._id,
            });
            Swal.fire(
              '¡Listo!',
              `Se ha copiado la planeación. 
              Ahora podrás encontrarla en la sección "Mis planeaciones guardadas" y editarla. 
              Esta planeación se vinculará a los datos de tu cuenta`,
              'success'
            ).then(() => {
              navigate(`/planeacion-resultado/${newPlanning._id}`, {
                replace: true,
                state: { planningId: newPlanning._id },
              });
            });
          }

          return { newPlanning, newSessions };
        }
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-sesiones',
      planning: planningId,
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser && planningId) {
              setUser({ ...kameUser, token: jwtToken });
              getPlanning(planningId, jwtToken)
                .then((resp) => {
                  setPlanning(resp);
                  getPlanningSessions(resp, jwtToken);
                  if ('userCCT' in resp && resp.userCCT.documentId) {
                    fetchEscuela(resp.userCCT.documentId);
                  }
                })
                .catch((error) => setError(error));
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  const handlePrint = () => {
    window.print();
    trackEvent({
      event: 'planningPrint',
      page: '/planeacion-sesiones',
      planningId,
    });
    setTimeout(() => {
      setShowModalTooltipComponent(true);
    }, 300);
  };

  const handlePdf = () => {
    trackEvent({
      event: 'planningPdfDownload',
      page: '/planeacion-sesiones',
      planningId,
    });
    const data = document.getElementById('print-placeholder');
    if (data) {
      const pdf = new JSPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
      });

      pdf.setProperties({
        title: 'PACO Teacher | Planeación',
        subject: planning.name || '',
        author: planning.autor || '',
        keywords: planning.description || '',
        creator: `${user.name || ''} ${user.lastName || ''}`,
      });

      pdf.setDisplayMode(1);
      pdf.html(data, {
        callback: (pdf) => {
          const fileName = planning.name || '';
          pdf.save(fileName.toLowerCase().replace(/\s/g, '-'));
          setIsLoading(false);
          setShowPrint(false);
        },
        html2canvas: { scale: 0.42 },
        margin: [5, 10, 20, 13],
      });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {sessions.length ? (
        <ModalPublicPlanning
          planning={planning}
          sessions={sessions}
          show={showModalPublic}
          size="md"
          onHide={() => setShowModalPublic(!showModalPublic)}
          setPlanning={setPlanning}
        />
      ) : (
        <></>
      )}
      <ModalSharePlanning
        planning={planning}
        show={showModalShare}
        size="md"
        onHide={() => setShowModalShare(!showModalShare)}
      />
      {showModalTooltipComponent ? (
        <ModalTooltipComponent
          title="Ver planeación final"
          description="Genera sesiones para tu planeación y regresa a los pasos previos si consideras que hay algo que cambiar. Cuando tu planeación ya tenga sesiones podrás descargarla, imprimirla o compartirla desde aquí. "
        />
      ) : (
        <></>
      )}
      <HeaderLoginComponent />
      <div className="app-section__top">
        <BreadcrumbComponent
          url={isSameUser() ? '/mis-planeaciones' : '/planeaciones/reformas-anteriores'}
          customNavigate={() => {
            console.log('Im here');
            navigate(-1);
          }}
          back="Regresar"
          urlInit={'/'}
          urlInitText="Inicio"
          urlSecond={isSameUser() ? '/mis-planeaciones' : '/planeaciones/reformas-anteriores'}
          urlSecondText={
            isSameUser() ? 'Mis planeaciones' : 'Planeaciones para descargar'
          }
          urlThird={`/planeacion-sesiones/${planning._id}`}
          urlThirdText={`${planning.name || ''}`}
        />
        <div className="result-block__wrapper">
          <div className="result-block container">
            <div className="result-block__row row session-head">
              {sessions.length === 0 ? (
                <>
                  <div className="result-block__planning col-lg-7 col-md-10 col-12 mx-auto">
                    <StepperPlanningComponent
                      status={5}
                      planningId={planningId}
                      canGoNext={true}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="result-block__title col-12">
                <p>
                  {`${planning.asignatura?.value || ''}`} ·{' '}
                  {`${planning.gradoEscolar?.value || ''}`}{' '}
                  {`${planning.isNewCiclo ? ' · 2022-2023' : ''}`} ·{' '}
                  {`${planning.modeloEducativo?.value}`}
                </p>
                <div className="btn--simple-icon btn--edition">
                  <h1>{`${planning.name || ''}`}</h1>
                  {isSameUser() ? (
                    <span
                      className="material-icons-outlined icon"
                      onClick={(evt) => {
                        evt.preventDefault();
                        updatePlanningName();
                      }}
                    >
                      edit
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="result-block__row result-block__stick row session-options">
              {planning && planning.userId ? (
                <div className="result-block__col col-lg-4 col-md-6 col-12">
                  <p>Autor</p>
                  <h4>
                    <strong>{`${
                      planning.isAnonymous
                        ? 'Anónima'
                        : (planning.userId as IUser).alias || ''
                    }`}</strong>
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {isSameUser() && sessions && sessions.length ? (
                <>
                  <div className="result-block__col col-lg-3 col-md-6 col-12">
                    <div className="toggle-info">
                      <p>Hacer planeación pública</p>
                      <SwitchComponent
                        id="publicUrl"
                        checked={planning.isPublic}
                        onChangeEvent={(checked) => {
                          if (checked) {
                            setShowModalPublic(true);
                          } else {
                            trackEvent({
                              event: 'unPublishPlanning',
                              page: '/planeacion-sesiones',
                              isPublic: checked,
                            });
                            updatePlanning(
                              planning._id || '',
                              { isPublic: checked, isAnonymous: false },
                              `${user.token}`
                            ).then(() =>
                              setPlanning({
                                ...planning,
                                isPublic: checked,
                                isAnonymous: false,
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="result-block__col result-block__share col-lg-5 col-12">
                    <button
                      className="btn btn--action"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setShowModalShare(true);
                      }}
                    >
                      <p>Compartir</p>
                      <span className="material-icons-outlined">share</span>
                    </button>
                    <button
                      className="btn btn--action"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setShowModalTooltipComponent(false);
                        setTimeout(() => {
                          handlePrint();
                        }, 300);
                      }}
                    >
                      <p>Imprimir</p>
                      <span className="material-icons-outlined">print</span>
                    </button>
                    <button
                      className="btn btn--action"
                      onClick={(evt) => {
                        evt.preventDefault();
                        setIsLoading(true);
                        setShowPrint(true);
                        setTimeout(() => {
                          handlePdf();
                        }, 200);
                      }}
                    >
                      <p>Descargar</p>
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="result-block__row row session-data">
              <div className="result-block__col col-12">
                <article>
                  <h4>
                    <strong>Información general</strong>
                  </h4>
                </article>
                <div className='result-block-pad'>
                  {isSameUser() ? (
                    <div className="result-block-pad container">
                      {escuela ? (
                        <div>
                          <div className="result-block__row row">
                            <div className="result-block__col col-md-4 col-12">
                              <p>Nombre de la Institución Educativa</p>
                              <h4>
                                <strong>{`${escuela.attributes.Nombre || ''}`}</strong>
                              </h4>
                            </div>
                            <div className="result-block__col col-md-4 col-12">
                              <p>Nombre del docente</p>
                              <h4>{`${planning.autor || ''}`}</h4>
                            </div>
                            <div className="result-block__col col-md-4 col-12">
                              <p>Clave del centro de trabajo CCT</p>
                              <h4>{`${escuela.attributes.CCT || ''}`}</h4>
                            </div>
                          </div>
                          <div className="result-block__row row">
                            <div className="result-block__col col-md-4 col-12">
                              <p>Localidad</p>
                              <h4>{`${escuela.attributes.Localidad || ''}`}</h4>
                            </div>
                            {planning.isNewCiclo ? (
                              <div className="result-block__col col-md-4 col-12">
                                <p>Ciclo escolar</p>
                                <h4>Ciclo escolar 2022 - 2023</h4>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="result-block container">
                    <div className="result-block__row row">
                      <div className="result-block__col col-md-4 col-12">
                        <p>Reforma</p>
                        <h4>
                          <strong>{`${planning.modeloEducativo?.value || ''}`}</strong>
                        </h4>
                      </div>
                      {planning.campoFormacion && planning.campoFormacion.documentId ? (
                        <div className="result-block__col col-md-4 col-12">
                          <p>Campo de formación académica y áreas de desarrollo</p>
                          <h4>{`${planning.campoFormacion?.value || ''}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="result-block__row row">
                      <div className="result-block__col col-md-4 col-12">
                        <p>Grado y grupo</p>
                        <h4>{`${planning.gradoEscolar?.value || ''} - ${
                          planning.grupoEscolar?.value || ''
                        }`}</h4>
                      </div>
                      <div className="result-block__col col-md-4 col-12">
                        <p>Asignatura</p>
                        <h4>{`${planning.asignatura?.value || ''}`}</h4>
                      </div>
                    </div>
                    <div className="result-block__row row">
                      {planning.bloque && planning.bloque.documentId ? (
                        <div className="result-block__col col-md-4 col-12">
                          <p>Bloque</p>
                          <h4>{`${planning.bloque?.value}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                      {planning.eje ? (
                        <div className="result-block__col col-md-4 col-12">
                          {(planning.asignatura?.documentId == '15' || planning.asignatura?.documentId == '13') 
                              ? <p>Ámbito</p> : <p>Eje</p>
                          }
                          <h4>{`${planning.eje?.value || ''}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                      {planning.tema ? (
                        <div className="result-block__col col-md-4 col-12">
                          <p>Tema</p>
                          <h4>{`${planning.tema?.value || ''}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="result-block__row row">
                      {planning.lengart ? (
                        <div className="result-block__col col-md-4 col-12">
                          <p>Lenguaje Artístico</p>
                          <h4>{`${planning.lengart?.value}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                      {planning.tipotexto ? (
                        <div className="result-block__col col-md-4 col-12">
                          <p>Tipo de texto</p>
                          <h4>{`${planning.tipotexto?.value}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                      {planning.pslenguaje ? (
                        <div className="result-block__col col-md-4 col-12">
                          <p>Practica social del lenguaje</p>
                          <h4>{`${planning.pslenguaje?.value}`}</h4>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {(planning.aprendizajesFundamentales || []).length ? (
                      <div className="result-block__row row">
                        <div className="result-block__col col-md-8 col-12">
                          <p>Aprendizajes fundamentales</p>
                          <ul className="result-block__li">
                            {(planning.aprendizajesFundamentales || []).map(
                              (aprendizaje, k) => (
                                <li key={k}>
                                  <span className="material-icons-outlined">east</span>
                                  <p>{aprendizaje.value}</p>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {(planning.aprendizajes || []).length ? (
                      <div className="result-block__row row">
                        <div className="result-block__col col-md-8 col-12">
                          <p>Aprendizajes esperados</p>
                          <ul className="result-block__li">
                            {(planning.aprendizajes || []).map((aprendizaje, k) => (
                              <li key={k}>
                                <span className="material-icons-outlined">east</span>
                                <p>{aprendizaje.value}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {(planning.competencias || []).length ? (
                      <div className="result-block__row row">
                        <div className="result-block__col col-md-8 col-12">
                          <p>Competencias</p>
                          <ul className="result-block__li">
                            {(planning.competencias || []).map((competencia, k) => (
                              <li key={k}>
                                <span className="material-icons-outlined">east</span>
                                <p>{competencia.value}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="result-block container">
                    {planning.description && planning.description !== '' ? (
                      <div className="result-block__row row">
                        <div className="result-block__colcol-12">
                          <p>Descripción</p>
                          <h4>{planning.description || ''}</h4>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {planning.period && planning.period !== '' ? (
                      <div className="result-block__row row">
                        <div className="result-block__colcol-12">
                          <p>Periodo de tiempo</p>
                          <h4>{planning.period || ''}</h4>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {planning.dateRanges &&
                    planning.dateRanges.start > 0 &&
                    planning.dateRanges.end > 0 ? (
                      <div className="result-block__row row">
                        <div className="result-block__colcol-12">
                          <p>Rango de fechas</p>
                          <h4>{`${
                            format(
                              fromUnixTime(planning.dateRanges.start),
                              'dd . MMMM . yy'
                            ) || ''
                          } - ${
                            format(
                              fromUnixTime(planning.dateRanges.end),
                              'dd . MMMM . yy'
                            ) || ''
                          }`}</h4>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {planning.apClave && planning.apClave !== '' ? (
                      <div className="result-block__row row">
                        <div className="result-block__colcol-12">
                          <p>Aprendizajes clave</p>
                          <h4>{planning.apClave || ''}</h4>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {planning.propPed && planning.propPed !== '' ? (
                      <div className="result-block__row row">
                        <div className="result-block__colcol-12">
                          <p>Propósitos pedagógicos</p>
                          <h4>{planning.propPed || ''}</h4>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {planning.contexto && planning.contexto !== '' ? (
                      <div className="result-block__row row">
                        <div className="result-block__colcol-12">
                          <p>Contexto en el aula</p>
                          <h4>{planning.contexto || ''}</h4>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="result-block__row row session-container">
              <div className="col-12">
                {sessions.length ? (
                  <div className="result-block__row result-block__session row">
                    <div className="col-12 mb-4">
                      <h5>Sesiones de mi planeación</h5>
                    </div>
                    {sessions.map((session, key) => {
                      return (
                        <div
                          className="result-block__session-col col-md-4 col-sm-6 col-12"
                          key={key}
                        >
                          <SessionBlockComponent
                            edit={isSameUser() ? 'Editar' : ''}
                            detail={isSameUser() ? '' : 'Ver detalle'}
                            title={session.name}
                            date={
                              session.startDate
                                ? format(
                                    fromUnixTime(session.startDate),
                                    'dd . MMM . yyyy'
                                  )
                                : ''
                            }
                            time={
                              session.minutes
                                ? format(fromUnixTime(session.minutes), 'HH:mm')
                                : ''
                            }
                            url={`/sesiones/${planning._id}/${session._id}`}
                            onDelete={() => {
                              return Swal.fire({
                                icon: 'question',
                                title: 'Borrar sesión',
                                text: `¿Deseas borrar esta sesión?`,
                                showCancelButton: true,
                                confirmButtonText: 'Sí, borrar',
                                cancelButtonText: 'Cancelar',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  if (session._id) {
                                    return deletePlanningSession(
                                      session._id,
                                      `${user.token}`
                                    )
                                      .then(() => {
                                        const newSessions = [...sessions];
                                        newSessions.splice(key, 1);
                                        setSessions(newSessions);
                                      })
                                      .catch((error) => setError(error));
                                  }
                                }
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="session-placeholder">
                    <div className="session-placeholder__inner">
                      <span className="material-icons-outlined session-placeholder__icon">
                        work_outline
                      </span>
                      <article className="session-placeholder__article col-12 col-reset">
                        <h6 className="col-md-5 col-12 mx-auto">
                          Aquí encontrarás las sesiones ligadas a esta
                          planeación para consultarlas en cualquier momento.{' '}
                        </h6>
                      </article>
                      <ButtonAddComponent
                        url={`/sesiones/${planning._id}`}
                        name="Crea una nueva sesión para tu planeación"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="result-block result-block__action-padding container">
              {isSameUser() ? (
                <>
                  <div className="form__row form__action form__action--both">
                    <ButtonAddComponent
                      url={`/sesiones/${planning._id}`}
                      name="Crea una nueva sesión para tu planeación"
                    />
                  </div>
                  <div className="form__row form__action form__action--both">
                    <ButtonTypeOutlineComponent
                      url={`/planeacion-aprendizajes/${planning._id || ''}`}
                      name="Editar"
                    />
                  </div>
                  <div className="form__row form__action form__action--both">
                    <button
                      className="btn btn--type-outline btn--auto btn--delete"
                      onClick={(evt) => {
                        evt.preventDefault();
                        return Swal.fire({
                          icon: 'question',
                          title: 'Borrar planeación',
                          text: `¿Deseas borrar esta planeación?`,
                          showCancelButton: true,
                          confirmButtonText: 'Sí, borrar',
                          cancelButtonText: 'Cancelar',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const { _id: planningId } = planning;
                            if (planningId) {
                              trackEvent({
                                event: 'deletePlanning',
                                page: '/planeacion-sesiones',
                                fullPath: window.location.href,
                              });
                              return deletePlanning(planningId, `${user.token}`)
                                .then(() => navigate('/mis-planeaciones'))
                                .catch((error) => setError(error));
                            }
                          }
                        });
                      }}
                    >
                      Borrar planeación
                    </button>
                  </div>
                </>
              ) : (
                <div className="form__row form__action form__action--both">
                  <button
                    className="btn btn--type-outline btn--auto"
                    onClick={(evt) => {
                      evt.preventDefault();
                      Swal.fire({
                        icon: 'question',
                        title: 'Copiar planeación',
                        text: `¿Deseas añadir la planeación: ${
                          planning.name || ''
                        } a tus planeaciones?`,
                        showCancelButton: true,
                        confirmButtonText: 'Añadir',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          return duplicatePlanning();
                        }
                      });
                    }}
                  >
                    Copiar esta planeación
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
      <div id="print-placeholder" className="toPrint">
        <PrintPlanningScreen
          planning={planning}
          sessions={sessions}
          escuela={escuela}
          show={showPrint}
          recursos={recursos}
        />
      </div>
    </section>
  );
};

export default SessionInitScreen;
