import qs from 'qs';
import api from './index';
import { IPlanningNemFav, PlanningNemLikeResult } from '../types/PlaningInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createPlanningNemFav = async (
  planningNemFav: Partial<IPlanningNemFav>,
  token: string
) => {
  console.log('------ createPlanningNemFav ------');
  const response = await api.post<IPlanningNemFav>(
    '/planningNemFav',
    planningNemFav,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPlanningNemFav= async (id: string, token: string) => {
  console.log('------ getPlanningNemFav ------');
  const response = await api.get<IPlanningNemFav>(
    `/planningNemFav/${id}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchPlanningNemFav = async (
  query: Partial<IPlanningNemFav>,
  token: string
) => {
  console.log('------ searchPlanningNemFav ------');
  const response = await api.post<PlanningNemLikeResult>(
    `/planningNemFav/search?${qs.stringify({ limit: 50 })}`,
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPlanningNemLikes= async (evento: string, name: string, token: string) => {
  console.log('------ getPlanningNemLikes ------');
  const response = await api.get(
    `/planningNemDownload/${evento}/${name}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};