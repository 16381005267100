import React from 'react';
import { Link } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import './buttonTypeOutline.component.scss';

const ButtonTypeOutlineComponent = (props: any) => {
  const { trackEvent } = useTracking();

  return (
    <Link to={props.url} className={`btn btn--type-outline ${props.large}`} 
      onClick={() => {
        if(props.name=='Editar' && props.url.includes('/planeacion-aprendizajes/')){
          trackEvent({
            event: 'editPlanning',
            page: '/planeacion-sesiones',
            fullPath: window.location.href,
          });
        }
      }}>
      {props.name}
    </Link>
  );
};

export default ButtonTypeOutlineComponent;
