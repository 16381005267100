import { ArtiChat, Instrumento, PlanningNem } from '../types/EntidadesInterface';
import api from './index';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const consultarArti = async ( body: any, token: string) => {
  const response = await api.post(
    '/chatgpt', body, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getCountArti = async (body: any, user: any) => {
  console.log('------ countUseArti ------');
  const { token, _id: id } = user;
  const response = await api.post(`/chatgpt/count/${id}`, body, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  }).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  })
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const instrumentosArti = async ( body: any, token: string) => {
  const response = await api.post(
    '/chatgpt/instrumentos', body, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getMisInstrumentosArti = async (id: any, token: string) => {
  const response = await api.get(`/chatgpt/misinstrumentos/${id}`, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getInstrumentoArti = async (id: any, token: string) => {
  const response = await api.get(`/chatgpt/instrumento/${id}`, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateInstrumentoArti = async (
  id: string,
  instrumentos: Partial<Instrumento>,
  token: string
): Promise<Partial<Instrumento>> => {
  console.log('------ updateInstrumento ------');
  const response = await api
    .patch<Instrumento>(`/chatgpt/instrumento/${id}`, instrumentos, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const checkSessionInstruments = async ( sessionId: string, token:any) => {
  const response = await api.get(
    `/chatgpt/instrumento/planning/${sessionId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const artiChat = async ( body: ArtiChat, token:any) => {
  const response = await api.post('/artichat', body,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const artiChatNoUser = async ( body: ArtiChat, token:any) => {
  const response = await api.post('/artichat/free', body,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const createPlanningNem = async ( body: any, token: string) => {
  const response = await api.post(
    '/planningNem', body, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};


export const getMisPlanningNem = async (id: any, token: string) => {
  const response = await api.get(`/planningNem/all/${id}`, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getPlaneacionNem = async (id: any, token: string) => {
  const response = await api.get(`/planningNem/${id}`, 
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updatePlaneacionNem = async (
  id: string,
  planeacionNem: Partial<PlanningNem>,
  token: string
): Promise<Partial<PlanningNem>> => {
  const response = await api
    .patch<PlanningNem>(`/planningNem/${id}`, planeacionNem, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};