import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '835c0c21-d17e-4f29-804a-0d010f1c6224',
  clientToken: 'pub9e4d03d59a087ada50bc25104f0339f1',
  site: 'datadoghq.com',
  service: 'kame-docente-website',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
