import React from 'react';
import { Modal } from 'react-bootstrap';
import Iframe from 'react-iframe'
import './modalIframe.component.scss';


const ModalIframeComponent = (props: any) => {
  
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="modal-warning"
      centered
      className="modal--game"
    >
      <Modal.Header className="modal__title-icon" closeButton>
        <Modal.Title id="modal-warning">
          <h4>{props.title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Iframe url={props.src}
          className="modal__iframe"
          display="block"
          position="relative"/>

      </Modal.Body>
    </Modal>
  );
};

export default ModalIframeComponent;
