import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalGame.component.scss';

const ModalGameComponent = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="modal-warning"
      centered
      className="modal--game"
    >
      <Modal.Header className="modal__title-icon" closeButton>
        <Modal.Title id="modal-warning">
          <h4>{props.title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <figure className="modal__game">
          <img src={props.image} alt="Game" />
        </figure>
      </Modal.Body>
    </Modal>
  );
};

export default ModalGameComponent;
