import React, { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ToggleEnabledComponent from '../../Form/toggleEnabled/toggleEnabled.component';
import ModalInfoComponent from '../../Modal/modalInfo/modalInfo.component';
import { IFlexiblePlanning } from '../../../types/FlexiblePlanningInterface';
import OverlayComponent from '../../OverlayTrigger/OverlayComponent';

interface Props {
  planning: Partial<IFlexiblePlanning>;
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
}

const NotasAdicionalesComponent = ({
  planning,
  updatePlanningState,
  updatePlanning,
}: Props) => {
  const [modalShow, setModalShow] = useState(false);
  const [activo, setActivo] = useState(false);

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { disabledFields } = planning;
    const fields = disabledFields?.planning?.fields || [];
    if (!event.target.checked) {
      fields.push('comentarios', 'firma', 'sello', 'notas');
      updatePlanning({
        ...planning,
        comentarios: '',
        firma: '',
        sello: '',
        notas: '',
        disabledFields: {
          ...planning.disabledFields,
          planning: {
            fields: [...new Set(fields)],
          },
        },
      });
    } else {
      updatePlanning({
        ...planning,
        disabledFields: {
          ...planning.disabledFields,
          planning: {
            fields: fields.filter(
              (f) => !['comentarios', 'firma', 'sello', 'notas'].includes(f)
            ),
          },
        },
      });
    }
  };

  useEffect(() => {}, [planning]);

  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Notas adicionales</h4>
            <p>
              En este espacio puedes dejar notas y comentarios sobre otros
              aspectos de la planeación.
            </p>
          </>
        }
      />
      <div
        className={`session-complete ${
          ['comentarios', 'firma', 'sello', 'notas'].every((f) =>
            planning.disabledFields?.planning?.fields.includes(
              f as keyof IFlexiblePlanning
            )
          )
            ? 'disabled'
            : ''
        }`}
      >
        <Accordion defaultActiveKey="0" className="new-accordion-planning">
          <Card>
            <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
              <div id="notas" className="plan-nav__click"></div>
              <div className="accordion-planning__title">
                <div className="toggle-complete">
                  <div className="toggle-action">
                    <input
                      type="checkbox"
                      id="toggle"
                      checked={
                        ['comentarios', 'firma', 'sello', 'notas'].every((f) =>
                          planning.disabledFields?.planning?.fields.includes(
                            f as keyof IFlexiblePlanning
                          )
                        )
                          ? false
                          : true
                      }
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <figure></figure>
                    </div>
                  </div>
                  <label>
                    <span className="material-icons-outlined icon">
                      note_alt
                    </span>
                    <p>
                      Notas adicionales
                    
                    </p>
                    <button
                      onClick={() => {
                        setModalShow(true);
                      }}
                      className="toggle-moda-tooltip"
                    >
                      <span className="material-icons-outlined">info</span>
                    </button>
                  </label>
                </div>
              </div>
              <CustomToggle eventKey="0">
                <span className="material-icons-outlined accordion-header__arrow">
                  keyboard_arrow_down
                </span>
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanningState,
                          updatePlanning,
                          toUpdate: 'planning',
                          planning: {
                            field: 'comentarios',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updatePlanning({
                              ...planning,
                              comentarios: '',
                            });
                          }
                        }}
                        contentTitle={
                          <>
                            Comentarios de la dirección
                            <OverlayComponent toolTipText="Puedes añadir este espacio para recibir comentarios de la dirección una vez que imprimas tu planeación." />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={planning.comentarios || ''}
                              onChangeCapture={(event) =>
                                updatePlanningState(
                                  'comentarios',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updatePlanning({
                                  ...planning,
                                  comentarios: (
                                    event.target as HTMLTextAreaElement
                                  ).value,
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanningState,
                          updatePlanning,
                          toUpdate: 'planning',
                          planning: {
                            field: 'firma',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updatePlanning({
                              ...planning,
                              firma: '',
                            });
                          }
                        }}
                        contentTitle={
                          <>
                            Firma o visto bueno
                            <OverlayComponent toolTipText="Puedes añadir este espacio para la firma o visto bueno de la dirección. El espacio aparece en blanco una vez que imprimes tu planeación. " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={planning.firma || ''}
                              onChangeCapture={(event) =>
                                updatePlanningState(
                                  'firma',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updatePlanning({
                                  ...planning,
                                  firma: (event.target as HTMLTextAreaElement)
                                    .value,
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                    <div className="planning-row-form__col col-md-6 col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanningState,
                          updatePlanning,
                          toUpdate: 'planning',
                          planning: {
                            field: 'sello',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updatePlanning({
                              ...planning,
                              sello: '',
                            });
                          }
                        }}
                        contentTitle={
                          <>
                            Sello
                            <OverlayComponent toolTipText="Puedes añadir este espacio para el sello de la dirección. El espacio aparece en blanco una vez que imprimes tu planeación.  " />
                          </>
                        }
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={planning.sello || ''}
                              onChangeCapture={(event) =>
                                updatePlanningState(
                                  'sello',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updatePlanning({
                                  ...planning,
                                  sello: (event.target as HTMLTextAreaElement)
                                    .value,
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="planning-row-form">
                  <div className="row">
                    <div className="col-12">
                      <ToggleEnabledComponent
                        updates={{
                          currentPlanning: planning,
                          updatePlanningState,
                          updatePlanning,
                          toUpdate: 'planning',
                          planning: {
                            field: 'notas',
                          },
                        }}
                        disableField={(checked) => {
                          if (!checked) {
                            updatePlanning({
                              ...planning,
                              notas: '',
                            });
                          }
                        }}
                        contentTitle={<>Notas personales  . {' '} <span className="accordion-title__extra">Esta información no se comparte</span></>}
                        contentToEnabled={
                          <>
                            <textarea
                              className="form-control"
                              value={planning.notas || ''}
                              onChangeCapture={(event) =>
                                updatePlanningState(
                                  'notas',
                                  (event.target as HTMLTextAreaElement).value
                                )
                              }
                              onBlur={(event) => {
                                event.preventDefault();
                                updatePlanning({
                                  ...planning,
                                  notas: (event.target as HTMLTextAreaElement)
                                    .value,
                                });
                              }}
                            ></textarea>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </>
  );
};

export default NotasAdicionalesComponent;
