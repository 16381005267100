import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import validator from 'validator';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import AsideComponent from '../../../components/Aside/aside.component';
import InputComponent from '../../../components/Form/input/input.component';
import CheckboxComponent from '../../../components/Form/checkbox/checkbox.component';
import ButtonOutlineComponent from '../../../components/Button/buttonTypeOutline/buttonTypeOutline.component';
import ButtonSocialComponent from '../../../components/Button/buttonSocial/buttonSocial.component';
import './register.screen.scss';
import ModalInfoComponent from '../../../components/Modal/modalInfo/modalInfo.component';
import Cookies from 'js-cookie';

const RegisterScreen = () => {
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any | null>(null);
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [modalShow, setModalShow] = useState(() => {
    // Comprueba si la cookie 'modalClosed' existe al cargar el componente
    const modalClosedCookie = Cookies.get('modalClosed');
    return modalClosedCookie ? false : true; // Si existe la cookie, no muestres el modal
  });
  const [user, setUser] = useState({
    email: '',
    password: '',
    terms: false,
  });
  const [errorExist, setErrorExist] = useState(false);

  function isValidEmail(email: any) {
    return validator.isEmail(email);
  }

  const handleChange = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setErrorMessage(`error`);
    } else {
      setErrorMessage('success');
    }
    if (event.target.value.trim() !== '') {
      console.log('input value is NOT empty');
      console.log(event.target.value);
    } else {
      console.log('input value is empty');
      setErrorMessage('');
    }

    setUser({
      ...user,
      email: (event?.target as HTMLTextAreaElement).value || '',
    });
  };

  const validatePassword = (value: string) => {
    const valid = validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 0,
      minSymbols: 0,
    });
    console.log('validatePassword', { valid });
    if (valid) {
      setErrorMessagePassword('success');
      console.log(value + 'Strong');
    } else {
      setErrorMessagePassword('error');
      console.log(value + 'No Strong');
    }
  };

  const setError = (errorMessage: string) => {
    setErrorExist(true);
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const confirmSignUp = async (code: string) => {
    setIsLoading(true);
    try {
      const { email, password } = user;
      const confirmed = await Auth.confirmSignUp(email, code);
      if (confirmed) {
        await Auth.signIn(email, password);
      }
      setIsLoading(false);
      // this.state.navigation.navigate('Onboarding', {});
    } catch (err) {
      if ((err as any).code === 'UserNotConfirmedException') {
        setError('Tu cuenta aún no ha sido verificada');
      } else if ((err as any).code === 'PasswordResetRequiredException') {
        setError('Por favor reinicia tu contraseña');
      } else if ((err as any).code === 'NotAuthorizedException') {
        setError('Haz olvidado tu contraseña?');
      } else if ((err as any).code === 'UsernameExistsException') {
        setError(
          'Tu correo ya está vinculado en PACO Teacher, por favor inicia sesión.'
        );
      } else if ((err as any).code === 'UserNotFoundException') {
        setError('El usuario no existe');
      } else if ((err as any).code === 'CodeMismatchException') {
        setError('El código de verificación ingresado es incorrecto.');
      } else {
        //setError((err as any).message);
        setError('Por favor, intentalo de nuevo.');
      }
    }
  };

  const submitUser = async () => {
    try {
      const { email, password, terms } = user;
      if (!terms) {
        return Swal.fire(
          'Ooops',
          'Debes aceptar los términos y condiciones',
          'warning'
        );
      }
      if (email && password) {
        setIsLoading(true);
        await Auth.signUp({ username: email, password });
        Swal.fire({
          icon: 'question',
          title: 'Valida tu identidad',
          text: 'Ingresa el código que has recibido en tu correo electrónico. Revisa también en tu bandeja de spam.',
          input: 'number',
          inputAttributes: {
            length: '5',
          },
          showCancelButton: false,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: false,
          preConfirm: (confirmToken: string) => {
            if (!confirmToken || confirmToken.trim() === '') {
              throw new Error(
                'Captura el código que has recibido en tu correo electrónico'
              );
            }
            return confirmSignUp(confirmToken)
              .then((resp) => resp)
              .catch((error) => {
                setErrorExist(true);
                Swal.showValidationMessage(`${error}`);
              });
          },
          allowOutsideClick: false,
        })
          .then((result) => {
            setTimeout(() => {
              if (result.isConfirmed && !errorExist) {
                Swal.fire('Listo', 'Te has registrado', 'success');
              }
            }, 2000);
          })
          .catch((error) => {
            setErrorExist(true);
            Swal.showValidationMessage(`${error}`);
          });
      } else {
        setIsLoading(false);
        return Swal.fire(
          'Ooops',
          'Captura tu correo electrónico y contraseña',
          'warning'
        );
      }
    } catch (err) {
      if ((err as any).code === 'UserNotConfirmedException') {
        setError('Tu cuenta aún no ha sido verificada');
      } else if ((err as any).code === 'PasswordResetRequiredException') {
        setError('Por favor reinicia tu contraseña');
      } else if ((err as any).code === 'NotAuthorizedException') {
        setError('Haz olvidado tu contraseña?');
      } else if ((err as any).code === 'UsernameExistsException') {
        setError(
          'Tu correo ya está vinculado en Paco Teacher, por favor inicia sesión.'
        );
      } else if ((err as any).code === 'CodeMismatchException') {
        setError('El código de verificación ingresado es incorrecto.');
      } else {
        //setError((err as any).message);
        setError('Por favor, intentalo de nuevo.');
      }
    }
  };
  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/registro',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    // const script = document.createElement('script');
    // script.type = 'text/javascript'
    // script.id = 'hs-script-loader'
    // script.async = true
    // script.defer = true
    // script.src = '//js.hs-scripts.com/22409842.js';
    // document.body.appendChild(script);
    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);
  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <HeaderLogoutComponent />

      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setModalShow(false);
          Cookies.set('modalClosed', 'true', { expires: 15 }); // Guarda una cookie llamada 'modalClosed' durante 15 días
        }}
        click={() => {
          setModalShow(false);
          Cookies.set('modalClosed', 'true', { expires: 15 });
        }}
        imageUrl="/assets/images/elements/julia-complete.png"
        content={
          <>
            <h4>Cuenta diferente requerida</h4>
            <p>
              Para acceder a <strong>PACO Teacher</strong> necesitas una cuenta
              diferente a la que utilizas en <strong>PACO</strong>. Si aún no la
              tienes, ¡crea una para disfrutar todos los contenidos que hemos
              creado para los maestros!
            </p>
          </>
        }
      />
      <div className="app-section__top">
        <BreadcrumbComponent url="/" back="Regresar" />
        <div className="container">
          <div className="row">
            <div className="register__col-left register--to-mobile col-md-4 col-12">
              <AsideComponent
                title="Únete y trabaja fácilmente aprovechando nuestras herramientas:                "
                list={
                  <>
                    <li>
                      <span className="material-icons-outlined">done</span>
                      <p>Formatos de planeación intuitivos.</p>
                    </li>
                    <li>
                      <span className="material-icons-outlined">done</span>
                      <p>Recursos didácticos de alta calidad.</p>
                    </li>
                    <li>
                      <span className="material-icons-outlined">done</span>
                      <p>
                        Comparte y aprende de las experiencias de toda una
                        comunidad educativa digital.
                      </p>
                    </li>
                  </>
                }
                image="/assets/images/aside/aside1.png"
                alt="Registro"
              />
            </div>
            <div className="register__col-right col-md-8 col-12">
              <div className="form-init ">
                <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                  <h3 className="form__title">Crear cuenta</h3>
                  <form
                    onSubmit={(evt) => {
                      evt.preventDefault();
                      submitUser();
                    }}
                  >
                    <div className="form__row">
                      <label htmlFor="email" className="form__label">
                        Correo electrónico
                      </label>
                      <div
                        className="form__validation-input"
                        data-alert={errorMessage}
                      >
                        <InputComponent
                          type="email"
                          id="email"
                          placeholder="miemail@mail.com"
                          value={user.email}
                          onChangeInput={handleChange}
                          onChangeCapture={(evt) =>
                            setUser({
                              ...user,
                              email:
                                (evt?.target as HTMLTextAreaElement).value ||
                                '',
                            })
                          }
                        />
                        <div className="alert alert-danger alert--small mt-2 alert--with-icon js-alert-error">
                          <span className="material-icons-outlined">error</span>
                          Recuerda ingresar un correo valido ej.
                          miemail@mail.com
                        </div>
                        <div className="input-success js-alert-success">
                          <span className="material-icons-outlined">done</span>
                        </div>
                      </div>
                    </div>
                    <div className="form__row">
                      <label htmlFor="password" className="form__label">
                        Contraseña
                      </label>
                      <div
                        className="form__validation-input form--validator-password"
                        data-alert={errorMessagePassword}
                      >
                        <div className="form-control__password">
                          <InputComponent
                            type={passwordShown ? 'text' : 'password'}
                            id="password"
                            placeholder="Escribe una contraseña"
                            value={user.password}
                            onChangeInput={(i: any) =>
                              validatePassword(i.target.value as string)
                            }
                            onChangeCapture={(evt) =>
                              setUser({
                                ...user,
                                password:
                                  (evt?.target as HTMLTextAreaElement).value ||
                                  '',
                              })
                            }
                          />
                          <button
                            type="button"
                            className="form-control__password-action"
                            onClick={() => setPasswordShown(!passwordShown)}
                            data-active={passwordShown ? 'visible' : 'off'}
                          >
                            <span className="material-icons-outlined form-control__password-visible">
                              visibility
                            </span>
                            <span className="material-icons-outlined form-control__password-off">
                              visibility_off
                            </span>
                          </button>
                        </div>
                        <div className="alert alert-danger alert--small mt-2 alert--with-icon js-alert-error">
                          <span className="material-icons-outlined">error</span>
                          Recuerda ingresar una contraseña que cumpla con los
                          criterios mínimos
                        </div>
                        <div className="input-success js-alert-success">
                          <span className="material-icons-outlined">done</span>
                        </div>
                      </div>
                      <div className="alert alert-password mt-4">
                        <p>
                          <strong>
                            <i className="fas fa-info-circle"></i> Tip.
                          </strong>{' '}
                          Considera en tu contraseña <strong>mínimo</strong>:
                        </p>
                        <ul>
                          <li>8 caracteres</li>
                          <li>Letras minúsculas</li>
                          <li>Letras mayúsculas</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form__row">
                      <CheckboxComponent
                        id="terms"
                        htmlFor="terms"
                        onChangeEvent={(checked) =>
                          setUser({ ...user, terms: checked })
                        }
                        checked={user.terms}
                        text={
                          <>
                            He leído y acepto las{' '}
                            <Link to="/aviso-de-privacidad">
                              Políticas de privacidad
                            </Link>{' '}
                            y{' '}
                            <Link to="/terminos-y-condiciones">
                              Términos y condiciones
                            </Link>
                          </>
                        }
                      />
                    </div>
                    <div className="form__row form__action">
                      <button
                        type="submit"
                        className="btn btn--type3"
                        disabled={isLoading}
                      >
                        Regístrate
                      </button>
                    </div>
                  </form>
                </div>
                <div className="separator">
                  <p>Ó regístrate con </p>
                </div>
                <div className="social-list-log">
                  <ul>
                    <li>
                      <ButtonSocialComponent
                        icon="fab fa-facebook-f"
                        onClick={() =>
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Facebook,
                          })
                        }
                      />
                    </li>
                    <li>
                      <ButtonSocialComponent
                        icon="fab fa-google"
                        onClick={() =>
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Google,
                          })
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div className="form__row form__action">
                  <ButtonOutlineComponent
                    url="/iniciar-sesion"
                    name="Ya tengo cuenta"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default RegisterScreen;
