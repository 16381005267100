import qs from 'qs';
import api from './index';
import {
  IFlexiblePlanningFavorite,
  ResultPaginateFlexible,
} from '../types/FlexiblePlanningInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createFlexiblePlanningFavorite = async (
  planningFavorite: Partial<IFlexiblePlanningFavorite>,
  token: string
) => {
  console.log('------ createFlexiblePlanningFavorite ------');
  const response = await api
    .post<IFlexiblePlanningFavorite>(
      '/flexible-planningFavorites',
      planningFavorite,
      {
        headers: {
          ...commonHeaders,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getFlexiblePlanningFavorite = async (
  id: string,
  token: string
) => {
  console.log('------ getFlexiblePlanningFavorite ------', { id, token });
  const response = await api
    .get<IFlexiblePlanningFavorite>(`/flexible-planningFavorites/${id}`, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchFlexiblePlanningFavorite = async (
  query: Partial<IFlexiblePlanningFavorite>,
  token: string
) => {
  console.log('------ searchFlexiblePlanningFavorite ------');
  const response = await api
    .post<ResultPaginateFlexible>(
      `/flexible-planningFavorites/search?${qs.stringify({ limit: 50 })}`,
      query,
      {
        headers: {
          ...commonHeaders,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch(function (error) {
      console.log(error);
      if (error.message == 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
