import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { Link, useNavigate } from 'react-router-dom';
import { getMisPlanningNem } from '../../../api/chatGptApi';
import {
  PlanningNem,
  ResultPlanningNem,
} from '../../../types/EntidadesInterface';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';
import PlaneacionNemItemComponent from '../../../components/Cards/planeacion/planeacionNem.component';

const PlanningNemDashboardScreen = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planeaciones, setPlaneaciones] = useState<Partial<ResultPlanningNem>>(
    {}
  );

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/mis-planeaciones-nem',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            getMisPlanningNem(kameUser._id, jwtToken).then((plan) => {
              setPlaneaciones(plan);
              setIsLoading(false);
            });
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>

      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="header-activity-banner header--confetti">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">
                  Planeaciones para la Nueva Escuela <br /> Mexicana con
                  Inteligencia Artificial
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section className="container body-activity body--strategies">
          <div className="area-title area-title--decore area--button-action row">
            <div className="col-12">
              <div className="area-title__button">
                <Link to={'/crea-tu-planeacion-nem'}>
                  <button className="btn btn--type1">
                    Crear una nueva planeación
                  </button>
                </Link>
              </div>

              <div className="search__engine search__inner-page col-md-10 col-12 col-reset mx-auto">
                <h3 className="text-center mb-4">Mis planeaciones NEM</h3>
                <div className="instrumentos">
                  <SearchSelectComponent
                    idItem="planning"
                    options={(planeaciones.docs || []).map((i) => {
                      const { name, _id: id } = i;
                      return {
                        value: id,
                        label: name,
                      };
                    })}
                    onChange={(plan) => {
                      navigate(`/planeacion-nem-detalle/${plan?.value}`);
                    }}
                  />
                </div>
                <p className="search__engine__results">
                  Resultados encontrados: {planeaciones.docs?.length}
                </p>
              </div>
            </div>
          </div>
          <div className="strategies-grid row">
            <div className="col-md-10 col-12 mx-auto">
              <div className="row">
                {(planeaciones.docs || []).map((planNem, key) => {
                  const { planningNem, name, _id: id } = planNem as PlanningNem;
                  const { grado, campoFormativo } = planningNem;
                  return (
                    <div
                      className="container-materials__col col-md-3 col-6"
                      key={key}
                    >
                      <PlaneacionNemItemComponent
                        nombre={`${name || 'Planeación NEM'}`}
                        grade={
                          grado.documentId > '6'
                            ? `${grado.value} - Secundaria`
                            : `${grado.value}`
                        }
                        campoFormativo={`${campoFormativo?.value || ''}`}
                        url={`/planeacion-nem-detalle/${id}`}
                        hide={true}
                        hideDownloads={true}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PlanningNemDashboardScreen;
