import React from 'react';
import './stepper.component.scss';

const StepperComponent = (props: any) => {
  return (
    <div className="stepper" data-status={props.status}>
      <ul>
        <li>
          <span className="stepper__span">1</span>
          <small>Datos personales</small>
        </li>
        <li>
          <span className="stepper__span">2</span>
          <small>Centros educativos</small>
        </li>
        
      </ul>
    </div>
  );
};

export default StepperComponent;
