import React from 'react';
import { Link } from 'react-router-dom';
import './planeacion.component.scss';
import LikeComponent from '../../Like/like.component';

type Props = {
  nombre: string;
  grade: string;
  url?: string;
  campoFormativo?: string;
  numDownloads?: string;
  allFavorites?: number;
  myFavorite?: number;
  paco?: boolean;
  onCheckFavorite?: (
    event: React.FormEvent<HTMLInputElement> | undefined
  ) => void;
  hide?: boolean;
  hideDownloads?: boolean;
  dosificacion?: boolean;
  documento?: any;
  mes?: string;
  onDownload?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined
  ) => void;
};

const PlaneacionNemItemComponent = ({
  nombre,
  grade,
  url,
  campoFormativo,
  numDownloads,
  allFavorites,
  myFavorite,
  paco,
  onCheckFavorite,
  hide,
  hideDownloads,
  dosificacion,
  documento,
  mes,
  onDownload,
}: Props) => {
  return (
    <div
      className="plan-item"
      data-type={dosificacion ? 'dosificacion' : 'nem'}
    >
      <div className="plan-item__inner">
        <div className="plan-item__wrapp">
          <div className="plan-item__title">
            <p className="plan-item__extrafont">{''}</p>
            <h4>{nombre}</h4>
          </div>
          {paco ? (
            <div className="plan-item__grade pteacher-nem">
              <p className="plan-item__extrafont">Por PACO Teacher</p>
            </div>
          ) : (
            <></>
          )}
          <div className="plan-item__grade">
            <p className="plan-item__extrafont">Grado</p>
            <p>{grade}</p>
          </div>
          {dosificacion ? (
            <div className="plan-item__grade grade--highlight">
              <p className="plan-item__extrafont nem-text">Para el mes de: </p>
              <p className="plan-item__extrainfo nem-text">{`${mes}` || ''}</p>
            </div>
          ) : (
            <div className="plan-item__grade grade--highlight">
              <p className="plan-item__extrafont nem-text">Campo formativo</p>
              <p className="plan-item__extrainfo nem-text">
                {`${campoFormativo}` || ''}
              </p>
            </div>
          )}
        </div>
        {dosificacion ? (
          <div className="plan-item__bottom">
            <a
              href={documento.url}
              download={`${documento.hash}.${documento.ext}`}
              onClick={onDownload}
            >
              Descargar
            </a>
            <img src="/assets/images/icon/word.png" alt="WORD" />
          </div>
        ) : (
          <div className="plan-item__bottom">
            <Link to={url || '/'}>Ver detalle</Link>
          </div>
        )}
      </div>
      <div className="liker-footer">
        {hide ? (
          <></>
        ) : (
          <LikeComponent
            totalFavs={allFavorites || 0}
            checked={!!myFavorite}
            onCheck={onCheckFavorite}
          />
        )}
        {hideDownloads ? (
          <></>
        ) : (
          <div className="check-like">
            <input
              type="checkbox"
              className="check-like__check"
              title="Descargas"
            />
            <div className="check-like__change">
              <figure>
                <span className="material-icons icon">download</span>
              </figure>
              <p>{numDownloads}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaneacionNemItemComponent;
