import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import InputComponent from '../../../components/Form/input/input.component';
import SelectComponent from '../../../components/Form/select/select.component';
import SearchSelectComponent from '../../../components/Form/searchSelect/searchSelect.component';
import './educationCenter.screen.scss';
import { IUser, IUserCCTs } from '../../../types/UserInterface';
import { getUser, updateUser } from '../../../api/usersApi';
import {
  getEntidades,
  getEscuelas,
  getMunicipios,
} from '../../../strapi/escuelas';
import { EntidadData, EscuelaData } from '../../../types/EntidadesInterface';

const EducationCenterScreen = () => {
  const { trackEvent } = useTracking();
  const { planningId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [entidades, setEntidades] = useState<EntidadData[]>([]);
  const [municipios, setMunicipios] = useState<EntidadData[]>([]);
  const [escuelas, setEscuelas] = useState<EscuelaData[]>([]);

  const [entidadSelected, setEntidadSelected] = useState('');
  const [municipioSelected, setMunicipioSelected] = useState('');
  const [escuelaCCT, setEscuelaCCT] = useState<IUserCCTs>({
    documentId: '',
    cct: '',
  });
  const [userCCTs, setUserCCTs] = useState<IUserCCTs[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/mi-centro-educativo',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              if (kameUser.userCCTs?.length) {
                setUserCCTs(kameUser.userCCTs);
              }
              getEntidades()
                .then((resultEntidades) => {
                  if (resultEntidades.data.length) {
                    setEntidades(resultEntidades.data);
                  }
                })
                .catch((error) => setError(error));
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  const cleanData = (all: boolean) => {
    setIsLoading(true);

    if (all) {
      setRefresh(true);
      setEntidadSelected('');
    }

    setMunicipioSelected('');
    setEscuelaCCT({
      documentId: '',
      cct: '',
    });

    setEscuelas([]);
    setTimeout(() => {
      setRefresh(false);
      setIsLoading(false);
    }, 500);
  };

  const fetchMunicipios = async (entidadId: string, contains: string) => {
    try {
      const result = await getMunicipios(Number(entidadId), contains);
      if (result.data.length) {
        setMunicipios(result.data);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const fetchEscuelas = async (municipioId: string, contains: string) => {
    try {
      const result = await getEscuelas(Number(municipioId), contains);
      if (result.data.length) {
        setEscuelas(result.data);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const updateCurrentUser = async (ccts: IUserCCTs[]) => {
    try {
      if (user._id && user.token) {
        const updated = await updateUser(
          user._id,
          { userCCTs: ccts || [] },
          user.token
        );
        const entidadS = entidades.filter(
          ({ id }) => `${id}` === entidadSelected
        );
        const municipioS = municipios.filter(
          ({ id }) => `${id}` === municipioSelected
        );
        const schoolS = escuelaCCT.Nombre || '';
        console.log(entidadS);
        console.log(municipioS)
        trackEvent({
          event: 'userCCTs',
          page: '/mi-centro-educativo',
          school: {
            entidad: entidadS ? entidadS[0]?.attributes.Nombre : '',
            municipio: municipioS ? municipioS[0]?.attributes.Nombre : '',
            escuela: schoolS,
          },
        });
        Swal.fire('¡Listo!', 'Tu perfil ha sido actualizado', 'success').then(
          () =>
            navigate(
              planningId
                ? `/planeacion-flexible/${planningId}`
                : '/bienvenido'
            )
        );
        return updated;
      }
    } catch (error) {
      setError(error as string);
    }
  };
  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <HeaderLoginComponent />
      <div className="app-section__top">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tab-change">
                  <Link to="/mi-perfil">Mis datos personales</Link>
                  <Link to="/mi-centro-educativo" className="active">
                    Mis centros educativos
                  </Link>
                </div>
              </div>
            </div>
            <div className="tab-forms row">
              <div className="col-md-6 col-12 mx-auto">
                <h3 className="form__title">Centro educativo</h3>
                <article className="form__text">
                  <p>
                    Esto ayudará a facilitar tus planeaciones, puedes agregar
                    hasta <strong>5 CCT</strong>.
                  </p>
                  <p>
                    Si algo cambia en el futuro, podrás editar esta información
                    en la sección de <strong>&quot;Mi perfil&quot;</strong>
                  </p>
                </article>
                <form
                  onSubmit={(evt) => {
                    evt.preventDefault();
                    setIsLoading(true);
                    return Swal.fire({
                      icon: 'question',
                      title: 'Actualizar datos',
                      text: `¿Deseas actualizar tus datos?`,
                      showCancelButton: true,
                      confirmButtonText: 'Sí, actualizar',
                      cancelButtonText: 'Cancelar',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const { documentId, cct, Nombre } = escuelaCCT;
                        let finalCCts: IUserCCTs[] = [];
                        if (documentId && cct) {
                          const currentCCTs = [...userCCTs] || [];
                          (currentCCTs as IUserCCTs[]).push({
                            documentId,
                            cct,
                            Nombre: Nombre || '',
                          });
                          const filterCCTs = currentCCTs.filter(
                            (value, index, self) =>
                              index === self.findIndex((t) => t.cct === value.cct)
                          );
                          setUserCCTs(filterCCTs);
                          finalCCts = filterCCTs;
                        } else {
                          finalCCts = userCCTs;
                        }
                        updateCurrentUser(finalCCts);
                      } else {
                        setIsLoading(false);
                      }
                    });
                  }}
                >
                  <div className="form__row">
                    <label htmlFor="state" className="form__label">
                      ¿En qué estado de la república se encuentra el Centro
                      Educativo?{' '}
                    </label>
                    {!refresh ? (
                      <SelectComponent
                        label="Estado de la República"
                        id="state"
                        option={
                          <>
                            <option defaultValue="">Seleccionar...</option>
                            {entidades.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.Nombre}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          setEntidadSelected(evt.target.value);
                          cleanData(false);
                          fetchMunicipios(evt.target.value, '');
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="form__row">
                    <label htmlFor="town" className="form__label">
                      Municipio o Alcaldía{' '}
                    </label>
                    {!isLoading ? (
                      <SearchSelectComponent
                        options={(municipios || []).map((m) => {
                          const {
                            attributes: { Nombre: label },
                            id: value,
                          } = m;
                          return { value: `${value}`, label };
                        })}
                        onInputChange={(newValue) => {
                          if (newValue && newValue.length > 2) {
                            fetchMunicipios(entidadSelected, newValue);
                          }
                        }}
                        onChange={(newValue) => {
                          if (newValue) {
                            setMunicipioSelected(newValue.value);
                            fetchEscuelas(newValue.value, '');
                          }
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="form__row">
                    <label htmlFor="institute" className="form__label">
                      Elige un Centro Educativo{' '}
                    </label>
                    {!isLoading ? (
                      <SearchSelectComponent
                        options={(escuelas || []).map((m) => {
                          const {
                            attributes: { Nombre: label, CCT: extra, Turno },
                            id: value,
                          } = m;
                          return {
                            value: `${value}`,
                            label: `${extra} | ${label} | ${Turno}`,
                            extra,
                          };
                        })}
                        onInputChange={(newValue) => {
                          if (newValue && newValue.length > 3) {
                            fetchEscuelas(municipioSelected, newValue);
                          }
                        }}
                        onChange={(newValue) => {
                          if (newValue) {
                            setEscuelaCCT({
                              documentId: newValue.value || '',
                              cct: newValue.extra || '',
                              Nombre: newValue.label,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="form__row">
                    <label htmlFor="cct" className="form__label">
                      Clave de trabajo CCT{' '}
                    </label>
                    <InputComponent
                      type="text"
                      id="cct"
                      placeholder=""
                      value={escuelaCCT.cct}
                      readOnly={true}
                    />
                  </div>
                  {userCCTs.length > 0 && userCCTs.length <= 4 ? (
                    <div className="form__row mt32">
                      <button
                        className="btn btn--add "
                        onClick={(evt) => {
                          evt.preventDefault();
                          const { documentId, cct, Nombre } = escuelaCCT;
                          if (documentId && cct) {
                            return Swal.fire({
                              icon: 'question',
                              title: 'Agregar Institución',
                              text: `¿Deseas añadir tu centro educativo ${cct}?`,
                              showCancelButton: true,
                              confirmButtonText: 'Sí, añadir',
                              cancelButtonText: 'Cancelar',
                              showLoaderOnConfirm: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const currentCCTs = [...userCCTs] || [];
                                (currentCCTs as IUserCCTs[]).push({
                                  documentId,
                                  cct,
                                  Nombre: Nombre || '',
                                });
                                const filterCCTs = currentCCTs.filter(
                                  (value, index, self) =>
                                    index ===
                                    self.findIndex((t) => t.cct === value.cct)
                                );
                                setUserCCTs(filterCCTs);
                              }
                              cleanData(true);
                            });
                          } else {
                            Swal.fire(
                              'Oops',
                              'Debes elegir un centro educativo',
                              'warning'
                            );
                          }
                        }}
                      >
                        <span className="material-icons-outlined">add</span>
                        <p>Agregar otro Centro Educativo</p>
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="form__row mt32">
                    <h6>Centros educativos añadidos</h6>
                    {userCCTs.map((userCCT, key) => {
                      return (
                        <div key={key} className="item-added">
                          <span className="item__name">{`${
                            userCCT.Nombre || ''
                          }`}</span>
                          <button
                            className="item__delete"
                            onClick={(evt) => {
                              evt.preventDefault();
                              return Swal.fire({
                                icon: 'question',
                                title: 'Eliminar centro educativo',
                                text: `¿Deseas eliminar el centro educativo ${userCCT.cct}?`,
                                showCancelButton: true,
                                confirmButtonText: 'Sí, eliminar',
                                cancelButtonText: 'Cancelar',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  const copy = [...userCCTs];
                                  copy.splice(key, 1);
                                  updateCurrentUser(copy);
                                  setUserCCTs(copy);
                                }
                              });
                            }}
                          >
                            <span className="material-icons-outlined">
                              delete
                            </span>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <div className="form__row form__action mt32">
                    <div className="col-12 col-reset">
                      <button
                        type="submit"
                        className="btn btn--type3"
                        disabled={isLoading}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FooterComponent />
    </section>
  );
};

export default EducationCenterScreen;
