import { encode, ParsedUrlQueryInput } from 'querystring';
import { Auth } from 'aws-amplify';
import api from './index';
import { IUser, IUserPaginate } from '../types/UserInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const userSignUp = async (user: Partial<IUser>, token: string) => {
  console.log('------ userSignUp ------');
  const response = await api.post<IUser>('/users', user, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getUser = async (id: string, token: string) => {
  if (!token) {
    window.location.href = '/iniciar-sesion';
  }
  const session = await Auth.currentSession();
  const sessionAccessToken = session.getAccessToken();
  const sessionToken = sessionAccessToken.getJwtToken();
  const response = await api
    .get<IUser>(`/users/${id}`, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${sessionToken}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message === 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateUser = async (
  id: string,
  user: Partial<IUser>,
  token: string
) => {
  console.log('------ updateUser ------');
  const response = await api
    .patch<IUser>(`/users/${id}`, user, {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(function (error) {
      console.log(error);
      if (error.message === 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchUser = async (
  offset: number,
  limit: number,
  sort: string,
  query: Partial<IUser>,
  token: string
) => {
  console.log('------ searchUser ------');
  const response = await api
    .post<IUserPaginate>(
      `/users/search?${encode({
        limit,
        offset,
        sort,
      } as ParsedUrlQueryInput)}`,
      query,
      {
        headers: {
          ...commonHeaders,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch(function (error) {
      console.log(error);
      if (error.message === 'Network Error')
        throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
      else throw 'Por favor, intentalo de nuevo.';
    });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
