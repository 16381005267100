import React from 'react';
import './aside.component.scss';

const AsideComponent = (props: any) => {
  return (
    <div className="aside-block">
      <div className="aside-block__inner">
        <h3>{props.title}</h3>
        <ul className="aside-block__ul">{props.list}</ul>
        <figure className="aside-block__image">
          <img src={props.image} alt={props.alt} />
        </figure>
      </div>
    </div>
  );
};

export default AsideComponent;
