import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { Accordion } from 'react-bootstrap';
import { format, fromUnixTime } from 'date-fns';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import InputComponent from '../../../components/Form/input/input.component';
import DatepickerComponent from '../../../components/Form/datepicker/datepicker.component';
import TimepickerComponent from '../../../components/Form/timepicker/timepicker.component';
import IntroduccionComponent from '../../../components/SessionParts/introduccion/introduccion.component';
import DesarrolloComponent from '../../../components/SessionParts/desarrollo/desarrollo.component';
import CierreComponent from '../../../components/SessionParts/cierre/cierre.component';
import EvaluacionComponent from '../../../components/SessionParts/evaluacion/evaluacion.component';
import NotasComponent from '../../../components/SessionParts/notas/notas.component';
import TextareaComponent from '../../../components/Form/textarea/textarea.component';
import StepperPlanningComponent from '../../../components/StepperPlanning/stepperPlanning.component';
import ModalTooltipComponent from '../../../components/Modal/modalTooltip/modalTooltip.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import './sessionPrivate.screen.scss';
import { getUser } from '../../../api/usersApi';
import { getPlanning, updatePlanning } from '../../../api/planningsApi';
import { IUser } from '../../../types/UserInterface';
import { IPlanning, IPlanningSession } from '../../../types/PlaningInterface';
import {
  createPlanningSession,
  deletePlanningSession,
  getPlanningSession,
  updatePlanningSession,
} from '../../../api/planningSessionsApi';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import {
  GenericResult,
  PreguntaReflexion,
  RecursoEducativo,
  StrapiDoc,
} from '../../../types/EntidadesInterface';

const SessionPrivateScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { id: planningId, sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IPlanning>>({});
  const [session, setSession] = useState<Partial<IPlanningSession>>({});
  const [preguntasR, setPreguntasR] = useState<
    { id: number; attributes: PreguntaReflexion }[]
  >([]);
  const [intrumentosEv, setIntrumentosEv] = useState<
    { id: number; attributes: PreguntaReflexion }[]
  >([]);
  const [recursos, setRecursos] = useState<
    { id: number; attributes: RecursoEducativo }[]
  >([]);
  const [tiposRecurso, setTiposRecurso] = useState<GenericResult>({ data: [] });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const updateSession = async (newSession: Partial<IPlanningSession>) => {
    try {
      const { _id: sessionId, description, name } = newSession;
      setIsLoading(true);
      if (sessionId) {
        const update = await updatePlanningSession(
          sessionId,
          { ...newSession },
          `${user.token}`
        );
        setSession({ ...update });
      } else {
        const validSession = [description, name].every((k) => k && k !== '');
        if (validSession) {
          const introduccion = newSession.introduccion || {
            minutes: 0,
            totalMinutes: 0,
            description: '',
          };
          const desarrollo = newSession.desarrollo || {
            minutes: 0,
            totalMinutes: 0,
            description: '',
          };
          const cierre = newSession.cierre || {
            preguntasReflexion: [],
            minutes: 0,
            totalMinutes: 0,
            description: '',
          };
          const result = await createPlanningSession(
            {
              ...newSession,
              planningId,
              startDate: newSession.startDate || 0,
              minutes: newSession.minutes || 0,
              totalMinutes: newSession.totalMinutes || 0,
              introduccion,
              desarrollo,
              cierre,
            },
            `${user.token}`
          );
          setSession({ ...result });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setError(`${error}`);
      setIsLoading(false);
    }
  };

  const preguntasReflexion = async () => {
    try {
      const query = {
        sort: ['Nombre:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
      };
      const response = await getStrapiCatalog(
        qs.stringify(query),
        '/preguntas-reflexion'
      );
      setPreguntasR(
        response.data as {
          id: number;
          attributes: PreguntaReflexion;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const instrumentosEv = async () => {
    try {
      const query = {
        sort: ['Nombre:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
      };
      const response = await getStrapiCatalog(
        qs.stringify(query),
        '/instrumentos-de-evaluacion'
      );
      setIntrumentosEv(
        response.data as {
          id: number;
          attributes: PreguntaReflexion;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getResources = async (allResources: number[]) => {
    try {
      if (allResources.length) {
        // @ts-ignore: Unreachable code error
        const ids = [...new Set(allResources)];
        const query = {
          sort: ['id:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            id: { $in: ids },
          },
        };
        const response = await getStrapiCatalog(
          qs.stringify(query),
          '/recursos-educativos'
        );
        const tiposRecurso = await getStrapiCatalog(
          qs.stringify({ sort: ['Nombre:asc'], populate: '*' }),
          '/tipo-de-recursos'
        );
        setRecursos(
          response.data as {
            id: number;
            attributes: RecursoEducativo;
          }[]
        );
        setTiposRecurso(tiposRecurso);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getTotalMinutes = (value: number) => {
    if (value) {
      const date = fromUnixTime(value);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return hours * 60 + minutes;
    }
    return 0;
  };

  const updateSessionState = (key: keyof IPlanningSession, value: any) => {
    const newSession = {
      ...session,
      [key]: value,
    };
    if (key === 'minutes') {
      const totalMinutes = getTotalMinutes(Number(value));
      Object.assign(newSession, { totalMinutes });
    }
    setSession(newSession);
    return newSession as Partial<IPlanningSession>;
  };

  const isSameUser = () => {
    if (user && planning) {
      const { _id: userId } = user;
      const { userId: planningUserId } = planning;
      if (planningUserId && userId !== (planningUserId as IUser)._id) {
        return false;
      }
    }
    return true;
  };

  const isValidSession = () => {
    const { introduccion, desarrollo, cierre } = session;
    const hasSessionId = !!(session && '_id' in session);
    const hasIntroId = !!(introduccion && '_id' in introduccion);
    const hasDesarrolloId = !!(desarrollo && '_id' in desarrollo);
    const hasCierreId = !!(cierre && '_id' in cierre);

    return hasSessionId && hasIntroId && hasDesarrolloId && hasCierreId;
  };

  const updatePlanningName = () => {
    Swal.fire({
      icon: 'info',
      title: 'Editar planeación',
      text: 'Ingresa el nombre de tu planeación',
      input: 'text',
      inputValue: planning.name || '',
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (newName: string) => {
        if (!newName || newName.trim() === '') {
          throw new Error('El nombre de tu planeación no puede estar vacío');
        }
        return updatePlanning(
          planningId || '',
          { name: newName },
          `${user.token}`
        )
          .then(() => {
            setPlanning({
              ...planning,
              name: newName,
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Listo', 'Tu planeación se ha actualizado', 'success');
      }
    });
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/sesiones',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser && planningId) {
              if (sessionId) {
                getPlanningSession(sessionId, jwtToken)
                  .then((sessionResp) => {
                    if (sessionResp) {
                      setSession({ ...sessionResp });
                      const allResources: StrapiDoc[] = [];
                      const { introduccion, desarrollo, cierre } = sessionResp;
                      [introduccion, desarrollo, cierre].forEach((section) => {
                        if (section) {
                          const { resources } = section;
                          if (resources && resources.length) {
                            allResources.push(...resources);
                          }
                        }
                      });
                      if (allResources.length) {
                        getResources(
                          allResources.map((r) => Number(r.documentId))
                        );
                      }
                    }
                  })
                  .catch((error) => setError(error));
              }
              setUser({ ...kameUser, token: jwtToken });
              getPlanning(planningId, jwtToken)
                .then((resp) => setPlanning(resp))
                .catch((error) => setError(error));
              preguntasReflexion();
              instrumentosEv();
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <ModalTooltipComponent
        title="Crear sesiones"
        description="Este paso corresponde a la parte central y más importante de tu planeación. Aquí defines la duración de tu planeación (si es para un día o una semana), el número de sesiones que la integran y la descripción detallada de cada sesión (inicio, desarrollo y cierre).  Incluye al final una sección de instrumentos de evaluación y otra de notas para señalar cambios o adecuaciones curriculares. "
      />
      <HeaderLoginComponent />
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/planeacion-sesiones/${planning._id}`}
          back="Regresar"
          urlSecond={`/planeacion-sesiones/${planning._id}`}
          urlSecondText={`${planning.name || ''}`}
          urlThird={`/sesiones/${planning._id}/${session._id || ''}`}
          urlThirdText={`${session.name || ''}`}
        />
        <div className="result-block__wrapper">
          <div className="result-block container pb-0">
            <div className="result-block__row row">
              <div className="result-block__planning col-lg-7 col-md-10 col-12 mx-auto">
                {isSameUser() ? (
                  <StepperPlanningComponent
                    status={4}
                    planningId={planningId}
                    canGoNext={true}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="result-block__title col-6">
                <p>
                  {`${planning.asignatura?.value || ''}`} ·{' '}
                  {`${planning.gradoEscolar?.value || ''}`}{' '}
                  {`${planning.isNewCiclo ? ' · 2022-2023' : ''}`} ·{' '}
                  {`${planning.modeloEducativo?.value}`}
                </p>
                <div className="btn--simple-icon btn--edition">
                  <h1>{`${planning.name || ''}`}</h1>
                  {isSameUser() ? (
                    <span
                      className="material-icons-outlined icon"
                      onClick={(evt) => {
                        evt.preventDefault();
                        updatePlanningName();
                      }}
                    >
                      edit
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {!isSameUser() ? (
                <div className="result-block__title col-6">
                  <div className="alert alert-neutral">
                    <p>
                      No puedes editar esta sesión ya que pertenece a alguien
                      más
                    </p>
                    <p>
                      <strong>
                        <i className="fas fa-info-circle"></i> Tip.
                      </strong>{' '}
                      Si te gusta esta sesión, puedes duplicar la planeación{' '}
                      {`${planning.name || ''}`}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {isSameUser() ? (
              <div className="result-block__row result-block__stick row mb-0">
                <div className="result-block__col col-lg-4 col-6">
                  <p>Autor</p>
                  <h4>
                    <strong>{`${planning.autor || ''}`}</strong>
                  </h4>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="result-block container">
              <div className="result-block__row row">
                <div className="result-block__col col-lg-6 col-12">
                  <div className="form__row">
                    <label
                      htmlFor="name"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">Nombre de la sesión</div>
                      <OverlayComponent toolTipText="Puedes escribir un nombre para la sesión." />
                    </label>
                    <InputComponent
                      type="text"
                      id="name"
                      placeholder=""
                      value={session.name}
                      readOnly={!isSameUser()}
                      onChangeCapture={(evt) => {
                        evt?.preventDefault();
                        updateSessionState(
                          'name',
                          (event?.target as HTMLTextAreaElement).value || ''
                        );
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = updateSessionState(
                          'name',
                          (event?.target as HTMLTextAreaElement).value || ''
                        );
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                <div className="result-block__col col-lg-3 col-6">
                  <div className="form__row">
                    <label
                      htmlFor="name"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">Fecha de la sesión</div>
                      <OverlayComponent toolTipText="Añade la fecha en la que vas a implementar la sesión." />
                    </label>
                    <DatepickerComponent
                      disabled={!isSameUser()}
                      currentValue={
                        session.startDate
                          ? fromUnixTime(session.startDate)
                          : null
                      }
                      onDateChange={(unix) => {
                        const newSession = updateSessionState(
                          'startDate',
                          unix
                        );
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                <div className="result-block__col col-lg-3 col-6">
                  <div className="form__row">
                    <label
                      htmlFor="name"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Tiempo de sesión (hh:mm)
                      </div>
                      <OverlayComponent toolTipText="Añade el tiempo total destinado a la sesión. Formato horas:minutos" />
                    </label>
                    <TimepickerComponent
                      disabled={!isSameUser()}
                      currentValue={
                        session.minutes ? fromUnixTime(session.minutes) : null
                      }
                      onTimeChange={(unix) => {
                        const newSession = updateSessionState('minutes', unix);
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                <div className="result-block__col col-12">
                  <div className="form__row">
                    <label
                      htmlFor="name"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Descripción de la sesión
                      </div>
                      <OverlayComponent toolTipText="Añade una descripción breve para ayudarte a identificar el contenido de cada sesión" />
                    </label>
                    <TextareaComponent
                      id="description"
                      placeholder=""
                      value={session.description}
                      readOnly={!isSameUser()}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        updateSessionState(
                          'description',
                          (evt.target as HTMLTextAreaElement).value || ''
                        );
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = updateSessionState(
                          'description',
                          (evt?.target as HTMLTextAreaElement).value || ''
                        );
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                <div className="result-block__col col-12">
                  <div className="form__row">
                    <label
                      htmlFor="material-fisico"
                      className="form__label tooltip-contain"
                    >
                      <div className="tooltip-text">
                        Material físico{' '}
                        <small className="color-gray">(opcional)</small>
                      </div>
                      <OverlayComponent toolTipText="Añade el material físico que se requiere para cada sesión (proyector, plumones, cartulinas, etc)." />
                    </label>
                    <TextareaComponent
                      id="material-fisico"
                      placeholder=""
                      value={session.materialFisico}
                      readOnly={!isSameUser()}
                      onChangeCapture={(evt) => {
                        evt.preventDefault();
                        updateSessionState(
                          'materialFisico',
                          (evt.target as HTMLTextAreaElement).value || ''
                        );
                      }}
                      onBlur={(evt) => {
                        evt?.preventDefault();
                        const newSession = updateSessionState(
                          'materialFisico',
                          (evt?.target as HTMLTextAreaElement).value || ''
                        );
                        updateSession(newSession);
                      }}
                    />
                  </div>
                </div>
                <div className="result-block__col col-12">
                  <div className="session-info">
                    <p>Cada sesión se guarda automáticamente</p>
                    {session.updatedAt ? (
                      <p>
                        Última actualización:{' '}
                        <strong>
                          {`${format(
                            new Date(session.updatedAt),
                            'dd . MMM . yyyy - HH:mm aaa'
                          )}`}
                        </strong>
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
              <Accordion
                defaultActiveKey={['0']}
                className="sesion-accordion"
                alwaysOpen
              >
                <div className="result-block__row row ">
                  <IntroduccionComponent
                    session={session}
                    recursos={recursos}
                    tiposRecurso={tiposRecurso}
                    setSession={updateSessionState}
                    updateSession={updateSession}
                    isSameUser={isSameUser()}
                    isValidSession={isValidSession}
                    getTotalMinutes={getTotalMinutes}
                  />
                </div>
                <div className="result-block__row row ">
                  <DesarrolloComponent
                    session={session}
                    recursos={recursos}
                    tiposRecurso={tiposRecurso}
                    setSession={updateSessionState}
                    updateSession={updateSession}
                    isSameUser={isSameUser()}
                    isValidSession={isValidSession}
                    getTotalMinutes={getTotalMinutes}
                  />
                </div>
                <div className="result-block__row row ">
                  <CierreComponent
                    session={session}
                    recursos={recursos}
                    tiposRecurso={tiposRecurso}
                    setSession={updateSessionState}
                    updateSession={updateSession}
                    preguntasR={preguntasR}
                    isSameUser={isSameUser()}
                    isValidSession={isValidSession}
                    getTotalMinutes={getTotalMinutes}
                  />
                </div>
                <div className="result-block__row row ">
                  <EvaluacionComponent
                    session={session}
                    setSession={updateSessionState}
                    updateSession={updateSession}
                    intrumentosEv={intrumentosEv}
                    isSameUser={isSameUser()}
                  />
                </div>
                <div className="result-block__row row ">
                  <NotasComponent
                    session={session}
                    setSession={updateSessionState}
                    updateSession={updateSession}
                    isSameUser={isSameUser()}
                  />
                </div>
              </Accordion>
              <nav className="options-bar">
                {isSameUser() ? (
                  <div className="options-bar__col options-bar__add">
                    <button
                      type="button"
                      className="btn option--simple"
                      onClick={() => {
                        return Swal.fire({
                          icon: 'question',
                          title: 'Nueva sesión',
                          text: `¿Deseas guardar esta sesión y crear una nueva?`,
                          showCancelButton: true,
                          confirmButtonText: 'Guardar',
                          cancelButtonText: 'Cancelar',
                          showLoaderOnConfirm: true,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            return updateSession(session)
                              .then(() => {
                                navigate(
                                  `/planeacion-sesiones/${planning._id}`
                                );
                              })
                              .catch((error) => setError(error));
                          }
                        });
                      }}
                    >
                      Añade una nueva sesión a tu planeación
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                {planning.userId === user._id ? (
                  <div className="options-bar__col options-bar__delete">
                    <button
                      className="btn option--delete"
                      onClick={(evt) => {
                        evt.preventDefault();
                        const { _id: sessionId } = session;
                        if (sessionId) {
                          return Swal.fire({
                            icon: 'question',
                            title: 'Borrar sesión',
                            text: `¿Deseas borrar esta sesión?`,
                            showCancelButton: true,
                            confirmButtonText: 'Sí, borrar',
                            cancelButtonText: 'Cancelar',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              if (sessionId) {
                                return deletePlanningSession(
                                  sessionId,
                                  `${user.token}`
                                )
                                  .then(() =>
                                    navigate(
                                      `/planeacion-sesiones/${
                                        planning._id || ''
                                      }`
                                    )
                                  )
                                  .catch((error) => setError(error));
                              }
                            }
                          });
                        }
                      }}
                    >
                      Borrar sesión
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="options-bar__col options-bar__continue">
                  <button
                    type="button"
                    className="btn btn--type3"
                    onClick={(evt) => {
                      evt.preventDefault();
                      updateSession(session).then(() => {
                        navigate(`/planeacion-sesiones/${planning._id}`);
                      });
                    }}
                  >
                    Continuar
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default SessionPrivateScreen;
