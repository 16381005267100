import React from 'react';
import { Link } from 'react-router-dom';
import './buttonType3.component.scss';

const ButtonType3Component = (props: any) => {
  return (
    <Link to={props.url} className="btn btn--type3">
      {props.name}
    </Link>
  );
};

export default ButtonType3Component;
