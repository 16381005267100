import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { format, fromUnixTime } from 'date-fns';
import {
  IFlexiblePlanning,
  StrapiDoc,
} from '../../../types/FlexiblePlanningInterface';
import { RecursoEducativo } from '../../../types/EntidadesInterface';

type Props = {
  show: boolean;
  planning: Partial<IFlexiblePlanning>;
  resources: { id: number; attributes: RecursoEducativo }[];
};

const PrintPlanComponent = ({ show, planning, resources }: Props) => {
  
  const notasHasDisabledField = planning.disabledFields?.planning?.fields || [];

  const getResourceContent = (resource: StrapiDoc, idx: number) => {
    const strapiR = resources.filter((r) => `${r.id}` === resource.documentId);
    if (strapiR && strapiR.length) {
      const newResource = strapiR[0];
      const {
        attributes: {
          Nombre,
          recurso,
          descripcion,
          fuente,
          tipo_de_recursos: tipoDeRecurso,
        },
      } = newResource;
      return (
        <li key={idx}>
          <h6>{`${Nombre}`}</h6>
          <p>
            <ReactMarkdown linkTarget={'_blank'}>{`${
              descripcion || ''
            }`}</ReactMarkdown>
          </p>
          {fuente.data.attributes.Fuente != 'Paco Max' ? (
            <p className="print-section__url">
              <ReactMarkdown linkTarget={'_blank'}>{`${
                recurso || ''
              }`}</ReactMarkdown>
            </p>
          ) : (
            <></>
          )}
          <p>
            <strong>Ejercicios&nbsp;</strong>
            {`${tipoDeRecurso.data
              .map((tR) => tR.attributes.Nombre)
              .join(', ')}`}
          </p>
          <p>
            <strong>Fuente</strong> {`${fuente.data.attributes.Fuente}`}
          </p>
        </li>
      );
    }
    return <></>;
  };

  const getTotalMinutes = (value: number) => {
    if (value) {
      const date = fromUnixTime(value);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return hours * 60 + minutes;
    }
    return 0;
  };

  return (
    <>
      <section
        className={`${
          show ? 'print-plan-body print-section' : 'print-plan-body '
        }`}
      >
        <div className="print-plan__title">
          <figure className="print-plan__logo">
            <img
              src="https://www.pacoteacher.com/assets/images/logo/logo60.png"
              alt="PACO Teacher"
              style={{ width: '60px' }}
            />
          </figure>
          <p>Mi planeación</p>
          <h1>{`${planning.name || ''}`}</h1>
        </div>
        <section className="print-plan__section">
          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col  col-12">
                <h5>Datos generales de tu planeación</h5>
              </div>
            </div>
            <div className="print-section__row row">
              {(!!planning.userCCT?.documentId || !!planning.otherCCT) && (
                <div className="print-section__row__col col-12">
                  <h6>Institución Educativa</h6>
                  <p>
                    {`${
                      planning.userCCT?.documentId
                        ? planning.userCCT.value
                        : planning.otherCCT || ''
                    }`}
                  </p>
                </div>
              )}
            </div>
            <div className="print-section__row row">
              {!!planning.cicloEscolar?.documentId && (
                <div className="print-section__row__col col-4">
                  <h6>Ciclo escolar</h6>
                  <p>{`${planning.cicloEscolar.value}`}</p>
                </div>
              )}
              {!!planning.modeloEducativo?.documentId && (
                <div className="print-section__row__col col-4">
                  <h6>Modelo educativo</h6>
                  <p>{`${planning.modeloEducativo.value}`}</p>
                </div>
              )}
              {!!planning.gradoEscolar?.documentId && (
                <div className="print-section__row__col col-4">
                  <h6>Grado escolar</h6>
                  <p>{`${planning.gradoEscolar.value}`}</p>
                </div>
              )}
              {!!planning.grupoEscolar?.documentId && (
                <div className="print-section__row__col col-4">
                  <h6>Grupo escolar</h6>
                  <p>{`${planning.grupoEscolar.value}`}</p>
                </div>
              )}
            </div>
          </div>
          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col  col-12">
                <h5>Asignaturas y detalles para tu planeación</h5>
              </div>
            </div>
            {(planning.asignaturas || []).map((asignatura, idx) => {
              const {
                campoFormacion,
                period,
                bloque,
                dateRanges,
                eje,
                tema,
                pSocial,
                tTexto,
                lArtistico,
                aprendizajes,
                competencias,
                aprendizajesFundamentales,
                description,
                apClave,
                propPed,
                contexto,
                sessions,
              } = asignatura;
              return (
                <div className="print-section__row m-0 row" key={idx}>
                  <section className="print-subsection">
                    <div className="print-section__row row-col--subtitle row">
                      <div className="print-section__row__col  col-12">
                        <h5>{`${asignatura.asignatura?.value || ''}`}</h5>
                      </div>
                    </div>
                    <div className="print-section__row row">
                      {!!campoFormacion?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Campo de formación</h6>
                          <p>{`${campoFormacion.value}`}</p>
                        </div>
                      )}
                      <div className="print-section__row__col col-4">
                        <h6>Asignatura</h6>
                        <p>{`${asignatura.asignatura?.value || ''}`}</p>
                      </div>
                      {!!bloque?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Bloque</h6>
                          <p>{`${bloque.value}`}</p>
                        </div>
                      )}
                      {!!period && (
                        <div className="print-section__row__col col-4">
                          <h6>Período de tiempo</h6>
                          <p>{`${period}`}</p>
                        </div>
                      )}
                      {!!dateRanges?.end && !!dateRanges.start && (
                        <div className="print-section__row__col col-4">
                          <h6>Rango de fechas</h6>
                          <p>{`${
                            format(
                              fromUnixTime(dateRanges.start),
                              'dd . MMMM . yy'
                            ) || ''
                          } - ${
                            format(
                              fromUnixTime(dateRanges.end),
                              'dd . MMMM . yy'
                            ) || ''
                          }`}</p>
                        </div>
                      )}
                      {!!planning.modeloEducativo?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Modelo educativo</h6>
                          <p>{`${planning.modeloEducativo.value}`}</p>
                        </div>
                      )}
                      {!!eje?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Eje</h6>
                          <p>{`${eje.value}`}</p>
                        </div>
                      )}
                      {!!tema?.value && (
                        <div className="print-section__row__col col-4">
                          <h6>Tema</h6>
                          <p>{`${tema.value}`}</p>
                        </div>
                      )}
                      {!!pSocial?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Práctica social del lenguaje</h6>
                          <p>{`${pSocial.value}`}</p>
                        </div>
                      )}
                      {!!tTexto?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Tipo de texto</h6>
                          <p>{`${tTexto.value}`}</p>
                        </div>
                      )}
                      {!!lArtistico?.documentId && (
                        <div className="print-section__row__col col-4">
                          <h6>Lenguaje artístico</h6>
                          <p>{`${lArtistico.value}`}</p>
                        </div>
                      )}
                      {!!aprendizajes && (
                        <div className="print-section__row__col col-12">
                          <h6>Aprendizajes esperados</h6>
                          {aprendizajes.map((ap, idx) => (
                            <p key={idx}>{`${ap.value}`}</p>
                          ))}
                        </div>
                      )}
                      {!!(competencias && competencias.length) && (
                        <div className="print-section__row__col col-12">
                          <h6>Competencias</h6>
                          {competencias.map((comp, idx) => (
                            <p key={idx}>{`${comp.value}`}</p>
                          ))}
                        </div>
                      )}
                      {!!aprendizajesFundamentales && (
                        <div className="print-section__row__col col-12">
                          <h6>Aprendizajes fundamentales</h6>
                          {aprendizajesFundamentales.map((apF, idx) => (
                            <p key={idx}>{`${apF.value}`}</p>
                          ))}
                        </div>
                      )}
                      {!!description && (
                        <div className="print-section__row__col col-12">
                          <h6>Descripción general</h6>
                          <p>{`${description}`}</p>
                        </div>
                      )}
                    </div>
                    {(!!apClave || !!propPed || !!contexto) && (
                      <div className="print-section__row section__row-subsection row">
                        <div className="print-section__row__col row-col--thirdtitle col-12">
                          <h6>Campos adicionales</h6>
                        </div>
                        {!!apClave && (
                          <div className="print-section__row__col col-12">
                            <h6>Aprendizajes clave</h6>
                            <p>{`${apClave}`}</p>
                          </div>
                        )}
                        {!!propPed && (
                          <div className="print-section__row__col col-12">
                            <h6>Propósitos pedagógicos</h6>
                            <p>{`${propPed}`}</p>
                          </div>
                        )}
                        {!!contexto && (
                          <div className="print-section__row__col col-12">
                            <h6>Contexto en el aula</h6>
                            <p>{`${contexto}`}</p>
                          </div>
                        )}
                      </div>
                    )}
                    {!!sessions?.length && (
                      <>
                        {sessions.map((session, key) => {
                          const {
                            name,
                            startDate,
                            minutes,
                            description,
                            materialFisico,
                            pausaActiva,
                            inicio,
                            desarrollo,
                            cierre,
                            evaluacion,
                          } = session;
                          const inicioHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('inicio')
                              ).length;
                          const desarrolloHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('desarrollo')
                              ).length;
                          const cierreHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('cierre')
                              ).length;
                          const evaluacionHasDisabledField =
                            planning.disabledFields?.sessions
                              ?.filter((s) => s.sessionId === session._id)
                              .filter((s) =>
                                s.fields.includes('evaluacion')
                              ).length;
                          return (
                            <div
                              className="print-section__row section__row-subsection row"
                              key={key}
                            >
                              <div className="row-col--thirdtitle col-12">
                                <h6>Secuencias didácticas</h6>
                              </div>
                              <div className="print-section__row__col col-12">
                                <h6>{`${name || ''}`}</h6>
                                <section className="prin-section__session">
                                  <div className="print-section__row row">
                                    {!!startDate && (
                                      <div className="print-section__row__col col-md-4 col-12">
                                        <h6>Fecha de la sesión</h6>
                                        <p>{`${
                                          format(
                                            fromUnixTime(startDate),
                                            'dd . MMM . yyyy'
                                          ) || ''
                                        }`}</p>
                                      </div>
                                    )}
                                    {!!minutes && (
                                      <div className="print-section__row__col col-md-4 col-12">
                                        <h6>
                                          Tiempo total de la secuencia didáctica
                                        </h6>
                                        <p>{`${getTotalMinutes(
                                          minutes || 0
                                        )} min.`}</p>
                                      </div>
                                    )}
                                    {!!description && (
                                      <div className="print-section__row__col col-12">
                                        <h6>Descripción de la sesión</h6>
                                        <p>{`${description}`}</p>
                                      </div>
                                    )}
                                    {!!materialFisico && (
                                      <div className="print-section__row__col col-12">
                                        <h6>Material físico</h6>
                                        <p>{`${materialFisico}`}</p>
                                      </div>
                                    )}
                                    {!!pausaActiva && (
                                      <div className="print-section__row__col col-12">
                                        <h6>Pausa activa</h6>
                                        <p>{`${pausaActiva}`}</p>
                                      </div>
                                    )}
                                  </div>
                                  {!inicioHasDisabledField && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>A. Inicio</h5>
                                      </div>
                                      {!!inicio?.minutes && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Tiempo estimado</h6>
                                          <p>{`${getTotalMinutes(
                                            inicio.minutes || 0
                                          )} min.`}</p>
                                        </div>
                                      )}
                                      {!!inicio?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividad a desarrollar</h6>
                                          <p>{`${inicio.description}`}</p>
                                        </div>
                                      )}
                                      {!!inicio?.resources?.length && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Recursos educativos</h6>
                                          <ul>
                                            {inicio.resources.map(
                                              (resource, idx) => {
                                                return getResourceContent(
                                                  resource,
                                                  idx
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {!desarrolloHasDisabledField && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>B. Desarrollo</h5>
                                      </div>
                                      {!!desarrollo?.minutes && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Tiempo estimado</h6>
                                          <p>{`${getTotalMinutes(
                                            desarrollo.minutes || 0
                                          )} min.`}</p>
                                        </div>
                                      )}
                                      {!!desarrollo?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividad a desarrollar</h6>
                                          <p>{`${desarrollo.description}`}</p>
                                        </div>
                                      )}
                                      {!!desarrollo?.resources?.length && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Recursos educativos</h6>
                                          <ul>
                                            {desarrollo.resources.map(
                                              (resource, idx) => {
                                                return getResourceContent(
                                                  resource,
                                                  idx
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {!cierreHasDisabledField && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>C. Cierre</h5>
                                      </div>
                                      {!!cierre?.minutes && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Tiempo estimado</h6>
                                          <p>{`${getTotalMinutes(
                                            cierre.minutes || 0
                                          )} min.`}</p>
                                        </div>
                                      )}
                                      {!!cierre?.preguntasReflexion && (
                                        <div className="print-section__row__col col-md-4 col-12">
                                          <h6>Instrumento de evaluación</h6>
                                          {cierre.preguntasReflexion.map(
                                            (pR, idx) => (
                                              <p key={idx}>{`${pR.value}`}</p>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {!!cierre?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividad a desarrollar</h6>
                                          <p>{`${cierre.description}`}</p>
                                        </div>
                                      )}
                                      {!!cierre?.resources?.length && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Recursos educativos</h6>
                                          <ul>
                                            {cierre.resources.map(
                                              (resource, idx) => {
                                                return getResourceContent(
                                                  resource,
                                                  idx
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {!evaluacionHasDisabledField && (
                                    <div className="print-section__row row--division row">
                                      <div className="print-section__row__col row--division__inner-title col-12">
                                        <h5>D. Evaluación</h5>
                                      </div>
                                      {!!evaluacion?.description && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Actividades a desarrollar</h6>
                                          <p>{`${evaluacion.description}`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.criterios && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Criterios de evaluación</h6>
                                          <p>{`${evaluacion.criterios}`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.evidencias && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Evidencias</h6>
                                          <p>{`${
                                            evaluacion.evidencias || ''
                                          }`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.comentarios && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Comentarios adicionales</h6>
                                          <p>{`${evaluacion.comentarios}`}</p>
                                        </div>
                                      )}
                                      {!!evaluacion?.adecuaciones && (
                                        <div className="print-section__row__col col-12">
                                          <h6>Adecuaciones curriculares</h6>
                                          <p>{`${evaluacion.adecuaciones}`}</p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </section>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </section>
                </div>
              );
            })}
          </div>
          {(notasHasDisabledField.length < 4) && (
            <div className="print-section__item">
              <div className="print-section__row row-col--title row">
                <div className="print-section__row__col  col-12">
                  <h5>Notas adicionales</h5>
                </div>
              </div>
              <div className="print-section__row row">
                {!notasHasDisabledField.includes('comentarios') && (
                  <div className="print-section__row__col col-12">
                    <h6>Comentarios de la dirección</h6>
                    <p>{`${planning.comentarios || ' '}`}</p>
                  </div>
                )}
                {!notasHasDisabledField.includes('firma') && (
                  <div className="print-section__row__col col-6">
                    <h6>Firma o visto bueno</h6>
                    <p>{`${planning.firma || ' '}`}</p>
                    <div className="print-sign"></div>
                  </div>
                )}
                {!notasHasDisabledField.includes('sello') && (
                  <div className="print-section__row__col col-6">
                    <h6>Sello</h6>
                    <p>{`${planning.sello || ' '}`}</p>
                    <div className="print-sign"></div>
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      </section>
    </>
  );
};

export default PrintPlanComponent;
