import React from 'react';
import { Link } from 'react-router-dom';
import './buttonAdd.component.scss';

const ButtonAddComponent = (props: any) => {
  return (
    <Link className="btn btn--add" to={props.url}>
      <span className="material-icons-outlined">add</span>
      <p>{props.name}</p>
    </Link>
  );
};

export default ButtonAddComponent;
