import React from 'react';
import { Link } from 'react-router-dom';
import './buttonType1.component.scss';

const ButtonType1Component = (props: any) => {
  return (
    <Link to={props.url} className="btn btn--type1">
      {props.name}

    </Link>
  );
};

export default ButtonType1Component;
