import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { useParams } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import './plannings.screen.scss';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import CommunityComponent from '../../../components/Community/community.component';
import CommunityNemComponent from '../../../components/Community/community-nem.component';

const PlanningsScreen = () => {
  const { trackEvent } = useTracking();
  const { type } = useParams();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/comunidad',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
            }
          })
          .catch((error) => setError(error));
      })
      .catch(() => {});
  }, [type]);

  return (
    <section className="app-section">
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="module">
          <div className="container container-materials">
            <div className="banner-paco row">
              <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                <a
                  href="https://pacoelchato.com"
                  target="__blank"
                  onClick={() => {
                    trackEvent({
                      event: 'bannerPACO',
                      page: window.location.pathname,
                      fullPath: window.location.href,
                    });
                  }}
                >
                  <figure>
                    <img
                      src="/assets/images/banners/paco-banner.png"
                      alt="PACO"
                    />
                  </figure>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="module__title title--action col-12 mb8">
                <h2>Planeaciones para descargar</h2>
              </div>
              <div className="mx-auto module__message col-md-8 col-12 col-reset">
                <p>
                  Explora y descubre propuestas de planeaciones de calidad PACO
                  Teacher creadas y <br /> compartidas por expertos en educación
                  y por la comunidad docente. <br /> No olvides darle “Me gusta”
                  a tus favoritos.{' '}
                </p>
              </div>
            </div>
            {type === 'nueva-escuela-mexicana' ? (
              <CommunityNemComponent user={user} />
            ) : (
              <CommunityComponent user={user} />
            )}
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default PlanningsScreen;
