import React from 'react';
import './buttonSocial.component.scss';

const ButtonSocialComponent = (props: any) => {
  return (
    <button className="btn btn--social" onClick={props.onClick}>
      <i className={props.icon}></i>
    </button>
  );
};

export default ButtonSocialComponent;
