import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import Swal from 'sweetalert2';
import TimepickerComponent from '../../Form/timepicker/timepicker.component';
import TextareaComponent from '../../Form/textarea/textarea.component';
import MaterialItemAddedComponent from '../../Cards/materialAdded/materialAdded.component';
import { Accordion } from 'react-bootstrap';
import './desarrollo.component.scss';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { IPlanningSession } from '../../../types/PlaningInterface';
import {
  GenericResult,
  Logo,
  RecursoEducativo,
} from '../../../types/EntidadesInterface';

type Props = {
  session: Partial<IPlanningSession>;
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
  tiposRecurso: GenericResult;
  setSession: (
    key: keyof IPlanningSession,
    value: any
  ) => Partial<IPlanningSession>;
  updateSession: (newSession: Partial<IPlanningSession>) => Promise<void>;
  isSameUser: boolean;
  isValidSession: () => boolean;
  getTotalMinutes: (value: number) => number;
};

const DesarrolloComponent = ({
  session,
  recursos,
  tiposRecurso,
  setSession,
  updateSession,
  isSameUser,
  isValidSession,
  getTotalMinutes,
}: Props) => {
  const navigate = useNavigate();
  const { desarrollo } = session;
  const introRecursos = (desarrollo?.resources || []).map((r) => r.documentId);
  const strapiRecursos = recursos.filter(
    (r) => introRecursos.indexOf(`${r.id}`) >= 0
  );
  return (
    <Accordion.Item eventKey="1" className="session-tab">
      <Accordion.Header>
        <div className="session-tab__toggle tab--color2">
          <h6>2. Desarrollo</h6>
          <p>
            {`${
              desarrollo?.minutes
                ? format(fromUnixTime(desarrollo.minutes), 'HH:mm')
                : ''
            }`}
          </p>
          <span className="material-icons-outlined icon">expand_more</span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="session-tab__open">
          <div className="session-tab__open-row row">
            <div className="col-12">
              <div className="col-md-4 col-12">
                <div className="form__row">
                  <label
                    htmlFor="description"
                    className="form__label tooltip-contain"
                  >
                    <div className="tooltip-text">Tiempo estimado (hh:mm)</div>
                    <OverlayComponent toolTipText="Añade el tiempo destinado a esta etapa de la sesión. Formato horas:minutos" />
                  </label>
                  <TimepickerComponent
                    disabled={!isSameUser}
                    currentValue={
                      desarrollo?.minutes
                        ? fromUnixTime(desarrollo.minutes)
                        : null
                    }
                    onTimeChange={(unix) => {
                      const newSession = setSession('desarrollo', {
                        description: desarrollo?.description || '',
                        minutes: unix,
                        totalMinutes: getTotalMinutes(unix),
                        resources: desarrollo?.resources || [],
                      });
                      updateSession(newSession);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="col-md-12 col-12">
                <div className="form__row">
                  <label
                    htmlFor="description"
                    className="form__label tooltip-contain"
                  >
                    <div className="tooltip-text">
                      Actividades a desarrollar
                    </div>
                    <OverlayComponent toolTipText="Describe las actividades que vas a desarrollar para esta etapa." />
                  </label>
                  <TextareaComponent
                    id="description"
                    placeholder="Descripción"
                    value={desarrollo ? desarrollo.description : ''}
                    readOnly={!isSameUser}
                    onChangeCapture={(evt) => {
                      evt.preventDefault();
                      setSession('desarrollo', {
                        description:
                          (evt.target as HTMLTextAreaElement).value || '',
                        minutes: desarrollo?.minutes || 0,
                        totalMinutes: getTotalMinutes(desarrollo?.minutes || 0),
                        resources: desarrollo?.resources || [],
                      });
                    }}
                    onBlur={(evt) => {
                      evt?.preventDefault();
                      const newSession = setSession('desarrollo', {
                        description:
                          (evt?.target as HTMLTextAreaElement).value || '',
                        minutes: desarrollo?.minutes || 0,
                        totalMinutes: getTotalMinutes(desarrollo?.minutes || 0),
                        resources: desarrollo?.resources || [],
                      });
                      updateSession(newSession);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="session-tab__open-row row">
            {(strapiRecursos || []).map((recurso, k) => {
              const {
                attributes: {
                  Nombre,
                  aprendizajes,
                  fuente,
                  tipo_de_recursos: tipoDeRecurso,
                },
              } = recurso;
              const recursoFiltro = (tiposRecurso.data || []).filter(
                (tR) => tR.id === tipoDeRecurso.data[0].id
              );
              const logo = recursoFiltro.length
                ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                : null;
              return (
                <div className="session-tab__col col-lg-4  col-12" key={k}>
                  <div className="material-title">
                    <p>{`${Nombre}`}</p>
                  </div>
                  <MaterialItemAddedComponent
                    deleteLabel={isSameUser ? 'delete' : ''}
                    image={`${
                      logo ? logo.data.attributes.formats.thumbnail.url : ''
                    }`}
                    alt="Sheepy y Los Dragones"
                    name={`${
                      tipoDeRecurso.data.length
                        ? tipoDeRecurso.data
                            .map((tR) => tR.attributes.Nombre)
                            .join(', ')
                        : ''
                    }`}
                    type="Actividad"
                    aprendizaje={
                      aprendizajes.data.length
                        ? aprendizajes.data[0].attributes.Nombre
                        : ''
                    }
                    fuente={fuente ? fuente.data.attributes.Fuente : ''}
                    time="30 min"
                    url={
                      session._id && desarrollo?._id
                        ? `/actividad/${recurso.id}/${session._id}/${desarrollo?._id}`
                        : `/actividad/${recurso.id}`
                    }
                    onClickDelete={() => {
                      const currentRs = desarrollo?.resources || [];
                      const index = currentRs.findIndex(
                        (r) => r.documentId === `${recurso.id}`
                      );
                      currentRs.splice(index, 1);
                      return Swal.fire({
                        icon: 'question',
                        title: 'Eliminar recurso',
                        text: `¿Deseas quitar este recurso de tu sesión?`,
                        showCancelButton: true,
                        confirmButtonText: 'Sí, quitar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const newSession = setSession('desarrollo', {
                            description: desarrollo?.description || '',
                            minutes: desarrollo?.minutes || 0,
                            totalMinutes: getTotalMinutes(
                              desarrollo?.minutes || 0
                            ),
                            resources: currentRs,
                          });
                          updateSession(newSession);
                        }
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          {isSameUser ? (
            <div className="session-tab__open-row row">
              <div className="col-12 form__action">
                <div className="col-12">
                  <button
                    className="btn btn--add"
                    onClick={(evt) => {
                      evt.preventDefault();
                      if (isValidSession()) {
                        navigate(`/busqueda/${session._id}/${desarrollo?._id}`);
                      } else {
                        Swal.fire(
                          'Oops',
                          'Para agrear recursos, primero define el nombre y descripción de tu sesión',
                          'warning'
                        );
                      }
                    }}
                  >
                    <span className="material-icons-outlined">add</span>
                    <p>Busca recursos para agregar a tu planeación</p>
                  </button>
                </div>
                <div className="alert alert-simple">
                  <p>
                    Usa recursos digitales para darle un nuevo giro al trabajo
                    en clase.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default DesarrolloComponent;
