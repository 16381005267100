import React, { useState, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { Link, useNavigate } from 'react-router-dom';
import { getDosificaciones, getPlanningsNem } from '../../strapi/planningsNem';
import {
  DosificacionNemResult,
  IDosificacionNem,
  IPlanningNem,
  PlanningLikes,
  PlanningNemLikeResult,
  PlanningNemResult,
} from '../../types/PlaningInterface';
import '../../pages/Materials/plannings/plannings.screen.scss';
import PlaneacionNemItemComponent from '../Cards/planeacion/planeacionNem.component';
import SearchSelectComponent from '../Form/searchSelect/searchSelect.component';
import { IUser } from '../../types/UserInterface';
import {
  createPlanningNemFav,
  getPlanningNemLikes,
  searchPlanningNemFav,
} from '../../api/planningNemFavs';
import Swal from 'sweetalert2';
import SelectComponent from '../Form/select/select.component';
import { Entidad, Grado } from '../../types/EntidadesInterface';
import qs from 'qs';
import { getStrapiCatalog } from '../../strapi/escuelas';

type Props = {
  user: Partial<IUser>;
};

const CommunityNemComponent = ({ user }: Props) => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [plannings, setPlannings] = useState<Partial<PlanningNemResult>>({});
  const [downloads, setDownloads] = useState<Partial<PlanningLikes[]>>([]);
  const [downloadsDos, setDownloadsDos] = useState<Partial<PlanningLikes[]>>(
    []
  );
  const [favorites, setFavorites] = useState<PlanningNemLikeResult>({
    myFavorites: [],
    all: [],
  });
  const [grados, setGrados] = useState<{ id: number; attributes: Grado }[]>([]);
  const [campoF, setCampoF] = useState<{ id: number; attributes: Entidad }[]>(
    []
  );
  const [busqueda, setBusqueda] = useState<
    Partial<{ grado: string; campoF: string }>
  >({});
  const [active, setActive] = useState(true);
  const [proyectos, setProyectos] = useState<Partial<DosificacionNemResult>>(
    {}
  );
  const [busquedaP, setBusquedaP] = useState<
    Partial<{ grado: string; mes: string }>
  >({});
  const [meses, setMes] = useState<string[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const fetchCatalog = async (query: string, route: string) => {
    try {
      const result = await getStrapiCatalog(query, route);
      if (result.data.length) {
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return [];
  };

  const fetchCatalogs = async () => {
    try {
      const query = {
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 50 },
        filters: { level: { $eq: 'Primaria' } },
      };
      const resultGrados = await fetchCatalog(
        qs.stringify({ ...query, sort: ['id:asc'] }),
        '/grados'
      );
      setGrados(
        resultGrados as {
          id: number;
          attributes: Grado;
        }[]
      );
      const resultCampoF = await fetchCatalog(
        qs.stringify({ sort: ['id:asc'], pagination: { pageSize: 50 } }),
        '/nem-campos-formativos'
      );
      setCampoF(
        resultCampoF as {
          id: number;
          attributes: Entidad;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getFavorites = async (userId: string, token: string) => {
    try {
      const result = await searchPlanningNemFav(
        { userId, isFavorite: true },
        token
      );
      if (result) {
        setFavorites(result);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const setFavorite = async (planningNemId: string, checked: boolean) => {
    trackEvent({
      event: 'planningNemFav',
      page: '/planeaciones/nueva-escuela-mexicana',
      planningId: planningNemId,
      favorite: checked,
    });
    const result = await createPlanningNemFav(
      { userId: user._id, planningNemId, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  const getPlannings = async () => {
    const ands: any[] = [];
    if (busqueda.grado) {
      ands.push({ grados: { id: { $eq: busqueda.grado } } });
    }
    if (busqueda.campoF) {
      ands.push({ nem_campo_formativos: { id: { $eq: busqueda.campoF } } });
    }
    const query = {
      sort: ['id:desc'],
      populate: '*',
      pagination: { pageSize: 200 },
      filters: {
        $and: ands,
      },
    };
    getPlanningsNem({ ...query }).then((response) => {
      setPlannings(response);
    });
  };

  useEffect(() => {
    getPlannings();
  }, [busqueda]);

  const getDosificacionesProy = async () => {
    const ands: any[] = [];
    if (busquedaP.grado) {
      ands.push({ grado: { id: { $eq: busquedaP.grado } } });
    }
    if (busquedaP.mes) {
      ands.push({ mes: { $eq: busquedaP.mes } });
    }
    const query = {
      sort: ['id:desc'],
      populate: '*',
      pagination: { pageSize: 100 },
      filters: {
        $and: ands,
      },
    };
    getDosificaciones({ ...query }).then((response) => {
      setProyectos(response);
      const mes = [
        ...new Set(response.data.map((item) => item.attributes.mes)),
      ];
      setMes(mes);
    });
  };

  useEffect(() => {
    getDosificacionesProy();
  }, [busquedaP]);

  useEffect(() => {
    fetchCatalogs();
    if (user._id) {
      getPlanningNemLikes(
        'downloadPlanningNem',
        'planningId',
        user.token || ''
      ).then((descarga) => {
        setDownloads(descarga.planningsNemDownload);
      });
      getPlanningNemLikes(
        'downloadDosificacion',
        'dosificacionId',
        user.token || ''
      ).then((descarga) => {
        setDownloadsDos(descarga.planningsNemDownload);
      });
      trackEvent({
        event: 'pageView',
        page: '/planeaciones/nueva-escuela-mexicana',
        fullPath: window.location.href,
      });
      getFavorites(user?._id || '', user?.token || '');
      setIsLoading(false);
    }
  }, [user]);

  return (
    <section>
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <div className="container container-materials">
        <div className="planning-desc row">
          <div className="col-12">
            <div className="tab-change">
              <Link
                to="/planeaciones/nueva-escuela-mexicana"
                className="active"
              >
                Nueva Escuela Mexicana
              </Link>
              <Link to="/planeaciones/reformas-anteriores">
                Reformas 2011 y 2017
              </Link>
            </div>
          </div>
        </div>
        <div className="container-materials__row row">
          <div className="search-select row">
            <div className="strategie--title col-md-6 col-12">
              <h4>Elige el contenido que te interesa consultar:</h4>
            </div>
          </div>
          <div className="select-proyectos row">
            <div className="col-lg-6 col-sm-12 col-12">
              <button
                className={`btn ${active ? 'btn--type7' : 'btn--type8'}`}
                onClick={() => {
                  setActive(true);
                  trackEvent({
                    event: 'clickPlaneacionesNEM',
                    page: '/planeaciones/nueva-escuela-mexicana',
                    fullPath: window.location.href,
                  });
                }}
              >
                Planeaciones
              </button>
              <button
                className={`btn ${active ? 'btn--type8' : 'btn--type7'}`}
                onClick={() => {
                  setActive(false);
                  trackEvent({
                    event: 'clickDosificacionNEM',
                    page: '/planeaciones/nueva-escuela-mexicana',
                    fullPath: window.location.href,
                  });
                  trackEvent({
                    event: 'pageView',
                    page: '/planeaciones/nueva-escuela-mexicana/dosificaciones',
                    fullPath: window.location.href,
                  });
                }}
              >
                Dosificación de proyectos
              </button>
            </div>
          </div>
          {active ? (
            <>
              <div className="search-select row">
                <div className="col-md-6 col-12">
                  <SearchSelectComponent
                    placeholder="Busca por proyecto"
                    options={(plannings.data || []).map((planning) => ({
                      value: `${planning.id}`,
                      label: planning.attributes.Proyecto,
                    }))}
                    onChange={(newValue) => {
                      navigate(
                        `/planeaciones-nueva-escuela-mexicana/${newValue?.value}`
                      );
                    }}
                  />
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <SelectComponent
                      label=""
                      id="grado-escolar"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Grado
                          </option>
                          {grados.map((e, k) => {
                            return (
                              <option
                                key={k}
                                value={`${e.id}`}
                              >{`${e.attributes.Grado}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setBusqueda({
                          ...busqueda,
                          grado: evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <SelectComponent
                      label=""
                      id="campof"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Campo formativo
                          </option>
                          {campoF.map((e, k) => {
                            return (
                              <option
                                key={k}
                                value={`${e.id}`}
                              >{`${e.attributes.Nombre}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setBusqueda({
                          ...busqueda,
                          campoF: evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <button
                  className="search__engine-clear mt-2"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setBusqueda({});
                    setGrados([]);
                    setCampoF([]);
                    fetchCatalogs();
                    trackEvent({
                      event: 'cleanFilters',
                      page: '/planeaciones/nueva-escuela-mexicana',
                      section: 'planeaciones',
                      fullPath: window.location.href,
                    });
                  }}
                >
                  Limpiar filtros de búsqueda
                </button>
                <p className="text-intro mt-2">
                  <strong>
                    Descubre planeaciones que puedan inspirarte. Escribe la
                    palabra del concepto o tema clave que te interese explorar.
                  </strong>
                </p>
                <div className="sticker-planning col-lg-10 col-12">
                  <figure>
                    <img
                      src="/assets/images/elements/new-planning-nem.png "
                      alt="¡Encuentra planeaciones NUEVAS y sin costo cada semana!"
                    />
                  </figure>
                </div>

                <div className="search-result text-start mt-2">
                  {plannings.data && plannings.data.length > 0 ? (
                    <p>
                      Mostrando <strong>{`${plannings.data.length}`}</strong>{' '}
                      {plannings.data.length > 1 ? (
                        <>planeaciones disponibles</>
                      ) : (
                        <>planeación disponible</>
                      )}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="search-select row">
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <SelectComponent
                      label=""
                      id="grado-escolar"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Grado
                          </option>
                          {grados.map((e, k) => {
                            return (
                              <option
                                key={k}
                                value={`${e.id}`}
                              >{`${e.attributes.Grado}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        setBusquedaP({
                          ...busquedaP,
                          grado: evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <SelectComponent
                      label=""
                      id="mes"
                      option={
                        <>
                          <option defaultValue="" hidden>
                            Mes
                          </option>
                          {meses.map((m, k) => {
                            return (
                              <option key={k} value={`${m}`}>{`${m}`}</option>
                            );
                          })}
                        </>
                      }
                      onChange={(evt) => {
                        console.log(evt.target.value);
                        setBusquedaP({
                          ...busqueda,
                          mes: evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <button
                  className="search__engine-clear mt-2"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setBusquedaP({});
                    setGrados([]);
                    setMes([]);
                    fetchCatalogs();
                    trackEvent({
                      event: 'cleanFilters',
                      page: '/planeaciones/nueva-escuela-mexicana',
                      section: 'dosificacion',
                      fullPath: window.location.href,
                    });
                  }}
                >
                  Limpiar filtros de búsqueda
                </button>
                <p className="text-intro mt-2">
                  <strong>
                    Encuentra sugerencias mensuales de proyectos de la Nueva
                    Escuela Mexicana. Elije los proyectos que mejor se adapten a
                    tu plan analítico y necesidades contextuales.
                  </strong>
                </p>
                <div className="search-result text-start mt-2">
                  {proyectos.data && proyectos.data.length > 0 ? (
                    <p>
                      Mostrando <strong>{`${proyectos.data.length}`}</strong>{' '}
                      dosificaciones de proyectos disponibles
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {active ? (
          <div className="container-materials__row row">
            {(plannings?.data || []).map((planning, key) => {
              const {
                Proyecto,
                grados,
                nem_campo_formativos: campoFormativo,
              } = planning.attributes as IPlanningNem;
              const myFavorite = favorites.myFavorites.filter(
                ({ planningNemId }) => `${planningNemId}` === `${planning.id}`
              );
              const allFavorites = favorites.all.length
                ? favorites.all
                    .filter(({ _id }) => `${_id}` === `${planning.id}`)
                    .map(({ count }) => count)
                : [];
              return (
                <div
                  className="container-materials__col col-md-3 col-6"
                  key={key}
                >
                  <PlaneacionNemItemComponent
                    nombre={`${Proyecto}`}
                    paco={true}
                    grade={`${
                      grados
                        ? grados.data.map((g) => g.attributes.Grado).join(', ')
                        : ''
                    }`}
                    campoFormativo={`${
                      campoFormativo
                        ? campoFormativo.data
                            .map((c) => c.attributes.Nombre)
                            .join(', ')
                        : ''
                    }`}
                    url={`/planeaciones-nueva-escuela-mexicana/${planning.id}`}
                    numDownloads={`${
                      downloads.find((d) => d?._id == `${planning.id}`)
                        ?.count || '0'
                    }`}
                    myFavorite={myFavorite.length}
                    allFavorites={
                      allFavorites.length
                        ? allFavorites.reduce((a, b) => a + b)
                        : 0
                    }
                    onCheckFavorite={(event) => {
                      event?.preventDefault();
                      const checked = (event?.target as any).checked;
                      setFavorite(`${planning.id}`, checked).then(() => {
                        getFavorites(user._id || '', `${user.token}`);
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="container-materials__row row">
            {(proyectos?.data || []).map((dosificacion, key) => {
              const { grado, mes, documento } =
                dosificacion.attributes as IDosificacionNem;
              return (
                <div
                  className="container-materials__col col-md-3 col-6"
                  key={key}
                >
                  <PlaneacionNemItemComponent
                    nombre={`Dosificación de proyectos`}
                    paco={true}
                    grade={`${grado.data.attributes.Grado}`}
                    url={`/planeaciones-nueva-escuela-mexicana/${dosificacion.id}`}
                    dosificacion={true}
                    documento={documento.data.attributes}
                    mes={mes}
                    hide={true}
                    onDownload={() => {
                      trackEvent({
                        event: 'downloadDosificacion',
                        page: '/planeaciones/nueva-escuela-mexicana',
                        dosificacionId: dosificacion.id,
                        grado: grado.data.attributes.Grado,
                        mes,
                      });
                    }}
                    numDownloads={`${
                      downloadsDos.find((d) => d?._id == `${dosificacion.id}`)
                        ?.count || '0'
                    }`}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default CommunityNemComponent;
