import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import MultiSelectComponent from '../../components/Form/multiselect/multiselect.component';
import './search.screen.scss';
import CheckboxComponent from '../../components/Form/checkbox/checkbox.component';
import ModalTooltipComponent from '../../components/Modal/modalTooltip/modalTooltip.component';
import MaterialItemAddedComponent from '../../components/Cards/materialAdded/materialAdded.component';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import { SearchResult } from '../../types/PlaningInterface';
import { getStrapiCatalog } from '../../strapi/escuelas';
import { GenericResult, Logo } from '../../types/EntidadesInterface';
import { MeiliSearch } from 'meilisearch';
import { Recurso } from '../../types/MeiliSearchInterfaces';
import {
  createResourceFavorite,
  searchResourceFavorite,
} from '../../api/resourceFavoritesApi';
import {
  getFlexiblePlanning,
  updateFlexiblePlanning,
} from '../../api/flexiblePlanningsApi';
import Paginate from '../../components/Pagination/pagination.component';
import {
  IFlexiblePlanning,
  IFlexiblePlanningSession,
  IFlexiblePlanningSessionDoc,
  StrapiDoc,
} from '../../types/FlexiblePlanningInterface';

const client = new MeiliSearch({
  host: 'https://meilisearch.a22.mx',
  apiKey: '6530efa95fd438f33b2b5dae0b02f440a815152bb0c651cbc64dd2c2845c4d94',
});

const SearchScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { planningId, asignaturaId, secuenciaId, bloqueId } = useParams();
  const [planning, setPlanning] = useState<Partial<IFlexiblePlanning>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [recursos, setRecursos] = useState<Recurso[]>([]);
  const [filtros, setFiltros] = useState<{
    fuentes: GenericResult;
    tiposRecurso: GenericResult;
    grados: GenericResult;
  }>({
    fuentes: { data: [] },
    tiposRecurso: { data: [] },
    grados: { data: [] },
  });
  const [activeFilters, setActiveFilters] = useState<{
    fuentes: string[];
    tiposRecurso: string[];
    grados: string[];
  }>({ fuentes: [], tiposRecurso: [], grados: [] });
  const [searchText, setSearchText] = useState('');
  const [favorites, setFavorites] = useState<SearchResult>({
    myFavorites: [],
    all: [],
  });

  const [page, setPage] = useState(1);
  const resourcesPerPage = 51;
  const [currentResources, setCurrentResources] = useState<Recurso[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
    setIsLoading(false);
  };

  const handleChangePage = async (page: number) => {
    window.scrollTo(0, 300);
    var indexOfLastResources = page * resourcesPerPage;
    var indexOfFirstResources = indexOfLastResources - resourcesPerPage;
    setCurrentResources(
      recursos.slice(indexOfFirstResources, indexOfLastResources)
    );
    setPage(page);
  };

  const getRecursos = async (
    _activeFilters: {
      fuentes: string[];
      tiposRecurso: string[];
      grados: string[];
    },
    match: string,
    isLoggedIn: boolean
  ) => {
    try {
      const ands: any = [];

      const { fuentes, tiposRecurso, grados } = _activeFilters;
      [
        { filtro: fuentes, label: 'fuente' },
        { filtro: tiposRecurso, label: 'tipo_de_recursos' },
        { filtro: grados, label: 'grado' },
      ].forEach(({ filtro, label }) => {
        if (filtro.length) {
          filtro.forEach((f) => {
            ands.push(`${label}.id = ${f}`);
          });
        }
      });
      const meiliResult = await client
        .index('recurso-educativo')
        .search(match, {
          limit: isLoggedIn ? 1000 : 3,
          filter: [ands.join(' AND ')],
        });

      if (match !== '' || ands.length) {
        trackEvent({
          event: 'recursosSearch',
          page: '/busqueda',
          match,
          filters: ands,
          results: meiliResult.hits.length,
          time: meiliResult.processingTimeMs,
        });
      }

      setRecursos(
        meiliResult.hits
          .map((hit) => {
            return {
              ...hit,
              id: hit.id.replace('recurso-educativo-', ''),
            };
          })
          .sort((a, b) => {
            return (a as Recurso).fuente.id > (b as Recurso).fuente.id ? 1 : -1;
          }) as Recurso[]
      );
      setCurrentResources(
        (
          meiliResult.hits
            .map((hit) => {
              return {
                ...hit,
                id: hit.id.replace('recurso-educativo-', ''),
              };
            })
            .sort((a, b) => {
              return (a as Recurso).fuente.id > (b as Recurso).fuente.id
                ? 1
                : -1;
            }) as Recurso[]
        ).slice(0, 30)
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getFiltros = async () => {
    try {
      const fuentes = await getStrapiCatalog(
        qs.stringify({ sort: ['Fuente:asc'] }),
        '/fuentes'
      );
      const tiposRecurso = await getStrapiCatalog(
        qs.stringify({ sort: ['Nombre:asc'], populate: '*' }),
        '/tipo-de-recursos'
      );
      const grados = await getStrapiCatalog(
        qs.stringify({
          sort: ['id:asc'],
          filters: { level: { $eq: 'Primaria' } },
        }),
        '/grados'
      );
      setFiltros({ fuentes, tiposRecurso, grados });
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getFavorites = async (userId: string, token: string) => {
    try {
      const result = await searchResourceFavorite(
        { userId, isFavorite: true },
        token
      );
      if (result) {
        setFavorites(result);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const setFavorite = async (resource: Recurso, checked: boolean) => {
    trackEvent({
      event: 'resourceFavorite',
      page: '/busqueda',
      resource: resource.id,
      favorite: checked,
    });
    const result = await createResourceFavorite(
      { userId: user._id, resourceId: resource.id, isFavorite: checked },
      `${user.token}`
    );
    return result;
  };

  const findFlexiblePlanning = async (id: string, token: string) => {
    try {
      const foundedPlanning = await getFlexiblePlanning(id, token);
      if (foundedPlanning) {
        setPlanning(foundedPlanning);
      }
    } catch (error) {
      console.error(error);
      setError('Hubo un error intenta de nuevo');
    }
  };

  const updatePlanning = async (newPlanning: Partial<IFlexiblePlanning>) => {
    try {
      const { _id: planningId } = newPlanning;
      if (planningId) {
        const update = await updateFlexiblePlanning(
          planningId,
          { ...newPlanning },
          `${user.token}`
        );
        setPlanning({ ...update });
        return update;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return {};
  };

  useEffect(() => {
    setIsLoading(true);
    trackEvent({
      event: 'pageView',
      page: '/busqueda',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            getFiltros();
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              getFavorites(kameUserId, jwtToken);
              getRecursos(activeFilters, '', true).then(() =>
                getFavorites(kameUserId, jwtToken)
              );
              if (planningId) {
                findFlexiblePlanning(planningId, jwtToken);
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      })
      .catch(() => {
        getFiltros();
        getRecursos(activeFilters, '', false).then(() => setIsLoading(false));
      });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <ModalTooltipComponent
        title="Recursos educativos digitales"
        description="<ul>
            <li>
              Identifica las palabras clave sobre el tema para
              encontrar los recursos digitales que necesitas.
            </li>
            <li>
              Recuerda que puedes incluir recursos digitales para
              enriquecer el trabajo en el aula.
            </li>
            <li>
              Incluir diferentes recursos digitales puede hacer más
              interesante el trabajo en clase.
            </li>
            <li>
              Un video puede ayudarte a captar la atención de los
              estudiantes, no te vayas sin incluir uno.
            </li>
            <li>
              Incluye un cuestionario en línea y descubre una nueva
              forma de evaluar a tus estudiantes.
            </li>
          </ul>"
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={
            planningId && asignaturaId && secuenciaId
              ? `/planeacion-flexible/${planningId}/${asignaturaId}/${secuenciaId}`
              : '/mis-planeaciones'
          }
          back="Regresar"
        />
        <section className="search-block">
          <div className="container">
            <div className="search-block__top">
              <div className="row">
                <div className="search-block__top-col col-12">
                  <div className="search__title">
                    <h1>Recursos educativos digitales</h1>
                    <p>Explora recursos digitales para trabajar en clase. </p>
                  </div>
                </div>
                <div className="search-block__top-col col-12">
                  <div className="search__engine search__inner-page">
                    <div className="search-select">
                      <span className="material-icons-outlined search-select__icon">
                        search
                      </span>
                      <input
                        type="text"
                        className="form-control react-select-container"
                        value={searchText}
                        placeholder="Busca recursos educativos..."
                        onChangeCapture={(evt) => {
                          evt.preventDefault();
                          const newValue = (evt.target as HTMLInputElement)
                            .value;
                          setSearchText(newValue);
                          if (newValue && newValue.length > 3) {
                            getRecursos(
                              activeFilters,
                              newValue,
                              user.email !== ''
                            );
                          }
                        }}
                      />
                      <p className="mt-4">
                        <strong>
                          Encuentra el recurso que mejor apoye tu clase. Escribe
                          la palabra del concepto o tema clave que vayas a
                          trabajar en la sesión de la planeación.
                        </strong>
                      </p>
                    </div>
                    {/**
                     * <SearchSelectComponent
                      options={(recursos || []).map((m) => {
                        const {
                          attributes: { Nombre: label },
                          id: value,
                        } = m;
                        return { value: `${value}`, label };
                      })}
                      onInputChange={(newValue) => {
                        if (newValue && newValue.length > 3) {
                          getRecursos(planning, activeFilters, newValue);
                        }
                      }}
                      onChange={(newValue) => {
                        if (newValue) {
                          console.log(newValue);
                        }
                      }}
                    />
                     */}
                  </div>
                  {/* NEW filters */}
                  <div className="search__global-filters">
                    <div className="row">
                      <div className="form__row col-lg-3 col-12">
                        <label htmlFor="state" className="form__label">
                          Grado escolar
                        </label>
                        <MultiSelectComponent
                          options={(filtros.grados.data || []).map((grado) => ({
                            value: `${grado.id}`,
                            label: (grado.attributes as any).Grado,
                          }))}
                          onChange={(evt) => {
                            let activeF = { ...activeFilters };
                            activeF.grados.splice(0, activeF.grados.length);
                            evt.forEach((e) => {
                              activeF.grados.push(`${e.value}`);
                            });
                            setActiveFilters({ ...activeF });
                          }}
                          from="search"
                          value={(activeFilters.grados || []).map((grado) => ({
                            value: `${grado}`,
                            label: (
                              filtros.grados.data[
                                filtros.grados.data.findIndex(
                                  (gr) => `${gr.id}` === grado
                                )
                              ].attributes as any
                            ).Grado,
                          }))}
                        />
                      </div>
                      <div className="form__row col-lg-3 col-12">
                        <label htmlFor="state" className="form__label">
                          Tipo de contenido
                        </label>
                        <MultiSelectComponent
                          options={(filtros.tiposRecurso.data || []).map(
                            (tipoRecurso) => ({
                              value: `${tipoRecurso.id}`,
                              label: (tipoRecurso.attributes as any).Nombre,
                            })
                          )}
                          onChange={(evt) => {
                            let activeF = { ...activeFilters };
                            activeF.tiposRecurso.splice(
                              0,
                              activeF.tiposRecurso.length
                            );
                            evt.forEach((e) => {
                              activeF.tiposRecurso.push(`${e.value}`);
                            });
                            setActiveFilters({ ...activeF });
                          }}
                          from="search"
                          value={(activeFilters.tiposRecurso || []).map(
                            (tipoRecurso) => ({
                              value: `${tipoRecurso}`,
                              label: (
                                filtros.tiposRecurso.data[
                                  filtros.tiposRecurso.data.findIndex(
                                    (rec) => `${rec.id}` === tipoRecurso
                                  )
                                ].attributes as any
                              ).Nombre,
                            })
                          )}
                        />
                      </div>
                      <div className="form__row col col-lg-1 col-12">
                        <button
                          type="button"
                          onClick={(evt) => {
                            evt.preventDefault();
                            getRecursos(
                              activeFilters,
                              searchText,
                              user.email !== ''
                            );
                          }}
                          className="btn btn--type3 btn--small"
                        >
                          Aplicar filtros
                        </button>
                      </div>
                      <div className="col-12">
                        <button
                          className="search__engine-clear"
                          onClick={(evt) => {
                            evt.preventDefault();
                            const newActiveF = {
                              fuentes: [],
                              grados: [],
                              tiposRecurso: [],
                            };
                            setActiveFilters(newActiveF);
                            setSearchText('');
                            getRecursos(newActiveF, '', user.email !== '');
                            trackEvent({
                              event: 'cleanFilters',
                              page: '/busqueda',
                              fullPath: window.location.href,
                            });
                          }}
                        >
                          Limpiar filtros de búsqueda
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Legacy filters */}
                <div className="hidden search-block__top-col col-md-6 col-12">
                  <div className="search__engine-sortable">
                    {/**
                     * <div className="search__sort-select">
                      <label>Ordernar por</label>
                      <SelectComponent
                        label="Ordernar por"
                        id="order"
                        size="small"
                        option={
                          <>
                            <option defaultValue="" disabled>
                              Seleccionar...
                            </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </>
                        }
                      />
                    </div>
                     */}
                    <button
                      className="btn btn--filter"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      Filtros de búsqueda{' '}
                      <span className="material-icons-outlined">
                        filter_alt
                      </span>
                    </button>
                    <div
                      className="filter-open-container"
                      data-open={`${isOpen ? 'active' : ''}`}
                    >
                      <button
                        className="btn--simple-filter"
                        onClick={(evt) => {
                          evt.preventDefault();
                          setIsOpen(!isOpen);
                        }}
                      >
                        Cancelar
                      </button>
                      <div className="filter-open-contaner__inner">
                        {/**
                           * <div className="filter-open-container__row">
                          <h6>Fuente</h6>
                          {(filtros.fuentes.data || []).map((fuente, key) => (
                            <CheckboxComponent
                              key={key}
                              text={`${(fuente.attributes as any).Fuente}`}
                              id={`fuente-${fuente.id}`}
                              htmlFor={`fuente-${fuente.id}`}
                              checked={
                                activeFilters.fuentes.indexOf(`${fuente.id}`) >=
                                0
                              }
                              onChangeEvent={(checked) => {
                                const activeF = { ...activeFilters };
                                if (checked) {
                                  activeF.fuentes.push(`${fuente.id}`);
                                  setActiveFilters({ ...activeF });
                                } else {
                                  const index = activeF.fuentes.findIndex(
                                    (f) => f === `${fuente.id}`
                                  );
                                  activeF.fuentes.splice(index, 1);
                                  setActiveFilters({ ...activeF });
                                }
                              }}
                            />
                          ))}
                        </div>
                           */}
                        <div className="filter-open-container__row">
                          <h6>Grado escolar</h6>
                          <div className="filter-open-container__multiple row">
                            {(filtros.grados.data || []).map((grado, key) => (
                              <div className="col-md-4 col-12 mb-3" key={key}>
                                <CheckboxComponent
                                  key={key}
                                  text={`${(grado.attributes as any).Grado}`}
                                  id={`grado-${grado.id}`}
                                  htmlFor={`grado-${grado.id}`}
                                  checked={
                                    activeFilters.grados.indexOf(
                                      `${grado.id}`
                                    ) >= 0
                                  }
                                  onChangeEvent={(checked) => {
                                    const activeF = { ...activeFilters };
                                    if (checked) {
                                      activeF.grados.push(`${grado.id}`);
                                      setActiveFilters({ ...activeF });
                                    } else {
                                      const index = activeF.grados.findIndex(
                                        (f) => f === `${grado.id}`
                                      );
                                      activeF.grados.splice(index, 1);
                                      setActiveFilters({ ...activeF });
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="filter-open-container__row">
                          <h6>Tipo de contenido</h6>
                          {(filtros.tiposRecurso.data || []).map(
                            (tipoRecurso, key) => (
                              <CheckboxComponent
                                key={key}
                                text={`${
                                  (tipoRecurso.attributes as any).Nombre
                                }`}
                                id={`tipoRecurso-${tipoRecurso.id}`}
                                htmlFor={`tipoRecurso-${tipoRecurso.id}`}
                                checked={
                                  activeFilters.tiposRecurso.indexOf(
                                    `${tipoRecurso.id}`
                                  ) >= 0
                                }
                                onChangeEvent={(checked) => {
                                  const activeF = { ...activeFilters };
                                  if (checked) {
                                    activeF.tiposRecurso.push(
                                      `${tipoRecurso.id}`
                                    );
                                    setActiveFilters({ ...activeF });
                                  } else {
                                    const index =
                                      activeF.tiposRecurso.findIndex(
                                        (f) => f === `${tipoRecurso.id}`
                                      );
                                    activeF.tiposRecurso.splice(index, 1);
                                    setActiveFilters({ ...activeF });
                                  }
                                }}
                              />
                            )
                          )}
                        </div>
                        <div className="filter-open-container__action">
                          <button
                            type="button"
                            onClick={(evt) => {
                              evt.preventDefault();
                              setIsOpen(!isOpen);
                              const newF = {
                                fuentes: [],
                                grados: [],
                                tiposRecurso: [],
                              };
                              setActiveFilters(newF);
                              getRecursos(newF, '', user.email !== '');
                              trackEvent({
                                event: 'cleanFilters',
                                page: '/busqueda',
                                fullPath: window.location.href,
                              });
                            }}
                            className="btn btn--type-outline"
                          >
                            Limpiar filtros
                          </button>
                          <button
                            type="button"
                            onClick={(evt) => {
                              evt.preventDefault();
                              setIsOpen(!isOpen);
                              getRecursos(activeFilters, '', user.email !== '');
                            }}
                            className="btn btn--type3"
                          >
                            Aplicar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-block__bottom">
              {recursos.length > 0 ? (
                <p>
                  Mostrando <strong>{`${(recursos || []).length}`}</strong>{' '}
                  recursos disponibles
                </p>
              ) : (
                <></>
              )}
            </div>
            {/* <div className="search-block__results">
              <h4>Recursos relacionados</h4>
              {/* Aquí pueden ir recursos relacionados como menciona Ernesto?}
            </div> */}
            <div className="search-block__results">
              <h4>Resultados de la búsqueda</h4>
              <div className="search-pills">
                {searchText !== '' && searchText.length >= 3 ? (
                  <div className="search-pills__item">
                    <p>{`${searchText}`}</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="search-block__results-grid">
                <div className="row">
                  {(currentResources || []).map((recurso, k) => {
                    const {
                      Nombre,
                      aprendizajes,
                      fuente,
                      tipo_de_recursos: tipoDeRecurso,
                    } = recurso;
                    const recursoFiltro = (
                      filtros.tiposRecurso.data || []
                    ).filter((tR) => `${tR.id}` === `${tipoDeRecurso[0].id}`);
                    const logo = recursoFiltro.length
                      ? ((recursoFiltro[0].attributes as any).Logo as Logo)
                      : null;

                    const myFavorite = favorites.myFavorites.filter(
                      ({ resourceId }) => `${resourceId}` === recurso.id
                    );
                    const allFavorites = favorites.all.length
                      ? favorites.all
                          .filter(({ _id }) => `${_id}` === recurso.id)
                          .map(({ count }) => count)
                      : [];

                    return (
                      <div
                        className="session-block__results__col col-lg-4  col-12"
                        key={k}
                      >
                        <div className="material-title">
                          <p>{`${Nombre}`}</p>
                        </div>
                        <MaterialItemAddedComponent
                          add={`${
                            planning && planningId ? 'add_circle_outline' : ''
                          }`}
                          image={`${
                            logo
                              ? logo.data.attributes.formats.thumbnail.url
                              : ''
                          }`}
                          alt={Nombre}
                          name={`${
                            tipoDeRecurso.length
                              ? tipoDeRecurso.map((tR) => tR.Nombre).join(', ')
                              : ''
                          }`}
                          type="Recurso"
                          aprendizaje={
                            aprendizajes.length ? aprendizajes[0].Nombre : ''
                          }
                          fuente={fuente ? fuente.Fuente : ''}
                          url={
                            planningId &&
                            asignaturaId &&
                            secuenciaId &&
                            bloqueId
                              ? `/actividad/${recurso.id}/${planningId}/${asignaturaId}/${secuenciaId}/${bloqueId}`
                              : `/actividad/${recurso.id}`
                          }
                          onClickAdd={() => {
                            return Swal.fire({
                              icon: 'question',
                              title: 'Agregar recurso',
                              text: `¿Deseas agregar este recurso a tu Secuencia Didáctica?`,
                              showCancelButton: true,
                              confirmButtonText: 'Sí, agregar',
                              cancelButtonText: 'Cancelar',
                              showLoaderOnConfirm: true,
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                const { asignaturas } = planning;
                                if (asignaturas?.length) {
                                  let resources: StrapiDoc[] = [];
                                  asignaturas.forEach((asignatura, idx) => {
                                    const { sessions } = asignatura;
                                    (sessions || []).forEach((session, idy) => {
                                      if (
                                        session._id === secuenciaId &&
                                        bloqueId
                                      ) {
                                        const bloque: IFlexiblePlanningSessionDoc =
                                          session[
                                            bloqueId as keyof IFlexiblePlanningSession
                                          ] as any;
                                        resources = bloque.resources || [];

                                        const exists = resources.findIndex(
                                          (r) =>
                                            r.documentId === `${recurso.id}`
                                        );
                                        console.log({ resources, exists });
                                        if (exists >= 0) {
                                          return Swal.fire(
                                            'Oops',
                                            'Este recurso ya fue añadido previamente',
                                            'warning'
                                          );
                                        } else {
                                          resources.push({
                                            documentId: `${recurso.id}`,
                                            value: recurso.Nombre,
                                          });
                                          const newPlanning = { ...planning };
                                          (newPlanning as any).asignaturas[
                                            idx
                                          ].sessions[idy][bloqueId].resources =
                                            resources;
                                          setPlanning({ ...newPlanning });

                                          updatePlanning({ ...newPlanning })
                                            .then(() => {
                                              return Swal.fire({
                                                icon: 'success',
                                                title: 'Añadir recurso',
                                                text: `El recurso fue añadido correctamente`,
                                                showCancelButton: true,
                                                confirmButtonText:
                                                  'Volver a mi sesión',
                                                cancelButtonText:
                                                  'Continuar buscando',
                                              }).then((response) => {
                                                if (response.isConfirmed) {
                                                  navigate(
                                                    `/planeacion-flexible/${planningId}/${asignaturaId}/${secuenciaId}`
                                                  );
                                                }
                                              });
                                            })
                                            .catch((error) => setError(error));
                                        }
                                      }
                                    });
                                  });
                                }
                              }
                              /* if (result.isConfirmed) {
                                const { introduccion, desarrollo, cierre } =
                                  session;
                                const bloques = [
                                  { nombre: 'introduccion', introduccion },
                                  { nombre: 'desarrollo', desarrollo },
                                  { nombre: 'cierre', cierre },
                                ].filter(
                                  (b) =>
                                    b && (b as any)[b.nombre]?._id === bloqueId
                                );
                                if (session._id && bloques && bloques.length) {
                                  const bloqueName = bloques[0].nombre;
                                  const bloque = (bloques[0] as any)[
                                    bloqueName
                                  ];
                                  const resources =
                                    (bloque as IPlanningSessionDoc).resources ||
                                    [];
                                  const exists =
                                    resources.findIndex(
                                      (r) => r.documentId === `${recurso.id}`
                                    ) >= 0;
                                  if (exists) {
                                    return Swal.fire(
                                      'Oops',
                                      'Este recurso ya fue añadido previamente',
                                      'warning'
                                    );
                                  } else {
                                    (resources || []).push({
                                      documentId: `${recurso.id}`,
                                      value: recurso.Nombre,
                                    });
                                    const currentSession = (session as any)[
                                      bloqueName
                                    ];
                                    trackEvent({
                                      event: 'addResourceToPlanning',
                                      page: '/actividad',
                                      recursoId: recurso.id,
                                      sessionId: session._id,
                                      bloqueName,
                                    });
                                    updatePlanningSession(
                                      session._id,
                                      {
                                        [bloqueName]: {
                                          ...currentSession,
                                          resources,
                                        },
                                      },
                                      `${user.token}`
                                    )
                                      .then(() => {
                                        return Swal.fire({
                                          icon: 'success',
                                          title: 'Añadir recurso',
                                          text: `El recurso fue añadido correctamente`,
                                          showCancelButton: true,
                                          confirmButtonText:
                                            'Volver a mi sesión',
                                          cancelButtonText:
                                            'Continuar buscando',
                                        }).then((response) => {
                                          if (response.isConfirmed) {
                                            navigate(
                                              `/sesiones/${planning._id}/${session._id}`
                                            );
                                          }
                                        });
                                      })
                                      .catch((error) => setError(error));
                                  }
                                }
                              } */
                            });
                          }}
                          myFavorite={myFavorite.length}
                          allFavorites={
                            allFavorites.length
                              ? allFavorites.reduce((a, b) => a + b)
                              : 0
                          }
                          onCheckFavorite={(event) => {
                            event?.preventDefault();
                            const checked = (event?.target as any).checked;
                            setFavorite(recurso, checked).then(() => {
                              getFavorites(user._id || '', `${user.token}`);
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                {recursos.length > 1 && (
                  <Paginate
                    total={Math.ceil(recursos.length / resourcesPerPage)}
                    current={page}
                    onChangePage={handleChangePage}
                  />
                )}
              </div>
            </div>
            {user.email === '' && !isLoading ? (
              <section className="resource-message">
                <article className="col-md-6 col-12">
                  <div className="resource-message__icon">
                    <figure>
                      <img
                        src="/assets/images/icon/alert.gif"
                        alt="Alert"
                        className="icon-alert"
                      />
                    </figure>
                  </div>
                  <div className="resource-message__text">
                    <h5>Para tener acceso a este y más recursos.</h5>
                    <p>Registrate a PACO Teacher</p>
                    <Link to="/registro" className="btn btn--type3">
                      Regístrate
                    </Link>
                  </div>
                </article>
              </section>
            ) : (
              <></>
            )}
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default SearchScreen;
