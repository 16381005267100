import React, { useState } from 'react';
import { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { IFlexiblePlanningAsignatura } from '../../../../types/FlexiblePlanningInterface';

interface Props {
  planningAsignatura: Partial<IFlexiblePlanningAsignatura>;
}

const ResumeSecuenciaComponent = ({ planningAsignatura }: Props) => {
  const [activo, setActivo] = useState(false);

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <>
      <Accordion
        defaultActiveKey="0"
        className="new-accordion-planning accordion--color2"
      >
        <Card>
          <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
            <div className="accordion-planning__title">
              <h6>{`Resumen de la asignatura (${
                planningAsignatura.asignatura?.value || ''
              })`}</h6>
            </div>
            <CustomToggle eventKey="0">
              <span className="material-icons-outlined accordion-header__arrow">
                keyboard_arrow_down
              </span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <section className="planning-resume">
                {planningAsignatura.aprendizajes?.length ? (
                  <div className="planning-resume__row">
                    <h6>Aprendizajes esperados</h6>
                    <article>
                      <ul>
                        {planningAsignatura.aprendizajes.map((ap, idx) => {
                          return <li key={idx}>{`${ap.value}`}</li>;
                        })}
                      </ul>
                    </article>
                  </div>
                ) : (
                  <></>
                )}
                {planningAsignatura.competencias?.length ? (
                  <div className="planning-resume__row">
                    <h6>Competencias</h6>
                    <article>
                      <ul>
                        {planningAsignatura.competencias.map((ap, idx) => {
                          return <li key={idx}>{`${ap.value}`}</li>;
                        })}
                      </ul>
                    </article>
                  </div>
                ) : (
                  <></>
                )}
                {!!planningAsignatura.description && (
                  <div className="planning-resume__row">
                    <h6>Descripción</h6>
                    <article>
                      <p>{`${planningAsignatura.description}`}</p>
                    </article>
                  </div>
                )}
                {!!planningAsignatura.apClave && (
                  <div className="planning-resume__row">
                    <h6>Aprendizajes claves</h6>
                    <article>
                      <p>{`${planningAsignatura.apClave}`}</p>
                    </article>
                  </div>
                )}
                {!!planningAsignatura.propPed && (
                  <div className="planning-resume__row">
                    <h6>Propósitos pedagógicos</h6>
                    <article>
                      <p>{`${planningAsignatura.propPed}`}</p>
                    </article>
                  </div>
                )}
                {!!planningAsignatura.contexto && (
                  <div className="planning-resume__row">
                    <h6>Contexto en el aula</h6>
                    <article>
                      <p>{`${planningAsignatura.contexto}`}</p>
                    </article>
                  </div>
                )}
              </section>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default ResumeSecuenciaComponent;
