import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { deleteSession } from '../../../utils/helpers';
import ModalArtiComponent from '../../Modal/modalArti/modalArti.component';
import { useTracking } from 'react-tracking';
import AdviceVentasComponent from '../../Advice/adviceVentas.component';

const HeaderLoginComponent = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [modalArtiShow, setModalArtiShow] = useState(false);

  const contentClassname = isOpen ? `active` : ``;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 100;
      if (scrollCheck) {
        setScroll(scrollCheck);
      } else {
        setScroll(false);
      }
    });
  });

  return (
    <header className="header" data-class={scroll}>
      <ModalArtiComponent
        show={modalArtiShow}
        onHide={() => setModalArtiShow(false)}
      />
      <section className="header__top-row">
        <div className="container">
          <div className="header__row row">
            <div className="header__col-left col-xl-2 col-6">
              <Link to="/bienvenido" className="header__logo">
                <figure>
                  <img
                    src="/assets/images/logo/paco-teacher-logo.svg"
                    alt="PACO Teacher"
                  />
                </figure>
              </Link>
            </div>

            <div className="header__col-right col-xl-10 col-6">
              <div className="header__gif">
                <button
                  className="header__arti"
                  onClick={() => {
                    setModalArtiShow(true);
                    trackEvent({
                      event: 'clickArtiChat',
                      page: window.location.pathname,
                      fullPath: window.location.href,
                    });
                  }}
                >
                  <img
                    src="/assets/images/pacomax/arti-mov.gif"
                    alt="Arti Chat"
                  />
                </button>
              </div>
              <nav
                className="main-nav main--login"
                data-action={contentClassname}
              >
                <ul>
                  <li>
                    <NavLink to="/mis-planeaciones">
                      Planeaciones{' '}
                      <span className="material-icons">expand_more</span>
                    </NavLink>
                    <ol>
                      <li>
                        <NavLink to="/planeaciones/nueva-escuela-mexicana">
                          Planeaciones para descargar (incluye NEM y
                          dosificaciones)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/crea-tu-planeacion-nem">
                          Planeaciones NEM con Inteligencia Artificial
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/planeacion-flexible">
                          Planeaciones 2011 y 2017
                        </NavLink>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <NavLink to="/respuestas-grado">
                      Prepara tu clase{' '}
                      <span className="material-icons">expand_more</span>
                    </NavLink>
                    <ol>
                      <li>
                        <NavLink to="/busqueda">Recursos educativos</NavLink>
                      </li>
                      <li>
                        <NavLink to="/estrategias-didacticas">
                          Enseña matemáticas con PACO Max
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/explicaciones-materia">
                          Explicaciones por tema
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/respuestas-grado">
                          Respuestas a todos los libros SEP (incluye NEM)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/ponte-a-prueba-grados">
                          Reactivos para exámenes
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/instrumentos-de-evaluacion">
                          Instrumentos de evaluación con Inteligencia Artificial
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/recursos-papalote">
                          Recursos educativos Papalote Museo del Niño
                        </NavLink>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <NavLink to="/boletin-nueva-escuela-mexicana">
                      Boletín NEM
                    </NavLink>
                  </li>
                  <li>
                    <button
                      className="header__arti"
                      onClick={() => {
                        setModalArtiShow(true);
                        trackEvent({
                          event: 'clickArtiChat',
                          page: window.location.pathname,
                          fullPath: window.location.href,
                        });
                      }}
                    >
                      <img
                        src="/assets/images/pacomax/arti-mov.gif"
                        alt="Arti Chat"
                      />
                    </button>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/preguntas-frecuentes"
                      className={'main-nav--link-help'}
                    >
                      <span className="material-icons icon">help_outline</span>
                      <span className="icon--text">Ayuda</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <li className="main-nav__separator main-nav__extras">
                      <a
                        className="main-nav__link link--close-sesion"
                        onClick={(evt) => {
                          evt.preventDefault();
                          deleteSession();
                          Auth.signOut().then(() => {
                            window.location.pathname == '/'
                              ? window.location.reload()
                              : navigate('/');
                          });
                        }}
                      >
                        Cerrar sesión
                      </a>
                    </li>
                  </li>
                  <li className="main-nav__extras main--hide">
                    <Link
                      to="/mi-perfil"
                      className="main-nav__link nav-link__profile"
                    >
                      <span className="material-icons-outlined icon--profile">
                        person
                      </span>{' '}
                      Mi perfil
                    </Link>
                    <ol>
                      <li>
                        <NavLink to="/Bienvenido">Menú principal</NavLink>
                      </li>
                      <li>
                        <NavLink to="/mis-favoritos">Mis favoritos</NavLink>
                      </li>
                      <li>
                        <NavLink to="/mis-instrumentos">
                          Mis instrumentos de evaluación
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/mis-planeaciones">
                          Mis planeaciones guardadas
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/mi-centro-educativo">
                          Mis centros educativos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/mi-perfil">Mis datos personales</NavLink>
                      </li>
                      <li className="main-nav__logout"></li>
                    </ol>
                  </li>
                </ul>
              </nav>
              <button
                id="open-nav"
                className={contentClassname}
                onClick={() => setIsOpen(!isOpen)}
              >
                <small></small>
                <small></small>
                <small></small>
              </button>
            </div>
          </div>
        </div>
      </section>
      <AdviceVentasComponent />
    </header>
  );
};

export default HeaderLoginComponent;
