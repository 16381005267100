import React, { useState, useEffect, useRef } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
const html2pdf = require('html2pdf.js');
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import qs from 'qs';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import DatosGeneralesComponent from '../../components/planeacionesComponents/datosGenerales/datosGenerales.component';
import AsignaturaComponent from '../../components/planeacionesComponents/asignatura/asignatura.component';
import NotasAdicionalesComponent from '../../components/planeacionesComponents/notasAdicionales/notasAdicionales.component';
import PlanNavComponent from '../../components/planeacionesComponents/planNav/planNav.component';
import PrintPlanComponent from '../../components/planeacionesComponents/printPlan/printPlan.component';
import { getUser } from '../../api/usersApi';
import { IUser, StrapiDoc } from '../../types/UserInterface';
import {
  IFlexiblePlanning,
  IFlexiblePlanningAsignatura,
  IFlexiblePlanningSession,
  DisabledFields,
} from '../../types/FlexiblePlanningInterface';
import {
  deleteFlexiblePlanning,
  getFlexiblePlanning,
} from '../../api/flexiblePlanningsApi';
import { getStrapiCatalog } from '../../strapi/escuelas';
import {
  Aprendizaje,
  AprendizajeFundamental,
  Bloque,
  Competencia,
  Eje,
  GenericResult,
  LenguajeArtistico,
  PracticaSocial,
  RecursoEducativo,
  Tema,
  TipoTexto,
} from '../../types/EntidadesInterface';
import {
  updateFlexiblePlanning,
  createFlexiblePlanning,
} from '../../api/flexiblePlanningsApi';
import { useTracking } from 'react-tracking';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import ModalPublicFlexiblePlanning from './modalPublicFlexiblePlanning.modal';


pdfMake.vfs = pdfFonts.pdfMake.vfs; // Load pdfMake fonts (optional)
interface MSNavigator extends Navigator {
  msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
}

declare var navigator: MSNavigator;

const PlaneaionesFlexiblesScreen = () => {
  const { trackEvent } = useTracking();
  const { planningId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [planning, setPlanning] = useState<Partial<IFlexiblePlanning>>({});
  const [recursos, setRecursos] = useState<
    { id: number; attributes: RecursoEducativo }[]
  >([]);
  const [tiposRecurso, setTiposRecurso] = useState<GenericResult>({ data: [] });

  const [showPrint, setShowPrint] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModalPublic, setShowModalPublic] = useState(false);
  const [filtros, setFiltros] = useState<{
    ciclosEscolares: GenericResult;
    modelosEducativo: GenericResult;
    grados: GenericResult;
    grupos: GenericResult;
    camposFormacion: GenericResult;
    materias: GenericResult;
    pReflexion: GenericResult;
    instEvaluacion: GenericResult;
    libros: GenericResult;
  }>({
    ciclosEscolares: { data: [] },
    modelosEducativo: { data: [] },
    grados: { data: [] },
    grupos: { data: [] },
    camposFormacion: { data: [] },
    materias: { data: [] },
    pReflexion: { data: [] },
    instEvaluacion: { data: [] },
    libros: { data: [] },
  });
  const [bloques, setBloques] = useState<{ id: number; attributes: Bloque }[]>(
    []
  );
  const [ejes, setEjes] = useState<{ id: number; attributes: Eje }[]>([]);
  const [temas, setTemas] = useState<{ id: number; attributes: Tema }[]>([]);
  const [aprendizajes, setAprendizajes] = useState<
    {
      id: number;
      attributes: Aprendizaje;
    }[]
  >([]);
  const [competencias, setCompetencias] = useState<
    {
      id: number;
      attributes: Competencia;
    }[]
  >([]);
  const [fundamentales, setFundamentales] = useState<
    {
      id: number;
      attributes: AprendizajeFundamental;
    }[]
  >([]);
  const [pSociales, setPSociales] = useState<
    {
      id: number;
      attributes: PracticaSocial;
    }[]
  >([]);
  const [tipoTextos, setTipoTextos] = useState<
    {
      id: number;
      attributes: TipoTexto;
    }[]
  >([]);
  const [lenguajeArtistico, setLenguajeArtistico] = useState<
    {
      id: number;
      attributes: LenguajeArtistico;
    }[]
  >([]);
  const componentRef = useRef<HTMLDivElement>(null);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const handlePrint = () => {
    trackEvent({
      event: 'printPlanning',
      page: '/planeacion-flexible',
      planningId: planning._id,
      fullPath: window.location.href,
    });
    window.print();
  };


  // PDF Generator
  const handlePdf = async () => {
    const printElement = componentRef.current;
  
    if (!printElement) {
      setIsLoading(false);
      return;
    }
    const fileName = planning.name || 'Planeacion';
    const options = {
      margin: 5,
      filename: `${fileName.toLowerCase().replace(/\s/g, '-')}.pdf`,
      image: { type: 'jpeg', quality: 1 }, // Increase the image quality
      html2canvas: { scale: 2, dpi: 300 }, // Increase the scale and dpi for better resolution
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
  
    // Wait for html2pdf to load fonts and render the PDF
    await html2pdf().set(options).from(printElement).save();
  
    setIsLoading(false);
    setShowPrint(false);
  };
  

  // Word generator
  function Export2Doc(elementId: any, filename = '') {
    const preHtml = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
      <meta charset='utf-8'>
      <title>Export HTML To Doc</title>
      <style>
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      }
      body {
        background: url(https://www.pacoteacher.com/assets/images/elements/paco-a4.jpg) center center no-repeat;
        background-size: cover;
      }
      h1 {
        color: #4b0082;
      }
        h5 {
          color: #8439FF;
          font-size: 18px;
        }
        h6 {
          font-size: 12px;
        }
        p {
          font-size: 14px;
        }
        img,
        .print-plan__logo {
          width: 60px !important;
        }
        .print-section__item {
          border-bottom: 1px solid #DEE6F2;
        }
      </style>
    </head>
    <body>
    `;
        const postHtml = "</body></html>";
        const element = document.getElementById(elementId);
        // console.log('ELEMENT::', element);
        if (!element) {
            console.error("Element not found!");
            return;
        }
        const html = preHtml + element.innerHTML + postHtml;
        // console.log('HTML::', html);
        const blob = new Blob(['ufeff', html], {
            type: 'application/msword'
        });

        const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        filename = filename ? filename + '.doc' : 'document.doc';

        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            downloadLink.href = url;
            downloadLink.download = filename;
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    }
    const downloadWordDocument = () => {
        Export2Doc('print-placeholder', `${planning.name || 'mi_planeacion_PACO-Teacher'}`);
    };


  const fetchCatalog = async (query: string, route: string) => {
    try {
      const result = await getStrapiCatalog(query, route);
      if (result.data.length) {
        return result.data;
      }
    } catch (error) {
      setError(`${error}`);
    }
    return [];
  };

  const getCatalogs = async () => {
    const ciclosEscolares = await getStrapiCatalog(
      qs.stringify({ sort: ['id:asc'], populate: '*' }),
      '/ciclos-escolares'
    );
    const modelosEducativo = await getStrapiCatalog(
      qs.stringify({
        sort: ['modelo_educativo:asc'],
        populate: '*',
        filters: {
          id: {
            $in: [1, 2],
          },
        },
      }),
      '/modelos-educativos'
    );
    const grados = await getStrapiCatalog(
      qs.stringify({ sort: ['id:asc'], filters: { level: { $eq: 'Primaria' } } }),
      '/grados'
    );
    const grupos = await getStrapiCatalog(
      qs.stringify({ sort: ['id:asc'] }),
      '/grupos'
    );
    const camposFormacion = await getStrapiCatalog(
      qs.stringify({
        sort: ['Nombre:asc'],
      }),
      '/campos-de-formacions'
    );
    const materias = await getStrapiCatalog(
      qs.stringify({
        sort: ['Materia:asc'],
        populate: ['modelo_educativo', 'grados', 'campos_de_formacion'],
      }),
      '/materias'
    );
    const pReflexion = await getStrapiCatalog(
      qs.stringify({
        sort: ['Nombre:asc'],
      }),
      '/preguntas-reflexion'
    );
    const instEvaluacion = await getStrapiCatalog(
      qs.stringify({
        sort: ['Nombre:asc'],
      }),
      '/instrumentos-de-evaluacion'
    );
    const libros = await getStrapiCatalog(
      qs.stringify({
        sort: ['id:asc'],
        populate: '*',
        pagination: { pageSize: 100 },
      }),
      '/libros'
    );

    setFiltros({
      ciclosEscolares,
      modelosEducativo,
      grados,
      grupos,
      camposFormacion,
      materias,
      pReflexion,
      instEvaluacion,
      libros,
    });
    setIsLoading(false);
  };

  const getCustomCatalogs = async (
    currentPlanning: Partial<IFlexiblePlanning>
  ) => {
    try {
      if (currentPlanning.gradoEscolar?.documentId) {
        if (
          currentPlanning.asignaturas?.length &&
          currentPlanning.modeloEducativo?.documentId
        ) {
          const materiaIds = currentPlanning.asignaturas.map(
            ({ asignatura }) => asignatura?.documentId
          );
          const bloqueIds = currentPlanning.asignaturas.map(
            ({ bloque }) => bloque?.documentId
          );
          const ejesIds = currentPlanning.asignaturas.map(
            ({ eje }) => eje?.documentId
          );
          const pSocialesIds = currentPlanning.asignaturas.map(
            ({ pSocial }) => pSocial?.documentId
          );
          const temasIds = currentPlanning.asignaturas.map(
            ({ tema }) => tema?.documentId
          );

          const andsBloque = {
            materias: { id: { $in: materiaIds } },
            grados: {
              id: { $eq: currentPlanning.gradoEscolar?.documentId },
            },
          };
          const andsEje = {
            materia: { id: { $in: materiaIds } },
            grados: {
              id: { $eq: currentPlanning.gradoEscolar?.documentId },
            },
            modelo_educativo: {
              id: { $eq: currentPlanning.modeloEducativo?.documentId },
            },
          };
          const andsApFund = {
            materias: { id: { $in: materiaIds } },
            grado: {
              id: { $eq: currentPlanning.gradoEscolar?.documentId },
            },
          };

          const query = {
            sort: ['id:asc'],
            populate: '*',
            pagination: { pageSize: 50 },
            filters: {
              $and: [andsBloque],
            },
          };

          const resultBloques = await fetchCatalog(
            qs.stringify({ ...query }),
            '/bloques'
          );
          const resultEjes = await fetchCatalog(
            qs.stringify({ ...query, filters: { $and: [andsEje] } }),
            '/ejes'
          );
          const resultApFund = await fetchCatalog(
            qs.stringify({ ...query, filters: { $and: [andsApFund] } }),
            '/aprendizajes-fundamentales'
          );
          setBloques(
            resultBloques as {
              id: number;
              attributes: Bloque;
            }[]
          );
          setEjes(resultEjes as { id: number; attributes: Eje }[]);
          setFundamentales(
            resultApFund as {
              id: number;
              attributes: AprendizajeFundamental;
            }[]
          );

          if (ejesIds) {
            const andsTema = {
              materia: { id: { $in: materiaIds } },
              grados: {
                id: { $eq: currentPlanning.gradoEscolar?.documentId },
              },
              modelo_educativo: {
                id: { $eq: currentPlanning.modeloEducativo?.documentId },
              },
              ejes: { id: { $in: ejesIds } },
            };
            const resultTemas = await fetchCatalog(
              qs.stringify({ ...query, filters: { $and: [andsTema] } }),
              '/temas'
            );
            setTemas(resultTemas as { id: number; attributes: Tema }[]);
          }

          if (ejesIds.length || temasIds.length) {
            const andsApEsp = {
              materia: { id: { $in: materiaIds } },
              grado: {
                id: { $eq: currentPlanning.gradoEscolar?.documentId },
              },
              modelo_educativo: {
                id: { $eq: currentPlanning.modeloEducativo?.documentId },
              },
            };
            const resultApEsp = await fetchCatalog(
              qs.stringify({
                ...query,
                filters: {
                  $and: [andsApEsp],
                  $or: [
                    {
                      ejes: { id: { $in: ejesIds } },
                      temas: { id: { $in: temasIds } },
                      bloque: { id: { $in: bloqueIds } },
                      practica_social: { id: { $in: pSocialesIds } },
                    },
                  ],
                },
              }),
              '/aprendizajes'
            );
            setAprendizajes(
              resultApEsp as { id: number; attributes: Aprendizaje }[]
            );

            const andsComp = {
              materia: { id: { $in: materiaIds } },
              grados: {
                id: { $eq: currentPlanning.gradoEscolar?.documentId },
              },
              modelo_educativo: {
                id: { $eq: currentPlanning.modeloEducativo?.documentId },
              },
            };
            const resultComp = await fetchCatalog(
              qs.stringify({ ...query, filters: { $and: [andsComp] } }),
              '/competencias'
            );
            setCompetencias(
              resultComp as { id: number; attributes: Competencia }[]
            );

            const andsLenguajeArtistico = {
              ejes: { id: { $in: ejesIds } },
              temas: { id: { $in: temasIds } },
            };
            const resultLenguajeArtistico = await fetchCatalog(
              qs.stringify({
                ...query,
                filters: { $and: [andsLenguajeArtistico] },
              }),
              '/lenguaje-artisticos'
            );
            setLenguajeArtistico(
              resultLenguajeArtistico as {
                id: number;
                attributes: LenguajeArtistico;
              }[]
            );
          }

          if (ejesIds.length && bloqueIds.length) {
            const andsPSociales = {
              grado: { id: { $eq: currentPlanning.gradoEscolar?.documentId } },
              bloque: { id: { $in: bloqueIds } },
              eje: { id: { $in: ejesIds } },
            };
            const resultPSociales = await fetchCatalog(
              qs.stringify({ ...query, filters: { $and: [andsPSociales] } }),
              '/practicas-sociales'
            );
            setPSociales(
              resultPSociales as { id: number; attributes: PracticaSocial }[]
            );
          }

          if (pSocialesIds.length) {
            const andsTipoTextos = {
              practica_socials: { id: { $in: pSocialesIds } },
            };
            const resultTipoTextos = await fetchCatalog(
              qs.stringify({ ...query, filters: { $and: [andsTipoTextos] } }),
              '/tipo-de-textos'
            );
            setTipoTextos(
              resultTipoTextos as { id: number; attributes: TipoTexto }[]
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
      setError('Hubo un error intenta de nuevo');
    }
  };

  const getResources = async (allResources: number[]) => {
    try {
      if (allResources.length) {
        // @ts-ignore: Unreachable code error
        const ids = [...new Set(allResources)];
        const query = {
          sort: ['id:asc'],
          populate: '*',
          pagination: { pageSize: 50 },
          filters: {
            id: { $in: ids },
          },
        };
        const response = await getStrapiCatalog(
          qs.stringify(query),
          '/recursos-educativos'
        );
        const tiposRecurso = await getStrapiCatalog(
          qs.stringify({ sort: ['Nombre:asc'], populate: '*' }),
          '/tipo-de-recursos'
        );
        setRecursos(
          response.data as {
            id: number;
            attributes: RecursoEducativo;
          }[]
        );
        setTiposRecurso(tiposRecurso);
      }
    } catch (error) {
      setError(`${error}`);
    }
  };

  const getCurrentPlanning = async (id: string, token: string) => {
    try {
      const foundedPlanning = await getFlexiblePlanning(id, token);
      if (foundedPlanning && Object.keys(foundedPlanning).length) {
        setPlanning(foundedPlanning);
        if (foundedPlanning.asignaturas?.length) {
          getCustomCatalogs(foundedPlanning);
          const allResources: StrapiDoc[] = [];
          foundedPlanning.asignaturas.forEach((asignatura) => {
            const sessions = asignatura.sessions;
            sessions?.forEach((session) => {
              const { inicio, desarrollo, cierre } = session;
              [inicio, desarrollo, cierre].forEach((section) => {
                if (section) {
                  const { resources } = section;
                  if (resources && resources.length) {
                    allResources.push(...resources);
                  }
                }
              });
            });
          });
          if (allResources.length) {
            getResources(allResources.map((r) => Number(r.documentId)));
          }
        }
      } else {
        navigate('/mis-planeaciones');
      }
    } catch (error) {
      console.error(error);
      setError('Hubo un error intenta de nuevo');
    }
    setIsLoading(false);
  };

  const updatePlanningState = (key: keyof IFlexiblePlanning, value: any) => {
    const newPlanning = {
      ...planning,
      [key]: value,
    };
    setPlanning(newPlanning);
    return newPlanning as Partial<IFlexiblePlanning>;
  };

  const updatePlanning = async (newPlanning: Partial<IFlexiblePlanning>) => {
    try {
      const { _id: planningId, name, gradoEscolar } = newPlanning;
      if (planningId) {
        const update = await updateFlexiblePlanning(
          planningId,
          { ...newPlanning },
          `${user.token}`
        );

        const { disabledFields } = update;
        const sessions: IFlexiblePlanningSession[] = [];
        const disabledAsignaturas = (update.asignaturas || []).filter((a) => {
          sessions.push(...(a.sessions || []));
          const ids = (disabledFields?.asignaturas || []).map(
            (dF) => dF.asignaturaId
          );
          return !ids.includes(`${a._id}`);
        });
        const disabledSessions = sessions.filter((a) => {
          const ids = (disabledFields?.sessions || []).map(
            (dF) => dF.sessionId
          );
          return !ids.includes(`${a._id}`);
        });

        if (disabledAsignaturas.length || disabledSessions.length) {
          const newDisabledFields: DisabledFields = {
            ...update.disabledFields,
            asignaturas: (update.disabledFields?.asignaturas || []).concat(
              disabledAsignaturas.map((a) => ({
                asignaturaId: a._id || '',
                fields: [],
              }))
            ),
            sessions: (update.disabledFields?.sessions || []).concat(
              disabledSessions.map((s) => ({
                sessionId: s._id || '',
                fields: [],
              }))
            ),
          };

          const newUpdate = await updateFlexiblePlanning(
            planningId,
            { ...update, disabledFields: newDisabledFields },
            `${user.token}`
          );

          setPlanning({ ...newUpdate });
        } else {
          setPlanning({ ...update });
        }

        return update;
      } else {
        const validPlanning = [name, gradoEscolar?.value].every(
          (k) => k && k !== ''
        );
        if (validPlanning) {
          const asignaturas: IFlexiblePlanningAsignatura[] = [
            {
              description: '',
            },
          ];
          const result = await createFlexiblePlanning(
            { ...newPlanning, userId: user._id, asignaturas },
            `${user.token}`
          );
          if (result && result.asignaturas?.length) {
            const asignatura = result.asignaturas[0];
            const update = await updateFlexiblePlanning(
              result._id,
              {
                ...result,
                disabledFields: {
                  ...result.disabledFields,
                  asignaturas: [
                    {
                      asignaturaId: asignatura._id || '',
                      fields: [],
                    },
                  ],
                },
              },
              `${user.token}`
            );
            setPlanning({ ...update });
            navigate(`/planeacion-flexible/${result._id}`);
          } else {
            setPlanning({ ...result });
          }
          return result;
        } else {
          setPlanning({ ...newPlanning });
          return newPlanning;
        }
      }
    } catch (error) {
      navigate('/iniciar-sesion');
    }
    return {};
  };

  const checkAndSetPublicPlanning = async (checked: boolean) => {
    const isValid =
      planning.asignaturas?.length &&
      planning.asignaturas.some((a) => a.asignatura?.documentId) &&
      planning.asignaturas.some(
        (a) => a.sessions?.length && a.sessions.every((s) => !!s.name)
      );
    if (isValid) {
      if (checked) {
        setShowModalPublic(true);
      } else {
        updatePlanning({ ...planning, isPublic: false });
      }
    } else {
      Swal.fire(
        'Planeación pública',
        'Por favor añade por lo menos una asignatura y una secuencia didactica a tu planeación.',
        'warning'
      );
      updatePlanning({ ...planning, isPublic: false });
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/planeacion-flexible',
      planningId: planningId,
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            setUser({ ...kameUser, token: jwtToken });
            if (planningId) {
              getCurrentPlanning(planningId, jwtToken);
            }
            getCatalogs();
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      <ModalPublicFlexiblePlanning
        user={user}
        planning={planning}
        show={showModalPublic}
        size="md"
        onHide={() => setShowModalPublic(!showModalPublic)}
        updatePlanningState={updatePlanningState}
        updatePlanning={updatePlanning}
        recursos={recursos}
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <PlanNavComponent />
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/mis-planeaciones`}
          back="Regresar"
          urlSecond={'/mis-planeaciones'}
          urlSecondText={'Mis planeaciones guardadas'}
          urlThird={'#'}
          urlThirdText={'Crear planeación'}
        />
        <section className="new-planning-header">
          <figure className="new-plannin-header__image">
            <img src="/assets/images/pacomax/julia.png" alt="Julia" />
          </figure>
          <div className="container">
            <div className="new-planning-header__top row">
              <div className="col-12">
                <p className="new-header-top__title">
                  Es hora de crear tu <span>planeación</span>
                </p>
              </div>
            </div>
            <div className="new-planning__actions row">
              <div className="planning-options col-12">
                <button
                  type='button'
                  onClick={handlePrint}
                  className="planning-options__button"
                >
                  <span className="material-icons-outlined">print</span>
                  Imprimir
                </button>
                <button
                  type='button'
                  onClick={(evt) => {
                    evt.preventDefault();
                    setIsLoading(true);
                    setShowPrint(true);
                    trackEvent({
                      event: 'downloadPlanning',
                      page: '/planeacion-flexible',
                      planningId: planning._id,
                      fullPath: window.location.href,
                    });
                    setTimeout(() => {
                      handlePdf();
                    }, 200);
                  }}
                  className="planning-options__button"
                >
                  <span className="material-icons-outlined">file_download</span>
                  Descargar
                </button>
                <button
                    type='button'
                    onClick={downloadWordDocument}
                    className="planning-options__button"
                >
                    <span className="material-icons-outlined">text_snippet</span>
                    Documento Word
                </button>
              </div>
            </div>
            <div className="new-planning__public row">
              <div className="new-planning__public-col col-12">
                <div className="toggle-complete">
                  <div className="toggle-action">
                    <input
                      type="checkbox"
                      id="isPublic"
                      checked={planning.isPublic}
                      onChange={(evt) => {
                        const { checked } = evt.target;
                        checkAndSetPublicPlanning(checked);
                      }}
                    />
                    <div>
                      <figure></figure>
                    </div>
                  </div>
                  <label htmlFor="toggle">Hacer planeación pública</label>
                </div>
              </div>
            </div>
            <div className="new-planning__intro row">
              <div className="mx-auto col-md-8 col-12">
                <article>
                  <h3 className="h6">Instrucciones</h3>
                  <p>
                    Esta planeación es un formato flexible que puedes
                    personalizar y adaptar de acuerdo a tus necesidades o
                    contexto educativo. Con ayuda de nuestros botones
                    interactivos puedes desplegar apartados para completar los
                    datos que necesitas en tu planeación. Al activarlos estos
                    aparecerán para que puedas agregar información en línea o
                    dejar el espacio en blanco para que se incluyan en el
                    formato impreso; en caso de no necesitar alguno de los
                    apartados, puedes desactivarlo para que no aparezca en línea
                    o impreso.
                  </p>
                  <p>
                    Nuestro formato te permite vincular fácilmente el contenido
                    de tu planeación a los Planes y Programas de la SEP, así
                    como agregar recursos digitales de PACO Max y otros
                    recomendados por PACO Teacher para enriquecer tus clases.
                  </p>
                  <p>
                    También podrás previsualizar tu formato antes de imprimirlo,
                    guardar tus planeaciones en línea y compartirlas con otros
                    docentes cuando desees.
                  </p>
                  <p>* Estos campos son obligatorios.</p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <section className="new-planning-wrapp">
          <div className="container">
            <div className="mx-auto col-md-8 col-12">
              <div className="new-planning__autosave mb-4">
                <p>
                  <span className="material-icons">save</span> Las acciones que
                  realizas en tu planeación se guardan automáticamente para que
                  no pierdas tu progreso
                </p>
              </div>
              <DatosGeneralesComponent
                planning={{ ...planning }}
                user={user}
                filtros={filtros}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
              />
              <AsignaturaComponent
                planning={{ ...planning }}
                user={user}
                recursos={recursos}
                tiposRecurso={tiposRecurso}
                filtros={filtros}
                bloques={bloques}
                ejes={ejes}
                pSociales={pSociales}
                tipoTextos={tipoTextos}
                lenguajeArtistico={lenguajeArtistico}
                temas={temas}
                aprendizajes={aprendizajes}
                competencias={competencias}
                fundamentales={fundamentales}
                getCustomCatalogs={getCustomCatalogs}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
              />
              <NotasAdicionalesComponent
                planning={{ ...planning }}
                updatePlanningState={updatePlanningState}
                updatePlanning={updatePlanning}
              />
              <div className="new-planning__public row">
                <div className="new-planning__public-col col-12">
                  <div className="toggle-complete">
                    <div className="toggle-action">
                      <input
                        type="checkbox"
                        id="isPublic"
                        checked={planning.isPublic}
                        onChange={(evt) => {
                          const { checked } = evt.target;
                          checkAndSetPublicPlanning(checked);
                        }}
                      />
                      <div>
                        <figure></figure>
                      </div>
                    </div>
                    <label htmlFor="toggle">Hacer planeación pública</label>
                  </div>
                </div>
              </div>
              <div className="new-planning__bottom">
                <button
                  className="btn btn--type1"
                  onClick={(evt) => {
                    trackEvent({
                      event: 'guardarPlaneacion',
                      page: '/planeacion-flexible',
                      planningId: planning._id,
                      fullPath: window.location.href,
                    });
                    evt.preventDefault();
                    return Swal.fire({
                      icon: 'success',
                      title: '¡Listo!',
                      text: `Tu planeación se ha guardado con éxito`,
                      showCancelButton: true,
                      confirmButtonText: 'Ver mis planeaciones',
                      cancelButtonText: 'Continuar editando',
                      showLoaderOnConfirm: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate('/mis-planeaciones');
                      }
                    });
                  }}
                >
                  Guardar y publicar
                </button>
                {!!planning._id && (
                  <button
                    className="btn btn--type5"
                    onClick={(e) => {
                      trackEvent({
                        event: 'vistaPrevia',
                        page: '/planeacion-flexible',
                        planningId: planning._id,
                        fullPath: window.location.href,
                      });
                      e.preventDefault();
                      navigate(
                        `/planeacion-flexible-vista-previa/${planning._id}`
                      );
                    }}
                  >
                    Vista previa
                  </button>
                )}
                <button
                  className="btn btn--delete"
                  onClick={(evt) => {
                    evt.preventDefault();
                    return Swal.fire({
                      icon: 'question',
                      title: 'Eliminar planeación',
                      text: `¿Deseas eliminar esta planeación?`,
                      showCancelButton: true,
                      confirmButtonText: 'Si, eliminar',
                      cancelButtonText: 'Cancelar',
                      showLoaderOnConfirm: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        trackEvent({
                          event: 'deletePlanning',
                          page: '/planeacion-flexible',
                          planningId: planning._id,
                          fullPath: window.location.href,
                        });
                        deleteFlexiblePlanning(
                          `${planning._id}`,
                          `${user.token}`
                        ).then(() => navigate('/mis-planeaciones'));
                      }
                    });
                  }}
                >
                  Eliminar planeación
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
      <div id="print-placeholder" ref={componentRef}>
        <PrintPlanComponent
          show={showPrint}
          planning={planning}
          resources={recursos}
        />
      </div>
    </section>
  );
};

export default PlaneaionesFlexiblesScreen;
