import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { useParams, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './explainDetail.screen.scss';
import { getPecExplanationTopic } from '../../../strapi/pecContent';
import { DatumExplanationTopic } from '../../../types/PECInterface';
import NavigationHints from '../../../components/NavigationHints/navigationHints';

const ExplainDetailScreen = () => {
  const { materia, slug } = useParams();
  const [searchParams] = useSearchParams();
  const { trackEvent } = useTracking();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [explanationTopic, setExplanationTopic] = useState<
    Partial<DatumExplanationTopic>
  >({});

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchPecExplanationTopic = async () => {
    try {
      const query = {
        populate: '*',
        filters: {
          $or: [
            { slug: { $eq: slug } },
            { slug: { $eq: slug?.replace('-estudia-aprende-facil', '') } },
          ],
        },
        pagination: { pageSize: 100 },
      };
      const explanationTopicsResult = await getPecExplanationTopic(
        qs.stringify(query)
      );

      if (
        explanationTopicsResult &&
        explanationTopicsResult.data &&
        explanationTopicsResult.data.length
      ) {
        setExplanationTopic(explanationTopicsResult.data[0]);
        trackEvent({
          event: 'pageView',
          page: '/paco-te-explica',
          fullPath: window.location.href,
          explanationTitle: explanationTopicsResult.data[0].attributes?.title,
        });
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            if (slug) {
              setIsLoading(true);
              fetchPecExplanationTopic();
            }
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={searchParams.get('goBack') || ''}
          back="Regresar"
          urlSecond={searchParams.get('goBack') || ''}
          urlSecondText={
            searchParams.get('goBack')?.includes('/explicaciones-indice')
              ? 'Explicaciones por tema'
              : 'Respuestas a los libros'
          }
          urlThird={`/paco-te-explica/${materia}/${slug}`}
          urlThirdText={`${explanationTopic.attributes?.subjectTitle}`}
        />
        <section className="container body-activity">
          <div className="row row--index">
            <div className="mx-auto col-md-10 col-12">
              <section className="header-activity header--empty">
                <figure className="header-activity__book">
                  <img
                    src="/assets/images/icon/explain.png"
                    alt="Explicaciones"
                  />
                </figure>
                <article className="header-activity__text-head">
                  <p>{`${(
                    explanationTopic.attributes?.subjectTitle || ''
                  ).toLocaleUpperCase()}`}</p>
                  <h1 className="header-activity__title h3">
                    {`${explanationTopic.attributes?.title || ''}`}
                  </h1>
                </article>
              </section>
              <section className="body-activity__content ">
                <div className="block-activity activity--empty">
                  <article className="game__article">
                    {explanationTopic &&
                    explanationTopic.attributes?.content ? (
                      <ReactMarkdown
                        linkTarget={'_blank'}
                        skipHtml={false}
                        rehypePlugins={[rehypeRaw]}
                      >{`${explanationTopic.attributes?.content}`}</ReactMarkdown>
                    ) : (
                      <p>
                        {' '}
                        <strong>404</strong> Explicación no encontrada
                      </p>
                    )}
                  </article>
                </div>
              </section>
            </div>
          </div>
        </section>
        <NavigationHints
          button1={{
            link: '#',
            texto: 'Asesor pedagógico con Inteligencia Artificial',
            color: 'btn--color5',
            icon: false,
            arti: true,
            max: false,
            artiChat: true,
          }}
          button2={{
            link: '/busqueda',
            texto: 'Recursos educativos',
            color: 'btn--color3',
            iconText: 'icon--grid4',
            icon: true,
            arti: false,
            max: false,
          }}
          button3={{
            link: '/planeaciones/nueva-escuela-mexicana',
            texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
            color: 'btn--color2',
            iconText: 'icon--grid2',
            icon: true,
            arti: false,
            max: false,
          }}
          user={user}
        />
      </div>
      <FooterComponent />
    </section>
  );
};

export default ExplainDetailScreen;
