import React from 'react';

const PlanNavComponent = () => {

  return (
    <>
     <nav className="plan-nav">
        <ul className="plan-nav__list">
          <li className="plan-nav__item">
            <a href="#informacion" className="plan-nav__link">
              <span className="material-icons-outlined">description</span>
              Datos generales
            </a>
          </li>
          <li className="plan-nav__item">
            <a href="#asignatura" className="plan-nav__link">
              <span className="material-icons-outlined">library_add</span>
              Asignaturas
            </a>
          </li>
          <li className="plan-nav__item">
            <a href="#notas" className="plan-nav__link">
              <span className="material-icons-outlined">extension</span>
              Notas adicionales
            </a>
          </li>
        </ul>
     </nav>
    </>
  );
};

export default PlanNavComponent;
