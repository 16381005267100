import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './modalSuggestion.component.scss';
import { useTracking } from 'react-tracking';
import Swal from 'sweetalert2';
import validator from 'validator';
import TextareaComponent from '../../Form/textarea/textarea.component';
import InputComponent from '../../Form/input/input.component';


type Props = {
  show: boolean;
  onHide: () => void;
  correo?: boolean;
};

const ModalSuggestionComponent = ({ show, onHide, correo }: Props) => {

  const { trackEvent } = useTracking();
  const [suggestions, setComments] = useState<string>('');
  const [email, setEmail] = useState<string>();

  function isValidEmail(email: any) {
    return validator.isEmail(email);
  }

  const confirmSend = async () => {
    let valid = false;
    if(correo) {
      if(isValidEmail(email)){
        valid = [suggestions, email].every(
          (k) => k !== ''
        );
      }
    } else {
      valid = [suggestions].every(
        (k) => k !== ''
      );
    }
    
    if (valid) {
      trackEvent({
        event: 'suggestions',
        fullPath: window.location.href,
        suggestions,
        email
      });
      Swal.fire({
        html: `<div className='modal__sug-top'>
          <h4>¡Gracias! Tus sugerencias fueron enviadas a nuestro equipo.</h4>
        </div>`,
        imageUrl: "../../assets/images/elements/taco.png",
        color: '#003980',
        imageWidth: 125,
        imageHeight: 100,
        imageAlt: 'Arti',
        showCloseButton: true,
        showConfirmButton: false,
        timer: 3500,
      }).then(() => {
        setComments('');
        setEmail('')
        onHide();
      });
    }else {
      Swal.fire('Ooops', 'Captura todos los datos correctamente', 'warning');
    }
    return true;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="modal-sug"
      centered
      className="modal--sug"
      backdrop="static"
    >
      <Modal.Header className="modal__title-icon">
        <Modal.Title id="modal-sug">
          <h4>¿Tienes una <br />idea para <br />mejorar? </h4>
        </Modal.Title>
          <figure className="modal-sug-image-back">
            <img src="../../assets/images/elements/taco.png" alt="Taco" />
          </figure>
          <button
            className="modal__close close--red"
            onClick={(e) => {
              e.preventDefault();
              onHide();
            }}
          >
            <span className="material-icons-outlined icon">close</span>
          </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal__sug-top col-12">
          <h4 className="text-center">¡Compártela!</h4>
        </div>
        <div className="modal__sug-middle col-12">
          <TextareaComponent
            id="suggestions"
            placeholder="Escribe un comentario o sugerencia..."
            value={suggestions || ''}
            onChangeCapture={(evt) =>
              setComments(
                (evt?.target as HTMLInputElement).value || '',
              )
            }
          />
        </div>
        { correo ? 
          <div className="modal__sug-middle col-12">
            <h6>Correo electrónico</h6>
            <InputComponent
              type={'text'}
              id="comments"
              placeholder="Escribe tu correo electrónico"
              value={email || ''}
              onChangeCapture={(evt) =>
                setEmail(
                  (evt?.target as HTMLInputElement).value || '',
                )
              }
            />
          </div> 
          : <></>
        }
        <div className="modal__sug-bottom col-12">
          <p className="text-center">Ver <a href="/terminos-y-condiciones">términos y condiciones</a> y <a href="/aviso-de-privacidad">aviso de privacidad</a></p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={(e) => {
            e.preventDefault();
            confirmSend();
          }}
          className="btn btn--type3"
        >
          Listo
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSuggestionComponent;
