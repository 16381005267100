import React from 'react';
import { EstrategiasDidacticas, RecursoEducativo } from '../../../types/EntidadesInterface';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

type Props = {
  show: boolean;
  estrategia?: EstrategiasDidacticas;
  temaId: string;
  resource?: RecursoEducativo;
};

const PrintStraComponent = ({ show, estrategia, temaId, resource }: Props) => {

  return (
    <>
      <section className={`${show ? 'print-plan-body print-section' : 'print-plan-body '}`}>
        <div className="print-plan__title">
          <figure className="print-plan__logo">
            <img src="/assets/images/logo/a22-logo.svg" alt="PACO Teacher" />
          </figure>
          <p>Estrategia didáctica</p>
          <h1>Enseña con PACO Max en ¡1, 2, 3!</h1>
        </div>
        <section className="print-plan__section">

          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col  col-12">
                <h5>{estrategia?.Titulo}</h5>
              </div>
            </div>
            <div className="print-section__row row">
              <div className="print-section__row__col col-12">
                <h6>Sugerencia para preparar a tu grupo:</h6>
                <p>Tiempo aproximado: {estrategia?.timePrepara}</p>
                <p>{estrategia?.preparaGrupo}</p>
                <ReactMarkdown
                  skipHtml={false}
                >{`${estrategia?.actividadPrepara}`}</ReactMarkdown>
              </div>
            </div>
          </div>

          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col  col-12">
                <h5>Actividad: {estrategia?.Titulo}</h5>
                <article className="print-section_strategy-article">
                  <p>Tiempo aproximado: {estrategia?.time}</p>
                  <p>Propósito : {estrategia?.propositos_de_ensenanza?.data.attributes.Nombre}</p>
                  <p>Estrategia : {estrategia?.estrategia?.data.attributes.Nombre}</p>
                  {( estrategia?.habilidades?.data.map((t, key) => {
                    return(
                      <>
                      {(t.id.toString() == temaId) ?
                        <p key={key}>Concepto/tema : {t.attributes.habilidades}</p>
                        : <></>
                      }
                      </>
                    )})
                  )}
                  <p>Material : {estrategia?.material}</p>
                </article>
              </div>
            </div>
              <div className="print-section__row m-0 row">
                <section className="print-subsection">
                 
                  <div className="print-section__row row">
                    <div className="print-section__row__col col-12">
                      <ul>
                          <li>
                            <ReactMarkdown
                            >{`${estrategia?.actividad1}`}</ReactMarkdown> 
                          </li>
                          {estrategia?.positionResource == 1 ?
                            <>
                            <li>{estrategia.tipo_de_recursos?.data[0].attributes.Nombre}: {resource?.Nombre}
                            </li>
                            <div className="print-section__row section__row-subsection row">
                              <div className="row-col--thirdtitle col-12">
                                <h6>Recurso PACO Max</h6>
                              </div>
                              <div className="print-section__row__col col-12">
                                <section className="prin-section__session">
                                  <div className="print-section__row row">
                                    <div className="print-section__row__col col-12">
                                      <h6>{resource?.Nombre}</h6>
                                      <p>{resource?.descripcion}</p>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                            </>
                            : <></>
                          }
                          <li>
                            <ReactMarkdown
                              skipHtml={false}
                            >{`${estrategia?.actividad2}`}</ReactMarkdown> 
                          </li>
                          {estrategia?.positionResource == 2 ?
                            <>
                            <li>{estrategia.tipo_de_recursos?.data[0].attributes.Nombre}: 
                              {resource?.Nombre}
                            </li>
                            <div className="print-section__row section__row-subsection row">
                              <div className="row-col--thirdtitle col-12">
                                <h6>Recurso PACO Max</h6>
                              </div>
                              <div className="print-section__row__col col-12">
                                <section className="prin-section__session">
                                  <div className="print-section__row row">
                                    <div className="print-section__row__col col-12">
                                      <h6>{resource?.Nombre}</h6>
                                      <p>{resource?.descripcion}</p>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                            </>
                            : <></>
                          }
                          <li>
                            <ReactMarkdown
                              skipHtml={false}
                            >{`${estrategia?.actividad3}`}</ReactMarkdown> 
                          </li>
                          {estrategia?.positionResource == 3 ?
                            <>
                            <li>{`${estrategia.tipo_de_recursos?.data[0].attributes.Nombre}: `} 
                              {resource?.Nombre}
                            </li>
                            <div className="print-section__row section__row-subsection row">
                              <div className="row-col--thirdtitle col-12">
                                <h6>Recurso PACO Max</h6>
                              </div>
                              <div className="print-section__row__col col-12">
                                <section className="prin-section__session">
                                  <div className="print-section__row row">
                                    <div className="print-section__row__col col-12">
                                      <h6>{resource?.Nombre}</h6>
                                      <p>{resource?.descripcion}</p>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                            </>
                            : <></>
                          }
                        </ul>
                    </div>
                    
                 </div>
              </section>
            </div>
          </div>

          <div className="print-section__item">
            <div className="print-section__row row-col--title row">
              <div className="print-section__row__col  col-12">
                <h5>Sugerencias para la evaluación de la actividad</h5>
              </div>
            </div>
            <div className="print-section__row row">
              <div className="print-section__row__col col-12">
                <p>{estrategia?.evaluacion}</p>
              </div>
            </div>
           
          </div>
         
        </section>
      </section>
    </>
  );
};

export default PrintStraComponent;
