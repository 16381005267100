import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../components/Footer/footer.component';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';
import './activityPec.screen.scss';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import { getPecBookAnswer, getPecBookById } from '../../strapi/pecContent';
import { DatumBook, DatumBookAnswer } from '../../types/PECInterface';
import NavigationHints from '../../components/NavigationHints/navigationHints';

const ActivityPecScreen = () => {
  const navigate = useNavigate();
  const { bookId, lessonId, pageNumber } = useParams();
  const { trackEvent } = useTracking();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [pages, setPages] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(pageNumber));
  const [answers, setAnswers] = useState<DatumBookAnswer[]>();
  const [book, setBook] = useState<Partial<DatumBook>>({});

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchPecBookSection = async () => {
    try {
      const query = {
        populate: '*',
        filters: {
          $and: [
            { pec_book: { id: { $eq: bookId } } },
            { pec_book_section: { id: { $eq: lessonId } } },
          ],
        },
        pagination: { pageSize: 100 },
      };
      const answersResult = await getPecBookAnswer(qs.stringify(query));

      if (answersResult && bookId) {
        setAnswers(answersResult.data);
        const foundedPages = [
          ...new Set(
            answersResult.data.map((answer) => answer.attributes.pageNumber)
          ),
        ];
        setPages(foundedPages);

        const bookResult = await getPecBookById(bookId);
        setBook(bookResult.data);
        console.log({ answersResult, foundedPages });
        trackEvent({
          event: 'pageView',
          page: '/actividad-pec',
          fullPath: window.location.href,
          sectionName:
            answersResult.data[0].attributes.pec_book_section.data.attributes
              .bookSectionName,
          bookName:
            answersResult.data[0].attributes.pec_book.data.attributes.bookName,
          gradoId: answersResult.data[0].attributes.grado.data.id,
        });
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  const transformLinkUri = (uri: string) => {
    return `${uri}?goBack=/actividad-pec/${bookId}/${lessonId}/${pageNumber}`;
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          setUser({ ...kameUser, token: jwtToken });
          if (bookId && lessonId) {
            setIsLoading(true);
            fetchPecBookSection();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/actividad-indice/${book.attributes?.grado.data.id}/${bookId}`}
          back="Regresar"
          urlSecond={`/actividad-indice/${book.attributes?.grado.data.id}/${bookId}`}
          urlSecondText={`${
            answers?.length
              ? answers[0].attributes.pec_book.data.attributes.bookName
              : ''
          }`}
          urlThird={`/actividad-pec/${bookId}/${lessonId}/${currentPage}`}
          urlThirdText={`Página ${currentPage}`}
        />
      </div>
      <section className="game-layout">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <section className="header-activity">
                {book.attributes?.bookImage.data ? (
                  <figure className="header-activity__book">
                    <img
                      src={book.attributes?.bookImage.data.attributes.url || ''}
                      alt={`${
                        answers
                          ? answers[0].attributes.pec_book.data.attributes
                              .bookName || ''
                          : ''
                      }`}
                    />
                  </figure>
                ) : (
                  <></>
                )}
                <article className="header-activity__text-head">
                  <p>{`${
                    answers
                      ? answers[0].attributes.pec_book.data.attributes.level ||
                        ''
                      : ''
                  }. ${
                    answers
                      ? answers[0].attributes.grado.data.attributes.Grado || ''
                      : ''
                  }`}</p>
                  <h1 className="header-activity__title h3">
                    {`${
                      answers
                        ? answers[0].attributes.pec_book.data.attributes
                            .bookName || ''
                        : ''
                    }`}
                  </h1>
                </article>
              </section>
            </div>
            <div className="col-md-8">
              <div className="title-activity">
                <h2 className="h3">Respuestas del libro</h2>
              </div>
              <section className="body-activity__content">
                <div className="block-activity">
                  <div className="block-activity__item-head">
                    <h3 className="h4">Consigna</h3>
                  </div>
                  {answers?.length &&
                  answers.filter(
                    (answer) => answer.attributes.pageNumber === currentPage
                  ).length ? (
                    answers
                      .filter(
                        (answer) => answer.attributes.pageNumber === currentPage
                      )
                      .map(({ attributes }, key) => {
                        const { questions, answers, explanations } = attributes;
                        return (
                          <div key={key}>
                            <article className="game__article">
                              <ReactMarkdown
                                transformLinkUri={transformLinkUri}
                                skipHtml={false}
                                rehypePlugins={[rehypeRaw]}
                              >{`${questions}`}</ReactMarkdown>
                              <p>
                                <strong>Respuesta:</strong>
                              </p>
                              <ReactMarkdown
                                transformLinkUri={transformLinkUri}
                                skipHtml={false}
                                rehypePlugins={[rehypeRaw]}
                              >{`${answers}`}</ReactMarkdown>
                              {explanations && explanations !== '' ? (
                                <article className="game__answer">
                                  <Accordion
                                    onSelect={(evt) => {
                                      evt
                                        ? trackEvent({
                                            event: 'verExplicacion',
                                            page: '/actividad-pec',
                                            fullPath: window.location.href,
                                            bookPage: currentPage,
                                          })
                                        : '';
                                    }}
                                  >
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header>
                                        Ver explicación{' '}
                                        <span className="material-icons-outlined icon">
                                          expand_more
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <ReactMarkdown
                                          transformLinkUri={transformLinkUri}
                                          skipHtml={false}
                                          rehypePlugins={[rehypeRaw]}
                                        >{`${explanations}`}</ReactMarkdown>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </article>
                              ) : (
                                <></>
                              )}
                            </article>
                            <article className="game__answer"></article>
                          </div>
                        );
                      })
                  ) : (
                    <div>
                      <article className="game__article noanswer">
                        <p>
                          {' '}
                          PACO está trabajando en las respuestas de esta página.{' '}
                        </p>
                        <p> Pronto estarán disponibles. </p>
                      </article>
                    </div>
                  )}
                </div>
              </section>
              <section className="pagination-activity">
                <ul>
                  {pages.length > 1 &&
                  pages.some((page) => page < Number(pageNumber)) ? (
                    <li>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(Number(pageNumber) - 1);
                          navigate(
                            `/actividad-pec/${bookId}/${lessonId}/${
                              Number(pageNumber) - 1
                            }`
                          );
                          setTimeout(() => {
                            window.scrollTo(0, 0);
                          }, 100);
                        }}
                      >
                        <span className="material-icons">arrow_back_ios</span>
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>
                    <div className="pagination-activity__indicator">
                      <p>Página</p>
                      <p className="indicator__number">{`${currentPage}`}</p>
                    </div>
                  </li>
                  {pages.length > 1 &&
                  pages.some((page) => page > Number(pageNumber)) ? (
                    <li>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(Number(pageNumber) + 1);
                          navigate(
                            `/actividad-pec/${bookId}/${lessonId}/${
                              Number(pageNumber) + 1
                            }`
                          );
                          setTimeout(() => {
                            window.scrollTo(0, 0);
                          }, 100);
                        }}
                      >
                        <span className="material-icons">
                          arrow_forward_ios
                        </span>
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </section>
            </div>
            <aside className="game__col-right col-md-4">
              <div className="game__aside">
                <article className="game__aside-title">
                  <p className="game__aside-label">Nivel</p>
                  <p>
                    {answers
                      ? answers[0].attributes.pec_book.data.attributes.level ||
                        ''
                      : ''}
                  </p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Grado</p>
                  <p>{`${
                    answers
                      ? answers[0].attributes.grado.data.attributes.Grado || ''
                      : ''
                  }`}</p>
                </article>

                <article className="game__aside-title">
                  <p className="game__aside-label">Materia</p>
                  <p>{`${
                    answers
                      ? answers[0].attributes.pec_book.data.attributes
                          .bookName || ''
                      : ''
                  }`}</p>
                </article>
                {book.attributes?.sepBook ? (
                  <article className="game__aside-title">
                    <p className="mb-4 game__aside-label">Libro Conaliteg</p>
                    <a
                      className="btn btn--type-outline"
                      target="_blank"
                      rel="noreferrer"
                      href={
                        book.attributes?.sepBook
                          ? `${book.attributes?.sepBook}#page/${pageNumber}`
                          : ''
                      }
                      onClick={() => {
                        trackEvent({
                          event: 'goConaliteg',
                          page: '/actividad-pec',
                          book: book.attributes?.sepBook,
                          fullPath: window.location.href,
                        });
                      }}
                    >
                      Conoce más{' '}
                      <span className="material-icons">north_east</span>
                    </a>
                  </article>
                ) : (
                  <></>
                )}
              </div>
            </aside>
          </div>
        </div>
        <NavigationHints
          button1={{
            link: '/estrategias-didacticas',
            texto: 'Enseña Matemáticas con PACO Max',
            color: 'btn--color3',
            iconText: 'icon--grid7',
            icon: true,
            arti: false,
            max: true,
          }}
          button2={{
            link: '#',
            texto: 'Asesor pedagógico con Inteligencia Artificial',
            color: 'btn--color5',
            icon: false,
            arti: true,
            max: false,
            artiChat: true,
          }}
          button3={{
            link: '/planeaciones/nueva-escuela-mexicana',
            texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
            color: 'btn--color2',
            iconText: 'icon--grid2',
            icon: true,
            arti: false,
            max: false,
          }}
          user={user}
        />
      </section>
      <FooterComponent />
    </section>
  );
};

export default ActivityPecScreen;
