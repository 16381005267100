import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ToggleEnabledComponent from '../../Form/toggleEnabled/toggleEnabled.component';
import CheckboxComponent from '../../Form/checkbox/checkbox.component';
import SelectComponent from '../../Form/select/select.component';
import ModalInfoComponent from '../../Modal/modalInfo/modalInfo.component';
import { IUser } from '../../../types/UserInterface';
import { IFlexiblePlanning } from '../../../types/FlexiblePlanningInterface';
import {
  CicloEscolar,
  GenericResult,
  Grado,
  Grupo,
  ModeloEducativo,
} from '../../../types/EntidadesInterface';
import OverlayComponent from '../../OverlayTrigger/OverlayComponent';

interface Props {
  planning: Partial<IFlexiblePlanning>;
  user: Partial<IUser>;
  filtros: {
    ciclosEscolares: GenericResult;
    modelosEducativo: GenericResult;
    grados: GenericResult;
    grupos: GenericResult;
    materias: GenericResult;
  };
  updatePlanningState: (
    key: keyof IFlexiblePlanning,
    value: any
  ) => Partial<IFlexiblePlanning>;
  updatePlanning: (
    newPlanning: Partial<IFlexiblePlanning>
  ) => Promise<Partial<IFlexiblePlanning>>;
}

const DatosGeneralesComponent = ({
  planning,
  user,
  filtros,
  updatePlanningState,
  updatePlanning,
}: Props) => {
  const [modalShow, setModalShow] = useState(false);
  const [activo, setActivo] = useState(false);

  useEffect(() => {}, [planning]);

  function CustomToggle({
    children,
    eventKey,
  }: {
    children: ReactNode;
    eventKey: string;
  }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActivo(!activo)
    );

    return (
      <button
        type="button"
        className="card-header__toggle"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <>
      <ModalInfoComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        click={() => setModalShow(false)}
        content={
          <>
            <h4>Datos generales para la planeación</h4>
            <p>
              Asigna un nombre a tu planeación para identificarla fácilmente.
              Puedes incluir información adicional sobre la institución, ciclo
              escolar, modelo educativo, grado y grupo si así lo deseas.
            </p>
          </>
        }
      />
      <Accordion defaultActiveKey="0" className="new-accordion-planning">
        <Card>
          <Card.Header className={`card-header ${activo ? '' : 'active'}`}>
            <div id="informacion" className="plan-nav__click"></div>
            <div className="accordion-planning__title">
              <span className="material-icons-outlined icon">description</span>
              <p>
                Datos generales para la planeación
                <button
                  onClick={() => {
                    setModalShow(true);
                  }}
                  className="toggle-moda-tooltip"
                >
                  <span className="material-icons-outlined">info</span>
                </button>
              </p>
            </div>
            <CustomToggle eventKey="0">
              <span className="material-icons-outlined accordion-header__arrow">
                keyboard_arrow_down
              </span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="planning-row-form">
                <div className="form-input-element">
                  <label htmlFor="planningName" className="form-label">
                  Ponle un nombre a tu planeación para que la identifiques{' '}
                    <small>*</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="planningName"
                    aria-describedby="emailHelp"
                    value={planning.name || ''}
                    onChangeCapture={(event) =>
                      updatePlanningState(
                        'name',
                        (event.target as HTMLInputElement).value
                      )
                    }
                    onBlur={(event) => {
                      event.preventDefault();
                      updatePlanning({
                        ...planning,
                        name: (event.target as HTMLInputElement).value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="planning-row-form">
                <ToggleEnabledComponent
                  updates={{
                    currentPlanning: planning,
                    updatePlanningState,
                    updatePlanning,
                    toUpdate: 'planning',
                    planning: {
                      field: 'userCCT',
                    },
                  }}
                  disableField={(checked) => {
                    if (!checked && planning.userCCT?.documentId) {
                      updatePlanning({
                        ...planning,
                        userCCT: {
                          documentId: '',
                          value: '',
                        },
                      });
                    }
                  }}
                  contentTitle={'Selecciona tu Institución Educativa'}
                  contentToEnabled={
                    <>
                      {user.userCCTs && user.userCCTs.length ? (
                        user.userCCTs.map((cct, idx) => {
                          return (
                            <CheckboxComponent
                              key={idx}
                              id={`cct-${cct.documentId}`}
                              htmlFor={`cct-${cct.documentId}`}
                              text={<>{cct.Nombre}</>}
                              checked={
                                cct.documentId === planning.userCCT?.documentId
                              }
                              onChangeEvent={(checked) => {
                                if (checked) {
                                  updatePlanning({
                                    ...planning,
                                    userCCT: {
                                      documentId: `${cct.documentId}`,
                                      value: cct.Nombre || '',
                                    },
                                  });
                                }
                              }}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <div className="form-input-element">
                        <div className="col-md-6 col-12 col-reset">
                          <label htmlFor="cctName" className="form-label">
                            O escribe una Institución sin CCT
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="cctName"
                            aria-describedby="emailHelp"
                            placeholder="Escribe el nombre"
                            value={planning.otherCCT || ''}
                            onChangeCapture={(event) =>
                              updatePlanningState(
                                'otherCCT',
                                (event.target as HTMLInputElement).value
                              )
                            }
                            onBlur={(event) => {
                              event.preventDefault();
                              updatePlanning({
                                ...planning,
                                otherCCT: (event.target as HTMLInputElement)
                                  .value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
              <div className="planning-row-form">
                <div className="row">
                  <div className="planning-row-form__col col-lg-6 col-12">
                    <ToggleEnabledComponent
                      updates={{
                        currentPlanning: planning,
                        updatePlanningState,
                        updatePlanning,
                        toUpdate: 'planning',
                        planning: {
                          field: 'cicloEscolar',
                        },
                      }}
                      disableField={(checked) => {
                        if (!checked && planning.cicloEscolar?.documentId) {
                          const fields =
                            planning.disabledFields?.planning?.fields || [];
                          fields.push('modeloEducativo');
                          updatePlanning({
                            ...planning,
                            cicloEscolar: {
                              documentId: '',
                              value: '',
                            },
                            disabledFields: {
                              ...planning.disabledFields,
                              planning: { fields },
                            },
                          });
                        }
                      }}
                      contentTitle={'Ciclo escolar'}
                      contentToEnabled={
                        <>
                          <SelectComponent
                            label={''}
                            id={'ciclo-escolar'}
                            option={
                              <>
                                <option defaultValue="">Seleccionar...</option>
                                {filtros.ciclosEscolares.data.map(
                                  (cicloE, idx) => {
                                    const { attributes, id } = cicloE;
                                    const { ciclo } =
                                      attributes as CicloEscolar;
                                    return (
                                      <option
                                        key={idx}
                                        id={`${id}`}
                                        value={`${id}`}
                                        selected={
                                          `${id}` ===
                                          planning.cicloEscolar?.documentId
                                        }
                                      >{`${ciclo}`}</option>
                                    );
                                  }
                                )}
                              </>
                            }
                            onChange={(evt) => {
                              evt.preventDefault();
                              if (evt.target.value) {
                                const cicloEsc =
                                  filtros.ciclosEscolares.data.filter(
                                    (cicloE) =>
                                      `${cicloE.id}` === evt.target.value
                                  );
                                updatePlanning({
                                  ...planning,
                                  cicloEscolar: {
                                    documentId: evt.target.value,
                                    value: cicloEsc.length
                                      ? (cicloEsc[0].attributes as CicloEscolar)
                                          .ciclo
                                      : '',
                                  },
                                });
                              }
                            }}
                          />
                        </>
                      }
                    />
                  </div>
                  <div className="planning-row-form__col col-lg-6 col-12">
                    <ToggleEnabledComponent
                      updates={{
                        currentPlanning: planning,
                        updatePlanningState,
                        updatePlanning,
                        toUpdate: 'planning',
                        planning: {
                          field: 'modeloEducativo',
                        },
                      }}
                      disableField={(checked) => {
                        if (!checked) {
                          planning.asignaturas?.forEach((asignatura, idx) => {
                            (planning as any).asignaturas[idx] = {
                              ...(planning as any).asignaturas[idx],
                              campoFormacion: undefined,
                              asignatura: undefined,
                              bloque: undefined,
                              eje: undefined,
                              tema: undefined,
                              pSocial: undefined,
                              tTexto: undefined,
                              lArtistico: undefined,
                              aprendizajes: [],
                              competencias: [],
                              aprendizajesFundamentales: [],
                            };
                          });
                          const fields =
                            planning.disabledFields?.planning?.fields || [];
                          fields.push('modeloEducativo');
                          updatePlanning({
                            ...planning,
                            modeloEducativo: {
                              documentId: '',
                              value: '',
                            },
                            disabledFields: {
                              ...planning.disabledFields,
                              planning: { fields },
                            },
                          });
                        }
                      }}
                      contentTitle={
                        <>
                          Modelo educativo
                          <OverlayComponent
                            toolTipText="Puedes elegir el modelo educativo que prefieras o simplemente omitir esta parte.
Al seleccionar un modelo educativo aparecerán de manera automática los campos de información que necesitas llenar sobre cada uno."
                          />
                        </>
                      }
                      contentToEnabled={
                        <>
                          <SelectComponent
                            label={''}
                            id={'modelo-educativo'}
                            option={
                              <>
                                <option value="">Seleccionar...</option>
                                <option
                                  value=""
                                  selected={
                                    !planning.modeloEducativo?.documentId &&
                                    planning.asignaturas?.some(
                                      (a) => a.asignatura?.value !== ''
                                    )
                                  }
                                >
                                  Planeación libre
                                </option>
                                {filtros.modelosEducativo.data.map(
                                  (modeloE, idx) => {
                                    const { attributes, id } = modeloE;
                                    const { modelo_educativo } =
                                      attributes as ModeloEducativo;
                                    return (
                                      <option
                                        key={idx}
                                        id={`${id}`}
                                        value={`${id}`}
                                        selected={
                                          `${id}` ===
                                          planning.modeloEducativo?.documentId
                                        }
                                      >{`${modelo_educativo}`}</option>
                                    );
                                  }
                                )}
                              </>
                            }
                            onChange={(evt) => {
                              evt.preventDefault();
                              if(planning.asignaturas && planning.asignaturas?.length > 0){
                                Swal.fire({
                                  icon: 'warning',
                                  title: '',
                                  text: `Si cambias el modelo educativo se borrara todo el progreso de las asignaturas ¿Continuar?`,
                                  showCancelButton: true,
                                  confirmButtonText: 'Continuar',
                                  cancelButtonText: 'Cancelar',
                                  showLoaderOnConfirm: true,
                                }).then((result:any) => {
                                  if (result.isConfirmed) {
                                    const selected =
                                      filtros.modelosEducativo.data.filter(
                                        (s) => `${s.id}` === evt.target.value
                                      );
                                    planning.asignaturas = [];
                                    updatePlanning({
                                      ...planning,
                                      modeloEducativo: {
                                        documentId: evt.target.value || '',
                                        value: selected.length
                                          ? (
                                              selected[0]
                                                .attributes as ModeloEducativo
                                            ).modelo_educativo
                                          : '',
                                      },
                                    });
                                  }else{
                                    evt.target.value = planning.modeloEducativo?.documentId || '';
                                  }
                                });
                              }else{
                                const selected =
                                  filtros.modelosEducativo.data.filter(
                                    (s) => `${s.id}` === evt.target.value
                                  );
                                planning.asignaturas = [];
                                updatePlanning({
                                  ...planning,
                                  modeloEducativo: {
                                    documentId: evt.target.value || '',
                                    value: selected.length
                                      ? (
                                          selected[0]
                                            .attributes as ModeloEducativo
                                        ).modelo_educativo
                                      : '',
                                  },
                                });
                              }
                            }}
                          />
                        </>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="planning-row-form">
                <div className="row">
                  <div className="planning-row-form__col col-lg-6 col-12">
                    <div className="form-input-element">
                      <label htmlFor="grado-escolar" className="form-label">
                        Selecciona el grado escolar <small>*</small>
                      </label>
                      <SelectComponent
                        label={''}
                        id="grado-escolar"
                        option={
                          <>
                            <option value="">Seleccionar...</option>
                            {filtros.grados.data.map((gradoE, idx) => {
                              const { attributes, id } = gradoE;
                              const { Grado } = attributes as Grado;
                              return (
                                <option
                                  key={idx}
                                  id={`${id}`}
                                  value={`${id}`}
                                  selected={
                                    `${id}` ===
                                    planning.gradoEscolar?.documentId
                                  }
                                >{`${Grado}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          evt.preventDefault();
                          const selected = filtros.grados.data.filter(
                            (s) => `${s.id}` === evt.target.value
                          );
                          planning.asignaturas?.forEach((asignatura, idx) => {
                            (planning as any).asignaturas[idx] = {
                              ...(planning as any).asignaturas[idx],
                              campoFormacion: undefined,
                              asignatura: undefined,
                              bloque: undefined,
                              eje: undefined,
                              tema: undefined,
                              pSocial: undefined,
                              tTexto: undefined,
                              lArtistico: undefined,
                              aprendizajes: [],
                              competencias: [],
                              aprendizajesFundamentales: [],
                            };
                          });
                          updatePlanning({
                            ...planning,
                            gradoEscolar: {
                              documentId: evt.target.value || '',
                              value: selected.length
                                ? (selected[0].attributes as Grado).Grado
                                : '',
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="planning-row-form__col col-lg-6 col-12">
                    <ToggleEnabledComponent
                      updates={{
                        currentPlanning: planning,
                        updatePlanningState,
                        updatePlanning,
                        toUpdate: 'planning',
                        planning: {
                          field: 'grupoEscolar',
                        },
                      }}
                      disableField={(checked) => {
                        if (!checked) {
                          updatePlanning({
                            ...planning,
                            grupoEscolar: {
                              documentId: '',
                              value: '',
                            },
                          });
                        }
                      }}
                      contentTitle={'Selecciona el grupo escolar'}
                      contentToEnabled={
                        <>
                          <div className="multicheckboxes">
                            {filtros.grupos.data.map((grupoE, idx) => {
                              const { attributes, id } = grupoE;
                              const { Grupo } = attributes as Grupo;
                              return (
                                <CheckboxComponent
                                  key={idx}
                                  id={`gupo-${idx}`}
                                  htmlFor={`gupo-${idx}`}
                                  checked={
                                    `${id}` ===
                                    planning.grupoEscolar?.documentId
                                  }
                                  text={<>{`${Grupo}`}</>}
                                  onChangeEvent={(checked) => {
                                    if (checked) {
                                      updatePlanning({
                                        ...planning,
                                        grupoEscolar: {
                                          documentId: `${id}`,
                                          value: Grupo,
                                        },
                                      });
                                    }
                                  }}
                                />
                              );
                            })}
                          </div>
                        </>
                      }
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default DatosGeneralesComponent;
