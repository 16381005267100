import React from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';
import './multiselect.component.scss';

type Props = {
  id?: string;
  options: {
    value: string;
    label: string;
    extra?: string;
  }[];
  value?: {
    value: string;
    label: string;
    extra?: string;
  }[];
  disabled?: boolean;
  from?: string;
  onChange?:
    | ((
        newValue: MultiValue<{
          value: string;
          label: string;
          extra?: string | undefined;
        }>,
        actionMeta: ActionMeta<{
          value: string;
          label: string;
          extra?: string | undefined;
        }>
      ) => void)
    | undefined;
};

const MultiSelectComponent = ({
  id,
  options,
  value,
  disabled,
  from,
  onChange,
}: Props) => {
  return (
    <div className="search-select">
      <span className="material-icons-outlined search-select__icon">
        search
      </span>
      {(from==='search' || value?.length) ? (
        <Select
          id={id || ''}
          options={options}
          className="react-select-container"
          classNamePrefix="react-select"
          isMulti
          value={value || []}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#F3F5F9',
              primary: '#6081E2',
            },
          })}
          isDisabled={disabled || false}
          onChange={onChange}
        />
      ) : (
        <Select
          id={id || ''}
          options={options}
          className="react-select-container"
          classNamePrefix="react-select"
          isMulti
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#F3F5F9',
              primary: '#6081E2',
            },
          })}
          isDisabled={disabled || false}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default MultiSelectComponent;
