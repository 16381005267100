import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import './answersIndex.screen.scss';
import { getStrapiCatalog } from '../../../strapi/escuelas';
import { Grado } from '../../../types/PECInterface';

const AnswersIndexScreen = () => {
  const { trackEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [grados, setGrados] = useState<Grado[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const fetchGrados = async () => {
    try {
      const query = {
        sort: ['id:asc'],
        filters: { isOnPECBooks: { $eq: true } },
      };

      const gradosResult = await getStrapiCatalog(
        qs.stringify(query),
        '/grados'
      );

      if (gradosResult && gradosResult.data && gradosResult.data.length) {
        setGrados(
          gradosResult.data.map((g) => ({ ...g.attributes, id: g.id } as Grado))
        );
      }
    } catch (error) {
      console.error(error);
      setError('Error al consultar esta sección');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/respuestas-grado',
      fullPath: window.location.href,
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
            setIsLoading(true);
            fetchGrados();
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section app--background">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="header-activity-banner">
          <div className="container">
            <div className="row">
              <div className="header-activity-banner__col col-12">
                <h1 className="h3">Respuestas a los libros SEP</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="container body-activity">
          <div className="row row--index">
            <figure className="activity-image">
              <img src="/assets/images/elements/taco.png" alt="Arti" />
            </figure>
            <div className="activity-banner__body mx-auto col-md-8 col-12">
              <section className="body-activity__content">
                <article className="header-activity__intro">
                  <h2 className="h4">Selecciona tu grado de primaria</h2>
                </article>
                <section className="body-activity__grades col-12">
                  <div className="grid-grades row">
                    {grados && grados.length ? (
                      grados.map((grado, key) => {
                        const {
                          id: gradoId,
                          GradoNumber,
                          Grado,
                          level,
                        } = grado;
                        return (
                          <div
                            className={`grid-choose__item grid--color${GradoNumber} col-lg-4 col-6 ${
                              level !== 'Primaria' ? 'hidden' : ''
                            }`}
                            key={key}
                          >
                            <button
                              className="grid-choose__item-inner"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/respuestas-materia/${gradoId}`);
                              }}
                            >
                              <div className="grid-choose__check">
                                <p className="choose-number">
                                  {`${GradoNumber}`} <small>0</small>
                                </p>
                                <p className="choose-text">{`${Grado} - ${level}`}</p>
                              </div>
                            </button>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              </section>
              <section className="body-activity__content">
                <article className="header-activity__intro sec">
                  <h2 className="h4">Selecciona tu grado de secundaria</h2>
                </article>
                <section className="body-activity__grades col-12">
                  <div className="grid-grades row">
                    {grados && grados.length ? (
                      grados.map((grado, key) => {
                        const {
                          id: gradoId,
                          GradoNumber,
                          Grado,
                          level,
                        } = grado;
                        return (
                          <div
                            className={`grid-choose__item grid--color${GradoNumber} col-lg-4 col-6 ${
                              level !== 'Secundaria' ? 'hidden' : ''
                            }`}
                            key={key}
                          >
                            <button
                              className="grid-choose__item-inner"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/respuestas-materia/${gradoId}`);
                              }}
                            >
                              <div className="grid-choose__check">
                                <p className="choose-number">
                                  {`${GradoNumber}`} <small>0</small>
                                </p>
                                <p className="choose-text">{`${Grado} - ${level}`}</p>
                              </div>
                            </button>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              </section>
            </div>
          </div>
        </section>
      </div>
      <FooterComponent />
    </section>
  );
};

export default AnswersIndexScreen;
