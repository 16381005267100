import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../components/Footer/footer.component';
import BreadcrumbComponent from '../../components/Breadcrumb/breadcrumb.component';
import ModalPDFComponent from '../../components/Modal/modalPDF/modalPDF.component';
import ModalIframeComponent from '../../components/Modal/modalIframe/modalIframe.component';
import { getUser } from '../../api/usersApi';
import { getAnStrapiEntry, getStrapiCatalog } from '../../strapi/escuelas';
import { IUser } from '../../types/UserInterface';
import { LogoResult, RecursoPapalote } from '../../types/EntidadesInterface';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import ModalGameComponent from '../../components/Modal/modalGame/modaGame.component';
import NavigationHints from '../../components/NavigationHints/navigationHints';

const ActivityPapaloteScreen = () => {
  const { trackEvent } = useTracking();
  const { recursoId } = useParams();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [recurso, setRecurso] = useState<{
    id: number;
    attributes: RecursoPapalote;
  }>();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowPDF, setModalShowPDF] = useState(false);
  const [modalShowIframe, setModalShowIframe] = useState(false);
  const [recursoImage, setRecursoImage] = useState('');

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getTiposRecurso = async (_recurso: RecursoPapalote) => {
    const tiposR = await getStrapiCatalog(
      qs.stringify({ sort: ['Nombre:asc'], populate: ['Logo'] }),
      '/tipo-de-recursos-papalote'
    );
    if (tiposR.data?.length) {
      const tipoRecursoData = _recurso?.tipo_de_recurso_papalote?.data || [];
      const tipoRecursoImageId = tipoRecursoData.id;
      const index = tiposR.data.findIndex((r) => r.id === tipoRecursoImageId);
      if (index >= 0) {
        const image =
          (tiposR.data[index].attributes as LogoResult).Logo.data.attributes
            .url || '';
        setRecursoImage(image);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            setUser({ ...kameUser, token: jwtToken });
            if (recursoId) {
              getAnStrapiEntry('/recursos-papalote', recursoId).then(
                (response) => {
                  if (response) {
                    console.log(response);
                    const r = response.data as {
                      id: number;
                      attributes: RecursoPapalote;
                    };
                    setRecurso(r);
                    trackEvent({
                      event: 'pageView',
                      page: '/actividad-papalote',
                      fullPath: window.location.href,
                      recursoId,
                      recursoName: r.attributes.Nombre,
                      recursoTipo:
                        r.attributes.tipo_de_recurso_papalote.data.attributes
                          .Nombre,
                    });
                    getTiposRecurso(
                      response.data.attributes as RecursoPapalote
                    );
                  }
                }
              );
            }
          })
          .catch((error) => setError(error));
      })
      .catch(() => {});
  }, []);

  return (
    <section className="app-section">
      <ModalPDFComponent
        show={modalShowPDF}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowPDF(false)}
        title={`${recurso?.attributes.tipo_de_recurso_papalote.data.attributes.Nombre}`}
        file={recurso?.attributes.recurso?.data?.attributes?.url}
        download={
          recurso?.attributes.tipo_de_recurso_papalote.data.attributes.Nombre ==
          'Descargables'
            ? true
            : false
        }
        fileDown={recurso?.attributes.recurso?.data?.attributes}
      />
      <ModalIframeComponent
        show={modalShowIframe}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShowIframe(false)}
        title={`${recurso?.attributes.tipo_de_recurso_papalote.data.attributes.Nombre}`}
        src={recurso?.attributes?.link}
      />
      <ModalGameComponent
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
        title={`${recurso?.attributes.tipo_de_recurso_papalote.data.attributes.Nombre}`}
        image={recurso?.attributes.recurso?.data?.attributes?.url}
      />
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent url={`/recursos-papalote`} back="Regresar" />
      </div>
      <section className="game-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {recursoImage !== '' ? (
                <figure className="game__image">
                  <img src={recursoImage} alt="Game Banner" />
                </figure>
              ) : (
                <div></div>
              )}
              <div className="game__aside game-info-mobile">
                <article className="game__aside-title">
                  <p className="game__aside-label">Recurso</p>
                  <p>{`${recurso?.attributes.Nombre || ''}`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Tipo de Recurso</p>
                  <p>{`${recurso?.attributes.tipo_de_recurso_papalote.data.attributes.Nombre}`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Fuente</p>
                  <p>{`Papalote Museo del Niño`}</p>
                </article>
              </div>
              <div className="game__info">
                <article className="game__article">
                  <h6>Descripción</h6>
                  <ReactMarkdown
                    linkTarget={'_blank'}
                    skipHtml={false}
                    rehypePlugins={[rehypeRaw]}
                  >{`${recurso?.attributes.descripcion || ''}`}</ReactMarkdown>
                </article>
                <article className="game__article">
                  <h6>Recurso</h6>
                  <article className="game__aside-title">
                    {recurso?.attributes.recurso?.data ? (
                      <button
                        onClick={() => {
                          recurso?.attributes.recurso?.data.attributes.ext ===
                          '.pdf'
                            ? setModalShowPDF(true)
                            : setModalShow(true);
                          trackEvent({
                            event: 'verContenido',
                            page: '/actividad-papalote',
                            fullPath: window.location.href,
                            recursoId: recurso?.id,
                            recursoName: recurso?.attributes.Nombre,
                          });
                        }}
                        className="btn btn--type-outline btn--small"
                      >
                        Ver contenido
                      </button>
                    ) : (
                      <> </>
                    )}
                    {recurso?.attributes.link ? (
                      <button
                        onClick={() => {
                          setModalShowIframe(true);
                          trackEvent({
                            event: 'verContenido',
                            page: '/actividad-papalote',
                            fullPath: window.location.href,
                            recursoId: recurso?.id,
                            recursoName: recurso?.attributes.Nombre,
                          });
                        }}
                        className="btn btn--type-outline btn--small"
                      >
                        Ver contenido
                      </button>
                    ) : (
                      <> </>
                    )}
                  </article>
                  <article className="resource-copyright">
                    <p>
                      <strong>
                        {
                          'Copyright © 2023 Museo Interactivo Infantil, A.C. Todos los derechos reservados.\n'
                        }
                      </strong>
                      <br />
                      <strong>
                        {
                          'Museo Interactivo Infantil A.C., así como la identidad corporativa y de producto utilizadas en la presente, el logotipo de "Papalote Museo del Niño"; "Toco, Juego y Aprendo" son propiedad de Museo Interactivo Infantil A.C.'
                        }
                      </strong>
                    </p>
                  </article>
                </article>
              </div>
            </div>
            <aside className="game__col-right col-md-4">
              <div className="game__aside">
                <article className="game__aside-title">
                  <p className="game__aside-label">Recurso</p>
                  <p>{`${recurso?.attributes.Nombre || ''}`} </p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Tipo de Recurso</p>
                  <p>{`${recurso?.attributes.tipo_de_recurso_papalote.data.attributes.Nombre}`}</p>
                </article>
                <article className="game__aside-title">
                  <p className="game__aside-label">Fuente</p>
                  <p>{`Papalote Museo del Niño`}</p>
                </article>
              </div>
            </aside>
          </div>
        </div>
        <NavigationHints
          button1={{
            link: '/busqueda',
            texto: 'Recursos educativos',
            color: 'btn--color3',
            iconText: 'icon--grid4',
            icon: true,
            arti: false,
            max: false,
          }}
          button2={{
            link: '/planeaciones/nueva-escuela-mexicana',
            texto: 'Planeaciones para descargar (incluye NEM y dosificaciones)',
            color: 'btn--color2',
            iconText: 'icon--grid2',
            icon: true,
            arti: false,
            max: false,
          }}
          button3={{
            link: '/crea-tu-planeacion-nem',
            texto: 'Planeaciones NEM con Inteligencia Artificial',
            iconText: 'icon--grid3',
            color: 'btn--color2',
            icon: true,
            arti: true,
            max: false,
          }}
          user={user}
        />
      </section>
      <FooterComponent />
    </section>
  );
};

export default ActivityPapaloteScreen;
