import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.a22.mx',
  // baseURL: 'https://apidev.a22.mx',
  // baseURL: 'http://localhost:5002',
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  (config as any).headers['request-startTime'] = new Date().getTime();
  return config;
});

instance.interceptors.response.use((response) => {
  const currentTime = new Date().getTime();
  const startTime = (response.config as any).headers['request-startTime'];
  response.headers['request-duration'] = `${currentTime - Number(startTime)}`;
  return response;
});

export default instance;
