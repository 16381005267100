import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../components/Footer/footer.component';
import ButtonType3Component from '../../components/Button/buttonType3/buttonType3.component';
import InputComponent from '../../components/Form/input/input.component';
import TextareaComponent from '../../components/Form/textarea/textarea.component';
import './contact.screen.scss';
import { getUser } from '../../api/usersApi';
import { IUser } from '../../types/UserInterface';

const ContactScreen = () => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageView',
      page: '/contacto',
      fullPath: window.location.href,
    });
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      const {
        attributes,
        signInUserSession: {
          accessToken: { jwtToken },
        },
      } = currentUser;
      const kameUserId = attributes['custom:kameUserId'];
      getUser(kameUserId, jwtToken)
        .then((kameUser) => {
          if (kameUser) {
            setUser({ ...kameUser, token: jwtToken });
          }
        })
        .catch((error) => setError(error));
    });
  }, []);

  return (
    <section className="app-section">
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <section className="container-simple container">
          <div className="row">
            <div className="col-md-8 col-12 mx-auto">
              <h3 className="form__title">¿Necesitas ayuda?</h3>

              <form className="col-md-8 col-12 mx-auto">
                <div className="form__row">
                  <label htmlFor="name" className="form__label">
                    Nombre
                  </label>
                  <InputComponent
                    type="text"
                    id="name"
                    placeholder="Tu nombre completo"
                  />
                </div>
                <div className="form__row">
                  <label htmlFor="email" className="form__label">
                    Correo electrónico
                  </label>
                  <InputComponent
                    type="email"
                    id="email"
                    placeholder="miemail@mail.com"
                  />
                </div>
                <div className="form__row mb32">
                  <label htmlFor="message" className="form__label">
                    Mensaje
                  </label>
                  <TextareaComponent
                    id="message"
                    placeholder="déjanos tu mensaje"
                  />
                </div>

                <div className="form__row form__action">
                  <ButtonType3Component url="/" name="Enviar" />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>

      <FooterComponent />
    </section>
  );
};

export default ContactScreen;
