import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { useTracking } from 'react-tracking';
import { updatePlanning } from '../../../api/planningsApi';
import { getUser } from '../../../api/usersApi';
import CheckboxComponent from '../../../components/Form/checkbox/checkbox.component';
import InputComponent from '../../../components/Form/input/input.component';
import CreatableComponent from '../../../components/Form/multiselect/creatable.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { IPlanning, IPlanningSession } from '../../../types/PlaningInterface';
import './modalPublicPlanning.component.scss';
import { IUser } from '../../../types/UserInterface';
import {
  getStrapiCatalog,
  setPlaneacionStrapi,
} from '../../../strapi/escuelas';
import QueryString from 'qs';
import { RecursoEducativo } from '../../../types/EntidadesInterface';

type Props = {
  planning: Partial<IPlanning>;
  sessions: IPlanningSession[];
  show: boolean;
  size: string;
  onHide: () => void;
  setPlanning: (value: React.SetStateAction<Partial<IPlanning>>) => void;
};

const ModalPublicPlanning = ({
  planning,
  sessions,
  size,
  show,
  onHide,
  setPlanning,
}: Props) => {
  const { trackEvent } = useTracking();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [planningTags, setPlanningTasks] = useState<string[]>([]);
  const [tagsSelected, setTagsSelected] = useState<
    {
      value: string;
      label: string;
      extra?: string;
    }[]
  >([]);
  const [planningAlias, setPlanningAlias] = useState(planning.alias || '');

  const { name, autor } = planning;

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const getPlanningRecursos = async () => {
    const recursoIds: string[] = [];
    const allSearchTags: string[] = [];

    if (sessions.length) {
      sessions.forEach((session) => {
        const { introduccion, desarrollo, cierre } = session;
        [introduccion, desarrollo, cierre].forEach((section) => {
          const { resources } = section;
          if (resources && resources.length) {
            resources.forEach(({ documentId }) => recursoIds.push(documentId));
          }
        });
      });
    }

    if (recursoIds.length) {
      const query = {
        filters: { id: { $in: recursoIds } },
        populate: ['temas', 'ejes'],
      };
      const response = await getStrapiCatalog(
        QueryString.stringify(query),
        'recursos-educativos'
      );
      if (response && response.data) {
        response.data.forEach((recurso) => {
          const searchTags: string[] = [];
          const { attributes } = recurso;
          const { tags, temas, ejes } = attributes as RecursoEducativo;
          if (tags) {
            searchTags.push(
              ...tags.split(',').map((tag) => tag.trim().toLowerCase())
            );
          }
          if (temas && temas.data.length) {
            searchTags.push(
              ...temas.data.map((tema) => tema.attributes.Nombre.toLowerCase())
            );
          }
          if (ejes && ejes.data.length) {
            searchTags.push(
              ...ejes.data.map((eje) => eje.attributes.Nombre.toLowerCase())
            );
          }

          allSearchTags.push(...searchTags);
        });

        console.log({ allSearchTags });
      }
    }

    if (allSearchTags.length) {
      const cleanTags = [...new Set(allSearchTags)].sort((a, b) =>
        a > b ? 1 : -1
      );
      setPlanningTasks(cleanTags);
      console.log({ cleanTags });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              getPlanningRecursos();
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size as any}
      aria-labelledby="modal-options"
      centered
      className="modal--options modal--white modal--left"
    >
      <Modal.Header className="modal__title-icon">
        <Modal.Title id="modal-options">
          <h4>Hacer pública mi planeación:</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Nombre: {`${name}`}</h5>
        <div className="row">
          <div className="col-12">
            <div className="form__row">
              <label htmlFor="autor" className="form__label">
                Alias:
              </label>
              <div className="form-control__password">
                <InputComponent
                  type="text"
                  id="autor"
                  placeholder={isAnonymous ? 'Anónima' : 'Escribe un autor'}
                  value={isAnonymous ? 'Anónima' : user.alias}
                  readOnly={true}
                  onChangeCapture={(evt) =>
                    setPlanningAlias(
                      (evt?.target as HTMLTextAreaElement).value || ''
                    )
                  }
                />
              </div>
            </div>
            <div className="form__row">
              <CheckboxComponent
                id="terms"
                htmlFor="terms"
                onChangeEvent={(checked) => setIsAnonymous(checked)}
                checked={isAnonymous}
                text={<>Quiero que mi planeación se anónima</>}
              />
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="tags" className="form__label tooltip-contain">
              Selecciona los criterios de búsqueda de tu planeación
              <OverlayComponent
                toolTipText={`
                  Agrega criterios de búsqueda (palabras clave o clasificación) a tu planeación. 
                  Esto ayudará a que la comunidad pueda encontrarla fácilmente.
                `}
              />
            </label>
            <div className="form-control__password">
              <CreatableComponent
                options={planningTags.map((planningTag) => ({
                  value: planningTag,
                  label: planningTag,
                }))}
                value={(tagsSelected || []).map((v) => ({
                  value: v.value,
                  label: v.label,
                }))}
                onChange={(values) => {
                  setTagsSelected(
                    values.map(({ value, label }) => ({ value, label }))
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn--type2 btn--small "
          onClick={(evt) => {
            evt.preventDefault();
            trackEvent({
              event: 'publishPlanning',
              page: '/planeacion-sesiones',
              isPublic: true,
              tagsSelected: tagsSelected.length
                ? tagsSelected.map(({ value }) => value).join(', ')
                : '',
              isAnonymous,
            });
            updatePlanning(
              planning._id || '',
              {
                isPublic: true,
                isAnonymous,
                alias: planningAlias || autor,
              },
              `${user.token}`
            )
              .then(() => {
                setPlaneacionStrapi(
                  {
                    ...planning,
                    userId: (planning.userId as IUser)._id,
                    autor: planningAlias || autor,
                  } as IPlanning,
                  tagsSelected.map(({ value }) => value).join(', ')
                )
                  .then(() => {
                    onHide();
                    setPlanning({ ...planning, isPublic: true, isAnonymous });
                  })
                  .catch((error) => setError(error));
              })
              .catch((error) => setError(error));
          }}
        >
          Aceptar
        </button>
        <button
          className="btn btn--type-outline btn--small "
          onClick={(evt) => {
            evt.preventDefault();
            onHide();
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPublicPlanning;
