import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

type Props = {
  toolTipText: string;
};

const OverlayComponent = ({ toolTipText }: Props) => {
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="auto"
      overlay={(props) => {
        return <Tooltip {...props}>{`${toolTipText}`}</Tooltip>;
      }}
    >
      <button className="tooltip-trigger">
        <span className="material-icons-outlined icon">info</span>
      </button>
    </OverlayTrigger>
  );
};

export default OverlayComponent;
