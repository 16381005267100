import React from 'react';
import './buttonAction.component.scss';

const ButtonActionComponent = (props: any) => {
  return (
    <button className="btn btn--action" onClick={props.onClick}>
      <p>{props.name}</p>
      <span className="material-icons-outlined">{props.icon}</span>
    </button>
  );
};

export default ButtonActionComponent;
