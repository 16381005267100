import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { es } from 'date-fns/locale';
import './datepicker.component.scss';
import { getUnixTime } from 'date-fns';

type Props = {
  disabled?: boolean;
  onDateChange?: (unix: number) => void;
  currentValue?: Date | null;
};

const DatepickerComponent = ({
  disabled,
  onDateChange,
  currentValue,
}: Props) => {
  const handleChange = (date: Date | null) => {
    if (date && onDateChange) {
      const unixDate = date ? getUnixTime(new Date(date.toDateString())) : 0;
      onDateChange(unixDate);
    }
  };
  let date = new Date();
  const dateOne = new Date(date.setMonth(date.getMonth() + 4));

  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
      <Stack>
        <div className="datepicker d-none d-md-block d-lg-block">
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            value={currentValue}
            disabled={disabled || false}
            maxDate={dateOne}
            disablePast={true}
            disableFuture={false}
          />
        </div>
        <div className="datepicker d-block d-md-none d-lg-none">
          <MobileDatePicker
            inputFormat="MM/dd/yyyy"
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            value={currentValue}
            disabled={disabled || false}
            maxDate={dateOne}
            disablePast={true}
            disableFuture={false}
          />
        </div>
      </Stack>
    </LocalizationProvider>
  );
};

export default DatepickerComponent;
