import React from 'react';
import './like.component.scss';

type Props = {
  totalFavs?: number;
  checked?: boolean;
  disabled?: boolean;
  onCheck?: (event: React.FormEvent<HTMLInputElement> | undefined) => void;
};

const LikeComponent = ({ totalFavs, onCheck, checked, disabled }: Props) => {
  return (
    <div className="check-like">
      <input
        type="checkbox"
        className="check-like__check"
        onClick={onCheck}
        checked={checked || false}
        disabled={disabled || false}
        title="Favorito"
      />
      <div className="check-like__change">
        <figure>
          { (Number(totalFavs)>0) ? 
            <span className="material-icons-outlined check__inactive">
            favorite
            </span> : 
            <span className="material-icons-outlined check__inactive">
            favorite_border
            </span>
          }
          <span className="material-icons-outlined check__active">
            favorite
          </span>
        </figure>
        <p>{Number(totalFavs)}</p>
      </div>
    </div>
  );
};

export default LikeComponent;
