import qs from 'qs';
import api from './index';
import { IResourceFavorite, SearchResult } from '../types/PlaningInterface';

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const createResourceFavorite = async (
  planningFavorite: Partial<IResourceFavorite>,
  token: string
) => {
  console.log('------ createResourceFavorite ------');
  const response = await api.post<IResourceFavorite>(
    '/resourceFavorites',
    planningFavorite,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getResourceFavorite = async (id: string, token: string) => {
  console.log('------ getResourceFavorite ------', { id, token });
  const response = await api.get<IResourceFavorite>(
    `/resourceFavorites/${id}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchResourceFavorite = async (
  query: Partial<IResourceFavorite>,
  token: string
) => {
  console.log('------ searchResourceFavorite ------');
  const response = await api.post<SearchResult>(
    `/resourceFavorites/search?${qs.stringify({ limit: 50 })}`,
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  ).catch(function (error) {
    console.log(error);
    if(error.message=="Network Error")
      throw 'Error de red. Revisa tu conexión e intentalo de nuevo.';
    else throw 'Por favor, intentalo de nuevo.';
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
