import React from 'react';
import { Link } from 'react-router-dom';
import './materialPapalote.component.scss';

type Props = {
  url: string;
  image: string;
  alt: string;
  type: string;
  name: string;
};

const MaterialPapaloteComponent = ({ url, image, alt, type, name }: Props) => {
  return (
    <div className="material-added">
      <div className="row">
        <div className="material-added__col-left col-md-4 col-sm-3 col-4">
          <div className="material-added__image">
            <figure>
              <Link to={url}>
                <img src={image} alt={`${alt}`} />
              </Link>
            </figure>
          </div>
        </div>
        <div className="material-added__col-rigth col-md-8 col-sm-9 col-8">
          <Link to={url}>
            <div className="material-added__text">
              <p>{type}</p>
              <h4>{name}</h4>
            </div>
            <div className="material-added__text">
              <p>Por:</p>
              <h4 className=""> Papalote Museo del Niño</h4>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MaterialPapaloteComponent;
