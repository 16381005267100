import React  from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './modalTooltip.component.scss';

interface ModalTooltipProps {
  title: string;
  description: string;
  defaultOpen?: boolean;
  icon?: string;
}

const ModalTooltipComponent: React.FC<ModalTooltipProps> = (props) => {
  return (
    <div className="modal-tooltip">
      <Accordion defaultActiveKey={props.defaultOpen ? "0" : undefined}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="modal-tooltip__header">
              <span className="material-icons-outlined">{props.icon || "help"}</span> 
              {props.title}
            </div>
            <span className="material-icons-outlined icon">expand_more</span>
          </Accordion.Header>
          <Accordion.Body>
            <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ModalTooltipComponent;