import { DosificacionNemResult, PlanningNemResult } from '../types/PlaningInterface';
import api from './index';
import qs from 'qs';

export const getPlanningsNem = async (q: any) => {
  const query = qs.stringify({ ...q });
  const response = await api.get<PlanningNemResult>(
    `/nem-planeaciones?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getDosificaciones = async (q: any) => {
  const query = qs.stringify({ ...q });
  const response = await api.get<DosificacionNemResult>(
    `/nem-dosificaciones?${query}`
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
