import React from 'react';
import { format, fromUnixTime } from 'date-fns';
import { Escuela, RecursoEducativo } from '../../../types/EntidadesInterface';
import { IPlanning, IPlanningSession } from '../../../types/PlaningInterface';
import './printPlanning.screen.scss';

type Props = {
  planning: Partial<IPlanning>;
  sessions: IPlanningSession[];
  escuela: {
    id: number;
    attributes: Escuela;
  } | null;
  show: boolean;
  recursos: {
    id: number;
    attributes: RecursoEducativo;
  }[];
};

const PrintPlanningScreen = ({ planning, sessions, escuela, show, recursos }: Props) => {
  return (
    <section className={`${show ? '' : 'print-section'}`}>
      <div className="result-block__wrapper">
        <div className="result-block container">
          <div className="result-block__row row">
            <div className="col-12">
              <figure className="logo-print">
                <img
                  src="https://pacoteacher.com/assets/images/logo/a22-logo.svg"
                  alt="PACO Teacher"
                />
              </figure>
            </div>
            <div className="result-block__title col-6">
              <h1>{`${planning.name || ''}`}</h1>
            </div>
          </div>
          {escuela ? (
            <div className="result-block__row row">
              <div className="result-block__col col-12">
                <h3>Institución</h3>
              </div>
              <div className="result-block__col col-4">
                <p>Nombre de la Institución Educativa</p>
                <h4>{`${escuela.attributes.Nombre || ''}`}</h4>
              </div>
              <div className="result-block__col col-4">
                <p>Nombre del docente</p>
                <h4>{`${planning.autor || ''}`}</h4>
              </div>
              <div className="result-block__col col-4">
                <p>Clave del centro de trabajo CCT</p>
                <h4>{`${escuela.attributes.CCT || ''}`}</h4>
              </div>
              <div className="result-block__col col-4">
                <p>Localidad</p>
                <h4>{`${escuela.attributes.Localidad || ''}`}</h4>
              </div>
              {planning.isNewCiclo ? (
                <div className="result-block__col col-4">
                  <p>Ciclo escolar</p>
                  <h4>Ciclo escolar 2022 - 2023</h4>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
          <div className="result-block container">
          <div className="result-block__row row">
            <div className="result-block__col col-12">
              <h3>Planeación</h3>
            </div>
            <div className="result-block__col col-4">
              <p>Reforma</p>
              <h4>{`${planning.modeloEducativo?.value || ''}`}</h4>
            </div>
            <div className="result-block__col col-4">
              <p>Grado y grupo</p>
              <h4>{`${planning.gradoEscolar?.value || ''} - ${
                planning.grupoEscolar?.value || ''
              }`}</h4>
            </div>
            {planning.modeloEducativo?.documentId === '2' ? (
              <div className="result-block__col col-4">
                <p>Campo de formación académica y áreas de desarrollo</p>
                <h4>{`${planning.campoFormacion?.value || ''}`}</h4>
              </div>
            ) : (
              <div className="result-block__col col-4">
                <p>Bloque</p>
                <h4>{`${planning.bloque?.value || ''}`}</h4>
              </div>
            )}
          </div>
          <div className="result-block__row row">
            <div className="result-block__col col-4">
              <p>Asignatura</p>
              <h4>{`${planning.asignatura?.value || ''}`}</h4>
            </div>
            {planning.eje ? (
              <div className="result-block__col col-4">
                <p>Eje</p>
                <h4>{`${planning.eje?.value || ''}`}</h4>
              </div>
            ) : (
              <></>
            )}
            {planning.tema ? (
              <div className="result-block__col col-4">
                <p>Tema</p>
                <h4>{`${planning.tema?.value || ''}`}</h4>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="result-block__row row">
            {(planning.aprendizajesFundamentales || []).length ? (
              <div className="result-block__col col-12">
                <p>Aprendizajes fundamentales</p>
                <ul className="result-block__li">
                  {(planning.aprendizajesFundamentales || []).map(
                    (aprendizaje, k) => (
                      <li key={k}>
                        <p>{aprendizaje.value}</p>
                      </li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              <></>
            )}
            {(planning.aprendizajes || []).length ? (
              <div className="result-block__col col-12">
                <p>Aprendizajes esperados</p>
                <ul className="result-block__li">
                  {(planning.aprendizajes || []).map((aprendizaje, k) => (
                    <li key={k}>
                      <p>{aprendizaje.value}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
            {(planning.competencias || []).length ? (
              <div className="result-block__col col-12">
                <p>Competencias</p>
                <ul className="result-block__li">
                  {(planning.competencias || []).map((competencia, k) => (
                    <li key={k}>
                      <p>{competencia.value}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="result-block container">
          {planning.description && planning.description !== '' ? (
            <div className="result-block__row row">
              <div className="result-block__colcol-12">
                <p>Descripción</p>
                <h4>{planning.description || ''}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
          {planning.period && planning.period !== '' ? (
            <div className="result-block__row row">
              <div className="result-block__colcol-12">
                <p>Periodo de tiempo</p>
                <h4>{planning.period || ''}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
          {planning.dateRanges &&
          planning.dateRanges.start > 0 &&
          planning.dateRanges.end > 0 ? (
            <div className="result-block__row row">
              <div className="result-block__colcol-12">
                <p>Rango de fechas</p>
                <h4>{`${
                  format(
                    fromUnixTime(planning.dateRanges.start),
                    'dd . MMMM . yy'
                  ) || ''
                } - ${
                  format(
                    fromUnixTime(planning.dateRanges.end),
                    'dd . MMMM . yy'
                  ) || ''
                }`}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
          {planning.apClave && planning.apClave !== '' ? (
            <div className="result-block__row row">
              <div className="result-block__colcol-12">
                <p>Aprendizajes clave</p>
                <h4>{planning.apClave || ''}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
          {planning.propPed && planning.propPed !== '' ? (
            <div className="result-block__row row">
              <div className="result-block__colcol-12">
                <p>Propósitos pedagógicos</p>
                <h4>{planning.propPed || ''}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
          {planning.contexto && planning.contexto !== '' ? (
            <div className="result-block__row row">
              <div className="result-block__colcol-12">
                <p>Contexto en el aula</p>
                <h4>{planning.contexto || ''}</h4>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="result-block container">
          <div className="result-block__row row">
            <div className="result-block__col col-12">
              <h2>Sesiones</h2>
            </div>
          </div>
          {sessions.map((session, key) => {
            const {
              name,
              description,
              startDate,
              minutes,
              materialFisico,
              introduccion,
              desarrollo,
              cierre,
              evaluacion,
              notes,
            } = session;
            const introduccionR = recursos.filter(
              (re) => (introduccion?.resources || []).map((r) => r.documentId)
                      .indexOf(`${re.id}`) >= 0
            );
            const desarrolloR = recursos.filter(
              (re) => (desarrollo?.resources || []).map((r) => r.documentId)
                      .indexOf(`${re.id}`) >= 0
            );
            const cierreR = recursos.filter(
              (re) => (cierre?.resources || []).map((r) => r.documentId)
                      .indexOf(`${re.id}`) >= 0
            );
            return (
              <div className="result-block__row row result--index" key={key}>
                <div className="result-block__col col-12">
                  <div className="result-print-box">
                    <h3>Sesión - {`${name}`}</h3>
                    <p>
                      {`${
                        format(fromUnixTime(startDate), 'dd . MMM . yyyy') || ''
                      }`}{' '}
                      - {`${format(fromUnixTime(minutes), 'mm') || ''} min.`}
                    </p>
                    <article className="result-block__session">
                      <p>{`${description || ''}`}</p>
                    </article>
                    <div className="result-block__col col-12">
                      <h4>Material físico</h4>
                      <article className="result-block__session mt-0">
                        <p>{`${materialFisico || ''}`}</p>
                      </article>
                    </div>
                  </div>
                  {introduccion ? (
                    <div id="tab-1" className="result-block__session-tab">
                      <h4 className="tab-title">1. Inicio</h4>
                      <p>{`${
                        format(fromUnixTime(introduccion.minutes || 0), 'mm') ||
                        ''
                      } min.`}</p>
                      <article className="result-block__session">
                        <p>{`${introduccion.description || ''}`}</p>
                      </article>
                      <div className="session-tab__open-row row">
                        {introduccion.resources && introduccion.resources?.length >0 ? (
                          <>
                          <div className="session-tab--print-title mb24 col-12">
                            <h5>Recursos educativos digitales</h5>
                          </div> 
                          {( introduccionR || []).map((recurso, k) => {
                            return(
                              <div className="session-tab--print-element session-tab__col col-12" key={k}>
                                <div>
                                  <h6>{recurso.attributes.Nombre}</h6>
                                  <p>{recurso.attributes.descripcion}</p>
                                  {(recurso.attributes.fuente.data.attributes.Fuente!='Paco Max') 
                                    ? <p className="session-tab--url"><strong>URL</strong> {recurso.attributes.recurso}</p> 
                                    :  <></>
                                  }
                                </div> 
                              </div> 
                            )
                          })}
                          </>
                          ) : <></>
                        }
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {desarrollo ? (
                    <div id="tab-2" className="result-block__session-tab">
                      <h4 className="tab-title">2. Desarrollo</h4>
                      <p>{`${
                        format(fromUnixTime(desarrollo.minutes || 0), 'mm') ||
                        ''
                      } min.`}</p>
                      <article className="result-block__session">
                        <p>{`${desarrollo.description || ''}`}</p>
                      </article>
                      <div className="session-tab__open-row row">
                        {desarrollo.resources && desarrollo.resources?.length >0 ? (
                          <>
                          <div className="session-tab--print-title mb24 col-12">
                            <h5>Recursos educativos digitales</h5>
                          </div> 
                          {( desarrolloR || []).map((recurso, k) => {
                            return(
                              <div className="session-tab--print-element session-tab__col col-12" key={k}>
                                <div>
                                  <h6>{recurso.attributes.Nombre}</h6>
                                  <p>{recurso.attributes.descripcion}</p>
                                  {(recurso.attributes.fuente.data.attributes.Fuente!='Paco Max') 
                                    ? <p className="session-tab--url"><strong>URL</strong> {recurso.attributes.recurso}</p> 
                                    :  <></>
                                  }
                                </div> 
                              </div> 
                            )
                          })}
                          </>
                          ) : <></>
                        }
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {cierre ? (
                    <div id="tab-3" className="result-block__session-tab">
                      <h4 className="tab-title">3. Cierre</h4>
                      <p>{`${
                        format(fromUnixTime(cierre.minutes || 0), 'mm') || ''
                      } min.`}</p>
                      <article className="result-block__session">
                        <p>{`${cierre.description}`}</p>
                      </article>
                      {(cierre.preguntasReflexion || [])?.length ? (
                        <article className="result-block__session">
                          <strong>Preguntas de reflexión</strong>
                          <ul className="result-block__li">
                            {(cierre.preguntasReflexion || []).map(
                              (pR, key) => {
                                return (
                                  <li key={key}>
                                    <p>{`${pR.value}`}</p>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </article>
                      ) : (
                        <></>
                      )}
                      <div className="session-tab__open-row row">
                        {cierre.resources && cierre.resources?.length >0 ? (
                          <>
                          <div className="session-tab--print-title mb24 col-12">
                            <h5>Recursos educativos digitales</h5>
                          </div> 
                          {( cierreR || []).map((recurso, k) => {
                            return(
                              <div className="session-tab--print-element session-tab__col col-12" key={k}>
                                <div>
                                  <h6>{recurso.attributes.Nombre}</h6>
                                  <p>{recurso.attributes.descripcion}</p>
                                  {(recurso.attributes.fuente.data.attributes.Fuente!='Paco Max') 
                                    ? <p className="session-tab--url"><strong>URL</strong> {recurso.attributes.recurso}</p> 
                                    :  <></>
                                  }
                                </div> 
                              </div> 
                            )
                          })}
                          </>
                          ) : <></>
                        }
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {evaluacion.length ? (
                    evaluacion.map((ev, key) => {
                      const {
                        description,
                        minutes,
                        instrumentoEval,
                        criterios,
                        evidencias,
                        comentarios,
                      } = ev;
                      return (
                        <div
                          id="tab-4"
                          className="result-block__session-tab"
                          key={key}
                        >
                          <h4 className="tab-title">4. Evaluación</h4>
                          <p>{`${
                            format(fromUnixTime(minutes), 'mm') || ''
                          } min.`}</p>
                          <article className="result-block__session">
                            <p>{`${description}`}</p>
                          </article>
                          <div className="result-block__row row">
                            <div className="result-block__col col-4 mb-4">
                              <h4>Instrumento de evaluación</h4>
                              <ul className="result-block__li">
                                {instrumentoEval?.length ? (
                                  instrumentoEval.map((iEv, key) => {
                                    return (
                                      <li key={key}>
                                        <p>{`${iEv.value}`}</p>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </div>
                            <div className="result-block__col col-12">
                              <h4>Criterios de evaluación</h4>
                              <article className="result-block__session mt-0">
                                <p>{`${criterios || ''}`}</p>
                              </article>
                            </div>
                            <div className="result-block__col col-12">
                              <h4>Evidencias</h4>
                              <article className="result-block__session mt-0">
                                <p>{`${evidencias || ''}`}</p>
                              </article>
                            </div>
                            <div className="result-block__col col-12">
                              <h4>Comentarios</h4>
                              <article className="result-block__session mt-0">
                                <p>{`${comentarios || ''}`}</p>
                              </article>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {notes ? (
                    <div id="tab-5" className="result-block__session-tab">
                      <h4 className="tab-title">5. Notas</h4>
                      <div className="result-block__row row">
                        <div className="result-block__col col-12">
                          <h4>Adecuaciones curriculares</h4>
                          <article className="result-block__session mt-0">
                            <p>{`${notes.adecuacionesC || ''}`}</p>
                          </article>
                        </div>
                        <div className="result-block__col col-12">
                          <h4>Notas de la dirección</h4>
                          <article className="result-block__session mt-0">
                            <p>{`${notes.notasD || ''}`}</p>
                          </article>
                        </div>
                        <div className="result-block__col col-12">
                          <h4>Notas personales</h4>
                          <article className="result-block__session mt-0">
                            <p>{`${notes.notas || ''}`}</p>
                          </article>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PrintPlanningScreen;
