import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { addHours } from 'date-fns';
import { IUser } from '../types/UserInterface';

const COOKIE_NAME = 'KameA22Cookie';
const COOKIE_CLIENT_IP = 'KameA22ClientIp';

export const saveSession = async (user: Partial<IUser>) => {
  const expireDate = addHours(new Date(), 5);
  Cookies.set(
    COOKIE_NAME,
    JSON.stringify({
      ...user,
    }),
    {
      expires: expireDate,
    }
  );
};

export const getSession = () => {
  // const data = '{"_id":"123", "username": "Helios"}'; // con sesión
  const data = Cookies.get(COOKIE_NAME); // sin sesión
  return data ? (JSON.parse(data) as IUser) : null;
};

export const updateSession = (user: IUser) => {
  Cookies.remove(COOKIE_NAME);
  Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
};

export const deleteSession = () => {
  Cookies.remove(COOKIE_NAME);
};

export const getAuthSessionId = async (body: any) => {
  try {
    const current = await Auth.currentAuthenticatedUser();
    if (current) {
      const {
        signInUserSession: {
          idToken: {
            payload: { origin_jti: sessionId },
          },
        },
        attributes,
      } = current;
      const kameUserId = attributes['custom:kameUserId'];
      return { sessionId: sessionId || '', kameUserId: kameUserId || '' };
    }
  } catch (error) {
    console.log('getAuthSessionId', error, body);
    if (body && 'payload' in body) {
      const {
        payload: {
          data: {
            signInUserSession: {
              idToken: {
                payload: { origin_jti: sessionId },
              },
            },
            attributes,
          },
        },
      } = body;
      const kameUserId = attributes['custom:kameUserId'];
      return { sessionId: sessionId || '', kameUserId: kameUserId || '' };
    }
  }
  return { sessionId: '', kameUserId: '' };
};

export const getUserIp = async () => {
  let currentIp = Cookies.get(COOKIE_CLIENT_IP) || '';
  if (!currentIp || currentIp === '') {
    try {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      if (data) {
        currentIp = data.IPv4;
        Cookies.set(COOKIE_CLIENT_IP, data.IPv4, { expires: 1 });
      }
    } catch (error) {
      console.log('getUserIp', error);
    }
  }
  return currentIp;
};
