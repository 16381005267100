import React from 'react';
import Select, { ActionMeta, InputActionMeta, SingleValue } from 'react-select';
import './searchSelect.component.scss';

type Props = {
  options: {
    value: string;
    label: string;
    extra?: string;
  }[];
  idItem?: any;
  placeholder?: string;
  onChange?: (
    newValue: SingleValue<{
      value: string;
      label: string;
      extra?: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
      extra?: string;
    }>
  ) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
};

const SearchSelectComponent = ({ options, idItem, placeholder, onChange, onInputChange }: Props) => {
  return (
    <div className="search-select">
      <span className="material-icons-outlined search-select__icon">
        search
      </span>
      <Select
        placeholder={placeholder}
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={onChange}
        id={idItem}
        onInputChange={onInputChange}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F3F5F9',
            primary: '#6081E2',
          },
        })}
      />
    </div>
  );
};

export default SearchSelectComponent;
