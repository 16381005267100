import React from 'react';
import { useTracking } from 'react-tracking';

const AdviceVentasComponent = () => {
  const { trackEvent } = useTracking();

  return (
    <>
      <section className="advice-brand">
        <div className="container">
          <div className="row">
            <div className="advice-brand__col col-12">
              <a
                href="https://forms.gle/gbFugCy5xwtRusYu9"
                target="__blank"
                rel="noreferrer"
                onClick={() => {
                  trackEvent({
                    event: 'bannerSurveySales',
                    page: window.location.pathname,
                    fullPath: window.location.href,
                  });
                }}
              >
                <article>
                  <h6>¡Únete a nuestro equipo de ventas,</h6>
                  <p>
                    genera ingresos adicionales y sé parte del cambio <br /> en
                    la educación de los niños!{' '}
                  </p>
                </article>
                <div className="advice-buttons">
                  <button
                    className="btn btn--type1 advice-btn3"
                    onClick={() => {
                      trackEvent({
                        event: 'bannerSurveySalesButton',
                        page: window.location.pathname,
                        fullPath: window.location.href,
                      });
                    }}
                  >
                    Me interesa
                  </button>
                </div>
                <figure>
                  <img src="/assets/images/pacomax/paco-half.png" alt="PACO" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* 
                <figure>
                  <img
                    src="/assets/images/pacomax/paco-max-enc.png"
                    alt="PACO"
                  />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default AdviceVentasComponent;
