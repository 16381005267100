import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import FooterComponent from '../../../components/Footer/footer.component';
import AsideComponent from '../../../components/Aside/aside.component';
import InputComponent from '../../../components/Form/input/input.component';
import StepperComponent from '../../../components/Stepper/stepper.component';
import OverlayComponent from '../../../components/OverlayTrigger/OverlayComponent';
import { IUser } from '../../../types/UserInterface';
import { getUser, updateUser } from '../../../api/usersApi';
import './stepOne.screen.scss';
import SelectComponent from '../../../components/Form/select/select.component';
import { Ages } from '../../../types/EntidadesInterface';
import { getStrapiCatalog } from '../../../strapi/escuelas';

const StepOneScreen = () => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });
  const [ages, setAges] = useState<{ id: number; attributes: Ages }[]>([]);

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  const updateCurrentUser = async () => {
    try {
      if (user._id && user.token) {
        const { name, lastName, middleName, age, phone } = user;
        const valid = [name, lastName, middleName, age, phone].every(
          (attr) => attr && attr !== ''
        );
        const valid2 = [phone].every((attr) => attr && attr.length >= 10);
        if (valid && valid2) {
          const updated = await updateUser(user._id, { ...user }, user.token);
          user.userCCTs && user.userCCTs?.length > 0
            ? navigate('/bienvenido')
            : navigate('/paso-2-centro-educativo');
          return updated;
        } else {
          setError(
            'Faltan campos por llenar. El número de teléfono debe ser válido.'
          );
        }
      }
    } catch (error) {
      setError(error as string);
    }
  };

  const fetchAges = async () => {
    try {
      const query = {
        sort: ['id:asc'],
      };
      const respAges = await getStrapiCatalog(
        qs.stringify({ ...query }),
        '/age-brackets'
      );
      setAges(
        respAges.data as {
          id: number;
          attributes: Ages;
        }[]
      );
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({
      event: 'pageView',
      page: '/paso-1-perfil',
      fullPath: window.location.href,
    });
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        fetchAges();
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
              if (!kameUser.phone && kameUser.name) {
                Swal.fire(
                  '',
                  `Revisa que tu información este completa y sea correcta`,
                  'warning'
                );
              }
            }
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error));
  }, []);
  return (
    <section className="app-section">
      <HeaderLoginComponent />
      <div className="app-section__top">
        <div className="container">
          <div className="row">
            <div className="register__col-left col-md-4 col-12">
              <AsideComponent
                title="Crea un usuario para ser parte de nuestra comunidad"
                list=""
                image="/assets/images/aside/aside2.png"
                alt="Registro"
              />
            </div>
            <div className="register__col-right col-md-8 col-12">
              <div className="form-init ">
                <div className="form-init__inner col-lg-7 col-md-10 col-12 mx-auto">
                  <StepperComponent status="step-1" />
                  <h3 className="form__title">Tus datos personales</h3>
                  <form
                    onSubmit={(evt) => {
                      evt.preventDefault();
                      updateCurrentUser();
                    }}
                  >
                    <div className="form__row">
                      <label htmlFor="name" className="form__label">
                        Nombre (s) <small className="required">*</small>
                      </label>
                      <InputComponent
                        type="text"
                        id="name"
                        placeholder=""
                        value={user.name || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setUser({
                            ...user,
                            name:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="lastName" className="form__label">
                        Apellido paterno <small className="required">*</small>
                      </label>
                      <InputComponent
                        type="text"
                        id="lastName"
                        placeholder=""
                        value={user.lastName || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setUser({
                            ...user,
                            lastName:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="lastNameTwo" className="form__label">
                        Apellido materno <small className="required">*</small>
                      </label>
                      <InputComponent
                        type="text"
                        id="lastNameTwo"
                        placeholder=""
                        value={user.middleName || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setUser({
                            ...user,
                            middleName:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label
                        htmlFor="alias"
                        className="form__label tooltip-contain"
                      >
                        <div className="tooltip-text">Alias&nbsp;</div>
                        <small className="color-gray">(opcional)</small>
                        <OverlayComponent toolTipText="Así serás reconocido ante la comunidad de PACO Teacher" />
                      </label>
                      <InputComponent
                        type="text"
                        id="alias"
                        placeholder=""
                        value={user.alias || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setUser({
                            ...user,
                            alias:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="phone" className="form__label">
                        Teléfono <small className="required">*</small>
                      </label>
                      <InputComponent
                        type="tel"
                        id="phone"
                        placeholder="55 1234 5678"
                        value={user.phone || ''}
                        onChangeCapture={(event) => {
                          event?.preventDefault();
                          setUser({
                            ...user,
                            phone:
                              (event?.target as HTMLTextAreaElement).value ||
                              '',
                          });
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <label htmlFor="age" className="form__label">
                        Rango de edad
                      </label>
                      <SelectComponent
                        label="Edad"
                        id="age"
                        value={user.age?.documentId || ''}
                        option={
                          <>
                            <option defaultValue="" hidden>
                              Seleccionar...
                            </option>
                            {ages?.map((e, k) => {
                              return (
                                <option
                                  key={k}
                                  value={`${e.id}`}
                                >{`${e.attributes.Age}`}</option>
                              );
                            })}
                          </>
                        }
                        onChange={(evt) => {
                          const age = ages.filter(
                            (m) => `${m.id}` === evt.target.value
                          );
                          setUser({
                            ...user,
                            age: {
                              documentId: evt.target.value,
                              value: age.length ? age[0].attributes.Age : '',
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="form__row">
                      <div className="alert alert-neutral">
                        <p>
                          <strong>
                            <i className="fas fa-info-circle"></i> Tip.
                          </strong>{' '}
                          Puedes completar después los campos opcionales.
                        </p>
                      </div>
                    </div>
                    <div className="form__row form__action mt32">
                      <button type="submit" className="btn btn--type3">
                        Continuar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </section>
  );
};

export default StepOneScreen;
