import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';
import { useTracking } from 'react-tracking';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderLoginComponent from '../../../components/Header/headerLogin/headerLogin.component';
import HeaderLogoutComponent from '../../../components/Header/headerLogout/headerLogout.component';
import FooterComponent from '../../../components/Footer/footer.component';
import { getUser } from '../../../api/usersApi';
import { IUser } from '../../../types/UserInterface';
import { getPlanningsNem } from '../../../strapi/planningsNem';
import { IPlanningNem } from '../../../types/PlaningInterface';
import BreadcrumbComponent from '../../../components/Breadcrumb/breadcrumb.component';
import NavigationHints from '../../../components/NavigationHints/navigationHints';

const PlanningsViewScreen = () => {
  const { trackEvent } = useTracking();
  const { id: planId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({ email: '' });

  const [planning, setPlanning] = useState<Partial<IPlanningNem>>({});

  const setError = (errorMessage: string) => {
    console.log('errorMessage', errorMessage);
    Swal.fire('Error', `${errorMessage}`, 'error');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const {
          attributes,
          signInUserSession: {
            accessToken: { jwtToken },
          },
        } = currentUser;
        const kameUserId = attributes['custom:kameUserId'];
        getUser(kameUserId, jwtToken)
          .then((kameUser) => {
            if (kameUser) {
              setUser({ ...kameUser, token: jwtToken });
            }
          })
          .catch((error) => setError(error));
      })
      .catch(() => {});

    const query = {
      populate: '*',
      filters: { id: { $eq: planId } },
    };
    getPlanningsNem(query).then((response) => {
      setPlanning(response.data[0].attributes);
      setIsLoading(false);
      trackEvent({
        event: 'pageView',
        page: '/planeaciones-nueva-escuela-mexicana',
        planningNem: response.data[0].attributes.Proyecto,
        fullPath: window.location.href,
      });
    });
  }, []);

  return (
    <section className="app-section">
      <div className="loader__wrapp" data-class={isLoading}>
        <div className="loader__inner">
          <div className="loader"></div>
        </div>
      </div>
      {user.email !== '' ? <HeaderLoginComponent /> : <HeaderLogoutComponent />}
      <div className="app-section__top">
        <BreadcrumbComponent
          url={`/planeaciones/nueva-escuela-mexicana`}
          customNavigate={() => {
            navigate(-1);
          }}
          back="Regresar"
        />
      </div>
      <section className="container">
        <div className="planning-nem-paco col-md-6 col-12">
          <div className="print-plan__title">
            <h1 className="proyecto-nem">{`${planning.Proyecto || ''}`}</h1>
            <p>
              <strong>Por PACO Teacher</strong>
            </p>
          </div>
          <section className="print-plan__section">
            <div className="print-section__item">
              <div className="print-section__row row">
                {!!planning.nem_campo_formativos && (
                  <div className="nem-section__row__col col-6">
                    <h6>Campo formativo</h6>
                    <p>{`${planning.nem_campo_formativos.data
                      .map((c) => c.attributes.Nombre)
                      .join(', ')}`}</p>
                  </div>
                )}
                {!!planning.grados && (
                  <div className="nem-section__row__col col-6">
                    <h6>Grado escolar</h6>
                    <p>{`${planning.grados.data
                      .map((g) => g.attributes.Grado)
                      .join(', ')}`}</p>
                  </div>
                )}
                {!!planning.libro && (
                  <div className="nem-section__row__col col-6">
                    <h6>Libro de texto</h6>
                    <p>{`${planning.libro}`}</p>
                  </div>
                )}
                {!!planning.fase && (
                  <div className="nem-section__row__col col-6">
                    <h6>Fase</h6>
                    <p>{`${planning.fase}`}</p>
                  </div>
                )}
                {!!planning.nem_ejes_articulares && (
                  <div className="nem-section__row__col col-6">
                    <h6>Ejes articulares</h6>
                    <p>{`${planning.nem_ejes_articulares.data
                      .map((e) => e.attributes.Nombre)
                      .join(', ')}`}</p>
                  </div>
                )}
                {!!planning.escenario && (
                  <div className="nem-section__row__col col-6">
                    <h6>Escenario</h6>
                    <p>{`${planning.escenario}`}</p>
                  </div>
                )}
                {!!planning.proposito && (
                  <div className="nem-section__row__col col-6">
                    <h6>Proposito</h6>
                    <p>{`${planning.proposito}`}</p>
                  </div>
                )}
                {!!planning.sesiones && (
                  <div className="nem-section__row__col col-6">
                    <h6>Número de sesiones</h6>
                    <p>{`${planning.sesiones}`}</p>
                  </div>
                )}
                {!!planning.nem_metodologia && (
                  <div className="nem-section__row__col col-6">
                    <h6>Metodología</h6>
                    <p>{`${planning.nem_metodologia.data.attributes.Nombre}`}</p>
                  </div>
                )}
                <div className="nem-section__row__col col-6">
                  <h6>Formato</h6>
                  <p>
                    Hoja de cálculo (Excel){' '}
                    <img src="/assets/images/icon/excel.png" alt="Excel" />
                  </p>
                </div>
                {!!planning.planeacion?.data && (
                  <div className="nem-section__row__col offset-6 col-6">
                    <a
                      className="btn btn--type7"
                      href={planning.planeacion.data.attributes.url}
                      download={`${planning.planeacion.data.attributes.hash}.${planning.planeacion.data.attributes.ext}`}
                      onClick={() => {
                        trackEvent({
                          event: 'downloadPlanningNem',
                          page: '/planeaciones-nueva-escuela-mexicana',
                          fullPath: window.location.href,
                          planningNem: planning.Proyecto,
                          planningId: planId,
                        });
                      }}
                    >
                      Descargar
                      <span className="material-icons icon">download</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </section>
      <NavigationHints
        button1={{
          link: '/instrumentos-de-evaluacion',
          texto: 'Instrumentos de evaluación con Inteligencia Artificial',
          color: 'btn--color4',
          iconText: 'icon--grid8',
          icon: true,
          arti: true,
          max: false,
        }}
        button2={{
          link: '/busqueda',
          texto: 'Recursos educativos',
          color: 'btn--color3',
          iconText: 'icon--grid4',
          icon: true,
          arti: false,
          max: false,
        }}
        button3={{
          link: '/estrategias-didacticas',
          texto: 'Enseña Matemáticas con PACO Max',
          color: 'btn--color3',
          iconText: 'icon--grid7',
          icon: true,
          arti: false,
          max: true,
        }}
        user={user}
      />
      <FooterComponent />
    </section>
  );
};

export default PlanningsViewScreen;
